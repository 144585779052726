import React, { useMemo } from 'react';
import { Box, TableCell } from '@mui/material';
import _ from 'lodash';
import Remove from '@mui/icons-material/Remove';
import { useSelector } from 'react-redux';

import { colorPalette } from 'ui/theme';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Location } from 'services/locations';
import { BulkWizardItem } from 'services/inventory';
import { EACH_UOM_ID, getUoms } from 'services/uoms';

import { useCustomRowStyle } from './styled';

export const CustomRow: React.FC<RenderCustomRowProps<BulkWizardItem>> = (
  props
) => {
  const { row, setData, onAction, index, meta } = props;

  const { items: uoms } = useSelector(getUoms);
  const classes = useCustomRowStyle();

  const errors = _.get(meta, 'errors');

  const selectedUom = useMemo(() => {
    return uoms.find((u) => u.id === row.uomId) || null;
  }, [uoms, row.uomId]);

  const handleAmountChange = (value: number | null) => {
    setData(
      {
        ...row,
        quantity: value,
      },
      index
    );
  };

  const handleLocationChange = (value: Location | null) => {
    setData(
      {
        ...row,
        moveToLocationId: value ? value.id : null,
      },
      index
    );
  };

  const handleOnAction = () => {
    if (onAction) {
      onAction(row.id);
    }
  };

  return (
    <>
      <TableCell>
        <Box width={224}>
          <ImageItemBox
            source={row.defaultImage}
            name={row.name}
            description={row.description}
          />
        </Box>
      </TableCell>
      <TableCell>
        {row.availableQty}
        {` ${_.get(selectedUom, 'abbreviation', '')}`}
      </TableCell>
      <TableCell>
        <TextFieldQuantity
          selectedUomId={row.uomId ? row.uomId : EACH_UOM_ID}
          uoms={uoms}
          value={row.quantity}
          fullWidth
          error={errors[`items[${index}].quantity`]}
          onTextChange={handleAmountChange}
          dataQa="bulk-move-qty-to-move"
        />
      </TableCell>
      <TableCell>
        <LocationsAsyncAutocomplete
          value={row.locationId}
          placeholder="From Location"
          companyWide={false}
          disabled
          onChange={_.noop}
          dataQa="bulk-move-from-location"
        />
      </TableCell>
      <TableCell>
        <LocationsAsyncAutocomplete
          value={row.moveToLocationId}
          placeholder="To Location"
          onChange={handleLocationChange}
          companyWide={false}
          error={errors[`items[${index}].moveToLocationId`]}
          filterOutLocations={[row.locationId]}
          dataQa="bulk-move-to-location"
        />
      </TableCell>
      <TableCell>
        <Box
          display="flex"
          alignItems="center"
          className={classes.remove}
          width={24}
          height="100%"
          onClick={handleOnAction}
          color={colorPalette.fishbowlCorporateGray3}
        >
          <Remove data-qa="bulk-move-item-remove" color="inherit" />
        </Box>
      </TableCell>
    </>
  );
};

export default CustomRow;
