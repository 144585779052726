import React from 'react';
import { Box, Typography, Chip } from '@mui/material';
import _ from 'lodash';
import clsx from 'clsx';

import { PickStatus, PickItemStatus } from 'services/picking';
import { ShipStatus } from 'services/shipping';
import {
  SalesOrderPaymentStatus,
  SalesOrderStatus,
} from 'services/salesOrders';
import { ReceiptItemStatus, ReceiptStatus } from 'services/receiving';
import { PurchaseOrderStatus } from 'services/purchaseOrders';
import { SerialTableStatus } from 'ui/components/Table/SerialTable';

import { useTitleBarStyle } from './styled';
import { StatusLabel } from 'ui/components/Status/StatusLabel';

export interface FBOTitleBarProps {
  title?: string | JSX.Element | null;
  children?: React.ReactNode;
  status?:
    | PickStatus
    | PickItemStatus
    | ShipStatus
    | SalesOrderStatus
    | SerialTableStatus
    | ReceiptStatus
    | ReceiptItemStatus
    | PurchaseOrderStatus;
  statusCustom?: string;
  statusCustomStyle?: string;
  beforeElements?: React.ReactNode[];
  afterElements?: React.ReactNode[];
  dangerouslySetInnerHTML?: boolean;
  imageUrl?: string;
  sx?: React.CSSProperties;
  noPadding?: boolean;
}

export type TitleBarStyleProps = Pick<FBOTitleBarProps, 'status'>;

export const CustomStatus = (props: {
  statusCustom: string;
  statusCustomStyle: string | undefined;
  classes: ReturnType<typeof useTitleBarStyle>;
  combineStatusLabels?: boolean;
  label?: string;
}) => {
  const {
    statusCustom,
    statusCustomStyle,
    classes,
    combineStatusLabels,
    label,
  } = props;
  let status;
  switch (statusCustom) {
    case SalesOrderPaymentStatus.Paid:
      status = SalesOrderPaymentStatus.Paid;
      break;
    case SalesOrderPaymentStatus.Unpaid:
      status = SalesOrderPaymentStatus.Unpaid;
      break;
    case SalesOrderPaymentStatus.PartiallyPaid:
      status = SalesOrderPaymentStatus.PartiallyPaid;
      break;
    case SerialTableStatus.Success:
      status = SerialTableStatus.Success;
      break;
    case SerialTableStatus.PartialSelected:
      status = SerialTableStatus.PartialSelected;
      break;
    default:
      status = null;
  }

  if (status) {
    return (
      <StatusLabel
        style={{ marginLeft: '8px' }}
        status={status}
        data-qa="title-custom-status-label"
        combineStatusLabel={combineStatusLabels}
        label={label}
      />
    );
  }

  return (
    <Chip
      style={{ marginLeft: '8px' }}
      label={statusCustom}
      size="small"
      classes={{ root: classes.status, label: classes.statusLabel }}
      className={statusCustomStyle}
      color="secondary"
    />
  );
};

const FBOTitleBar = (props: FBOTitleBarProps) => {
  const {
    title,
    children,
    status,
    statusCustom,
    statusCustomStyle,
    beforeElements,
    afterElements,
    dangerouslySetInnerHTML,
    sx = {},
    // imageUrl,
  } = props;

  const classes = useTitleBarStyle(props);

  return (
    <Box className={clsx(classes.titleBar)} sx={sx}>
      {(title || status || statusCustom) && (
        <>
          {/* {imageUrl && (
            <img src={imageUrl} alt="Logo" className={classes.image} />
          )} */}

          {/* figure out whats going on here? */}
          {_.isString(title) && dangerouslySetInnerHTML ? (
            <Typography
              dangerouslySetInnerHTML={{ __html: title }}
              className="redesign"
              variant="body1"
            />
          ) : (
            <Typography className="redesign" variant="body1">
              <b>{title}</b>
            </Typography>
          )}

          {status && (
            <StatusLabel
              style={{ marginLeft: '8px' }}
              status={status}
              data-qa="title-status-label"
            />
          )}
          {statusCustom && (
            <CustomStatus
              statusCustom={statusCustom}
              statusCustomStyle={statusCustomStyle}
              classes={classes}
            />
          )}
        </>
      )}
      <Box
        display="flex"
        flexGrow={1}
        justifyContent="flex-end"
        alignItems="center"
      >
        {beforeElements || null}
        {children}
        {afterElements || null}
      </Box>
    </Box>
  );
};

export default React.memo(FBOTitleBar);
