import * as yup from 'yup';

export const locationSchema = yup.object({
  locationIds: yup.array().of(yup.number()).required(),
});
export const itemsSchema = yup.object({
  ropItems: yup.array().of(yup.mixed()).required(),
});

export const getSchema = (activeStep: number): any => {
  switch (activeStep) {
    case 0:
      return locationSchema;
    case 1:
      return itemsSchema;
    // case 2:
    //   return confirmSchema;
    default:
      return locationSchema;
  }
};
