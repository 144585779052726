import React, { useCallback, useState, useEffect } from 'react';

import { showNotification } from 'services/api';
import { Modal } from 'ui/components/Modal/Modal';
import Wizard from 'ui/components/Wizard/Wizard';

import { useRopWizard } from './AutoRopWizardProvider';
import { ItemSelector, LocationGroups, ConfirmRopValues } from './components';
import { getSteps, initialRopWizardData } from './consts';
import { AutoRopWizardProps } from './types';
import { useAutoRopWizardStyle } from './styled';
import { postAutoROP, postReorderPointList } from './helpers';
import { getSchema } from './validation';
import { validateYup } from 'services/forms/validation';
import { logErrorCtx } from 'app/logging';

const AutoRopWizard: React.FC<AutoRopWizardProps> = (props) => {
  const { show, setShowRopWizard } = props;
  const {
    wizardData,
    setWizardData,
    setConfirmData,
    confirmData,
    setConfirmDataFromResponse,
    setErrors,
  } = useRopWizard();

  const classes = useAutoRopWizardStyle();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (activeStep !== getSteps().length - 1) {
      return;
    }
    (async () => {
      setIsLoading(true);
      try {
        const response = await postAutoROP(wizardData);
        setConfirmDataFromResponse(response);
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error while saving Auto Reorder Points', {
          error,
          stackTrace: error.stack,
          title: error.message,
          description: 'Unable to save auto re-order points in AutoRopWizard',
          component: 'AutoRopWizard',
        });
        return;
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardData.chosenDates, postAutoROP, setConfirmDataFromResponse]);

  const handleNextClicked = useCallback(async () => {
    const schema = getSchema(activeStep);
    const isValid = validateYup(wizardData, schema, setErrors);

    if (activeStep === 0 && !isValid) {
      showNotification('You need to select location', {
        variant: 'warning',
      });
      return;
    }

    if (activeStep === 1 && !isValid) {
      showNotification('You need to select item', {
        variant: 'warning',
      });
      return;
    }

    if (!isValid) {
      return;
    }

    // if before last step
    if (activeStep === getSteps().length - 2) {
      setIsLoading(true);
      try {
        const response = await postAutoROP(wizardData);
        setConfirmDataFromResponse(response);
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error while calculating reorder points', {
          error,
          stackTrace: error.stack,
          title: error.message,
          description:
            'Error occured when calculating re-order points in AutoRopWizard',
          component: 'AutoRopWizard',
        });
        showNotification(`Unable to calculate reorder points`, {
          variant: 'error',
        });
      }
      setIsLoading(false);
      return;
    }

    // if last step
    if (activeStep === getSteps().length - 1) {
      try {
        setIsLoading(true);
        await postReorderPointList(confirmData);
        setShowRopWizard(false);
        setConfirmData([]);
        setWizardData(initialRopWizardData);
        setActiveStep(0);
        showNotification('Successfully created reorder points', {
          variant: 'success',
        });
      } catch (err) {
        logErrorCtx('Error in AutoRopWizard last step', {
          error: err as Error,
          component: 'AutoRopWizard',
        });
      }
      setIsLoading(false);
      return;
    }

    setActiveStep((old) => old + 1);
  }, [
    activeStep,
    wizardData,
    confirmData,
    setConfirmData,
    setConfirmDataFromResponse,
    setShowRopWizard,
    setWizardData,
    setErrors,
  ]);

  const handleBackClicked = useCallback(() => {
    setActiveStep((old) => old - 1);
  }, []);

  const handleCancelClicked = useCallback(() => {
    setActiveStep(0);
    setShowRopWizard(false);
    setWizardData(initialRopWizardData);
  }, [setShowRopWizard, setWizardData]);

  return (
    <Modal
      open={show}
      onCancelClicked={handleCancelClicked}
      title="Calculate Reorder Points"
      scroll="paper"
      withoutFooter
      withoutDefaultPadding
      maxWidth="lg"
      dataQa="inventory-rop-wizard"
    >
      <Wizard
        steps={getSteps(activeStep, classes.activeIcon)}
        activeStep={activeStep}
        onNext={handleNextClicked}
        onPrevious={handleBackClicked}
        hideSteps
        isLoading={isLoading}
      >
        {[
          <LocationGroups key={0} />,
          <ItemSelector key={1} />,
          <ConfirmRopValues key={2} />,
        ]}
      </Wizard>
    </Modal>
  );
};

export default AutoRopWizard;
