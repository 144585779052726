import React, { memo, useCallback, useState } from 'react';
import {
  fetchEcommerceChannels,
  getAllECommerceSettingsByChannel,
} from 'services/integrations/ecommerce/redux';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

import {
  ChannelType,
  disconnectChannel,
} from 'services/integrations/ecommerce';
import { Modal } from 'ui/components/Modal/Modal';
import { ShopifyLogoWhite } from 'ui/theme/images';
import { TextField } from 'ui/components/TextField/TextField';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { fetchShopifyUrl } from 'services/integrations/ecommerce/shopify';
import { Errors } from 'services/forms/validation';
import { logErrorCtx } from 'app/logging';

import { ShopifyCardProps } from './types';
import { Card } from '../';
import { CardButton } from '../IntegrationButtons/CardButton';
import { showNotification } from 'services/api';
import { MenuItem } from 'ui/components/Menu';
import { IconButtonWithActions } from 'ui/components/Button/IconButtonWithActions';
import { IconNames } from 'ui/theme';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const ShopifyCard: React.FC<ShopifyCardProps> = (props) => {
  const { connectionValid } = props;

  const dispatch = useDispatch();

  const [shopifyModalVisible, setShopifyModalVisible] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [showConnectionModal, setShowConnectionModal] = useState(false);
  const [shopifyShopDomain, setShopifyShopDomain] = useState<string | null>(
    null
  );
  const [shopifyErrors, setShopifyErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState(false);
  const [activeChannel, setActiveChannel] = useState<{
    id: number;
    name: string;
  }>();

  const shopifySettings = useSelector(
    getAllECommerceSettingsByChannel(ChannelType.Shopify)
  );

  const handleTextFieldChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setShopifyShopDomain(e.target.value);
    },
    []
  );

  const closeModal = () => {
    setShopifyModalVisible(false);
    setShopifyErrors({});
  };

  const shopifyConnectClicked = useCallback(
    async (e) => {
      e.preventDefault();

      if (!shopifyShopDomain) {
        setShopifyErrors({ shopDomain: 'Please enter Shop Domain.' });
        return;
      }

      const validDomain = shopifyShopDomain.match(
        /^([a-zA-Z0-9_.-]+)\.([A-z]{2,})/
      );

      if (!validDomain) {
        setShopifyErrors({ shopDomain: 'Invalid domain' });
        return;
      }

      // Fix for being unable to connect to shopify if the url has trailing slash at the end
      const shopifyShopDomainUrlTrailing = shopifyShopDomain.replace(
        /\/$/g,
        ''
      );

      const domainExists = shopifySettings.filter(
        (el) => el.channel.domain === shopifyShopDomainUrlTrailing
      );

      if (domainExists.length > 0) {
        showNotification(`Wrong data. Domain already connected.`, {
          variant: 'warning',
        });

        return;
      }

      setIsLoading(true);

      try {
        const url = await fetchShopifyUrl(shopifyShopDomainUrlTrailing);
        const windowOpen = window.open(url + '#shopify', '_blank');
        if (windowOpen) {
          windowOpen.focus();
        }
        setShopifyErrors({});
        setShopifyModalVisible(false);
        setShopifyShopDomain(null);
      } catch (e) {
        showNotification(`Shopify Connection Failed!`, {
          variant: 'error',
        });
        const error = e as Error;
        logErrorCtx('Shopify connection failed.', {
          error,
          stackTrace: error.stack,
          title: 'Connecting to Shopify failed.',
          component: 'Shopify -> ShopifyCard',
        });
      }
      setIsLoading(false);
    },
    [shopifyShopDomain, shopifySettings]
  );

  const disconnectShopify = useCallback(async () => {
    if (activeChannel) {
      setIsLoading(true);
      await disconnectChannel(activeChannel.id!);
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchEcommerceChannels());
      setIsLoading(false);
      setShowDisconnectModal(false);
      setActiveChannel(undefined);
    } else {
      showNotification(`Unable to disconnect! No channel id provided!`, {
        variant: 'error',
      });
    }
  }, [dispatch, activeChannel]);

  const handleConnect = () => {
    if (!connectionValid) {
      setShowConnectionModal(true);
      return;
    }

    setShopifyModalVisible(true);
  };

  const createItemsSearchActionBarOptions = (): MenuItem[] => {
    return shopifySettings.map((channel) => {
      return {
        label: channel.channel.name!,
        onOptionClick: () => {
          setActiveChannel({
            id: channel.channel.id!,
            name: channel.channel.name!,
          });
          setShowDisconnectModal(true);
        },
      };
    });
  };

  return (
    <>
      <Card
        imageUrl={ShopifyLogoWhite}
        cardId="shopify"
        isLoading={false}
        description="Manage orders while keeping inventory levels up to date with Shopify."
        title="Shopify"
      >
        <>
          {shopifySettings.length > 0 ? (
            <IconButtonWithActions
              icon={IconNames.MoreVertical}
              iconSize={16}
              text="Disconnect"
              boxStyle
              items={createItemsSearchActionBarOptions()}
            />
          ) : null}
          <CardButton
            onClick={handleConnect}
            variant="primary"
            color="positive"
            dataQa="shopify-connect"
            label="Connect"
          />
        </>
      </Card>
      <Modal
        open={shopifyModalVisible}
        title="Enter your Shop Domain"
        maxWidth="sm"
        onCancelClicked={closeModal}
        isLoadingContent={isLoading}
        ModalActionsComponent={() => (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <FBOButton
              variant="secondary"
              color="positive"
              data-qa="cancel-button"
              onClick={closeModal}
            >
              Cancel
            </FBOButton>
            <Box ml={2}>
              <FBOButton
                variant="primary"
                color="positive"
                data-qa="save-button"
                onClick={shopifyConnectClicked}
              >
                Save
              </FBOButton>
            </Box>
          </Box>
        )}
      >
        <form onSubmit={shopifyConnectClicked}>
          <Box display="flex" alignItems="center">
            <Typography variant="body1" color="textPrimary">
              https://
            </Typography>
            <Box mx={1} flex={1}>
              <TextField
                value={shopifyShopDomain}
                label="Shop Domain"
                onChange={handleTextFieldChange}
                placeholder="Your shop domain"
                error={!!shopifyErrors.shopDomain}
                helperText={shopifyErrors.shopDomain}
                fullWidth
                disableDebounce
              />
            </Box>
          </Box>
        </form>
      </Modal>
      <ConfirmationModal
        open={showDisconnectModal}
        title="Disconnect Shopify"
        body={
          'Are you sure you want to disconnect Shopify channel ' +
          (activeChannel ? activeChannel!.name : null) +
          '?'
        }
        isLoading={isLoading}
        onCancelClicked={() => setShowDisconnectModal(false)}
        onConfirmClicked={disconnectShopify}
      />
      <ConfirmationModal
        open={showConnectionModal}
        title="Maximum number of integrations has been reached!"
        onConfirmClicked={() => setShowConnectionModal(false)}
        onCancelClicked={() => setShowConnectionModal(false)}
        body="Please contact sales to add additional integrations."
      />
    </>
  );
};

export default memo(ShopifyCard);
