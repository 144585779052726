import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Pagination } from 'services/search';
import {
  Column,
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import { PermissionType } from 'services/permissions';
import { arrayHasAllDeletedObjects } from 'helpers';
import { getSettingsCompany } from 'services/settings/company';
import { CostingMethod } from 'ui/modules/setup/pages/SettingsPage/components/CompanyCard/types';

import { ItemSearchResultsProps } from './types';
import { default as ItemRow } from './ItemRow';
import {
  createItemsSearchActionBarOptions,
  ITEM_COLUMNS_ALWAYS_ON,
} from './consts';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const ItemsSearchResults: React.FC<ItemSearchResultsProps> = (props) => {
  const {
    activeItemId,
    handleItemClick,
    items,
    columns,
    handleAddNewClick,
    isLoadingItems,
    pagination,
    onPaginationChange,
    onAction,
    selectedItems,
    setSelectedItems,
    onDeleteClicked,
    onItemPageAction,
  } = props;

  const companySettings = useSelector(getSettingsCompany);

  const resolvedColumns = useMemo(() => {
    let columnsToReturn: Column[] = columns;
    if (activeItemId) {
      columnsToReturn = columns.filter((c) =>
        ITEM_COLUMNS_ALWAYS_ON.some((column) => column.title === c.title)
      );
    } else if (
      companySettings.accountingMethod !== CostingMethod.Average &&
      !activeItemId
    ) {
      columnsToReturn = columns.filter((c) => c.title !== 'Cost');
    }

    return columnsToReturn;
  }, [activeItemId, columns, companySettings.accountingMethod]);

  const allDeletedItems = useMemo(
    () => arrayHasAllDeletedObjects(items),
    [items]
  );

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  const handleSelectClick = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  const handleAction = useCallback(
    (action: any) => {
      onAction(action.type, action.value);
    },
    [onAction]
  );

  return (
    <>
      <FBOTitleBar title="Items">
        <>
          {!activeItemId && selectedItems.length > 0 && (
            <FBOButton
              style={{ marginRight: '8px' }}
              variant="secondary"
              size="medium"
              color="negative"
              icon="TrashCan"
              onClick={onDeleteClicked}
              permissions={[PermissionType.ItemsDelete]}
              data-qa="delete-selected-items"
            >
              Delete
            </FBOButton>
          )}
          {activeItemId !== -1 && (
            <FBOButton
              style={{ marginRight: '8px' }}
              variant={!activeItemId ? 'primary' : 'secondary'}
              size="medium"
              color="positive"
              icon="FBOAddCircle"
              onClick={handleAddNewClick}
              permissions={[PermissionType.ItemsCreate]}
              data-qa="item-new"
            >
              {!activeItemId ? 'Add New' : ''}
            </FBOButton>
          )}
          <FBOMenuButton
            variant="secondary"
            items={createItemsSearchActionBarOptions(
              onItemPageAction,
              items.length
            )}
            data-qa="items-three-dot-menu"
          />
        </>
      </FBOTitleBar>

      <ItemsTable
        data={items}
        columns={resolvedColumns}
        activeId={activeItemId}
        onItemClick={handleItemClick}
        onSelectedChange={handleSelectClick}
        selectedItems={selectedItems}
        selectableItems={!activeItemId}
        disableSelection={allDeletedItems}
        RenderCustomRow={ItemRow}
        isLoadingData={isLoadingItems}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        onAction={handleAction}
        dataQa="item-table"
        tableLayoutFixed
        meta={{ companySettings }}
        emptyTableText={
          activeItemId
            ? 'NO ITEMS AVAILABLE'
            : "ADD NEW ENTRY BY PRESSING 'ADD NEW'"
        }
        showEnhancedTablePaginationActions={!Boolean(activeItemId)}
      />
    </>
  );
};
export default memo(ItemsSearchResults);
