import React, { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import { ReceiveWizardSummaryProps } from './types';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import {
  finishColumns,
  splitColumns,
} from 'ui/modules/sales/pages/PickingPage/components/PickingDetailsCard/components/SummaryTrackingTable/consts';
import RecieveSummaryRow from './RecieveSummaryRow';
import RecieveSummarySplitRow from './RecieveSummarySplitRow';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import { colorPalette } from 'ui/theme';

const ReceiveWizardSummary: React.FC<ReceiveWizardSummaryProps> = (props) => {
  const { receive } = props;
  const itemsToBeSplit = useMemo(() => {
    return receive.filter(
      (i) => (i.quantity ?? 0) < (i.receiptItem.quantity ?? 0)
    );
  }, [receive]);

  return (
    <Box
      display="flex"
      height="100%"
      flexDirection="column"
      width="100%"
      margin="auto"
    >
      <FBOTitleBar title="Quantity to Receive" />

      <ItemsTable
        data={receive}
        columns={finishColumns}
        RenderCustomRow={RecieveSummaryRow}
        selectableItems={false}
        sx={{
          borderRadius: '5px',
          border: `1px solid ${colorPalette.redesign.background3}`,
          borderTop: 'none',
          maxHeight: '200px',
        }}
      />

      {itemsToBeSplit.length > 0 && (
        <>
          <FBOTitleBar title="Quantity to Recieve" />

          <ItemsTable
            data={itemsToBeSplit}
            columns={splitColumns}
            RenderCustomRow={RecieveSummarySplitRow}
            selectableItems={false}
            sx={{
              borderRadius: '5px',
              border: `1px solid ${colorPalette.redesign.background3}`,
              borderTop: 'none',
              maxHeight: '200px',
            }}
          />
        </>
      )}
    </Box>
  );
};

export default memo(ReceiveWizardSummary);
