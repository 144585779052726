import React, { memo, useCallback } from 'react';
import {
  TableCell,
  TableRow,
  TableBody,
  Checkbox,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import _, { noop } from 'lodash';
import clsx from 'clsx';

import { EnhancedBodyProps, RowNameNadDescriptionProps } from './types';
import { useEnhancedBodyStyles } from './styled';
import { default as DefaultEnhancedRow } from './EnhancedRow';
import { Icon } from 'ui/components/Icon';
import { IconNames } from 'ui/theme/icons';
import { colorPalette } from 'ui/theme';

const defaultEmptyTableText = "ADD NEW SUBSTITUTE ITEM BY PRESSING 'NEW'";

const EnhancedBody: React.FC<EnhancedBodyProps> = (props) => {
  const {
    data,
    columns,
    onSelectItemClick,
    selectedItems,
    meta,
    isTableEmpty,
    isLoadingData,
    emptyTableText = defaultEmptyTableText,
    selectableItems = true,
    disableSelection,
    onItemClick = noop,
    activeId = null,
    setData = noop,
    RenderCustomRow: EnhancedRow = DefaultEnhancedRow,
    onAction = noop,
    filterRows = () => true,
    rowErrors = [],
    RenderRowSubrows = null,
    tableBordered,
    getRowclsx = () => ({}),
    dataQa,
    onEmptyTableTextRowClick: onEmptyTableTextRowClicked,
    isCycleCountOrScrap = false,
  } = props;

  const classes = useEnhancedBodyStyles(props);
  const isItemSelected = useCallback(
    (id: number) => selectedItems.indexOf(id) !== -1,
    [selectedItems]
  );

  const handleRowSelectClick = useCallback(
    (rowId: number) => (event: React.MouseEvent<unknown>) => {
      event.stopPropagation();
      onSelectItemClick(rowId);
    },
    [onSelectItemClick]
  );

  const handleRowClick = useCallback(
    (rowId: number) => () => onItemClick(rowId),
    [onItemClick]
  );

  const defaultCellDataHandler = useCallback(
    (row: any, index: number) => {
      setData([...data.slice(0, index), row, ...data.slice(index + 1)]);
    },
    [data, setData]
  );

  const handleRowAction = useCallback(
    (action: any, row: any) => {
      onAction(action, row);
    },
    [onAction]
  );

  const shouldDisableCheckbox = (rowId: number) => {
    if (meta?.idsToDisable?.length === 0) {
      return false;
    }
    if (meta?.idsToDisable?.includes(rowId)) {
      return true;
    }
    return false;
  };

  const renderEmptyRow = useCallback(() => {
    const colSpanCalculated = columns.length + 1;

    return (
      <TableRow onClick={onEmptyTableTextRowClicked}>
        <TableCell
          colSpan={colSpanCalculated}
          data-qa="ADD NEW ENTRY BY PRESSING 'ADD FIELD'"
        >
          <Typography align="center" color="textSecondary" component="div">
            <i>{emptyTableText.toUpperCase()}</i>
          </Typography>
        </TableCell>
      </TableRow>
    );
  }, [columns, emptyTableText, onEmptyTableTextRowClicked]);

  const renderTooltipText = (
    rowId: number,
    item: RowNameNadDescriptionProps
  ) => {
    const name = _.get(item, 'name');
    const number = _.get(item, 'number');
    const description = _.get(item, 'description');

    let fullTitle = name || number;

    if (name && description) {
      fullTitle = `${name} - ${description}`;
    }

    if (meta?.idsToDisable?.includes(rowId)) {
      return 'System created tracking can not be deleted';
    }

    return fullTitle;
  };

  return (
    <>
      {isLoadingData ? (
        <TableBody>
          <TableRow>
            <TableCell style={{ borderBottom: 0 }}>
              <Box className={classes.loader}>
                <CircularProgress id="search-results-loading" />
              </Box>
            </TableCell>
          </TableRow>
        </TableBody>
      ) : (
        <TableBody>
          {isTableEmpty && renderEmptyRow()}

          {!isTableEmpty &&
            data.map((row: any, index: number) => {
              const shouldRenderRow = filterRows(row);
              if (!shouldRenderRow) {
                return null;
              }
              return (
                <React.Fragment
                  key={`${row.id ? row.id + '-' + index : index}Row`}
                >
                  <TableRow
                    title={renderTooltipText(row.id, row)}
                    hover
                    selected={activeId !== null && activeId === row.id}
                    onClick={handleRowClick(row.id)}
                    className={clsx(classes.tableRow, {
                      [classes.rowSelectedRestyle]: isItemSelected(row.id),
                      [classes.rowClickable]: !!onItemClick,
                      ...getRowclsx(row),
                    })}
                    data-qa={renderTooltipText(row.id, row) || row.number}
                  >
                    {selectableItems ? (
                      <TableCell
                        padding="checkbox"
                        className={classes.cellStyleRedesign}
                        data-qa={`${row.name || row.number || dataQa}-checkbox`}
                      >
                        <Checkbox
                          checked={isItemSelected(row.id)}
                          onClick={handleRowSelectClick(row.id)}
                          disabled={
                            Boolean(disableSelection) ||
                            (isCycleCountOrScrap ? row.committed : false) ||
                            shouldDisableCheckbox(row.id)
                          }
                          className={`redesign ${classes.cellCheckboxRedesign}`}
                          icon={
                            <Icon
                              name={IconNames.FBOUncheckedCircle}
                              stroke={colorPalette.redesign.background4}
                            />
                          }
                          checkedIcon={
                            <Icon name={IconNames.FBOCheckedCircle} />
                          }
                          disableRipple
                          color="primary"
                          size="small"
                          inputProps={
                            {
                              'data-qa': `${dataQa}-checkbox`,
                            } as any
                          }
                        />
                      </TableCell>
                    ) : (
                      <TableCell
                        data-qa={`${row.name || row.number || dataQa}-checkbox`}
                        style={{ minWidth: 0, width: 0, maxWidth: 0 }}
                      />
                    )}
                    <EnhancedRow
                      classes={classes}
                      row={row}
                      setData={defaultCellDataHandler}
                      columns={columns}
                      index={index}
                      onAction={handleRowAction}
                      errors={rowErrors[index] || {}}
                      meta={meta}
                      tableBordered={tableBordered}
                      dataQa={dataQa}
                    />
                  </TableRow>
                  {RenderRowSubrows && (
                    <RenderRowSubrows
                      classes={classes}
                      row={row}
                      setData={defaultCellDataHandler}
                      columns={columns}
                      index={index}
                      onAction={handleRowAction}
                      errors={rowErrors[index] || {}}
                      meta={meta}
                    />
                  )}
                </React.Fragment>
              );
            })}
        </TableBody>
      )}
    </>
  );
};

export default memo(EnhancedBody);
