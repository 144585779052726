import {
  createApiAction,
  FailureAction,
  StartAction,
  SuccessAction,
} from 'services/api';

import { paymentIntegrationHostname } from '../consts';

export enum PaymentIntegrationActionType {
  FETCH_PAYMENT_INTEGRATIONS_START = 'paymentIntegrations/fetch_payment_integrations_start',
  FETCH_PAYMENT_INTEGRATIONS_SUCCESS = 'paymentIntegrations/fetch_payment_integrations_success',
  FETCH_PAYMENT_INTEGRATIONS_FAILURE = 'paymentIntegrations/fetch_payment_integrations_failure',
}

const fetchPaymentIntegrationsStart = (): StartAction => ({
  type: PaymentIntegrationActionType.FETCH_PAYMENT_INTEGRATIONS_START,
  payload: null,
});

const fetchPaymentIntegrationsSuccess = (payload: any): SuccessAction => ({
  type: PaymentIntegrationActionType.FETCH_PAYMENT_INTEGRATIONS_SUCCESS,
  payload,
});

const fetchPaymentIntegrationsFailure = (error: string): FailureAction => ({
  type: PaymentIntegrationActionType.FETCH_PAYMENT_INTEGRATIONS_FAILURE,
  payload: { error },
});

export const fetchPaymentIntegrations = () =>
  createApiAction(
    {
      hostname: paymentIntegrationHostname,
      path: '/api/v1/connections',
      method: 'GET',
    },
    fetchPaymentIntegrationsStart,
    fetchPaymentIntegrationsSuccess,
    fetchPaymentIntegrationsFailure
  );
