import React, { useMemo } from 'react';
import { Box, TableCell, Typography, TableRow } from '@mui/material';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';

import { RopItem } from '../../../types';
import { useCustomSubRowStyle } from './styled';
import { resolveReorderBarWidths } from '../helpers';

import { IconNames, colorPalette } from 'ui/theme';
import Icon from 'ui/components/Icon/Icon';

export const CustomSubRow: React.FC<RenderCustomRowProps<RopItem>> = (
  props
) => {
  const { row } = props;

  const classes = useCustomSubRowStyle();

  const vendorText = useMemo(() => {
    const vendorItem = row.item
      ? row.item.vendorItems.find((vi) => vi.defaultFlag)
      : null;

    if (!vendorItem) {
      return '';
    }

    const leadTime = vendorItem.leadTime;
    const vendorName = vendorItem.vendor ? vendorItem.vendor.name : '';

    if (!leadTime) {
      return vendorName;
    }

    return `${vendorName} \u2022 Lead Time: ${leadTime} days `;
  }, [row.item]);

  const { safetyStockWidth, leadTimeWidth, orderIntervalWidth, notifyAtWidth } =
    useMemo(() => resolveReorderBarWidths(row), [row]);

  return (
    <TableRow>
      <TableCell>
        <Box pl={8} height={42}>
          <Typography variant="subtitle1" color="textSecondary">
            {vendorText}
          </Typography>
        </Box>
      </TableCell>
      <TableCell colSpan={6}>
        <Box className={classes.statusBar}>
          <Box
            className={classes.statusSafety}
            width={`${safetyStockWidth}%`}
            height={12}
          />
          <Box
            className={classes.statusLead}
            width={`${leadTimeWidth}%`}
            height={12}
          />
          <Box
            className={classes.statusInterval}
            width={`${orderIntervalWidth}%`}
            height={12}
          />
          <Box className={classes.notifyAt} left={`${notifyAtWidth}%`}>
            {row.notifyAt && row.notifyAt > 0 ? (
              <Icon
                name={IconNames.Bell}
                color={colorPalette.redesign.contentPrimary}
                style={{ color: colorPalette.redesign.contentSecondary }}
              />
            ) : null}
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};
