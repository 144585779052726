import moment from 'moment';
import _ from 'lodash';

import { Pick, PickItem, PickItemStatus, PickStatus } from 'services/picking';
import { TrackingDataTypes } from 'services/settings/tracking';
import { TrackingGroup, TrackingInfo } from 'services/inventory';

const getDateTrackingTypeIds = (pickItem: PickItem) => {
  let dateTrackingTypeIds: number[] = [];
  if (pickItem.item) {
    dateTrackingTypeIds = pickItem.item.itemTrackingTypeList
      .filter(
        (t) =>
          _.get(t, 'trackingType.trackingDataType', null) ===
          TrackingDataTypes.Date
      )
      .map((t) => t.trackingTypeId);
  }

  return dateTrackingTypeIds;
};

const trackingInfoIsExpired = (trackingInfo: TrackingInfo) => {
  const today = moment().startOf('day');
  const value = trackingInfo.value;

  if (!value) {
    return true;
  }

  return moment(value).isBefore(today);
};

const trackingGroupsHaveExpiredItems = (
  trackingGroups: TrackingGroup[],
  dateTrackingTypeIds: number[]
) => {
  return trackingGroups.some((trackingGroup) => {
    const expiredInfos = trackingGroup.trackingInfoList.filter(
      (trackingInfo) =>
        dateTrackingTypeIds.includes(trackingInfo.trackingTypeId) &&
        trackingInfoIsExpired(trackingInfo)
    );

    return expiredInfos.length > 0;
  });
};

export const pickHasExpiredItems = (pickItems: PickItem[]): boolean => {
  return pickItems.some((pickItem) => {
    const dateTrackingTypeIds = getDateTrackingTypeIds(pickItem);

    return trackingGroupsHaveExpiredItems(
      pickItem.trackingGroupList,
      dateTrackingTypeIds
    );
  });
};

export const shouldShowCommit = (
  numberOfAvailableItems: number,
  pickStatus: PickStatus
) =>
  numberOfAvailableItems > 0 &&
  (pickStatus === PickStatus.Open ||
    pickStatus === PickStatus.Started ||
    pickStatus === PickStatus.PartiallyPicked);

export const shouldShowFinish = (pickStatus: PickStatus) =>
  pickStatus !== PickStatus.Finished;

export const shouldShowVoid = (pickStatus: PickStatus) =>
  pickStatus !== PickStatus.Open;

export const shouldShowStart = (pick: Pick) =>
  pick.pickItems.every(
    (i) =>
      i.status === PickItemStatus.Available || i.status === PickItemStatus.Short
  );

export const shouldShowUnGroup = (pick: Pick) => pick.number[0] === 'G';

export const sortPickItemsByPickFromLocation = (
  items: PickItem[]
): PickItem[] => {
  const pickFromLocationItems = items.filter((item) => item.pickFromLocation);
  const nonPickFromLocationItems = items.filter(
    (item) => !item.pickFromLocation
  );

  pickFromLocationItems.sort((a, b) => {
    const sortNumberA = a.pickFromLocation!.sortNumber ?? Infinity;
    const sortNumberB = b.pickFromLocation!.sortNumber ?? Infinity;
    return sortNumberA - sortNumberB || items.indexOf(a) - items.indexOf(b);
  });

  return [...pickFromLocationItems, ...nonPickFromLocationItems];
};
