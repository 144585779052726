import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Divider, Switch, Typography } from '@mui/material';
import { TextField } from 'ui/components/TextField/TextField';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { ClassAutocomplete } from 'ui/components/Autocomplete/ClassesAutocomplete';
import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { TaxRateVariants } from 'ui/components/Autocomplete/TaxRatesAutocomplete/types';
import { TaxRatesAutocomplete } from 'ui/components/Autocomplete/TaxRatesAutocomplete';
import { Item, ItemType, ShippingTerm, SHIPPING_TERMS } from 'services/items';
import { fetchLocations, Location } from 'services/locations';
import { SalesOrderStatus } from 'services/salesOrders';
import { fetchTaxRates, TaxRate } from 'services/taxRates';
import {
  ShippoReferenceField,
  shippoReferenceFields,
} from 'services/integrations/shipping/shippo';
import { fetchCustomFieldsAPI, ObjectType } from 'services/customFields';
import { ChannelType } from 'services/integrations/ecommerce';
import { Class } from 'services/classes';

import { MappingLocations } from '../MappingLocations';
import { SettingsFormProps } from '../../types';
import { useEcommerceCardStyle } from '../../../../styled';
import useEcommerceSettings from '../../../../useEcommerceSettings';
const OrderSettingsForm: React.FC<SettingsFormProps> = (props) => {
  const classes = useEcommerceCardStyle();
  const dispatch = useDispatch();

  const { index } = props;

  const [setting, setEcommerceSettings, errors, , , location, channelName] =
    useEcommerceSettings(index);

  const [orderSettings, setOrderSettings] = useState(setting);
  const [customFields, setCustomFields] = useState(shippoReferenceFields);
  const [isLoading, setIsLoading] = useState(false);

  const selectedShippingTerm = useMemo(
    () =>
      SHIPPING_TERMS.find((t) => t.id === orderSettings.order.shippingTerms) ||
      null,
    [orderSettings]
  );

  const selectedCustomField1 = useMemo(() => {
    return (
      customFields.find(
        (s) => s.key === orderSettings.orderExport.customField1
      ) || null
    );
  }, [customFields, orderSettings.orderExport.customField1]);

  const selectedCustomField2 = useMemo(() => {
    return (
      customFields.find(
        (s) => s.key === orderSettings.orderExport.customField2
      ) || null
    );
  }, [customFields, orderSettings.orderExport.customField2]);

  const selectedCustomField3 = useMemo(() => {
    return (
      customFields.find(
        (s) => s.key === orderSettings.orderExport.customField3
      ) || null
    );
  }, [customFields, orderSettings.orderExport.customField3]);

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchLocations());
    // @ts-ignore
    dispatch(fetchTaxRates());
  }, [dispatch]);

  useEffect(() => {
    if (orderSettings.id) {
      setEcommerceSettings(orderSettings);
    }
  }, [orderSettings, setEcommerceSettings, index]);

  useEffect(() => {
    // Fetch customFields and add them to state
    if (setting.channel.channel === ChannelType.ShipStation) {
      setIsLoading(true);
      (async () => {
        const fields = (await fetchCustomFieldsAPI({}, ObjectType.SalesOrder))
          .data;

        const cus = [...customFields];
        if (fields.length > 0) {
          fields.forEach((s) => {
            cus.push({
              key: 'customField.' + s.name,
              label: s.name!,
            });
          });

          setCustomFields(cus);
        }
      })();
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setting.channel.channel]);

  const handleAutoCompleteChange = useCallback(
    (
        name:
          | 'shippingTerms'
          | 'location'
          | 'shippingItem'
          | 'status'
          | 'paymentMethod'
          | 'taxItem'
      ) =>
      (v: ShippingTerm | Item | Location | TaxRate | null) => {
        if (name === 'status' || name === 'paymentMethod') {
          setOrderSettings((old) => ({
            ...old,
            order: { ...old.order, [name]: v || null },
          }));
          return;
        }

        setOrderSettings((old) => ({
          ...old,
          order: { ...old.order, [name]: v ? v.id : null },
        }));
      },
    [setOrderSettings]
  );

  const handleAutoCompleteChangeOrderExport = useCallback(
    (name: 'customField1' | 'customField2' | 'customField3' | 'status') =>
      (value: string | null) => {
        setOrderSettings((old) => ({
          ...old,
          orderExport: { ...old.orderExport, [name]: value },
        }));
      },
    [setOrderSettings]
  );

  const handleCheckboxChange = useCallback(
    (type: 'order' | 'orderExport') =>
      (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (type === 'order') {
          setOrderSettings((old) => ({
            ...old,
            order: {
              ...old.order,
              [event.target.name]: checked,
              paymentMethod: checked ? old.order.paymentMethod : null,
            },
          }));
        } else {
          setOrderSettings((old) => ({
            ...old,
            orderExport: {
              ...old.orderExport,
              [event.target.name]: checked,
            },
          }));
        }
      },
    [setOrderSettings]
  );

  const handleClassChange = (accountingClass: Class | null) => {
    setOrderSettings((old) => ({
      ...old,
      order: {
        ...old.order,
        class: accountingClass ? accountingClass.id : null,
      },
    }));
  };

  const handleTextFieldChange = (e: any) => {
    const newValue = e.target.value;
    setOrderSettings((old) => ({
      ...old,
      order: {
        ...old.order,
        prefix: newValue === '' ? null : newValue,
      },
    }));
  };

  const orderStatusItems = useMemo(() => {
    if (setting.channel.channel === ChannelType.Shopify) {
      return [
        SalesOrderStatus.Estimate,
        SalesOrderStatus.Issued,
        SalesOrderStatus.Fulfilled,
      ];
    }

    return [SalesOrderStatus.Estimate, SalesOrderStatus.Issued];
  }, [setting.channel.channel]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      height="100%"
      overflow="auto"
    >
      <>
        <Box className={classes.modalRowExpandable}>
          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              {setting.channel.channel === ChannelType.ShipStation
                ? 'Order import'
                : 'Order settings'}
            </Typography>

            <Switch
              checked={orderSettings.order.enabled || false}
              onChange={handleCheckboxChange('order')}
              name="enabled"
              color="primary"
            />
          </Box>
        </Box>

        <Divider />
      </>

      {orderSettings.order.enabled && (
        <>
          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Default location
            </Typography>

            <Box width="40%">
              <LocationsAsyncAutocomplete
                placeholder="Select Location"
                value={orderSettings.order.location}
                onChange={(v: Location | null) =>
                  handleAutoCompleteChange('location')(v)
                }
                error={!!errors.location}
                parentId={null}
                companyWide={false}
              />
            </Box>
          </Box>

          <Divider />

          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Sales tax item (Flat Rate)
            </Typography>

            <Box width="40%">
              <TaxRatesAutocomplete
                placeholder="Sales Tax"
                taxRateVariant={TaxRateVariants.FlatRate}
                value={orderSettings.order.taxItem}
                onChange={(v: TaxRate | null) =>
                  handleAutoCompleteChange('taxItem')(v)
                }
                error={!!errors.taxItem}
              />
            </Box>
          </Box>

          <Divider />

          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Shipping Item
            </Typography>

            <Box width="40%">
              <ItemsAutocomplete
                onChange={(v: Item | null) =>
                  handleAutoCompleteChange('shippingItem')(v)
                }
                value={orderSettings.order.shippingItem}
                placeholder="Select Item"
                itemTypes={[ItemType.Shipping]}
                error={!!errors.shippingItem}
                onlySaleItems
              />
            </Box>
          </Box>

          <Divider />

          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Shipping Terms
            </Typography>

            <Box width="40%">
              <Autocomplete
                options={SHIPPING_TERMS}
                value={selectedShippingTerm}
                getOptionLabel={(option: ShippingTerm) => option.name}
                onChange={(e: React.ChangeEvent<{}>, v: ShippingTerm | null) =>
                  handleAutoCompleteChange('shippingTerms')(v)
                }
                placeholder="Shipping Terms"
                error={!!errors.shippingTerms}
              />
            </Box>
          </Box>

          <Divider />

          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Order Status
            </Typography>

            <Box width="40%">
              <Autocomplete
                options={orderStatusItems}
                value={orderSettings.order.status}
                onChange={(e: React.ChangeEvent<{}>, v: any) =>
                  handleAutoCompleteChange('status')(v)
                }
                placeholder="Order Status"
                error={!!errors.status}
              />
            </Box>
          </Box>

          <Divider />

          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Accounting Class
            </Typography>

            <Box width="40%">
              <ClassAutocomplete
                value={orderSettings.order.class}
                onChange={handleClassChange}
                placeholder="Select a class"
              />
            </Box>
          </Box>

          <Divider />

          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Use {channelName} order number as Fishbowl order number
            </Typography>

            <Switch
              checked={orderSettings.order.useNumber}
              onChange={handleCheckboxChange('order')}
              name="useNumber"
              color="primary"
            />
          </Box>

          {orderSettings.order.useNumber && (
            <>
              <Divider />
              <Box className={classes.modalRow}>
                <Typography variant="body1" color="textPrimary">
                  Prefix
                </Typography>

                <Box width="40%">
                  <TextField
                    type="text"
                    placeholder="Prefix"
                    name="prefix"
                    autoComplete="nope"
                    value={orderSettings.order.prefix}
                    onChange={handleTextFieldChange}
                    inputProps={{
                      maxLength: 5,
                    }}
                  />
                </Box>
              </Box>
            </>
          )}

          <Divider />

          <Box className={classes.modalRowExpandable}>
            <Box className={classes.modalRow}>
              <Typography variant="body1" color="textPrimary">
                Create payment record in Fishbowl
              </Typography>

              <Switch
                checked={orderSettings.order.createPayments}
                onChange={handleCheckboxChange('order')}
                name="createPayments"
                color="primary"
              />
            </Box>
          </Box>
        </>
      )}

      <Divider />

      {setting.channel.channel === ChannelType.ShipStation && (
        <Box className={classes.modalRowExpandable}>
          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Order export
            </Typography>

            <Switch
              checked={orderSettings.orderExport.enabled}
              onChange={handleCheckboxChange('orderExport')}
              name="enabled"
              color="primary"
            />
          </Box>
        </Box>
      )}

      <Divider />

      {orderSettings.orderExport.enabled && (
        <>
          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Order Status
            </Typography>

            <Box width="40%">
              <Autocomplete
                options={['Issued', 'Picked', 'Packed']}
                value={orderSettings.orderExport.status}
                onChange={(e: React.ChangeEvent<{}>, v: any) =>
                  handleAutoCompleteChangeOrderExport('status')(v)
                }
                placeholder="Order Status"
                error={!orderSettings.orderExport.status}
              />
            </Box>
          </Box>

          <Divider />
          <MappingLocations
            identifier="orderExport"
            classes={classes}
            mappingLocations={orderSettings.orderExport.locations}
            errors={errors}
            setEcommerceSettings={setOrderSettings}
            location={location}
          />
          <Divider />
          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              {channelName} Custom field 1
            </Typography>

            <Box width="40%">
              <Autocomplete
                options={customFields}
                value={selectedCustomField1}
                onChange={(e: any, v: ShippoReferenceField | null) =>
                  handleAutoCompleteChangeOrderExport('customField1')(
                    v ? v.key : null
                  )
                }
                getOptionLabel={(s: ShippoReferenceField) => s.label}
                placeholder="Custom field 1"
                isLoading={isLoading}
              />
            </Box>
          </Box>
          <Divider />
          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              {channelName} Custom field 2
            </Typography>

            <Box width="40%">
              <Autocomplete
                options={customFields}
                value={selectedCustomField2}
                onChange={(e: any, v: ShippoReferenceField | null) =>
                  handleAutoCompleteChangeOrderExport('customField2')(
                    v ? v.key : null
                  )
                }
                getOptionLabel={(s: ShippoReferenceField) => s.label}
                placeholder="Custom field 2"
                isLoading={isLoading}
              />
            </Box>
          </Box>
          <Divider />
          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              {channelName} Custom field 3
            </Typography>

            <Box width="40%">
              <Autocomplete
                options={customFields}
                value={selectedCustomField3}
                onChange={(e: any, v: ShippoReferenceField | null) =>
                  handleAutoCompleteChangeOrderExport('customField3')(
                    v ? v.key : null
                  )
                }
                getOptionLabel={(s: ShippoReferenceField) => s.label}
                placeholder="Custom field 3"
                isLoading={isLoading}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default memo(OrderSettingsForm);
