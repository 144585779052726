import React from 'react';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { ConfirmRopValueItem, RopItem, RopWizardData } from './types';

export const getSteps = (activeStep: number = 0, classes: any = {}) => [
  {
    label: 'Location Groups',
    icon:
      activeStep > 0 ? (
        <RadioButtonCheckedIcon className={classes.activeIcon} />
      ) : (
        <RadioButtonUncheckedOutlinedIcon />
      ),
  },
  {
    label: 'Items',
    icon:
      activeStep > 1 ? (
        <RadioButtonCheckedIcon className={classes.activeIcon} />
      ) : (
        <RadioButtonUncheckedOutlinedIcon />
      ),
  },
  {
    label: 'Confirm ROP Values',
    icon:
      activeStep > 2 ? (
        <RadioButtonCheckedIcon className={classes.activeIcon} />
      ) : (
        <RadioButtonUncheckedOutlinedIcon />
      ),
  },
];

export const initialRopItem: RopItem = {
  id: null,
  item: null,
  alerts: [],
  leadTime: 0,
  notifyAt: 0,
  orderInterval: 0,
  safetyStock: 0,
};

export const initialRopWizardData: RopWizardData = {
  locationIds: [],
  chosenDates: {
    startDate: new Date(),
    endDate: new Date(),
  },
  ropItems: [],
  defaultValues: initialRopItem,
  useVendorLeadTime: false,
};

export const initialConfirmRopValueItem: ConfirmRopValueItem = {
  alerts: [],
  calculatedConsumptionRate: 0,
  calculatedMax: 0,
  calculatedMin: 0,
  consumptionRate: 0,
  currentMax: 0,
  currentMin: 0,
  currentNotifyAt: 0,
  forecast: 0,
  item: null,
  leadTime: 0,
  locationId: null,
  locationName: null,
  notifyAt: 0,
  orderInterval: 0,
  safetyStock: 0,
  storedConsumptionRate: 0,
};
