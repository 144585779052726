import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { Page } from 'ui/components/Page/Page';
import { CardNavigation } from './components';
import { Routes } from '../../navigation';
import { fetchUoms } from 'services/uoms';

import { useEcommercePageStyle } from './styled';
import { EcommercePageCmp, EcommercePageProps } from './types';
import { EcommerceProvider } from './EcommerceProvider';
import { Ecommerce } from './components/Ecommerce';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EcommercePage: EcommercePageCmp = (props: EcommercePageProps) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const styles = useEcommercePageStyle();

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchUoms());
  }, [dispatch]);

  return (
    <Page direction="row">
      <CardNavigation search={search} setSearch={setSearch} />
      <Box className={styles.container}>
        <EcommerceProvider>
          <Ecommerce />
        </EcommerceProvider>
      </Box>
    </Page>
  );
};

EcommercePage.route = Routes.EcommercePage;

export default EcommercePage;
