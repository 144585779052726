import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useAccountingSettingsPageStyle = makeStyles({
  cardContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  companyNameContainer: {
    width: '600px',
    marginBottom: '14px',
  },
  companyName: {
    fontWeight: 'bold',
  },
});

export const useCardStyle = makeStyles((theme: Theme) => ({
  paper: {
    width: '600px',
    padding: 0,
  },
  container: {
    paddingBottom: theme.spacing(6),
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 56,
    padding: theme.spacing(2),
  },
  text: {
    width: '40%',
    textAlign: 'right',
  },
  titleContainer: {
    width: '40%',
  },
  link: {
    width: '40%',
    textAlign: 'right',
    cursor: 'pointer',
    ...theme.typography.body1,
  },
  exportButton: {
    marginLeft: '16px',
    marginRight: '16px',
  },
}));
