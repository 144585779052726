import React, { memo, useMemo } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { TableCell, Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';

import { useReceiveWizardRowStyle } from './styled';
import { ReceiptItemReceive, ReceiptItemType } from 'services/receiving';
import { isValidReceiptItemReceives } from '../../../../helpers';

const ReconcileWizardRow: React.FC<RenderCustomRowProps<ReceiptItemReceive>> = (
  props
) => {
  const { row } = props;

  const classes = useReceiveWizardRowStyle();

  const resolvedQuantity = useMemo(() => {
    return `${row.quantity}/${row.receiptItem.quantity}`;
  }, [row]);

  const itemName = useMemo(() => {
    if (row.receiptItem.receiptItemType === ReceiptItemType.Purchase) {
      return _.get(row, 'receiptItem.purchaseOrderItem.name', '');
    }

    return _.get(row, 'receiptItem.salesOrderItem.name', '');
  }, [row]);

  // Summary Row
  if (row.receiptItemId === -1) {
    return (
      <TableCell colSpan={2}>
        <Box display="flex" alignItems="center">
          <RadioButtonUncheckedIcon className={classes.checkIcon} />
          <Box>
            <Typography>Summary</Typography>
            <Typography variant="subtitle2">
              Review Receive Information
            </Typography>
          </Box>
        </Box>
      </TableCell>
    );
  }

  return (
    <>
      <TableCell className={classes.firstColumnWidth}>
        <Box display="flex" alignItems="center">
          {isValidReceiptItemReceives(row) ? (
            <CheckCircleIcon
              className={clsx({
                [classes.checkIcon]: true,
                [classes.checkIconSuccess]:
                  row.quantity === _.get(row, 'receiptItem.quantity', 0),
                [classes.checkIconPartial]:
                  row.quantity !== _.get(row, 'receiptItem.quantity', 0),
              })}
            />
          ) : (
            <RadioButtonUncheckedIcon className={classes.checkIcon} />
          )}
          <Box>
            <Typography>{itemName}</Typography>
            <Typography variant="subtitle2">
              {_.get(row, 'receiptItem.item.description')}
            </Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell>{resolvedQuantity}</TableCell>
    </>
  );
};

export default memo(ReconcileWizardRow);
