import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useEcommercePageStyle = makeStyles(() => ({
  container: {
    paddingBottom: '64px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const useEcommerceCardStyle = makeStyles((theme: Theme) => ({
  paper: {
    width: '600px',
    padding: 0,
    border: '1px solid rgba(0, 0, 0, 0.12);',
  },
  container: {
    display: 'flex',
    alignItems: 'top',
    flexDirection: 'column',
    width: '100%',
  },
  cardRow: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 56,
    padding: theme.spacing(2),
    borderBottom: '1px solid rgba(0, 0, 0, 0.12);',
    '&:last-of-type:': {
      borderBottom: 'none',
    },
  },
  cardRowClickable: {
    cursor: 'pointer',
    transitionDuration: '0.2s',
    transitionProperty: 'background-color',
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    '&:hover': {
      backgroundColor: '#f1f1f1',
    },
  },
  modalRow: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 56,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  modalRowExpandable: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
  },
  modalRowInner: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

export const useEcommerceWizardStyle = makeStyles((theme: Theme) => ({
  paper: {
    width: '832px',
    padding: 0,
  },
  container: {
    paddingBottom: '64px',
  },
  sidebar: {
    width: 200,
    borderRight: `1px solid ${colorPalette.applicationDarkGray10}`,
  },
  content: {
    width: '100%',
    flex: 1,
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    borderTop: `1px solid ${colorPalette.applicationDarkGray10}`,
  },
  tabs: {
    backgroundColor: 'transparent',
    overflow: 'auto',
    overflowX: 'hidden',
    width: '100%',
    '& .MuiTabs-indicator': { backgroundColor: 'transparent' },
    '& .MuiTabs-scroller': {
      overflow: 'unset !important',
    },
  },
  tab: {
    minHeight: 56,
    height: 56,
    borderRadius: 2,
    textTransform: 'none',
    borderBottom: `1px solid ${colorPalette.applicationDarkGray10}`,
    '&.Mui-selected': {
      backgroundColor: colorPalette.applicationLightGray,
      border: 'none',
      '& svg': {
        fill: colorPalette.applicationDarkGray80,
      },
    },
    '&.MuiTab-labelIcon': {
      paddingTop: 6,
    },
    '& .MuiTab-wrapper': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& svg': {
        marginBottom: '0 !important',
        marginRight: theme.spacing(2),
        fill: colorPalette.applicationMediumGray,
      },
    },
  },
  cardRow: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 56,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  cardRowExpandable: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
  },
  cardRowInner: {
    display: 'flex',
    flexShrink: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  activeIcon: {
    color: 'green',
    fill: `${colorPalette.statusGreen} !important`,
  },
}));
