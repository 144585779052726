import { ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Column } from 'ui/components/Table/ItemsTable/types';
import { MenuItem, MenuItemVariant } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';
import { DisplaySalesOrder } from 'services/salesOrders';
import { PermissionType } from 'services/permissions';
import { Dates } from 'helpers';

import { SalesOrderPageAction } from './types';

export const SALES_ORDER_COLUMNS = (
  date: Dates,
  hasConnection: boolean = false
): Column[] => {
  const resolveDateColumn = (): Column => {
    switch (date) {
      case Dates.DateLastModified:
        return {
          title: 'Date Last Modified',
          field: Dates.DateLastModified,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      case Dates.DateIssued:
        return {
          title: 'Date Issued',
          field: Dates.DateIssued,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      default:
        return {
          title: 'Date Created',
          field: Dates.DateCreated,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
    }
  };

  const col: Column[] = [
    {
      title: 'No.',
      field: 'number',
      type: ColumnTypes.string,
      width: 100,
      sortable: true,
    },
    {
      title: 'Stock',
      type: ColumnTypes.renderByCustomRow,
      field: 'stockStatus',
      fitWidth: true,
    },
    {
      title: 'Status',
      type: ColumnTypes.renderByCustomRow,
      field: 'salesOrderStatus',
      sortable: true,
      fitWidth: true,
    },
    {
      title: 'Customer',
      type: ColumnTypes.renderByCustomRow,
      field: 'customer.name',
      sortable: true,
    },
    {
      title: 'Sales Rep',
      type: ColumnTypes.renderByCustomRow,
      field: 'representative',
      sortable: true,
    },
    {
      title: 'Location',
      type: ColumnTypes.renderByCustomRow,
      field: 'location.name',
      sortable: true,
    },
    {
      title: 'Source',
      type: ColumnTypes.renderByCustomRow,
      field: '',
      searchable: false,
    },
    resolveDateColumn(),
    {
      title: 'Order Type',
      type: ColumnTypes.renderByCustomRow,
      field: '',
      searchable: false,
    },
    {
      title: 'Total',
      type: ColumnTypes.renderByCustomRow,
      align: 'right',
      field: 'grandTotal',
      searchable: false,
      sortable: true,
    },
    {
      title: 'Paid Amount',
      type: ColumnTypes.renderByCustomRow,
      width: 150,
      align: 'right',
      field: 'paymentTotal',
      searchable: false,
      sortable: true,
    },
    {
      title: '',
      type: ColumnTypes.renderByCustomRow,
      width: '50px',
    },
  ];

  if (!hasConnection) {
    return col.filter((c) => c.title !== 'Source');
  }

  return col;
};

export const SALES_ORDER_DETAILS_COLUMNS: Column[] = [
  {
    title: 'No.',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Customer',
    field: 'customer.name',
    type: ColumnTypes.string,
  },
];

export const SALES_ORDER_DETAILS_COLUMNS_WITH_STATUS: Column[] = [
  {
    title: 'No.',
    type: ColumnTypes.renderByCustomRow,
  },
  { title: 'Status', type: ColumnTypes.renderByCustomRow },
  {
    title: 'Customer',
    field: 'customer.name',
    type: ColumnTypes.string,
  },
];

export const createActionBarOptions = (
  onDuplicateClick: () => void,
  onDeleteClick: () => void,
  salesOrder: DisplaySalesOrder
): MenuItem[] => [
  {
    label: 'Duplicate Order',
    dataQa: `${salesOrder.number}-duplicate-order`,
    onOptionClick: onDuplicateClick,
    icon: IconNames.FBODuplicateSmall,
    permissions: [
      PermissionType.SalesOrderEdit,
      PermissionType.SalesOrderCreate,
    ],
    separateAfter: true,
  },
  {
    label: 'Delete Order',
    dataQa: `${salesOrder.number}-delete-order`,
    onOptionClick: onDeleteClick,
    icon: IconNames.FBODeleteSmall,
    permissions: salesOrder.deleted
      ? [PermissionType.Undelete]
      : [PermissionType.SalesOrderDelete],
    variant: MenuItemVariant.Danger,
  },
];

export const createSOSearchActionBarOptions = (
  onActionClick: (actionType: SalesOrderPageAction, date?: Dates) => void,
  salesOrdersCount: number
): MenuItem[] => [
  {
    label: 'Show Report',
    onOptionClick: () => onActionClick(SalesOrderPageAction.ShowReport),
    icon: IconNames.FBOReportSmall,
    separateAfter: true,
    dataQa: 'show-report-button',
  },
  {
    label: 'Import Orders',
    onOptionClick: () => onActionClick(SalesOrderPageAction.Import),
    icon: IconNames.FBOImport,
    separateAfter: true,
    permissions: [
      PermissionType.SalesOrderEdit,
      PermissionType.SalesOrderCreate,
    ],
    dataQa: 'import-orders-button',
  },
  {
    label: 'Export Orders',
    onOptionClick: () => onActionClick(SalesOrderPageAction.Export),
    icon: IconNames.FBOExport,
    separateAfter: true,
    disabled: !salesOrdersCount,
    dataQa: 'export-orders-button',
  },
  {
    label: 'Custom Fields',
    icon: IconNames.FBOReport,
    separateAfter: true,
    onOptionClick: () => onActionClick(SalesOrderPageAction.CustomFields),
    permissions: [
      PermissionType.SalesOrderEdit,
      PermissionType.SalesOrderCreate,
    ],
    dataQa: 'sale-order-custom-field',
  },
  {
    label: 'Change Dates',
    icon: IconNames.FBORecycleSmall,
    dataQa: 'change-dates-button',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(SalesOrderPageAction.ChangeDate, Dates.DateCreated),
        dataQa: 'date-created-button',
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(
            SalesOrderPageAction.ChangeDate,
            Dates.DateLastModified
          ),
        dataQa: 'date-last-modified-button',
        separateAfter: true,
      },
      {
        label: 'Date Issued',
        onOptionClick: () =>
          onActionClick(SalesOrderPageAction.ChangeDate, Dates.DateIssued),
        dataQa: 'date-issued-button',
      },
    ],
  },
];
