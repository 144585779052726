import React from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';

import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const cardPreviousExportColumns: (format: string) => Column[] = (
  format
) => [
  {
    type: ColumnTypes.custom,
    field: 'date',
    title: 'Date',
    render: (row) => {
      return <Typography>{moment(row.dateCrated).format(format)}</Typography>;
    },
  },
  {
    type: ColumnTypes.string,
    field: 'ledger.note',
    title: 'Details',
  },
  {
    type: ColumnTypes.string,
    field: 'ledger.type',
    title: 'Type',
  },
  {
    type: ColumnTypes.string,
    field: 'results',
    title: 'Results',
  },
  {
    type: ColumnTypes.string,
    field: 'type',
    title: 'Status',
  },
];
