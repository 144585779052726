import React, { memo, useMemo, useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Popover, Grid } from '@mui/material';
import _ from 'lodash';

import {
  formatTypes,
  USCountryString,
  useCurrencyFormatter,
  useGetCurrencySymbol,
} from 'helpers';
import { RenderCustomFooterProps } from 'ui/components/Table/ItemsTable/types';
import { MultiFormatInput } from 'ui/components/TextField/MultiFormatInput';
import { TaxRatesAutocomplete } from 'ui/components/Autocomplete/TaxRatesAutocomplete';
import { TaxRateVariants } from 'ui/components/Autocomplete/TaxRatesAutocomplete/types';
import { ClassAutocomplete } from 'ui/components/Autocomplete/ClassesAutocomplete';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { TaxRate } from 'services/taxRates';
import {
  calculateTotals,
  initialSalesOrder,
  SalesOrderItemTypes,
  SalesOrderStatus,
} from 'services/salesOrders';
import { showNotification } from 'services/api';
import { Class, getClasses } from 'services/classes';
import { activeUserHasPermission } from 'services/user/redux';
import {
  getSettingsCompany,
  getSettingsCompanyCountry,
} from 'services/settings/company';
import { getSettingsSalesOrder } from 'services/settings/salesOrders';

import {
  ItemFooterData,
  ItemFooterActions,
  Discount,
  DiscountTypes,
} from './types';
import {
  discountAnchorOrigin,
  discountTransformOrigin,
  totalAnchorOrigin,
  totalTransformOrigin,
} from './consts';
import {
  editSalesOrderPermissions,
  shouldShowSave,
  calculateMultiCurrencyFields,
} from '../../../../helpers';
import { calculatedMarginAndMarkup } from './helpers';

import { IconNames } from 'ui/theme';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const PopoverItem: React.FC<{
  label: string;
  value: string;
  last?: boolean;
  dataQa?: string;
}> = (props) => {
  const { label, value, last, dataQa } = props;

  return (
    <Box display="flex" justifyContent="space-between" pb={last ? 0 : 3}>
      <Typography variant="body2" color="textSecondary">
        {label}
      </Typography>
      <Typography data-qa={dataQa}>{value}</Typography>
    </Box>
  );
};

const FBOTableFooter = (
  props: RenderCustomFooterProps<ItemFooterData, ItemFooterActions>
) => {
  const { footerData, onFooterAction } = props;

  const currencyFormatter = useCurrencyFormatter();
  const currencySymbol = useGetCurrencySymbol();

  const fieldsDisabled =
    _.get(footerData, 'salesOrder.status', null) ===
      SalesOrderStatus.Fulfilled ||
    _.get(footerData, 'salesOrder.status', null) ===
      SalesOrderStatus.Cancelled ||
    _.get(footerData, 'salesOrder.status', null) === SalesOrderStatus.Issued;

  const editPermission = editSalesOrderPermissions(
    _.get(footerData, 'salesOrder', initialSalesOrder)
  );

  const accountingClasses = useSelector(getClasses);
  const canClick = useSelector(activeUserHasPermission(editPermission));
  const { taxJarToken } = useSelector(getSettingsCompany);
  const soSettings = useSelector(getSettingsSalesOrder);

  const [totalEl, setTotalEl] = useState<null | HTMLElement>(null);

  const [multiTotalEl, setMultiTotalEl] = useState<null | HTMLElement>(null);
  const [discountEl, setDiscountEl] = useState<null | HTMLElement>(null);
  const [discountPopupIndex, setDiscountPopupIndex] = useState<0 | 1>(0);
  const [discountPopupValue, setDiscountPopupValue] = useState<number | null>(
    0
  );
  const [taxExemptWarningModalVisible, setTaxExemptWarningModalVisible] =
    useState(false);
  const [selectedTax, setSelectedTax] = useState<TaxRate | null>(
    _.get(footerData, 'salesOrder.salesTax', null)
  );

  const activeMulticurrencyCode = _.get(
    footerData,
    'salesOrder.customer.currency.code',
    null
  );

  const isTotalPopVisible = useMemo(() => Boolean(totalEl), [totalEl]);
  const isMultiTotalPopVisible = useMemo(
    () => Boolean(multiTotalEl),
    [multiTotalEl]
  );
  const isDiscountPopVisible = useMemo(() => Boolean(discountEl), [discountEl]);

  const disableCalculateTax = useMemo(() => {
    return footerData && shouldShowSave(footerData.salesOrder.status);
  }, [footerData]);

  const canAddDiscount =
    canClick &&
    (_.get(footerData, 'salesOrder.status', null) === SalesOrderStatus.Issued ||
      _.get(footerData, 'salesOrder.status', null) ===
        SalesOrderStatus.Estimate);

  const validationErrors = useMemo(() => {
    return footerData ? footerData.validationErrors : {};
  }, [footerData]);

  const selectedAccountingClass = useMemo(() => {
    return (
      accountingClasses.find(
        (c) => c.id === _.get(footerData, 'salesOrder.accountingClassId', null)
      ) || null
    );
  }, [accountingClasses, footerData]);

  const taxTotal = useMemo(() => {
    return footerData!.salesOrder.salesItems.reduce((total, item) => {
      switch (item.salesOrderItemType) {
        case SalesOrderItemTypes.Bundle:
        case SalesOrderItemTypes.BundleCreditReturn:
        case SalesOrderItemTypes.Sale:
        case SalesOrderItemTypes.DropShip:
        case SalesOrderItemTypes.CreditReturn:
        case SalesOrderItemTypes.MiscSale:
        case SalesOrderItemTypes.MiscReturn:
          if (!item.deleted) {
            return total + item.taxTotal;
          }
      }
      return total;
    }, 0);
  }, [footerData]);

  const discount: Discount = useMemo(() => {
    if (footerData) {
      return {
        value: footerData.salesOrder.orderLevelDiscountPercent
          ? footerData.salesOrder.orderLevelDiscountPercent
          : footerData.salesOrder.orderLevelDiscountFlatAmount
          ? footerData.salesOrder.orderLevelDiscountFlatAmount
          : 0,
        type: footerData.salesOrder.orderLevelDiscountPercent
          ? DiscountTypes.Percent
          : DiscountTypes.FlatRate,
      };
    }
    return { value: 0, type: DiscountTypes.Percent };
  }, [footerData]);

  // price without taxRates
  const subTotal = useMemo(() => {
    return footerData!.salesOrder.salesItems.reduce(
      (acc, item) => {
        switch (item.salesOrderItemType) {
          case SalesOrderItemTypes.FlatTaxRate:
            if (!item.deleted && item.price) {
              acc.homeCurrencySubtotal = acc.homeCurrencySubtotal + item.price;
              acc.multiCurrencySubTotal =
                acc.multiCurrencySubTotal +
                item.price * (item.exchangeRate ?? 1);
            }
            break;
          case SalesOrderItemTypes.Bundle:
          case SalesOrderItemTypes.BundleCreditReturn:
          case SalesOrderItemTypes.Sale:
          case SalesOrderItemTypes.CreditReturn:
          case SalesOrderItemTypes.DropShip:
          case SalesOrderItemTypes.MiscSale:
          case SalesOrderItemTypes.MiscReturn:
            if (!item.deleted && item.price && item.quantity) {
              acc.homeCurrencySubtotal =
                acc.homeCurrencySubtotal + item.subTotal;
            }

            if (!item.deleted && item.multiCurrencyItemPrice && item.quantity) {
              acc.multiCurrencySubTotal += item.multiCurrencySubTotal ?? 0;
            }

            if (!item.deleted && item.cost && item.quantity) {
              acc.avarageCostTotal =
                acc.avarageCostTotal + item.cost * item.quantity;
            }
        }

        return acc;
      },
      {
        homeCurrencySubtotal: 0,
        multiCurrencySubTotal: 0,
        avarageCostTotal: 0,
      }
    );
  }, [footerData]);

  const discountTotal = useMemo(() => {
    if (discount.type === DiscountTypes.Percent) {
      return subTotal.homeCurrencySubtotal * (discount.value / 100);
    }

    return discount.value;
  }, [discount, subTotal.homeCurrencySubtotal]);

  const grandTotal = useMemo(() => {
    return subTotal.homeCurrencySubtotal + taxTotal - discountTotal;
  }, [discountTotal, taxTotal, subTotal.homeCurrencySubtotal]);

  const multiCurrencyData = useMemo(() => {
    const exchangeRate = _.get(footerData, 'salesOrder.exchangeRate', null);

    const multiCurrencyDiscountTotal = discountTotal * exchangeRate;

    const multiCurrencyGrandTotal = grandTotal * exchangeRate;

    const multiCurrencyTaxTotal = taxTotal * exchangeRate;

    return {
      multiCurrencyDiscountTotal,
      multiCurrencyGrandTotal,
      multiCurrencyTaxTotal,
    };
  }, [footerData, discountTotal, grandTotal, taxTotal]);
  const getCountry = useSelector(getSettingsCompanyCountry);
  const [hideTax, setHideTax] = useState(true);

  useEffect(() => {
    if (getCountry && getCountry === USCountryString) setHideTax(false);
  }, [getCountry]);

  useEffect(() => {
    setDiscountPopupValue(discount.value);
    setDiscountPopupIndex(discount.type === DiscountTypes.Percent ? 0 : 1);
  }, [subTotal.homeCurrencySubtotal, taxTotal, discount]);

  // Show toast if the location is empty
  useEffect(() => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (validationErrors.locationId) {
      showNotification('Please fill in the location', { variant: 'error' });
    }
  }, [validationErrors]);

  const handleIconClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setTotalEl(event.currentTarget);
    },
    []
  );

  const handleMultiIconClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setMultiTotalEl(event.currentTarget);
    },
    []
  );

  const handleDiscountClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      setDiscountEl(event.currentTarget);
    },
    []
  );

  const handleTotalPopClose = useCallback(() => {
    setTotalEl(null);
  }, []);

  const handleMultiTotalPopClose = useCallback(() => {
    setMultiTotalEl(null);
  }, []);

  const handleDiscountPopClose = useCallback(() => {
    setDiscountEl(null);
  }, []);

  const handleDiscountPopupChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setDiscountPopupValue(parseFloat(e.target.value));
    },
    []
  );

  const handleDiscountAmountTypeChanged = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const taxTypeValue = e.target.innerText === '%' ? 0 : 1;
      setDiscountPopupIndex(taxTypeValue);
    },
    []
  );

  const handleDiscountPopupApplyClick = useCallback(() => {
    if (onFooterAction) {
      if (discountPopupIndex === 0) {
        onFooterAction.setSalesOrder((old) => ({
          ...old,
          orderLevelDiscountPercent: discountPopupValue,
          orderLevelDiscountFlatAmount: null,
        }));
      } else {
        onFooterAction.setSalesOrder((old) => ({
          ...old,
          orderLevelDiscountFlatAmount: discountPopupValue,
          orderLevelDiscountPercent: null,
        }));
      }
    }
    setDiscountEl(null);
  }, [discountPopupValue, discountPopupIndex, onFooterAction]);

  const updateTax = useCallback(
    (value: TaxRate | null) => {
      if (onFooterAction) {
        const salesItemsUpdated = footerData
          ? footerData.salesOrder.salesItems.map((s) => {
              if (s.deleted || !s.taxable || soSettings.salesTaxId) {
                return s;
              }

              const basePrice = (s.price ?? 0) / (1 + (s.taxRate ?? 0));

              s.taxId = value ? value.id : null;
              s.tax = value;
              s.taxRate = value ? value.percentage ?? 0 : 0;
              s.price = !soSettings.priceIncludesTax
                ? s.price
                : basePrice * (1 + (s.taxRate ?? 0));

              return {
                ...s,
                ...calculateTotals(
                  s.price ?? 0,
                  s.quantity ?? 0,
                  s.discount ?? 0,
                  value ? value.percentage ?? 0 : 0,
                  soSettings.priceIncludesTax ?? false,
                  s.exchangeRate ?? 1
                ),
              };
            })
          : [];

        onFooterAction.setSalesOrder((old) => ({
          ...old,
          salesTaxId: value ? value.id : null,
          salesTax: value,
          salesItems: salesItemsUpdated,
        }));
      }
    },
    [
      onFooterAction,
      footerData,
      soSettings.salesTaxId,
      soSettings.priceIncludesTax,
    ]
  );

  const handleTaxChange = useCallback(
    (value: TaxRate | null) => {
      if (onFooterAction) {
        setSelectedTax(value);
        if (
          footerData &&
          footerData.salesOrder.customer &&
          footerData.salesOrder.customer.taxExempt &&
          footerData.oldState.current &&
          footerData.oldState.current.salesTaxId !== (value ? value.id : -1)
        ) {
          setTaxExemptWarningModalVisible(true);
          return;
        }

        updateTax(value);
      }
    },
    [onFooterAction, footerData, updateTax]
  );

  const handleTaxExemptWarningModalContinue = useCallback(() => {
    setTaxExemptWarningModalVisible(false);
    updateTax(selectedTax);
  }, [updateTax, selectedTax]);

  const handleTaxExemptOnClose = useCallback(() => {
    setTaxExemptWarningModalVisible(false);
  }, []);

  const handleClassChange = useCallback(
    (value: Class | null) => {
      if (onFooterAction) {
        onFooterAction.setSalesOrder((old) => ({
          ...old,
          accountingClassId: value ? value.id : null,
        }));
      }
    },
    [onFooterAction]
  );

  const handleExchangeRateChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const exchangeRate = e.target.value ? parseFloat(e.target.value) : 1;

    if (onFooterAction) {
      onFooterAction.setSalesOrder((old) =>
        calculateMultiCurrencyFields(old, exchangeRate)
      );
    }
  };
  const salesOrderProfitCalculation = calculatedMarginAndMarkup(
    subTotal,
    discountTotal
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="100%"
        px={5}
      >
        <Grid container>
          <Grid
            item
            container
            xs={taxJarToken ? 8 : 7}
            spacing={1}
            alignItems="center"
          >
            <Grid item xs={taxJarToken ? 2 : 3}>
              <ClassAutocomplete
                label="Class"
                placeholder="Select class"
                onChange={handleClassChange}
                value={selectedAccountingClass}
                permissions={editPermission}
                disabled={fieldsDisabled}
                dataQa="sales-order-class"
              />
            </Grid>
            {!hideTax && (
              <Grid item xs={taxJarToken ? 2 : 3}>
                <TaxRatesAutocomplete
                  label="Tax"
                  value={_.get(footerData, 'salesOrder.salesTaxId', null)}
                  onChange={handleTaxChange}
                  disabled={fieldsDisabled}
                  permissions={editPermission}
                  taxRateVariant={TaxRateVariants.Percentage}
                  placeholder="Select tax"
                  dataQa="sales-order-tax"
                />
              </Grid>
            )}
            {!!taxJarToken && (
              <Grid item xs={2} style={{ marginLeft: '16px', paddingLeft: 0 }}>
                <FBOButton
                  variant="tertiary"
                  color="positive"
                  size="medium"
                  disabled={!taxJarToken || !disableCalculateTax}
                  onClick={onFooterAction?.handleCalculateTaxesClicked}
                  data-qa="sale-order-discount-apply"
                >
                  Calculate Taxes
                </FBOButton>
              </Grid>
            )}
            <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
              <Grid item xs={taxJarToken ? 1 : 2}>
                <CurrencyField
                  label="Currency"
                  value={1}
                  disabled={true}
                  allowNegative
                  decimalPlaces={5}
                />
              </Grid>
              <Grid item>
                <Typography>=</Typography>
              </Grid>
              <Grid item xs={2}>
                <CurrencyField
                  label="Rate"
                  value={_.get(footerData, 'salesOrder.exchangeRate', null)}
                  disabled={fieldsDisabled}
                  onChange={handleExchangeRateChange}
                  currencyCode={activeMulticurrencyCode}
                  decimalPlaces={5}
                />
              </Grid>
            </MultiCurrencyWrapper>
          </Grid>
          <Grid
            item
            container
            xs={taxJarToken ? 4 : 5}
            justifyContent="flex-end"
            alignItems="center"
            wrap="nowrap"
          >
            <Typography variant="body1">Discount:</Typography>
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="small"
              href="#"
              onClick={canAddDiscount ? handleDiscountClicked : _.noop}
              data-qa="sale-order-discount"
              sx={{
                textDecoration: 'underline',
                color: '#547089 !important',
              }}
            >
              {discount.type === DiscountTypes.FlatRate
                ? currencyFormatter(discount.value)
                : ''}
              {discount.type === DiscountTypes.Percent
                ? `${discount.value}%`
                : ''}
            </FBOButton>
            <Typography data-qa="sale-order-total">
              Total:
              <span
                style={{
                  fontWeight: 600,
                  fontSize: '14px',
                  marginLeft: '8px',
                  marginRight: '8px',
                }}
              >
                {currencyFormatter(grandTotal)}
              </span>
            </Typography>
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="small"
              icon={IconNames.UpwardCarat}
              onClick={handleIconClicked}
              data-qa="sale-order-total-arrow-up"
              sx={{ marginRight: '8px' }}
            />
            <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
              <Typography sx={{ whiteSpace: 'nowrap' }}>
                <span style={{ marginRight: '8px' }}>
                  {`${activeMulticurrencyCode} total:`}
                </span>
                <b>
                  {currencyFormatter(
                    multiCurrencyData.multiCurrencyGrandTotal || 0,
                    activeMulticurrencyCode
                  )}
                </b>
              </Typography>
              <FBOButton
                variant="tertiary"
                color="neutral"
                size="small"
                icon={IconNames.UpwardCarat}
                onClick={handleMultiIconClicked}
                data-qa="sale-order-total-arrow-up"
                sx={{ marginLeft: '8px' }}
              />
            </MultiCurrencyWrapper>
          </Grid>

          <Popover
            anchorOrigin={totalAnchorOrigin}
            transformOrigin={totalTransformOrigin}
            anchorEl={totalEl}
            open={isTotalPopVisible}
            onClose={handleTotalPopClose}
          >
            <Box width={200} px={2} py={2.5}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <PopoverItem
                    label="Subtotal:"
                    value={currencyFormatter(subTotal.homeCurrencySubtotal)}
                    dataQa="sale-order-subtotal"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PopoverItem
                    label="Order Discount:"
                    value={
                      (discountTotal > 0 ? '-' : '') +
                      currencyFormatter(discountTotal)
                    }
                    dataQa="sale-order-discount-total"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PopoverItem
                    label="Tax:"
                    value={currencyFormatter(taxTotal)}
                    dataQa="sale-order-tax"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between" pb={3}>
                    <Typography>Total:</Typography>
                    <Typography data-qa="sale-order-grand-total">
                      <b>{currencyFormatter(grandTotal)}</b>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <PopoverItem
                    label="Paid:"
                    value={currencyFormatter(
                      _.get(footerData, 'salesOrder.paymentTotal', 0)
                    )}
                    dataQa="sale-order-paid"
                  />
                </Grid>
                {soSettings.showMarkupAndMargin && (
                  <>
                    <Grid item xs={12}>
                      <PopoverItem
                        label="Markup:"
                        value={currencyFormatter(
                          salesOrderProfitCalculation.resolvedMarkupAmount
                        )}
                        dataQa="sale-order-markup"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PopoverItem
                        label="Markup Percent:"
                        value={`${salesOrderProfitCalculation.resolvedMakupPercentage}%`}
                        dataQa="sale-order-markup-percent"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PopoverItem
                        label="Margin Percent:"
                        value={`${salesOrderProfitCalculation.resolvedMarginPercentage}%`}
                        last
                        dataQa="sale-order-margin-percent"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Popover>
          <Popover
            anchorOrigin={totalAnchorOrigin}
            transformOrigin={totalTransformOrigin}
            anchorEl={multiTotalEl}
            open={isMultiTotalPopVisible}
            onClose={handleMultiTotalPopClose}
          >
            <Box width={200} px={2} py={2.5}>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <PopoverItem
                    label="Subtotal:"
                    value={currencyFormatter(
                      subTotal.multiCurrencySubTotal || 0,
                      activeMulticurrencyCode
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PopoverItem
                    label="Order Discount:"
                    value={
                      (multiCurrencyData.multiCurrencyDiscountTotal > 0
                        ? '-'
                        : '') +
                      currencyFormatter(
                        multiCurrencyData.multiCurrencyDiscountTotal,
                        activeMulticurrencyCode
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <PopoverItem
                    label="Tax:"
                    value={currencyFormatter(
                      multiCurrencyData.multiCurrencyTaxTotal,
                      activeMulticurrencyCode
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between" pb={3}>
                    <Typography>Total:</Typography>
                    <Typography>
                      <b>
                        {currencyFormatter(
                          multiCurrencyData.multiCurrencyGrandTotal,
                          activeMulticurrencyCode
                        )}
                      </b>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Popover>
          <Popover
            anchorOrigin={discountAnchorOrigin}
            transformOrigin={discountTransformOrigin}
            anchorEl={discountEl}
            open={isDiscountPopVisible}
            onClose={handleDiscountPopClose}
          >
            <Box p={1} display="flex" alignItems="center">
              <MultiFormatInput
                className="redesign"
                variant="standard"
                type="text"
                label={
                  discountPopupIndex === 1
                    ? 'Discount Amount'
                    : 'Discount Percent'
                }
                placeholder="Enter quantity"
                value={discountPopupValue}
                onFormatChange={handleDiscountAmountTypeChanged}
                onChange={handleDiscountPopupChange}
                options={formatTypes(currencySymbol)}
                activeIndex={discountPopupIndex}
                dataQa="sale-order-discount-quantity"
              />
              <Box ml={1}>
                <FBOButton
                  variant="primary"
                  color="positive"
                  size="small"
                  onClick={handleDiscountPopupApplyClick}
                  data-qa="sale-order-discount-apply"
                >
                  Add
                </FBOButton>
              </Box>
            </Box>
          </Popover>
        </Grid>
      </Box>
      <ConfirmationModal
        open={taxExemptWarningModalVisible}
        title="Tax Rate Changed"
        body={`Tax Rate has been changed and customer has Tax Exempt flag checked. Are you sure you want to continue?`}
        onCancelClicked={handleTaxExemptOnClose}
        onConfirmClicked={handleTaxExemptWarningModalContinue}
        confirmLabel="Continue"
        confirmButtonRed
      />
    </>
  );
};

export default memo(FBOTableFooter);
