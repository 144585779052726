import {
  createApiReducer,
  chainReducers,
  INITIAL_API_STATE,
} from 'services/api';

import { EcommerceChannelsActionTypes } from './actions';
import { transformEcommerceSettings } from '../transformations';

const fetchEcommerceChannelsReducer = createApiReducer(
  [
    EcommerceChannelsActionTypes.FETCH_ECOMMERCE_CHANNELS_START,
    EcommerceChannelsActionTypes.FETCH_ECOMMERCE_CHANNELS_SUCCESS,
    EcommerceChannelsActionTypes.FETCH_ECOMMERCE_CHANNELS_FAILURE,
  ],
  (data) => ({ items: data.map(transformEcommerceSettings) }),
  (data) => data
);

const disconnectEcommerceReducer = createApiReducer(
  [
    EcommerceChannelsActionTypes.ECOMMERCE_DISCONNECT_START,
    EcommerceChannelsActionTypes.ECOMMERCE_DISCONNECT_SUCCESS,
    EcommerceChannelsActionTypes.ECOMMERCE_DISCONNECT_FAILURE,
  ],
  () => INITIAL_API_STATE,
  (error) => error.response.data.errorMessage
);

const ecommerceChannelsChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchEcommerceChannelsReducer,
  disconnectEcommerceReducer
);

export default ecommerceChannelsChainedReducers;
