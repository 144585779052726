import { Errors } from 'services/forms/validation';
import { BulkWizardItem } from 'services/inventory';

import { BulkWizardData } from '../../types';

export enum ItemSearchTypes {
  Tags = 'Tags',
  All = 'All Fields',
}

export interface ItemsStepProps {
  wizardData: BulkWizardData;
  setBulkItems: React.Dispatch<React.SetStateAction<BulkWizardItem[]>>;
  errors: Errors;
}
