import _ from 'lodash';

import { Customer } from 'services/customers';
import { Item } from 'services/items';
import { Location } from 'services/locations';
import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/picking';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { OrderPriority } from 'services/settings/orderPriorities';
import { Tag } from 'services/tags';
import { PaginationDirection } from 'services/url';
import { User } from 'services/user';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const initialPickingFormValues: AdvancedSearchFormValues = {
  number: null,
  status: null,
  customer: null,
};

export const pickAdvancedSearchFieldsCombined = {
  Customer: 'customer.id',
  Location: 'location.id',
  Item: 'item.id',
  Tags: 'item.tag.id',
  User: 'user.id',
  Priority: 'orderPriority.id',
  DateCreatedFrom: 'created.gte',
  DateCreatedTo: 'created.lte',
  DateModifiedFrom: 'modified.gte',
  DateModifiedTo: 'modified.lte',
  DateScheduledFrom: 'scheduled.gte',
  DateScheduledTo: 'scheduled.lte',
  DateFulfilledFrom: 'fulfilled.gte',
  DateFulfilledTo: 'fulfilled.lte',
};

export const displayNameMap = {
  number: 'Pick',
  status: 'Status',
  [pickAdvancedSearchFieldsCombined.Priority]: 'Priority',
  [pickAdvancedSearchFieldsCombined.User]: 'User',
  [pickAdvancedSearchFieldsCombined.Item]: 'Item',
  [pickAdvancedSearchFieldsCombined.Tags]: 'Tags',
  [pickAdvancedSearchFieldsCombined.Customer]: 'Customer',
  [pickAdvancedSearchFieldsCombined.Location]: 'Location',
};

export const createDisplayValueMap = (
  priorityLevels: OrderPriority[],
  item: Item | null,
  customers: Customer[],
  locations: Location[],
  users: User[],
  tags: Tag[]
) => {
  return {
    [pickAdvancedSearchFieldsCombined.Priority]: (id: number) =>
      _.get(
        priorityLevels.find((p) => p.id === id),
        'name',
        null
      ),
    status: (status: string) => status.split(',').join(', '),
    [pickAdvancedSearchFieldsCombined.Item]: () => (item ? item.name : '-'),
    [pickAdvancedSearchFieldsCombined.Customer]: (id: number) =>
      _.get(
        customers.find((c) => c.id === id),
        'name',
        null
      ),
    [pickAdvancedSearchFieldsCombined.Location]: (id: number) =>
      _.get(
        locations.find((l) => l.id === id),
        'name',
        null
      ),
    [pickAdvancedSearchFieldsCombined.User]: (id: number) =>
      _.get(
        users.find((u) => u.id === id),
        'firstName',
        null
      ) +
      ' ' +
      _.get(
        users.find((u) => u.id === id),
        'lastName',
        null
      ),
    [pickAdvancedSearchFieldsCombined.Tags]: () =>
      tags.map((t) => t.name).join(', '),
  };
};

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'number',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};
