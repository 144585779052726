import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { StripeLogo } from 'ui/theme';
import { connectStripe } from 'services/integrations/payments/stripe';
import { showNotification } from 'services/api';
import {
  isPaymentConnected,
  fetchPaymentIntegrations,
} from 'services/integrations/payments/redux';
import {
  disconnectPaymentIntegration,
  PaymentIntegrationType,
} from 'services/integrations/payments';

import { Card } from '../Card';
import { StripeCardProps } from './types';
import { CardButton } from '../IntegrationButtons/CardButton';
import { logErrorCtx } from 'app/logging';

const StripeCard: React.FC<StripeCardProps> = () => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const isConnected = useSelector(
    isPaymentConnected(PaymentIntegrationType.Stripe)
  );

  const connectClicked = async () => {
    setIsLoading(true);
    try {
      const redirectUrl = await connectStripe();
      window.location.href = redirectUrl;
    } catch (err) {
      const error = err as Error;
      showNotification(
        _.get(error, 'response.data.errorMessage', 'Please contact support'),
        { variant: 'error' }
      );
      logErrorCtx('Stripe connection failed.', {
        error,
        stackTrace: error.stack,
        title: 'Connecting to Stripe failed.',
        component: 'Stripe -> StripeCard',
      });
    }
    setIsLoading(false);
  };

  const disconnectClicked = async () => {
    setIsLoading(true);
    try {
      await disconnectPaymentIntegration(PaymentIntegrationType.Stripe);
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchPaymentIntegrations());
    } catch (e) {
      showNotification(
        _.get(e, 'response.data.errorMessage', 'Please contact support'),
        { variant: 'error' }
      );
      const error = e as Error;
      logErrorCtx('Error disconnecting from Stripe', {
        error: error,
        stackTrace: error.stack,
        title: 'Disconnect Stripe Error',
        component: 'StripeCard',
      });
    }
    setIsLoading(false);
  };

  return (
    <Card
      imageUrl={StripeLogo}
      cardId="stripe"
      isLoading={isLoading}
      description="Integrate with Stripe."
      title="Stripe"
    >
      {isConnected ? (
        <CardButton
          onClick={disconnectClicked}
          variant="secondary"
          color="negative"
          dataQa="stripe-disconnect"
          label="Disconnect"
        />
      ) : (
        <CardButton
          onClick={connectClicked}
          variant="primary"
          color="positive"
          dataQa="stripe-connect"
          label="Connect"
        />
      )}
    </Card>
  );
};
export default memo(StripeCard);
