import React, { useCallback, useEffect, useState } from 'react';

import { Routes } from '../../navigation';
import { Page } from 'ui/components/Page/Page';
import { Box } from '@mui/system';
import { colorPalette, icons } from 'ui/theme';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { getInvoicesAPI } from 'services/invoices';
import { logErrorCtx } from 'app/logging';
import {
  customDateRangeInvoices,
  initialDateRange,
  initialPagination,
} from './consts';
import { Pagination } from 'services/search';
import { InvoicesSearchResults } from './components/InvoicesSearchResults';
import { useUrlQueryObject } from 'services/url';
import { InvoiceDetailsCard } from './components/InvoiceDetailsCard';
import { FBOSearchInput } from 'ui/components/SearchHeader/styled';
import { InputAdornment } from '@mui/material';
import { DateRangePicker } from 'ui/components/TextField/DateRangePicker';
import { DateRange } from 'materialui-daterange-picker';
import moment from 'moment';

const InvoicesPage = () => {
  const [activeId, setActiveId] = useState<number | null>(null);
  const [invoices, setInvoices] = useState<any>([]);
  const [pagination, setPagination] = useState<Pagination>(initialPagination);
  const [loadingInvoices, setLoadingInvoices] = useState(false);
  const [search, setSearch] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState<DateRange>(initialDateRange);

  const formatStartDate = (date: Date | undefined) => {
    return date ? `${moment(date).format('YYYY-MM-DD')} 00:00:00` : null;
  };

  const formatEndDate = (date: Date | undefined) => {
    return date ? `${moment(date).format('YYYY-MM-DD')} 23:59:59` : null;
  };

  useEffect(() => {
    getInvoices(
      pagination,
      formatStartDate(dateRange.startDate),
      formatEndDate(dateRange.endDate),
      search
    );
  }, []);

  const getInvoices = async (
    pagination: Pagination,
    startDate: string | null,
    endDate: string | null,
    search: string | null
  ) => {
    setLoadingInvoices(true);
    setInvoices([]);

    try {
      const res = await getInvoicesAPI(
        { pagination: pagination },
        startDate,
        endDate,
        search
      );
      setInvoices(res.data);
      setPagination(res.pagination);
    } catch (e) {
      logErrorCtx('Error in getInvoicesAPI', {
        error: e as Error,
        component: 'InvoicesPage',
      });
    }

    setLoadingInvoices(false);
  };

  const [, extendUrlQuery] = useUrlQueryObject();

  const handleActiveInvoiceClose = useCallback(
    () => extendUrlQuery({ activeId: null }),
    [extendUrlQuery]
  );

  const handleInvoiceClicked = useCallback(
    async (id: number) => {
      extendUrlQuery({ activeId: id });
    },
    [extendUrlQuery]
  );
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const tempActiveId = queryParams?.get('activeId');
    if (tempActiveId) {
      setActiveId(Number(tempActiveId));
    } else {
      setActiveId(null);
    }
  }, [queryParams]);

  const handleDateChange = (value: DateRange) => {
    const startDate = moment(value.startDate).startOf('day').toDate();
    const endDate = moment(value.endDate).endOf('day').toDate();

    setDateRange({ startDate, endDate });
    getInvoices(
      { ...pagination, page: 1 },
      formatStartDate(startDate),
      formatEndDate(endDate),
      search
    );
  };

  const handleSearchChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    setSearch(value || null);
    getInvoices(
      { ...pagination, page: 1 },
      formatStartDate(dateRange.startDate),
      formatEndDate(dateRange.endDate),
      value
    );
  };

  const handlePaginationChange = (newPagination: Pagination) => {
    setPagination(newPagination);
    getInvoices(
      newPagination,
      formatStartDate(dateRange.startDate),
      formatEndDate(dateRange.endDate),
      search
    );
  };

  return (
    <Page>
      <Box
        display="flex"
        mb={'16px'}
        sx={{
          alignItems: 'center',
          padding: '16px',
          backgroundColor: `${colorPalette.white}`,
          borderRadius: '10px',
          gap: '8px',
        }}
      >
        <Box mr={0} sx={{ flexGrow: '1' }}>
          <FBOSearchInput
            type="text"
            placeholder="Search"
            value={search}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <icons.MagnifyingGlass />
                </InputAdornment>
              ),
              sx: { paddingLeft: '8px' },
            }}
            onChange={handleSearchChange}
            style={{ width: '100%', margin: 0 }}
            data-qa="search-ledgers"
          />
        </Box>
        <Box style={{ margin: 0 }}>
          <DateRangePicker
            onChange={handleDateChange}
            value={dateRange}
            label={''}
            style={{ width: 235, margin: 0 }}
            definedRanges={customDateRangeInvoices}
            data-qa="invoices-page-date-range"
            aria-label="Date Range"
          />
        </Box>
      </Box>
      <PaperSlidingLayout shown={!!activeId}>
        <InvoicesSearchResults
          invoices={invoices}
          activeInvoiceId={activeId}
          handleInvoiceClick={handleInvoiceClicked}
          isLoadingInvoices={loadingInvoices}
          pagination={pagination}
          onPaginationChange={handlePaginationChange}
        />
        <InvoiceDetailsCard
          activeId={activeId}
          onClose={handleActiveInvoiceClose}
        />
      </PaperSlidingLayout>
    </Page>
  );
};

InvoicesPage.route = Routes.InvoicesPage;

export default InvoicesPage;
