import React, { useCallback } from 'react';

import {
  Currency,
  transformCurrency,
  fetchSearch,
  getSearches,
} from 'services/currencies';
import { Pagination } from 'services/search';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { useUrlQueryObject } from 'services/url';
import { fetchUsers } from 'services/user';

import { Routes } from '../../navigation';
import { CurrencyAdvancedSearch } from './components';
import { CurrencySearchResults } from './components/CurrenySearchResults';
import { CurrencyPageCmp, CurrencyPageProps } from './types';
import {
  advancedSearchReduxActions,
  CURRENCY_COLUMNS,
  displayNameMap,
  initialCurrencyFormValues,
  initialPagination,
} from './consts';
import { CurrencyDetailsCard } from './components/CurrencyDetailsCard';

const CurrencyPage: CurrencyPageCmp = (props: CurrencyPageProps) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activeCurrencyId,
  } = props;

  const [, extendUrlQuery] = useUrlQueryObject();

  const handleCurrencyClose = () => extendUrlQuery({ activeId: null });

  const handleAddNewPress = () => extendUrlQuery({ activeId: -1 });

  const handleCurrencyClicked = (id: number | null) =>
    extendUrlQuery({ activeId: id });

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );
  return (
    <>
      <PageWithAdvancedSearch
        detailCardColumns={CURRENCY_COLUMNS}
        initialFormValues={initialCurrencyFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={CurrencyAdvancedSearch}
        displayNameMap={displayNameMap}
        pageName="Currencies"
      >
        <PaperSlidingLayout shown={!!activeCurrencyId}>
          <CurrencySearchResults
            currency={searchResult.items}
            activeCurrencyId={activeCurrencyId}
            handleCurrencyClick={handleCurrencyClicked}
            onAddNewPress={handleAddNewPress}
            isLoadingCurrencies={isLoadingSearchResult}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
          />
          <CurrencyDetailsCard
            activeCurrencyId={activeCurrencyId}
            fetchSearchResult={fetchSearchResult}
            onClose={handleCurrencyClose}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
    </>
  );
};

CurrencyPage.route = Routes.CurrencyPage;

export default withSearchResults<Currency>(CurrencyPage, {
  url: '/v1/currencies',
  dataAdapter: transformCurrency,
  columns: CURRENCY_COLUMNS,
  initialPagination,
  fetchSearch,
  getSearches,
  rehydrationThunks: [fetchUsers],
});
