import React, { memo, useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { Timezone } from 'timezones.json';
import { TIMEZONES } from 'helpers/timezones';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { getTimezone } from 'ui/modules/setup/services';
import { Errors, validateYup } from 'services/forms/validation';
import { Location } from 'services/locations';
import { PermissionType } from 'services/permissions';
import { createUser, initialUser, User } from 'services/user';
import { useHandleTextFieldChange } from 'services/forms';

import { NewUserModalProps } from './types';
import { yupSchema } from './validations';
import { Autocomplete } from '../Autocomplete';
import { LocationsAsyncAutocomplete } from '../LocationsAsyncAutocomplete';

const NewUserModal: React.FC<NewUserModalProps> = (props) => {
  const { show, newUserName, onClose, onSave } = props;

  const [formUser, setFormUser] = useState<User>(initialUser);
  const [modalLoading, setModalLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    setFormUser({
      ...initialUser,
      firstName: newUserName,
      registered: false,
    });
    setErrors({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleTimezoneAutocomplete = useCallback((_: any, value: any) => {
    setFormUser((old) => ({
      ...old,
      timezone: value ? value.utc[0] : null,
    }));
  }, []);

  const handleLocationChange = useCallback((v: Location | null) => {
    setFormUser((old) => ({
      ...old,
      defaultLocationId: v ? v.id : null,
    }));
  }, []);

  const handleApplyClicked = async () => {
    if (!validateYup(formUser, yupSchema, setErrors)) {
      return;
    }

    setModalLoading(true);

    try {
      const newUser = await createUser(formUser);
      onSave(newUser);
    } catch {
      // do nothing
    }

    setModalLoading(false);
  };

  const handleTextFieldChange = useHandleTextFieldChange(setFormUser, formUser);

  const canEditUser =
    formUser.id && formUser.id > 0
      ? [PermissionType.UsersEdit]
      : [PermissionType.UsersCreate];

  return (
    <Modal
      open={show}
      title="New User"
      onCancelClicked={onClose}
      onApplyClicked={handleApplyClicked}
      applyLabel="Save"
      isLoadingContent={modalLoading}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            name="firstName"
            label="First Name"
            placeholder="First Name"
            value={formUser.firstName}
            onChange={handleTextFieldChange}
            required
            error={!!errors.firstName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            value={formUser.lastName}
            onChange={handleTextFieldChange}
            required
            error={!!errors.lastName}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            name="email"
            label="Email"
            placeholder="Enter Email"
            value={formUser.email}
            onChange={handleTextFieldChange}
            required
            error={!!errors.email}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            label="TIME ZONE"
            name="timezone"
            options={TIMEZONES}
            value={getTimezone(formUser.timezone)}
            getOptionLabel={(option: Timezone) => option.text}
            onChange={handleTimezoneAutocomplete}
            data-qa="timeZone"
            required
            error={!!errors.timezone}
          />
        </Grid>
        <Grid item xs={6}>
          <LocationsAsyncAutocomplete
            placeholder="Select Location"
            onChange={handleLocationChange}
            label="Default Location"
            value={formUser.defaultLocationId}
            companyWide={false}
            permissions={canEditUser}
            dataQa="user-default-location"
            required
            error={!!errors.defaultLocationId}
            parentId={null}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default memo(NewUserModal);
