import { Dates } from 'helpers';
import { MenuItem } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';

export enum CarriersPageAction {
  Import,
  Export,
  ChangeDate,
}

export const createCarriersSearchActionBarOptions = (
  onActionClick: (actionType: CarriersPageAction, date?: Dates) => void,
  carriersCount: number
): MenuItem[] => [
  {
    label: 'Import Carriers',
    icon: IconNames.FBOImport,
    onOptionClick: () => onActionClick(CarriersPageAction.Import),
    separateAfter: true,
  },
  {
    label: 'Export Carriers',
    icon: IconNames.FBOExport,
    onOptionClick: () => onActionClick(CarriersPageAction.Export),
    disabled: !carriersCount,
    separateAfter: true,
  },
  {
    icon: IconNames.FBORecycleSmall,
    label: 'Change Dates',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(CarriersPageAction.ChangeDate, Dates.DateCreated),
        dataQa: 'date-created',
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(CarriersPageAction.ChangeDate, Dates.DateLastModified),
        dataQa: 'date-last-modified',
      },
    ],
  },
];
