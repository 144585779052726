import { Dates } from 'helpers';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import { MenuItem, MenuItemVariant } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';
import { Item, ItemType } from 'services/items';
import { PermissionType } from 'services/permissions';

import { ItemTab } from '../ItemDetailsCard/types';

export enum ItemPageRowAction {
  Duplicate,
  Delete,
}

export enum ItemPageAction {
  ExportVisable,
  ExportItems,
  ImportItems,
  ImportItemLocations,
  ExportItemLocations,
  ChangeDate,
  CustomFields,
  ImportReorderPoint,
  ExportReorderPoint,
  ExportVendorItems,
  ImportVendorItems,
}

export const createActionBarOptions = (
  onActionClick: (actionType: ItemPageRowAction) => void,
  item: Item
): MenuItem[] => [
  {
    label: 'Duplicate Item',
    onOptionClick: () => onActionClick(ItemPageRowAction.Duplicate),
    icon: IconNames.FBODuplicateSmall,
    permissions: item.deleted
      ? [PermissionType.Undelete]
      : [PermissionType.ItemsCreate],
    dataQa: 'item-table-three-dot-menu-duplicate-item',
    separateAfter: true,
  },
  {
    label: 'Delete Item',
    onOptionClick: () => onActionClick(ItemPageRowAction.Delete),
    variant: MenuItemVariant.Danger,
    icon: IconNames.FBODeleteSmall,
    permissions: item.deleted
      ? [PermissionType.Undelete]
      : [PermissionType.ItemsDelete],
  },
];

export const createItemsSearchActionBarOptions = (
  onActionClick: (actionType: ItemPageAction, value?: Dates) => void,
  itemsCount: number
): MenuItem[] => [
  {
    label: 'Import',
    icon: IconNames.FBOImport,
    permissions: [PermissionType.ItemsEdit, PermissionType.ItemsCreate],
    dataQa: 'items-import-option',
    separateAfter: true,
    subItems: [
      {
        label: 'Import Items',
        onOptionClick: () => onActionClick(ItemPageAction.ImportItems),
        iconRight: IconNames.FBOQuestionMarkInfo,
        iconRightHref:
          'https://help.fishbowlonline.com/s/article/Drive-Items#Import_items',
        dataQa: 'import-items',
        separateAfter: true,
      },
      {
        label: 'Import Item Locations',
        onOptionClick: () => onActionClick(ItemPageAction.ImportItemLocations),
        dataQa: 'import-item-locations',
        separateAfter: true,
      },
      {
        label: 'Import Reorder Point',
        onOptionClick: () => onActionClick(ItemPageAction.ImportReorderPoint),
        dataQa: 'import-reorder-point',
        separateAfter: true,
      },
      {
        label: 'Import Vendor Items',
        onOptionClick: () => onActionClick(ItemPageAction.ImportVendorItems),
        dataQa: 'import-vendor-items',
      },
    ],
  },
  {
    icon: IconNames.FBOExport,
    label: 'Export',
    dataQa: 'items-export-option',
    separateAfter: true,
    subItems: [
      {
        label: 'Export Items',
        onOptionClick: () => onActionClick(ItemPageAction.ExportItems),
        disabled: !itemsCount,
        dataQa: 'export-items',
        separateAfter: true,
      },
      {
        label: 'Export Item Locations',
        onOptionClick: () => onActionClick(ItemPageAction.ExportItemLocations),
        disabled: !itemsCount,
        dataQa: 'export-item-locations',
        separateAfter: true,
      },
      {
        label: 'Export Reorder Point',
        onOptionClick: () => onActionClick(ItemPageAction.ExportReorderPoint),
        disabled: !itemsCount,
        dataQa: 'export-reorder-point',
        separateAfter: true,
      },
      {
        label: 'Export Vendor Items',
        onOptionClick: () => onActionClick(ItemPageAction.ExportVendorItems),
        disabled: !itemsCount,
        dataQa: 'export-vendor-items',
      },
    ],
  },

  {
    label: 'Custom Fields',
    icon: IconNames.FBOReport,
    onOptionClick: () => onActionClick(ItemPageAction.CustomFields),
    permissions: [PermissionType.ItemsEdit, PermissionType.ItemsCreate],
    dataQa: 'items-custom-fields-option',
    separateAfter: true,
  },
  {
    icon: IconNames.FBORecycleSmall,
    label: 'Change Dates',
    dataQa: 'items-change-dates-option',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(ItemPageAction.ChangeDate, Dates.DateCreated),
        dataQa: 'date-created',
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(ItemPageAction.ChangeDate, Dates.DateLastModified),
        dataQa: 'date-last-modified',
      },
    ],
  },
];

export const ITEM_COLUMNS_ALWAYS_ON: Column[] = [
  {
    title: 'Name',
    field: 'name',
    sortable: true,
    type: ColumnTypes.string,
  },
];

const ITEM_COLUMNS_HIDABLE = (date: Dates): Column[] => {
  const resolveDateColumn = (): Column => {
    switch (date) {
      case Dates.DateLastModified:
        return {
          title: 'Date Last Modified',
          field: Dates.DateLastModified,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
          width: 150,
        };
      default:
        return {
          title: 'Date Created',
          field: Dates.DateCreated,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
          width: 100,
        };
    }
  };

  return [
    {
      title: 'Barcode / UPC',
      field: 'upc',
      sortable: true,
      type: ColumnTypes.string,
      width: 130,
    },
    {
      title: 'SKU',
      field: 'sku',
      sortable: true,
      type: ColumnTypes.string,
      width: 100,
    },
    {
      title: 'Type',
      field: 'itemType',
      sortable: true,
      type: ColumnTypes.string,
      width: 100,
    },

    {
      title: 'Quantity',
      field: 'id',
      type: ColumnTypes.string,
      searchable: false,
      width: 100,
    },
    {
      title: 'Sale Item',
      field: 'saleItems.name',
      type: ColumnTypes.string,
      sortable: true,
    },
    resolveDateColumn(),
    {
      title: 'Price',
      field: 'saleItems.price',
      sortable: true,
      type: ColumnTypes.string,
      searchable: false,
      width: 100,
    },
    {
      title: 'Cost',
      width: 100,
      field: 'cost',
      sortable: true,
      type: ColumnTypes.string,
      searchable: false,
    },
    {
      title: '',
      width: 50,
      type: ColumnTypes.renderByCustomRow,
    },
  ];
};

export const ITEM_COLUMNS = (date: Dates): Column[] => [
  ...ITEM_COLUMNS_ALWAYS_ON,
  ...ITEM_COLUMNS_HIDABLE(date),
];

export const itemTypeTabs = [
  {
    itemType: ItemType.Inventory,
    tabs: [
      ItemTab.General,
      ItemTab.SaleItems,
      ItemTab.SubstituteItems,
      ItemTab.ReorderPoints,
      ItemTab.Tracking,
      ItemTab.Locations,
      ItemTab.VendorItems,
      ItemTab.Documents,
    ],
  },
  {
    itemType: ItemType.Bundle,
    tabs: [ItemTab.General, ItemTab.BundleItems, ItemTab.Documents],
  },
  {
    itemType: ItemType.Labor,
    tabs: [ItemTab.General, ItemTab.VendorItems, ItemTab.Documents],
  },
  {
    itemType: ItemType.NonInventory,
    tabs: [
      ItemTab.General,
      ItemTab.SaleItems,
      ItemTab.ReorderPoints,
      ItemTab.Locations,
      ItemTab.VendorItems,
      ItemTab.Documents,
    ],
  },
  {
    itemType: ItemType.Overhead,
    tabs: [ItemTab.General, ItemTab.VendorItems, ItemTab.Documents],
  },
  {
    itemType: ItemType.Service,
    tabs: [
      ItemTab.General,
      ItemTab.SaleItems,
      ItemTab.VendorItems,
      ItemTab.Documents,
    ],
  },
  {
    itemType: ItemType.Shipping,
    tabs: [
      ItemTab.General,
      ItemTab.SaleItems,
      ItemTab.VendorItems,
      ItemTab.Documents,
    ],
  },
];
