import { makeStyles } from '@mui/styles';

import { colorPalette } from 'ui/theme';

export const useBulkWizardStyle = makeStyles(() => ({
  activeIcon: {
    color: 'green',
    fill: `${colorPalette.fishbowlCorporateBlue} !important`,
  },
}));
