import React, { memo, useCallback, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { IconButtonWithActions } from 'ui/components/Button/IconButtonWithActions';
import { MenuItem } from 'ui/components/Menu';
import { AmazonLogo, IconNames } from 'ui/theme';
import {
  ChannelType,
  disconnectChannel,
} from 'services/integrations/ecommerce';
import { showNotification } from 'services/api';
import {
  fetchEcommerceChannels,
  getAllECommerceSettingsByChannel,
} from 'services/integrations/ecommerce/redux';
import { fetchAmazonUrl } from 'services/integrations/ecommerce/amazon';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { Modal } from 'ui/components/Modal/Modal';

import { AmazonCardProps } from './types';
import { Card } from '../';
import { CardButton } from '../IntegrationButtons/CardButton';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const AmazonCard: React.FC<AmazonCardProps> = (props) => {
  const { connectionValid } = props;

  const dispatch = useDispatch();

  const ecommerceSettings = useSelector(
    getAllECommerceSettingsByChannel(ChannelType.Amazon)
  );

  const [modalVisible, setModalVisible] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [showConnectionModal, setShowConnectionModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [activeChannel, setActiveChannel] = useState<{
    id: number;
    name: string;
  }>();

  const connectAmazon = useCallback(async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const url = await fetchAmazonUrl();

      const windowOpen = window.open(url + '#amazon', '_blank');
      if (windowOpen) {
        windowOpen.focus();
      }
      setModalVisible(false);
    } catch (e) {
      showNotification(`Error connecting Amazon. `, {
        variant: 'error',
      });
    }
    setIsLoading(false);
  }, []);

  const handleDisconnect = async () => {
    if (activeChannel) {
      setIsLoading(true);
      try {
        await disconnectChannel(activeChannel.id!);
        // Lint skip to be removed
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(fetchEcommerceChannels());
      } catch (e) {
        const error = e as Error;

        showNotification(`Disconnect from channel failed - ${error.message}`, {
          variant: 'error',
        });
      }
      setIsLoading(false);
      setShowDisconnectModal(false);
    } else {
      showNotification(`Unable to disconnect! No channel id provided!`, {
        variant: 'error',
      });
    }
  };

  const handleConnect = () => {
    if (!connectionValid) {
      setShowConnectionModal(true);
      return;
    }

    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const createItemsSearchActionBarOptions = (): MenuItem[] => {
    return ecommerceSettings.map((channel) => {
      return {
        label: channel.channel.name!,
        onOptionClick: () => {
          setActiveChannel({
            id: channel.channel.id!,
            name: channel.channel.name!,
          });
          setShowDisconnectModal(true);
        },
      };
    });
  };

  return (
    <>
      <Card
        imageUrl={AmazonLogo}
        cardId="amazon"
        isLoading={false}
        description="Integrate with your Seller Central account and manage your Amazon orders."
        title="Amazon"
      >
        <>
          {ecommerceSettings.length > 0 ? (
            <IconButtonWithActions
              icon={IconNames.MoreVertical}
              iconSize={16}
              text="Disconnect"
              boxStyle
              items={createItemsSearchActionBarOptions()}
            />
          ) : null}
          <CardButton
            onClick={handleConnect}
            variant="primary"
            color="positive"
            dataQa="amazon-connect"
            label="Connect"
          />
        </>
      </Card>
      <Modal
        open={modalVisible}
        title="Connect to Amazon"
        maxWidth="sm"
        onCancelClicked={handleCloseModal}
        isLoadingContent={isLoading}
        ModalActionsComponent={() => (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <FBOButton
              variant="secondary"
              color="positive"
              data-qa="cancel-button"
              onClick={handleCloseModal}
            >
              Cancel
            </FBOButton>
            <Box ml={2}>
              <FBOButton
                variant="primary"
                color="positive"
                data-qa="connect-button"
                onClick={connectAmazon}
              >
                Connect
              </FBOButton>
            </Box>
          </Box>
        )}
      >
        <form onSubmit={connectAmazon}>
          <Box display="flex" alignItems="center">
            <Typography variant="body1" color="textPrimary">
              Do you want to connect to Amazon?
            </Typography>
          </Box>
        </form>
      </Modal>
      <ConfirmationModal
        open={showDisconnectModal}
        title="Disconnect Amazon"
        body={
          'Are you sure you want to disconnect Amazon channel ' +
          (activeChannel ? activeChannel!.name : null) +
          '?'
        }
        isLoading={isLoading}
        onCancelClicked={() => setShowDisconnectModal(false)}
        onConfirmClicked={handleDisconnect}
      />
      <ConfirmationModal
        open={showConnectionModal}
        title="Maximum number of integrations has been reached!"
        onConfirmClicked={() => setShowConnectionModal(false)}
        onCancelClicked={() => setShowConnectionModal(false)}
        body="Please contact sales to add additional integrations."
      />
    </>
  );
};
export default memo(AmazonCard);
