import React, { memo } from 'react';
import { Box } from '@mui/material';

import { Paper } from 'ui/components/Paper/Paper';
import { TitleBar } from 'ui/components/TitleBar';
import { ItemsTable, initialPagination } from 'ui/components/Table/ItemsTable';
import { useGetIntlDateFormatString } from 'helpers';

import { useCardStyle } from '../../styled';
import { cardPreviousExportColumns } from './consts';
import { CardPreviousExportProps } from './types';

const CardPreviousExport: React.FC<CardPreviousExportProps> = (props) => {
  const { historyDetails } = props;
  const classes = useCardStyle();
  const intlDateFormat = useGetIntlDateFormatString();

  return (
    <Box className={classes.container}>
      <Paper id="previousExportId" className={classes.paper}>
        <TitleBar dangerouslySetInnerHTML={true} title="Previous Export" />
        <ItemsTable
          columns={cardPreviousExportColumns(intlDateFormat)}
          data={historyDetails}
          selectableItems={false}
          emptyTableText="No Data"
          pagination={initialPagination}
        />
      </Paper>
    </Box>
  );
};

export default memo(CardPreviousExport);
