import {
  paginateURL,
  advancedSearchURL,
  quickSearchURL,
  expandURL,
} from 'services/url';
import { createApiCall } from 'services/api';
import { FetchItemsHandler } from '../types';
import { fetchLocationsByIds } from 'services/locations/api';
import { SalesOrder } from 'services/salesOrders/types';
import uniq from 'lodash/uniq';
import _ from 'lodash';

export const fetchItemsForSales: FetchItemsHandler = async (
  url,
  dataAdapter,
  currentState,
  quickSearchColumns
) => {
  let path = url;
  const advancedSearch = currentState.advancedSearch;
  const expand = [
    'salesOrderItems',
    'salesOrderBundleItems',
    'salesOrderBundleItems.salesOrderItems',
    'salesOrderBundleItems.salesOrderItems.saleItem',
    'salesOrderItems.saleItem.item.images',
  ];

  // quick search URL using received quick search
  path = quickSearchURL(
    path,
    currentState.quickSearch,
    quickSearchColumns,
    advancedSearch
  );
  path = advancedSearchURL(path, currentState.advancedSearch);
  path = expandURL(path, expand.join());

  // paginate URL if pagination was sent
  if (currentState.pagination) {
    path = paginateURL(path, currentState.pagination);
  }

  const fetchItems = createApiCall(
    { path, method: 'GET' },
    {
      getMessage: {
        error: (error: any) =>
          error.response.data.message ||
          'Something went wrong when we where trying to load items. Please try again in few minutes.',
      },
    }
  );
  const itemsResponse = await fetchItems();
  const salesOrders = itemsResponse.data.results.map(dataAdapter);
  const salesOrderLocationIds = uniq(
    salesOrders.map((salesOrder: SalesOrder) => salesOrder.locationId)
  );
  const locations = salesOrderLocationIds.length
    ? await fetchLocationsByIds(salesOrderLocationIds.join())
    : [];
  const updatedSalesOrders = salesOrders.map((salesOrder: SalesOrder) => {
    return {
      ...salesOrder,
      location:
        locations.find((location) => location.id === salesOrder.locationId) ||
        null,
    };
  });

  return {
    results: updatedSalesOrders,
    totalResults: itemsResponse.data.maxResults,
  };
};
