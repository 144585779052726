import { roundToDecimals } from 'helpers';
import { createApiCall } from 'services/api';
import { transformItem } from 'services/items';

import {
  ConfirmRopValueItem,
  ConfirmRopValues,
  RopItem,
  RopWizardData,
} from './types';

export const postAutoROP = async (
  body: RopWizardData
): Promise<ConfirmRopValues[]> => {
  const res = await createApiCall(
    {
      path: `/v1/auto_reorder_points`,
      method: 'POST',
      body: transformToPostAutoROP(body),
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return (res.data || []).map(transformAutoROP);
};

export const postReorderPointList = async (
  body: ConfirmRopValues[]
): Promise<void> => {
  const res = await createApiCall(
    {
      path: `/v1/reorder_points`,
      method: 'POST',
      body: transformToPostReorderPoints(body),
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return (res.data || []).map(transformAutoROP);
};

const transformAutoROP = (res: any): ConfirmRopValues => {
  return {
    locationName: res.locationName,
    confirmItems: res.autoReorderPoints.map(transformConfirmRopItems),
  };
};

const transformToPostAutoROP = (data: RopWizardData): any => {
  return {
    locationIds: data.locationIds,
    dateFrom: data.chosenDates!.startDate,
    dateTo: data.chosenDates!.endDate,
    items: data.ropItems.map(transformToPostRopItem),
  };
};

const transformToPostReorderPoints = (data: ConfirmRopValues[]): any => {
  return data
    .map((i) => i.confirmItems.map(transformToPostReorderPointsItems))
    .flat();
};

const transformToPostRopItem = (data: RopItem): any => {
  return {
    itemId: data.id,
    leadTime: data.leadTime,
    orderInterval: data.orderInterval,
    safetyStock: data.safetyStock,
    notifyAt: data.notifyAt,
  };
};

const transformToPostReorderPointsItems = (data: ConfirmRopValueItem): any => {
  return {
    min: data.calculatedMin,
    max: data.calculatedMax,
    alert: data.notifyAt,
    reorderPointAlerts: data.alerts,
    locationId: data.locationId,
    itemId: data.item ? data.item.id : null,
  };
};

const transformConfirmRopItems = (res: any): ConfirmRopValueItem => {
  const storedConsumptionRate = res.storedConsumptionRate
    ? roundToDecimals(res.storedConsumptionRate, 2)
    : null;

  return {
    calculatedConsumptionRate: res.calculatedConsumptionRate,
    storedConsumptionRate,
    consumptionRate: res.calculatedConsumptionRate,
    calculatedMax: res.calculatedMax,
    calculatedMin: res.calculatedMin,
    currentMax: res.currentMax,
    currentMin: res.currentMin,
    item: transformItem(res.item),
    locationId: res.locationId,
    locationName: res.locationName,
    forecast: null,
    leadTime: null,
    notifyAt: null,
    currentNotifyAt: null,
    orderInterval: null,
    safetyStock: null,
    alerts: [],
  };
};

export const fillConfirmRopValueItems = (
  confirmValues: ConfirmRopValues[],
  ropItems: RopItem[]
): ConfirmRopValues[] => {
  const filledConfirmValues = confirmValues.map((confirmData) => {
    return {
      ...confirmData,
      confirmItems: confirmData.confirmItems.map((confirmItem) => {
        const ropItem = ropItems.find(
          (ri) => confirmItem.item && ri.id === confirmItem.item.id
        );

        if (!ropItem) {
          return confirmItem;
        }

        const notifyAt = confirmItem.calculatedConsumptionRate
          ? confirmItem.calculatedConsumptionRate * (ropItem.notifyAt || 0)
          : 0;

        return {
          ...confirmItem,
          leadTime: ropItem.leadTime,
          currentNotifyAt: Math.ceil(notifyAt),
          notifyAt: ropItem.notifyAt,
          orderInterval: ropItem.orderInterval,
          safetyStock: ropItem.safetyStock,
          alerts: ropItem.alerts,
        };
      }),
    };
  });

  return filledConfirmValues;
};
