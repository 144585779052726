import React from 'react';
import { Switch } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';

import { Routes } from './routes';
import {
  LedgersPage,
  AccountingSettingsPage,
  QbdAccountingSettingPage,
  ClassesPage,
} from '../pages';
import { AccountingRouterCmp, AccountingRouterProps } from './types';
import { PermissionType } from 'services/permissions';
import { InvoicesPage } from '../pages/InvoicesPage';

const AccountingRouter: AccountingRouterCmp = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: AccountingRouterProps
) => {
  return (
    <Switch>
      <SecureRoute
        path={LedgersPage.route}
        exact
        component={LedgersPage}
        permissions={[PermissionType.AccountingView]}
      />
      <SecureRoute
        path={ClassesPage.route}
        exact
        component={ClassesPage}
        permissions={[PermissionType.AccountingView]}
      />
      <SecureRoute
        path={InvoicesPage.route}
        exact
        component={InvoicesPage}
        permissions={[PermissionType.AccountingView]}
        featureFlag="invoicesModule"
      />
      <SecureRoute
        path={AccountingSettingsPage.route}
        exact
        component={AccountingSettingsPage}
        permissions={[PermissionType.AccountingView]}
      />
      <SecureRoute
        path={QbdAccountingSettingPage.route}
        exact
        component={QbdAccountingSettingPage}
        permissions={[PermissionType.AccountingView]}
      />
    </Switch>
  );
};

AccountingRouter.route = Routes.AccountingRouter;

export default AccountingRouter;
