import { createFilterOptions, FilterOptionsState } from '@mui/material';

import { Pagination } from 'services/search';
import { fetchUsersAPI, initialUser, User } from 'services/user';

const filter = createFilterOptions<User>();

export const userAutocompleteOptionLabel = (user: User) => {
  if (!user.id || user.id < 0) {
    return `+ Add "${user.firstName}"`;
  }

  return user.firstName || '';
};

export const userAutocompleteFilterOptions =
  (disableAdd: boolean) =>
  (options: User[], params: FilterOptionsState<User>) => {
    const filtered = filter(options, params);

    if (disableAdd) {
      return filtered;
    }

    if (!!params.inputValue) {
      filtered.unshift({
        ...initialUser,
        firstName: params.inputValue || null,
      });
    }

    return filtered;
  };

export const resolvedUserFunction = (
  expands?: string[],
  customQuickSearchColumns?: string[]
) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchUsersAPI({
      pagination,
      expands: expands,
      quickSearchValue: searchValue,
      customQuickSearchColumns,
    });
};
