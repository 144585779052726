import { ApiStatus } from 'services/api';

export interface PaymentIntegration {
  _status: ApiStatus;
  connections: any[];
}

export enum PaymentIntegrationType {
  Stripe = 'stripe',
  AuthorizeNet = 'authnet',
}
