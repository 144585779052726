import { Dates } from 'helpers';
import { SalesOrder } from 'services/salesOrders/types';
import { Pagination } from 'services/search';

export interface SalesOrderSearchResultsProps {
  salesOrders: SalesOrder[];
  pagination: Pagination;
  onPaginationChange: (pagination: Pagination) => void;
  activeSalesOrderId: null | number;
  handleSalesOrderClick: (id: number) => void;
  onAction: (type: SalesOrderTableRowActions, value: any) => void;
  onAddNewPress(): void;
  isLoadingSalesOrders: boolean;
  selectedItems: number[];
  activeSalesOrderDate: Dates;
  setSelectedItems: React.Dispatch<React.SetStateAction<number[]>>;
  onDeleteClicked: () => void;
  onPageAction: (type: SalesOrderPageAction, date?: Dates) => void;
  onUnissueClicked: () => void;
  onIssueClicked: () => void;
}
export enum SalesOrderTableRowActions {
  Duplicate,
  Delete,
}

export enum SalesOrderPageAction {
  Export,
  Import,
  CustomFields,
  ShowReport,
  ChangeDate,
}
