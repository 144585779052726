import React, { useRef, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFlags } from 'helpers/useFlags';

import { showProgressAlert } from 'services/alert/redux';
import { Dates, getErrorMessage } from 'helpers';
import {
  transformCarrier,
  Carrier,
  fetchSearch,
  getSearches,
} from 'services/carriers';
import { Pagination } from 'services/search';
import { useUrlQueryObject } from 'services/url';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import {
  showDownloadNotification,
  showLoadingNotification,
} from 'services/api/notifications';
import {
  BackgroundAction,
  BackgroundType,
  startBackgroundExport,
  startBackgroundImport,
  useBackgroundTasks,
} from 'services/backgroundTasks';

import { Routes } from '../../navigation';
import {
  CarrierDetailCard,
  CarriersSearchResults,
  CarriersAdvancedSearch,
} from './components';
import { CarriersPageCmp, CarriersPageProps } from './types';
import {
  advancedSearchReduxActions,
  CARRIER_COLUMNS,
  displayNameMap,
  initialCarriersFormValues,
  initialPagination,
} from './consts';
import { CarriersPageAction } from './components/CarriersSearchResults/consts';
import { logErrorCtx } from 'app/logging';

const CarriersPage: CarriersPageCmp = (props: CarriersPageProps) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activeCarrierId,
  } = props;

  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateCreated);
  const [, extendUrlQuery] = useUrlQueryObject();
  const dispatch = useDispatch();
  const csvEnabled = useFlags().csvImport;

  const hiddenInput = useRef<HTMLInputElement>(null);

  const { startFetching, startCsvFetching } = useBackgroundTasks();

  const exportCarriers = useCallback(async () => {
    showDownloadNotification();
    try {
      await startBackgroundExport(BackgroundType.Carrier);
      startFetching();
    } catch {
      // continue regardless of error
    }
  }, [startFetching]);

  const importCarriers = useCallback(() => {
    hiddenInput.current!.click();
  }, [hiddenInput]);

  const handleHiddenInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const uploadedFile = event.target.files[0];
      showLoadingNotification('Your Carriers import has been initiated.');

      if (csvEnabled) startCsvFetching();

      event.target.value = '';
      try {
        await startBackgroundImport(
          BackgroundType.Carrier,
          uploadedFile,
          csvEnabled
        );
        dispatch(
          showProgressAlert(
            'Your Import to Carriers has been initiated. This may take a few minutes to complete.',
            BackgroundType.Carrier,
            BackgroundAction.Import
          )
        );
      } catch (e) {
        const message = getErrorMessage(e);
        const error = e as Error;
        logErrorCtx('Error in startBackgroundImport', {
          stackTrace: error.stack,
          error,
          description: message,
          component: 'CarriersPage',
        });
        return;
      }
    },
    [dispatch]
  );

  const handleCarriersPageAction = useCallback(
    (action: CarriersPageAction, date?: Dates) => {
      switch (action) {
        case CarriersPageAction.Export:
          exportCarriers();
          break;
        case CarriersPageAction.Import:
          importCarriers();
          break;
        case CarriersPageAction.ChangeDate:
          setActiveDate(date!);
          break;
      }
    },
    [exportCarriers, importCarriers]
  );

  const handleActiveCarrierClose = () => {
    extendUrlQuery({ activeId: null });
  };

  const handleAddNewPress = () => extendUrlQuery({ activeId: -1 });

  const handleCarrierClick = (carrierId: number) => {
    // Carrier will always be found
    extendUrlQuery({ activeId: carrierId });
  };

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );

  return (
    <>
      <PageWithAdvancedSearch
        detailCardColumns={CARRIER_COLUMNS(activeDate)}
        initialFormValues={initialCarriersFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={CarriersAdvancedSearch}
        displayNameMap={displayNameMap}
        pageName="Carriers"
      >
        <PaperSlidingLayout shown={!!activeCarrierId}>
          <CarriersSearchResults
            carriers={searchResult.items}
            activeCarrierId={activeCarrierId}
            handleCarrierClick={handleCarrierClick}
            onAddNewPress={handleAddNewPress}
            activeDate={activeDate}
            isLoadingCarriers={isLoadingSearchResult}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
            onCarriersPageAction={handleCarriersPageAction}
          />
          <CarrierDetailCard
            activeCarrierId={activeCarrierId}
            onClose={handleActiveCarrierClose}
            fetchSearchResult={fetchSearchResult}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      <input
        type="file"
        ref={hiddenInput}
        style={{ display: 'none' }}
        onChange={handleHiddenInput}
        accept=".csv"
      />
    </>
  );
};

CarriersPage.route = Routes.CarriersPage;

export default withSearchResults<Carrier>(CarriersPage, {
  url: '/v1/carriers',
  dataAdapter: transformCarrier,
  columns: CARRIER_COLUMNS(Dates.DateCreated),
  initialPagination,
  fetchSearch,
  getSearches,
});
