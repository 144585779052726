import * as yup from 'yup';

export const wooCommerceSchema = yup.object().shape({
  domain: yup
    .string()
    .matches(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{2,3}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/
    ),
  key: yup.string(),
  token: yup.string(),
});
