import { PopoverOrigin } from '@mui/material';
import { EcommerceMappings } from '.';
import { EcommerceSettings, InventoryType } from './types';

const initialEcommerceCustomer = {
  currency: null,
  customer: null,
  paymentTerms: null,
  priority: null,
  representative: null,
  useDefault: false,
};

const initialEcommerceInventory = {
  locationMapping: [],
  locations: [],
  mapping: {},
  update: false,
  tags: [],
  type: 'Available',
};

const initialEcommerceItem = {
  dimensionsUnit: null,
  existing: {},
  mapping: {},
  type: null,
  fbItemType: null,
  createLinkItems: false,
  uom: null,
  weightUnit: null,
  existingFishbowl: null,
  existingEcommerce: null,
  newName: null,
  newDescription: null,
  newSku: null,
  update: false,
  create: false,
  class: null,
};

const initialEcommerceOrder = {
  enabled: true,
  createPayments: false,
  location: null,
  paymentMethod: null,
  shippingItem: null,
  shippingTerms: null,
  defaultCarrier: null,
  status: null,
  useNumber: false,
  taxItem: null,
  class: null,
  prefix: null,
};

export const initialEcommercePOS = {
  class: null,
  createPayments: false,
  location: null,
  paymentMethod: null,
  shippingItem: null,
  shippingTerms: null,
  useNumber: false,
  customer: null,
  taxItem: null,
  enabled: false,
};

export const initialEcommerceOrderExport = {
  enabled: false,
  status: null,
  locations: {},
  customField1: null,
  customField2: null,
  customField3: null,
};

const initialEcommerceChannel = {
  id: null,
  channel: null,
  name: null,
  domain: null,
};

const initialFulfillment = {
  locations: [],
  mapping: {},
  update: false,
  updateCost: false,
};

export const initialEcommerceSettings: EcommerceSettings = {
  id: null,
  customer: initialEcommerceCustomer,
  fulfillment: initialFulfillment,
  inventory: initialEcommerceInventory,
  item: initialEcommerceItem,
  order: initialEcommerceOrder,
  pos: initialEcommercePOS,
  channel: initialEcommerceChannel,
  orderExport: initialEcommerceOrderExport,
};

export const initialEcommerceSettingsMultipleConnections: EcommerceSettings[] =
  [initialEcommerceSettings];

export enum PaymentMethod {
  Cash = 'Cash',
  Check = 'Check',
  CreditDebit = 'Credit/Debit',
  GenericCreditDebit = 'Generic Credit/Debit',
  Echeck = 'E-Check',
  GiftCard = 'Gift Card',
  Invoice = 'Invoice',
  Shopify = 'Shopify',
  BigCommerce = 'Big Commerce',
}

export const initialMappingsState: EcommerceMappings = {
  name: null,
  location: {},
  item: {
    fishbowl: {},
    channel: {},
  },
  id: null,
  data: null,
  tenant: null,
  channel: null,
  error: null,
};

export const headerTitleAnchorOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
};

export const headerTitleTransformOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

export enum EcommerceLocationHash {
  Amazon = '#amazon',
  WooCommerce = '#woocommerce',
  Salesforce = '#salesforce',
  Shopify = '#shopify',
  BigCommerce = '#bigcommerce',
  Magento = '#magento',
  ShipStation = '#shipstation',
  Ebay = '#ebay',
}

export const InventoryTypeOptions: InventoryType[] = [
  { name: 'Available', code: 'Available' },
  { name: 'On Hand', code: 'OnHand' },
];
