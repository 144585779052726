import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, InputAdornment } from '@mui/material';
import _ from 'lodash';

import { Page } from 'ui/components/Page/Page';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { FBOSearchInput } from 'ui/components/SearchHeader/styled';
import { ReportId, reportList } from 'services/reports';
import { fetchLocations } from 'services/locations';
import { fetchTags } from 'services/tags';
import { fetchRepresentatives } from 'services/representatives';
import { fetchVendors } from 'services/vendors';
import { useUrlQueryObject } from 'services/url';

import { ReportsDetailsCard } from './components/ReportsDetailsCard';
import { ReportsPageTable } from './components/ReportsPageTable';
import { ReportsPagePageCmp, ReportsPageProps } from './types';
import { Routes } from '../../navigation';
import { getActiveUser } from 'services/user';
import { hasPermission } from 'services/permissions';
import { useFlags } from 'helpers/useFlags';
import { colorPalette, icons } from 'ui/theme';
import { useHistory } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ReportsPage: ReportsPagePageCmp = (props: ReportsPageProps) => {
  const dispatch = useDispatch();
  const { permissions: userPermissions, isAdmin } = useSelector(getActiveUser);
  const [urlQuery, extendUrlQuery] = useUrlQueryObject();
  const flags = useFlags();
  const history = useHistory();

  const [activeId, setActiveId] = useState<number | null>(null);
  const [search, setSearch] = useState<string | null>(null);

  const filteredReportList = useMemo(() => {
    const sortedReports = _.sortBy(reportList, 'title');

    if (!flags.inventoryAvailabilityReport) {
      _.remove(sortedReports, {
        reportId: ReportId.InventoryAvailabilityReport,
      });
    }

    if (!flags.marginsReport) {
      _.remove(sortedReports, {
        reportId: ReportId.MarginReport,
      });
    }

    if (!flags.consumptionReport) {
      _.remove(sortedReports, {
        reportId: ReportId.ConsumptionReport,
      });
    }

    if (!flags.bolReport) {
      _.remove(sortedReports, {
        reportId: ReportId.BOLReport,
      });
    }

    const reportsWithPermissions = sortedReports.filter((r) => {
      const allowedPermissions = r.permission ? [r.permission] : [];
      return hasPermission(allowedPermissions, userPermissions, isAdmin);
    });

    if (!search) {
      return reportsWithPermissions;
    }

    const rgxp = new RegExp(search, 'gi');

    return reportsWithPermissions.filter(
      (r) => r.title.match(rgxp) || r.description.match(rgxp)
    );
  }, [search, userPermissions, isAdmin, flags]);

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchLocations());
    // @ts-ignore
    dispatch(fetchTags());
    // @ts-ignore
    dispatch(fetchRepresentatives());
    // @ts-ignore
    dispatch(fetchVendors());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // navigate back to reports page if user doesn't have report permission
  useEffect(() => {
    const activeHasPermission =
      reportList.filter((r) => {
        const allowedPermissions = r.permission ? [r.permission] : [];
        return (
          hasPermission(allowedPermissions, userPermissions, isAdmin) &&
          r.id === activeId
        );
      }).length > 0;
    if (!activeHasPermission) {
      setActiveId(null);
      history.push(Routes.ReportsPage);
    }
  }, [userPermissions, isAdmin]);

  useEffect(() => {
    // Update activeId on url change
    if (urlQuery.activeId) {
      setActiveId(urlQuery.activeId);
      return;
    }
    setActiveId(null);
  }, [urlQuery]);

  const handleReportClicked = useCallback(
    (id: number) => {
      setActiveId(id);
      extendUrlQuery({ activeId: id });
    },
    [extendUrlQuery]
  );

  const handleReportsOnClose = useCallback(() => {
    setActiveId(null);
    extendUrlQuery({ activeId: null });
  }, [extendUrlQuery]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value;
      setSearch(value || null);
    },
    []
  );

  return (
    <Page>
      <Box
        sx={{
          alignItems: 'center',
          padding: '16px',
          backgroundColor: `${colorPalette.white}`,
          borderRadius: '10px',
          gap: '8px',
          marginBottom: '16px',
        }}
      >
        <FBOSearchInput
          type="text"
          placeholder="Search"
          value={search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <icons.MagnifyingGlass />
              </InputAdornment>
            ),
            sx: { paddingLeft: '8px' },
          }}
          onChange={handleSearchChange}
          dataQa="quick-search-input"
        />
      </Box>

      <PaperSlidingLayout shown={Boolean(activeId)}>
        <ReportsPageTable
          items={filteredReportList}
          activeId={activeId}
          onReportClick={handleReportClicked}
        />
        <ReportsDetailsCard
          activeId={activeId}
          onClose={handleReportsOnClose}
        />
      </PaperSlidingLayout>
    </Page>
  );
};

ReportsPage.route = Routes.ReportsPage;

export default ReportsPage;
