import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const ECOMMERCE_HISTORY_COLUMNS: Column[] = [
  {
    title: 'Date',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Message',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Result',
    type: ColumnTypes.renderByCustomRow,
  },
];
