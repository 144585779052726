import React, {
  useState,
  useCallback,
  useContext,
  useRef,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Tab, Grid, Typography, Box } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import _ from 'lodash';

import { showNotification } from 'services/api';
import { CreateSalesOrderPayment } from 'services/salesOrders';
import { createStripePayment } from 'services/integrations/payments/stripe';
import { getCurrentPaymentIntegration } from 'services/integrations/payments/redux';
import { useCurrencyFormatter } from 'helpers';
import { Modal } from 'ui/components/Modal/Modal';
import { TabPanel } from 'ui/components/TabPanel';
import { validateYup } from 'services/forms/validation';
import { salesOrderAddNewPaymentModalValidation } from 'ui/modules/sales/pages/SalesOrderPage/components/SalesOrderDetailsCard/validations';
import { PaymentIntegrationType } from 'services/integrations/payments';

import {
  PaymentModalProps,
  PaymentFormValuesCheck,
  PaymentType,
  PaymentTypeId,
} from './types';
import { PaymentTabCard, PaymentTabCash, PaymentTabCheck } from './components';
import {
  MODAL_HEIGHT,
  defaultFormValuesCard,
  defaultFormValuesCheck,
} from './consts';
import { PaymentContext } from './PaymentProvider';
import { logErrorCtx } from 'app/logging';

const PaymentModalContent: React.FC<PaymentModalProps> = (props) => {
  const {
    visible,
    onSave,
    onClose,
    handleClosePaymentModal,
    activeItem: salesOrder,
    processingPayment,
  } = props;

  const currencyFormatter = useCurrencyFormatter();

  const paymentIntegration = useSelector(getCurrentPaymentIntegration);
  const authFormButtonRef = useRef<HTMLButtonElement | null>(null);

  const stripe = useStripe();
  const elements = useElements();

  const {
    shownValues,
    paymentAmount,
    setPaymentAmount,
    activePaymentType,
    setActivePaymentType,
    formValuesCard,
    setFormValuesCard,
    validationErrors,
    setValidationErrors,
  } = useContext(PaymentContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formValuesCheck, setFormValuesCheck] =
    useState<PaymentFormValuesCheck>(defaultFormValuesCheck);

  const paymentIntegrationType: PaymentIntegrationType | null = useMemo(
    () => _.get(paymentIntegration, 'accountData.type', null),
    [paymentIntegration]
  );

  const handleActiveTabChange = useCallback(
    (_event: React.ChangeEvent<{}>, newValue: number) => {
      setActivePaymentType(newValue);
    },
    [setActivePaymentType]
  );

  const resetPaymentModalForm = useCallback(() => {
    setPaymentAmount(null);
    setFormValuesCard(defaultFormValuesCard);
    setFormValuesCheck(defaultFormValuesCheck);
  }, [setPaymentAmount, setFormValuesCard]);

  const saveCard = useCallback(() => {
    const isValid = validateYup(
      { ...formValuesCard, paymentAmount },
      salesOrderAddNewPaymentModalValidation('Card'),
      setValidationErrors
    );

    if (isValid) {
      const newPayment: CreateSalesOrderPayment = {
        paymentTypeId: PaymentTypeId.Card,
        paymentType: 'Credit/Debit',
        creditCardType: formValuesCard.cardType!,
        amount: paymentAmount!,
        lastFour: formValuesCard.lastFourDigits!,
        nameOnCard: formValuesCard.nameOnCard!,
        expirationMonth: parseInt(formValuesCard.expDate!.split('/')[0], 10),
        expirationYear: parseInt(
          '20' + formValuesCard.expDate!.split('/')[1],
          10
        ),
      };

      onSave(newPayment);
      resetPaymentModalForm();
    }
  }, [
    paymentAmount,
    resetPaymentModalForm,
    onSave,
    formValuesCard,
    setValidationErrors,
  ]);

  const saveCash = useCallback(() => {
    const isValid = validateYup(
      { paymentAmount },
      salesOrderAddNewPaymentModalValidation('Cash'),
      setValidationErrors
    );

    if (isValid) {
      const newPayment: CreateSalesOrderPayment = {
        paymentTypeId: PaymentTypeId.Cash,
        paymentType: 'Cash',
        amount: paymentAmount!,
      };

      onSave(newPayment);
      resetPaymentModalForm();
    }
  }, [paymentAmount, onSave, resetPaymentModalForm, setValidationErrors]);

  const saveCheck = useCallback(async () => {
    const isValid = validateYup(
      { ...formValuesCheck, paymentAmount },
      salesOrderAddNewPaymentModalValidation('Check'),
      setValidationErrors
    );

    if (isValid) {
      const newPayment: CreateSalesOrderPayment = {
        paymentTypeId: PaymentTypeId.Check,
        paymentType: 'Check',
        amount: paymentAmount!,
        referenceNumber: formValuesCheck.referenceNumber!,
      };

      onSave(newPayment);
      resetPaymentModalForm();
    }
  }, [
    formValuesCheck,
    paymentAmount,
    onSave,
    resetPaymentModalForm,
    setValidationErrors,
  ]);

  const submitStripe = useCallback(async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const isValid = validateYup(
      { ...formValuesCard, paymentAmount },
      salesOrderAddNewPaymentModalValidation('Stripe'),
      setValidationErrors
    );

    if (!isValid) {
      return;
    }

    try {
      const paymentSecret = await createStripePayment(
        paymentAmount! * 100,
        paymentIntegration.accountData.accountId,
        salesOrder.id!
      );

      const result = await stripe.confirmCardPayment(paymentSecret, {
        payment_method: {
          card: elements.getElement(CardElement)!,
          billing_details: {
            name: formValuesCard.nameOnCard!,
          },
        },
      });

      if (result.error) {
        // Show error to your customer (e.g., insufficient funds)
        const errorMsg = result.error.message;
        if (errorMsg) {
          showNotification(errorMsg, { variant: 'error' });
        }
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          // Show a success message to your customer
          // There's a risk of the customer closing the window before callback
          // execution. Set up a webhook or plugin to listen for the
          // payment_intent.succeeded event that handles any business critical
          // post-payment actions.
          showNotification('Payment successful', { variant: 'success' });
          onSave();
        }
      }
    } catch (e) {
      logErrorCtx('Error in createStripePayment', {
        error: e as Error,
        stackTrace: (e as Error).stack,
        component: 'PaymentModalContent',
        title: 'Error in createStripePayment',
        description: 'Error in createStripePayment',
      });
      const msg = _.get(
        e,
        'response.data.errorMessage',
        'Payment service error. Contact Support.'
      );
      if (msg) {
        showNotification(msg, { variant: 'error' });
      }
    }
  }, [
    paymentIntegration,
    elements,
    stripe,
    formValuesCard,
    paymentAmount,
    setValidationErrors,
    onSave,
    salesOrder.id,
  ]);

  const saveAuthNet = useCallback(() => {
    const isValid = validateYup(
      { ...formValuesCard, paymentAmount },
      salesOrderAddNewPaymentModalValidation('AuthNet'),
      setValidationErrors
    );

    if (!isValid) {
      return;
    }

    // click the Authorize.Net button connected to the AcceptUI script in the AuthorizeForm component to open the hosted form
    authFormButtonRef.current && authFormButtonRef.current.click();
    // close modals so the hosted form works properly
    onClose();
    handleClosePaymentModal();
    resetPaymentModalForm();
  }, [
    authFormButtonRef,
    paymentAmount,
    resetPaymentModalForm,
    handleClosePaymentModal,
    formValuesCard,
    onClose,
    setValidationErrors,
  ]);

  const handleApplyClicked = useCallback(async () => {
    setIsLoading(true);
    switch (activePaymentType) {
      case PaymentType.Card: {
        if (paymentIntegrationType === PaymentIntegrationType.Stripe) {
          await submitStripe();
        }
        if (paymentIntegrationType === PaymentIntegrationType.AuthorizeNet) {
          saveAuthNet();
        }
        saveCard();

        break;
      }
      case PaymentType.Cash:
        await saveCash();
        break;
      case PaymentType.Check:
        await saveCheck();
        break;
    }
    setIsLoading(false);
  }, [
    paymentIntegrationType,
    activePaymentType,
    saveCard,
    saveCash,
    saveCheck,
    saveAuthNet,
    submitStripe,
  ]);

  const handleCloseClicked = useCallback(() => {
    resetPaymentModalForm();
    onClose();
  }, [onClose, resetPaymentModalForm]);

  const renderNavigationModal = () => (
    <Tabs
      value={activePaymentType}
      onChange={handleActiveTabChange}
      variant="fullWidth"
      indicatorColor="primary"
    >
      <Tab label="Card" />
      <Tab label="Cash" data-qa="sale-order-payment-cash" />
      <Tab label="Check" data-qa="sale-order-payment-check" />
    </Tabs>
  );

  const applyLabel = () => {
    const pay = 'Pay using Auth.NET';
    const submit = 'Submit';

    return paymentIntegrationType === PaymentIntegrationType.AuthorizeNet
      ? pay
      : submit;
  };

  return (
    <Modal
      open={visible}
      title="Payment"
      applyLabel={applyLabel()}
      renderNavigation={renderNavigationModal}
      onApplyClicked={handleApplyClicked}
      onCancelClicked={handleCloseClicked}
      onResetClicked={resetPaymentModalForm}
      withoutDefaultPadding
      maxWidth="sm"
      customHeight={MODAL_HEIGHT}
      isLoadingContent={isLoading}
      dataQa="sale-order-payment"
      footerDivider="shadow"
    >
      <Box>
        <TabPanel value={activePaymentType} index={0} noSpacing overflow>
          <PaymentTabCard
            validationErrors={validationErrors}
            authFormButtonRef={authFormButtonRef}
          />
        </TabPanel>

        <TabPanel value={activePaymentType} index={1} noSpacing overflow>
          <PaymentTabCash
            paymentAmount={paymentAmount}
            setPaymentAmount={setPaymentAmount}
            validationErrors={validationErrors}
          />
        </TabPanel>

        <TabPanel value={activePaymentType} index={2} noSpacing overflow>
          <PaymentTabCheck
            formValues={formValuesCheck}
            setFormValues={setFormValuesCheck}
            paymentAmount={paymentAmount}
            setPaymentAmount={setPaymentAmount}
            validationErrors={validationErrors}
          />
        </TabPanel>
      </Box>
      <Box px={4} pb={4} flex="1" display="flex" alignItems="flex-end">
        <Grid container justifyContent="flex-end">
          <Grid item container spacing={2} xs={6}>
            <Grid item xs={6}>
              <Typography variant="caption">Total: </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Typography>{currencyFormatter(shownValues.total)}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption">Total Paid: </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Typography>
                {processingPayment
                  ? '--'
                  : currencyFormatter(shownValues.totalPayments)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption">Current Payment: </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Typography>
                {processingPayment ? (
                  '--'
                ) : (
                  <b>{currencyFormatter(paymentAmount || 0)}</b>
                )}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption">Balance: </Typography>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <Typography>
                {processingPayment
                  ? '--'
                  : currencyFormatter(shownValues.balance)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default React.memo(PaymentModalContent);
