import { Dates } from 'helpers';
import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
  PermissionType,
} from 'services/permissions';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { PaginationDirection } from 'services/url';
import { MenuItem } from 'ui/components/Menu';
import { ColumnTypes, Column } from 'ui/components/Table/ItemsTable';
import { IconNames } from 'ui/theme';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'name',
    direction: PaginationDirection.Ascending,
  },
  totalRows: 25,
};

export const initialPermisionFormValues: AdvancedSearchFormValues = {
  name: null,
};

export const displayNameMap = {
  name: 'Name',
};

export const permissionColumns = (date: Dates): Column[] => {
  const resolveDateColumn = (): Column => {
    switch (date) {
      case Dates.DateLastModified:
        return {
          title: 'Date Last Modified',
          field: Dates.DateLastModified,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      default:
        return {
          title: 'Date Created',
          field: Dates.DateCreated,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
    }
  };

  return [
    {
      title: 'Name',
      field: 'name',
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Users',
      type: ColumnTypes.renderByCustomRow,
    },
    resolveDateColumn(),
  ];
};

export enum PermissionsPageAction {
  ChangeDate,
  Import,
  Export,
}

export const createActionBarOptions = (
  onActionClick: (actionType: PermissionsPageAction, date?: Dates) => void,
  permissionsCount: number
): MenuItem[] => [
  {
    label: 'Import Permissions',
    icon: IconNames.FBOImport,
    onOptionClick: () => onActionClick(PermissionsPageAction.Import),
    permissions: [
      PermissionType.PermissionCreate,
      PermissionType.PermissionEdit,
    ],
    separateAfter: true,
  },
  {
    label: 'Export Permissions',
    icon: IconNames.FBOExport,
    onOptionClick: () => onActionClick(PermissionsPageAction.Export),
    disabled: !permissionsCount,
    separateAfter: true,
  },
  {
    icon: IconNames.FBORecycleSmall,
    label: 'Change Dates',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(PermissionsPageAction.ChangeDate, Dates.DateCreated),
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(
            PermissionsPageAction.ChangeDate,
            Dates.DateLastModified
          ),
      },
    ],
  },
];
