import { ConfirmRopValueItem } from '../../types';

/*
Reorder point calculation:

displayed daily consumption rate (CS) = daily consumption rate from server * (1+(forcast %))

min = CS * (safety stock + lead time)
max = CS * (safety stock + lead time + order interval)
alert = CS * notify at

*/
export const calculateConsumptionRate = (
  forecast: number,
  consumptionRate: number
): number => {
  if (!consumptionRate) {
    return 0;
  }
  if (!forecast) {
    return consumptionRate;
  }
  return (1 + forecast / 100) * consumptionRate;
};

export const calculateMin = (
  confirmItem: ConfirmRopValueItem,
  consumptionRate: number
): number => {
  if (!consumptionRate) {
    return 0;
  }
  const min =
    consumptionRate *
    ((confirmItem.safetyStock || 0) + (confirmItem.leadTime || 0));

  return Math.ceil(min);
};

export const calculateMax = (
  confirmItem: ConfirmRopValueItem,
  consumptionRate: number
): number => {
  if (!consumptionRate) {
    return 0;
  }
  const max =
    consumptionRate *
    ((confirmItem.safetyStock || 0) +
      (confirmItem.leadTime || 0) +
      (confirmItem.orderInterval || 0));
  return Math.ceil(max);
};

export const calculateAlert = (
  confirmItem: ConfirmRopValueItem,
  consumptionRate: number
): number => {
  if (!consumptionRate) {
    return 0;
  }
  const alert = consumptionRate * (confirmItem.notifyAt || 0);
  return Math.ceil(alert);
};
