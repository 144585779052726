import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useCustomSubRowStyle = makeStyles(() => ({
  statusBar: {
    width: 'calc(100% - 106px)',
    height: 10,
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    alignItems: 'center',
  },
  statusSafety: {
    backgroundColor: colorPalette.statusRed,
    height: 5,
  },
  statusLead: {
    backgroundColor: colorPalette.statusOrange,
    height: 5,
  },
  statusInterval: {
    backgroundColor: colorPalette.statusGreen,
    height: 5,
  },
  notifyAt: {
    position: 'absolute',
    top: -1,
    width: 3,
    height: 10,
  },
  notifyAtIcon: {
    fontSize: 14,
    marginLeft: -6,
  },
}));

export const usePopoverItemStyle = makeStyles(() => ({
  popover: {
    cursor: 'pointer',
    '&:hover': {
      background: '#f5f5f5',
    },
  },
}));

export const useCustomRowStyle = makeStyles(() => ({
  tableCell: {
    borderBottom: 0,
  },
}));
