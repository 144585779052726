import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useItemSelecorStyle = makeStyles((theme: Theme) => ({
  tableColumn: {
    width: '49%',
    overflow: 'auto',
    borderRight: '1px solid #EBEBEB',
    backgroundColor: '#fff',
    // cant scroll to bottom on widescreen monitors fix
    height: '101%',
  },
  tableColumnPicked: {
    width: '50%',
    overflow: 'auto',
    borderLeft: '1px solid #EBEBEB',
    backgroundColor: '#fff',
  },
  searchInputInner: {
    height: 32,
    background: 'white',
    paddingTop: `0!important`,
    paddingBottom: `0!important`,
  },
  searchInputOuter: {
    flex: 1,
    paddingRight: 5,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  noBorder: {
    border: 0,
  },
  statusBar: {
    width: '100%',
    height: 32,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
}));
