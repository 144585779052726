import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { HistoryTooltipProps } from './types';

const HistoryTooltip: React.FC<HistoryTooltipProps> = (props) => {
  const { title, disabled, children, placement = 'top-start' } = props;

  const tooltipTitle = () => {
    return <Typography>{title}</Typography>;
  };

  return (
    <Tooltip
      placement={placement}
      disableHoverListener={disabled}
      title={tooltipTitle()}
    >
      {children}
    </Tooltip>
  );
};

export default HistoryTooltip;
