import React, { memo, useEffect, useState } from 'react';
import { TableCell, Tooltip } from '@mui/material';
import moment from 'moment';
import { IconNames, colorPalette } from 'ui/theme';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { useCurrencyFormatter, useGetIntlDateFormatString } from 'helpers';
import {
  PaymentStatus,
  SalesOrderPayment,
  SalesOrderPaymentType,
  SalesOrderTransactionType,
} from 'services/salesOrders';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { PaymentIntegrationType } from 'services/integrations/payments';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const PaymentRow: React.FC<RenderCustomRowProps<SalesOrderPayment>> = (
  props
) => {
  const { row: payment, onAction } = props;

  const currencyFormatter = useCurrencyFormatter();
  const intlFormatDate = useGetIntlDateFormatString();

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);

  const paymentTypeName = _.get(payment.paymentType, 'name', null);

  useEffect(() => {
    if (tooltipOpen) {
      setTimeout(() => {
        setTooltipOpen(false);
      }, 2000);
    }
  }, [tooltipOpen]);

  const onRefundIconClick = () => {
    if (onAction) {
      onAction(payment);
    }
  };

  const copyToClipboard = () => {
    if (!payment.data.requestLink) {
      return;
    }

    navigator.clipboard.writeText(payment.data.requestLink);
    setTooltipOpen(true);
  };

  const renderActionButton = () => {
    if (payment.data.type === PaymentIntegrationType.AuthorizeNet) {
      return;
    }

    if (
      paymentTypeName == SalesOrderPaymentType.RequestLink &&
      payment.status == PaymentStatus.Pending
    ) {
      return (
        <Tooltip open={tooltipOpen} placement="top-end" title="Copied">
          <FBOButton
            variant="tertiary"
            color="neutral"
            size="medium"
            icon={IconNames.IconCopy}
            onClick={copyToClipboard}
            data-qa="payment-row-copy-button"
            style={{ color: colorPalette.redesign.contentPrimary }}
          />
        </Tooltip>
      );
    }

    return (
      payment.transactionType === SalesOrderTransactionType.Charge &&
      !payment.refunded && (
        <Tooltip title="Refund Payment">
          <FBOButton
            variant="tertiary"
            color="neutral"
            size="medium"
            icon={IconNames.IconRefund}
            onClick={onRefundIconClick}
            data-qa="payment-row-refund-button"
            style={{ color: colorPalette.redesign.contentPrimary }}
          />
        </Tooltip>
      )
    );
  };

  return (
    <>
      <TableCell>
        {moment(payment.date || undefined).format(intlFormatDate)}
      </TableCell>
      <TableCell>{paymentTypeName}</TableCell>
      <TableCell>{payment.transactionType}</TableCell>
      <TableCell>
        <StatusLabel status={payment.status} />
      </TableCell>
      <TableCell>{currencyFormatter(payment.amount || 0)}</TableCell>
      <TableCell padding="none" align="center">
        {renderActionButton()}
      </TableCell>
    </>
  );
};

export default memo(PaymentRow);
