import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { DateRange } from 'materialui-daterange-picker';

import { Item, ItemType } from 'services/items';
import { Location } from 'services/locations';
import { User } from 'services/user';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';

import { ReportFiltersProps } from '../../types';
import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import LocationsAsyncAutocomplete from 'ui/components/Autocomplete/LocationsAsyncAutocomplete/LocationsAsyncAutocomplete';
import { useHandleTextFieldChange } from 'services/forms/hooks';
import { TextField } from 'ui/components/TextField/TextField';
import { UserAsyncAutocomplete } from 'ui/components/Autocomplete/UserAsyncAutocomplete';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { EventTypes } from './const';

const InventoryEventHistoryFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters } = props;
  const selectedDateRange = useMemo(() => {
    return parameters.dateRange || {};
  }, [parameters]);
  const [selectedEvents, setSelectedEvents] = useState<EventTypes[]>(
    Object.values(EventTypes)
  );
  const handleSelectAll = useCallback(
    () => (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      if (checked) {
        setSelectedEvents(Object.values(EventTypes));
      } else {
        setSelectedEvents([]);
      }
      setParameters((old) => ({
        ...old,
        selectAllOptional: checked,
        inventoryEvents: checked ? Object.values(EventTypes) : [],
      }));
    },
    [setParameters]
  );
  const handleItemChange = useCallback(
    (selectedItem: Item | null) => {
      setParameters((old) => ({
        ...old,
        itemId: selectedItem ? selectedItem.id : null,
      }));
    },
    [setParameters]
  );
  const handleDateChange = useCallback(
    (value: DateRange) => {
      setParameters((old) => ({ ...old, dateRange: value }));
    },
    [setParameters]
  );
  useEffect(() => {
    if (selectedEvents.length !== Object.values(EventTypes).length) {
      setParameters((old) => ({
        ...old,
        selectAllOptional: false,
        inventoryEvents: selectedEvents,
      }));
    }
  }, [selectedEvents]);
  const handleLocationChange = useCallback(
    (loc: Location | null) => {
      setParameters((old) => ({
        ...old,
        locationId: loc ? loc.id : null,
        locationChildId: null,
      }));
    },
    [setParameters]
  );

  const handleLocationChildChange = useCallback(
    (loc: Location | null) => {
      setParameters((old) => ({
        ...old,
        locationChildId: loc ? loc.id : null,
      }));
    },
    [setParameters]
  );

  const handleUserChange = useCallback(
    (user: User | null) => {
      setParameters((old) => ({
        ...old,
        userId: user ? user.id : null,
      }));
    },
    [setParameters]
  );

  const handleTextFieldChange = useHandleTextFieldChange(
    setParameters,
    parameters
  );
  const handleEventsChange = (event: any, newValues: EventTypes[]) => {
    setSelectedEvents(newValues);
  };
  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      px={2}
      pb={0}
      pt="16px"
    >
      <Box>
        <DateRangePicker
          className="date-picker-small-font"
          label="Date Range"
          value={selectedDateRange}
          name="dateRange"
          placeholder="Select date range"
          onChange={handleDateChange}
          fullWidth
        />
      </Box>
      <Box>
        <UserAsyncAutocomplete
          label="User"
          value={parameters.userId}
          placeholder="Select User"
          onChange={handleUserChange}
        />
      </Box>
      <Box>
        <LocationsAsyncAutocomplete
          label="Root Location"
          placeholder="Select root location"
          value={parameters.locationId}
          onChange={handleLocationChange}
          parentId={null}
          companyWide={false}
          disableAdd
        />
      </Box>
      <Box>
        <LocationsAsyncAutocomplete
          label="Descendent Location"
          placeholder="Select descendent location"
          value={parameters.locationChildId}
          onChange={handleLocationChildChange}
          parentId={parameters.locationId}
          companyWide={false}
          disableAdd
        />
      </Box>
      <Box>
        <ItemsAutocomplete
          label="Item"
          placeholder="Select item"
          onChange={handleItemChange}
          value={parameters.itemId}
          itemTypes={[ItemType.Inventory]}
          customQuickSearchColumns={['name']}
        />
      </Box>
      <Box>
        <TextField
          className="redesign"
          variant="standard"
          label="Tracking Info"
          placeholder="Tracking Info"
          name="trackingInfo"
          type="text"
          value={parameters.trackingInfo}
          onChange={handleTextFieldChange}
          fullWidth
        />
      </Box>

      <Box display="flex" flexDirection="column" width="100%" pt={1}>
        <Box>
          <FormControlLabel
            className="redesign"
            sx={{ marginBottom: '24px' }}
            control={
              <Checkbox
                className="redesign"
                checked={Boolean(parameters.selectAllOptional)}
                onChange={handleSelectAll()}
                color="primary"
                data-qa="inventory-event-history-report-select-all"
              />
            }
            label="Select All Event Types"
          />
        </Box>
        <Box>
          <Autocomplete
            className="multi-line"
            label="Inventory Event Types"
            placeholder="Select inventory Event Types"
            options={Object.values(EventTypes)}
            value={selectedEvents}
            onChange={handleEventsChange}
            dataQa="select-inventory-event-types"
            multiple
          />
        </Box>
      </Box>
    </Box>
  );
};

export default memo(InventoryEventHistoryFilters);
