import { SalesOrder } from 'services/salesOrders';

export interface ItemFooterData {
  salesOrder: SalesOrder;
  validationErrors: any;
  oldState: React.MutableRefObject<SalesOrder | null>;
}

export interface ItemFooterActions {
  setSalesOrder: React.Dispatch<React.SetStateAction<SalesOrder>>;
  handleCalculateTaxesClicked: () => Promise<void>;
}

export enum DiscountTypes {
  None,
  Percent,
  FlatRate,
}

export interface Discount {
  type: DiscountTypes;
  value: number;
}
