import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Channel, fetchTaxRates, getTaxRates } from 'services/taxRates';
import { Modal } from 'ui/components/Modal/Modal';
import { InternationalTaxRates } from './InternationalTaxRates';
import { routes, TaxMappingRates, TaxRateMappingModalProps } from './types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  getXeroAccountIsConnected,
  InternationalTaxRate,
} from 'services/integrations/xero';
import { getQuickbooksAccountIsConnected } from 'services/integrations/quickbooks';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import { updateTaxRates } from './api';
import { useHistory } from 'react-router-dom';
import { removeObjectFromArrayByField } from './helper';
import { DetailsCard } from 'ui/components/Page/DetailsCard';

const TaxRateMappingModal: React.FC<TaxRateMappingModalProps> = (props) => {
  const { open, onClose } = props;
  const dispatch = useDispatch();
  const { items: taxes } = useSelector(getTaxRates);
  const xeroConnected = useSelector(getXeroAccountIsConnected);
  const qboConnected = useSelector(getQuickbooksAccountIsConnected);
  const [accountingType, setAccountingType] = useState(Channel.None);
  const [contentLoading, setContentLoading] = useState(false);
  const history = useHistory();

  const [selectedMappedAccounts, setSelectedMappedAccounts] = useState<
    TaxMappingRates[]
  >([]);
  const oldState = useRef<TaxMappingRates[] | null>([]);

  useEffect(() => {
    if (xeroConnected) setAccountingType(Channel.Xero);
    if (qboConnected) setAccountingType(Channel.Qbo);
  }, [xeroConnected, qboConnected]);
  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchTaxRates());
  }, [dispatch]);
  const handleInternationalTaxChange = useCallback(
    (taxRate: InternationalTaxRate, index?: number): boolean => {
      if (index) {
        const obj = {
          taxId: index,
          channelsTaxRate: {
            ...taxRate,
          },
        };
        removeObjectFromArrayByField(selectedMappedAccounts, taxRate.code);
        setSelectedMappedAccounts([...selectedMappedAccounts, obj]);
      }

      return true;
    },
    [selectedMappedAccounts, setSelectedMappedAccounts]
  );
  const saveMappedTaxRates = useCallback(async () => {
    setContentLoading(true);
    oldState.current = selectedMappedAccounts;

    try {
      await updateTaxRates(
        selectedMappedAccounts,
        accountingType.toLocaleLowerCase()
      );
    } catch {
      // continue regardless of error
    }

    setContentLoading(false);
    onClose();
    return true;
  }, [selectedMappedAccounts, onClose]);
  const ModalActions = () => {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box mr={2}>
          <Button
            variant="outlined"
            onClick={() => history.push(`${routes.AddNewTaxRates}`)}
          >
            Create New Tax In Drive
          </Button>
        </Box>
        <Box display="flex">
          <Box mr={2}>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Box>
          <NetworkSpinnerWrapper isLoading={contentLoading} size={24}>
            <Button
              variant="contained"
              color="primary"
              onClick={saveMappedTaxRates}
            >
              Save
            </Button>
          </NetworkSpinnerWrapper>
        </Box>
      </Box>
    );
  };
  return (
    <DetailsCard
      onSubmit={saveMappedTaxRates}
      isLoading={contentLoading}
      state={selectedMappedAccounts}
      oldState={oldState}
    >
      <Modal
        open={open}
        onCancelClicked={onClose}
        title="Tax Mapping"
        ModalActionsComponent={ModalActions}
        withoutDefaultPadding
        customHeight={700}
        maxWidth="md"
        footerDivider="shadow"
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>FBO Tax Rates</TableCell>
              <TableCell></TableCell>
              <TableCell>
                {xeroConnected ? 'Xero Tax Rates' : 'Quickbook Tax Rates'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taxes.length > 0 ? (
              taxes.map((tax) => (
                <TableRow key={tax.id}>
                  <TableCell>{tax.name}</TableCell>
                  <TableCell style={{ width: '30px' }}>
                    <NavigateNextIcon />
                  </TableCell>
                  <TableCell>
                    {' '}
                    <InternationalTaxRates
                      accountingType={accountingType}
                      label={
                        xeroConnected ? 'Xero mapping' : 'quickbook mapping'
                      }
                      selectedOptionCallback={handleInternationalTaxChange}
                      index={tax.id as number | undefined}
                      // @ts-ignore
                      selectedValue={
                        tax.channel
                          ? {
                              name: tax.mappedTaxName,
                              rate: tax.channelTaxRate,
                              code: tax.code,
                              taxType: tax.channelTaxType,
                            }
                          : null
                      }
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <Typography
                color="textSecondary"
                component="div"
                display="flex"
                marginTop="40%"
                marginLeft="30%"
              >
                ADD NEW TAX RATE BY PRESSING 'CREATE NEW TAX IN DRIVE'
              </Typography>
            )}
          </TableBody>
        </Table>
      </Modal>
    </DetailsCard>
  );
};

export default memo(TaxRateMappingModal);
