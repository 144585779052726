import PaymentIcon from '@mui/icons-material/Payment';
import { ChannelType } from 'services/integrations/ecommerce';

import { NavigationDescriptor } from './types';

export const cardNavigationDescriptor: NavigationDescriptor[] = [
  {
    id: 'shopify',
    icon: PaymentIcon,
    title: 'Shopify',
    separate: false,
    channel: ChannelType.Shopify,
  },
  {
    id: 'woocommerce',
    icon: PaymentIcon,
    title: 'WooCommerce',
    separate: false,
    channel: ChannelType.WooCommerce,
  },
  {
    id: 'amazon',
    icon: PaymentIcon,
    title: 'Amazon',
    separate: false,
    channel: ChannelType.Amazon,
  },
  {
    id: 'magento',
    icon: PaymentIcon,
    title: 'Adobe Commerce',
    separate: false,
    channel: ChannelType.Magento,
  },
  {
    id: 'salesforce',
    icon: PaymentIcon,
    title: 'Salesforce',
    separate: false,
    channel: ChannelType.Salesforce,
  },
  {
    id: 'bigcommerce',
    icon: PaymentIcon,
    title: 'BigCommerce',
    separate: false,
    channel: ChannelType.BigCommerce,
  },
  {
    id: 'shipstation',
    icon: PaymentIcon,
    title: 'ShipStation',
    separate: false,
    channel: ChannelType.ShipStation,
  },
  {
    id: 'ebay',
    icon: PaymentIcon,
    title: 'Ebay',
    separate: false,
    channel: ChannelType.Ebay,
  },
];
