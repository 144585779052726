import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const receiveWizardColumns: Column[] = [
  {
    title: 'Items',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Quantity',
    type: ColumnTypes.renderByCustomRow,
  },
];

export const RECEIVE_ITEM_TABLE_HEIGHT = 500;
