import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getLocations, Location } from 'services/locations';
import { MultiSelect } from 'ui/components/Autocomplete/MultiSelect';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { MappingLocationsProps } from './types';

const MappingLocations: React.FC<MappingLocationsProps> = (props) => {
  const {
    identifier,
    classes,
    mappingLocations,
    errors,
    setEcommerceSettings,
    location,
  } = props;

  const { items: locations } = useSelector(getLocations);

  const [locationCheckboxes, setLocationCheckboxes] = useState<{
    [id: number]: boolean;
  }>({});

  const locationOptions = useMemo(
    () => locations.filter((l) => !l.parentLocationId && !l.companyWide),
    [locations]
  );

  const getLocValues = (val: number[]) => {
    const locValues: Location[] = [];
    val.forEach((locId: number) => {
      const l = locationOptions.find((l) => l.id === locId);
      if (l) locValues.push(l);
    });
    return locValues;
  };

  useEffect(() => {
    const newMappings: any = {};
    const newLocationCheckboxes: { [id: number]: boolean } = {};
    if (location) {
      Object.entries(location).forEach(([id]: any) => {
        if (!mappingLocations[id]) {
          newMappings[id] = [];
          newLocationCheckboxes[id] = false;
          return;
        }
        newMappings[id] = mappingLocations[id];
        newLocationCheckboxes[id] = mappingLocations[id].length > 0;
      });

      setLocationCheckboxes(newLocationCheckboxes);

      switch (identifier) {
        case 'orderExport':
          setEcommerceSettings((old) => ({
            ...old,
            orderExport: { ...old.orderExport, locations: newMappings },
          }));
          break;
        case 'inventory':
          setEcommerceSettings((old) => ({
            ...old,
            inventory: { ...old.inventory, mapping: newMappings },
          }));
          break;
        case 'fullfilment':
          setEcommerceSettings((old) => ({
            ...old,
            fulfillment: { ...old.fulfillment, mapping: newMappings },
          }));
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [location]);

  const handleAutoCompleteChange = useCallback(
    (id: number) => (values: Location[] | null) => {
      const newvalues = values ? values.map((l) => l.id!) : [];

      switch (identifier) {
        case 'orderExport':
          setEcommerceSettings((old) => ({
            ...old,
            orderExport: {
              ...old.orderExport,
              locations: {
                ...old.orderExport.locations,
                [id]: newvalues,
              },
            },
          }));
          break;
        case 'inventory':
          setEcommerceSettings((old) => ({
            ...old,
            inventory: {
              ...old.inventory,
              mapping: {
                ...old.inventory.mapping,
                [id]: newvalues,
              },
            },
          }));
          break;
        case 'fullfilment':
          setEcommerceSettings((old) => ({
            ...old,
            fulfillment: {
              ...old.fulfillment,
              mapping: {
                ...old.fulfillment.mapping,
                [id]: newvalues,
              },
            },
          }));
          break;
        default:
          break;
      }
    },
    [setEcommerceSettings, identifier]
  );

  const handleLocations = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const id = event.target.value;

      setLocationCheckboxes((old) => ({
        ...old,
        [id]: checked,
      }));

      if (!checked) {
        switch (identifier) {
          case 'orderExport':
            setEcommerceSettings((old) => ({
              ...old,
              orderExport: {
                ...old.orderExport,
                locations: {
                  ...old.orderExport.locations,
                  [id]: [],
                },
              },
            }));
            break;
          case 'inventory':
            setEcommerceSettings((old) => ({
              ...old,
              inventory: {
                ...old.inventory,
                mapping: {
                  ...old.inventory.mapping,
                  [id]: [],
                },
              },
            }));
            break;
          case 'fullfilment':
            setEcommerceSettings((old) => ({
              ...old,
              fulfillment: {
                ...old.fulfillment,
                mapping: {
                  ...old.fulfillment.mapping,
                  [id]: [],
                },
              },
            }));
            break;
          default:
            break;
        }
      }
    },
    [setEcommerceSettings, identifier]
  );

  return (
    <>
      <Box className={classes.modalRowExpandable}>
        <Box className={classes.modalRow}>
          <Typography variant="body1" color="textPrimary">
            Map Locations
          </Typography>
        </Box>

        {Object.entries(mappingLocations).map(([id, value]: any, index) => {
          return (
            <Box className={classes.modalRowInner} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={handleLocations}
                    checked={!!locationCheckboxes[id]}
                    color="primary"
                    value={id}
                  />
                }
                label={location[id]}
              />

              <Box
                width="10%"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <NavigateNextIcon />
              </Box>

              <Box width="40%">
                <MultiSelect
                  label="Fishbowl locations"
                  options={locationOptions}
                  values={getLocValues(value)}
                  getOptionLabel={(o) => o.displayName}
                  onChange={(values) => handleAutoCompleteChange(id)(values)}
                  error={!!errors.mappings}
                  disabled={!locationCheckboxes[id]}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default memo(MappingLocations);
