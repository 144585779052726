import React, { memo, useState, useMemo } from 'react';
import { TableCell, Box, Link, Typography } from '@mui/material';
import _ from 'lodash';

import {
  Column,
  RenderCustomRowProps,
} from 'ui/components/Table/ItemsTable/types';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import {
  itemHasSerialTracking,
  ItemTrackingType,
  TrackingGroup,
} from 'services/inventory';
import { TrackingDataTypes, TrackingType } from 'services/settings/tracking';
import { PickItem } from 'services/picking';
import { getTrackingTypeColumns } from 'ui/components/Table/TrackingTable/components/TrackingRow/helpers';
import { SerialModal } from 'ui/components/Table/TrackingTable/components/TrackingRow/components';
import { TrackingTableTypes } from 'ui/components/Table/TrackingTable';
import { ItemType } from 'services/items';
import { getUoms } from 'services/uoms';
import { useSelector } from 'react-redux';
import { formatDatePreview, useGetIntlDateFormatString } from 'helpers';
import { getSettingsCompanyCountry } from 'services/settings/company';

const SummaryStepRow: React.FC<RenderCustomRowProps<PickItem>> = (props) => {
  const { row, columns } = props;
  const [serialModalVisible, setSerialModalVisible] = useState(false);
  const intlFormatDate = useGetIntlDateFormatString();
  const companyCountry = useSelector(getSettingsCompanyCountry);
  const trackingGroup: TrackingGroup = row.trackingGroupList[0];
  const uoms = useSelector(getUoms).items;
  const selectedUom = useMemo(() => {
    return uoms && row.uomId ? uoms.find((u) => u.id === row.uomId) : null;
  }, [uoms, row.uomId]);

  // we calculate the amount to commit / split
  const amount = useMemo(() => {
    const amount = _.get(row, 'amount') || 0;
    const quantity = _.get(row, 'quantity') || 0;

    if (columns.some((c) => c.title === 'Qty to Split')) {
      return quantity - amount;
    }

    return amount;
  }, [columns, row]);

  const quantityAtLocation = useMemo(() => {
    if (!row.item || row.item.itemType !== ItemType.Inventory) {
      return 'Non Inventory Item';
    }
    // items with tracking groups have different available quantity
    if (row.trackingGroupList[0]) {
      return `${row.trackingGroupList[0].onHand || 0} ${_.get(
        selectedUom,
        'abbreviation',
        ''
      )}`;
    }
    return `${row.quantityAvailable || 0} ${_.get(
      selectedUom,
      'abbreviation',
      ''
    )}`;
  }, [row]);

  const serialList = useMemo(() => {
    const serialList = trackingGroup ? [...trackingGroup.serialList] : [];
    const serialIds = trackingGroup ? trackingGroup.serialIds : [];

    return serialList.filter((i) => serialIds.some((si) => si === i.id));
  }, [trackingGroup, columns]);

  const serialItemTrackingTypes: ItemTrackingType[] = useMemo(() => {
    // For serial table we need only serial tracking types
    if (!row.item) {
      return [];
    }
    return row.item.itemTrackingTypeList.filter(
      (t) =>
        _.get(t, 'trackingType.trackingDataType', null) ===
        TrackingDataTypes.SerialNumber
    );
  }, [row.item]);

  const renderTrackingColumn = (column: Column): JSX.Element | null => {
    const trackingDataType: TrackingDataTypes = _.get(
      column,
      'meta.trackingDataType'
    );
    const trackingType: TrackingType = _.get(column, 'meta.trackingType', {});
    const itemHasSerial = row.item ? itemHasSerialTracking(row.item) : false;

    if (!trackingGroup) {
      return <></>;
    }
    const trackingTypeIndex = trackingGroup.trackingInfoList.findIndex(
      (t) => t.trackingTypeId === trackingType.id
    );
    const trackingInfo = trackingGroup.trackingInfoList[trackingTypeIndex];

    switch (trackingDataType) {
      case TrackingDataTypes.Date: {
        const formattedDateValue = formatDatePreview(
          trackingInfo?.value,
          companyCountry,
          intlFormatDate
        );
        return <Typography>{formattedDateValue}</Typography>;
      }
      case TrackingDataTypes.Text:
        return <Typography>{trackingInfo?.value}</Typography>;
      case TrackingDataTypes.SerialNumber:
        return itemHasSerial ? (
          row.autoAssign ? (
            <Typography>Auto Assigned</Typography>
          ) : (
            <Link
              href="#"
              underline="always"
              onClick={() => setSerialModalVisible(true)}
              data-qa="serial"
            >
              View Serials
            </Link>
          )
        ) : (
          <></>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TableCell>
        <Box>
          <ImageItemBox
            source={_.get(row.item, 'defaultImage', null)}
            name={_.get(row.item, 'name', null)}
            description={_.get(row.item, 'description', null)}
          />
        </Box>
      </TableCell>
      <TableCell>
        <Box>{quantityAtLocation}</Box>
      </TableCell>
      <TableCell>
        <Box>{`${amount} ${_.get(selectedUom, 'abbreviation', '')}`}</Box>
      </TableCell>
      {getTrackingTypeColumns(columns).map((column) => (
        <TableCell key={column.title}>
          <Box>{renderTrackingColumn(column)}</Box>
        </TableCell>
      ))}
      <SerialModal
        visible={serialModalVisible}
        tableType={TrackingTableTypes.View}
        itemTrackingTypes={serialItemTrackingTypes}
        quantity={amount}
        serialList={serialList}
        onApplyClicked={() => setSerialModalVisible(false)}
        onCloseClicked={() => setSerialModalVisible(false)}
      />
    </>
  );
};

export default memo(SummaryStepRow);
