import React, { memo, useContext } from 'react';
import { Box, Grid } from '@mui/material';
import { CardElement } from '@stripe/react-stripe-js';

import { useHandleTextFieldChange } from 'services/forms';
import { TextField } from 'ui/components/TextField/TextField';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { PaymentContext } from 'ui/components/Modal/PaymentModal/PaymentProvider';

import './Styles.css';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const StripeForm: React.FC = () => {
  const {
    paymentAmount,
    setPaymentAmount,
    formValuesCard,
    setFormValuesCard,
    validationErrors,
  } = useContext(PaymentContext);

  const handleTextFieldChange = useHandleTextFieldChange(
    setFormValuesCard,
    formValuesCard
  );

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentAmount(e.target.value ? parseFloat(e.target.value) : null);
  };

  return (
    <Box p={3}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CurrencyField
            label="Payment Amount"
            placeholder="Enter amount"
            name="amount"
            fullWidth
            value={paymentAmount}
            required
            onChange={handleAmountChange}
            error={!!validationErrors.paymentAmount}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="text"
            label="Name On Card"
            placeholder="Enter name"
            name="nameOnCard"
            fullWidth
            value={formValuesCard.nameOnCard}
            required
            onChange={handleTextFieldChange}
            error={!!validationErrors.nameOnCard}
            autoComplete="disabled"
            dataQa="sale-order-payment-name-on-card"
          />
        </Grid>
        <Grid item xs={12}>
          <CardElement options={CARD_ELEMENT_OPTIONS} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(StripeForm);
