import React, { useMemo } from 'react';

import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { Pagination } from 'services/search';
import { arrayHasAllDeletedObjects } from 'helpers';
import { PermissionType } from 'services/permissions';
import { UomSearchResultsProps } from './types';
import { UOM_COLUMNS } from '../../consts';
import { createUomsSearchActionBarOptions } from './consts';
import { default as UomRow } from './UomRow';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const UomSearchResults: React.FC<UomSearchResultsProps> = (props) => {
  const {
    uoms,
    activeUomId,
    handleUomClick,
    onAddNewPress,
    pagination,
    activeDate,
    onPaginationChange,
    isLoadingUoms,
    onUomsPageAction,
  } = props;

  const allDeletedUoms = useMemo(() => arrayHasAllDeletedObjects(uoms), [uoms]);

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  const alwaysOnColumns = useMemo(
    () =>
      UOM_COLUMNS(activeDate).filter(
        (c) => c.title === 'Name' || c.title === 'Abbreviation'
      ),
    [activeDate]
  );

  return (
    <>
      <FBOTitleBar title="Unit of Measure">
        {activeUomId !== -1 && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant={!activeUomId ? 'primary' : 'secondary'}
            color="positive"
            size="medium"
            icon="FBOAddCircle"
            onClick={onAddNewPress}
            data-qa="uom-new"
            permissions={[PermissionType.UomCreate]}
          >
            {!activeUomId ? 'Add New' : ''}
          </FBOButton>
        )}
        <FBOMenuButton
          variant="secondary"
          items={createUomsSearchActionBarOptions(
            onUomsPageAction,
            uoms.length
          )}
          data-qa="uom-page-three-dot-menu"
        />
      </FBOTitleBar>

      <ItemsTable
        data={uoms}
        columns={activeUomId ? alwaysOnColumns : UOM_COLUMNS(activeDate)}
        activeId={activeUomId}
        onItemClick={handleUomClick}
        selectableItems={false}
        isLoadingData={isLoadingUoms}
        RenderCustomRow={UomRow}
        disableSelection={allDeletedUoms}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        dataQa="uoms-table"
        showEnhancedTablePaginationActions={!Boolean(activeUomId)}
      />
    </>
  );
};

export default UomSearchResults;
