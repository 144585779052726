import {
  ItemInventoryEvents,
  ItemTrackingType,
  TrackingGroup,
} from 'services/inventory';
import { Errors } from 'services/forms/validation';
import { InventoryEventFormValues } from 'ui/modules/materials/pages/InventoryPage/components/InventoryEventModal';
import { Uom } from 'services/uoms';
import { RenderCustomRowProps } from '../ItemsTable';

export interface TrackingTableProps {
  tableType: TrackingTableTypes;
  itemTrackingTypes: ItemTrackingType[];
  trackingGroups: TrackingGroup[];
  disableAutoAssign?: boolean;
  onAddNewTracking?: () => void;
  onSetTrackingGroups: (data: TrackingGroup[]) => void;
  firstColumnTitle?: string;
  withoutAmountToAssign?: boolean;
  withoutTitleBar?: boolean;
  emptyTableText?: string;
  errors?: Errors[];
  formValues?: InventoryEventFormValues;
  itemUom?: Uom | null;
  inventoryUom?: Uom | null;
  isDecimal?: boolean;
  allowSerialNumberImport?: boolean;
  onEmptyTableTextRowClick?: () => void;
  customRow?: React.FC<RenderCustomRowProps<TrackingGroup>>;
  readOnlyTrackingTable?: boolean;
  closeParentModal?: () => void;
  removeTrackingGroup?: (id: string) => void;
  locationId?: number | null;
  uomDefaultAbbreviation?: string | null;
  itemCost?: number;
  duplicateArray?: string[];
  shouldSort?: boolean;
  headerMargin?: string;
  sx?: React.CSSProperties;
  bulkMove?: boolean;
  eventType?: ItemInventoryEvents;
}

export enum TrackingTableTypes {
  Add,
  Remove,
  Edit,
  View,
  ReceivingView,
  Cycle,
  CycleNew,
}
