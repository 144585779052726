import React from 'react';
import { Box, PopoverOrigin, Typography } from '@mui/material';
import Add from '@mui/icons-material/Add';

import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import { colorPalette } from 'ui/theme';

export const availableItemsColumns = (itemAmount: number): Column[] => [
  {
    title: `${itemAmount} Items`,
    type: ColumnTypes.custom,
    render: (row) => {
      return (
        <Box
          display="flex"
          flexDirection="column"
          maxWidth={220}
          overflow="hidden"
          textOverflow="ellipsis"
          height={37}
          justifyContent="center"
        >
          <Typography variant="body1" color="textPrimary" noWrap>
            {row.name}
          </Typography>
          {row.description && (
            <Typography variant="body2" color="textSecondary" noWrap>
              {row.description}
            </Typography>
          )}
        </Box>
      );
    },
  },
  {
    title: '',
    type: ColumnTypes.custom,
    width: 24,
    render: () => {
      return (
        <Box
          display="flex"
          alignItems="center"
          width={24}
          height="100%"
          color={colorPalette.fishbowlCorporateGray3}
        >
          <Add color="inherit" />
        </Box>
      );
    },
  },
];

export const selectedItemsColumns: Column[] = [
  {
    title: 'Item',
    width: 240,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Available Qty',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Qty to move',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'From Location',
    width: 180,
    type: ColumnTypes.renderByCustomRow,
  },

  {
    title: 'To Location',
    width: 180,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    type: ColumnTypes.renderByCustomRow,
    width: 60,
  },
];

export const totalAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

export const totalTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
};
