import { createApiCall } from 'services/api';
import { SalesOrder, transformSalesOrder } from 'services/salesOrders';

import { TaxJarReportingOptions } from './consts';
import { TaxJarChannelData } from './types';

const expands = [
  'salesOrderItems.saleItem.images',
  'customer.customerAddresses',
  'salesTax',
  'salesOrderItems.saleItem.item.itemTrackingTypes',
  'salesOrderItems.saleItem.item.tags',
  'salesOrderBundleItems.item',
  'salesOrderBundleItems.salesOrderItems.saleItem.defaultUom',
];

export const connectTaxJar = async (
  data: TaxJarChannelData
): Promise<{ success: boolean }> => {
  const includeTaxReporting =
    data.includeTaxReporting ===
    TaxJarReportingOptions.CalculationsAndReporting;

  const res = await createApiCall(
    {
      path: `/integrations/taxjar/connect?token=${data.token}&taxId=${data.taxId}&includeTaxReporting=${includeTaxReporting}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return res.data;
};

export const disconnectTaxJar = async (): Promise<void> => {
  await createApiCall(
    {
      path: `/integrations/taxjar/disconnect`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};

export const updateTaxesFromTaxJar = async (
  SOid: number
): Promise<SalesOrder> => {
  const res = await createApiCall(
    {
      path: `/v1/sales_orders/${SOid}/update_taxes?expand=${expands.join(',')}`,
      method: 'PUT',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformSalesOrder(res.data);
};
