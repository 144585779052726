import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

const BASE_ROW_HEIGHT = 56;

export const useCardRowStyle = makeStyles<Theme>((theme: Theme) => {
  return {
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: BASE_ROW_HEIGHT,
      height: BASE_ROW_HEIGHT,
      padding: theme.spacing(2),
      cursor: 'pointer',
    },
    label: {
      overflow: 'hidden',
      '& .MuiFormControlLabel-label': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    totalPrice: {
      marginRight: '50px',
    },
    subHeading: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: 1.21,
      marginTop: 2,
      fontSize: '12px',
    },
    heading: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontStyle: 'normal',
      paddingRight: 0,
      lineHeight: 1.2,
    },
    content: {
      width: 'auto',
      flex: 1,
    },
    sidebar: {
      width: 300,
      height: '100%',
      overflow: 'auto',
      borderLeft: `1px solid ${colorPalette.applicationDarkGray10}`,
    },
  };
});
