import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  InputAdornment,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Location, LocationType } from 'services/locations';
import { getUomById } from 'services/uoms';
import { useHandleTextFieldChange } from 'services/forms';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';

import { EventFormProps } from '../../types';
import UomSelect from '../UomDropdown';
import { themeRestyle } from 'ui/theme';
import { CustomersAutocomplete } from 'ui/components/Autocomplete/CustomerAutocomplete';
import { ClassAutocomplete } from 'ui/components/Autocomplete/ClassesAutocomplete';
import { Customer } from 'services/customers';
import { Class } from 'services/classes';
import { useFlags } from 'helpers/useFlags';

const FBOEventRemoveForm: React.FC<EventFormProps> = (props) => {
  const {
    formValues,
    noTracking,
    validationErrors,
    averageCost,
    totalQty,
    locationQuantity,
    setFormValues,
    uomIds,
    defaultUomAbbreviation,
    hasSerialTracking,
    onlySerial,
    committedQty,
  } = props;

  const selectedUom = useSelector(getUomById(formValues.uomId)) || null;
  const flags = useFlags();
  const handleLocationChange = useCallback(
    (value: Location | null) => {
      setFormValues((f) => ({ ...f, locationFromId: value ? value.id : null }));
    },
    [setFormValues]
  );

  const handleAmountChange = useHandleTextFieldChange(
    setFormValues,
    formValues,
    true
  );

  const handleTextFieldChange = useHandleTextFieldChange(
    setFormValues,
    formValues,
    true
  );
  const availableQty = useMemo(() => {
    return totalQty - committedQty;
  }, [totalQty, committedQty]);

  const handleUomSelectChange = (e: SelectChangeEvent) => {
    const uomId = e.target.value;
    setFormValues((form) => ({ ...form, uomId: Number(uomId) }));
  };

  const handleCustomerChange = useCallback(
    (customer: Customer | null) => {
      setFormValues((form) => ({
        ...form,
        materialExpensing: _.merge({}, form.materialExpensing, {
          customerId: customer?.id ?? null,
        }),
      }));
    },
    [setFormValues]
  );

  const handleClassChange = useCallback(
    (accountingClass: Class | null) => {
      setFormValues((form) => ({
        ...form,
        materialExpensing: _.merge({}, form.materialExpensing, {
          accountingClassId: accountingClass?.id ?? null,
        }),
      }));
    },
    [setFormValues]
  );

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      columns={16}
      columnSpacing={themeRestyle.spacing(2)}
      padding={`0px ${themeRestyle.spacing(4)} 0px ${themeRestyle.spacing(4)}`}
      disableEqualOverflow
    >
      <Grid xs={4}>
        <LocationsAsyncAutocomplete
          value={formValues.locationFromId}
          getLocationLabel={(l) => l.path || ''}
          label="Location"
          disableAdd
          required
          placeholder="Select Location"
          renderOption={(props, option: Location) => {
            return (
              <li {...props}>
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Typography variant="body1">{option.path}</Typography>
                  <Typography variant="body1" color="textSecondary">
                    {`${locationQuantity[option.id!] || 0} ${_.get(
                      selectedUom,
                      'abbreviation',
                      ''
                    )}`}
                  </Typography>
                </Box>
              </li>
            );
          }}
          onChange={handleLocationChange}
          companyWide={false}
          error={!!validationErrors.locationFromId}
          locationTypes={[
            LocationType.Stock,
            LocationType.Receiving,
            LocationType.Shipping,
          ]}
        />
      </Grid>
      {noTracking ? (
        <Grid xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            type="number"
            label="Amount to Remove"
            onChange={handleAmountChange}
            name="quantity"
            value={formValues.quantity}
            error={!!validationErrors.quantity}
            dataQa="amt-to-remove"
            disableDebounce={true}
          />
        </Grid>
      ) : (
        <Grid xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            type="number"
            label="Amount to Remove"
            onChange={handleAmountChange}
            name="amountToRemove"
            value={onlySerial ? formValues.amountToRemove : formValues.quantity}
            error={
              onlySerial
                ? !!validationErrors.quantity
                : !!validationErrors.amountToRemove
            }
            dataQa="amount-to-remove-tracking"
            disableDebounce={true}
            disabled={!onlySerial}
          />
        </Grid>
      )}
      <Grid xs={2}>
        <UomSelect
          selectedUomId={selectedUom?.id ?? null}
          uomsWithConversionIds={uomIds}
          onChange={handleUomSelectChange}
          disabled={hasSerialTracking}
        />
      </Grid>
      <Grid xs={3}>
        <TextField
          className="redesign"
          variant="standard"
          readOnly
          label="Avail. to Remove"
          value={availableQty}
          additionalInputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {defaultUomAbbreviation}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid xs={3}>
        <CurrencyField
          readOnly
          label="Avg. Unit Cost"
          value={parseFloat(averageCost)}
          allowNegative
        />
      </Grid>
      {flags.showCustomerAndClassFieldsInEventModals && (
        <Grid container xs={12}>
          <Grid xs={6}>
            <CustomersAutocomplete
              label="Customer/Job"
              onChange={handleCustomerChange}
              value={formValues.materialExpensing?.customerId ?? null}
              placeholder="Select Customer/Job"
              dataQa="remove-event-customer"
            />
          </Grid>
          <Grid xs={6}>
            <ClassAutocomplete
              value={formValues.materialExpensing?.accountingClassId ?? null}
              onChange={handleClassChange}
              label="Class/Category"
              placeholder="Select Class/Category"
              dataQa="remove-event-accounting-class"
            />
          </Grid>
        </Grid>
      )}
      <Grid xs={16}>
        <TextField
          className="redesign"
          variant="standard"
          label="Notes"
          type="text"
          placeholder="Notes"
          name="notes"
          value={formValues.notes}
          onChange={handleTextFieldChange}
        />
      </Grid>
    </Grid>
  );
};

export default FBOEventRemoveForm;
