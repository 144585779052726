import { FailureAction, StartAction, SuccessAction } from 'services/api';
import { integrationsApi } from 'services/api/config';
import { createEcommerceApiAction } from 'services/integrations/redux';

export enum EcommerceChannelsActionTypes {
  FETCH_ECOMMERCE_CHANNELS_START = 'ecommerce/fetch_ecommerce_channels_start',
  FETCH_ECOMMERCE_CHANNELS_SUCCESS = 'ecommerce/fetch_ecommerce_channels_success',
  FETCH_ECOMMERCE_CHANNELS_FAILURE = 'ecommerce/fetch_ecommerce_channels_failure',
  ECOMMERCE_DISCONNECT_START = 'ecommerce/ecommerce_disconnect_start',
  ECOMMERCE_DISCONNECT_SUCCESS = 'ecommerce/ecommerce_disconnect_success',
  ECOMMERCE_DISCONNECT_FAILURE = 'ecommerce/ecommerce_disconnect_failure',
}

const fetchEcommerceChannelsActionStart = (): StartAction => ({
  type: EcommerceChannelsActionTypes.FETCH_ECOMMERCE_CHANNELS_START,
  payload: null,
});

const fetchEcommerceChannelsActionSuccess = (payload: any): SuccessAction => ({
  type: EcommerceChannelsActionTypes.FETCH_ECOMMERCE_CHANNELS_SUCCESS,
  payload,
});

const fetchEcommerceChannelsActionFailure = (error: string): FailureAction => ({
  type: EcommerceChannelsActionTypes.FETCH_ECOMMERCE_CHANNELS_FAILURE,
  payload: { error },
});

export const fetchEcommerceChannels = () =>
  createEcommerceApiAction(
    () => integrationsApi.get('/configuration/channels'),
    fetchEcommerceChannelsActionStart,
    fetchEcommerceChannelsActionSuccess,
    fetchEcommerceChannelsActionFailure
  );
