import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';

import { DateRange } from 'materialui-daterange-picker';
import _ from 'lodash';

import { transformDateToMomentDate } from 'helpers';
import { Customer } from 'services/customers';
import { getUsers, User } from 'services/user';
import { getVendors, Vendor } from 'services/vendors';
import { PurchaseOrderStatus } from 'services/purchaseOrders';
import { Carrier, getCarriers } from 'services/carriers';
import { getPaymentTerms, PaymentTerm } from 'services/paymentTerms';
import { ShippingTerm, SHIPPING_TERMS } from 'services/items';
import { Location } from 'services/locations';
import {
  COUNTRIES,
  CountryAutocomplete,
  CountryType,
} from 'ui/components/Autocomplete/CountryAutocomplete';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { AdvancedSearchFieldsCmpProps } from 'ui/components/Page/PageWithAdvancedSearch';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { getRangeForRequest } from 'ui/components/TextField/DateRangePicker/helpers';
import { LabeledDivider } from 'ui/components/Divider/LabeledDivider';

import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';

const PurchaseOrderAdvancedSearch: React.FC<AdvancedSearchFieldsCmpProps> = (
  props
) => {
  const { setFormValues, formValues } = props;

  const users = useSelector(getUsers);
  const { items: vendors } = useSelector(getVendors);
  const { items: carriers } = useSelector(getCarriers);
  const { items: paymentTerms } = useSelector(getPaymentTerms);

  const dateScheduledRange: DateRange | null = useMemo(() => {
    if (!formValues.dateScheduledFrom || !formValues.dateScheduledTo) {
      return null;
    }

    return {
      startDate: transformDateToMomentDate(
        formValues.dateScheduledFrom as string
      )!,
      endDate: transformDateToMomentDate(formValues.dateScheduledTo as string)!,
    };
  }, [formValues.dateScheduledFrom, formValues.dateScheduledTo]);

  const dateCreatedRange: DateRange | null = useMemo(() => {
    if (!formValues.dateCreatedFrom || !formValues.dateCreatedTo) {
      return null;
    }

    return {
      startDate: transformDateToMomentDate(
        formValues.dateCreatedFrom as string
      )!,
      endDate: transformDateToMomentDate(formValues.dateCreatedTo as string)!,
    };
  }, [formValues.dateCreatedFrom, formValues.dateCreatedTo]);

  const selectedVendor = useMemo(
    () => vendors.find((v) => v.id === formValues.vendorId) || null,
    [formValues.vendorId, vendors]
  );

  const dateLastModifiedRange: DateRange | null = useMemo(() => {
    if (!formValues.dateLastModifiedFrom || !formValues.dateLastModifiedTo) {
      return null;
    }

    return {
      startDate: transformDateToMomentDate(
        formValues.dateLastModifiedFrom as string
      )!,
      endDate: transformDateToMomentDate(
        formValues.dateLastModifiedTo as string
      )!,
    };
  }, [formValues.dateLastModifiedFrom, formValues.dateLastModifiedTo]);

  const dateIssuedRange: DateRange | null = useMemo(() => {
    if (!formValues.dateIssuedFrom || !formValues.dateIssuedTo) {
      return null;
    }

    return {
      startDate: transformDateToMomentDate(
        formValues.dateIssuedFrom as string
      )!,
      endDate: transformDateToMomentDate(formValues.dateIssuedTo as string)!,
    };
  }, [formValues.dateIssuedFrom, formValues.dateIssuedTo]);

  const dateFulfilledRange: DateRange | null = useMemo(() => {
    if (!formValues.dateFulfilledFrom || !formValues.dateFulfilledTo) {
      return null;
    }

    return {
      startDate: transformDateToMomentDate(
        formValues.dateFulfilledFrom as string
      )!,
      endDate: transformDateToMomentDate(formValues.dateFulfilledTo as string)!,
    };
  }, [formValues.dateFulfilledFrom, formValues.dateFulfilledTo]);

  const selectedPaymentTerm = useMemo(() => {
    return paymentTerms.find((p) => p.id === formValues.paymentTermId) || null;
  }, [formValues.paymentTermId, paymentTerms]);

  const selectedShippingTerm = useMemo(() => {
    return (
      SHIPPING_TERMS.find((p) => p.id === formValues.shippingTermId) || null
    );
  }, [formValues.shippingTermId]);

  const selectedBillToCountry = useMemo(() => {
    return (
      COUNTRIES.find((c) => c.code === formValues.vendorAddressCountry) || null
    );
  }, [formValues]);

  const selectedShipToCountry = useMemo(() => {
    return COUNTRIES.find((c) => c.code === formValues.shipToCountry) || null;
  }, [formValues]);

  const selectedCarrier = useMemo(
    () => carriers.find((c) => c.id === formValues.carrierId) || null,
    [formValues.carrierId, carriers]
  );

  const selectedBuyer = useMemo(
    () => users.find((u) => u.id === formValues.buyerId) || null,
    [formValues.buyerId, users]
  );

  const selectedPoStatuses = useMemo(() => {
    if (!formValues.purchaseOrderStatus) {
      return [];
    }

    return formValues.purchaseOrderStatus.toString().split(',');
  }, [formValues.purchaseOrderStatus]);

  const handleVendorChange = useCallback(
    (e: any, vendor: Vendor | null) => {
      setFormValues({ ...formValues, vendorId: vendor ? vendor.id : null });
    },
    [setFormValues, formValues]
  );

  const handlePaymentTermChange = useCallback(
    (e: React.ChangeEvent<{}>, value: PaymentTerm | null) => {
      setFormValues({ ...formValues, paymentTermId: value ? value.id : null });
    },
    [formValues, setFormValues]
  );

  const handleShippingTermChange = useCallback(
    (e: React.ChangeEvent<{}>, value: ShippingTerm | null) => {
      setFormValues({ ...formValues, shippingTermId: value ? value.id : null });
    },
    [formValues, setFormValues]
  );

  const handleCarrierChange = useCallback(
    (e: any, carrier: Carrier | null) => {
      setFormValues({ ...formValues, carrierId: carrier ? carrier.id : null });
    },
    [setFormValues, formValues]
  );

  const handleBuyerChange = useCallback(
    (e: any, buyer: Customer | null) => {
      setFormValues({ ...formValues, buyerId: buyer ? buyer.id : null });
    },
    [setFormValues, formValues]
  );

  const handleStatusChange = useCallback(
    (e: React.ChangeEvent<{}>, statuses: PurchaseOrderStatus[]) => {
      setFormValues({
        ...formValues,
        purchaseOrderStatus: statuses.length ? statuses.join(',') : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleBillToCountryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: CountryType | null) => {
      setFormValues({
        ...formValues,
        vendorAddressCountry: _.get(value, 'code', ''),
      });
    },
    [formValues, setFormValues]
  );

  const handleShipToCountryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: CountryType | null) => {
      setFormValues({
        ...formValues,
        shipToCountry: _.get(value, 'code', ''),
      });
    },
    [formValues, setFormValues]
  );

  const handleRootLocationChange = useCallback(
    (v: Location | null) => {
      const id = _.get(v, 'id', null);
      setFormValues({
        ...formValues,
        locationId: id,
      });
    },
    [formValues, setFormValues]
  );

  const handleDateRangeChange = useCallback(
    (field: string) => (value: DateRange) => {
      const requestRange = getRangeForRequest(field, value);

      if (!requestRange) {
        return;
      }

      setFormValues({
        ...formValues,
        ...requestRange,
      });
    },
    [formValues, setFormValues]
  );

  const handleShowDeletedChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFormValues({ ...formValues, deleted: checked ? 'true' : null });
    },
    [formValues, setFormValues]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LabeledDivider label="Purchase Order Info" />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Vendor"
          options={vendors}
          value={selectedVendor}
          placeholder="Select vendor"
          getOptionLabel={(v: Vendor) => v.name || ''}
          onChange={handleVendorChange}
          dataQa="purchase-order-vendor"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Buyer"
          options={users}
          value={selectedBuyer}
          placeholder="Select buyer"
          getOptionLabel={(u: User) => u.firstName + ' ' + u.lastName}
          onChange={handleBuyerChange}
          dataQa="purchase-order-buyer"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Carrier"
          options={carriers}
          value={selectedCarrier}
          placeholder="Select carrier"
          getOptionLabel={(v: Carrier) => v.name || ''}
          onChange={handleCarrierChange}
          dataQa="purchase-order-carrier"
        />
      </Grid>
      <Grid item xs={6}>
        <LocationsAsyncAutocomplete
          onChange={handleRootLocationChange}
          label="Root Location"
          placeholder="Select root location"
          value={formValues.locationId as number}
          companyWide={false}
          parentId={null}
          dataQa="purchase-order-root-location"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          className="multi-line"
          label="Purchase Order Status"
          options={Object.values(PurchaseOrderStatus)}
          value={selectedPoStatuses}
          placeholder="Select purchase order status"
          onChange={handleStatusChange}
          multiple
          dataQa="purchase-order-status"
        />
      </Grid>
      <Grid item xs={12}>
        <LabeledDivider label="Addresses" />
      </Grid>
      <Grid item xs={6}>
        <CountryAutocomplete
          value={selectedBillToCountry}
          onChange={handleBillToCountryChange}
          label="Bill to country"
          placeholder="Select bill to country"
          data-qa="purchase-order-bill-to-country"
        />
      </Grid>
      <Grid item xs={6}>
        <CountryAutocomplete
          value={selectedShipToCountry}
          onChange={handleShipToCountryChange}
          label="Ship to country"
          placeholder="Select ship to country"
          data-qa="purchase-order-ship-to-country"
        />
      </Grid>
      <Grid item xs={12}>
        <LabeledDivider label="Details" />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Payment Term"
          placeholder="Select payment term"
          options={paymentTerms}
          getOptionLabel={(p) => p.name}
          value={selectedPaymentTerm}
          onChange={handlePaymentTermChange}
          dataQa="purchase-order-payment-terms"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Shipping Term"
          placeholder="Select shipping term"
          options={SHIPPING_TERMS}
          getOptionLabel={(p) => p.name}
          value={selectedShippingTerm}
          onChange={handleShippingTermChange}
          dataQa="purchase-order-shipping-term"
        />
      </Grid>
      <Grid item xs={12}>
        <LabeledDivider label="Dates" />
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            label="Date Created"
            placeholder="Select date created range"
            value={dateCreatedRange}
            onChange={handleDateRangeChange('dateCreated')}
            fullWidth
            dataQa="purchase-order-date-created"
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            value={dateLastModifiedRange}
            label="Date Modified"
            placeholder="Select date modified range"
            onChange={handleDateRangeChange('dateLastModified')}
            fullWidth
            dataQa="purchase-order-date-modified"
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            value={dateIssuedRange}
            label="Date Issued"
            name="dateIssued"
            placeholder="Select date issued range"
            onChange={handleDateRangeChange('dateIssued')}
            fullWidth
            dataQa="purchase-order-date-issued"
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            label="Date Scheduled"
            placeholder="Select date scheduled range"
            value={dateScheduledRange}
            onChange={handleDateRangeChange('dateScheduled')}
            fullWidth
            dataQa="purchase-order-date-scheduled"
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            value={dateFulfilledRange}
            label="Date Fulfilled"
            name="dateFulfilled"
            placeholder="Select date fulfilled range"
            onChange={handleDateRangeChange('dateFulfilled')}
            fullWidth
            dataQa="purchase-order-date-fulfilled"
          />
        </Box>
      </Grid>
      <Grid xs={6} />
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!formValues.deleted}
              onChange={handleShowDeletedChange}
              color="primary"
            />
          }
          label="Show Deleted"
          data-qa="purchase-order-show-deleted"
        />
      </Grid>
    </Grid>
  );
};

export default memo(PurchaseOrderAdvancedSearch);
