import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { TableCell, Typography } from '@mui/material';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';

import { QuickTransferFormValues, quickTransferSubRowProps } from './types';
import { TextField } from 'ui/components/TextField/TextField';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Location } from 'services/locations';
import { useQuickTransferTableCellStyle } from './styled';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme/icons';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  ItemInventory,
  TrackingGroup,
  getItemInventory,
  initialItemInventory,
  itemHasOnlySerialTracking,
  itemHasTracking,
} from 'services/inventory';

import { Box } from '@mui/system';
import CustomTooltipTextField from './CustomTooltipTextField';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { ExportStatus } from 'ui/modules/accounting/pages/AccountingSettingsPage/components/CardHistory/types';
import BulkMoveDetailsModal from './BulkMoveDetailsModal';
import { initialItem } from 'services/items';
import { InventoryEventFormValues } from '../InventoryEventModal/types';

const QuckTransferSubRow: React.FC<
  RenderCustomRowProps<quickTransferSubRowProps>
> = (props) => {
  const {
    row,
    meta: {
      tableData,
      setTableData,
      handleRemoveRow,
      setIsQuantityShort,
      setIsTrackingFilled,
      formValues,
    },
  } = props;
  const classes = useQuickTransferTableCellStyle();
  const [showTrackedBtn, setTrackedBtn] = useState(false);
  const [showBulkModal, setShowBulkModal] = useState(false);
  const [labelStatus, setLabelStatus] = useState(false);
  const [itemInventory, setItemInventory] =
    useState<ItemInventory>(initialItemInventory);
  useEffect(() => {
    const fetchData = async () => {
      if (row.Item?.id) {
        const inventory = await getItemInventory(row.Item.id!);
        setItemInventory(inventory);
      }
    };
    if (row.Item?.itemTrackingTypeList?.length) setTrackedBtn(true);
    fetchData();
  }, [row.Item?.id]);
  const availableQty = useMemo(() => {
    if (row.locationFromId && itemInventory) {
      const locationSummary = itemInventory.inventoryRowList.find(
        (summary) => summary.id === row.locationFromId
      );
      return (
        (locationSummary?.totalQty || 0) - (locationSummary?.committedQty || 0)
      );
    }
    return 0;
  }, [itemInventory, row.locationFromId]);
  const handleLocationFromChange = useCallback(
    (value: Location | null) => {
      setTableData((prevTableData: any[]) => {
        const updatedTableData = [...prevTableData];

        const indexToUpdate = updatedTableData.findIndex(
          (obj) => obj.id === row.id
        );

        if (indexToUpdate !== -1) {
          updatedTableData[indexToUpdate] = {
            ...updatedTableData[indexToUpdate],
            locationFromId: value ? value.id : null,
          };
        }

        return updatedTableData;
      });
    },
    [row.id, setTableData]
  );
  const handleLocationToChange = useCallback(
    (value: Location | null) => {
      setTableData((prevTableData: any[]) => {
        const updatedTableData = [...prevTableData];
        const indexToUpdate = updatedTableData.findIndex(
          (obj) => obj.id === row.id
        );

        if (indexToUpdate !== -1) {
          updatedTableData[indexToUpdate] = {
            ...updatedTableData[indexToUpdate],
            locationToId: value ? value.id : null,
          };
        }

        return updatedTableData;
      });
    },
    [row.id, setTableData]
  );
  useEffect(() => {
    setIsQuantityShort((prevState: any) => ({
      ...prevState,
      [row.id || 0]:
        availableQty < (row.quantity || 0) ||
        (availableQty === 0 && (row.quantity || 0) === 0),
    }));

    const hasTracking = itemHasTracking(row.Item!);
    if (hasTracking)
      setIsTrackingFilled((prevState: any) => ({
        ...prevState,
        [row.id || 0]: labelStatus,
      }));
  }, [tableData, availableQty, labelStatus, setIsQuantityShort]);
  const handleTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    rowIndex: number | null
  ) => {
    const { name, value } = e.target;
    let sum = 0;
    const updatedRows = tableData.map((data: QuickTransferFormValues) => {
      if (data.id === rowIndex) {
        return {
          ...data,
          [name]: value,
        };
      }
      if (data.Item?.id === row.Item?.id) {
        sum = sum + Number(data.quantity);
      }
      return data;
    });
    const updateStatus = updatedRows.map((data: QuickTransferFormValues) => {
      const errorStatus = sum + parseFloat(value) > availableQty ? true : false;
      if (data.Item?.id === row.Item?.id) {
        return {
          ...data,
          errorStatus: errorStatus,
        };
      }
      return data;
    });
    setTableData(updateStatus);
  };
  const onlySerial = useMemo(
    () => itemHasOnlySerialTracking(row.Item || initialItem),
    [row.Item]
  );

  const handleEventModalSave = async (
    formValues: InventoryEventFormValues,
    trackingGroups: TrackingGroup[]
  ) => {
    setTableData((prevTableData: any[]) => {
      const updatedTableData = [...prevTableData];
      const indexToUpdate = updatedTableData.findIndex(
        (obj) => obj.id === row.id
      );

      if (indexToUpdate !== -1) {
        updatedTableData[indexToUpdate] = {
          ...updatedTableData[indexToUpdate],
          trackingGroupObjects: onlySerial
            ? trackingGroups
            : trackingGroups.map((tg) => ({
                quantity: tg.quantity,
                serialIds: tg.serialIds,
                trackingGroupInfos: tg.trackingInfoList,
              })),
        };
      }

      return updatedTableData;
    });

    setShowBulkModal(false);
  };
  useEffect(() => {}, [tableData, setTableData]);
  return (
    <>
      <TableCell>
        <Box
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <FBOButton
            variant="tertiary"
            color="negative"
            size="small"
            icon={IconNames.TrashCan}
            data-qa="inventory-row-remove"
            onClick={() => handleRemoveRow(row.id)}
          />
        </Box>
      </TableCell>
      <TableCell className={classes.cell}>
        <LocationsAsyncAutocomplete
          placeholder="Select location"
          value={row.locationFromId}
          onChange={handleLocationFromChange}
          companyWide={false}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        <LocationsAsyncAutocomplete
          placeholder="Select To Location"
          value={row.locationToId}
          onChange={handleLocationToChange}
          companyWide={false}
          dataQa="to-location"
          getLocationLabel={(l) => l.path || ''}
          filterOutLocations={[row.locationFromId]}
        />
      </TableCell>
      <TableCell>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Typography style={{ marginRight: '8px' }}>
            {_.get(row, 'Item.name', null)}
          </Typography>
          {tableData.find((obj: any) => obj.id === row.id).errorStatus && (
            <ErrorOutlineIcon className={classes.errorIcon} />
          )}
        </span>
      </TableCell>

      <TableCell className={classes.cell}>
        <Typography>{availableQty}</Typography>
      </TableCell>
      <TableCell className={classes.cell}>
        <TextField
          className="redesign"
          variant="standard"
          type="text"
          placeholder="Qty To Move"
          name="quantity"
          value={row.quantity}
          onChange={(e) => handleTextFieldChange(e, row.id)}
        />
      </TableCell>

      <TableCell className={classes.cell}>
        <CustomTooltipTextField
          tooltipText={row.notes || ''}
          value={row.notes || ''}
          onChange={(e) => handleTextFieldChange(e, row.id)}
        />
      </TableCell>
      <TableCell>
        {showTrackedBtn && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginRight: '10px',
            }}
          >
            <StatusLabel
              status={labelStatus ? ExportStatus.Success : ExportStatus.Failure}
              label={labelStatus ? 'Done' : 'Not Added'}
              combineStatusLabel={true}
            />
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="medium"
              icon={IconNames.FBOCaratRight}
              disabled={
                tableData.find((obj: any) => obj.id === row.id).errorStatus
              }
              onClick={() => setShowBulkModal(true)}
              data-qa={`details-link-${row.id}`}
              component={'a'}
            />
          </Box>
        )}
      </TableCell>
      <BulkMoveDetailsModal
        show={showBulkModal}
        onConfirmClicked={handleEventModalSave}
        onCancelClicked={() => setShowBulkModal(false)}
        Item={row.Item || initialItem}
        locationFromId={row.locationFromId}
        locationToId={row.locationToId}
        quantity={row.quantity}
        notes={row.notes}
        id={row.id}
        availableQty={availableQty}
        setLabelStatus={setLabelStatus}
        meta={{ data: formValues, row: row, labelStatus: labelStatus }}
      />
    </>
  );
};

export default memo(QuckTransferSubRow);
