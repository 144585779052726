import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { colorPalette } from 'ui/theme';

export const useAddRowAlertModal = makeStyles(() => ({
  buttonLink: {
    'text-transform': 'none',
    'white-space': 'pre',
    cursor: 'pointer',
  },
}));

// Lint skip to be removed
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useReorderTabManageAlertsStyle = makeStyles((theme: Theme) => ({
  chipSuccess: {
    backgroundColor: colorPalette.statusGreen,
  },
  chipWarning: {
    backgroundColor: colorPalette.statusOrange,
  },
  buttonStyle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  searchInputInner: {
    height: 32,
    background: 'white',
    paddingTop: `0!important`,
    paddingBottom: `0!important`,
  },
  searchInputOuter: {
    flex: 1,
    paddingRight: 5,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  noBorder: {
    border: 0,
  },
}));
