/* eslint-disable */
import React, { useCallback, useMemo, useEffect } from 'react';
import { Box, Chip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { useFlags } from 'helpers/useFlags';

import { Routes } from 'ui/modules/remotesupport/navigation/routes';
import {
  clearAdminAccessToken,
  getAdminAccessToken,
} from 'services/auth/services';

import { getOutageBannerCopy } from 'services/outageBanner';
import { logErrorCtx } from 'app/logging';
import FBOButton from '../FBOButton/FBOButton';

export type FBOAppBarCmp = React.FunctionComponent<FBOAppBarComponentProps>;

export interface FBOAppBarComponentProps {}

const FBOAppBar: FBOAppBarCmp = () => {
  const [outageBannerCopy, setOutageBannerCopy] = React.useState('');

  const history = useHistory();

  const hasAdminAccessToken = useMemo(() => !!getAdminAccessToken(), []);

  const logOutRemoteAccess = useCallback(() => {
    clearAdminAccessToken();
    history.push(Routes.RemoteSupportPage);
    history.go(0);
  }, [history]);

  const flags = useFlags();

  useEffect(() => {
    if (flags.outageBanner) {
      (async () => {
        try {
          const newOutageBannerCopy = await getOutageBannerCopy();
          setOutageBannerCopy(newOutageBannerCopy);
        } catch (e) {
          logErrorCtx('Error Retriving Outage Banner Copy', {
            error: e as Error,
            stackTrace: (e as Error).stack,
            component: 'FBOAppBar',
            title: 'Error in fetch outage banner copy',
            description: `Error retriving outage banner copy`,
          });
          return;
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box data-qa="fbo-app-bar">
      {hasAdminAccessToken ? (
        <FBOButton
          variant="primary"
          color="negative"
          size="large"
          onClick={() => logOutRemoteAccess()}
          data-qa="remote-user-log-out"
          fullWidth
          style={{ marginBottom: '16px' }}
        >
          Log Out of Remote User
        </FBOButton>
      ) : null}

      {flags.outageBanner && outageBannerCopy && (
        <Box
          pl={1}
          flexGrow={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '16px',
            padding: 0,
          }}
        >
          <Chip
            label={outageBannerCopy}
            style={{
              backgroundColor: '#DF0C0C',
              fontSize: 13,
              width: '100%',
            }}
            size="small"
            color="secondary"
          />
        </Box>
      )}
    </Box>
  );
};

export default FBOAppBar;
