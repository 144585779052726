import React, { memo } from 'react';
import { InvoiceDetails } from 'services/invoices';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { TextField } from 'ui/components/TextField/TextField';
import moment from 'moment';
import { isProd, useGetIntlDateFormatString } from 'helpers';
import {
  QBO_CREDIT_MEMO_URL,
  QBO_INVOICE_URL,
  QBO_SANDBOX_CREDIT_MEMO_URL,
  QBO_SANDBOX_INVOICE_URL,
} from '../../../../consts';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

interface GeneralTabProps {
  invoiceDetails: InvoiceDetails;
}

const GeneralTab: React.FC<GeneralTabProps> = (props) => {
  const { invoiceDetails } = props;

  const intlDateFormat = useGetIntlDateFormatString();

  const getQBOTransactionLink = () => {
    const { type, transactionId } = invoiceDetails;
    let url = null;

    if (!transactionId) {
      return null;
    }

    const isProdEnv = isProd();

    if (isProdEnv) {
      if (type === 'Invoice') {
        url = QBO_INVOICE_URL;
      } else if (type === 'Credit Memo') {
        url = QBO_CREDIT_MEMO_URL;
      }
    } else {
      if (type === 'Invoice') {
        url = QBO_SANDBOX_INVOICE_URL;
      } else if (type === 'Credit Memo') {
        url = QBO_SANDBOX_CREDIT_MEMO_URL;
      }
    }

    if (url) {
      return url + transactionId;
    } else {
      return null;
    }
  };

  const QBOTransactionLink = getQBOTransactionLink();

  return (
    <Grid
      sx={{
        overflowY: 'scroll',
        maxHeight: '50%',
        padding: '16px',
      }}
      container
      columnSpacing="16px"
      columns={16}
      disableEqualOverflow
    >
      <Grid xs={4}>
        <TextField
          className="redesign"
          variant="standard"
          label="Customer"
          name="customer"
          value={invoiceDetails?.customer?.name || ''}
          fullWidth
          dataQa="customer"
          readOnly
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          className="redesign"
          variant="standard"
          label="Order No."
          name="order-number"
          value={invoiceDetails?.number || ''}
          fullWidth
          dataQa="order-number"
          readOnly
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          className="redesign"
          variant="standard"
          type="text"
          label="Location"
          name="location"
          value={invoiceDetails?.location?.name || ''}
          fullWidth
          dataQa="location"
          readOnly
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          className="redesign"
          variant="standard"
          label="Customer PO"
          name="customer-po"
          value={invoiceDetails?.customerPO || ''}
          fullWidth
          dataQa="customer-po"
          readOnly
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          className="redesign"
          variant="standard"
          label="Date Shipped"
          name="date-shipped"
          value={
            invoiceDetails?.dateShipped
              ? moment(invoiceDetails?.dateShipped).format(intlDateFormat)
              : ''
          }
          fullWidth
          dataQa="date-shipped"
          readOnly
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          className="redesign"
          variant="standard"
          label="Date Due"
          name="date-due"
          value={
            invoiceDetails?.dateDue
              ? moment(invoiceDetails?.dateDue).format(intlDateFormat)
              : ''
          }
          fullWidth
          dataQa="date-due"
          readOnly
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          className="redesign"
          variant="standard"
          label="Carrier"
          name="carrier"
          value={invoiceDetails?.carrier?.name || ''}
          fullWidth
          dataQa="carrier"
          readOnly
        />
      </Grid>
      <Grid xs={4}>
        <TextField
          className="redesign"
          variant="standard"
          label="Service"
          name="carrier-service"
          value={invoiceDetails?.carrierService?.name || ''}
          fullWidth
          dataQa="carrier-service"
          readOnly
        />
      </Grid>
      <Grid xs={8}>
        <TextField
          className="redesign"
          variant="standard"
          label="Payment Terms"
          name="payment-terms"
          value={invoiceDetails?.paymentTerm?.name || ''}
          fullWidth
          dataQa="payment-terms"
          readOnly
        />
      </Grid>
      <Grid xs={8}>
        <TextField
          className="redesign"
          variant="standard"
          label="Bill To"
          name="bill-to"
          value={invoiceDetails?.billToAddress || ''}
          fullWidth
          dataQa="bill-to"
          readOnly
        />
      </Grid>
      <Grid xs={4}>
        {invoiceDetails?.salesOrderId ? (
          <FBOButton
            variant="secondary"
            color="positive"
            size="medium"
            data-qa={'qbo-transaction-link'}
            href={`/sales/sales-order?activeId=${invoiceDetails?.salesOrderId}`}
            icon={IconNames.FBOLeavePage}
            fullWidth
            // @ts-ignore
            target="_blank"
            rel="noopener noreferrer"
          >
            Sales Order Link
          </FBOButton>
        ) : (
          ''
        )}
      </Grid>
      <Grid xs={4}>
        {QBOTransactionLink ? (
          <FBOButton
            variant="secondary"
            color="positive"
            size="medium"
            data-qa={'qbo-transaction-link'}
            href={QBOTransactionLink}
            icon={IconNames.FBOLeavePage}
            fullWidth
            // @ts-ignore
            target="_blank"
            rel="noopener noreferrer"
          >
            QBO Link
          </FBOButton>
        ) : (
          ''
        )}
      </Grid>

      <Grid xs={8}>
        <TextField
          className="redesign"
          variant="standard"
          label="Ship To"
          name="ship-to"
          value={invoiceDetails?.shipToAddress || ''}
          fullWidth
          dataQa="ship-to"
          readOnly
        />
      </Grid>
    </Grid>
  );
};

export default memo(GeneralTab);
