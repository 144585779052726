import React, { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import clsx from 'clsx';
import { matchPath } from 'react-router-dom';
import { Drawer, Collapse, Box, IconButton, List } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useFlags } from 'helpers/useFlags';

import { getEcommerceChannels } from 'services/integrations/ecommerce/redux';
import { getQuickbooksAccountIsConnected } from 'services/integrations/quickbooks';
import { getQuickbooksDesktopAccountIsConnected } from 'services/integrations/qbd/redux';
import { isTestEnv } from 'services/api/testEnv';
import { getActiveUser } from 'services/user/redux';
import { hasPermission } from 'services/permissions';
import { getShippingIntegration } from 'services/integrations/shipping/redux';
import { getXeroAccountIsConnected } from 'services/integrations/xero';
import { getSettingsCompany } from 'services/settings/company/redux';

import DrawerItem from './DrawerItem';
import {
  DRAWER_TOP_ITEMS,
  DRAWER_BOTTOM_ITEMS,
  APPBAR_SPACER_HEIGHT,
} from './consts';
import { useAppDrawerStyle, StyledList, DrawerItemContainer } from './styled';
import {
  AppDrawerProps,
  AppDrawerCmp,
  DrawerItem as DrawerItemType,
} from './types';
export const sideDrawerStatus = React.createContext({ drawerExpanded: false });
import { getCommerceAccountIsOrWasConnected } from 'services/commerce';

const AppDrawer: AppDrawerCmp = (props: AppDrawerProps) => {
  const { drawerExpanded, onDrawerExpand, drawerPersistent, activePathName } =
    props;

  const quickbooksConnected = useSelector(getQuickbooksAccountIsConnected);
  const connectedChannels = useSelector(getEcommerceChannels);
  const xeroConnected = useSelector(getXeroAccountIsConnected);
  const commerceConnected = useSelector(getCommerceAccountIsOrWasConnected);
  const qbdConnected = useSelector(getQuickbooksDesktopAccountIsConnected);

  const flags = useFlags();

  const connectedShippingChannels = useSelector(getShippingIntegration);
  const {
    permissions: userPermissions,
    isAdmin,
    isExecutor,
  } = useSelector(getActiveUser);

  const useMultiCurrency = useSelector(getSettingsCompany).useMultiCurrency;

  const classes = useAppDrawerStyle(props);

  const [expandedItem, setExpandedItem] = React.useState<number | null>(null);
  const [hoverExpanded, setHoverExpanded] = React.useState<boolean>(false);

  const hasConnectedChannels = useMemo(
    () => connectedChannels.items.length > 0,
    [connectedChannels]
  );

  const hasConnectedShippingChannels = useMemo(
    () => connectedShippingChannels.connections.length > 0,
    [connectedShippingChannels]
  );

  const topItems = useMemo(
    () =>
      DRAWER_TOP_ITEMS(
        quickbooksConnected,
        isTestEnv(),
        hasConnectedChannels,
        qbdConnected,
        hasConnectedShippingChannels,
        flags.sellware,
        xeroConnected,
        commerceConnected
      ),
    [
      quickbooksConnected,
      hasConnectedChannels,
      qbdConnected,
      hasConnectedShippingChannels,
      flags.sellware,
      xeroConnected,
      commerceConnected,
    ]
  );

  const bottomItems = useMemo(
    () => DRAWER_BOTTOM_ITEMS(isTestEnv(), useMultiCurrency),
    [useMultiCurrency]
  );

  useEffect(() => {
    const allItems = topItems.concat(bottomItems);
    // find index of active item
    allItems.forEach((value, index) => {
      value.subitems.forEach((subitem) => {
        if (subitem.route === activePathName) {
          setExpandedItem(index);
        }
      });
    });
  }, [activePathName, topItems, bottomItems]);

  const checkIfRouteIsActive = useCallback(
    (path: string | null) => {
      if (!path) {
        return false;
      }
      return matchPath(activePathName, { path });
    },
    [activePathName]
  );

  const handleItemClicked = useCallback(
    (id: number) => () => {
      const newActiveItem: number | null = id === expandedItem ? null : id;
      setExpandedItem(newActiveItem);
    },
    [expandedItem]
  );

  const handleDrawerExpand = useCallback(
    (expanded: boolean, persistent: boolean) => {
      onDrawerExpand(expanded, persistent);
    },
    [onDrawerExpand]
  );

  const handleMouseEnter = useCallback(
    (e: any) => {
      const el = document.getElementById('expand-toggle-button');
      if (el && e.clientY < el.offsetTop) {
        handleDrawerExpand(true, false);
        setHoverExpanded(true);
      }
    },
    [handleDrawerExpand]
  );

  const handleMouseLeave = useCallback(() => {
    handleDrawerExpand(false, false);
    setHoverExpanded(false);
  }, [handleDrawerExpand]);
  const drawerExpandedValue = useMemo(
    () => ({ drawerExpanded: drawerExpanded }),
    [drawerExpanded]
  );

  const renderDrawerItems = useCallback(
    (items: DrawerItemType[], offset?: number) => {
      const renderItems = () =>
        items.map((option, index) => {
          if (
            !hasPermission(option.permissions || [], userPermissions, isAdmin)
          ) {
            return null;
          }

          // only accessable to user with executor property
          if (option.onlyExecutor && !isExecutor) {
            return null;
          }

          const realIndex = _.isNil(offset) ? index : index + offset;
          return (
            <sideDrawerStatus.Provider
              value={drawerExpandedValue}
              key={option.text}
            >
              <DrawerItemContainer
                active={!!checkIfRouteIsActive(option.route)}
                key={option.text}
              >
                <DrawerItem
                  key={option.text}
                  text={option.text}
                  icon={option.icon}
                  to={option.route}
                  visible={option.visible}
                  itemExpanded={expandedItem === realIndex}
                  active={!!checkIfRouteIsActive(option.route)}
                  handleClick={handleItemClicked(realIndex)}
                  dataQa={option.text}
                />
                <List component="div" disablePadding>
                  <Collapse
                    in={
                      (expandedItem === realIndex && drawerExpanded) ||
                      option.text === 'Sandbox'
                    }
                    timeout="auto"
                    key={option.text}
                    className={classes.expander}
                  >
                    {option.subitems.map((subItem, index) => (
                      <DrawerItem
                        key={`${subItem.text}-${index}`}
                        text={subItem.text}
                        to={subItem.route}
                        visible={subItem.visible}
                        active={!!checkIfRouteIsActive(subItem.route)}
                        secondary
                        permissions={subItem.permissions}
                        dataQa={`${option.text}-${subItem.text}`}
                        component={subItem.component}
                      />
                    ))}
                  </Collapse>
                </List>
              </DrawerItemContainer>
            </sideDrawerStatus.Provider>
          );
        });

      return <StyledList disablePadding>{renderItems()}</StyledList>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      userPermissions,
      isAdmin,
      isExecutor,
      checkIfRouteIsActive,
      expandedItem,
      handleItemClicked,
      drawerExpanded,
    ]
  );

  const handleIconButtonClick = useCallback(
    () =>
      hoverExpanded && drawerPersistent
        ? handleDrawerExpand(!drawerExpanded, true)
        : hoverExpanded && !drawerPersistent
        ? handleDrawerExpand(drawerExpanded, true)
        : handleDrawerExpand(!drawerExpanded, true),
    [drawerExpanded, drawerPersistent, handleDrawerExpand, hoverExpanded]
  );

  return (
    <nav className={clsx({ [classes.navigation]: !drawerPersistent })}>
      <Drawer
        variant="permanent"
        className={clsx({
          [classes.drawerOpen]: drawerExpanded,
          [classes.drawerClose]: !drawerExpanded,
        })}
        classes={{
          paper: clsx(classes.drawer, {
            [classes.drawerOpen]: drawerExpanded,
            [classes.drawerClose]: !drawerExpanded,
          }),
        }}
        open={drawerExpanded}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box
          minHeight={APPBAR_SPACER_HEIGHT}
          alignItems="center"
          display="flex"
          justifyContent="center"
          p={1}
        />
        {renderDrawerItems(topItems)}

        <Box flexGrow={1} />

        {renderDrawerItems(bottomItems, topItems.length)}
        <Box
          height={60}
          className={classes.buttonBlock}
          id="expand-toggle-button"
        >
          <IconButton
            aria-label="open drawer"
            onClick={handleIconButtonClick}
            className={classes.expandIcon}
            size="large"
          >
            {drawerPersistent ? (
              <ChevronLeftIcon fontSize="medium" />
            ) : drawerExpanded && !hoverExpanded ? (
              <ChevronLeftIcon fontSize="medium" />
            ) : (
              <ChevronRightIcon fontSize="medium" />
            )}
          </IconButton>
        </Box>
      </Drawer>
    </nav>
  );
};

export default React.memo(AppDrawer);
