import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colorPalette } from 'ui/theme';

export const useItemSelecorStyle = makeStyles((theme: Theme) => ({
  tableColumn: {
    width: '25%',
    overflow: 'auto',
    borderRight: '1px solid #EBEBEB',
    backgroundColor: '#fff',
  },
  tableColumnPicked: {
    width: '74%',
    overflow: 'auto',
    borderLeft: '1px solid #EBEBEB',
    backgroundColor: '#fff',
  },
  defaultValuesContainer: {
    width: '74%',
    borderLeft: '1px solid #EBEBEB',
    backgroundColor: '#fff',
  },
  searchInputInner: {
    height: 32,
    background: 'white',
    paddingTop: `0!important`,
    paddingBottom: `0!important`,
  },
  searchInputOuter: {
    flex: 1,
    paddingRight: 5,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  noBorder: {
    border: 0,
  },
  statusBar: {
    width: '100%',
    height: 32,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    position: 'relative',
  },
  statusSafety: {
    backgroundColor: colorPalette.statusRed,
    display: 'flex',
    justifyContent: 'center',
    '& p': {
      marginTop: 14,
    },
  },
  statusLead: {
    backgroundColor: colorPalette.statusOrange,
    display: 'flex',
    justifyContent: 'center',
    '& p': {
      marginTop: 14,
    },
  },
  statusInterval: {
    backgroundColor: colorPalette.statusGreen,
    display: 'flex',
    justifyContent: 'center',
    '& p': {
      marginTop: 14,
    },
  },
  notifyAt: {
    position: 'absolute',
    top: 0,
    width: 3,
    height: 12,
    backgroundColor: '#fff',
  },
  notifyAtIcon: {
    fontSize: 15,
    marginTop: 15,
    marginLeft: -6,
  },
  defaultValues: {
    padding: '0px 8px 0px 8px',
  },
}));
