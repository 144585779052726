import React, {
  useState,
  memo,
  useMemo,
  useCallback,
  useEffect,
  ChangeEvent,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Collapse,
  Box,
  Link,
  Tooltip,
} from '@mui/material';

import { getUoms, Uom } from 'services/uoms';
import {
  DIMENSIONS_UNITS,
  WEIGHT_UNITS,
  DimensionUnit,
  WeightUnit,
  ItemImage,
  ItemType,
} from 'services/items';
import { ChannelTaxType, TaxRate } from 'services/taxRates';
import { useHandleTextFieldChange } from 'services/forms';
import { Tag } from 'services/tags';
import { Errors, validateYup } from 'services/forms/validation';
import { handleUrlLinkClicked, replaceValueInCollection } from 'helpers';
import { Class } from 'services/classes';
import { ImageDropZone } from 'ui/components/Image/ImageDropZone';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { ClassAutocomplete } from 'ui/components/Autocomplete/ClassesAutocomplete';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { activeUserHasPermission } from 'services/user/redux';
import { Account, fetchAccounts } from 'services/accounting';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { getSettingsCompany } from 'services/settings/company';
import { TaxRatesAutocomplete } from 'ui/components/Autocomplete/TaxRatesAutocomplete';
import { TaxRateVariants } from 'ui/components/Autocomplete/TaxRatesAutocomplete/types';

import {
  filterDefaultSaleItemTypes,
  shouldShowSyncValuesModal,
} from './helpers';
import { SaleItemModalProps } from './types';
import { useSaleItemModalStyle } from './styled';

import { isSkuFieldValid, yupSaleItemSchema } from '../../../../validations';
import { editItemPermissions } from '../../../../helpers';

import { logErrorCtx } from 'app/logging';
import { IncomeAccountField } from './IncomeAccountField';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

const SaleItemModal: React.FC<SaleItemModalProps> = (props) => {
  const {
    show,
    saleItem,
    item,
    setItem,
    setSaleItem,
    onCancelClicked,
    onApplyClicked,
    country,
  } = props;

  const classes = useSaleItemModalStyle(props);

  const editPermissions = editItemPermissions(item);

  const canEdit = useSelector(activeUserHasPermission(editPermissions));

  const { items: uoms } = useSelector(getUoms);
  const { taxJarToken, country: companyCountry } =
    useSelector(getSettingsCompany);

  const [showDimensions, setShowDimensions] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [skuValidationErrors, setSkuValidationErrors] = useState<Errors>({});
  const [images, setImages] = useState<ItemImage[]>([]);
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [showSyncValuesModal, setShowSyncValuesModal] =
    useState<boolean>(false);

  const isShippingServiceLaborOrOverheadType =
    item.itemType === ItemType.Shipping ||
    item.itemType === ItemType.Service ||
    item.itemType === ItemType.Labor ||
    item.itemType === ItemType.Overhead;

  const uomOptions = useMemo(() => {
    const itemUom = uoms.find((i) => i.id === item.defaultUomId) || null;

    if (!itemUom) {
      return uoms;
    }
    const fromUoms = itemUom.fromConversions.map((c) => c.fromUomId!);

    const toUoms = itemUom.toConversions.map((c) => c.toUomId!);

    const usedUomIds = [...fromUoms, ...toUoms, itemUom.id!];

    // filtered to just those that have a conversion from/to the item's default UOM
    return uoms.filter((uom) => usedUomIds.includes(uom.id!));
  }, [uoms, item.defaultUomId]);

  const initialSaleItem = useMemo(
    () => saleItem,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saleItem.id]
  );

  const selectedUom = useMemo(
    () => uoms.find((u) => u.id === saleItem.defaultUomId) || null,
    [saleItem.defaultUomId, uoms]
  );

  const selectedDimensionUnit = useMemo(
    () => DIMENSIONS_UNITS.find((u) => u.name === saleItem.dimensionUnit),
    [saleItem.dimensionUnit]
  );

  const selectedWeightUnit = useMemo(
    () => WEIGHT_UNITS.find((u) => u.name === saleItem.weightUnit),
    [saleItem.weightUnit]
  );

  const isNew = useMemo(
    () => item.saleItemList.find((s) => s.id === saleItem.id),
    [saleItem.id, item.saleItemList]
  );

  useEffect(() => {
    isSkuFieldValid(saleItem, setSkuValidationErrors);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleItem.sku]);

  useEffect(() => {
    (async () => {
      try {
        const accountData = await fetchAccounts();
        setAccounts(accountData);
      } catch (e) {
        logErrorCtx('Error in fetchAccounts', {
          error: e as Error,
          stackTrace: (e as Error).stack,
          component: 'SaleItemModal',
          title: 'Error in fetchAccounts',
          description: 'Error in fetchAccounts',
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (show) {
      setValidationErrors({});
      setSaleItem(saleItem);
      setShowDimensions(
        Boolean(saleItem.width) ||
          Boolean(saleItem.length) ||
          Boolean(saleItem.height) ||
          Boolean(saleItem.weight)
      );

      setImages(saleItem.imageLinkList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (
      !!validationErrors.length ||
      !!validationErrors.weight ||
      !!validationErrors.height ||
      !!validationErrors.width
    ) {
      setShowDimensions(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationErrors]);

  const handleApplyClicked = useCallback(() => {
    const isValid = validateYup(
      saleItem,
      yupSaleItemSchema(country!),
      setValidationErrors
    );

    if (isValid) {
      if (shouldShowSyncValuesModal(item, saleItem)) {
        onApplyClicked();
      } else setShowSyncValuesModal(true);
    }
  }, [saleItem, onApplyClicked, item, country]);

  const handleResetClicked = useCallback(() => {
    setSaleItem(initialSaleItem);
  }, [initialSaleItem, setSaleItem]);

  const handleTextFieldChange = useHandleTextFieldChange(
    setSaleItem,
    saleItem,
    true
  );
  const handleNumberFieldChange = useHandleTextFieldChange(
    setSaleItem,
    saleItem,
    true
  );

  const handleUomChange = useCallback(
    (_event: any, value: Uom | null) => {
      setSaleItem((prevSaleItem) => ({
        ...prevSaleItem,
        defaultUomId: value ? value.id : null,
      }));
    },
    [setSaleItem]
  );

  const handleDefaultSalesOrderItemType = useCallback(
    (_event: any, value: string) => {
      setSaleItem((prevSaleItem) => ({
        ...prevSaleItem,
        defaultSalesOrderItemType: value,
      }));
    },
    [setSaleItem]
  );

  const handleTaxRateChange = useCallback(
    (value: TaxRate | null) => {
      setSaleItem((prevSaleItem) => ({
        ...prevSaleItem,
        salesTaxId: value ? value.id : null,
      }));
    },
    [setSaleItem]
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const name = event.target.name;
      setSaleItem((prevSaleItem) => ({
        ...prevSaleItem,
        [name]: checked,
      }));
    },
    [setSaleItem]
  );

  const handleDimensionUnitChange = useCallback(
    (_event: any, value: DimensionUnit | null) => {
      setSaleItem((prevSaleItem) => ({
        ...prevSaleItem,
        dimensionUnit: value ? value.name : null,
      }));
    },
    [setSaleItem]
  );

  const handleWeightUnitChange = useCallback(
    (_event: any, value: WeightUnit | null) => {
      setSaleItem((prevSaleItem) => ({
        ...prevSaleItem,
        weightUnit: value ? value.name : null,
      }));
    },
    [setSaleItem]
  );

  const handleToggleDimensions = useCallback(() => {
    setShowDimensions(!showDimensions);
  }, [showDimensions]);

  const handleCancelImagesClicked = useCallback(() => {
    setImages(saleItem.imageLinkList);
  }, [saleItem.imageLinkList]);

  const handleSaveImagesClicked = useCallback(() => {
    const defaultImage = images.find((i) => i.defaultFlag);
    setSaleItem((prevSaleItem) => ({
      ...prevSaleItem,
      defaultImage: defaultImage ? defaultImage.url : null,
      imageLinkList: images,
    }));
  }, [images, setSaleItem]);

  const handleTagsChange = useCallback(
    (values: Tag[]) => setSaleItem((old) => ({ ...old, tags: values })),
    [setSaleItem]
  );

  const handleTagNameChange = useCallback(
    (tag: Tag) => {
      const index = item.tags.findIndex((i) => i.id === tag.id);
      setItem((old) => ({
        ...old,
        tags: replaceValueInCollection(old.tags, tag, index) || old.tags,
        saleItemList: old.saleItemList.map((s) => {
          const saleIndex = s.tags.findIndex((i) => i.id === tag.id);
          if (saleIndex > -1) {
            return {
              ...s,
              tags: replaceValueInCollection(s.tags, tag, saleIndex)!,
            };
          }
          return s;
        }),
      }));
    },
    [item.tags, setItem]
  );

  const handleTagDelete = useCallback(
    (id: number) => {
      const index = item.tags.findIndex((i) => i.id === id);
      let newItemTags = item.tags;
      if (index > -1) {
        newItemTags = [
          ...item.tags.slice(0, index),
          ...item.tags.slice(index + 1),
        ];
      }
      setItem((old) => ({
        ...old,
        tags: newItemTags,
        saleItemList: old.saleItemList.map((s) => {
          const saleIndex = item.tags.findIndex((i) => i.id === id);
          if (saleIndex > -1) {
            return {
              ...s,
              tags: [
                ...s.tags.slice(0, saleIndex),
                ...s.tags.slice(saleIndex + 1),
              ],
            };
          }
          return s;
        }),
      }));
    },
    [item.tags, setItem]
  );

  const handleClassChange = (accountingClass: Class | null) => {
    setSaleItem({
      ...saleItem,
      accountingClassId: accountingClass ? accountingClass.id : null,
    });
  };

  const handleSyncModalConfirmClicked = () => {
    // sync sale item values with item
    setItem((old) => ({
      ...old,
      // values to sync
      alertNotes: saleItem.alertNotes,
      defaultUomId: saleItem.defaultUomId,
      description: saleItem.description,
      details: saleItem.details,
      dimensionUnit: saleItem.dimensionUnit,
      height: saleItem.height,
      length: saleItem.length,
      sku: saleItem.sku,
      upc: saleItem.upc,
      weight: saleItem.weight,
      weightUnit: saleItem.weightUnit,
      width: saleItem.width,
    }));

    onApplyClicked();
    setShowSyncValuesModal(false);
  };

  const handleSyncModalCancelClicked = () => {
    setShowSyncValuesModal(false);
    onApplyClicked();
  };

  const handlePriceChange = (_event: any) => {
    return handleTextFieldChange(setInputFieldDefaultValue(_event, '0'));
  };

  return (
    <>
      <Modal
        open={show}
        title={isNew ? 'Edit Sale Item' : 'Add Sale Item'}
        maxWidth="lg"
        onResetClicked={handleResetClicked}
        onCancelClicked={onCancelClicked}
        onApplyClicked={handleApplyClicked}
        applyLabel={isNew ? 'Update' : 'Add'}
        cancelLabel="Cancel"
        resetLabel="Reset"
        isLoading={false}
        permissions={editPermissions}
        dataQa="sale-item-modal"
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <ImageDropZone
              images={images}
              setImages={setImages}
              onCancel={handleCancelImagesClicked}
              onSave={handleSaveImagesClicked}
              permissions={editPermissions}
            />
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="text"
                  placeholder="Name"
                  label="Name"
                  name="name"
                  autoComplete="nope"
                  required
                  value={saleItem.name}
                  onChange={handleTextFieldChange}
                  error={!!validationErrors.name}
                  permissions={editPermissions}
                  dataQa="sale-item-name"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="text"
                  placeholder="Description"
                  label="Description"
                  name="description"
                  autoComplete="nope"
                  value={saleItem.description}
                  onChange={handleTextFieldChange}
                  permissions={editPermissions}
                  dataQa="sale-item-description"
                />
              </Grid>
              <Grid item xs={3}>
                <Tooltip title="Max. characters: 255">
                  <TextField
                    className="redesign"
                    variant="standard"
                    type="text"
                    placeholder="SKU"
                    label="SKU"
                    name="sku"
                    autoComplete="nope"
                    value={saleItem.sku}
                    onChange={handleTextFieldChange}
                    permissions={editPermissions}
                    dataQa="sale-item-sku"
                    error={!!skuValidationErrors.sku}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="text"
                  placeholder="Barcode / UPC"
                  label="Barcode / UPC"
                  autoComplete="nope"
                  name="upc"
                  value={saleItem.upc}
                  onChange={handleTextFieldChange}
                  permissions={editPermissions}
                  dataQa="sale-item-barcode/upc"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="text"
                  placeholder="URL"
                  label="URL"
                  name="url"
                  autoComplete="nope"
                  value={saleItem.url}
                  error={!!validationErrors.url}
                  onChange={handleTextFieldChange}
                  permissions={editPermissions}
                  dataQa="sale-item-url"
                  InputProps={{
                    endAdornment: (
                      <>
                        {saleItem.url && (
                          <FBOButton
                            variant="tertiary"
                            color="neutral"
                            size="small"
                            icon={IconNames.IconLinkHorizontal}
                            onClick={() => handleUrlLinkClicked(saleItem.url)}
                            data-qa="sale-item-modal-link-icon-button"
                          />
                        )}
                      </>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  onChange={handleUomChange}
                  label="UOM"
                  required
                  options={uomOptions}
                  getOptionLabel={(uom: Uom) => uom.name!}
                  value={selectedUom}
                  placeholder="UOM"
                  permissions={editPermissions}
                  error={!!validationErrors.defaultUomId}
                  dataQa="sale-item-uom"
                />
              </Grid>
              <Grid item xs={2}>
                <CurrencyField
                  decimalPlaces={6}
                  placeholder="Price"
                  label="Price"
                  name="price"
                  autoComplete="nope"
                  value={saleItem.price}
                  onChange={handlePriceChange}
                  permissions={editPermissions}
                  error={!!validationErrors.price}
                  dataQa="sale-item-price"
                  inputProps={{
                    'data-qa': 'sale-item-price' as any,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TaxRatesAutocomplete
                  placeholder="Sales tax"
                  label="Sales tax"
                  onChange={handleTaxRateChange}
                  value={saleItem.salesTaxId}
                  taxRateVariant={TaxRateVariants.Percentage}
                  error={!!validationErrors.salesTaxId}
                  permissions={editPermissions}
                  dataQa="sale-item-tax"
                  channelTaxType={ChannelTaxType.Sales}
                />
              </Grid>
              <Grid item xs={2}>
                <Autocomplete
                  label="Default Type"
                  options={filterDefaultSaleItemTypes(item)}
                  permissions={editPermissions}
                  value={saleItem.defaultSalesOrderItemType}
                  onChange={handleDefaultSalesOrderItemType}
                  required
                  dataQa="sale-item-default-type"
                />
              </Grid>
              {taxJarToken && (
                <Grid item xs={2}>
                  <TextField
                    type="text"
                    placeholder="Tax Code"
                    label="Tax Code"
                    name="productTaxCode"
                    autoComplete="nope"
                    value={saleItem.productTaxCode}
                    onChange={handleTextFieldChange}
                    permissions={editPermissions}
                    dataQa="sale-item-width"
                  />
                </Grid>
              )}
              {companyCountry === 'US' && (
                <Grid
                  item
                  xs={1.5}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <FormControlLabel
                    className="redesign"
                    control={
                      <Checkbox
                        className="redesign"
                        checked={Boolean(saleItem.taxable)}
                        name="taxable"
                        color="primary"
                        onChange={handleCheckboxChange}
                        inputProps={
                          {
                            'data-qa': 'sale-item-taxable',
                          } as any
                        }
                      />
                    }
                    label="Taxable"
                    disabled={!canEdit}
                  />
                </Grid>
              )}
              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  className="redesign"
                  control={
                    <Checkbox
                      className="redesign"
                      checked={saleItem.defaultFlag}
                      name="defaultFlag"
                      color="primary"
                      onChange={handleCheckboxChange}
                      inputProps={
                        {
                          'data-qa': 'sale-item-default',
                        } as any
                      }
                    />
                  }
                  label="Default"
                  disabled={!canEdit}
                />
              </Grid>
              {!isShippingServiceLaborOrOverheadType && (
                <>
                  <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                      <FBOButton
                        variant="tertiary"
                        color="neutral"
                        size="small"
                        icon="DownwardExpandArrow"
                        onClick={handleToggleDimensions}
                        data-qa="sale-item-dimension-and-weight"
                      />

                      <Link
                        onClick={handleToggleDimensions}
                        underline="always"
                        className={classes.buttonLink}
                        variant="body2"
                        color="textPrimary"
                      >
                        Dimensions And Weight
                      </Link>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Collapse in={showDimensions} timeout="auto" unmountOnExit>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <TextField
                            className="redesign"
                            variant="standard"
                            type="number"
                            placeholder="Width"
                            label="Width"
                            name="width"
                            autoComplete="nope"
                            value={saleItem.width}
                            onChange={handleNumberFieldChange}
                            disableDebounce={true}
                            permissions={editPermissions}
                            dataQa="sale-item-width"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            className="redesign"
                            variant="standard"
                            type="number"
                            placeholder="Height"
                            label="Height"
                            name="height"
                            autoComplete="nope"
                            value={saleItem.height}
                            onChange={handleNumberFieldChange}
                            disableDebounce={true}
                            permissions={editPermissions}
                            dataQa="sale-item-height"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            className="redesign"
                            variant="standard"
                            type="number"
                            placeholder="Length"
                            label="Length"
                            name="length"
                            autoComplete="nope"
                            value={saleItem.length}
                            onChange={handleNumberFieldChange}
                            disableDebounce={true}
                            permissions={editPermissions}
                            dataQa="sale-item-length"
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Autocomplete
                            placeholder="Dimension"
                            onChange={handleDimensionUnitChange}
                            label="Dimension"
                            required
                            options={DIMENSIONS_UNITS}
                            getOptionLabel={(u) => u.name}
                            value={selectedDimensionUnit}
                            permissions={editPermissions}
                            dataQa="sale-item-dimension-unit"
                          />
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={2}>
                          <TextField
                            className="redesign"
                            variant="standard"
                            type="number"
                            placeholder="Weight"
                            label="Weight"
                            name="weight"
                            autoComplete="nope"
                            value={saleItem.weight}
                            onChange={handleNumberFieldChange}
                            disableDebounce={true}
                            permissions={editPermissions}
                            dataQa="sale-item-weight"
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Autocomplete
                            value={selectedWeightUnit}
                            placeholder="Unit"
                            required
                            onChange={handleWeightUnitChange}
                            label="Weight Unit"
                            getOptionLabel={(u) => u.name}
                            options={WEIGHT_UNITS}
                            permissions={editPermissions}
                            dataQa="sale-item-weight-unit"
                          />
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                  <Grid item xs={12} />
                </>
              )}
              <Grid item xs={12}>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="text"
                  placeholder="Alert notes"
                  label="Alert Notes"
                  name="alertNotes"
                  autoComplete="nope"
                  multiline
                  rows={6}
                  value={saleItem.alertNotes}
                  onChange={handleTextFieldChange}
                  permissions={editPermissions}
                  dataQa="sale-item-alert-notes"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="redesign"
                  variant="standard"
                  type="text"
                  placeholder="Notes"
                  label="Notes"
                  name="details"
                  autoComplete="nope"
                  multiline
                  rows={6}
                  value={saleItem.details}
                  onChange={handleTextFieldChange}
                  permissions={editPermissions}
                  dataQa="sale-item-note"
                />
              </Grid>
              <Grid item xs={12}>
                <TagsAsyncAutocomplete
                  value={saleItem.tags}
                  permissions={editPermissions}
                  onChange={handleTagsChange}
                  onTagNameChange={handleTagNameChange}
                  onTagDelete={handleTagDelete}
                  dataQa="sale-item-tag"
                />
              </Grid>

              <Grid item xs={12}>
                <IncomeAccountField
                  saleItem={saleItem}
                  setSaleItem={setSaleItem}
                  item={item}
                  account={accounts}
                />
              </Grid>
              <Grid item xs={12}>
                <ClassAutocomplete
                  value={saleItem.accountingClassId}
                  onChange={handleClassChange}
                  placeholder="Select a class"
                  label="Accounting Class"
                  permissions={editPermissions}
                  dataQa="sale-item-accounting-class"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <ConfirmationModal
        body="Do you want to sync default sale item values with item values?"
        cancelLabel="No"
        confirmLabel="Yes"
        onCancelClicked={handleSyncModalCancelClicked}
        onConfirmClicked={handleSyncModalConfirmClicked}
        open={showSyncValuesModal}
        title="Sync Values"
      />
    </>
  );
};

export default memo(SaleItemModal);

export const setInputFieldDefaultValue = (
  e: React.ChangeEvent<HTMLInputElement>,
  defaultValue: string
): ChangeEvent => {
  if (e.target.value === undefined) {
    e.target.value = defaultValue;
  }
  return e;
};
