import React, { useCallback, useState } from 'react';

import { showNotification } from 'services/api';
import { Modal } from 'ui/components/Modal/Modal';
import Wizard from 'ui/components/Wizard/Wizard';
import { Errors, validateYup } from 'services/forms/validation';
import { BulkWizardItem } from 'services/inventory';

import { LocationsStep } from './components';
import { getSteps, initialBulkWizardData } from './consts';
import { BulkWizardData, BulkWizardProps } from './types';
import { useBulkWizardStyle } from './styled';
import { getSchema } from './validation';
import { ItemsStep } from './components/ItemsStep';
import { setBulkItemIndexes } from './helpers';

const BulkWizard: React.FC<BulkWizardProps> = (props) => {
  const { show, setShowBulkWizard } = props;

  const classes = useBulkWizardStyle();
  const [activeStep, setActiveStep] = useState(0);
  const [wizardData, setWizardData] = useState<BulkWizardData>(
    initialBulkWizardData
  );
  const [errors, setErrors] = useState<Errors>({});
  const [isLoading, setIsLoading] = useState(false);

  const setLocationIds = useCallback(
    (parameters: React.SetStateAction<number[]>) => {
      if (typeof parameters === 'function') {
        setWizardData((old) => ({
          ...old,
          locationIds: parameters(old.locationIds),
        }));
        return;
      }

      setWizardData((old) => ({
        ...old,
        locationIds: parameters,
      }));
    },
    []
  );

  const setItems = useCallback(
    (parameters: React.SetStateAction<BulkWizardItem[]>) => {
      if (typeof parameters === 'function') {
        setWizardData((old) => ({
          ...old,
          items: setBulkItemIndexes(parameters(old.items)),
        }));
        return;
      }

      setWizardData((old) => ({
        ...old,
        items: setBulkItemIndexes(parameters),
      }));
    },
    []
  );

  const handleNextClicked = useCallback(async () => {
    const schema = getSchema(activeStep);
    const isValid = validateYup(wizardData, schema, setErrors);

    if (activeStep === 0 && !isValid) {
      showNotification('You need to select a location', {
        variant: 'warning',
      });
      return;
    }

    if (!isValid) {
      return;
    }

    // if last step
    if (activeStep === getSteps().length - 1) {
      setIsLoading(true);
      try {
        if (errors.length) {
          setIsLoading(false);
          return;
        }
      } catch (error) {
        // ignore error
      }
      setIsLoading(false);
      setShowBulkWizard(false);
      setWizardData(initialBulkWizardData);
      setActiveStep(0);
      showNotification('Successfully moved inventory', {
        variant: 'success',
      });

      return;
    }
    setActiveStep((old) => old + 1);
  }, [activeStep, wizardData, setShowBulkWizard, setErrors, setItems]);

  const handleBackClicked = useCallback(() => {
    setItems([]);
    setActiveStep((old) => old - 1);
  }, [setItems]);

  const handleCancelClicked = useCallback(() => {
    setActiveStep(0);
    setShowBulkWizard(false);
    setWizardData(initialBulkWizardData);
  }, [setShowBulkWizard]);

  return (
    <Modal
      open={show}
      onCancelClicked={handleCancelClicked}
      title="Bulk Move Inventory"
      scroll="paper"
      withoutFooter
      withoutDefaultPadding
      maxWidth="lg"
      dataQa="bulk-move"
    >
      <Wizard
        steps={getSteps(activeStep, classes.activeIcon)}
        activeStep={activeStep}
        onNext={handleNextClicked}
        onPrevious={handleBackClicked}
        disableNext={activeStep === 1 && !wizardData.items.length}
        hideSteps
        isLoading={isLoading}
      >
        {[
          <LocationsStep
            key={0}
            wizardData={wizardData}
            setLocationIds={setLocationIds}
          />,
          <ItemsStep
            key={1}
            wizardData={wizardData}
            errors={errors}
            setBulkItems={setItems}
          />,
        ]}
      </Wizard>
    </Modal>
  );
};

export default BulkWizard;
