import _ from 'lodash';
import React, { memo, useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, Box } from '@mui/material';

import {
  fetchReceiptById,
  receiveReceipt,
  updateReceiptItem,
  voidReceiptItem,
  voidReceipt,
  receiveReceiptItem,
  holdReceiptItem,
  fulfillReceipt,
  fulfillReceiptItem,
  updateReceipt,
} from 'services/receiving/api';
import {
  Receipt,
  initialReceipt,
  ReceiptItem,
  initialReceiptItem,
  ReceiptItemStatus,
  ReceiptItemReceive,
} from 'services/receiving';
import {
  clearModuleNavigation,
  ModuleNavigationType,
  updateModuleNavigation,
} from 'services/moduleNavigation';
import { ViewTrackingModal } from 'ui/components/Modal/ViewTrackingModal';
import { TabPanel } from 'ui/components/TabPanel';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { PermissionType } from 'services/permissions';
import { ReportId } from 'services/reports';
import { ReportsModal } from 'ui/components/Modal/ReportsModal';
import { activeUserHasPermission } from 'services/user/redux';
import { deleteDocuments } from 'services/documents';
import { Errors, validateYup } from 'services/forms/validation';
import { showNotification } from 'services/api';

import { ReceivingDetailsCardProps, ThreeDotMenuActions } from './types';
import {
  ReceiveModal,
  ReceivingDocumentsTab,
  ReceivingItems,
} from './components';
import { ReceivingItemModal } from './components/ReceivingItemModal';
import { ReceivingRejectModal } from './components/ReceivingRejectModal';
import {
  shouldShowFulfill,
  shouldShowReceive,
  shouldShowReconcile,
  shouldShowVoid,
} from './transformations';
import { createActionBarOptions } from './consts';
import {
  resolveItemsToReceive,
  resolveReceiptItemsToFulfill,
  isValidReceiptItemReceives,
  receiptContainsOnlyDropShipItems,
} from './helpers';
import { receiptItemsYupSchema, yupReceivingRejectSchema } from './validations';
import { getErrorMessage } from 'helpers';
import { logErrorCtx } from 'app/logging';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import FBOGeneralTab from './components/GeneralTab/FBOGeneralTab';
import FBOReceiveWizard from './components/ReceiveWizard/FBOReceiveWizard';
import FBOReconcileWizard from './components/ReconcileWizard/FBOReconcileWizard';

const ReceivingDetailsCard: React.FC<ReceivingDetailsCardProps> = (props) => {
  const { activeReceiptId, onClose, fetchSearchResult } = props;
  const dispatch = useDispatch();

  const [activeReceipt, setActiveReceipt] = useState<Receipt>(initialReceipt);
  const [activeReceiptItem, setActiveReceiptItem] =
    useState<ReceiptItem>(initialReceiptItem);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showReconcileWizard, setShowReconcileWizard] = useState(false);
  const [showReceiveWizard, setShowReceiveWizard] = useState(false);
  const [showReceivingItemModal, setReceivingItemModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showReceiveModal, setShowReceiveModal] = useState(false);
  const [showTracking, setShowTracking] = useState(false);
  const [fulfillClicked, setFulfillClicked] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  const canEditReceiptItems = useSelector(
    activeUserHasPermission([PermissionType.ReceivingEdit])
  );

  const hideModal = useCallback(() => {
    setReceivingItemModal(false);
    setActiveReceiptItem(initialReceiptItem);
  }, []);

  // items shown in receive wizard have:
  // - status open
  const itemsToReceive = useMemo(() => {
    return activeReceipt.receiptItems.filter(
      (i) =>
        i.status === ReceiptItemStatus.Open ||
        i.status === ReceiptItemStatus.Reconciled
    );
  }, [activeReceipt.receiptItems]);

  const landedAndBilledAreDifferent = useMemo(() => {
    return activeReceipt.receiptItems.some((i) => {
      const landedTotalCost = _.get(i, 'landedTotalCost');
      const billedTotalCost = _.get(i, 'billedTotalCost');

      return landedTotalCost !== billedTotalCost;
    });
  }, [activeReceipt.receiptItems]);

  const saveReceiptDocuments = async (newReceipt: Receipt) => {
    const notDeletedDocuments = newReceipt.documents.filter((d) => !d.deleted);
    const deletedDocuments = newReceipt.documents.filter((d) => d.deleted);

    const resolvedReceipt = {
      ...newReceipt,
      documents: notDeletedDocuments,
    };
    try {
      await updateReceipt(resolvedReceipt);
      const res = await fetchReceiptById(resolvedReceipt.id!);
      setActiveReceipt(res);
    } catch (error) {
      return;
    }
    try {
      await deleteDocuments(deletedDocuments);
    } catch (error) {
      // ignore error
    }
  };

  useEffect(() => {
    const asyncFc = async (id: number) => {
      setIsLoading(true);

      try {
        const receipt = await fetchReceiptById(id);
        setActiveReceipt(receipt);
      } catch (err) {
        const message = getErrorMessage(err);
        logErrorCtx('Error in fetchReceiptById', {
          error: err as Error,
          component: 'ReceivingDetailsCard',
          description: `Receipt id ${id}`,
        });
        showNotification(`${message} - receiving detail couldn't be loaded.`, {
          variant: 'error',
        });
        setIsLoading(false);
        onClose();

        return;
      }

      setIsLoading(false);
      setActiveTab(0);
    };

    if (!activeReceiptId) {
      setActiveReceipt(initialReceipt);
      return;
    }

    asyncFc(activeReceiptId);
    // eslint-disable-next-line
  }, [activeReceiptId]);

  // update spatial navigation
  useEffect(() => {
    if (!activeReceipt.id) {
      dispatch(clearModuleNavigation(ModuleNavigationType.Purchase));
      return;
    }

    if (activeReceipt.orderType === 'Purchase Order') {
      const dropShipSalesOrderId = _.get(
        activeReceipt,
        'purchaseOrder.dropShipSalesOrder.id'
      );

      dispatch(
        updateModuleNavigation(ModuleNavigationType.Purchase, {
          purchaseOrderId: activeReceipt.purchaseOrderId || undefined,
          receiptId: activeReceipt.id,
          salesOrderIds: dropShipSalesOrderId
            ? [dropShipSalesOrderId]
            : undefined,
        })
      );
    } else {
      dispatch(
        updateModuleNavigation(ModuleNavigationType.Sales, {
          salesOrderIds: activeReceipt.salesOrderId
            ? [activeReceipt.salesOrderId]
            : undefined,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeReceipt.status, activeReceipt.id]);

  const handleActiveTabChange = useCallback(
    (e: React.ChangeEvent<{}>, value: number) => {
      setActiveTab(value);
    },
    []
  );

  const rowMenuAction = (action: {
    type: ThreeDotMenuActions;
    receiptItem: ReceiptItem;
  }) => {
    const { type, receiptItem } = action;

    switch (type) {
      case ThreeDotMenuActions.Hold:
        handleHoldItem(receiptItem);
        break;
      case ThreeDotMenuActions.Receive:
        setActiveReceiptItem(receiptItem);
        setFulfillClicked(false);
        setShowReceiveModal(true);
        break;
      case ThreeDotMenuActions.Reject:
        setActiveReceiptItem(receiptItem);
        setShowRejectModal(true);
        break;
      case ThreeDotMenuActions.Fulfill:
        handleFulfillReceiptItem(receiptItem);
        break;
      case ThreeDotMenuActions.Void:
        handleVoidItem(receiptItem);
        break;
      case ThreeDotMenuActions.ShowTracking:
        handleShowTracking(receiptItem);
        break;
    }
  };

  const handleVoidItem = useCallback(
    async (item: ReceiptItem) => {
      setIsLoading(true);
      try {
        const resp = await voidReceiptItem(activeReceipt.id!, item.id!);
        setActiveReceipt(resp);
      } catch {
        // Ignore error
      }
      setIsLoading(false);
      fetchSearchResult();
    },
    [activeReceipt.id, fetchSearchResult]
  );

  const handleHoldItem = useCallback(
    async (item: ReceiptItem) => {
      setIsLoading(true);
      try {
        const newReceipt = await holdReceiptItem(activeReceiptId!, item.id!);
        setActiveReceipt(newReceipt);
      } catch {
        // Ignore error
      }
      setIsLoading(false);
      fetchSearchResult();
    },
    [activeReceiptId, setActiveReceipt, fetchSearchResult]
  );

  const handleFulfillReceipt = useCallback(async () => {
    // If there are not items to receive go straight to fulfill
    if (itemsToReceive.length === 0) {
      setIsLoading(true);
      try {
        const newReceipt = await fulfillReceipt(
          activeReceipt.id!,
          [],
          resolveReceiptItemsToFulfill(activeReceipt.receiptItems)
        );
        setActiveReceipt(newReceipt);
      } catch {
        // Ignore error
      }
      fetchSearchResult();
      setIsLoading(false);
      return;
    }

    // First we need to receive than fulfill
    setShowReceiveWizard(true);
    setFulfillClicked(true);
  }, [activeReceipt, fetchSearchResult, itemsToReceive]);

  const handleFulfillReceiptItem = useCallback(
    async (receiptItemToFulfill: ReceiptItem) => {
      if (receiptItemToFulfill.status === ReceiptItemStatus.Received) {
        setIsLoading(true);
        try {
          const newReceipt = await fulfillReceiptItem(
            activeReceipt.id!,
            receiptItemToFulfill
          );
          setActiveReceipt(newReceipt);
        } catch {
          // Ignore error
        }
        setIsLoading(false);
        fetchSearchResult();
        return;
      }

      // If item is not received yet we need to go trough receive
      setActiveReceiptItem(receiptItemToFulfill);
      setFulfillClicked(true);
      setShowReceiveModal(true);
    },
    [activeReceipt.id, fetchSearchResult]
  );

  const handleVoidReceipt = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await voidReceipt(activeReceipt.id!);
      setActiveReceipt(resp);
    } catch {
      // Ignore error
    }
    setIsLoading(false);
    fetchSearchResult();
  }, [activeReceipt.id, fetchSearchResult]);

  const handleReceivingItemClick = useCallback(
    (id: number) => {
      setActiveReceiptItem(
        activeReceipt.receiptItems.find((receiptItem) => receiptItem.id === id)!
      );
      if (canEditReceiptItems) {
        setReceivingItemModal(true);
      }
    },
    [activeReceipt.receiptItems, canEditReceiptItems]
  );

  const handleReconcileClicked = useCallback(() => {
    setShowReconcileWizard(true);
  }, []);

  const handleReceiveClicked = useCallback(() => {
    setShowReceiveWizard(true);
    setFulfillClicked(false);
  }, []);

  const handleReconcileWizardClose = useCallback(() => {
    setActiveReceiptItem(initialReceiptItem);
    setShowReconcileWizard(false);
  }, []);

  const handleReceiveWizardClose = useCallback(() => {
    setShowReceiveWizard(false);
  }, []);

  const handleRejectModalClose = useCallback(() => {
    setShowRejectModal(false);
  }, []);

  const handleSaveReciptItem = useCallback(async () => {
    if (
      activeReceiptItem.status === ReceiptItemStatus.Rejected &&
      !validateYup(activeReceiptItem, yupReceivingRejectSchema, setErrors)
    ) {
      return;
    }
    if (!validateYup(activeReceiptItem, receiptItemsYupSchema, setErrors)) {
      return;
    }
    // Update Receipt item
    try {
      const newReceipt = await updateReceiptItem(
        activeReceiptId!,
        activeReceiptItem
      );
      setActiveReceipt(newReceipt);
    } catch {
      return;
    }

    setReceivingItemModal(false);
  }, [activeReceiptItem, activeReceiptId, setReceivingItemModal]);

  const handleReceiveWizardSubmit = useCallback(
    async (receiptItemReceives: ReceiptItemReceive[]) => {
      // If we opened modal trough fulfill
      if (fulfillClicked) {
        setIsLoading(true);
        try {
          const newReceipt = await fulfillReceipt(
            activeReceipt.id!,
            receiptItemReceives.filter((i) => i.quantity)
          );
          setActiveReceipt(newReceipt);
        } catch {
          // Ignore error
        }
        fetchSearchResult();
        setIsLoading(false);
        setFulfillClicked(false);
        return;
      }

      // if there is only 1 item, call single receipt item endpoint
      if (receiptItemReceives.length === 1) {
        const receipt = await receiveReceiptItem(
          activeReceipt.id!,
          receiptItemReceives[0].receiptItemId!,
          receiptItemReceives[0]
        );
        setActiveReceipt(receipt);
      } else {
        const receipt = await receiveReceipt(
          activeReceipt.id!,
          receiptItemReceives.filter(isValidReceiptItemReceives)
        );
        setActiveReceipt(receipt);
      }
      fetchSearchResult();
    },
    [activeReceipt.id, fetchSearchResult, fulfillClicked]
  );

  const handleReceiveReceiptItem = useCallback(
    async (receive: ReceiptItemReceive) => {
      if (!fulfillClicked) {
        try {
          const receipt = await receiveReceiptItem(
            activeReceiptItem.receiptId!,
            activeReceiptItem.id!,
            receive
          );

          setActiveReceipt(receipt);
          fetchSearchResult();
        } catch {
          return;
        }

        setShowReceiveModal(false);
        setActiveReceiptItem(initialReceiptItem);
        return;
      }

      setFulfillClicked(false);
      try {
        const newReceipt = await fulfillReceiptItem(
          activeReceipt.id!,
          undefined,
          receive
        );
        setActiveReceipt(newReceipt);
      } catch {
        // Ignore error
      }
      fetchSearchResult();
      setShowReceiveModal(false);
      setActiveReceiptItem(initialReceiptItem);
    },
    [activeReceiptItem, fetchSearchResult, activeReceipt.id, fulfillClicked]
  );

  const handleShowTracking = useCallback((receiptItem: ReceiptItem) => {
    setActiveReceiptItem(receiptItem);
    setShowTracking(true);
  }, []);

  const handleCloseTracking = useCallback(() => {
    setShowTracking(false);
    setActiveReceiptItem(initialReceiptItem);
  }, []);

  const setReceiptItem = useCallback(
    (receiptItems: React.SetStateAction<ReceiptItem[]>) => {
      if (typeof receiptItems === 'function') {
        setActiveReceipt((old) => ({
          ...old,
          receiptItems: receiptItems(old.receiptItems),
        }));
        return;
      }

      setActiveReceipt((old) => ({
        ...old,
        receiptItems,
      }));
    },
    [setActiveReceipt]
  );

  const itemName = _.get(activeReceiptItem, 'item.name');

  const isDropShipReceipt = useMemo(() => {
    return receiptContainsOnlyDropShipItems(activeReceipt.receiptItems);
  }, [activeReceipt]);

  return (
    <>
      <DetailsCard isLoading={isLoading}>
        <FBOTitleBar
          title={`Receipt - ${activeReceipt.number}`}
          status={activeReceipt.status}
        >
          {(!_.isEmpty(itemsToReceive) ||
            shouldShowReceive(activeReceipt.status)) && (
            <FBOButton
              sx={{ marginRight: '8px' }}
              variant="secondary"
              color="positive"
              size="medium"
              data-qa="receiving-receive"
              permissions={[PermissionType.ReceivingReceive]}
              onClick={handleReceiveClicked}
            >
              Receive
            </FBOButton>
          )}
          {shouldShowReconcile(
            activeReceipt.status,
            activeReceipt.salesOrderId,
            isDropShipReceipt
          ) && (
            <FBOButton
              sx={{ marginRight: '8px' }}
              variant="secondary"
              color="positive"
              size="medium"
              data-qa="receiving-reconcile"
              permissions={[PermissionType.ReceivingReconcile]}
              onClick={handleReconcileClicked}
            >
              Reconcile
            </FBOButton>
          )}
          {shouldShowFulfill(
            activeReceipt.status,
            activeReceipt.salesOrderId
          ) && (
            <FBOButton
              sx={{ marginRight: '8px' }}
              variant="secondary"
              color="positive"
              size="medium"
              data-qa="receiving-fulfill"
              permissions={[PermissionType.ReceivingFulfill]}
              onClick={handleFulfillReceipt}
            >
              Fulfill
            </FBOButton>
          )}
          {shouldShowVoid(
            activeReceipt.status,
            landedAndBilledAreDifferent
          ) && (
            <FBOButton
              sx={{ marginRight: '8px' }}
              variant="secondary"
              color="negative"
              size="medium"
              data-qa="receiving-void"
              onClick={handleVoidReceipt}
              permissions={[PermissionType.ReceivingEdit]}
            >
              Void
            </FBOButton>
          )}
          <FBOMenuButton
            sx={{ marginRight: '8px' }}
            variant="tertiary"
            data-qa="receiving-item-three-dot-menu"
            items={createActionBarOptions(activeReceiptId, () =>
              setShowReportModal(true)
            )}
          />
          <FBOButton
            variant="tertiary"
            color="neutral"
            size="medium"
            icon="FBOClose"
            data-qa="receiving-close"
            onClick={onClose}
          />
        </FBOTitleBar>

        <Tabs
          value={activeTab}
          onChange={handleActiveTabChange}
          indicatorColor="primary"
          className="redesign"
        >
          <Tab label="General" />
          <Tab label="Documents" />
        </Tabs>
        <TabPanel value={activeTab} index={0} flexGrow noSpacing>
          <Box display="flex" flexDirection="column" height="100%" width="100%">
            <FBOGeneralTab activeReceipt={activeReceipt} />
            <ReceivingItems
              receipt={activeReceipt}
              setReceiptItems={setReceiptItem}
              onThreeDotMenuAction={rowMenuAction}
              onReceivingItemClick={handleReceivingItemClick}
            />
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={1} flexGrow noSpacing>
          <ReceivingDocumentsTab
            activeReceipt={activeReceipt}
            setActiveReceipt={setActiveReceipt}
            customDocumentSave={saveReceiptDocuments}
          />
        </TabPanel>
      </DetailsCard>

      <ReceivingItemModal
        activeReceiptItem={activeReceiptItem}
        setActiveReceiptItem={setActiveReceiptItem}
        receipt={activeReceipt}
        show={showReceivingItemModal}
        onClose={hideModal}
        onSave={handleSaveReciptItem}
        errors={errors}
      />
      <FBOReconcileWizard
        show={showReconcileWizard}
        receipt={activeReceipt}
        activeReceiptItem={activeReceiptItem}
        setActiveReceipt={setActiveReceipt}
        onClose={handleReconcileWizardClose}
        fetchSearchResult={fetchSearchResult}
      />

      <ReceivingRejectModal
        show={showRejectModal}
        onClose={handleRejectModalClose}
        activeReceiptItem={activeReceiptItem}
        setActiveReceiptItem={setActiveReceiptItem}
        activeReceiptId={activeReceipt.id}
        setActiveReceipt={setActiveReceipt}
      />
      <FBOReceiveWizard
        show={showReceiveWizard}
        receipt={activeReceipt}
        receiptItems={resolveItemsToReceive(
          activeReceipt.receiptItems,
          fulfillClicked
        )}
        onClose={handleReceiveWizardClose}
        onSubmit={handleReceiveWizardSubmit}
      />

      <ReceiveModal
        receipt={activeReceipt}
        receiptItem={activeReceiptItem}
        show={showReceiveModal}
        fulfillClicked={fulfillClicked}
        onCancel={() => setShowReceiveModal(false)}
        onSubmit={handleReceiveReceiptItem}
      />
      <ViewTrackingModal
        open={showTracking}
        itemTrackingTypes={_.get(
          activeReceiptItem,
          'item.itemTrackingTypeList',
          []
        )}
        trackingGroups={_.get(activeReceiptItem, 'trackingGroupList', [])}
        onClose={handleCloseTracking}
        disableAutoAssign
        title={itemName ? `Tracking for ${itemName}` : undefined}
      />
      <ReportsModal
        isOpen={showReportModal}
        reportId={ReportId.ReceivingList}
        params={{ receiptId: activeReceiptId }}
        onClose={() => setShowReportModal(false)}
        autoGenerate
      />
    </>
  );
};

export default memo(ReceivingDetailsCard);
