import React from 'react';
import { Switch } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';

import { Routes } from './routes';
import {
  IntegrationsPage,
  EcommercePage,
  ShippingPage,
  IntegrationsRedirectPage,
} from '../pages';
import { IntegrationsRouterCmp, IntegrationsRouterProps } from './types';
import { isTestEnv } from 'services/api/testEnv';

const IntegrationsRouter: IntegrationsRouterCmp = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: IntegrationsRouterProps
) => {
  return (
    <Switch>
      <SecureRoute
        path={IntegrationsPage.route}
        exact
        component={IntegrationsPage}
        redirectToHome={isTestEnv()}
      />
      <SecureRoute
        path={EcommercePage.route}
        exact
        component={EcommercePage}
        redirectToHome={isTestEnv()}
      />
      <SecureRoute
        path={ShippingPage.route}
        exact
        component={ShippingPage}
        redirectToHome={isTestEnv()}
      />
      <SecureRoute
        exact
        path={IntegrationsRedirectPage.route}
        component={IntegrationsRedirectPage}
        redirectToHome={isTestEnv()}
      />
    </Switch>
  );
};

IntegrationsRouter.route = Routes.IntegrationsRouter;

export default IntegrationsRouter;
