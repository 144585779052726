import { createFilterOptions, FilterOptionsState } from '@mui/material';
import { AccountType } from 'services/accounting';
import {
  fetchQbdAccountsAPI,
  initialQbdAccount,
  QbdAccount,
} from 'services/integrations/qbd';

import { Pagination } from 'services/search';

const filter = createFilterOptions<QbdAccount>();

export const qbdAccountAutocompleteOptionLabel = (qbdAccount: QbdAccount) => {
  return qbdAccount.name || '';
};

export const qbdAccountsAutocompleteFilterOptions =
  (disableAdd: boolean) =>
  (options: QbdAccount[], params: FilterOptionsState<QbdAccount>) => {
    const filtered = filter(options, params);

    if (disableAdd) {
      return filtered;
    }

    if (!!params.inputValue) {
      filtered.unshift({
        ...initialQbdAccount,
        name: params.inputValue || null,
      });
    }

    return filtered;
  };

export const resolvedFetchQbdAccountsFunction = (
  accountType: AccountType | string | null,
  listIds: string[] | null,
  expands?: string[],
  customQuickSearchColumns?: string[]
) => {
  return (pagination: Pagination, searchValue: string | null) =>
    fetchQbdAccountsAPI(
      {
        pagination,
        expands: expands,
        quickSearchValue: searchValue,
        customQuickSearchColumns,
      },
      accountType,
      listIds
    );
};
