import React from 'react';
import { Box, Typography, Paper } from '@mui/material';

import { TaxJarTooltipProps } from './types';
import { TextOnlyTaxJarTooltip } from './styled';
import { roundToDecimals } from 'helpers';

const TaxJarTooltip: React.FC<TaxJarTooltipProps> = (props) => {
  const {
    values,
    children,
    placement = 'bottom',
    disableHoverListener,
  } = props;

  const formatTaxValues = (amount: number, rate: number) => {
    const amountRounded = roundToDecimals(amount, 2);
    return `$ ${amountRounded} / ${(rate * 100).toFixed(3)} %`;
  };

  const tooltipValue = () => (
    <Box display="flex" flexDirection="column">
      <Paper>
        <Box display="flex" justifyContent="space-between" p={1}>
          <Typography
            variant="body1"
            color="textSecondary"
            data-qa="popover-item"
          >
            State tax:
          </Typography>
          <Typography>
            {values
              ? formatTaxValues(values.stateTaxAmount, values.stateTaxRate)
              : '0'}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" p={1}>
          <Typography
            variant="body1"
            color="textSecondary"
            data-qa="popover-item"
          >
            County tax:
          </Typography>
          <Typography>
            {values
              ? formatTaxValues(values.countyTaxAmount, values.countyTaxRate)
              : '0'}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" p={1}>
          <Typography
            variant="body1"
            color="textSecondary"
            data-qa="popover-item"
          >
            City tax:
          </Typography>
          <Typography>
            {values
              ? formatTaxValues(values.cityTaxAmount, values.cityTaxRate)
              : '0'}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" p={1}>
          <Typography
            variant="body1"
            color="textSecondary"
            data-qa="popover-item"
          >
            Special tax:
          </Typography>
          <Typography>
            {values
              ? formatTaxValues(
                  values.specialDistrictTaxAmount,
                  values.specialDistrictTaxRate
                )
              : '0'}
          </Typography>
        </Box>
      </Paper>
    </Box>
  );

  return (
    <TextOnlyTaxJarTooltip
      placement={placement}
      title={tooltipValue()}
      disableHoverListener={disableHoverListener}
    >
      {children}
    </TextOnlyTaxJarTooltip>
  );
};

export default TaxJarTooltip;
