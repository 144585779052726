import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { TableCell, Grid, Link, Box, Popover, Typography } from '@mui/material';
import _ from 'lodash';

import { IconNames } from 'ui/theme';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { TextField } from 'ui/components/TextField/TextField';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { Icon } from 'ui/components/Icon';
import { MultiFormatInput } from 'ui/components/TextField/MultiFormatInput';
import { getTaxRates, TaxRate, renderTaxRate } from 'services/taxRates';
import {
  useCurrencyFormatter,
  roundToDecimals,
  stopInputPropagation,
  useGetCurrencySymbol,
  formatTypes,
  toMulticurrencyCalculate,
  toHomeCurrencyCalculate,
} from 'helpers';
import { getUoms, EACH_UOM_ID, Uom } from 'services/uoms';
import { PermissionType } from 'services/permissions';
import {
  SalesOrderItemTypes,
  SalesOrderItem,
  DiscountTypes,
  convertToNegative,
  calculatePriceBasedOnUomChange,
  calculateTotals,
  SalesOrderStatus,
  SalesOrderItemStatus,
} from 'services/salesOrders';
import { TaxJarTooltip } from 'ui/components/TaxJarTooltip';
import { TaxTooltip } from 'ui/components/TaxTooltip';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { activeUserHasPermission } from 'services/user/redux';
import { getSettingsCompany } from 'services/settings/company';
import { filterItemUoms } from 'services/items/helpers';
import { getSettingsSalesOrder } from 'services/settings/salesOrders';

import { MenuTableCell } from '../MenuTableCell';
import { PopoverItem } from '../PopoverItem';
import {
  totalAnchorOrigin,
  totalTransformOrigin,
  discountAnchorOrigin,
  discountTransformOrigin,
} from './consts';
import { useSalesOrderTableCellStyle } from '../../styled';
import { editSalesOrderPermissions } from '../../../../helpers';
import { calculateMulticurrencyPrice } from '../../helpers';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const MiscRow: React.FC<RenderCustomRowProps<SalesOrderItem>> = (props) => {
  const {
    row,
    index,
    setData,
    errors,
    meta: { salesOrder },
  } = props;

  const currencyFormatter = useCurrencyFormatter();
  const currencySymbol = useGetCurrencySymbol();

  const activeMulticurrencyCode = _.get(
    salesOrder,
    'customer.currency.code',
    null
  );

  const [taxEl, setTaxEl] = useState<null | HTMLElement>(null);
  const [discountEl, setDiscountEl] = useState<null | HTMLElement>(null);
  const [discountPopupIndex, setDiscountPopupIndex] = useState<0 | 1>(0);
  const [discountPopupValue, setDiscountPopupValue] = useState<number | null>(
    null
  );
  const [uoms, setUoms] = useState<Uom[]>([]);

  const { items: storeTaxRates } = useSelector(getTaxRates);
  const { items: defaultUoms } = useSelector(getUoms);
  const { taxJarToken } = useSelector(getSettingsCompany);

  const fieldsDisabled =
    salesOrder.status === SalesOrderStatus.Fulfilled ||
    salesOrder.status === SalesOrderStatus.PartiallyFulfilled ||
    salesOrder.status === SalesOrderStatus.PartiallyPicked ||
    salesOrder.status === SalesOrderStatus.Cancelled ||
    salesOrder.deleted ||
    row.status === SalesOrderItemStatus.Fulfilled;

  const editPermission = editSalesOrderPermissions(salesOrder);

  const discount = row.discount.value
    ? roundToDecimals(row.discount.value, 2)
    : 0;

  const canClick = useSelector(activeUserHasPermission(editPermission));
  const soSettings = useSelector(getSettingsSalesOrder);
  const classes = useSalesOrderTableCellStyle();

  const canClikLink = !fieldsDisabled && canClick;

  const selectedTaxRate = useMemo(
    () => storeTaxRates.find((t) => t.id === row.taxId) || null,
    [storeTaxRates, row.taxId]
  );

  const isDiscountPopVisible = useMemo(() => Boolean(discountEl), [discountEl]);
  const isTaxPopoverVisible = useMemo(() => Boolean(taxEl), [taxEl]);
  const taxRates = useMemo(
    () => storeTaxRates.filter((tax) => tax.taxType === 'Percentage'),
    [storeTaxRates]
  );

  const formatedMulticurrencySubTotal = useMemo(
    () =>
      activeMulticurrencyCode &&
      currencyFormatter(
        row.multiCurrencySubTotal || 0,
        activeMulticurrencyCode
      ),
    [activeMulticurrencyCode, row.multiCurrencySubTotal, currencyFormatter]
  );

  const formatedMulticurrencyTaxTotal = useMemo(
    () =>
      activeMulticurrencyCode &&
      currencyFormatter(
        row.multiCurrencyTaxTotal || 0,
        activeMulticurrencyCode
      ),
    [activeMulticurrencyCode, row.multiCurrencyTaxTotal, currencyFormatter]
  );

  const rowIcon = useMemo(() => {
    switch (row.salesOrderItemType) {
      case SalesOrderItemTypes.MiscSale:
        return <Icon name={IconNames.Widgets} noDefaultFill />;
      case SalesOrderItemTypes.MiscReturn:
        return <Icon name={IconNames.CreditReturn} noDefaultFill />;
    }
  }, [row.salesOrderItemType]);

  const resolveDisplayTax = useMemo(() => {
    const displayTaxJarTaxRate =
      row.cityTaxRate +
      row.countyTaxRate +
      row.specialDistrictTaxRate +
      row.stateTaxRate;

    if (taxJarToken) {
      return `${(displayTaxJarTaxRate * 100).toFixed(3)}%`;
    }
    if (selectedTaxRate) {
      return renderTaxRate(selectedTaxRate, currencySymbol);
    } else {
      return '0%';
    }
  }, [taxJarToken, row, selectedTaxRate, currencySymbol]);

  useEffect(() => {
    if (row.saleItem) {
      const itemUoms = filterItemUoms(row.saleItem.defaultUom, defaultUoms);
      if (itemUoms) {
        setUoms(itemUoms);
      }
    }
  }, [row, defaultUoms]);

  const handleTextInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value ? e.target.value : null;
    setData({ ...row, [e.target.name]: value }, index);
  };

  const handlePriceInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const convertedValue = e.target.value
        ? convertToNegative(e.target.value, row.salesOrderItemType)
        : null;

      const multiPrice = calculateMulticurrencyPrice(
        convertedValue || 0,
        salesOrder.exchangeRate || 1
      );

      const totals = calculateTotals(
        convertedValue || 0,
        row.quantity || 0,
        row.discount,
        row.taxRate || 0,
        soSettings.priceIncludesTax || false
      );

      const multiCurrencyTotal = toMulticurrencyCalculate(
        totals.subTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyTaxTotal = toMulticurrencyCalculate(
        totals.taxTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyDiscountTotal = toMulticurrencyCalculate(
        totals.discountTotal,
        salesOrder.exchangeRate || 1
      );

      setData(
        {
          ...row,
          price: convertedValue,
          multiCurrencyItemPrice: multiPrice,
          ...totals,
          multiCurrencyTotal,
          multiCurrencyTaxTotal,
          multiCurrencyDiscountTotal,
        },
        index
      );
    },
    [row, index, setData, salesOrder.exchangeRate, soSettings.priceIncludesTax]
  );

  const handleMultiCurrencyPriceInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const convertedValue = e.target.value
        ? convertToNegative(e.target.value, row.salesOrderItemType)
        : null;

      const price = toHomeCurrencyCalculate(
        convertedValue || 0,
        salesOrder.exchangeRate || 1
      );

      const totals = calculateTotals(
        price,
        row.quantity || 0,
        row.discount,
        row.taxRate || 0,
        soSettings.priceIncludesTax || false
      );

      const multiCurrencyTotal = toMulticurrencyCalculate(
        totals.subTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyTaxTotal = toMulticurrencyCalculate(
        totals.taxTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyDiscountTotal = toMulticurrencyCalculate(
        totals.discountTotal,
        salesOrder.exchangeRate || 1
      );

      setData(
        {
          ...row,
          multiCurrencyItemPrice: convertedValue,
          price: price,
          ...totals,
          multiCurrencyTotal,
          multiCurrencyTaxTotal,
          multiCurrencyDiscountTotal,
        },
        index
      );
    },
    [row, index, setData, salesOrder.exchangeRate, soSettings.priceIncludesTax]
  );

  const handleAmountInputChange = useCallback(
    (value: number | null) => {
      const totals = calculateTotals(
        row.price || 0,
        value || 0,
        row.discount,
        row.taxRate || 0,
        soSettings.priceIncludesTax || false
      );

      const multiCurrencyTotal = toMulticurrencyCalculate(
        totals.subTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyTaxTotal = toMulticurrencyCalculate(
        totals.taxTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyDiscountTotal = toMulticurrencyCalculate(
        totals.discountTotal,
        salesOrder.exchangeRate || 1
      );

      setData(
        {
          ...row,
          quantity: value,
          ...totals,
          multiCurrencyTaxTotal,
          multiCurrencyTotal,
          multiCurrencyDiscountTotal,
        },
        index
      );
    },
    [row, index, setData, salesOrder.exchangeRate, soSettings.priceIncludesTax]
  );

  const handleAmountMenuChange = useCallback(
    (uomId: number) => {
      const newUom = uoms.find((u) => u.id === uomId)!;
      const oldUom = uoms.find((u) => u.id === row.uomId)!;

      const newPrice = calculatePriceBasedOnUomChange(
        newUom,
        oldUom,
        row.price || 0,
        []
      );

      const multiCurrencyItemPrice = calculateMulticurrencyPrice(
        newPrice || 0,
        salesOrder.exchangeRate || 1
      );

      const totals = calculateTotals(
        newPrice || 0,
        row.quantity || 0,
        row.discount,
        row.taxRate || 0,
        soSettings.priceIncludesTax || false
      );

      const multiCurrencyTotal = toMulticurrencyCalculate(
        totals.subTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyTaxTotal = toMulticurrencyCalculate(
        totals.taxTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyDiscountTotal = toMulticurrencyCalculate(
        totals.discountTotal,
        salesOrder.exchangeRate || 1
      );

      setData(
        {
          ...row,
          uomId,
          price: newPrice,
          multiCurrencyItemPrice,
          ...totals,
          multiCurrencyTotal,
          multiCurrencyTaxTotal,
          multiCurrencyDiscountTotal,
        },
        index
      );
    },
    [
      uoms,
      row,
      setData,
      index,
      salesOrder.exchangeRate,
      soSettings.priceIncludesTax,
    ]
  );

  const handleTaxClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      setTaxEl(event.currentTarget);
    },
    []
  );

  const handleTaxPopCliked = useCallback(
    (tax: TaxRate | null, taxable: boolean) => () => {
      const taxRate = tax ? tax.percentage : null;
      const price = row.price || 0;
      const newTaxRate = tax ? tax.percentage! : 0;

      const totals = calculateTotals(
        price,
        row.quantity || 0,
        row.discount,
        newTaxRate,
        soSettings.priceIncludesTax || false
      );

      const multiCurrencyTotal = toMulticurrencyCalculate(
        totals.subTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyTaxTotal = toMulticurrencyCalculate(
        totals.taxTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyDiscountTotal = toMulticurrencyCalculate(
        totals.discountTotal,
        salesOrder.exchangeRate || 1
      );

      setData(
        {
          ...row,
          taxId: tax ? tax.id : null,
          taxRate,
          taxable,
          ...totals,
          multiCurrencyTotal,
          multiCurrencyTaxTotal,
          multiCurrencyDiscountTotal,
        },
        index
      );
      setTaxEl(null);
    },
    [index, row, setData, salesOrder.exchangeRate, soSettings.priceIncludesTax]
  );

  const handleTaxPopClose = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();

      setTaxEl(null);
    },
    []
  );

  const handleDiscountPopupApplyClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      const innerDiscount = {
        value: discountPopupValue,
        type:
          discountPopupIndex === 0
            ? DiscountTypes.Percent
            : DiscountTypes.FlatRate,
      };

      const totals = calculateTotals(
        row.price || 0,
        row.quantity || 0,
        innerDiscount,
        row.taxRate || 0,
        soSettings.priceIncludesTax || false
      );

      const multiCurrencyTotal = toMulticurrencyCalculate(
        totals.subTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyTaxTotal = toMulticurrencyCalculate(
        totals.taxTotal,
        salesOrder.exchangeRate || 1
      );

      const multiCurrencyDiscountTotal = toMulticurrencyCalculate(
        totals.discountTotal,
        salesOrder.exchangeRate || 1
      );

      setData(
        {
          ...row,
          discount: innerDiscount,
          ...totals,
          multiCurrencyTotal,
          multiCurrencyTaxTotal,
          multiCurrencyDiscountTotal,
        },
        index
      );
      setDiscountEl(null);
    },
    [
      discountPopupValue,
      index,
      row,
      setData,
      discountPopupIndex,
      salesOrder.exchangeRate,
      soSettings.priceIncludesTax,
    ]
  );

  const handleDiscountPopupChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDiscountPopupValue(parseFloat(e.target.value));
    },
    []
  );

  const handleDiscountAmountTypeChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();

      const taxTypeValue = e.target.innerText === '%' ? 0 : 1;
      setDiscountPopupIndex(taxTypeValue);
    },
    []
  );

  const handleDiscountClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      setDiscountPopupIndex(
        row.discount.type === DiscountTypes.Percent ? 0 : 1
      );
      setDiscountPopupValue(row.discount.value);
      setDiscountEl(event.currentTarget);
    },
    [row.discount]
  );

  const handleDiscountPopClose = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();

      setDiscountEl(null);
    },
    []
  );

  const RenderTaxTooltip = useCallback(
    (props: { children: ReactElement }) => {
      const { children } = props;

      return taxJarToken ? (
        <TaxJarTooltip values={row || null} disableHoverListener={!taxJarToken}>
          {children}
        </TaxJarTooltip>
      ) : (
        <TaxTooltip tax={selectedTaxRate} taxable={row.taxable}>
          {children}
        </TaxTooltip>
      );
    },
    [row, taxJarToken, selectedTaxRate]
  );

  return (
    <>
      <TableCell className={classes.cell}>
        <Grid container alignItems="center">
          <Grid item>
            <Box
              marginRight={2}
              width={40}
              height={40}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {rowIcon}
            </Box>
          </Grid>
          <Grid item xs>
            <TextField
              className={'redesign'}
              variant={'standard'}
              type="text"
              label={
                row.salesOrderItemType === SalesOrderItemTypes.MiscSale
                  ? 'Miscellaneous Sale'
                  : 'Miscellaneous Return'
              }
              permissions={editPermission}
              placeholder="Enter name"
              name="name"
              value={row.name}
              fullWidth
              disabled={fieldsDisabled}
              onChange={handleTextInputChanged}
              onClick={stopInputPropagation}
              autoComplete="disabled"
              error={!!errors.name}
              dataQa="item-miscellaneous"
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell className={classes.cell}>
        <Grid container>
          <Grid item xs>
            <CurrencyField
              placeholder="Price"
              name="price"
              value={roundToDecimals(row.price || 0, 5)}
              onChange={handlePriceInputChange}
              onClick={stopInputPropagation}
              fullWidth
              error={!!errors.price}
              disabled={fieldsDisabled}
              permissions={[PermissionType.SalesOrderOverridePrice]}
              dataQa="misc-row-price"
              allowNegative
              decimalPlaces={5}
            />
          </Grid>
          <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
            <Grid item>
              <Box marginLeft="13px" marginRight="-10px" marginTop={1}>
                x
              </Box>
            </Grid>
          </MultiCurrencyWrapper>
        </Grid>
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell className={classes.cell}>
          <Grid container>
            <Grid item xs>
              <CurrencyField
                placeholder="Price"
                name="price"
                value={roundToDecimals(row.multiCurrencyItemPrice || 0, 5)}
                onChange={handleMultiCurrencyPriceInputChange}
                onClick={stopInputPropagation}
                fullWidth
                allowNegative
                decimalPlaces={5}
                debounceDelay={500}
                disabled={fieldsDisabled}
                currencyCode={activeMulticurrencyCode}
                permissions={[PermissionType.SalesOrderOverridePrice]}
                dataQa="misc-row-price"
              />
            </Grid>
            <Grid item>
              <Box marginLeft="13px" marginRight="-10px" marginTop={1}>
                x
              </Box>
            </Grid>
          </Grid>
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell className={classes.cell}>
        <TextFieldQuantity
          placeholder="Quantity"
          name="quantity"
          value={row.quantity}
          selectedUomId={row.uomId || EACH_UOM_ID}
          onTextChange={handleAmountInputChange}
          onClick={stopInputPropagation}
          onMenuChange={
            salesOrder.status !== SalesOrderStatus.Estimate
              ? undefined
              : handleAmountMenuChange
          }
          fullWidth
          uoms={uoms}
          isDecimal
          disabled={fieldsDisabled}
          permissions={editPermission}
          error={!!errors.quantity}
          dataQa="misc-row-quantity"
        />
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        <Link
          variant="body2"
          color="textSecondary"
          onClick={canClikLink ? handleDiscountClicked : _.noop}
          underline="always"
          data-qa="misc-row-discount"
          className="aligned-link"
        >
          {row.discount.type === DiscountTypes.FlatRate ? currencySymbol : ''}
          {discount}
          {row.discount.type === DiscountTypes.Percent ? '%' : ''}
        </Link>
        <Popover
          anchorOrigin={discountAnchorOrigin}
          transformOrigin={discountTransformOrigin}
          anchorEl={discountEl}
          open={isDiscountPopVisible}
          onClose={handleDiscountPopClose}
        >
          <Box p={1} display="flex" alignItems="center">
            <MultiFormatInput
              className="redesign"
              variant="standard"
              type="text"
              label="Amount"
              placeholder="Enter amount"
              value={discountPopupValue}
              onFormatChange={handleDiscountAmountTypeChanged}
              onChange={handleDiscountPopupChange}
              onClick={stopInputPropagation}
              options={formatTypes(currencySymbol)}
              activeIndex={discountPopupIndex}
              dataQa="misc-row-discount-quantity"
            />
            <Box ml={1}>
              <FBOButton
                variant="primary"
                color="positive"
                size="small"
                onClick={handleDiscountPopupApplyClick}
                data-qa="misc-row-discount-quantity-add"
              >
                Add
              </FBOButton>
            </Box>
          </Box>
        </Popover>
      </TableCell>
      <TableCell className={classes.cell} align="right">
        <RenderTaxTooltip>
          <Link
            variant="body2"
            color="textPrimary"
            onClick={!taxJarToken && canClikLink ? handleTaxClicked : _.noop}
            underline="always"
            data-qa="item-row-tax"
            className="aligned-link"
          >
            {resolveDisplayTax}
          </Link>
        </RenderTaxTooltip>
        <Popover
          anchorOrigin={totalAnchorOrigin}
          transformOrigin={totalTransformOrigin}
          anchorEl={taxEl}
          open={isTaxPopoverVisible}
          onClose={handleTaxPopClose}
        >
          <Box width={150}>
            <Grid container>
              <PopoverItem
                label="None"
                value="0%"
                onClick={handleTaxPopCliked(null, true)}
              />
              {taxRates.map((tax, i) => {
                return (
                  <PopoverItem
                    key={i}
                    label={tax.name!}
                    value={renderTaxRate(tax, currencySymbol)}
                    onClick={handleTaxPopCliked(tax, true)}
                  />
                );
              })}
            </Grid>
          </Box>
        </Popover>
      </TableCell>
      <TableCell
        align="center"
        className={classes.cell}
        onClick={stopInputPropagation}
      >
        <StatusLabel
          onClick={stopInputPropagation}
          status={row.status}
          label={`${row.quantityFulfilled ? row.quantityFulfilled : 0}/${
            row.quantity || 0
          }`}
        />
      </TableCell>
      <TableCell
        align="right"
        className={!soSettings.priceIncludesTax ? classes.cell : ''}
        onClick={stopInputPropagation}
      >
        <Typography variant="body2" data-qa="misc-row-sub-total">
          {currencyFormatter(row.subTotal)}
        </Typography>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          data-qa="misc-row-tax-total"
        >
          {currencyFormatter(row.taxTotal)}
        </Typography>
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell
          align="right"
          className={!soSettings.priceIncludesTax ? classes.cell : ''}
          onClick={stopInputPropagation}
        >
          <Typography
            variant="body2"
            color="textPrimary"
            data-qa="sale-item-sub-total"
          >
            {formatedMulticurrencySubTotal}
          </Typography>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            data-qa="sale-item-tax-total"
          >
            {formatedMulticurrencyTaxTotal}
          </Typography>
        </TableCell>
      </MultiCurrencyWrapper>
      <MenuTableCell {...props} />
    </>
  );
};

export default memo(MiscRow);
