import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import {
  Account,
  AccountType,
  fetchAccounts,
  updateAccounts,
} from 'services/accounting';
import { replaceValueInCollection } from 'helpers';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import { fetchQbdAccountsAPI, QbdAccount } from 'services/integrations/qbd';
import { Modal } from 'ui/components/Modal/Modal';

import { MappingModalProps } from './types';
import QbdAccountsAutocomplete from 'ui/components/Autocomplete/QbdAccountsAutocomplete/QbdAccountsAutocomplete';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { AccountTypeHelper } from 'ui/modules/accounting/types';
import { logErrorCtx } from 'app/logging';

const MappingModal: React.FC<MappingModalProps> = (props) => {
  const { open, onClose } = props;

  const [accounts, setAccounts] = useState<Account[]>([]);
  const [qbdAccounts, setQbdAccounts] = useState<QbdAccount[]>([]);
  const [contentLoading, setContentLoading] = useState(false);
  const [accountTypeHelper, setAccountTypeHelper] = useState<
    AccountTypeHelper[]
  >([]);

  const fetchFBOAccounts = useCallback(async () => {
    setContentLoading(true);

    try {
      const resFreshAccounts = await fetchAccounts();

      // We need to convert type Holding to Other Current Liability on fetch
      // so we can map it correctly.
      // That can not be changed on backed due some reasons
      const index = resFreshAccounts.findIndex(
        (el) => el.accountType === AccountType.Holding
      );

      const newFreshAccounts = replaceValueInCollection(
        resFreshAccounts,
        {
          ...resFreshAccounts[index],
          accountType: AccountType.OtherCurrentLiability,
        },
        index
      )!;
      setAccounts(newFreshAccounts);

      const p: AccountTypeHelper[] = [];
      for (const acc of newFreshAccounts) {
        p.push({ id: acc.id, accountType: acc.accountType, name: acc.name });
      }
      setAccountTypeHelper(p);
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Fbo Account information not received', {
        error,
        stackTrace: error.stack,
        title: 'Fbo Account not accessible',
        description: 'Fbo Account information failed to pull from endpoint',
        component: 'QbdAccountingSettingsPage -> MappingModal',
      });
    }

    setContentLoading(false);
  }, []);

  const fetchQbdAccounts = useCallback(async () => {
    setContentLoading(true);

    const listId = accounts
      .filter((a) => a.accountMappingId)
      .map((a) => a.accountMappingId!);

    try {
      const resQbdAccounts = await fetchQbdAccountsAPI({}, null, listId);
      setQbdAccounts(resQbdAccounts.data);
    } catch (e) {
      const error = e as Error;
      logErrorCtx('QBD Account not Retrieved!', {
        error: error,
        stackTrace: error.stack,
        title: 'QBD Account Retrieval Error',
        description: `Failed to properly retrieve a QBD Account for list Id: ${listId}`,
        component: 'QbdAccountingSettingsPage -> MappingModal',
      });
    }

    setContentLoading(false);
  }, [accounts]);

  useEffect(() => {
    if (!open) {
      return;
    }

    fetchFBOAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (!accounts) {
      return;
    }

    fetchQbdAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);

  const handleAccountChange = useCallback(
    (index: number) => (value: QbdAccount | null) => {
      setAccounts(
        (oldState) =>
          replaceValueInCollection(
            oldState,
            {
              ...oldState[index],
              accountMappingId: value ? value.listId : '',
            },
            index
          )!
      );
    },
    [setAccounts]
  );

  const saveAccounts = useCallback(async () => {
    setContentLoading(true);

    try {
      await updateAccounts(accounts);
    } catch {
      // continue regardless of error
    }

    setContentLoading(false);

    onClose();
  }, [accounts, onClose]);

  const ModalActions = () => {
    return (
      <Box display="flex" justifyContent="flex-end" width="100%">
        <Box mr={2}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </Box>
        <NetworkSpinnerWrapper isLoading={contentLoading} size={24}>
          <Button variant="contained" color="primary" onClick={saveAccounts}>
            Save
          </Button>
        </NetworkSpinnerWrapper>
      </Box>
    );
  };

  const selectedAccountType = useCallback(
    (id: number) => accountTypeHelper.find((s) => s.id === id) || null,
    [accountTypeHelper]
  );

  const handleAccountTypeChange = useCallback(
    (index: number) => (e: any, value: AccountTypeHelper | null) => {
      setAccountTypeHelper(
        (oldState) =>
          replaceValueInCollection(
            oldState,
            {
              ...oldState[index],
              accountType: value ? value.accountType : null,
              name: value ? value.name : null,
            },
            index
          )!
      );
    },
    [setAccountTypeHelper]
  );

  return (
    <Modal
      open={open}
      title="Account Mapping"
      onCancelClicked={onClose}
      isLoading={contentLoading}
      isLoadingContent={contentLoading}
      withoutDefaultPadding
      customHeight={700}
      maxWidth="md"
      ModalActionsComponent={ModalActions}
      footerDivider="shadow"
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>FBO Accounts</TableCell>
            <TableCell>Account Type Filter</TableCell>
            <TableCell></TableCell>
            <TableCell>QBD Accounts</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map((acc, index) => {
            const selectedAccount =
              qbdAccounts.find(
                (qbdAcc) => qbdAcc.listId === acc.accountMappingId
              ) || null;
            return (
              <TableRow key={index}>
                <TableCell style={{ width: '200px' }}>{acc.name}</TableCell>
                <TableCell>
                  {' '}
                  <Autocomplete
                    options={accountTypeHelper}
                    value={selectedAccountType(acc.id!)}
                    onChange={handleAccountTypeChange(index)}
                    getOptionLabel={(a: AccountTypeHelper) => a.name || ''}
                  />
                </TableCell>
                <TableCell style={{ width: '30px' }}>
                  <NavigateNextIcon />
                </TableCell>
                <TableCell>
                  <QbdAccountsAutocomplete
                    accountType={
                      accountTypeHelper[index]
                        ? accountTypeHelper[index].accountType
                        : acc.accountType
                    }
                    value={selectedAccount}
                    onChange={handleAccountChange(index)}
                    placeholder="Select"
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Modal>
  );
};

export default memo(MappingModal);
