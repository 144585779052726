import moment from 'moment';
import _ from 'lodash';

import {
  ApiConfig,
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
  showNotification,
} from 'services/api';
import { Pagination } from 'services/search';

import {
  transformbackgroundItem,
  transformCSV,
  transformDownloadHistory,
  transformUploadHistory,
} from './tranformations';

import {
  BackgroundAction,
  BackgroundItem,
  BackgroundStatus,
  BackgroundType,
  DownloadHistoryRow,
  StatusObj,
} from './types';
import { api, csvApi } from 'services/api/config';
import {
  getLocalStatuses,
  removeLocalStatus,
  setLocalStatus,
} from './services';
import { showDownloadNotification } from 'services/api/notifications';
import { paginatedCSVApiCall } from 'services/api/createApiCall';

const errorMessage = 'An error has occurred.';

export const fetchAllCSVStatuses = async (): Promise<BackgroundItem[]> => {
  const statuses = await createApiCall(getRequestStatus(csvApi.hostname), {
    getMessage: {
      error: (error: any) =>
        _.get(error, 'response.data.message', errorMessage),
    },
  })();
  return statuses.data.data.map(transformCSV);
};

const processCSV = (
  msg: string,
  data: BackgroundItem,
  variant: string = '',
  download: boolean = false
): BackgroundItem => {
  if (download) {
    showDownloadNotification(msg);
  } else {
    showNotification(msg, { variant: variant });
  }
  removeLocalStatus(data.id);
  return transformCSV(data);
};

export const fetchCSVStatuses = async (): Promise<any[]> => {
  const localStatuses = getLocalStatuses();
  const processed: StatusObj[] = [];
  if (localStatuses !== null) {
    Object.entries(JSON.parse(localStatuses)).forEach(([, value]) => {
      if ((value as StatusObj).status === BackgroundStatus.Pending) {
        processed.push(value as StatusObj);
      }
    });
  }
  const promises = processed.map(
    async (statusObj: StatusObj): Promise<BackgroundItem | null> => {
      const response = await createApiCall(
        getRequestStatus(csvApi.hostname, statusObj.id),
        {
          getMessage: {
            error: (error: any) =>
              _.get(error, 'response.data.message', errorMessage),
          },
        }
      )();

      if (response.data.data.status === BackgroundStatus.Completed) {
        const successMessage = 'Your file has been successfully uploaded.';
        return processCSV(successMessage, response.data.data, 'success');
      }
      if (response.data.data.status === BackgroundStatus.PartialSuccess) {
        return processCSV(
          response.data.data.message,
          response.data.data,
          'warning'
        );
      }
      if (response.data.data.status === BackgroundStatus.Failed) {
        const errMessage =
          response.data?.data?.message &&
          response.data?.data?.message.length < 128
            ? response.data?.data?.message
            : errorMessage;
        if (response.data.data.errorLogUrl) {
          return processCSV(errMessage, response.data.data, 'error');
        }
        return processCSV(errMessage, response.data.data, 'error');
      }
      return transformCSV(response.data.data);
    }
  );
  return Promise.all(promises);
};

export const fetchCSVStatus = async (id: string): Promise<BackgroundItem> => {
  const response = await createApiCall(getRequestStatus(csvApi.hostname, id), {
    getMessage: {
      error: (error: any) =>
        _.get(error, 'response.data.message', errorMessage),
    },
  })();
  return response.data.data;
};

export const fetchBackgroundStatus = async (
  csvEnabled: boolean = false
): Promise<BackgroundItem[]> => {
  if (csvEnabled) {
    return fetchCSVStatuses();
  } else {
    const response = await createApiCall(getRequestStatus(), {
      getMessage: {
        error: (error: any) =>
          _.get(error, 'response.data.message', 'Please contact support'),
      },
    })();
    return response.data.map(transformbackgroundItem);
  }
};

export const cancelDownload = async (
  fileName: string,
  action: BackgroundAction
): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/sqs/cancel?fileName=${fileName}&action=${action.toLowerCase()}`,
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) =>
          _.get(error, 'response.data.message', 'Please contact support'),
      },
    }
  )();
};

export const downloadFile = async (fileName: string): Promise<void> => {
  const response = await createApiCall(
    {
      path: `/v1/sqs/download?fileName=${fileName}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) =>
          _.get(error, 'response.data.message', 'Please contact support'),
      },
    }
  )();
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  const currentDatetime = moment().format('YYYY-MM-DD_h:mm:ss');
  link.href = url;
  link.setAttribute('download', `${fileName}-${currentDatetime}.csv`);
  document.body.appendChild(link);
  link.click();
};

export const fetchUploadHistory = async (
  pagination: Pagination
): Promise<DataWithPagination<DownloadHistoryRow>> => {
  const path = `/v2/csv/statuses`;
  const res = await paginatedCSVApiCall(
    path,
    pagination,
    transformUploadHistory
  );
  return res;
};

export const fetchDownloadHistory = async (
  pagination: Pagination
): Promise<DataWithPagination<DownloadHistoryRow>> => {
  const path = `/v1/sqs/history`;
  const res = await paginatedApiCall(
    path,
    pagination,
    transformDownloadHistory
  );
  return res;
};

export const startBackgroundExport = async (
  backgroundType: BackgroundType
): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/sqs/export?type=${backgroundType}`,
      method: 'POST',
    },
    {
      getMessage: {
        error: (error: any) =>
          _.get(error, 'response.data.message', 'Please contact support'),
      },
    }
  )();
};

const getRequestStatus = (
  hostname: string = api.hostname,
  id: string = ''
): ApiConfig => {
  const requestObject: ApiConfig =
    hostname === csvApi.hostname
      ? {
          hostname: csvApi.hostname,
          path: id !== '' ? `/v2/csv/statuses/${id}` : '/v2/csv/statuses',
          method: 'GET',
        }
      : {
          path: `/v1/sqs/status`,
          method: 'GET',
        };
  return requestObject;
};

const getRequestObject = (
  file: File,
  backgroundType: BackgroundType,
  csvEnabled: boolean = false
): ApiConfig => {
  const formData = new FormData();
  formData.append('file', file);
  const requestObject: ApiConfig =
    file.type === 'text/csv' && csvEnabled
      ? {
          hostname: csvApi.hostname,
          path: `/v2/csv/import?type=${backgroundType}`,
          method: 'POST',
          body: formData,
        }
      : {
          path: `/v1/sqs/import?type=${backgroundType}`,
          method: 'POST',
          body: formData,
        };
  return requestObject;
};

export const startBackgroundImport = async (
  backgroundType: BackgroundType,
  file: File,
  csvEnabled: boolean = false
): Promise<void> => {
  const requestObject = getRequestObject(file, backgroundType, csvEnabled);
  const response = await createApiCall(requestObject, {
    getMessage: {
      error: (error: any) =>
        _.get(error, 'response.data.message', errorMessage),
    },
  })();
  if (response.data.service === 'CSV') {
    setLocalStatus(response.data.data);
  }
};

export const clearCompletedDownloads = async (
  action: BackgroundAction,
  backgroundType?: BackgroundType | null
) => {
  let path = `/v1/sqs/status?action=${action}`;
  if (backgroundType) {
    path += `&type=${backgroundType}`;
  }
  await createApiCall(
    {
      path,
      method: 'DELETE',
    },
    {
      getMessage: {
        error: (error: any) =>
          _.get(error, 'response.data.message', 'Please contact support'),
      },
    }
  )();
};
