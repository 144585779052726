import { makeStyles } from '@mui/styles';

import { colorPalette } from 'ui/theme';

export const useQbdWizardStyle = makeStyles(() => ({
  activeIcon: {
    fill: `${colorPalette.fishbowlCorporateBlue} !important`,
  },
  downloadIcon: {
    fontSize: 150,
  },
}));
