import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';

import { NavigationDescriptor } from './types';

export const cardNavigationDescriptor: NavigationDescriptor[] = [
  {
    id: 'waitingToExportId',
    icon: GetAppOutlinedIcon,
    title: 'Waiting To Export',
    separate: false,
  },
  {
    id: 'previousExportId',
    icon: ListOutlinedIcon,
    title: 'Previous Export',
    separate: false,
  },
  {
    id: 'historyId',
    icon: HistoryOutlinedIcon,
    title: 'History',
    separate: false,
  },
  {
    id: 'accountMappingId',
    icon: RepeatOutlinedIcon,
    title: 'Account Mapping',
    separate: true,
  },
];
