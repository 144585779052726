import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable/types';

export const ALERTS_COLUMNS: Column[] = [
  {
    title: 'Name',
    width: 200,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Email',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Email',
    width: 64,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'In Stock',
    width: 64,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    width: 64,
    type: ColumnTypes.renderByCustomRow,
  },
];
