import { createApiCall } from 'services/api';
import { integrationsApi } from 'services/api/config';
import { EcommerceMappings } from '../ecommerce';

import { RedirectStoreTypes } from './types';

export const fetchEcommerceMappings = async (
  id: number
): Promise<EcommerceMappings> => {
  const response = await integrationsApi.get(
    `/configuration/channels/${id}/mapping`
  );

  return response.data;
};

export const fetchIntegrationsRedirect = async (
  storeType: RedirectStoreTypes,
  params: string
): Promise<string> => {
  const res = await createApiCall({
    path: `/integrations/${storeType}/redirect${params}`,
    method: 'GET',
  })();

  return res.data;
};
