import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { XeroLogo } from 'ui/theme/images';
import {
  fetchXeroAuthUrl,
  getXeroAccountIsConnected,
  xeroDisconnect,
} from 'services/integrations/xero';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { getQuickbooksDesktopAccountIsConnected } from 'services/integrations/qbd/redux';
import { getQuickbooksAccountIsConnected } from 'services/integrations/quickbooks';
import { showNotification } from 'services/api';

import { XeroCardProps } from './types';
import { Card } from '..';
import { CardButton } from '../IntegrationButtons';
import XeroDialogActions from './components/XeroDialogActions/XeroDialogActions';

const XeroCard: React.FC<XeroCardProps> = () => {
  const dispatch = useDispatch();

  const xeroConnected = useSelector(getXeroAccountIsConnected);
  const qbdConnected = useSelector(getQuickbooksDesktopAccountIsConnected);
  const qboConnected = useSelector(getQuickbooksAccountIsConnected);

  const [disconnectModalVisible, setDisconnectModalVisible] = useState(false);
  const [connectModalVisible, setConnectModalVisible] = useState(false);
  const [disconnectMappingModalVisible, setDisconnectMappingModalVisible] =
    useState(false);

  const openDisconnectModal = () => {
    setDisconnectModalVisible(true);
  };

  const closeDisconnectModal = () => {
    setDisconnectModalVisible(false);
  };

  const openConnectModal = () => {
    if (qbdConnected || qboConnected) {
      showNotification(
        'You can only be connected to 1 Accounting integration.',
        {
          variant: 'error',
        }
      );
      return;
    }

    setConnectModalVisible(true);
  };

  const closeConnectModal = () => {
    setConnectModalVisible(false);
  };

  const xeroConnectClicked = useCallback(async () => {
    try {
      const url = await fetchXeroAuthUrl();
      window.open(url, '_self');
    } catch {
      // Ignore error
    }
    setConnectModalVisible(false);
  }, []);

  const xeroDisconnectClicked = useCallback(() => {
    setDisconnectMappingModalVisible(true);
    setDisconnectModalVisible(false);
  }, [setDisconnectMappingModalVisible]);

  const handleConfirmClicked = useCallback(async () => {
    const clearMappings = true;
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(xeroDisconnect(clearMappings));
    setDisconnectMappingModalVisible(false);
  }, [dispatch]);

  const handleCancelClicked = useCallback(async () => {
    // @ts-ignore
    dispatch(xeroDisconnect());
    setDisconnectMappingModalVisible(false);
  }, [dispatch]);

  return (
    <>
      <Card
        imageUrl={XeroLogo}
        cardId="xero"
        isLoading={false}
        description="Sync your Fishbowl orders and inventory adjustments to your Xero account. No more double entry."
        title="Xero"
      >
        {xeroConnected ? (
          <CardButton
            onClick={openDisconnectModal}
            variant="secondary"
            color="negative"
            label="Disconnect"
            dataQa="xero-disconnect"
          />
        ) : (
          <CardButton
            onClick={openConnectModal}
            variant="primary"
            color="positive"
            label="Connect"
            dataQa="xero-connect"
          />
        )}
      </Card>
      <ConfirmationModal
        open={connectModalVisible}
        title={`Connect to Xero`}
        onConfirmClicked={xeroConnectClicked}
        onCancelClicked={closeConnectModal}
        body={`Do you want to connect to Xero?`}
        isLoading={false}
        DialogActionsComponent={() => (
          <XeroDialogActions
            xeroConnectClicked={xeroConnectClicked}
            closeConnectModal={closeConnectModal}
          />
        )}
      />

      <ConfirmationModal
        open={disconnectModalVisible}
        title={`Disconnect Xero integration?`}
        onConfirmClicked={xeroDisconnectClicked}
        onCancelClicked={closeDisconnectModal}
        body={`You are about to disconnect Xero integration from your account. Do you want to continue?`}
        confirmButtonRed
        confirmLabel="Yes"
        isLoading={false}
      />
      <ConfirmationModal
        open={disconnectMappingModalVisible}
        title={`Disconnect Xero integration?`}
        onConfirmClicked={handleConfirmClicked}
        onCancelClicked={handleCancelClicked}
        body={`Would you like to clear the mapping between Fishbowl Online and Xero?
        You should only clear mappings if you're going to reconnect to a different version or a different account of Xero?`}
        confirmButtonRed
        confirmLabel="Yes"
        cancelLabel="No"
      />
    </>
  );
};

export default memo(XeroCard);
