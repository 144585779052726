import React from 'react';
import { Box, PopoverOrigin, Typography } from '@mui/material';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import { IconNames, colorPalette } from 'ui/theme';

import Icon from 'ui/components/Icon/Icon';

export const fboAvailableItemsColumns = (itemAmount: number): Column[] => [
  {
    title: `${itemAmount} Items`,
    type: ColumnTypes.custom,
    render: (row) => {
      return (
        <Box
          display="flex"
          flexDirection="column"
          maxWidth={220}
          overflow="hidden"
          textOverflow="ellipsis"
          height={37}
          justifyContent="center"
        >
          <Typography variant="body1" color="textPrimary" noWrap>
            {row.name}
          </Typography>
          {row.description && (
            <Typography variant="body2" color="textSecondary" noWrap>
              {row.description}
            </Typography>
          )}
        </Box>
      );
    },
  },
  {
    title: '',
    type: ColumnTypes.custom,
    width: 24,
    render: () => {
      return (
        <Box
          display="flex"
          alignItems="center"
          width={24}
          height="100%"
          color={colorPalette.fishbowlCorporateGray3}
        >
          <Icon
            name={IconNames.FBOAddCircle}
            color={colorPalette.redesign.contentPrimary}
            style={{ width: '24px', padding: '2px' }}
          />
        </Box>
      );
    },
  },
];

export const selectedItemsColumns: Column[] = [
  {
    title: 'Item',
    width: 240,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Safety Stock',
    width: 150,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Lead Time',
    width: 150,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Order Interval',
    width: 150,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Notify At',
    width: 150,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: '',
    type: ColumnTypes.renderByCustomRow,
    width: 60,
  },
  {
    title: '',
    type: ColumnTypes.renderByCustomRow,
    width: 60,
  },
];

export const totalAnchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'left',
};

export const totalTransformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'left',
};
