import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Divider, Switch, Typography } from '@mui/material';

import { fetchLocations } from 'services/locations';

import { SettingsFormProps } from '../../types';
import { useEcommerceCardStyle } from '../../../../styled';
import useEcommerceSettings from '../../../../useEcommerceSettings';
import { ChannelType } from 'services/integrations/ecommerce';
import { MappingLocations } from '../MappingLocations';

const FulfillmentSettingsForm: React.FC<SettingsFormProps> = (props) => {
  const classes = useEcommerceCardStyle();
  const dispatch = useDispatch();

  const { index } = props;

  const [setting, setEcommerceSettings, errors, , , location] =
    useEcommerceSettings(index);

  const [fulfillmentSettings, setFulfillmentSettings] = useState(setting);

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchLocations());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fulfillmentSettings.id) {
      setEcommerceSettings(fulfillmentSettings);
    }
  }, [fulfillmentSettings, setEcommerceSettings, index]);

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFulfillmentSettings((old) => ({
        ...old,
        fulfillment: {
          ...old.fulfillment,
          [event.target.name]: checked,
        },
      }));
    },
    [setFulfillmentSettings]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      height="100%"
      overflow="auto"
    >
      <Box className={classes.modalRow}>
        <Typography variant="body1" color="textPrimary">
          Update Fulfillment
        </Typography>

        <Switch
          checked={fulfillmentSettings.fulfillment.update}
          onChange={handleCheckboxChange}
          name="update"
          color="primary"
        />
      </Box>

      <Divider />

      {fulfillmentSettings.fulfillment.update
        ? (setting.channel.channel === ChannelType.Shopify && (
            <MappingLocations
              identifier="fullfilment"
              classes={classes}
              mappingLocations={fulfillmentSettings.fulfillment.mapping}
              errors={errors}
              setEcommerceSettings={setFulfillmentSettings}
              location={location}
            />
          )) ||
          (setting.channel.channel === ChannelType.ShipStation && (
            <Box className={classes.modalRow}>
              <Typography variant="body1" color="textPrimary">
                Update Cost
              </Typography>

              <Switch
                checked={fulfillmentSettings.fulfillment.updateCost}
                onChange={handleCheckboxChange}
                name="updateCost"
                color="primary"
              />
            </Box>
          ))
        : null}
    </Box>
  );
};

export default memo(FulfillmentSettingsForm);
