import React, { useState, useEffect } from 'react';
import {
  Box,
  InputAdornment,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';

import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { EACH_UOM_ID, Uom } from 'services/uoms';
import { replaceValueInCollection } from 'helpers';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { TextField } from 'ui/components/TextField/TextField';

import { ConfirmRopValues } from '../../types';
import {
  calculateAlert,
  calculateConsumptionRate,
  calculateMax,
  calculateMin,
} from './helpers';
import { useConfirmItemRowTableTyle } from './styled';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

export const CustomRow: React.FC<RenderCustomRowProps<ConfirmRopValues>> = (
  props
) => {
  const { row, setData, meta, onAction, index } = props;

  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [activeItemIndex, setActiveItemIndex] = useState<number | null>(null);

  const classes = useConfirmItemRowTableTyle();

  const confirmData: ConfirmRopValues[] = _.get(meta, 'confirmData', null);
  const setConfirmData = _.get(meta, 'setConfirmData');

  useEffect(() => {
    if (tooltipOpen) {
      setTimeout(() => {
        setTooltipOpen(false);
      }, 3000);
    }
  }, [tooltipOpen]);

  const handleOnAction = (index: number, itemIndex: number) => {
    if (onAction) {
      onAction({ index, itemIndex });
    }
  };

  const handleTextChange =
    (name: string, itemIndex: number) => (value: number | null) => {
      const item = row.confirmItems[itemIndex];

      if (!item) {
        return;
      }
      setData(
        {
          ...row,
          confirmItems: replaceValueInCollection(
            row.confirmItems,
            { ...item, [name]: value || 0 },
            itemIndex
          )!,
        },
        index
      );
    };

  const handleConsumptionRateChange =
    (itemIndex: number) => (value: number | null) => {
      const confirmItem = row.confirmItems[itemIndex];
      const consumptionRate = value || 0;

      if (!confirmItem) {
        return;
      }

      setData(
        {
          ...row,
          confirmItems: replaceValueInCollection(
            row.confirmItems,
            {
              ...confirmItem,
              consumptionRate,
              calculatedMin: calculateMin(confirmItem, consumptionRate),
              calculatedMax: calculateMax(confirmItem, consumptionRate),
              currentNotifyAt: calculateAlert(confirmItem, consumptionRate),
            },
            itemIndex
          )!,
        },
        index
      );
    };

  const handleForecastChange =
    (itemIndex: number) =>
    (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const confirmItem = row.confirmItems[itemIndex];
      const forecast = ev.target.value ? parseInt(ev.target.value) : 0;

      if (!confirmItem) {
        return;
      }

      const newConsumptionRate = calculateConsumptionRate(
        forecast,
        confirmItem.calculatedConsumptionRate || 0
      );

      setData(
        {
          ...row,
          confirmItems: replaceValueInCollection(
            row.confirmItems,
            {
              ...confirmItem,
              forecast: forecast,
              consumptionRate: newConsumptionRate,
              calculatedMin: calculateMin(confirmItem, newConsumptionRate),
              calculatedMax: calculateMax(confirmItem, newConsumptionRate),
              currentNotifyAt: calculateAlert(confirmItem, newConsumptionRate),
            },
            itemIndex
          )!,
        },
        index
      );
      setTooltipOpen(true);
      setActiveItemIndex(itemIndex);
    };

  const handleApplyAllClicked = () => {
    if (activeItemIndex === null || !confirmData) {
      return;
    }
    const forecast = row.confirmItems[activeItemIndex].forecast || 0;

    const newValues = confirmData.map((cd) => {
      const newItems = cd.confirmItems.map((confirmItem) => {
        const newConsumptionRate = calculateConsumptionRate(
          forecast,
          confirmItem.calculatedConsumptionRate || 0
        );

        return {
          ...confirmItem,
          forecast,
          consumptionRate: newConsumptionRate,
          calculatedMin: calculateMin(confirmItem, newConsumptionRate),
          calculatedMax: calculateMax(confirmItem, newConsumptionRate),
          currentNotifyAt: calculateAlert(confirmItem, newConsumptionRate),
        };
      });
      return {
        ...cd,
        confirmItems: newItems,
      };
    });

    setConfirmData(newValues);
    setTooltipOpen(false);
    setActiveItemIndex(null);
  };

  return (
    <>
      {row.confirmItems.map((confirmItem, itemIndex) => {
        const itemUom: Uom | null = _.get(confirmItem, 'item.defaultUom', null);

        return (
          <TableRow key={itemIndex}>
            <TableCell className={classes.cell}>
              <ImageItemBox
                source={_.get(confirmItem, 'item.defaultImage', null)}
                name={_.get(confirmItem, 'item.name', null)}
                description={_.get(confirmItem, 'item.description', null)}
              />
            </TableCell>
            <TableCell className={classes.cell}>
              <Tooltip
                title={
                  <FBOButton
                    variant="secondary"
                    color="neutral"
                    size="small"
                    onClick={handleApplyAllClicked}
                    data-qa="custom-row-apply-to-all"
                  >
                    Apply to all
                  </FBOButton>
                }
                open={tooltipOpen && activeItemIndex === itemIndex}
                placement="top-end"
              >
                <TextField
                  value={confirmItem.forecast}
                  name="forecast"
                  onChange={handleForecastChange(itemIndex)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
            </TableCell>
            <TableCell className={classes.cell}>
              <Box display="flex" flexDirection="column" alignItems="left">
                <Box>
                  <TextFieldQuantity
                    name="consumptionRate"
                    value={confirmItem.consumptionRate}
                    uoms={itemUom ? [itemUom] : []}
                    selectedUomId={itemUom ? itemUom.id! : EACH_UOM_ID}
                    isDecimal
                    onTextChange={handleConsumptionRateChange(itemIndex)}
                  />
                </Box>
                <Box>
                  <Typography>
                    Stored: {confirmItem.storedConsumptionRate || 0}
                  </Typography>
                </Box>
              </Box>
            </TableCell>
            <TableCell className={classes.cell}>
              <TextFieldQuantity
                name="calculatedMin"
                value={confirmItem.calculatedMin}
                uoms={itemUom ? [itemUom] : []}
                selectedUomId={itemUom ? itemUom.id! : EACH_UOM_ID}
                isDecimal
                onTextChange={handleTextChange('calculatedMin', itemIndex)}
              />
            </TableCell>
            <TableCell className={classes.cell}>
              <TextFieldQuantity
                name="calculatedMax"
                value={confirmItem.calculatedMax}
                uoms={itemUom ? [itemUom] : []}
                selectedUomId={itemUom ? itemUom.id! : EACH_UOM_ID}
                isDecimal
                onTextChange={handleTextChange('calculatedMax', itemIndex)}
              />
            </TableCell>
            <TableCell className={classes.cell}>
              <TextFieldQuantity
                name="currentNotifyAt"
                value={confirmItem.currentNotifyAt}
                uoms={[]}
                selectedUomId={EACH_UOM_ID}
                isDecimal
                onTextChange={handleTextChange('currentNotifyAt', itemIndex)}
              />
            </TableCell>
            <TableCell className={classes.cell}>
              <Box pt={1}>
                <Typography>
                  {confirmItem.currentMin || 0} / {confirmItem.currentMax || 0}{' '}
                  / {confirmItem.notifyAt || 0}{' '}
                  {itemUom ? itemUom.abbreviation : ''}
                </Typography>
              </Box>
            </TableCell>
            <TableCell className={classes.cell}>
              {confirmItem.alerts.length ? (
                <Box pt={1}>
                  <FBOButton
                    variant="tertiary"
                    color="neutral"
                    size="medium"
                    icon={IconNames.Bell}
                    onClick={() => handleOnAction(index, itemIndex)}
                    data-qa="custom-row-notification-bell"
                  />
                </Box>
              ) : null}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
};
