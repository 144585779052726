import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  InputAdornment,
  Popover,
  Link,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { defaultMaximumPagination, RequestConfig } from 'helpers';
import { fetchItemsByLocationAPI } from 'services/items/api';
import { ItemByLocation } from 'services/items';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { TitleBar } from 'ui/components/TitleBar';
import { TextField } from 'ui/components/TextField/TextField';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Location, LocationType } from 'services/locations';
import { getInventorySummaries } from 'services/inventory';
import { showNotification } from 'services/api';

import {
  availableItemsColumns,
  selectedItemsColumns,
  totalAnchorOrigin,
  totalTransformOrigin,
} from './consts';
import { useItemSelecorStyle } from './styled';
import { ItemSearchTypes, ItemsStepProps } from './types';
import { PopoverItem, CustomRow, ErrorSubRow } from './components';
import { logErrorCtx } from 'app/logging';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

export const ItemsStep: React.FC<ItemsStepProps> = (props) => {
  const { wizardData, errors, setBulkItems } = props;

  const [items, setItems] = useState<ItemByLocation[]>([]);
  const [moveToLocation, setMoveToLocation] = useState<Location | null>(null);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string | null>(null);
  const [searchEl, setSearchEl] = useState<null | HTMLElement>(null);
  const [selectedSearchType, setSelectedSearchType] = useState<ItemSearchTypes>(
    ItemSearchTypes.All
  );

  const classes = useItemSelecorStyle();

  const isSearchPopoverVisible = useMemo(() => Boolean(searchEl), [searchEl]);

  useEffect(() => {
    (async () => {
      setTableLoading(true);
      const quickSearchColumns =
        selectedSearchType === ItemSearchTypes.Tags ? ['tags.name'] : ['name'];
      const requestConfig: RequestConfig = {
        pagination: defaultMaximumPagination,
        quickSearchValue: search,
        customQuickSearchColumns: quickSearchColumns,
      };

      if (wizardData.locationIds.length) {
        try {
          const resp = await fetchItemsByLocationAPI(
            requestConfig,
            wizardData.locationIds
          );
          setItems(resp.data);
        } catch (e) {
          const error = e as Error;
          logErrorCtx('Error in InventoryPage', {
            error,
            stackTrace: error.stack,
            title: 'Error moving inventory',
            description: 'fetchItemsByLocationAPI Not Fetched from Server',
            component: 'InventoryPage -> BulkWizard->ItemsStep',
          });
        }
      }

      setTableLoading(false);
    })();
  }, [search, selectedSearchType, wizardData.locationIds]);

  const addToSelectedItems = useCallback(
    async (ids: number[]) => {
      setTableLoading(true);
      try {
        let resp = await getInventorySummaries(ids, wizardData.locationIds);

        if (moveToLocation) {
          resp = resp.map((r) => ({
            ...r,
            moveToLocationId: moveToLocation.id,
          }));
        }

        setBulkItems((old) => [
          ...old,
          ...resp
            .filter((i) => !old.some((item) => item.itemId === i.itemId))
            .map((i) => ({ ...i, quantity: i.availableQty })),
        ]);
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error while selecting', {
          error,
          stackTrace: error.stack,
          title: 'Unable to get inventory summaries',
          description: 'Error while adding items',
          component: 'ItemsStep',
        });
      }
      setTableLoading(false);
    },
    [wizardData.locationIds, moveToLocation, setBulkItems]
  );

  const searchRowClicked = useCallback(
    async (id: number) => {
      const selectedItem = wizardData.items.find((i) => i.itemId === id);

      if (selectedItem) {
        showNotification('Item already added', { variant: 'warning' });
        return;
      }
      await addToSelectedItems([id]);
    },
    [wizardData.items, addToSelectedItems]
  );

  const selectedRowClicked = useCallback(
    (id: number) => {
      setBulkItems((old) => old.filter((i) => i.id !== id));
    },
    [setBulkItems]
  );

  const removeAllClicked = useCallback(() => {
    setBulkItems([]);
  }, [setBulkItems]);

  const addAllClicked = useCallback(async () => {
    await addToSelectedItems(items.map((i) => i.id!));
  }, [items, addToSelectedItems]);

  const handleSearchClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      setSearchEl(event.currentTarget);
    },
    []
  );

  const handleSearchPopClick = useCallback(
    (type: ItemSearchTypes) => () => {
      setSelectedSearchType(type);
      setSearchEl(null);
    },
    []
  );

  const handleSearchPopClose = useCallback(() => {
    setSearchEl(null);
  }, []);

  const handleMoveToLocationChange = useCallback(
    (location: Location | null) => {
      setMoveToLocation(location);
    },
    []
  );

  const renderSearchEndAdornment = () => {
    return (
      <InputAdornment position="end">
        <Link
          variant="body2"
          color="textPrimary"
          onClick={handleSearchClicked}
          href="#"
          underline="always"
        >
          {selectedSearchType}
        </Link>
        <Popover
          anchorOrigin={totalAnchorOrigin}
          transformOrigin={totalTransformOrigin}
          anchorEl={searchEl}
          open={isSearchPopoverVisible}
          onClose={handleSearchPopClose}
        >
          <Grid container>
            <PopoverItem
              label="All Fields"
              onClick={handleSearchPopClick(ItemSearchTypes.All)}
            />
            <PopoverItem
              label="Tags"
              onClick={handleSearchPopClick(ItemSearchTypes.Tags)}
            />
          </Grid>
        </Popover>
      </InputAdornment>
    );
  };

  return (
    <Box display="flex" flexGrow={1} overflow="hidden" flexDirection="column">
      <Box p={2}>
        <Typography variant="body2" color="textPrimary">
          Select move to locations then select items to move.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" overflow="auto">
        <Box
          display="flex"
          flexDirection="column"
          className={classes.tableColumn}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            p={2}
            fontWeight="fontWeightBold"
          >
            Move To Location:
            <Box width="70%">
              <LocationsAsyncAutocomplete
                value={moveToLocation}
                required
                placeholder="Select Location"
                onChange={handleMoveToLocationChange}
                companyWide={false}
                locationTypes={[
                  LocationType.Stock,
                  LocationType.Receiving,
                  LocationType.Shipping,
                ]}
              />
            </Box>
          </Box>
          <Box>
            <TitleBar noBorderRadius>
              <TextField
                placeholder="Search"
                className={classes.searchInputOuter}
                value={search}
                onChange={(ev) => setSearch(ev.target.value || null)}
                dataQa="bulk-move-search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: renderSearchEndAdornment(),
                  classes: {
                    root: classes.searchInputInner,
                    notchedOutline: classes.noBorder,
                  },
                }}
              />
              <FBOButton
                variant="tertiary"
                color="neutral"
                size="small"
                icon={IconNames.FBOAddCircle}
                data-qa="bulk-move-items-add-all"
                onClick={addAllClicked}
              >
                All
              </FBOButton>
            </TitleBar>
          </Box>
          <Box overflow="auto">
            <ItemsTable
              data={items}
              columns={availableItemsColumns(items.length)}
              isLoadingData={tableLoading}
              selectableItems={false}
              onItemClick={searchRowClicked}
              dataQa="bulk-move-items-left"
            />
          </Box>
        </Box>
        <Box
          className={classes.defaultValuesContainer}
          display="flex"
          flexDirection="column"
        >
          <Box width="100%">
            <TitleBar title="Items" noBorderRadius>
              <FBOButton
                variant="tertiary"
                color="neutral"
                size="small"
                icon={IconNames.SubCircle}
                onClick={removeAllClicked}
                data-qa="bulk-move-items-remove-all"
              >
                All
              </FBOButton>
            </TitleBar>
          </Box>
          <Box overflow="auto">
            <ItemsTable
              data={wizardData.items}
              columns={selectedItemsColumns}
              RenderCustomRow={CustomRow}
              RenderRowSubrows={ErrorSubRow}
              selectableItems={false}
              onAction={selectedRowClicked}
              meta={{
                errors,
              }}
              setData={setBulkItems}
              emptyTableText="ADD ITEM BY SELECTING FROM THE LEFT TABLE"
              dataQa="bulk-move-items-right"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ItemsStep;
