import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFPageProxy } from 'react-pdf/dist/Page';
import Measure, { ContentRect } from 'react-measure';
import { Box } from '@mui/material';
import { MeasureRef } from './types';
import { usePdfViewerStyles } from './styled';
import Spinner from './Spinner';

interface Props {
  onDocumentLoadSuccess: (pdf: pdfjs.PDFDocumentProxy) => void;
  onWrapperResize: (innerContentRect: ContentRect) => void;
  onPageLoadSuccess: (innerPage: PDFPageProxy) => void;
  width?: number;
  scale: number;
  pageNumber: number;
  file: any;
}

export default function PdfReport({
  onDocumentLoadSuccess,
  onPageLoadSuccess,
  onWrapperResize,
  width,
  scale,
  pageNumber,
  file,
}: Props) {
  const classes = usePdfViewerStyles({ scale });
  return (
    <Box className={`${classes.container} docContainer`}>
      <Measure bounds onResize={onWrapperResize}>
        {({ measureRef }: { measureRef: MeasureRef }) => (
          <div className={classes.documentContainer} ref={measureRef}>
            <Document
              onLoadSuccess={onDocumentLoadSuccess}
              file={file}
              loading={<Spinner />}
              className={classes.document}
            >
              <Page
                key="page"
                onLoadSuccess={onPageLoadSuccess}
                width={width}
                scale={scale}
                pageNumber={pageNumber}
              />
            </Document>
          </div>
        )}
      </Measure>
    </Box>
  );
}
