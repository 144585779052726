import { combineReducers } from 'redux';

import ecommerceReducer from '../ecommerce/redux/reducers';
import { reducer as quickbooksDesktopReducer } from '../qbd/redux';
import { reducer as quickbooksReducer } from '../quickbooks/redux';
import { reducer as shippingReducer } from '../shipping/redux';
import { reducer as paymentsReducer } from '../payments/redux';
import { reducer as xeroReducer } from '../xero/redux';

export default combineReducers({
  quickbooks: quickbooksReducer,
  ecommerce: ecommerceReducer,
  quickbooksDesktop: quickbooksDesktopReducer,
  shipping: shippingReducer,
  payments: paymentsReducer,
  xero: xeroReducer,
});
