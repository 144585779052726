import {
  Column,
  ColumnTypes,
  initialPagination,
} from 'ui/components/Table/ItemsTable';

export const availableLocationsColumns = (
  locationAmount: number,
  maxLocationAmount: number
): Column[] => [
  {
    title: `${locationAmount} of ${maxLocationAmount} Locations`,
    type: ColumnTypes.renderByCustomRow,
    field: 'name',
  },
  {
    title: '',
    type: ColumnTypes.renderByCustomRow,
    width: 24,
  },
];

export const selectedLocationsColumns = (locationAmount: number): Column[] => [
  {
    title: `${locationAmount} Locations Selected`,
    type: ColumnTypes.renderByCustomRow,
    field: 'name',
  },
  {
    title: '',
    type: ColumnTypes.renderByCustomRow,
    width: 24,
  },
];

export const initialLocation = {
  data: [],
  pagination: initialPagination,
};
