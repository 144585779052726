import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { ViewTrackingModal } from 'ui/components/Modal/ViewTrackingModal';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import {
  initialPagination,
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { TextField } from 'ui/components/TextField/TextField';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import {
  PickItem,
  postPickItemStart,
  postPickItemCommit,
  postPickItemHold,
  postPickItemFinish,
  PickItemStatus,
  transformPick,
  postPickItemVoid,
  Pick,
} from 'services/picking';
import { Location, LocationType } from 'services/locations';
import { getUsers, User } from 'services/user';
import { getOrderPriorities } from 'services/settings/orderPriorities';
import { getSettingsPicking } from 'services/settings/picking/redux';
import { initialPickItem } from 'services/picking/consts';
import { Pagination } from 'services/search';
import { replaceValueInCollection } from 'helpers';
import { CustomField, useCustomFields } from 'services/customFields';
import { PermissionType } from 'services/permissions';
import { useHandleTextFieldChange } from 'services/forms';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';

import { GeneralTabProps, RowActionType, RowActionIdentifier } from './types';
import { PICK_ITEM_COLUMNS } from './consts';
import {
  PickItemRow,
  PickItemDetailsModal,
  PickItemCommitModal,
} from './components';
import { PriorityLevelType } from '../../../../types';
import {
  pickHasExpiredItems,
  sortPickItemsByPickFromLocation,
} from '../../helpers';
import _ from 'lodash';
import {
  resolvePickCustomerName,
  resolvePickVendorName,
} from '../../../../helpers';

// FF CU-863hakwb6
import { useFlags } from 'helpers/useFlags';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOCustomFields from 'ui/components/CustomFields/CustomFields/FBOCustomFields';
import { colorPalette, themeRestyle } from 'ui/theme';
import { Box } from '@mui/material';
// FF CU-863hakwb6

const GeneralTab: React.FunctionComponent<GeneralTabProps> = (props) => {
  const {
    activePick,
    setActivePick,
    validationErrors,
    fetchSearchResults,
    customFieldsErrors,
    oldState,
  } = props;

  const { items: orderPriorities } = useSelector(getOrderPriorities);
  const users = useSelector(getUsers);
  const pickingSettings = useSelector(getSettingsPicking);

  const setCustomFields = useCustomFields<Pick>(setActivePick);

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [activePickItem, setActivePickItem] =
    useState<PickItem>(initialPickItem);
  const [showPickItemDetailsModal, setShowPickItemDetailsModal] =
    useState(false);
  const [showPickCommitModal, setShowPickCommitModal] = useState(false);
  // Bool that lets us know if finish or commit is clicked
  const [isFinishClicked, setIsFinishClicked] = useState(false);
  const [showViewTrackingModal, setShowViewTrackingModal] = useState(false);
  const [showExpiredItemsConfirmation, setShowExpiredItemsConfirmation] =
    useState(false);
  const [pagination, setPagination] = useState<Pagination>(initialPagination);

  const firstInputElement = useRef<HTMLInputElement>(null);

  const resolvedPriorityValue = useMemo(
    () =>
      orderPriorities.find((i) => i.id === activePick.orderPriorityId) || null,
    [activePick.orderPriorityId, orderPriorities]
  );

  const resolvedUserValue = useMemo(
    () => users.find((u) => u.id === activePick.userId) || null,
    [users, activePick.userId]
  );

  // FF CU-863hakwb6
  const flags = useFlags();
  const expandsOptimizationFlag = flags.expandsOptimization;
  // FF CU-863hakwb6

  // reorder data when pagination sort changed
  useEffect(() => {
    setActivePick({
      ...activePick,
      pickItems: _.orderBy(
        activePick.pickItems,
        pagination.sort.sortBy,
        pagination.sort.direction
      ),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.sort]);

  const autocompleteLabelResolver = useCallback(
    (option) => option.name || option.value,
    []
  );

  const hideModal = useCallback(() => {
    setShowPickItemDetailsModal(false);
    setShowPickCommitModal(false);
    setActivePickItem(initialPickItem);
  }, []);

  const showCommitModal = useCallback((pickItem: PickItem) => {
    setActivePickItem(pickItem);
    setShowPickCommitModal(true);
  }, []);

  const startPickItem = useCallback(
    async (pickItem: PickItem) => {
      let data;
      try {
        const resp = await postPickItemStart(activePick.id, pickItem.id!);
        data = resp.data;
      } catch {
        return;
      }
      const updatedPick = transformPick(data);
      oldState.current = updatedPick;
      setActivePick(updatedPick);
      fetchSearchResults();
    },
    [activePick, setActivePick, fetchSearchResults, oldState]
  );

  const holdPickItem = useCallback(
    async (pickItem: PickItem) => {
      let data;
      try {
        const resp = await postPickItemHold(activePick.id, pickItem.id!);
        data = resp.data;
      } catch {
        return;
      }
      const updatedPick = transformPick(data);
      oldState.current = updatedPick;
      setActivePick(updatedPick);
      fetchSearchResults();
    },
    [activePick, setActivePick, fetchSearchResults, oldState]
  );

  const voidPickItem = useCallback(
    async (pickItem: PickItem) => {
      const successMsg =
        pickItem.status === PickItemStatus.Hold ? 'resumed' : 'voided';

      let data;
      try {
        const resp = await postPickItemVoid(
          activePick.id,
          pickItem.id!,
          successMsg
        );
        data = resp.data;
      } catch {
        return;
      }
      const updatedPick = transformPick(data);
      oldState.current = updatedPick;
      setActivePick(updatedPick);
      fetchSearchResults();
    },
    [activePick, setActivePick, fetchSearchResults, oldState]
  );

  const finishPickItem = useCallback(
    async (pickItem: PickItem) => {
      // if Item is not commited we want to go trough process of commit
      if (pickItem.status !== PickItemStatus.Committed) {
        showCommitModal(pickItem);
        return;
      }

      // if item is commited and have some expired tracking
      if (
        pickingSettings.pickingExpiredItemWarning &&
        pickHasExpiredItems([pickItem])
      ) {
        setShowExpiredItemsConfirmation(true);
        return;
      }

      try {
        const updatedPick = await postPickItemFinish(
          activePick.id,
          pickItem,
          expandsOptimizationFlag
        );
        oldState.current = updatedPick;
        setActivePick(updatedPick);
      } catch {
        return;
      }

      fetchSearchResults();
    },
    [
      activePick.id,
      pickingSettings.pickingExpiredItemWarning,
      oldState,
      showCommitModal,
      setActivePick,
      fetchSearchResults,
    ]
  );

  const handleRowAction = useCallback(
    async (action: RowActionType) => {
      switch (action.identifier) {
        case RowActionIdentifier.ShowTracking:
          setShowViewTrackingModal(true);
          setActivePickItem(action.pickItem);
          break;
        case RowActionIdentifier.Start:
          startPickItem(action.pickItem);
          break;
        case RowActionIdentifier.Commit:
          showCommitModal(action.pickItem);
          setIsFinishClicked(false);
          break;
        case RowActionIdentifier.Hold:
          holdPickItem(action.pickItem);
          break;
        case RowActionIdentifier.Finish:
          setActivePickItem(action.pickItem);
          finishPickItem(action.pickItem);
          setIsFinishClicked(true);
          break;
        case RowActionIdentifier.Void:
          voidPickItem(action.pickItem);
          break;
      }
    },
    [showCommitModal, finishPickItem, holdPickItem, startPickItem, voidPickItem]
  );

  const saveCommitModal = useCallback(
    async (pickItem: PickItem) => {
      if (isFinishClicked) {
        try {
          const updatedPick = await postPickItemFinish(
            activePick.id,
            pickItem,
            expandsOptimizationFlag
          );
          oldState.current = updatedPick;
          setActivePick(updatedPick);
        } catch {
          return;
        }
      } else {
        try {
          const updatedPick = await postPickItemCommit(activePick.id, pickItem);
          oldState.current = updatedPick;
          setActivePick(updatedPick);
        } catch {
          return;
        }
      }

      hideModal();
      fetchSearchResults();
    },
    [
      activePick,
      hideModal,
      isFinishClicked,
      setActivePick,
      fetchSearchResults,
      oldState,
    ]
  );

  const handlePickItemDetailsModalSave = useCallback(
    async (updatedPick: Pick) => {
      setActivePick(updatedPick);
      oldState.current = updatedPick;
      setShowPickItemDetailsModal(false);
    },
    [setActivePick, oldState]
  );

  const handleAutocompleteChanged = useCallback(
    (filedName) =>
      (
        e: React.ChangeEvent<{}>,
        value: null | Location | PriorityLevelType
      ) => {
        setActivePick({
          ...activePick,
          [filedName]: value ? value.id : null,
        });
      },
    [activePick, setActivePick]
  );

  const handlePickItemsChanged = useCallback(
    (newPickItems: PickItem[]) => {
      setActivePick({
        ...activePick,
        pickItems: newPickItems,
      });
    },
    [activePick, setActivePick]
  );

  const handleLocationChange = useCallback(
    (loc: Location | null) => {
      setActivePick((old) => ({
        ...old,
        parentLocationId: loc ? loc.id : null,
      }));
    },
    [setActivePick]
  );

  const handleDateChange = useCallback(
    (date: string | null | undefined) => {
      setActivePick({
        ...activePick,
        scheduledDate: !date ? null : moment(date).toDate(),
      });
    },
    [activePick, setActivePick]
  );

  const handleSelectClick = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  const handlePickItemClick = useCallback(
    (id: number) => {
      const pickItem = activePick.pickItems.find((i) => i.id === id);
      if (pickItem) {
        pickItem.pickId = activePick.id;
        setActivePickItem(pickItem);
        setShowPickItemDetailsModal(true);
      }
    },
    [activePick.pickItems]
  );

  const handleCloseViewTrackingModal = useCallback(() => {
    setShowViewTrackingModal(false);
    setActivePickItem(initialPickItem);
  }, []);

  const handleExpiredItemsConfirmClicked = useCallback(async () => {
    setShowExpiredItemsConfirmation(false);

    try {
      const updatedPick = await postPickItemFinish(
        activePick.id,
        activePickItem,
        expandsOptimizationFlag
      );
      oldState.current = updatedPick;
      setActivePick(updatedPick);
    } catch {
      return;
    }

    setActivePickItem(initialPickItem);
    fetchSearchResults();
  }, [
    activePick.id,
    activePickItem,
    fetchSearchResults,
    setActivePick,
    oldState,
  ]);

  const customFieldChanged = useCallback(
    (customField: CustomField) => {
      const index = activePick.customFields.findIndex(
        (c) => c.id === customField.id
      );
      setCustomFields(
        (old) => replaceValueInCollection<CustomField>(old, customField, index)!
      );
    },
    [activePick.customFields, setCustomFields]
  );

  const handleTextFieldChange = useHandleTextFieldChange(
    setActivePick,
    activePick
  );
  const isCreditReturn = useMemo(
    () =>
      !!activePick?.pickItems?.some(
        (pickItem) =>
          pickItem.purchaseOrderItem?.purchaseOrderItemType === 'Credit Return'
      ),
    [activePick?.pickItems]
  );

  return (
    <>
      <Grid
        sx={{ overflowY: 'scroll', padding: '16px' }}
        container
        spacing={2}
        columns={15}
        disableEqualOverflow
      >
        <Grid xs={3}>
          <TextField
            className="redesign"
            variant="standard"
            label={isCreditReturn ? 'Vendor' : 'Customer'}
            name="customer"
            data-qa={isCreditReturn ? 'picking-vendor' : 'picking-customer'}
            placeholder={isCreditReturn ? 'Select vendor' : 'Select customer'}
            value={
              isCreditReturn
                ? resolvePickVendorName(activePick)
                : resolvePickCustomerName(activePick)
            }
            fullWidth
            disabled
          />
        </Grid>
        <Grid xs={3}>
          <Autocomplete
            label="Priority"
            name="orderPriorityId"
            placeholder="Select priority"
            options={orderPriorities}
            additionalInputProps={{ inputRef: firstInputElement }}
            getOptionLabel={autocompleteLabelResolver}
            value={resolvedPriorityValue}
            permissions={[PermissionType.PickingEdit]}
            onChange={handleAutocompleteChanged('orderPriorityId')}
            error={!!validationErrors.orderPriorityId}
            dataQa="picking-priority"
          />
        </Grid>
        <Grid xs={3}>
          <DatePickerWrapper
            onChange={handleDateChange}
            value={activePick.scheduledDate}
            label="Scheduled"
            placeholder="Select date scheduled"
            name="scheduledDate"
            fullWidth
            permissions={[PermissionType.PickingEdit]}
            error={!!validationErrors.scheduledDate}
            inputProps={{
              'data-qa': 'picking-scheduled' as any,
            }}
          />
        </Grid>
        <Grid xs={3}>
          <LocationsAsyncAutocomplete
            placeholder="Select Location"
            label="Location"
            onChange={handleLocationChange}
            value={activePick ? activePick.parentLocationId : null}
            parentId={null}
            companyWide={false}
            permissions={[PermissionType.PickingEdit]}
            error={!!validationErrors.parentLocationId}
            locationTypes={[
              LocationType.Stock,
              LocationType.Receiving,
              LocationType.Shipping,
            ]}
            dataQa="picking-location"
          />
        </Grid>
        <Grid xs={3}>
          <Autocomplete
            label="Assigned User"
            placeholder="Select assigned user"
            options={users}
            getOptionLabel={(option: User) =>
              option.firstName + ' ' + option.lastName
            }
            value={resolvedUserValue}
            permissions={[PermissionType.PickingEdit]}
            onChange={handleAutocompleteChanged('userId')}
            error={!!validationErrors.userId}
            dataQa="picking-assigned-user"
          />
        </Grid>
        <Grid xs={15}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Notes"
            placeholder="Enter notes"
            name="notes"
            permissions={[PermissionType.PickingEdit]}
            onChange={handleTextFieldChange}
            value={activePick.notes}
            fullWidth
          />
        </Grid>
        <Grid container xs={15} mb={themeRestyle.spacing(2)}>
          <FBOCustomFields
            customFields={activePick.customFields}
            onFieldChange={customFieldChanged}
            errors={customFieldsErrors}
            permissions={[PermissionType.PickingEdit]}
          />
        </Grid>
      </Grid>
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        overflow={'hidden'}
        sx={{
          borderTop: `1px solid ${colorPalette.redesign.background3}`,
        }}
      >
        <FBOTitleBar title="Items" />
        <ItemsTable
          columns={PICK_ITEM_COLUMNS}
          data={sortPickItemsByPickFromLocation(activePick.pickItems)}
          selectedItems={selectedItems}
          RenderCustomRow={PickItemRow}
          setData={handlePickItemsChanged}
          selectableItems={false}
          onItemClick={handlePickItemClick}
          onAction={handleRowAction}
          onSelectedChange={handleSelectClick}
          dataQa="picking-item-table"
          pagination={pagination}
          onPaginationChange={setPagination}
          tableLayoutFixed
          hideFooter
        />
        <PickItemDetailsModal
          pickItem={activePickItem}
          show={showPickItemDetailsModal}
          onClose={hideModal}
          onSave={handlePickItemDetailsModalSave}
        />
        <PickItemCommitModal
          activePickItem={activePickItem}
          show={showPickCommitModal}
          isFinishClicked={isFinishClicked}
          onClose={hideModal}
          onSave={saveCommitModal}
        />
        <ViewTrackingModal
          open={showViewTrackingModal}
          itemTrackingTypes={_.get(
            activePickItem,
            'item.itemTrackingTypeList',
            []
          )}
          trackingGroups={_.get(activePickItem, 'trackingGroupList', [])}
          onClose={handleCloseViewTrackingModal}
          firstColumnTitle="Committed Quantity"
          disableAutoAssign={
            activePickItem.status === PickItemStatus.Committed ||
            activePickItem.status === PickItemStatus.Finished
          }
        />
      </Box>
      {pickingSettings.pickingExpiredItemWarning && (
        <ConfirmationModal
          open={showExpiredItemsConfirmation}
          title="Finish Pick"
          body="Pick item is expired. Are you sure you want to finish this pick?"
          onCancelClicked={() => setShowExpiredItemsConfirmation(false)}
          onConfirmClicked={handleExpiredItemsConfirmClicked}
          confirmLabel="Finish"
          confirmButtonRed
        />
      )}
    </>
  );
};

export default React.memo(GeneralTab);
