import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';

import { TitleBar } from 'ui/components/TitleBar';
import { ItemsTable } from 'ui/components/Table/ItemsTable';

import { HistoryCardRow } from './components';
import { HistoryCardProps } from './types';
import { useHistoryCardStyle } from './styled';
import { ECOMMERCE_HISTORY_COLUMNS } from './consts';
import {
  EcommerceEventType,
  EcommerceHistory,
  getEcommerceHistory,
} from 'services/integrations/ecommerce';
import { showNotification } from 'services/api';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import AutoComplete from '@mui/material/Autocomplete';
import { TextField } from 'ui/components/TextField/TextField';

const HistoryCard: React.FC<HistoryCardProps> = (props) => {
  const { settingsId, channelName, imageUrl } = props;
  const classes = useHistoryCardStyle();
  const [ecommerceHistory, setEcommerceHistory] = useState<EcommerceHistory[]>(
    []
  );

  const [isLoading, setIsLoading] = useState(false);

  const eventOptions = useMemo(() => {
    const entries = Object.entries(EcommerceEventType)
      .filter(([, value]) => typeof value === 'string')
      .map(([key, value]) => ({ id: key, value: value as string }));

    entries.unshift({ id: '20', value: 'Error' });

    return entries;
  }, []);

  const getHistory = useCallback(
    async (type: number) => {
      setIsLoading(true);
      try {
        const history = await getEcommerceHistory(settingsId, type);
        setEcommerceHistory(history);
      } catch (e) {
        showNotification('Error fetching history data!', {
          variant: 'error',
        });
        throw e;
      }
      setIsLoading(false);
    },
    [settingsId]
  );

  useEffect(() => {
    getHistory(20);
  }, [getHistory]);

  const handleEventTypeChange = useCallback(
    (event: any, value: { id: string; value: string } | null) => {
      if (value) {
        getHistory(parseInt(value.id));
        return;
      }

      setEcommerceHistory([]);
    },
    [getHistory]
  );

  return (
    <Box>
      <div id="history" className={classes.paper}>
        <TitleBar
          title={channelName}
          imageUrl={imageUrl}
          noBorderRadius
        ></TitleBar>
        <AutoComplete
          options={eventOptions}
          getOptionLabel={(option) => option.value}
          defaultValue={{ id: '20', value: 'Error' }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Event Type"
              placeholder="Select Event Type"
            />
          )}
          onChange={handleEventTypeChange}
          style={{ marginTop: '10px' }}
        />
        <NetworkSpinnerWrapper isLoading={isLoading} size={40}>
          <ItemsTable
            data={ecommerceHistory}
            columns={ECOMMERCE_HISTORY_COLUMNS}
            selectableItems={false}
            emptyTableText={isLoading ? '' : 'No history data'}
            RenderCustomRow={HistoryCardRow}
            containerHeight={378}
          />
        </NetworkSpinnerWrapper>
      </div>
    </Box>
  );
};

export default memo(HistoryCard);
