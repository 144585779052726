import { paymentIntegrationsApi } from 'services/api/config';

export const connectAuthNet = async (
  apiLoginId: string | null,
  transactionKey: string | null
) => {
  await paymentIntegrationsApi.post('/api/v1/authnet/integrate', {
    apiLoginId,
    transactionKey,
  });
};

export const createPaymentAuthNet = async (
  dataDescriptor: string,
  dataValue: string,
  salesOrderId: number,
  amount: number
) => {
  await paymentIntegrationsApi.post('/api/v1/authnet/create-payment', {
    dataDescriptor,
    dataValue,
    salesOrderId,
    amount,
  });
};
