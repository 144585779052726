import React, { useState, memo, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Switch, Typography, Button, Link } from '@mui/material';

import { fetchLocations, getLocations, Location } from 'services/locations';
import {
  ChannelType,
  InventoryType,
  InventoryTypeOptions,
} from 'services/integrations/ecommerce';
import { removeValueFromCollection } from 'helpers';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';
import { Tag } from 'services/tags';

import { SettingsFormProps } from '../../types';
import { useEcommerceCardStyle } from '../../../../styled';
import useEcommerceSettings from '../../../../useEcommerceSettings';
import { MappingLocations } from '../MappingLocations';

const InventorySettingsForm: React.FC<SettingsFormProps> = (props) => {
  const classes = useEcommerceCardStyle();
  const dispatch = useDispatch();

  const { index } = props;

  const [setting, setEcommerceSettings, errors, , , location, channelName] =
    useEcommerceSettings(index);

  const [inventorySettings, setInventorySettings] = useState(setting);
  const [loc, setLoc] = useState<Location | null>(null);

  const { items: locations } = useSelector(getLocations);

  const selectedLoc = useMemo(() => {
    return locations.filter((l) =>
      inventorySettings.inventory.locations.includes(l.id!)
    );
  }, [inventorySettings.inventory.locations, locations]);

  const selectedInventoryType = useMemo(
    () =>
      InventoryTypeOptions.find(
        (t) => t.code === inventorySettings.inventory.type
      ),
    [inventorySettings]
  );

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchLocations());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (inventorySettings.id) {
      setEcommerceSettings(inventorySettings);
    }
  }, [inventorySettings, setEcommerceSettings, index]);

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setInventorySettings((old) => ({
        ...old,
        inventory: {
          ...old.inventory,
          [event.target.name]: checked,
        },
      }));
    },
    [setInventorySettings]
  );

  const handleTagsChange = useCallback(
    (values: Tag[]) => {
      setInventorySettings((old) => ({
        ...old,
        inventory: { ...old.inventory, tags: values },
      }));
    },
    [setInventorySettings]
  );

  const handleTagDelete = useCallback(
    (id: number) => {
      const tagIndex = inventorySettings.inventory.tags.findIndex(
        (i) => i.id === id
      );
      if (tagIndex > -1) {
        setInventorySettings((old) => ({
          ...old,
          inventory: {
            ...old.inventory,
            tags: [
              ...inventorySettings.inventory.tags.slice(0, tagIndex),
              ...inventorySettings.inventory.tags.slice(tagIndex + 1),
            ],
          },
        }));
      }
    },
    [inventorySettings.inventory.tags, setInventorySettings]
  );

  const handleAddLocationClick = () => {
    // disable adding existing location
    if (!loc || inventorySettings.inventory.locations.includes(loc.id!)) {
      return;
    }

    setInventorySettings((old) => ({
      ...old,
      inventory: {
        ...old.inventory,
        locations: [...old.inventory.locations, loc.id!],
      },
    }));
    setLoc(null);
  };

  const handleRemoveLocation = (index: number) => () => {
    setInventorySettings((old) => ({
      ...old,
      inventory: {
        ...old.inventory,
        locations: removeValueFromCollection(old.inventory.locations, index),
      },
    }));
  };

  const handleAutoCompleteChange = useCallback(
    () => (v: InventoryType) => {
      setInventorySettings((old) => ({
        ...old,
        inventory: { ...old.inventory, type: v ? v.code : null },
      }));
    },
    [setInventorySettings]
  );
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      height="100%"
      overflow="auto"
    >
      <Box className={classes.modalRow}>
        <Typography variant="body1" color="textPrimary">
          Update {channelName} Inventory
        </Typography>

        <Switch
          checked={inventorySettings.inventory.update}
          onChange={handleCheckboxChange}
          name="update"
          color="primary"
        />
      </Box>

      <Divider />

      {inventorySettings.inventory.update && (
        <>
          <Box className={classes.modalRow}>
            <Box width="45%">
              <Typography variant="body1" color="textPrimary">
                Type
              </Typography>
            </Box>
            <Box width="45%">
              <Autocomplete
                placeholder="Type"
                onChange={(e: React.ChangeEvent<{}>, v: InventoryType) =>
                  handleAutoCompleteChange()(v)
                }
                options={InventoryTypeOptions}
                getOptionLabel={(u) => u.name}
                value={selectedInventoryType}
                error={!!errors['type']}
              />
            </Box>
          </Box>
          <Divider />

          <Box paddingBottom="15px">
            <Box className={classes.modalRow}>
              <Typography variant="body1" color="textPrimary">
                Limit inventory syncing to Items with the following tags
              </Typography>
            </Box>

            <Box paddingLeft="15px">
              <TagsAsyncAutocomplete
                value={inventorySettings.inventory.tags}
                onChange={handleTagsChange}
                onTagDelete={handleTagDelete}
                dataQa="item-tag"
              />
            </Box>
          </Box>

          <Divider />

          {setting.channel.channel === ChannelType.Shopify ? (
            <MappingLocations
              identifier="inventory"
              classes={classes}
              mappingLocations={inventorySettings.inventory.mapping}
              errors={errors}
              setEcommerceSettings={setInventorySettings}
              location={location}
            />
          ) : (
            <Box className={classes.modalRowExpandable}>
              <Box className={classes.modalRow}>
                <Typography>Locations</Typography>
              </Box>
              <Box p={2} display="flex" flexDirection="column">
                <Box display="flex" justifyContent="space-between" width="100%">
                  <LocationsAsyncAutocomplete
                    placeholder="Select Location"
                    label="Select location"
                    onChange={setLoc}
                    value={loc ? loc.id : null}
                    companyWide={false}
                    parentId={null}
                    error={!!errors.locations}
                    fullWidth
                  />
                  <Box ml={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleAddLocationClick}
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
                <Box mt={2}>
                  {selectedLoc.map((loc, key) => (
                    <Box
                      key={`location_${key}`}
                      display="flex"
                      justifyContent="space-between"
                      width="100%"
                      p={2}
                      borderBottom="1px solid #EBEBEB"
                    >
                      <Typography variant="body1" color="textPrimary">
                        {loc.name}
                      </Typography>
                      <Link
                        onClick={handleRemoveLocation(key)}
                        style={{ cursor: 'pointer ' }}
                        underline="hover"
                      >
                        Remove
                      </Link>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default memo(InventorySettingsForm);
