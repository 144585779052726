import React, { useMemo } from 'react';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { Pagination } from 'services/search';
import { PermissionType } from 'services/permissions';
import { PermissionsSearchResultsProps } from './types';
import { createActionBarOptions, permissionColumns } from '../../consts';
import { default as PermissionRow } from './PermissionRow';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const PermissionsSearchResults: React.FC<PermissionsSearchResultsProps> = ({
  permissionGroups,
  activeId,
  onPermissionGroupClick,
  onAddNewPress,
  isLoading,
  pagination,
  activeDate,
  onPageAction,
  onPaginationChange,
}) => {
  const alwaysOnColumns = useMemo(
    () =>
      permissionColumns(activeDate).filter(
        (c) => c.title === 'Name' || c.title === 'Users'
      ),
    [activeDate]
  );

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  return (
    <>
      <FBOTitleBar title="Permission Groups">
        {activeId !== -1 && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant={!activeId ? 'primary' : 'secondary'}
            color="positive"
            size="medium"
            icon="FBOAddCircle"
            data-qa="permission-add-new"
            onClick={onAddNewPress}
            permissions={[PermissionType.PermissionCreate]}
          >
            {!activeId ? 'Add New' : ''}
          </FBOButton>
        )}
        <FBOMenuButton
          variant="secondary"
          items={createActionBarOptions(onPageAction, permissionGroups.length)}
          data-qa="permissions-three-dot-menu"
        />
      </FBOTitleBar>

      <ItemsTable
        data={permissionGroups}
        columns={activeId ? alwaysOnColumns : permissionColumns(activeDate)}
        RenderCustomRow={PermissionRow}
        activeId={activeId}
        onItemClick={onPermissionGroupClick}
        selectableItems={false}
        isLoadingData={isLoading}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        dataQa="permissions-table"
        showEnhancedTablePaginationActions={!Boolean(activeId)}
      />
    </>
  );
};

export default PermissionsSearchResults;
