import { useCallback, useContext } from 'react';

import {
  ChannelType,
  initialEcommerceSettings,
  EcommerceSettings,
} from 'services/integrations/ecommerce';

import { replaceValueInCollection } from 'helpers';
import { EcommerceContext } from './EcommerceProvider';
const useEcommerceSettings: (index: number) => [
  EcommerceSettings,
  (setting: EcommerceSettings) => void,
  any,
  {
    [id: number]: string;
  },
  {
    [id: number]: string;
  },
  {
    [id: number]: string;
  },
  string
] = (index) => {
  const [settings, setSettings, , errors, , , , , , mappings] =
    useContext(EcommerceContext);

  const setting =
    settings.length > 0
      ? settings.find((e, i) => i === index)!
      : initialEcommerceSettings;

  const setEcommerceSettings = useCallback(
    (setting: EcommerceSettings) => {
      setSettings((old) => replaceValueInCollection(old, setting, index)!);
    },
    [setSettings, index]
  );

  const channelName = () => {
    switch (setting.channel.channel) {
      case ChannelType.Shopify:
        return 'Shopify';
      case ChannelType.Amazon:
        return 'Amazon';
      case ChannelType.WooCommerce:
        return 'Woo Commerce';
      case ChannelType.Salesforce:
        return 'Sales force';
      case ChannelType.BigCommerce:
        return 'Big Commerce';
      case ChannelType.Magento:
        return 'Adobe Commerce';
      case ChannelType.ShipStation:
        return 'ShipStation';
      case ChannelType.Ebay:
        return 'Ebay';
      default:
        return '';
    }
  };

  const mp = mappings.find((m) => m.id === setting.id);

  return [
    setting,
    setEcommerceSettings,
    errors[index],
    mp ? mp.mappings.item.channel : '',
    mp ? mp.mappings.item.fishbowl : '',
    mp ? mp.mappings.location : '',
    channelName(),
  ];
};

export default useEcommerceSettings;
