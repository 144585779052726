import React, { useMemo, useCallback, memo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import {
  Grid,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import {
  COUNTRIES,
  CountryAutocomplete,
} from 'ui/components/Autocomplete/CountryAutocomplete';
import { useHandleTextFieldChange } from 'services/forms';
import {
  Address,
  AddressValidation,
  Contact,
  initalValidAddress,
  initialContact,
} from 'services/addresses';
import {
  getValidateAddress,
  ShippingConnectionType,
} from 'services/integrations/shipping';
import { getShippingIntegrationConnection } from 'services/integrations/shipping/redux';
import { findNextNegativeId, replaceValueInCollection } from 'helpers';
import {
  StateAutocomplete,
  STATES,
} from 'ui/components/Autocomplete/StateAutocomplete';

import { AddressValidationModal, VerifiedAddressLabel } from './components';
import { AddressContactListItem } from './AddressContactListItem';
import { AddressModalProps } from './types';
import { logErrorCtx } from 'app/logging';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

const AddressModal: React.FC<AddressModalProps> = (props) => {
  const {
    modalVisible,
    onClose,
    activeAddress,
    setActiveAddress,
    onSaveChanges,
    validationErrors,
    contactErrors,
    disableContacts = false,
  } = props;

  const { contacts } = activeAddress;

  const [addressValidation, setAddressValidation] =
    useState<AddressValidation>(initalValidAddress);

  const connection = useSelector(
    getShippingIntegrationConnection(ShippingConnectionType.Shippo)
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleTextFieldChange = useHandleTextFieldChange<Address>(
    setActiveAddress,
    { ...activeAddress, verified: false }
  );

  const handleNonVerifiedTextFieldChange = useHandleTextFieldChange<Address>(
    setActiveAddress,
    { ...activeAddress, verified: true }
  );

  const oldAddress = useMemo(
    () => activeAddress,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeAddress.id]
  );

  const setContactItems = useCallback(
    (contactItems: React.SetStateAction<Contact[]>) => {
      if (typeof contactItems === 'function') {
        setActiveAddress((a) => ({
          ...a,
          contacts: contactItems(a.contacts),
        }));
        return;
      }

      setActiveAddress((a) => ({
        ...a,
        contacts: contactItems,
      }));
    },
    [setActiveAddress]
  );

  const activeState = useMemo(() => {
    return STATES.find((s) => s.abbreviation === activeAddress.state) || null;
  }, [activeAddress]);

  const handleAddNewContact = useCallback(() => {
    setContactItems((oldState) => [
      ...oldState,
      { ...initialContact, id: findNextNegativeId<Contact>(oldState) },
    ]);
  }, [setContactItems]);

  const activeCountry = useMemo(() => {
    const newCountry = COUNTRIES.find((c) => c.code === activeAddress.country);
    if (newCountry) {
      return newCountry;
    } else {
      return null;
    }
  }, [activeAddress.country]);

  const handleCountryChange = useCallback(
    (e: any, v: any) => {
      setActiveAddress({
        ...activeAddress,
        country: _.get(v, 'code', ''),
        verified: false,
      });
    },
    [setActiveAddress, activeAddress]
  );

  const handleStateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, v: any) => {
      setActiveAddress({
        ...activeAddress,
        state: _.get(v, 'abbreviation', ''),
        verified: false,
      });
    },
    [activeAddress, setActiveAddress]
  );

  const handleEditContact = useCallback(
    (index: number) => (contact: Contact) => {
      setContactItems(
        (prevContacts) =>
          replaceValueInCollection(prevContacts, contact, index)!
      );
    },
    [setContactItems]
  );

  const handleContactCheckboxChange = useCallback(
    (index: number) => () => {
      const newContacts = [...contacts];
      const previousActiveIndex = _.findIndex(contacts, {
        defaultFlag: true,
        type: contacts[index].type,
      });
      if (previousActiveIndex !== -1) {
        newContacts[previousActiveIndex] = {
          ...newContacts[previousActiveIndex],
          defaultFlag: false,
        };
      }
      newContacts[index] = {
        ...newContacts[index],
        defaultFlag: previousActiveIndex !== index,
      };
      setContactItems(newContacts);
    },
    [contacts, setContactItems]
  );

  const handleResidentialChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setActiveAddress((old) => ({
        ...old,
        residential: checked,
        verified: false,
      }));
    },
    [setActiveAddress]
  );

  const handleDeleteContact = useCallback(
    (index: number) => () => {
      const deletedContact = contacts[index];
      const newContacts = [...contacts];
      if (deletedContact.id! < 0) {
        newContacts.splice(index, 1);
      } else {
        newContacts[index].deleted = true;
      }

      setContactItems(newContacts);
    },
    [contacts, setContactItems]
  );

  const handleResetClicked = useCallback(() => {
    setActiveAddress(oldAddress);
  }, [oldAddress, setActiveAddress]);

  const [isAddressValidationModalVisible, setAddressValidationModalVisible] =
    useState(false);

  const handleApplyClick = useCallback(async () => {
    if (!connection || activeAddress.country !== 'US') {
      onSaveChanges(activeAddress);
      return;
    }

    setIsLoading(true);

    try {
      const validatedAddress = await getValidateAddress(
        connection,
        activeAddress
      );

      if (!activeAddress.verified) {
        setAddressValidationModalVisible(true);
      } else {
        onSaveChanges(activeAddress);
      }

      setAddressValidation(validatedAddress);
    } catch (error) {
      logErrorCtx('Error in getValidateAddress', {
        error: error as Error,
        stackTrace: (error as Error).stack,
        component: 'AddressModal',
        title: 'Error in getValidateAddress',
        description: 'Error in getValidateAddress',
      });
    }
    setIsLoading(false);
  }, [activeAddress, connection, onSaveChanges]);

  const handleAddressValidationModalClose = () => {
    setAddressValidationModalVisible(false);
  };

  const handleSaveOriginal = () => {
    setAddressValidationModalVisible(false);
    onSaveChanges({ ...activeAddress, verified: false });
  };

  const handleSaveVerified = () => {
    setAddressValidationModalVisible(false);
    onSaveChanges({
      ...activeAddress,
      ...addressValidation.validated,
      id: activeAddress.id,
      version: activeAddress.version,
      contacts: activeAddress.contacts,
      verified: true,
    });
  };
  const handleDefaultBillToChanged = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setActiveAddress((old) => ({
        ...old,
        defaultBillTo: checked,
      }));
    },
    [setActiveAddress]
  );

  const handleDefaultShipToChanged = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setActiveAddress((old) => ({
        ...old,
        defaultShipTo: checked,
      }));
    },
    [setActiveAddress]
  );

  return (
    <>
      <Modal
        open={modalVisible}
        title={activeAddress.id! > 0 ? 'Edit Address' : 'New Address'}
        onCancelClicked={onClose}
        onApplyClicked={handleApplyClick}
        onResetClicked={handleResetClicked}
        isLoading={isLoading}
        applyLabel="Add"
        maxWidth="md"
        dataQa="address"
      >
        <Box mb={2}>
          <Grid container item spacing={1}>
            <Grid item xs={4}>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="Address Nickname"
                placeholder="Enter nickname"
                name="name"
                autoComplete="nope"
                fullWidth
                value={activeAddress.name}
                required
                onChange={
                  activeAddress.verified
                    ? handleNonVerifiedTextFieldChange
                    : handleTextFieldChange
                }
                error={!!validationErrors.name}
                dataQa="address-name"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="Attention"
                placeholder="Enter attention"
                name="attention"
                autoComplete="nope"
                fullWidth
                value={activeAddress.attention}
                onChange={
                  activeAddress.verified
                    ? handleNonVerifiedTextFieldChange
                    : handleTextFieldChange
                }
                dataQa="address-attention"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="Company Name"
                placeholder="Enter company name"
                name="companyName"
                autoComplete="nope"
                fullWidth
                value={activeAddress.companyName}
                onChange={
                  activeAddress.verified
                    ? handleNonVerifiedTextFieldChange
                    : handleTextFieldChange
                }
                dataQa="address-company-name"
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <Grid container item spacing={1}>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="Street"
                placeholder="Enter street address"
                name="street"
                autoComplete="nope"
                fullWidth
                value={activeAddress.street}
                required
                onChange={handleTextFieldChange}
                error={!!validationErrors.street}
                dataQa="address-street-name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="Street 2"
                placeholder="Enter second line of street address"
                name="street2"
                autoComplete="nope"
                fullWidth
                value={activeAddress.street2}
                onChange={handleTextFieldChange}
                dataQa="address-street2-name"
              />
            </Grid>
          </Grid>
        </Box>
        <Box mb={4}>
          <Grid container item spacing={1}>
            <Grid item xs={4}>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="City"
                placeholder="Enter city name"
                name="city"
                autoComplete="nope"
                fullWidth
                value={activeAddress.city}
                required
                onChange={handleTextFieldChange}
                error={!!validationErrors.city}
                dataQa="address-city"
              />
            </Grid>
            <Grid item xs={2}>
              {activeAddress.country === 'US' ? (
                <StateAutocomplete
                  value={activeState}
                  onChange={handleStateChange}
                  error={!!validationErrors.state}
                  placeholder="Select state"
                  required
                />
              ) : (
                <TextField
                  className="redesign"
                  variant="standard"
                  type="text"
                  label="State"
                  placeholder="Enter state name"
                  name="state"
                  autoComplete="nope"
                  fullWidth
                  value={activeAddress.state}
                  onChange={handleTextFieldChange}
                  error={!!validationErrors.state}
                />
              )}
            </Grid>
            <Grid item xs={2}>
              <TextField
                className="redesign"
                variant="standard"
                type="text"
                label="ZIP"
                placeholder="Enter ZIP code"
                name="postalCode"
                autoComplete="nope"
                fullWidth
                value={activeAddress.postalCode}
                required={activeAddress.country === 'US'}
                onChange={handleTextFieldChange}
                error={!!validationErrors.postalCode}
                dataQa="address-postal-code"
              />
            </Grid>
            <Grid item xs={4}>
              <CountryAutocomplete
                value={activeCountry}
                onChange={handleCountryChange}
                required
                error={!!validationErrors.country}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    inputProps={
                      {
                        'data-qa': `address-residential`,
                      } as any
                    }
                    checked={Boolean(activeAddress.residential)}
                    name="residential"
                    color="primary"
                    onChange={handleResidentialChanged}
                  />
                }
                label="Residential"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    inputProps={
                      {
                        'data-qa': `address-defaultBillTo`,
                      } as any
                    }
                    checked={Boolean(activeAddress.defaultBillTo)}
                    name="defaultBillTo"
                    color="primary"
                    onChange={handleDefaultBillToChanged}
                  />
                }
                label="Default Billing address"
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    inputProps={
                      {
                        'data-qa': `address-defaultShipTo`,
                      } as any
                    }
                    checked={Boolean(activeAddress.defaultShipTo)}
                    name="defaultShipTo"
                    color="primary"
                    onChange={handleDefaultShipToChanged}
                  />
                }
                label="Default Shipping address"
              />
            </Grid>
            <Grid item xs={5} />
            <Grid item xs={3}>
              {activeAddress.country === 'US' && (
                <VerifiedAddressLabel
                  verifiedLabelVisible={activeAddress.verified}
                />
              )}
            </Grid>
          </Grid>
        </Box>
        {!disableContacts && (
          <>
            <Box mb={2}>
              <Typography>
                <b>Contacts</b>
              </Typography>
            </Box>
            {contacts.map((contact, index) => {
              return (
                !contact.deleted && (
                  <Box mb={1} key={`AddressContactListItem${index}`}>
                    <AddressContactListItem
                      contact={contact}
                      onEdit={handleEditContact(index)}
                      onDelete={handleDeleteContact(index)}
                      onCheckboxClick={handleContactCheckboxChange(index)}
                      errors={contactErrors[index] || {}}
                    />
                  </Box>
                )
              );
            })}
            <Box display="flex" alignItems="center">
              <FBOButton
                variant="tertiary"
                color="neutral"
                size="medium"
                icon={IconNames.FBOAddCircle}
                onClick={handleAddNewContact}
                data-qa="address-add-new-contact"
              >
                Add New Contact
              </FBOButton>
            </Box>
          </>
        )}
      </Modal>
      <AddressValidationModal
        onClose={handleAddressValidationModalClose}
        modalVisible={isAddressValidationModalVisible}
        addressValidation={addressValidation}
        onSaveOriginal={handleSaveOriginal}
        onSaveVerified={handleSaveVerified}
      />
    </>
  );
};

export default memo(AddressModal);
