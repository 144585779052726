import { AxiosResponse } from 'axios';
import { StartAction, SuccessAction, FailureAction } from 'services/api';
import { logErrorCtx } from 'app/logging';

type StartActionFn = () => StartAction;
type SuccessActionFn = (data: any) => SuccessAction;
type FailureActionFn = (data: any) => FailureAction;

export const createEcommerceApiAction =
  (
    apiCall: () => Promise<AxiosResponse<any>>,
    startAction: StartActionFn,
    successAction: SuccessActionFn,
    failureAction: FailureActionFn
  ) =>
  async (dispatch: any) => {
    dispatch(startAction());

    try {
      const response = await apiCall();
      const res = await dispatch(successAction(response.data));

      return { ok: true, res };
    } catch (e) {
      const error = e as Error;
      const errorMsg = 'Error with Ecommerce features. Contact support.';

      logErrorCtx('Ecommerce API request failed', {
        error,
        stackTrace: error.stack,
        title: 'Ecommerce API request failed',
        description: `Ecommerce API request failed when calling: ${apiCall.name}`,
        component:
          'service/integrations/redux/actions#createEcommerceApiAction',
      });

      await dispatch(
        failureAction({
          message: errorMsg,
        })
      );

      return {
        ok: false,
        errorMessage: errorMsg,
      };
    }
  };
