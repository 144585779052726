import React, { useCallback, useState, useEffect } from 'react';
import { Box, InputAdornment, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDebouncedCallback } from 'use-debounce';

import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { TitleBar } from 'ui/components/TitleBar';
import { TextField } from 'ui/components/TextField/TextField';
import { fetchLocationsAPI, Location, LocationType } from 'services/locations';
import { DataWithPagination, showNotification } from 'services/api';
import { Pagination } from 'services/search';
import { defaultMaximumPagination } from 'helpers';

import {
  availableLocationsColumns,
  initialLocation,
  selectedLocationsColumns,
} from './consts';
import { useItemSelecorStyle } from '../styled';
import { LocationsStepProps } from './types';
import { LocationRow } from '.';
import { logErrorCtx } from 'app/logging';

import { IconNames } from 'ui/theme';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const LocationsStep: React.FC<LocationsStepProps> = (props) => {
  const {
    wizardData: { locationIds },
    setLocationIds,
  } = props;

  const classes = useItemSelecorStyle();

  const [search, setSearch] = useState<string | null>(null);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [searchedLocations, setSearchedLocations] =
    useState<DataWithPagination<Location>>(initialLocation);
  const [defaultLocations, setDefaultLocations] =
    useState<DataWithPagination<Location>>(initialLocation);
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);

  useEffect(() => {
    // on intial search we want inital pagination to override lazy load pagination
    if (search && search.length) {
      setSearchedLocations((old) => ({
        ...old,
        pagination: defaultMaximumPagination,
      }));
    }
    // when search value is changed and it's not null
    // we want to fetch locations
    if (search) {
      getLocations();
    } else {
      setSearchedLocations(defaultLocations);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // Reset location ids
  useEffect(() => {
    setLocationIds([]);
  }, [setLocationIds]);

  useEffect(() => {
    (async () => {
      const locations = await fetchLocationsAPI({}, [
        LocationType.Stock,
        LocationType.Receiving,
        LocationType.Shipping,
      ]);
      setDefaultLocations(locations);
      setSearchedLocations(locations);
    })();
  }, []);

  const searchRowClicked = useCallback(
    (itemId: number) => {
      const location = searchedLocations.data.find((i) => i.id === itemId);
      const selectedLocation = locationIds.includes(itemId);

      if (selectedLocation) {
        showNotification('Location already added', { variant: 'warning' });
        return;
      }

      if (location) {
        setLocationIds((old) => [...old, location.id!]);
        setSelectedLocations((old) => [...old, location]);
      }
    },
    [locationIds, setLocationIds, searchedLocations]
  );

  const selectedRowClicked = useCallback(
    (locationId: number) => {
      const newSelectedLocations = selectedLocations.filter(
        (l) => l.id !== locationId
      );
      const newLocationIds = newSelectedLocations.map((l) => l.id!);

      setLocationIds(newLocationIds);
      setSelectedLocations(newSelectedLocations);
    },
    [selectedLocations, setLocationIds]
  );

  const addAllClicked = useCallback(() => {
    const locations = searchedLocations.data.filter(
      (searchedLocation) =>
        !selectedLocations.some(
          (selectedLocation) => searchedLocation.id === selectedLocation.id
        )
    );
    const locationIds = locations.map((l) => l.id!);

    setSelectedLocations((old) => [...old, ...locations]);
    setLocationIds((old) => [...old, ...locationIds]);
  }, [searchedLocations, selectedLocations, setLocationIds]);

  const removeAllClicked = useCallback(() => {
    setSelectedLocations([]);
    setLocationIds([]);
  }, [setLocationIds]);

  const getLocations = useDebouncedCallback(async () => {
    setFetchLoading(true);
    try {
      const resItems = await fetchLocationsAPI(
        {
          quickSearchValue: (search || '').toLowerCase(),
          customQuickSearchColumns: ['name'],
        },
        [LocationType.Stock, LocationType.Receiving, LocationType.Shipping]
      );
      setSearchedLocations(resItems);
    } catch (err) {
      const error = err as Error;
      logErrorCtx('Locations Not Fetched', {
        error,
        stackTrace: error.stack,
        title: 'Failed to Fetch Locations from API',
        description: 'BuldWizard Failed to Fetch Locations',
        component: 'LocationsStep',
      });
    }

    setFetchLoading(false);
  }, 300);

  const getLocationsWithPagination = useCallback(
    async (pagination: Pagination) => {
      try {
        const res = await fetchLocationsAPI(
          {
            quickSearchValue: (search || '').toLowerCase(),
            customQuickSearchColumns: ['name'],
            pagination,
          },
          [LocationType.Stock, LocationType.Receiving, LocationType.Shipping]
        );

        setSearchedLocations((old) => ({
          pagination: res.pagination,
          data: [...old.data, ...res.data],
        }));
      } catch (err) {
        const error = err as Error;
        logErrorCtx('Paginated Locations Not Fetched', {
          error,
          stackTrace: error.stack,
          title: 'Failed to Fetch Paginated Locations from API',
          description: 'BuldWizard Failed to Fetch Paginated Locations',
          component: 'LocationsStep',
        });
      }
    },
    [search]
  );

  const handleLocationsNextPage = useCallback(async () => {
    const { pagination } = searchedLocations;

    if (pagination.totalRows <= pagination.pageSize * pagination.page) {
      return;
    }

    const newPagination: Pagination = {
      ...pagination,
      page: pagination.page + 1,
    };

    await getLocationsWithPagination(newPagination);
  }, [searchedLocations, getLocationsWithPagination]);

  return (
    <Box display="flex" flexGrow={1} flexDirection="column" overflow="hidden">
      <Box p={2}>
        <Typography variant="body2" color="textPrimary">
          Select move from location.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box width="49%">
          <TitleBar noBorderRadius>
            <TextField
              placeholder="Search"
              className={classes.searchInputOuter}
              value={search}
              onChange={(ev) => setSearch(ev.target.value || null)}
              dataQa="bulk-move-search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                classes: {
                  root: classes.searchInputInner,
                  notchedOutline: classes.noBorder,
                },
              }}
            />
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="small"
              icon={IconNames.FBOAddCircle}
              onClick={addAllClicked}
              data-qa="bulk-move-add-all"
            >
              All
            </FBOButton>
          </TitleBar>
        </Box>
        <Box width="50%">
          <TitleBar title="Locations" noBorderRadius>
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="small"
              icon={IconNames.SubCircle}
              onClick={removeAllClicked}
              data-qa="bulk-move-remove-all"
            >
              All
            </FBOButton>
          </TitleBar>
        </Box>
      </Box>

      <Box
        display="flex"
        width="100%"
        flex={1}
        overflow="hidden"
        justifyContent="space-between"
      >
        <Box className={classes.tableColumn}>
          <ItemsTable
            data={searchedLocations.data}
            columns={availableLocationsColumns(
              searchedLocations.data.length,
              searchedLocations.pagination.totalRows
            )}
            selectableItems={false}
            RenderCustomRow={LocationRow}
            meta={{ searchRow: true }}
            onItemClick={searchRowClicked}
            isLoadingData={fetchLoading}
            onScrollNextPage={handleLocationsNextPage}
            dataQa="bulk-move-left"
          />
        </Box>
        <Box className={classes.tableColumnPicked}>
          <ItemsTable
            data={selectedLocations}
            columns={selectedLocationsColumns(selectedLocations.length)}
            selectableItems={false}
            RenderCustomRow={LocationRow}
            onItemClick={selectedRowClicked}
            emptyTableText="ADD LOCATION BY SELECTING FROM THE LEFT TABLE"
            dataQa="bulk-move-right"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LocationsStep;
