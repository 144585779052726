import { createApiCall } from 'services/api';

export const fetchAmazonUrl = async (): Promise<string> => {
  const response = await createApiCall({
    path: `/integrations/amazon/auth`,
    method: 'GET',
  })();

  return response.data;
};
