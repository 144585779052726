import React, { memo, useContext } from 'react';

import { EcommerceCard, EcommerceWizard } from './components';
import { EcommerceProps } from './types';
import { EcommerceContext } from '../../EcommerceProvider';

const Ecommerce: React.FC<EcommerceProps> = () => {
  const [, , , , , isConnected, , settingsExtended] =
    useContext(EcommerceContext);

  if (!isConnected) {
    return null;
  }

  return (
    <>
      {settingsExtended.map((s, i) => {
        return s.isSetupNeeded ? (
          <EcommerceWizard key={i} index={i} />
        ) : (
          <EcommerceCard key={i} index={i} />
        );
      })}
    </>
  );
};

export default memo(Ecommerce);
