import React from 'react';
import { Box } from '@mui/material';

import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import { IconNames, colorPalette } from 'ui/theme';

import Icon from 'ui/components/Icon/Icon';

export const fboAvailableLocationsColumns = (
  locationAmount: number
): Column[] => [
  {
    title: `${locationAmount} Locations`,
    type: ColumnTypes.string,
    field: 'name',
  },
  {
    title: '',
    type: ColumnTypes.custom,
    width: 24,
    render: () => {
      return (
        <Box
          display="flex"
          alignItems="center"
          width={24}
          height="100%"
          color={colorPalette.fishbowlCorporateGray3}
        >
          <Icon
            name={IconNames.FBOAddCircle}
            color={colorPalette.redesign.contentPrimary}
            style={{ width: '24px', padding: '2px' }}
          />
        </Box>
      );
    },
  },
];

export const fboSelectedLocationsColumns: Column[] = [
  {
    title: ``,
    type: ColumnTypes.string,
    field: 'name',
  },
  {
    title: '',
    type: ColumnTypes.custom,
    width: 24,
    render: () => {
      return (
        <Box
          display="flex"
          alignItems="center"
          width={24}
          height="100%"
          color={colorPalette.fishbowlCorporateGray3}
        >
          <Icon
            name={IconNames.SubCircle}
            color={colorPalette.redesign.contentPrimary}
            style={{ width: '24px', padding: '2px' }}
          />
        </Box>
      );
    },
  },
];
