import _ from 'lodash';

import { SalesOrder, transformSalesOrderItem } from 'services/salesOrders';
import { transformItem } from 'services/items';
import { transformTrackingGroupDto } from 'services/inventory';
import { transformPurchaseOrderItem } from 'services/purchaseOrders';
import { transformDateToMomentDate } from 'helpers';
import {
  ObjectType,
  transformCustomField,
  transformToRequestCustomField,
} from 'services/customFields';

import {
  DisplayPick,
  Pick,
  PickBundleItem,
  PickItem,
  PickItemStatus,
} from './types';
import { pickItemIsInventoryType } from './helpers';

export const transformPick = (res: any): Pick & { salesOrder?: SalesOrder } => {
  const pickBundleItems = res.pickBundleItems
    ? res.pickBundleItems.map((pickBundleItem: PickItem) => {
        const pickBundleItemWithParentId = {
          ...pickBundleItem,
          pickId: res.id,
        };
        return transformPickBundleItem(pickBundleItemWithParentId);
      })
    : [];
  const bundlePickItems = pickBundleItems
    .map((bi: PickBundleItem) => bi.pickItems)
    .flat();

  // order pick items by location sort number
  const pickItems = _.orderBy(
    [
      ...(res.pickItems || []).map((pickItem: PickItem) => {
        const pickItemWithPickId = {
          ...pickItem,
          pickId: res.id,
        };

        return transformPickItem(pickItemWithPickId);
      }),
      ..._.uniqBy(bundlePickItems, 'id'),
    ],
    (i: PickItem) => {
      if (i.pickFromLocation) {
        return i.pickFromLocation.sortNumber;
      }

      return 0;
    },
    ['asc']
  );

  const salesOrders = res.salesOrders || [];
  const shipments = res.shipments || [];
  const purchaseOrders = res.purchaseOrders || [];
  const customFields = res.customFields || [];

  return {
    id: res.id,
    salesOrder: res.salesOrder,
    customFields: customFields.map(transformCustomField),
    dateCreated: transformDateToMomentDate(res.dateCreated),
    dateLastModified: transformDateToMomentDate(res.dateLastModified),
    notes: res.notes,
    number: res.number,
    orderPriorityId: res.orderPriorityId,
    parentLocationId: res.parentLocationId,
    parentLocation: res.parentLocation,
    pickId: res.id,
    pickItems,
    pickBundleItems,
    purchaseOrders,
    salesOrders,
    scheduledDate: transformDateToMomentDate(res.dateScheduled),
    shipments,
    status: res.status,
    stockStatus: res.stockStatus,
    userId: res.userId,
    version: res.version,
    deleted: res.deleted,
  };
};

// Picks trimmed down for main picking page
export const transformDisplayPick = (res: any): DisplayPick => {
  return {
    id: res.id,
    number: res.number,
    orderPriority: res.orderPriority,
    parentLocation: res.parentLocation,
    purchaseOrderId: res.purchaseOrderId,
    salesOrderId: res.salesOrderId,
    status: res.status,
    stockStatus: res.stockStatus,
    customer: res.customer,
    dateCreated: transformDateToMomentDate(res.created),
    dateLastModified: transformDateToMomentDate(res.modified),
    scheduledDate: transformDateToMomentDate(res.scheduled),
  };
};

export const transformToUpdatePick = (pick: Pick): any => ({
  id: pick.id,
  version: pick.version,
  parentLocationId: pick.parentLocationId,
  dateScheduled: pick.scheduledDate,
  orderPriorityId: pick.orderPriorityId,
  userId: pick.userId,
  notes: pick.notes,
  deleted: pick.deleted,
  customFields: pick.customFields.map((f) =>
    transformToRequestCustomField(f, ObjectType.Pick, true)
  ),
});

export const transformPickBundleItem = (res: any): PickBundleItem => ({
  id: res.id,
  version: res.version,
  item: null,
  itemId: res.itemId,
  pickId: res.pickId,
  pickItems: res.pickItems.map((pi: any) => ({
    ...transformPickItem(pi),
    bundleId: res.id,
  })),
});

export const transformPickItem = (res: any): PickItem => {
  const pickItem: PickItem = {
    id: res.id,
    amount: null,
    autoAssign: false,
    bundleId: null,
    dateCreated: transformDateToMomentDate(res.dateCreated),
    dateLastModified: res.dateLastModified,
    dateStarted: transformDateToMomentDate(res.dateStarted),
    fromLocationQuantityAvailable: res.fromLocationQuantityAvailable,
    inventoryEventId: res.inventoryEventId,
    item: res.item ? transformItem(res.item) : null,
    itemId: res.itemId,
    notes: res.notes,
    pickFromLocationId: res.pickFromLocationId,
    pickId: res.pickId,
    pickToLocation: res.pickToLocation,
    pickToLocationId: res.pickToLocationId,
    pickFromLocation: res.pickFromLocation,
    purchaseOrderItem: res.purchaseOrderItem
      ? transformPurchaseOrderItem(res.purchaseOrderItem)
      : null,
    purchaseOrderItemId: res.purchaseOrderItemId,
    quantity: res.quantity,
    quantityAvailable: res.quantityAvailable,
    salesOrderItem: res.salesOrderItem
      ? transformSalesOrderItem(res.salesOrderItem)
      : null,
    salesOrderItemId: res.salesOrderItemId,
    shipId: res.shipId,
    slotNumber: res.slotNumber,
    status: res.status,
    trackingGroupList: (res.trackingGroupObjects || []).map(
      transformTrackingGroupDto
    ),
    uomId: res.uomId,
    version: res.version,
  };

  // custom statuses
  if (pickItem.status === PickItemStatus.Available && pickItem.dateStarted) {
    pickItem.status = PickItemStatus.Started;
  } else if (
    (pickItem.quantity || 0) > (pickItem.quantityAvailable || 0) &&
    pickItemIsInventoryType(pickItem) &&
    pickItem.status === PickItemStatus.Available
  ) {
    pickItem.status = PickItemStatus.Short;
  }

  return pickItem;
};

export const transformToCommitPickItem = (pickItem: PickItem) => {
  if (!pickItemIsInventoryType(pickItem)) {
    return {
      pickItemId: pickItem.id,
      quantity: pickItem.amount,
      notes: pickItem.notes,
      pickBundleItemId: pickItem.bundleId,
    };
  }

  const payload: any = {
    pickItemId: pickItem.id,
    quantity: pickItem.amount,
    notes: pickItem.notes,
    pickBundleItemId: pickItem.bundleId,
  };

  if (!pickItem.autoAssign) {
    payload.pickFromLocationId = pickItem.pickFromLocationId;
    payload.trackingGroupObjects = pickItem.trackingGroupList
      .filter((row) => row.quantity)
      .map((row) => {
        return {
          quantity: row.quantity!,
          trackingGroupInfos: row.trackingInfoList.map((i) => ({
            id: i.id,
            trackingTypeId: i.trackingTypeId,
            value: i.value!,
          })),
          serialInputs: [],
          serialIds: row.serialIds,
        };
      });
  }

  return payload;
};

export const transformToUpdatePickItem = (pickItem: PickItem): any => {
  if (!pickItemIsInventoryType(pickItem)) {
    return {
      id: pickItem.id,
      version: pickItem.version,
      notes: pickItem.notes,
      slotNumber: pickItem.slotNumber,
    };
  }

  return {
    id: pickItem.id,
    version: pickItem.version,
    pickFromLocationId: pickItem.pickFromLocationId,
    pickToLocationId: pickItem.pickToLocationId,
    notes: pickItem.notes,
    slotNumber: pickItem.slotNumber,
  };
};
