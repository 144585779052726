import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Switch, Typography } from '@mui/material';

import {
  fetchRepresentatives,
  getRepresentatives,
  Representative,
} from 'services/representatives';
import {
  fetchOrderPriorities,
  getOrderPriorities,
  OrderPriority,
} from 'services/settings/orderPriorities';
import {
  fetchPaymentTerms,
  getPaymentTerms,
  PaymentTerm,
} from 'services/paymentTerms';
import { Customer, fetchCustomers, getCustomers } from 'services/customers';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { RepresentativesAutocomplete } from 'ui/components/Autocomplete/RepresentativesAutocomplete';
import { CustomersAutocomplete } from 'ui/components/Autocomplete/CustomerAutocomplete';

import { SettingsFormProps } from '../../types';
import { useEcommerceCardStyle } from '../../../../styled';
import useEcommerceSettings from '../../../../useEcommerceSettings';
const CustomerSettingsForm: React.FC<SettingsFormProps> = (props) => {
  const dispatch = useDispatch();
  const classes = useEcommerceCardStyle();

  const { index } = props;

  const [setting, setEcommerceSettings, errors, , , , channelName] =
    useEcommerceSettings(index);

  const [customerSettings, setCustomerSettings] = useState(setting);

  const { items: representatives } = useSelector(getRepresentatives);
  const { items: orderPriorities } = useSelector(getOrderPriorities);
  const { items: customers } = useSelector(getCustomers);
  const { items: paymentTerms } = useSelector(getPaymentTerms);

  const selectedOrderPriority = useMemo(() => {
    return (
      orderPriorities.find(
        (p) => p.id === customerSettings.customer.priority
      ) || null
    );
  }, [orderPriorities, customerSettings.customer.priority]);

  const selectedRepresentative = useMemo(() => {
    return (
      representatives.find(
        (r) => r.id === customerSettings.customer.representative
      ) || null
    );
  }, [representatives, customerSettings.customer.representative]);

  const selectedCustomer = useMemo(() => {
    return (
      customers.find((c) => c.id === customerSettings.customer.customer) || null
    );
  }, [customers, customerSettings.customer.customer]);

  const selectedPaymentTerm = useMemo(
    () =>
      paymentTerms.find(
        (p) => p.id === customerSettings.customer.paymentTerms
      ) || null,
    [customerSettings.customer.paymentTerms, paymentTerms]
  );

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchRepresentatives());
    // @ts-ignore
    dispatch(fetchOrderPriorities());
    // @ts-ignore
    dispatch(fetchCustomers());
    // @ts-ignore
    dispatch(fetchPaymentTerms());
    // react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (customerSettings.id) {
      setEcommerceSettings(customerSettings);
    }
  }, [customerSettings, setEcommerceSettings, index]);

  const handleAutoCompleteChange = useCallback(
    (name: 'representative' | 'priority' | 'customer' | 'paymentTerms') =>
      (v: Representative | OrderPriority | Customer | PaymentTerm | null) => {
        setCustomerSettings((old) => ({
          ...old,
          customer: { ...old.customer, [name]: v ? v.id : null },
        }));
      },
    [setCustomerSettings]
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setCustomerSettings((old) => ({
        ...old,
        customer: {
          ...old.customer,
          useDefault: checked,
          paymentTerms: checked ? old.customer.paymentTerms : null,
        },
      }));
    },
    [setCustomerSettings]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      height="100%"
      overflow="auto"
    >
      <Box className={classes.modalRowExpandable}>
        <Box className={classes.modalRow}>
          <Typography variant="body1" color="textPrimary">
            Default customer
          </Typography>

          <Box width="40%">
            <CustomersAutocomplete
              onChange={(v: Customer | null) =>
                handleAutoCompleteChange('customer')(v)
              }
              placeholder="Select customer"
              value={selectedCustomer}
              error={!!errors['customer']}
            />
          </Box>
        </Box>
        <Box className={classes.modalRow}>
          <Typography variant="body1" color="textPrimary">
            Use {channelName} Customer When Available
          </Typography>

          <Switch
            checked={customerSettings.customer.useDefault}
            onChange={handleCheckboxChange}
            name="useDefault"
            color="primary"
          />
        </Box>

        <Divider />
      </Box>

      {customerSettings.customer.useDefault && (
        <>
          <Box className={classes.modalRowExpandable}>
            <Box className={classes.modalRow}>
              <Typography variant="body1" color="textPrimary">
                Default payment terms
              </Typography>

              <Box width="40%">
                <Autocomplete
                  options={paymentTerms}
                  value={selectedPaymentTerm}
                  getOptionLabel={(option: PaymentTerm) => option.name || ''}
                  onChange={(e: React.ChangeEvent<{}>, v: PaymentTerm | null) =>
                    handleAutoCompleteChange('paymentTerms')(v)
                  }
                  placeholder="Select payment term"
                  error={!!errors['paymentTerms']}
                />
              </Box>
            </Box>
          </Box>

          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Priority
            </Typography>

            <Box width="40%">
              <Autocomplete
                options={orderPriorities}
                value={selectedOrderPriority}
                getOptionLabel={(option: OrderPriority) => option.name}
                onChange={(e: React.ChangeEvent<{}>, v: OrderPriority | null) =>
                  handleAutoCompleteChange('priority')(v)
                }
                placeholder="Select priority"
                error={!!errors['priority']}
              />
            </Box>
          </Box>

          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Sales Representative
            </Typography>

            <Box width="40%">
              <RepresentativesAutocomplete
                value={selectedRepresentative}
                placeholder="Select representative"
                onChange={(v: Representative | null) =>
                  handleAutoCompleteChange('representative')(v)
                }
                error={!!errors['representative']}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default memo(CustomerSettingsForm);
