import React, { memo, useCallback, useMemo, useEffect } from 'react';
import { Box, Link } from '@mui/material';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { Modal } from 'ui/components/Modal/Modal';
import { initialItemReorderPointsAlert } from 'services/items/consts';
import { findNextNegativeId, replaceValueInCollection } from 'helpers';

import { AlertsModalProps } from './types';
import { ALERTS_COLUMNS } from './consts';
import { useAddRowAlertModal } from './styled';
import AlertsRow from './AlertsRow';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

const AlertsModal: React.FC<AlertsModalProps> = (props) => {
  const { modalVisible, onClose, onApply, alerts, setAlerts } = props;

  const classes = useAddRowAlertModal();

  const oldAlerts = useMemo(() => {
    return alerts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  useEffect(() => {
    if (modalVisible && alerts.length === 0) {
      setAlerts([
        { ...initialItemReorderPointsAlert, id: findNextNegativeId(alerts) },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  const handleDelete = useCallback(
    (index: number) => {
      setAlerts(
        replaceValueInCollection(
          alerts,
          { ...alerts[index], deleted: true },
          index
        )!
      );
    },
    [alerts, setAlerts]
  );

  const handleAddClicked = useCallback(() => {
    setAlerts([
      ...alerts,
      { ...initialItemReorderPointsAlert, id: findNextNegativeId(alerts) },
    ]);
  }, [alerts, setAlerts]);

  const handleResetClicked = useCallback(() => {
    setAlerts(oldAlerts);
  }, [oldAlerts, setAlerts]);

  return (
    <Modal
      open={modalVisible}
      onCancelClicked={onClose}
      fullWidth
      withoutDefaultPadding
      onApplyClicked={onApply}
      onResetClicked={handleResetClicked}
      applyLabel={oldAlerts.length ? 'Update' : 'Add'}
      title={'Manage Alerts'}
    >
      <ItemsTable
        data={alerts}
        filterRows={(alert) => !alert.deleted}
        columns={ALERTS_COLUMNS}
        RenderCustomRow={AlertsRow}
        selectableItems={false}
        tableBordered
        onAction={handleDelete}
        setData={setAlerts}
      />
      <Box display="flex" alignItems="center">
        <FBOButton
          variant="tertiary"
          color="neutral"
          size="medium"
          icon={IconNames.AddCircle}
          onClick={handleAddClicked}
          data-qa="alerts-modal-add-row-button"
        />
        <Link
          onClick={handleAddClicked}
          className={classes.buttonLink}
          underline="always"
          variant="body2"
          color="textPrimary"
        >
          Add Row
        </Link>
      </Box>
    </Modal>
  );
};

export default memo(AlertsModal);
