import React, { useState } from 'react';
import { createFilterOptions } from '@mui/material';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';

import { StateAutocompleteProps, StateType } from './types';
import { STATES } from './consts';

const StateAutocomplete = (props: StateAutocompleteProps) => {
  const {
    label = 'State',
    value,
    disabled,
    required,
    onChange,
    placeholder,
    helperText,
    error,
  } = props;

  const [searchValue, setSearchValue] = useState<string | null>(null);

  const filterOptions = createFilterOptions({
    stringify: (option: StateType) => {
      if (searchValue && searchValue.length === 2) {
        return option.abbreviation;
      }
      return option.label + option.abbreviation;
    },
  });

  const handleAutocompleteInputChange = (
    e: any,
    value: string,
    reason: string
  ) => {
    if (reason === 'input') {
      setSearchValue(value || null);
      return;
    }

    setSearchValue(null);
  };

  return (
    <Autocomplete
      value={value}
      disabled={disabled}
      options={STATES}
      autoHighlight
      filterOptions={filterOptions}
      getOptionLabel={(option: StateType) => option.abbreviation}
      renderOption={(StateAutocompleteProps, option: StateType) => (
        <li {...StateAutocompleteProps}>
          <span style={{ marginLeft: 8 }}>
            {option.label} ({option.abbreviation})
          </span>
        </li>
      )}
      label={label}
      name="state"
      placeholder={placeholder}
      onInputChange={handleAutocompleteInputChange}
      required={required}
      onChange={onChange}
      helperText={helperText}
      error={error}
    />
  );
};

export default StateAutocomplete;
