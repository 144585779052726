import { createApiAction } from 'services/api';
import {
  StartAction,
  SuccessAction,
  FailureAction,
} from 'services/api/redux/types';

export enum QuickbooksDesktopAccountActionTypes {
  FETCH_QBD_ACCOUNT_START = 'qbd/fetch_qbd_account_start',
  FETCH_QBD_ACCOUNT_SUCCESS = 'qbd/fetch_qbd_account_success',
  FETCH_QBD_ACCOUNT_FAILURE = 'qbd/fetch_qbd_account_failure',
}

const fetchQuickbooksDesktopAccountActionStart = (): StartAction => ({
  type: QuickbooksDesktopAccountActionTypes.FETCH_QBD_ACCOUNT_START,
  payload: null,
});

const fetchQuickbooksDesktopAccountActionSuccess = (
  payload: any
): SuccessAction => ({
  type: QuickbooksDesktopAccountActionTypes.FETCH_QBD_ACCOUNT_SUCCESS,
  payload,
});

const fetchQuickbooksDesktopAccountActionFailure = (
  error: string
): FailureAction => ({
  type: QuickbooksDesktopAccountActionTypes.FETCH_QBD_ACCOUNT_FAILURE,
  payload: { error },
});

export const fetchQuickbooksDesktopActiveAccount = () =>
  createApiAction(
    {
      path: '/integrations/qbd_accounts',
      method: 'GET',
    },
    fetchQuickbooksDesktopAccountActionStart,
    fetchQuickbooksDesktopAccountActionSuccess,
    fetchQuickbooksDesktopAccountActionFailure
  );
