import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { Page } from 'ui/components/Page/Page';
import { Pagination } from 'services/search';

import { ChannelDetailsCard, ChannelTable } from './components';
import { initialPagination } from './consts';
import { MyChannelsPageCmp } from './types';
import { Routes } from '../../navigation';
import { Channel, getChannels } from 'services/commerce/channels';
import { logErrorCtx } from 'app/logging';
import { GlobalSettingsBanner } from '../../components/GlobalSettingsBanner';
import {
  getCommerceAccountIsConfigured,
  getCommerceAccountIsDisconnected,
  getCommerceAccountIsOrWasConnected,
} from 'services/commerce';
import { useSelector } from 'react-redux';
import { getActiveUser } from 'services/user';
import { getSellwareUrl } from '../DiscoverChannels/helpers';
import { ReconnectBanner } from '../../components/ReconnectBanner';
import { useUrlQueryObject } from 'services/url';
import { getSettingsCompany } from 'services/settings/company';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MyChannelsPage: MyChannelsPageCmp = () => {
  const [channels, setChannels] = useState<Channel[]>([]);
  const [activeId, setActiveId] = useState<number | null>(null);
  const [pagination, setPagination] = useState<Pagination>(initialPagination);
  const [loadingChannels, setLoadingChannels] = useState(false);
  const [sellwareUrlForReconnectFlow, setSellwareUrlForReconnectFlow] =
    useState<string>('');

  const [, extendUrlQuery] = useUrlQueryObject();

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const tempActiveId = queryParams?.get('activeId');
    if (tempActiveId) {
      setActiveId(Number(tempActiveId));
    } else {
      setActiveId(null);
    }
  }, [queryParams]);

  // reorder data when pagination sort changed
  useEffect(() => {
    setChannels(
      _.orderBy(channels, pagination.sort.sortBy, pagination.sort.direction)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.sort]);

  useEffect(() => {
    getMyChannels();
  }, []);

  const getMyChannels = useCallback(async () => {
    setLoadingChannels(true);

    try {
      const res = await getChannels();
      setChannels(
        _.orderBy(res, pagination.sort.sortBy, pagination.sort.direction)
      );
    } catch (e) {
      logErrorCtx('Error getting Commerce Channels', {
        error: e as Error,
        component: 'MyChannelsPage',
      });
    }

    setLoadingChannels(false);
  }, [pagination]);

  const handleClose = useCallback(() => {
    extendUrlQuery({ activeId: null });
  }, [extendUrlQuery]);

  const isSellwareConfigured = useSelector(getCommerceAccountIsConfigured);

  const isSellwareDisconnected = useSelector(getCommerceAccountIsDisconnected);

  const isSellwareConnected = useSelector(getCommerceAccountIsOrWasConnected);

  const activeUser = useSelector(getActiveUser);

  const companySettings = useSelector(getSettingsCompany);
  const companyName = _.get(companySettings, 'name', '');

  const showReconnectBanner = isSellwareDisconnected;

  const showGlobalSettingsBanner =
    !isSellwareConfigured && !showReconnectBanner && isSellwareConnected;

  useEffect(() => {
    const getSellwareUrlForReconnectFlow = async () => {
      const url = await getSellwareUrl(activeUser, companyName, false, true);
      setSellwareUrlForReconnectFlow(url);
    };

    if (showReconnectBanner && activeUser && companyName) {
      getSellwareUrlForReconnectFlow();
    }
  }, [activeUser, companyName, showReconnectBanner]);

  const handleChannelClick = useCallback(
    async (channelId: number) => {
      extendUrlQuery({ activeId: channelId });
    },
    [extendUrlQuery]
  );

  return (
    <>
      {showReconnectBanner && (
        <ReconnectBanner sellwareUrl={sellwareUrlForReconnectFlow} />
      )}
      {showGlobalSettingsBanner && <GlobalSettingsBanner />}
      <Page>
        <PaperSlidingLayout shown={Boolean(activeId)}>
          <ChannelTable
            items={channels}
            activeItemId={activeId}
            handleItemClick={handleChannelClick}
            isLoadingItems={loadingChannels}
            pagination={pagination}
            onPaginationChange={setPagination}
          />
          <ChannelDetailsCard
            activeItemId={activeId}
            channels={channels}
            onClose={handleClose}
            fetchChannels={getMyChannels}
          />
        </PaperSlidingLayout>
      </Page>
    </>
  );
};

MyChannelsPage.route = Routes.MyChannelsPage;

export default MyChannelsPage;
