import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import { Page } from 'ui/components/Page/Page';
import {
  fetchSettingsCompanies,
  getSettingsCompany,
} from 'services/settings/company/redux';
import {
  ExportHistory,
  getHistory,
  getHistoryDetails,
} from 'services/accounting';

import {
  AccountingSettingsPageCmp,
  AccountingSettingsPageProps,
} from './types';
import { Routes } from '../../navigation';
import {
  CardHistory,
  CardNavigation,
  CardPreviousExport,
  CardWaitingToExport,
  MappingModal,
} from './components';
import { useAccountingSettingsPageStyle } from './styled';

const QbdAccountingSettingsPage: AccountingSettingsPageCmp = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: AccountingSettingsPageProps
) => {
  const classes = useAccountingSettingsPageStyle();

  const dispatch = useDispatch();

  const companySettings = useSelector(getSettingsCompany);

  const [search, setSearch] = useState<string>('');
  const [showMappingModal, setShowMappingModal] = useState(false);
  const [history, setHistory] = useState<ExportHistory[]>([]);
  const [historyDetails, setHistoryDetails] = useState<ExportHistory[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const historyResp = await getHistory();
        setHistory(historyResp.data);
        if (!_.isEmpty(historyResp)) {
          const historyDetailsResp = await getHistoryDetails(
            historyResp.data[0].id
          );
          setHistoryDetails(historyDetailsResp.data);
        }
      } catch (e) {
        // Ignore error
      }
    })();
  }, []);

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchSettingsCompanies());
  }, [dispatch]);

  const handleMappingClicked = useCallback(() => {
    setShowMappingModal(true);
  }, []);

  return (
    <>
      <Page direction="row">
        <CardNavigation
          search={search}
          setSearch={setSearch}
          onMappingClicked={handleMappingClicked}
        />
        <Box className={classes.cardContainer}>
          <Box className={classes.companyNameContainer}>
            <Typography
              align="left"
              variant="h4"
              className={classes.companyName}
            >
              {companySettings.name}
            </Typography>
          </Box>
          <CardWaitingToExport onMappingClicked={handleMappingClicked} />
          <CardPreviousExport historyDetails={historyDetails} />
          <CardHistory history={history} />
        </Box>
      </Page>
      <MappingModal
        open={showMappingModal}
        onClose={() => setShowMappingModal(false)}
      />
    </>
  );
};

QbdAccountingSettingsPage.route = Routes.QbdAccountingSettingsPage;

export default QbdAccountingSettingsPage;
