import React, { memo, useCallback, useState } from 'react';

import { Grid, Box } from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { QuillTextEditor } from 'ui/components/TextField/QuillTextEditor';

import { useHandleTextFieldChange } from 'services/forms';
import { Errors, validateYup } from 'services/forms/validation';
import { getReportBlob } from 'services/reports';
import { showNotification } from 'services/api';
import { Email, EmailModalProps } from 'services/email/types';
import { yupEmailModalSchema } from 'services/email/validations';
import { sendEmail } from 'services/email/api';

import { initialEmail } from '../../../../services/email/consts';
import { DownloadFileType } from 'ui/components/ReportsViewer/components/ReportsViewerContent/consts';
import { isPdfDisabled } from 'ui/components/ReportsViewer/components/PdfViewer/helpers';

const EmailModal: React.FC<EmailModalProps> = (props) => {
  const { show, reportId, parameters, onClose, reportTheme, reportVersion } =
    props;

  const [email, setEmail] = useState<Email>(initialEmail);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  const handleTextFieldchange = useHandleTextFieldChange(setEmail, email);

  const handleQuillTextChange = useCallback((content: string) => {
    setEmail((old) => ({ ...old, body: content }));
  }, []);

  const fetchReportBlob = async () => {
    if (isPdfDisabled(reportId)) {
      const blob = await getReportBlob(
        reportId!,
        parameters,
        reportTheme,
        DownloadFileType.Excel,
        reportVersion
      );
      return blob;
    }
    const blob = await getReportBlob(
      reportId!,
      parameters,
      reportTheme,
      undefined,
      reportVersion
    );

    return blob;
  };

  const handleApplyClicked = async () => {
    const isValid = validateYup(email, yupEmailModalSchema, setErrors);
    if (!isValid) {
      return;
    }

    setLoading(true);

    let file = new Blob();

    if (reportId && parameters) {
      try {
        file = await fetchReportBlob();
      } catch {
        showNotification(
          'Something went wrong while generating report. Please try again.',
          { variant: 'error' }
        );

        setLoading(false);
        return;
      }
    }

    try {
      await sendEmail(
        email,
        reportId ? file : undefined,
        isPdfDisabled(reportId)
      );
    } catch {
      setLoading(false);
      return;
    }

    setLoading(false);
    onClose();
  };

  if (!show) {
    return null;
  }

  console.log('parameters', parameters);

  return (
    <Modal
      open={show}
      onClose={onClose}
      title="Send Email"
      applyLabel="Send"
      onApplyClicked={handleApplyClicked}
      onCancelClicked={onClose}
      withoutDefaultPadding
      footerDivider="shadow"
      customHeight={575}
      maxWidth="sm"
      isLoadingContent={loading}
      disableEnter={true}
    >
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="To"
              name="to"
              placeholder="Enter email address"
              onChange={handleTextFieldchange}
              value={email.to ?? parameters.email?.to}
              required
              error={!!errors.to}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Cc"
              name="cc"
              placeholder="Enter email address"
              onChange={handleTextFieldchange}
              value={email.cc ?? parameters.email?.cc}
              error={!!errors.cc}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Bcc"
              name="bcc"
              placeholder="Enter email address"
              onChange={handleTextFieldchange}
              value={email.bcc ?? parameters.email?.bcc}
              error={!!errors.cc}
              helperText={errors.cc}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              name="subject"
              label="Subject"
              placeholder="Subject"
              onChange={handleTextFieldchange}
              value={email.subject}
              required
              error={!!errors.subject}
            />
          </Grid>
          <Grid item xs={12}>
            <QuillTextEditor
              placeholder="Message"
              onChange={handleQuillTextChange}
              value={email.body || ''}
              error={!!errors.body}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default memo(EmailModal);
