import { Tag } from 'services/tags';

export enum ChannelType {
  Shopify = 1,
  WooCommerce = 2,
  BigCommerce = 3,
  Amazon = 4,
  Magento = 5,
  Salesforce = 6,
  Ebay = 7,
  ShipStation = 8,
}

export enum FishbowlMappings {
  Name = 10,
  Description = 11,
  Details = 12,
  Sku = 13,
}

export enum EcommerceEventType {
  'Item Created' = 1,
  'Item Updated' = 2,
  'Item Deleted' = 4,
  'Inventory Updated' = 5,
  'Order Imported' = 6,
  'Order Fulfilled' = 7,
  'Order Deleted' = 8,
  'Order Exported' = 10,
}

export interface EcommerceChannel {
  id: number | null;
  channel: number | null;
  name: string | null;
  domain: string | null;
}

export enum EcommerceMappingsType {
  Title = 100,
  Body = 101,
  Vendor = 102,
  Type = 103,
  Tags = 104,
  Handle = 105,
  Sku = 106,
  Barcode = 107,
}

export interface AutocompleteMappings {
  id: string;
  value: string;
}

export interface KeyValue {
  [id: number]: number[];
}

export interface EcommerceCustomer {
  currency: number | null;
  customer: number | null;
  paymentTerms: number | null;
  priority: number | null;
  representative: number | null;
  useDefault: boolean;
}

export interface EcommerceInventory {
  mapping: KeyValue;
  locations: number[];
  update: boolean;
  tags: Tag[];
  type: string | null;
}

export interface EcommerceFulfillment {
  mapping: KeyValue;
  locations: number[];
  update: boolean;
  updateCost: boolean;
}

export interface EcommerceItem {
  dimensionsUnit: string | null;
  newName: AutocompleteMappings | null;
  newDescription: AutocompleteMappings | null;
  newSku: AutocompleteMappings | null;
  mapping: { [key: number]: string };
  type: string | null;
  fbItemType: string | null;
  createLinkItems: boolean;
  uom: number | null;
  weightUnit: string | null;
  update: boolean;
  create: boolean;
  class: number | null;
}

export interface EcommerceOrder {
  enabled: boolean | null;
  createPayments: boolean;
  location: number | null;
  paymentMethod: string | null;
  shippingItem: number | null;
  shippingTerms: number | null;
  status: string | null;
  defaultCarrier: number | null;
  useNumber: boolean;
  taxItem: number | null;
  class: number | null;
  prefix: string | null;
}

export interface EcommercePOS {
  class: string | null;
  createPayments: boolean;
  customer: number | null;
  location: number | null;
  paymentMethod: string | null;
  shippingItem: number | null;
  shippingTerms: number | null;
  useNumber: boolean;
  enabled: boolean;
  taxItem: number | null;
}

export interface EcommerceOrderExport {
  enabled: boolean;
  status: string | null;
  locations: KeyValue;
  customField1: string | null;
  customField2: string | null;
  customField3: string | null;
}

export interface EcommerceSettings {
  id: number | null;
  customer: EcommerceCustomer;
  fulfillment: EcommerceFulfillment;
  inventory: EcommerceInventory;
  item: EcommerceItem;
  order: EcommerceOrder;
  pos: EcommercePOS;
  channel: EcommerceChannel;
  orderExport: EcommerceOrderExport;
}

export interface EcommerceHistory {
  id: number | null;
  date: string;
  message: string | null;
  link: string | null;
  error: boolean;
  type: EcommerceEventType;
}

export interface EcommerceMappings {
  name: string | null;
  location: { [id: number]: string };
  item: {
    fishbowl: { [id: number]: string };
    channel: { [id: number]: string };
  };
  id: number | null;
  data: unknown;
  tenant: unknown;
  channel: unknown;
  error: unknown;
}

export interface EcommerceSettingsExtended {
  settings: EcommerceSettings;
  isSetupNeeded: boolean;
}

export interface MappingsWithIds {
  id: number;
  mappings: EcommerceMappings;
}

export interface InventoryType {
  name: string;
  code: string;
}
