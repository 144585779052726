import { UrlQueryObject } from 'services/url/types';

export interface ItemDetailsProps {
  itemId: number | null;
  onClose(): void;
  fetchSearchResult(): Promise<void>;
  clone: boolean;
  setClone: React.Dispatch<React.SetStateAction<boolean>>;
  urlQuery: ItemUrlQueryObject;
  extendUrlQuery: (updatedQueries: ItemUrlQueryObject) => void;
}

export enum ItemTab {
  General = 'general',
  SaleItems = 'sale-items',
  BundleItems = 'bundle-items',
  SubstituteItems = 'substitute-items',
  ReorderPoints = 'reorder-points',
  Tracking = 'tracking',
  Locations = 'locations',
  VendorItems = 'vendor-items',
  Documents = 'documents',
  History = 'history',
}

export interface ItemUrlQueryObject extends UrlQueryObject {
  activeTab?: ItemTab | null;
}
