import { ColumnTypes } from 'ui/components/Table/ItemsTable';
import { Column } from 'ui/components/Table/ItemsTable/types';
import { MenuItem, MenuItemVariant } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';
import { PermissionType } from 'services/permissions';
import { PurchaseOrder } from 'services/purchaseOrders';
import { Dates } from 'helpers';

import { PurchaseOrderPageAction } from './types';

export const PURCHASE_ORDER_COLUMNS = (date: Dates): Column[] => {
  const resolveDateColumn = (): Column => {
    switch (date) {
      case Dates.DateLastModified:
        return {
          title: 'Date Last Modified',
          field: Dates.DateLastModified,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      case Dates.DateIssued:
        return {
          title: 'Date Issued',
          field: Dates.DateIssued,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      case Dates.DateScheduled:
        return {
          title: 'Date Scheduled',
          field: Dates.DateScheduled,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      case Dates.DateFullfilled:
        return {
          title: 'Date Fulfilled',
          field: Dates.DateFullfilled,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      default:
        return {
          title: 'Date Created',
          field: Dates.DateCreated,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
    }
  };

  return [
    {
      title: 'No.',
      field: 'number',
      type: ColumnTypes.string,
      sortable: true,
      width: 85,
    },
    {
      title: 'Status',
      field: 'purchaseOrderStatus',
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Vendor',
      field: 'vendor.name',
      sortable: true,
      type: ColumnTypes.string,
      width: 250,
    },
    {
      title: 'Buyer',
      field: 'buyer.firstName',
      type: ColumnTypes.string,
      sortable: true,
    },
    resolveDateColumn(),
    {
      title: 'Order Type',
      field: 'orderType',
      sortable: false,
      searchable: false,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Total',
      field: 'grandTotal',
      sortable: true,
      type: ColumnTypes.string,
      searchable: false,
      align: 'right',
    },
    {
      title: '',
      field: '',
      type: ColumnTypes.string,
      searchable: false,
      width: 50,
    },
  ];
};

export const PURCHASE_ORDER_DETAILS_COLUMNS: Column[] = [
  {
    title: 'No.',
    field: 'number',
    type: ColumnTypes.string,
    searchable: false,
    width: 85,
  },
  { title: 'Status', type: ColumnTypes.renderByCustomRow },
  {
    title: 'Vendor',
    field: 'vendor.name',
    type: ColumnTypes.string,
  },
];

export const createActionBarOptions = (
  onDuplicateClick: () => void,
  onDeleteClicked: () => void,
  purchaseOrder: PurchaseOrder
): MenuItem[] => [
  {
    label: 'Duplicate Order',
    onOptionClick: onDuplicateClick,
    icon: IconNames.FBODuplicateSmall,
    permissions: [
      PermissionType.PurchaseOrderEdit,
      PermissionType.PurchaseOrderCreate,
    ],
    dataQa: `${purchaseOrder.number}-duplicate-order`,
    separateAfter: true,
  },
  {
    label: 'Delete Order',
    permissions: purchaseOrder.deleted
      ? [PermissionType.Undelete]
      : [PermissionType.PurchaseOrderDelete],
    onOptionClick: onDeleteClicked,
    icon: IconNames.FBODeleteSmall,
    dataQa: `${purchaseOrder.number}-delete-order`,
    variant: MenuItemVariant.Danger,
  },
];

export const createPOSearchActionBarOptions = (
  onActionClick: (actionType: PurchaseOrderPageAction, date?: Dates) => void,
  purcahseOrdersCount: number
): MenuItem[] => [
  {
    label: 'Show Report',
    onOptionClick: () => onActionClick(PurchaseOrderPageAction.ShowReport),
    icon: IconNames.FBOReportSmall,
    separateAfter: true,
    dataQa: 'show-report-button',
  },
  {
    label: 'Import Orders',
    onOptionClick: () => onActionClick(PurchaseOrderPageAction.Import),
    icon: IconNames.FBOImport,
    permissions: [
      PermissionType.PurchaseOrderEdit,
      PermissionType.PurchaseOrderCreate,
    ],
    dataQa: 'import-orders-button',
    separateAfter: true,
  },
  {
    label: 'Export Orders',
    onOptionClick: () => onActionClick(PurchaseOrderPageAction.Export),
    icon: IconNames.FBOExport,
    disabled: !purcahseOrdersCount,
    dataQa: 'export-orders-button',
    separateAfter: true,
  },
  {
    label: 'Custom Fields',
    icon: IconNames.FBOReportSmall,
    onOptionClick: () => onActionClick(PurchaseOrderPageAction.CustomFields),
    permissions: [
      PermissionType.PurchaseOrderEdit,
      PermissionType.PurchaseOrderCreate,
    ],
    dataQa: 'custom-fields-button',
    separateAfter: true,
  },
  {
    icon: IconNames.FBORecycleSmall,
    label: 'Change Dates',
    dataQa: 'change-dates-button',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(PurchaseOrderPageAction.ChangeDate, Dates.DateCreated),
        dataQa: 'date-created-button',
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(
            PurchaseOrderPageAction.ChangeDate,
            Dates.DateLastModified
          ),
        dataQa: 'date-last-modified-button',
        separateAfter: true,
      },
      {
        label: 'Date Issued',
        onOptionClick: () =>
          onActionClick(PurchaseOrderPageAction.ChangeDate, Dates.DateIssued),
        dataQa: 'date-issued-button',
        separateAfter: true,
      },
      {
        label: 'Date Scheduled',
        onOptionClick: () =>
          onActionClick(
            PurchaseOrderPageAction.ChangeDate,
            Dates.DateScheduled
          ),
        dataQa: 'date-scheduled-button',
        separateAfter: true,
      },
      {
        label: 'Date Fulfilled',
        onOptionClick: () =>
          onActionClick(
            PurchaseOrderPageAction.ChangeDate,
            Dates.DateFullfilled
          ),
        dataQa: 'date-fulfilled-button',
      },
    ],
  },
];
