import React, { memo } from 'react';
import { TableCell } from '@mui/material';
import _ from 'lodash';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { SalesOrder } from 'services/salesOrders';

import { useSalesOrderRowSmallStyle } from './styled';
import FBOStatusDot, {
  DefaultStatus,
} from 'ui/theme/components/FBOStatusDot/FBOStatusDot';

const SalesOrderRowSmall: React.FC<RenderCustomRowProps<SalesOrder>> = (
  props
) => {
  const { row } = props;

  const classes = useSalesOrderRowSmallStyle();

  return (
    <>
      <TableCell className={classes.sidebarBox}>
        {_.get(row, 'number', '-')}
      </TableCell>
      <FBOStatusDot status={row.status || DefaultStatus.Transparent} />
      <TableCell className={classes.sidebarBox}>
        {_.get(row, 'customer.name', '-')}
      </TableCell>
    </>
  );
};

export default memo(SalesOrderRowSmall);
