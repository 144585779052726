import React, { memo, useMemo, useCallback } from 'react';
import _ from 'lodash';
import { TableCell, Box, Typography } from '@mui/material';
import moment from 'moment';

import {
  SalesOrder,
  SalesOrderItemTypes,
  SalesOrderTableRowOrderType,
} from 'services/salesOrders';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { colorPalette } from 'ui/theme';
import { DateMessage } from 'ui/components/DateTooltip/types';
import { DateTooltip } from 'ui/components/DateTooltip';
import {
  useCurrencyFormatter,
  Dates,
  useGetIntlDateFormatString,
} from 'helpers';
import { StatusLabel } from 'ui/components/Status/StatusLabel';

import { createActionBarOptions } from './consts';
import { SalesOrderTableRowActions } from './types';
import { mergeSoItems } from './';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import { FBOStockStatusTag } from 'ui/theme/components/FBOStockStatusTag/FBOStockStatusTag';

const SalesOrderRow: React.FC<RenderCustomRowProps<SalesOrder>> = (props) => {
  const {
    row,
    columns,
    onAction,
    meta: { hasConnection },
  } = props;

  const currencyFormatter = useCurrencyFormatter();

  const handleDuplicate = useCallback(() => {
    if (onAction) {
      onAction({ type: SalesOrderTableRowActions.Duplicate, value: row.id });
    }
  }, [onAction, row.id]);

  const handleDelete = useCallback(() => {
    if (onAction) {
      onAction({ type: SalesOrderTableRowActions.Delete, value: row.id });
    }
  }, [onAction, row.id]);

  const representativeName = `${_.get(row, 'representative.firstName', '')} ${
    _.get(row, 'representative.lastName') || ''
  }`;

  const intlDateFormat = useGetIntlDateFormatString();

  const dateValue: string = useMemo(() => {
    const dateColumn = columns.find(
      (i) =>
        i.field === Dates.DateCreated ||
        i.field === Dates.DateIssued ||
        i.field === Dates.DateLastModified
    );
    const dateField = dateColumn!.field;
    const date = dateField ? _.get(row, dateField, null) : null;

    return date ? moment(date).format(intlDateFormat) : '';
  }, [columns, row, intlDateFormat]);

  const tooltipDates: DateMessage[] = useMemo(
    () => [
      { date: row.dateCreated, message: 'Date Created' },
      { date: row.dateIssued, message: 'Date Issued' },
      { date: row.dateLastModified, message: 'Date Last Modified' },
    ],
    [row]
  );

  const OrderType = useCallback(() => {
    const mergedSoItems = mergeSoItems(row.salesItems);

    if (!mergedSoItems.length) {
      return null;
    }

    const isCreditReturn = mergedSoItems.every(
      (type) => type.salesOrderItemType === SalesOrderItemTypes.CreditReturn
    );

    if (isCreditReturn) {
      return <StatusLabel status={SalesOrderTableRowOrderType.CreditReturn} />;
    }

    const isSale = mergedSoItems.every(
      (type) => type.salesOrderItemType === SalesOrderItemTypes.Sale
    );

    if (isSale) {
      return <StatusLabel status={SalesOrderTableRowOrderType.Sale} />;
    }

    const isDropShip = mergedSoItems.every(
      (type) => type.salesOrderItemType === SalesOrderItemTypes.DropShip
    );

    if (isDropShip) {
      return <StatusLabel status={SalesOrderTableRowOrderType.DropShip} />;
    }

    const isMixed = mergedSoItems.some(
      (type) =>
        type.salesOrderItemType === SalesOrderItemTypes.CreditReturn ||
        type.salesOrderItemType === SalesOrderItemTypes.Sale ||
        type.salesOrderItemType === SalesOrderItemTypes.DropShip
    );

    if (isMixed) {
      return <StatusLabel status={SalesOrderTableRowOrderType.Mixed} />;
    }

    return null;
  }, [row]);
  const SONumber = _.get(row, 'number', '-');

  return (
    <>
      <TableCell data-qa={`${SONumber}-No.`}>{SONumber}</TableCell>
      <TableCell data-qa={`${SONumber}-Stock.`}>
        <FBOStockStatusTag row={row} />
      </TableCell>
      <TableCell data-qa={`${SONumber}-Status`}>
        <StatusLabel status={row.status} />
      </TableCell>
      <TableCell data-qa={`${SONumber}-customer`}>
        {_.get(row, 'customer.name', '-')}
      </TableCell>
      <TableCell data-qa={`${SONumber}-sales-rep`}>
        {representativeName}
      </TableCell>
      <TableCell data-qa={`${SONumber}-location`}>
        {_.get(row, 'location.name', '-')}
      </TableCell>
      {hasConnection && (
        <TableCell data-qa={`${SONumber}-source`}>
          <Box>
            <Typography variant="body1" color="textPrimary">
              {row.channelSource}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {row.store}
            </Typography>
          </Box>
        </TableCell>
      )}
      <DateTooltip values={tooltipDates}>
        <TableCell data-qa={`${SONumber}-Date Created`}>{dateValue}</TableCell>
      </DateTooltip>
      <TableCell data-qa={`${SONumber}-Order Type`}>
        <OrderType />
      </TableCell>
      <TableCell align="right" data-qa={`${SONumber}-Total`}>
        {currencyFormatter(row.grandTotal || 0)}
      </TableCell>
      <TableCell align="right" data-qa={`${SONumber}-Paid Amount`}>
        {currencyFormatter(row.paymentTotal || 0)}
      </TableCell>
      <TableCell sx={{ padding: 0 }} align="center">
        <FBOMenuButton
          variant="tertiary"
          items={createActionBarOptions(handleDuplicate, handleDelete, row)}
          data-qa={`${SONumber}-three-dot-menu`}
          style={{ color: colorPalette.redesign.contentSecondary }}
        />
      </TableCell>
    </>
  );
};

export default memo(SalesOrderRow);
