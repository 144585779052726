import { DefinedRange } from 'materialui-daterange-picker';
import moment from 'moment';

import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const columns: Column[] = [
  {
    title: 'Calculated Reorder Points',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Forecasting',
    width: 160,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Daily Consumption Rate',
    type: ColumnTypes.renderByCustomRow,
    width: 160,
  },
  {
    title: 'Min',
    width: 160,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Max',
    width: 160,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Notify At',
    width: 160,
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Current Min/Max/Notify',
    type: ColumnTypes.renderByCustomRow,
    width: 160,
  },
  {
    title: '',
    width: 64,
    type: ColumnTypes.renderByCustomRow,
  },
];

export const customDateRange: DefinedRange[] = [
  {
    startDate: moment().startOf('week').toDate(),
    endDate: moment().endOf('week').toDate(),
    label: 'This Week',
  },
  {
    startDate: moment().add(-1, 'week').toDate(),
    endDate: moment().toDate(),
    label: 'Last Week',
  },
  {
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(),
    label: 'This Month',
  },
  {
    startDate: moment().startOf('month').add(-1, 'month').toDate(),
    endDate: moment().endOf('month').add(-1, 'month').toDate(),
    label: 'Last Month',
  },
  {
    startDate: moment().startOf('year').toDate(),
    endDate: moment().endOf('year').toDate(),
    label: 'Last Year',
  },
  {
    startDate: moment().add(-1, 'year').toDate(),
    endDate: moment().toDate(),
    label: '1 Year',
  },
  {
    startDate: moment().startOf('year').toDate(),
    endDate: moment().toDate(),
    label: 'Year to date',
  },
];
