import React, {
  useState,
  useEffect,
  useCallback,
  memo,
  useMemo,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs, Tab } from '@mui/material';
import _ from 'lodash';

import { TabPanel } from 'ui/components/TabPanel';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { ActiveItemIdState } from 'ui/components/Page/WithSearchResults';
import { ReportsModal } from 'ui/components/Modal/ReportsModal';
import {
  fetchItem,
  putItem,
  postItem,
  deleteItem,
  ItemType,
  postTrackingToExistingItem,
  transformToDefaultSaleItem,
  Item,
  restoreItem,
} from 'services/items';
import { Errors } from 'services/forms/validation';
import { DefaultSaleItemType, SaleItem } from 'services/items/saleItems';
import { initialItem } from 'services/items/consts';
import { copySaleItemsImages } from 'services/items/helpers';
import { hasPermission, PermissionType } from 'services/permissions';
import {
  getItemInventory,
  initialItemInventory,
  InventoryRow,
  ItemInventory,
} from 'services/inventory';
import { ReportId } from 'services/reports';
import { getUoms } from 'services/uoms';
import { getActiveUser } from 'services/user';
import { deleteDocuments } from 'services/documents/api';
import { showNotification } from 'services/api';
import {
  clearModuleNavigation,
  ModuleNavigationType,
  updateModuleNavigation,
} from 'services/moduleNavigation';
import { getErrorMessage, replaceValueInCollection } from 'helpers';
import { getSettingsCompany } from 'services/settings/company';
import { getSettingsPurchaseOrder } from 'services/settings/purchaseOrders';
import { getSettingsSalesOrder } from 'services/settings/salesOrders';

import { ItemDetailsProps, ItemTab } from './types';
import {
  transformToDuplicatedItem,
  transformToInitialItem,
} from './transformations';
import {
  SaleItemsTab,
  TrackingTab,
  LocationsTab,
  ReorderTab,
  ItemDocumentsTab,
} from './components';
import {
  copyItemImages,
  isItemDetailsCardValid,
  shouldShowCreateSaleItemModal,
  shouldShowSyncModal,
} from './helpers';
import { VendorItemsTab } from './components/VendorItemsTab';
import { SubstituteItemsTab } from './components/SubstituteItemsTab';
import { useItemDetailsSubstituteStyle } from './styled';
import { ItemTitleBar } from './components/ItemTitleBar';
import BundleItemsTab from './components/BundleItemsTab/BundleItemsTab';
import { FBOItemDialogActions } from './ItemDialogActions';
import { logErrorCtx } from 'app/logging';
import FBOGeneralTab from './components/GeneralTab/FBOGeneralTab';
import FBOTrackingWizard from './components/TrackingWizard/FBOTrackingWizard';

const ItemDetailsCard: React.FC<ItemDetailsProps> = (props) => {
  const {
    itemId,
    onClose,
    fetchSearchResult,
    clone,
    setClone,
    urlQuery,
    extendUrlQuery,
  } = props;

  const dispatch = useDispatch();

  const classes = useItemDetailsSubstituteStyle();

  const { items: uoms } = useSelector(getUoms);
  const poSettings = useSelector(getSettingsPurchaseOrder);
  const companySettings = useSelector(getSettingsCompany);
  const soSettings = useSelector(getSettingsSalesOrder);

  const { permissions, isAdmin } = useSelector(getActiveUser);

  const [item, setItem] = useState(initialItem);
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [customFieldsErrors, setCustomFieldsErrors] = useState<Errors>({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTrackingWizard, setShowTrackingWizard] = useState(false);
  const [itemInventory, setItemInventory] =
    useState<ItemInventory>(initialItemInventory);
  const [closeOnSave, setCloseOnSave] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showCreateSaleItemModal, setCreateShowSaleItemModal] = useState(false);
  const [showSyncSaleItemModal, setShowSyncSaleItemModal] = useState(false);
  const [activeReportModalId, setActiveReportModalId] = useState<ReportId>(
    ReportId.Items
  );
  const [activeReportParameters, setActiveReportParameters] = useState({});

  const oldState = useRef<Item | null>(initialItem);

  const canHaveSaleItems = useMemo(
    () =>
      item.itemType !== ItemType.Bundle &&
      item.itemType !== ItemType.Labor &&
      item.itemType !== ItemType.Overhead,
    [item]
  );

  const isBundleItem = useMemo(
    () => item.itemType === ItemType.Bundle,
    [item.itemType]
  );

  const isInventoryItem = useMemo(
    () => item.itemType === ItemType.Inventory,
    [item.itemType]
  );

  const isNonInventoryItem = useMemo(
    () => item.itemType === ItemType.NonInventory,
    [item.itemType]
  );

  const isShippingOrServiceorOverheadorLaborItem = useMemo(
    () =>
      item.itemType === ItemType.Shipping ||
      item.itemType === ItemType.Service ||
      item.itemType === ItemType.Labor ||
      item.itemType === ItemType.Overhead,
    [item.itemType]
  );

  const existingItemTrackingTypeIds = useMemo(
    () => item.itemTrackingTypeList.map((t) => t.trackingTypeId),
    // eslint-disable-next-line
    [item.id, item.version]
  );

  const newTrackingTypeIds = useMemo(
    () =>
      _.difference(
        item.itemTrackingTypeList.map((t) => t.trackingTypeId),
        existingItemTrackingTypeIds
      ),
    // eslint-disable-next-line
    [item.itemTrackingTypeList, existingItemTrackingTypeIds]
  );

  const shouldShowSubstituteItems = useMemo(() => {
    return isInventoryItem || (itemId !== null && itemId < 0);
  }, [itemId, isInventoryItem]);
  const trackingTabName = urlQuery.activeTab;

  const duplicateClicked = useCallback(() => {
    extendUrlQuery({ activeId: ActiveItemIdState.Duplicate });
  }, [extendUrlQuery]);

  const asyncFc = useCallback(
    async (id: number) => {
      setIsLoading(true);

      try {
        const resItem = await fetchItem(id);
        oldState.current = resItem;
        setItem(resItem);

        if (clone) {
          duplicateClicked();
        }
      } catch (err) {
        const error = err as Error;
        const message = getErrorMessage(err);
        logErrorCtx('Error loading sales item', {
          error,
          stackTrace: error.stack,
          title: 'unable to load sales item in materials',
          description: 'Failed to load sales item in Materials Store',
          component: 'materials => ItemDetailsCard',
        });
        showNotification(`${message} - sales order couldn't be loaded.`, {
          variant: 'error',
        });
        setIsLoading(false);
        onClose();

        return;
      }

      setIsLoading(false);
    },
    // eslint-disable-next-line
    [clone, duplicateClicked]
  );

  useEffect(() => {
    if (!itemId || itemId < ActiveItemIdState.Initial) return;

    // Fetch from server
    asyncFc(itemId);
    // eslint-disable-next-line
  }, [itemId]);

  useEffect(() => {
    // Don't execute if itemId not exists or ActiveItemIdState is not state of duplicate
    if (!itemId || itemId > ActiveItemIdState.Duplicate) return;

    // Duplicating item
    if (itemId === ActiveItemIdState.Duplicate) {
      const newItem = transformToDuplicatedItem(item);
      oldState.current = {} as Item;
      setItem(newItem);

      showNotification('You duplicated material item', { variant: 'success' });
      setClone(false);
    }

    setCloseOnSave(false);
    // eslint-disable-next-line
  }, [itemId]);

  useEffect(() => {
    if (!itemId || itemId > ActiveItemIdState.New) return;

    // Creating new item
    if (itemId === ActiveItemIdState.New) {
      const newItem = transformToInitialItem(
        uoms,
        companySettings,
        poSettings.purchaseTaxId
      );

      oldState.current = newItem;
      setItem(newItem);
    }

    setCloseOnSave(false);
  }, [itemId, uoms, companySettings, poSettings.purchaseTaxId]);

  useEffect(() => {
    if (
      !item.id ||
      item.id === ActiveItemIdState.New ||
      item.id === ActiveItemIdState.Duplicate
    ) {
      dispatch(clearModuleNavigation(ModuleNavigationType.Materials));
      return;
    }

    dispatch(
      updateModuleNavigation(ModuleNavigationType.Materials, {
        itemId: item.id,
        itemType: item.itemType || undefined,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.id, item.itemType]);

  const handleSyncModalClicked =
    (confirmed: boolean = false) =>
    async () => {
      setIsLoading(true);

      const notDeletedDocuments = item.documents.filter((d) => !d.deleted);
      let resolvedItem = {
        ...item,
        documents: notDeletedDocuments,
      };

      if (confirmed) {
        // sync sale item values with item values
        const defaultSaleItemIndex = resolvedItem.saleItemList.findIndex(
          (s) => s.defaultFlag
        );

        if (defaultSaleItemIndex > -1) {
          resolvedItem = {
            ...resolvedItem,
            saleItemList: replaceValueInCollection(
              resolvedItem.saleItemList,
              {
                ...resolvedItem.saleItemList[defaultSaleItemIndex],
                // values to sync
                alertNotes: resolvedItem.alertNotes,
                defaultUomId: resolvedItem.defaultUomId,
                description: resolvedItem.description,
                details: resolvedItem.details,
                dimensionUnit: resolvedItem.dimensionUnit,
                height: resolvedItem.height,
                length: resolvedItem.length,
                sku: resolvedItem.sku,
                upc: resolvedItem.upc,
                weight: resolvedItem.weight,
                weightUnit: resolvedItem.weightUnit,
                width: resolvedItem.width,
              },
              defaultSaleItemIndex
            )!,
          };
        }
      }

      let crudFunction;
      switch (item.id) {
        case null:
        case ActiveItemIdState.New:
          crudFunction = createItem;
          break;
        case ActiveItemIdState.Duplicate:
          crudFunction = duplicateItem;
          break;
        default:
          crudFunction = updateItem;
      }

      try {
        await crudFunction(resolvedItem);
      } catch {
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setShowSyncSaleItemModal(false);

      if (closeOnSave) {
        onClose();
      }
      setCloseOnSave(false);
    };

  const handleCreateSaleItemClicked =
    (confirmed: boolean = false) =>
    async () => {
      oldState.current = item;
      setIsLoading(true);

      const notDeletedDocuments = item.documents.filter((d) => !d.deleted);
      let resolvedItem = {
        ...item,
        documents: notDeletedDocuments,
      };

      if (confirmed) {
        // add default sale item
        resolvedItem.saleItemList = [
          transformToDefaultSaleItem(resolvedItem, soSettings, companySettings),
        ];

        // copy all sale item images which have the same url as item images
        try {
          resolvedItem = await copySaleItemsImages(resolvedItem);
        } catch (err) {
          setIsLoading(false);
          return;
        }
      }

      try {
        await createItem(resolvedItem);
      } catch {
        setIsLoading(false);
        return;
      }

      setIsLoading(false);
      setCreateShowSaleItemModal(false);

      if (closeOnSave) {
        onClose();
      }
    };

  const handleSaveClicked = async (close: boolean = false) => {
    const isValid = isItemDetailsCardValid(
      item,
      extendUrlQuery,
      setValidationErrors,
      setCustomFieldsErrors,
      companySettings.country!
    );
    if (!isValid) {
      extendUrlQuery({ activeTab: ItemTab.General });
      setIsLoading(false);
      return false;
    }
    if (
      item.itemType === ItemType.Shipping &&
      item.saleItemList.some(
        (s) => s.defaultSalesOrderItemType === DefaultSaleItemType.DropShip
      )
    ) {
      showNotification(
        "Shipping Item can't have Sale Item with default type of Drop Ship",
        { variant: 'warning' }
      );
      return false;
    }

    if (
      item.id &&
      item.id > 0 &&
      !_.isEmpty(newTrackingTypeIds) &&
      !_.isEmpty(item.itemTrackingTypeList) &&
      !_.isEmpty(item.inventoryLocationSummaryList)
    ) {
      const inventory = await getItemInventory(item.id!);
      if (inventory.totalQty) {
        setItemInventory(inventory);
        setShowTrackingWizard(true);
        setCloseOnSave(close);
        return false;
      }
    }

    setCloseOnSave(close);

    oldState.current = item;

    switch (true) {
      case shouldShowCreateSaleItemModal(item, canHaveSaleItems):
        setCreateShowSaleItemModal(true);
        return false;
      case shouldShowSyncModal(item, canHaveSaleItems):
        setShowSyncSaleItemModal(true);
        return false;
      default:
    }

    setIsLoading(true);

    const notDeletedDocuments = item.documents.filter((d) => !d.deleted);
    const deletedDocuments = item.documents.filter((d) => d.deleted);
    const resolvedItem = {
      ...item,
      documents: notDeletedDocuments,
    };

    try {
      await deleteDocuments(deletedDocuments);
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Failed to delete document while creating Item', {
        error,
        stackTrace: error.stack,
        title: 'Error when deleting document while creating items in materials',
        description: 'unable to delete document when creating items',
        component: 'ItemsPage -> ItemDetailsCard',
      });
    }

    let crudFunction;
    switch (item.id) {
      case null:
      case ActiveItemIdState.New:
        crudFunction = createItem;
        break;
      case ActiveItemIdState.Duplicate:
        crudFunction = duplicateItem;
        break;
      default:
        crudFunction = updateItem;
    }

    try {
      await crudFunction(resolvedItem);
    } catch {
      setIsLoading(false);
      return false;
    }

    setIsLoading(false);

    if (close) {
      onClose();
    }
    return true;
  };

  const createItem = async (item: Item) => {
    const newItem = await postItem(item);
    oldState.current = newItem;
    setItem(newItem);
    extendUrlQuery({ activeId: newItem.id });

    fetchSearchResult();
  };

  const updateItem = async (item: Item) => {
    const newItem = await putItem(item);
    oldState.current = newItem;
    setItem(newItem);

    fetchSearchResult();
  };

  const duplicateItem = async (item: Item) => {
    let newItem = { ...item };

    const copiedItemImages = await copyItemImages(newItem.imageLinkList);

    // copy sale item images
    const newSaleItems: Promise<SaleItem>[] = newItem.saleItemList.map(
      async (s) => {
        const copiedSaleItemImages = await copyItemImages(s.imageLinkList);
        return { ...s, imageLinkList: copiedSaleItemImages };
      }
    );

    const resolvedNewSaleItems = await Promise.all(newSaleItems);

    newItem = {
      ...newItem,
      imageLinkList: copiedItemImages,
      saleItemList: resolvedNewSaleItems,
    };

    setClone(false);

    await createItem(newItem);
  };

  const handleTabChange = async (
    event: React.ChangeEvent<{}>,
    newValue: ItemTab
  ) => {
    extendUrlQuery({ activeTab: newValue });
  };

  const handleDelete = useCallback(async () => {
    try {
      await deleteItem(item);
      await fetchSearchResult();
    } catch {
      return;
    }

    setDeleteModalVisible(false);
    onClose();
  }, [item, fetchSearchResult, onClose]);

  const handleDeleteClicked = useCallback(
    () => setDeleteModalVisible(true),
    []
  );

  const handleTrackingWizardClose = useCallback(
    () => setShowTrackingWizard(false),
    []
  );

  const handleReportModalClick = useCallback(
    (reportId: ReportId) => {
      switch (reportId) {
        case ReportId.Items:
          setActiveReportParameters({ itemId: item.id });
          break;
        case ReportId.ItemPriceList: {
          const saleItem = item.saleItemList.find((si) => si.defaultFlag);
          setActiveReportParameters({
            saleItemId: saleItem ? saleItem.id : null,
          });
          break;
        }
      }
      setShowReportModal(true);
      setActiveReportModalId(reportId);
    },
    [item.id, item.saleItemList]
  );

  const handleTrackingWizardSubmit = useCallback(
    async (inventoryRows: InventoryRow[]) => {
      setIsLoading(true);

      let newItem;
      try {
        newItem = await postTrackingToExistingItem(
          item.id!,
          inventoryRows,
          item.itemTrackingTypeList,
          existingItemTrackingTypeIds
        );
        oldState.current = newItem;
        setItem(newItem);
        setShowTrackingWizard(false);
      } catch {
        setIsLoading(false);
        return;
      }

      // copy all sale item images which have the same url as item images
      try {
        newItem = await copySaleItemsImages(newItem);
        oldState.current = newItem;
        setItem(newItem);
      } catch (e) {
        setIsLoading(false);
        throw e;
      }

      // update item
      try {
        newItem = await putItem(newItem);
        oldState.current = newItem;
        setItem(newItem);
      } catch (e) {
        setIsLoading(false);
        throw e;
      }

      fetchSearchResult();
      setIsLoading(false);

      if (closeOnSave) {
        onClose();
      }
    },
    [
      item.id,
      item.itemTrackingTypeList,
      closeOnSave,
      existingItemTrackingTypeIds,
      onClose,
      fetchSearchResult,
    ]
  );

  const handleUndeleteClicked = useCallback(
    async (close: boolean = false) => {
      setIsLoading(true);

      try {
        await restoreItem(itemId!);
        const restoredItem = await fetchItem(itemId!);
        oldState.current = restoredItem;
        setItem(restoredItem);
      } catch {
        setIsLoading(false);
        return false;
      }

      if (close) {
        onClose();
      }

      setIsLoading(false);
      fetchSearchResult();
      return true;
    },

    [fetchSearchResult, onClose, itemId]
  );

  return (
    <DetailsCard
      isLoading={isLoading}
      onSubmit={() => handleSaveClicked(false)}
      state={item}
      oldState={oldState}
    >
      <ItemTitleBar
        item={item}
        onSave={handleSaveClicked}
        onClose={onClose}
        onDelete={handleDeleteClicked}
        onDuplicate={duplicateClicked}
        onOpenReport={handleReportModalClick}
        onUndeleteClicked={handleUndeleteClicked}
        trackingTabName={trackingTabName}
      />
      <Tabs
        value={urlQuery.activeTab || ItemTab.General}
        onChange={handleTabChange}
        indicatorColor="primary"
        className="redesign"
      >
        <Tab label="General" data-qa="general-tab" value={ItemTab.General} />
        {canHaveSaleItems && (
          <Tab
            label="Sale Items"
            data-qa="sale-item-tab"
            value={ItemTab.SaleItems}
          />
        )}
        {isBundleItem && (
          <Tab
            label="Bundle Items"
            value={ItemTab.BundleItems}
            data-qa="bundle-items-tab"
          />
        )}
        {itemId && itemId > 0 && (
          <Tab
            label="Substitute Items"
            data-qa="substitute-items-tab"
            value={ItemTab.SubstituteItems}
            className={shouldShowSubstituteItems ? '' : classes.hidden}
          />
        )}
        {!isShippingOrServiceorOverheadorLaborItem &&
          !isBundleItem &&
          !isNonInventoryItem &&
          hasPermission(
            [PermissionType.ItemsReorderPoints],
            permissions,
            isAdmin
          ) && (
            <Tab
              label="Reorder Points"
              data-qa="reorder-point-tab"
              value={ItemTab.ReorderPoints}
            />
          )}
        {isInventoryItem && (
          <Tab
            label="Tracking"
            data-qa="tracking-tab"
            value={ItemTab.Tracking}
          />
        )}

        {!isShippingOrServiceorOverheadorLaborItem &&
          !isBundleItem &&
          !isNonInventoryItem &&
          hasPermission(
            [PermissionType.ItemsDefaultLocations],
            permissions,
            isAdmin
          ) && (
            <Tab
              label="Locations"
              data-qa="location-tab"
              value={ItemTab.Locations}
            />
          )}
        {!isBundleItem &&
          hasPermission(
            [PermissionType.ItemsVendorItems],
            permissions,
            isAdmin
          ) && (
            <Tab
              label="Vendor Items"
              data-qa="vendor-items-tab"
              value={ItemTab.VendorItems}
            />
          )}
        <Tab label="Documents" data-qa="documents" value={ItemTab.Documents} />
      </Tabs>
      <TabPanel
        value={urlQuery.activeTab || ItemTab.General}
        index={ItemTab.General}
        noSpacing
      >
        <FBOGeneralTab
          validationErrors={validationErrors}
          customFieldsErrors={customFieldsErrors}
          oldState={oldState}
          item={item}
          setItem={setItem}
          isShippingOrServiceOrOverheadOrLaborItem={
            isShippingOrServiceorOverheadorLaborItem
          }
        />
      </TabPanel>
      <TabPanel value={urlQuery.activeTab} index={ItemTab.SaleItems} noSpacing>
        <SaleItemsTab item={item} setItem={setItem} />
      </TabPanel>
      <TabPanel
        value={urlQuery.activeTab}
        index={ItemTab.BundleItems}
        noSpacing
      >
        <BundleItemsTab item={item} setItem={setItem} />
      </TabPanel>
      <TabPanel
        index={ItemTab.SubstituteItems}
        value={urlQuery.activeTab}
        noSpacing
      >
        <SubstituteItemsTab item={item} setItem={setItem} />
      </TabPanel>
      <TabPanel
        value={urlQuery.activeTab}
        index={ItemTab.ReorderPoints}
        noSpacing
      >
        <ReorderTab item={item} setItem={setItem} />
      </TabPanel>
      <TabPanel value={urlQuery.activeTab} index={ItemTab.Tracking}>
        <TrackingTab item={item} setItem={setItem} errors={validationErrors} />
      </TabPanel>
      <TabPanel value={urlQuery.activeTab} index={ItemTab.Locations} noSpacing>
        <LocationsTab item={item} setItem={setItem} />
      </TabPanel>
      <TabPanel
        value={urlQuery.activeTab}
        index={ItemTab.VendorItems}
        noSpacing
      >
        <VendorItemsTab item={item} setItem={setItem} />
      </TabPanel>
      <TabPanel value={urlQuery.activeTab} index={ItemTab.Documents} noSpacing>
        <ItemDocumentsTab item={item} setItem={setItem} />
      </TabPanel>
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Item"
        body={`This will delete item ${item.name}
         , are you sure?`}
        onCancelClicked={() => setDeleteModalVisible(false)}
        dataQa="delete-item-confirmation-modal"
        onConfirmClicked={handleDelete}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        confirmButtonRed
      />
      <FBOTrackingWizard
        open={showTrackingWizard}
        itemInventory={itemInventory}
        item={item}
        setItem={setItem}
        newTrackingTypeIds={newTrackingTypeIds}
        existingItemTrackingTypeIds={existingItemTrackingTypeIds}
        onSubmit={handleTrackingWizardSubmit}
        onClose={handleTrackingWizardClose}
      />
      <ReportsModal
        isOpen={showReportModal}
        reportId={activeReportModalId}
        params={activeReportParameters}
        onClose={() => setShowReportModal(false)}
        autoGenerate
      />
      <ConfirmationModal
        open={showCreateSaleItemModal}
        title="Create Sale Item"
        isLoading={isLoading}
        dataQa="create-sale-item-confirmation-modal"
        body="Do you want to create default sale item?"
        onCancelClicked={handleCreateSaleItemClicked()}
        onConfirmClicked={handleCreateSaleItemClicked(true)}
        DialogActionsComponent={() =>
          FBOItemDialogActions(
            handleCreateSaleItemClicked,
            setCreateShowSaleItemModal
          )
        }
      />
      <ConfirmationModal
        open={showSyncSaleItemModal}
        isLoading={isLoading}
        dataQa="sync-sale-item-confirmation-modal"
        title="Sync Values"
        body="Do you want to sync item values with default sale item values?"
        onCancelClicked={handleSyncModalClicked()}
        onConfirmClicked={handleSyncModalClicked(true)}
        DialogActionsComponent={() =>
          FBOItemDialogActions(handleSyncModalClicked, setShowSyncSaleItemModal)
        }
      />
    </DetailsCard>
  );
};

export default memo(ItemDetailsCard);
