export interface EcommerceCardProps {
  index: number;
}

export enum EcommerceSettingsModalType {
  OrderSettings = 'Order',
  CustomerSettings = 'Customer',
  ItemSettings = 'Item',
  InventorySettings = 'Inventory',
  FulfillmentSettings = 'Fulfillment',
  POSSettings = 'POS',
  FBASettings = 'FBA',
}
