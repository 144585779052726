import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  InputAdornment,
  Typography,
  Popover,
  Link,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import {
  replaceValueInCollection,
  defaultPagination,
  RequestConfig,
} from 'helpers';
import { initialDataWithPagination } from 'services/api/consts';
import { fetchItemsAPI } from 'services/items/api';
import { ItemReorderPointsAlert, ItemType } from 'services/items';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { TitleBar } from 'ui/components/TitleBar';
import { TextField } from 'ui/components/TextField/TextField';

import {
  fboAvailableItemsColumns,
  selectedItemsColumns,
  totalAnchorOrigin,
  totalTransformOrigin,
} from './consts';
import { useItemSelecorStyle } from './styled';
import { useRopWizard } from '../../AutoRopWizardProvider';
import { DataWithPagination, showNotification } from 'services/api';
import { RopItemSearchTypes } from './types';
import { CustomRow } from './components/CustomRow';
import { CustomSubRow } from './components/CustomSubRow';
import { PopoverItem } from './components';
import { resolveReorderBarWidths, transformToRopItem } from './helpers';
import { initialRopItem } from '../../consts';
import { RopItem } from '../../types';
import { AlertsModal } from '../../../AlertsModal';
import { Pagination } from 'services/search';
import { logErrorCtx } from 'app/logging';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames, colorPalette } from 'ui/theme';
import Icon from 'ui/components/Icon/Icon';

export const ItemSelector: React.FC = () => {
  const [itemsWithPagination, setItemsWithPagination] =
    useState<DataWithPagination>(initialDataWithPagination);
  const [activeRopItem, setActiveRopItem] = useState<RopItem>(initialRopItem);
  const [alertModalVisible, setAlertModalVisible] = useState<boolean>(false);
  const [search, setSearch] = useState<string | null>(null);
  const [searchEl, setSearchEl] = useState<null | HTMLElement>(null);
  const [selectedSearchType, setSelectedSearchType] =
    useState<RopItemSearchTypes>(RopItemSearchTypes.All);

  const classes = useItemSelecorStyle();

  const { wizardData, setRopItems, setDefaultValues, setWizardData } =
    useRopWizard();
  const { ropItems, defaultValues, useVendorLeadTime } = wizardData;

  const { safetyStockWidth, leadTimeWidth, orderIntervalWidth, notifyAtWidth } =
    useMemo(() => resolveReorderBarWidths(defaultValues), [defaultValues]);

  const isSearchPopoverVisible = useMemo(() => Boolean(searchEl), [searchEl]);

  useEffect(() => {
    (async () => {
      const quickSearchColumns =
        selectedSearchType === RopItemSearchTypes.Tags
          ? ['tags.name']
          : ['name'];
      const requestConfig: RequestConfig = {
        pagination: defaultPagination,
        expands: ['images', 'vendorItems', 'defaultUom', 'tags'],
        quickSearchValue: search,
        customQuickSearchColumns: quickSearchColumns,
      };

      try {
        const resp = await fetchItemsAPI(requestConfig, [
          ItemType.Inventory,
          ItemType.NonInventory,
        ]);
        setItemsWithPagination(resp);
      } catch (e) {
        logErrorCtx('Error in fetchItemsAPI', {
          error: e as Error,
          stackTrace: (e as Error).stack,
          component: 'ItemSelector',
          title: 'Error in fetchItemsAPI',
          description: 'Error in fetchItemsAPI',
        });
      }
    })();
  }, [search, selectedSearchType]);

  const searchRowClicked = useCallback(
    (itemId: number) => {
      const item = itemsWithPagination.data.find((i) => i.id === itemId);
      const selectedItem = ropItems.find((i) => i.id === itemId);

      if (selectedItem) {
        showNotification('Item already added', { variant: 'warning' });
        return;
      }

      if (item) {
        setRopItems((old) => [
          ...old,
          transformToRopItem(defaultValues, item, useVendorLeadTime),
        ]);
      }
    },
    [
      defaultValues,
      itemsWithPagination,
      ropItems,
      useVendorLeadTime,
      setRopItems,
    ]
  );

  const selectedRowClicked = useCallback(
    (itemId: number) => {
      const newSelectedItems = ropItems.filter((i) => i.id !== itemId);

      setRopItems(newSelectedItems);
    },
    [ropItems, setRopItems]
  );

  const handleTextChange = useCallback(
    (ev: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setDefaultValues((old) => ({
        ...old,
        [ev.target.name]: parseInt(ev.target.value) || null,
      }));
    },
    [setDefaultValues]
  );

  const removeAllClicked = useCallback(() => {
    setRopItems([]);
  }, [setRopItems]);

  const addAllClicked = useCallback(() => {
    const newRopItems: RopItem[] = itemsWithPagination.data.map((item) =>
      transformToRopItem(defaultValues, item, useVendorLeadTime)
    );

    setRopItems((old) => [
      ...old,
      ...newRopItems.filter((i) => !old.some((item) => item.id === i.id)),
    ]);
  }, [defaultValues, itemsWithPagination, useVendorLeadTime, setRopItems]);

  const onAlertButtonClick = useCallback(
    (id: number | null) => {
      const ropItem = ropItems.find((i) => i.id === id);
      if (ropItem) {
        setActiveRopItem(ropItem);
        setAlertModalVisible(true);
      }
    },
    [ropItems]
  );

  const onDefaultAlertButtonClick = useCallback(() => {
    setActiveRopItem(defaultValues);
    setAlertModalVisible(true);
  }, [defaultValues]);

  const handleModalApplyClicked = useCallback(() => {
    const index = ropItems.findIndex((i) => i.id === activeRopItem.id);
    // if there is no id we are using default values
    if (!activeRopItem.id) {
      setDefaultValues(activeRopItem);
      setAlertModalVisible(false);
      return;
    }

    setRopItems(replaceValueInCollection(ropItems, activeRopItem, index)!);
    setAlertModalVisible(false);
  }, [ropItems, activeRopItem, setRopItems, setDefaultValues]);

  const handleSearchClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      setSearchEl(event.currentTarget);
    },
    []
  );

  const handleSearchPopClick = useCallback(
    (type: RopItemSearchTypes) => () => {
      setSelectedSearchType(type);
      setSearchEl(null);
    },
    []
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setWizardData((old) => ({
        ...old,
        useVendorLeadTime: checked,
      }));
    },
    [setWizardData]
  );

  const handleSearchPopClose = useCallback(() => {
    setSearchEl(null);
  }, []);

  const handleSetAlerts = useCallback((alerts: ItemReorderPointsAlert[]) => {
    setActiveRopItem((old) => ({
      ...old,
      alerts,
    }));
  }, []);

  const handleScroll = async () => {
    const { pagination } = itemsWithPagination;

    if (pagination.totalRows < pagination.pageSize * pagination.page) {
      return;
    }

    const newPagination: Pagination = {
      ...pagination,
      page: pagination.page + 1,
    };

    const quickSearchColumns =
      selectedSearchType === RopItemSearchTypes.Tags ? ['tags.name'] : ['name'];
    const requestConfig: RequestConfig = {
      pagination: newPagination,
      expands: ['images', 'vendorItems', 'defaultUom', 'tags'],
      quickSearchValue: search,
      customQuickSearchColumns: quickSearchColumns,
    };

    try {
      const resp = await fetchItemsAPI(requestConfig, [
        ItemType.Inventory,
        ItemType.NonInventory,
      ]);
      setItemsWithPagination((old) => ({
        pagination: resp.pagination,
        data: [...old.data, ...resp.data],
      }));
    } catch (e) {
      logErrorCtx('Error in handleScroll fetchItemsAPI', {
        error: e as Error,
        stackTrace: (e as Error).stack,
        component: 'ItemSelector',
        title: 'Error in handleScroll fetchItemsAPI',
        description: 'Error in handleScroll fetchItemsAPI',
      });
    }
  };

  const renderSearchEndAdornment = () => {
    return (
      <InputAdornment position="end">
        <Link
          variant="body2"
          color="textPrimary"
          onClick={handleSearchClicked}
          href="#"
          underline="always"
        >
          {selectedSearchType}
        </Link>
        <Popover
          anchorOrigin={totalAnchorOrigin}
          transformOrigin={totalTransformOrigin}
          anchorEl={searchEl}
          open={isSearchPopoverVisible}
          onClose={handleSearchPopClose}
        >
          <Grid container>
            <PopoverItem
              label="All Fields"
              onClick={handleSearchPopClick(RopItemSearchTypes.All)}
            />
            <PopoverItem
              label="Tags"
              onClick={handleSearchPopClick(RopItemSearchTypes.Tags)}
            />
          </Grid>
        </Popover>
      </InputAdornment>
    );
  };

  return (
    <>
      <Box
        display="flex"
        flexGrow={1}
        overflow="hidden"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="column"
          className={classes.tableColumn}
        >
          <Box>
            <TitleBar noBorderRadius>
              <TextField
                placeholder="Search"
                className={classes.searchInputOuter}
                value={search}
                onChange={(ev) => setSearch(ev.target.value || null)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: renderSearchEndAdornment(),
                  classes: {
                    root: classes.searchInputInner,
                    notchedOutline: classes.noBorder,
                  },
                }}
              />
              <FBOButton
                variant="secondary"
                color="neutral"
                size="medium"
                icon={IconNames.AddCircle}
                onClick={addAllClicked}
                data-qa="rop-wizard-add-all"
              >
                All
              </FBOButton>
            </TitleBar>
          </Box>
          <Box overflow="auto">
            <ItemsTable
              data={itemsWithPagination.data}
              onScrollNextPage={handleScroll}
              columns={fboAvailableItemsColumns(
                itemsWithPagination.data.length
              )}
              selectableItems={false}
              onItemClick={searchRowClicked}
              dataQa="inventory-rop-item-selector-left"
            />
          </Box>
        </Box>
        <Box
          className={classes.defaultValuesContainer}
          display="flex"
          flexDirection="column"
        >
          <Box p={2}>
            <Box
              display="flex"
              flexGrow={1}
              flexDirection="row"
              alignItems="center"
            >
              <Box width={224} className={classes.defaultValues}>
                <Typography variant="h5">Default Values</Typography>
              </Box>
              <Box width={150} className={classes.defaultValues}>
                <TextField
                  type="number"
                  value={defaultValues.safetyStock}
                  name="safetyStock"
                  label="Safety Stock"
                  placeholder="0"
                  onChange={handleTextChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Days</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    'data-qa': 'safety-stock-input-default',
                  }}
                />
              </Box>
              <Box width={150} className={classes.defaultValues}>
                <TextField
                  type="number"
                  value={defaultValues.leadTime}
                  name="leadTime"
                  label="Lead Time"
                  placeholder="0"
                  onChange={handleTextChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Days</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    'data-qa': 'lead-time-input-default',
                  }}
                />
              </Box>
              <Box width={150} className={classes.defaultValues}>
                <TextField
                  type="number"
                  value={defaultValues.orderInterval}
                  name="orderInterval"
                  label="Order Interval"
                  placeholder="0"
                  onChange={handleTextChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Days</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    'data-qa': 'order-interval-input-default',
                  }}
                />
              </Box>
              <Box width={150} className={classes.defaultValues}>
                <TextField
                  type="number"
                  value={defaultValues.notifyAt}
                  name="notifyAt"
                  label="Notify At"
                  placeholder="0"
                  onChange={handleTextChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Days</InputAdornment>
                    ),
                  }}
                  inputProps={{
                    'data-qa': 'notify-at-input-default',
                  }}
                />
              </Box>
              <Box width={60} className={classes.defaultValues}>
                <FBOButton
                  variant="tertiary"
                  color="neutral"
                  size="medium"
                  icon={IconNames.Bell}
                  onClick={onDefaultAlertButtonClick}
                  data-qa="item-selector-notifications"
                />
              </Box>
            </Box>
            <Grid container item>
              <Grid item xs={2} />
              <Grid item xs={8}>
                <Box className={classes.statusBar}>
                  <Box
                    className={classes.statusSafety}
                    width={`${safetyStockWidth}%`}
                    height={12}
                  >
                    <Typography variant="body1" color="textPrimary">
                      {defaultValues.safetyStock
                        ? defaultValues.safetyStock
                        : null}
                    </Typography>
                  </Box>
                  <Box
                    className={classes.statusLead}
                    width={`${leadTimeWidth}%`}
                    height={12}
                  >
                    <Typography variant="body1" color="textPrimary">
                      {defaultValues.leadTime ? defaultValues.leadTime : null}
                    </Typography>
                  </Box>
                  <Box
                    className={classes.statusInterval}
                    width={`${orderIntervalWidth}%`}
                    height={12}
                  >
                    <Typography variant="body1" color="textPrimary">
                      {defaultValues.orderInterval
                        ? defaultValues.orderInterval
                        : null}
                    </Typography>
                  </Box>

                  <Box className={classes.notifyAt} left={`${notifyAtWidth}%`}>
                    {defaultValues.notifyAt && defaultValues.notifyAt > 0 ? (
                      <Icon
                        name={IconNames.Bell}
                        color={colorPalette.redesign.contentPrimary}
                      />
                    ) : null}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container item>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={useVendorLeadTime}
                      onChange={handleCheckboxChange}
                      color="primary"
                      data-qa="items-use-default-vendor-lead-time"
                    />
                  }
                  label="Use Default Vendor Lead Time"
                />
              </Grid>
            </Grid>
          </Box>
          <Box width="100%">
            <TitleBar title="Items" noBorderRadius>
              <FBOButton
                variant="secondary"
                color="neutral"
                size="medium"
                icon={IconNames.SubCircle}
                onClick={removeAllClicked}
                data-qa="rop-wizard-remove-all"
              >
                All
              </FBOButton>
            </TitleBar>
          </Box>
          <Box overflow="auto">
            <ItemsTable
              data={ropItems}
              columns={selectedItemsColumns}
              RenderCustomRow={CustomRow}
              RenderRowSubrows={CustomSubRow}
              onAction={onAlertButtonClick}
              selectableItems={false}
              setData={setRopItems}
              onItemClick={selectedRowClicked}
              emptyTableText="ADD ITEM BY SELECTING FROM THE LEFT TABLE"
              dataQa="inventory-rop-item-selector-right"
            />
          </Box>
        </Box>
      </Box>
      <AlertsModal
        modalVisible={alertModalVisible}
        alerts={activeRopItem.alerts}
        setAlerts={handleSetAlerts}
        onApply={handleModalApplyClicked}
        onClose={() => setAlertModalVisible(false)}
      />
    </>
  );
};

export default ItemSelector;
