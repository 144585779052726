import _ from 'lodash';

import { transformDateToMomentDate } from 'helpers';

import {
  PermissionGroup,
  Permission,
  PermissionCategory,
  PermissionArea,
  PermissionUser,
} from './types';

export const transformPermissionGroup = (res: any): PermissionGroup => {
  const users = res.users || [];
  const permissions: Permission[] = (res.permissions || []).map(
    transformPermission
  );

  return {
    id: res.id,
    dateCreated: transformDateToMomentDate(res.dateCreated),
    dateLastModified: transformDateToMomentDate(res.dateLastModified),
    name: res.name,
    permissionIds: permissions.map((p) => p.id),
    permissions,
    readOnly: res.readOnly,
    users: users.map(transformPermissionUser),
    version: res.version,
  };
};

export const transformPermission = (res: any): Permission => {
  return {
    id: res.id,
    dateCreated: transformDateToMomentDate(res.dateCreated),
    dateLastModified: transformDateToMomentDate(res.dateLastModified),
    key: res.key,
    name: res.name,
    version: res.version,
  };
};

export const transformPermissionUser = (res: any): PermissionUser => {
  return {
    id: res.id,
    dateCreated: transformDateToMomentDate(res.dateCreated),
    dateLastModified: transformDateToMomentDate(res.dateLastModified),
    email: res.email,
    firstName: res.firstName,
    lastName: res.lastName,
    version: res.version,
  };
};

export const transformPermissionCategory = (res: any): PermissionCategory => {
  const permissionAreas = res.permissionAreas || [];

  return {
    id: res.id,
    name: res.name,
    permissionAreas: permissionAreas.map(transformPermissionArea),
  };
};

export const transformPermissionArea = (res: any): PermissionArea => {
  const permissions = res.permissions || [];

  return {
    id: res.id,
    name: res.name,
    key: res.key,
    permissions: permissions.map(transformPermission),
  };
};

export const transformToRequestPermissionGroup = (pg: PermissionGroup): any => {
  const payload = {
    name: pg.name,
    permissions: pg.permissions.map((p) => p.id),
    users: _.uniq(pg.users.map((u) => u.id)),
  };

  // POST
  if (!pg.id || pg.id < 0) {
    return payload;
  }

  // PUT
  return {
    ...payload,
    id: pg.id,
    version: pg.version,
  };
};
