import React, { memo } from 'react';
import lodash from 'lodash';
import { useFlags } from 'helpers/useFlags';
import { FeatureFlagProps } from './types';
import { FlagName } from 'helpers/flagType';

const FeatureFlag: React.FC<FeatureFlagProps> = ({ featureFlag, children }) => {
  const flags = useFlags();

  const flagName = lodash.camelCase(featureFlag) as FlagName;

  return <>{flags[flagName] ? <>{children}</> : null}</>;
};

export default memo(FeatureFlag);
