import React, { memo, useCallback, useMemo, useEffect } from 'react';
import { Box } from '@mui/material';
import _ from 'lodash';

import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { Modal } from 'ui/components/Modal/Modal';
import { initialItemReorderPointsAlert } from 'services/items/consts';
import { ItemReorderPointsAlert } from 'services/items';
import { findNextNegativeId, replaceValueInCollection } from 'helpers';

import { MaganeAlertsModalProps } from './types';
import { MANAGE_ALERTS_COLUMNS } from './consts';
import ManageAlertsRow from './ManageAlertsRow';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const ManageAlertsModal: React.FC<MaganeAlertsModalProps> = (props) => {
  const { modalVisible, onClose, onApply, reorderPoint, setReorderPoint } =
    props;

  const { reorderPointAlerts: alerts } = reorderPoint;

  const oldAlerts = useMemo(() => {
    return alerts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  useEffect(() => {
    if (modalVisible && alerts.length === 0) {
      setAlerts([
        { ...initialItemReorderPointsAlert, id: findNextNegativeId(alerts) },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  const setAlerts = useCallback(
    (reorderPointAlerts: React.SetStateAction<ItemReorderPointsAlert[]>) => {
      if (typeof reorderPointAlerts === 'function') {
        setReorderPoint((l) => ({
          ...l,
          reorderPointAlerts: reorderPointAlerts(l.reorderPointAlerts),
        }));
        return;
      }

      setReorderPoint((l) => ({
        ...l,
        reorderPointAlerts,
      }));
    },
    [setReorderPoint]
  );

  const handleDelete = useCallback(
    (index: number) => {
      setAlerts(
        replaceValueInCollection(
          alerts,
          { ...alerts[index], deleted: true },
          index
        )!
      );
    },
    [alerts, setAlerts]
  );

  const handleAddClicked = useCallback(() => {
    setAlerts([
      ...alerts,
      { ...initialItemReorderPointsAlert, id: findNextNegativeId(alerts) },
    ]);
  }, [alerts, setAlerts]);

  const handleResetClicked = useCallback(() => {
    setAlerts(oldAlerts);
  }, [oldAlerts, setAlerts]);

  return (
    <Modal
      open={modalVisible}
      onCancelClicked={onClose}
      fullWidth
      withoutDefaultPadding
      onApplyClicked={onApply}
      onResetClicked={handleResetClicked}
      applyLabel={reorderPoint.id! > 0 ? 'Update' : 'Add'}
      title={_.get(reorderPoint, 'location.displayName', '')}
    >
      <ItemsTable
        data={alerts}
        filterRows={(alert) => !alert.deleted}
        columns={MANAGE_ALERTS_COLUMNS}
        RenderCustomRow={ManageAlertsRow}
        selectableItems={false}
        tableBordered
        onAction={handleDelete}
        setData={setAlerts}
      />
      <Box display="flex" alignItems="center">
        <FBOButton
          variant="tertiary"
          color="neutral"
          size="medium"
          icon="FBOAddCircle"
          onClick={handleAddClicked}
          data-qa="manage-alerts-modal-add-row-button"
        >
          Add Row
        </FBOButton>
      </Box>
    </Modal>
  );
};

export default memo(ManageAlertsModal);
