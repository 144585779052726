import { Dates } from 'helpers';
import { PermissionType } from 'services/permissions';
import { MenuItem } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';

export enum UomsPageAction {
  Import,
  Export,
  ChangeDate,
}

export const createUomsSearchActionBarOptions = (
  onActionClick: (actionType: UomsPageAction, date?: Dates) => void,
  uomsCount: number
): MenuItem[] => [
  {
    label: 'Import UOMs',
    icon: IconNames.FBOExport,
    onOptionClick: () => onActionClick(UomsPageAction.Import),
    permissions: [PermissionType.UomCreate, PermissionType.UomEdit],
    separateAfter: true,
  },
  {
    label: 'Export UOMs',
    icon: IconNames.FBOImport,
    onOptionClick: () => onActionClick(UomsPageAction.Export),
    disabled: !uomsCount,
    separateAfter: true,
  },
  {
    icon: IconNames.FBORecycleSmall,
    label: 'Change Dates',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(UomsPageAction.ChangeDate, Dates.DateCreated),
        dataQa: 'date-created',
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(UomsPageAction.ChangeDate, Dates.DateLastModified),
        dataQa: 'date-Last-modified',
      },
    ],
  },
];
