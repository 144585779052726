import {
  KeyValue,
  EcommerceMappingsType,
  ChannelType,
  EcommerceChannel,
  FishbowlMappings,
} from './types';

export const getNewMapping = (newMapping: KeyValue) => {
  let newName: any = null;
  let newDescription: any = null;
  let newSku: any = null;
  Object.entries(newMapping || {}).forEach(([key, value]) => {
    switch (parseInt(key, 10)) {
      case FishbowlMappings.Name:
        newName = { id: value, value: EcommerceMappingsType[value as any] };
        return;
      case FishbowlMappings.Description:
        newDescription = {
          id: value,
          value: EcommerceMappingsType[value as any],
        };
        return;
      case FishbowlMappings.Sku:
        newSku = { id: value, value: EcommerceMappingsType[value as any] };
        return;
    }
  });

  return { newName, newDescription, newSku };
};

export const paymentMethod = (channel: EcommerceChannel) => {
  switch (channel.channel) {
    case ChannelType.Shopify:
      return 'Shopify';
    case ChannelType.Amazon:
      return 'Amazon';
    case ChannelType.WooCommerce:
      return 'WooCommerce';
    default:
      return null;
  }
};
