import { makeStyles } from '@mui/styles';

export const useItemSelecorStyle = makeStyles(() => ({
  tableColumn: {
    width: '35%',
    overflow: 'auto',
    borderRight: '1px solid #EBEBEB',
    backgroundColor: '#fff',
  },
  tableColumnPicked: {
    width: '64%',
    overflow: 'auto',
    borderLeft: '1px solid #EBEBEB',
    backgroundColor: '#fff',
  },
  defaultValuesContainer: {
    width: '74%',
    borderLeft: '1px solid #EBEBEB',
    backgroundColor: '#fff',
  },
  searchInputInner: {
    height: 32,
    background: 'white',
    paddingTop: `0!important`,
    paddingBottom: `0!important`,
  },
  searchInputOuter: {
    flex: 1,
    paddingRight: 5,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  noBorder: {
    border: 0,
  },
  defaultValues: {
    padding: '0px 8px 0px 8px',
  },
}));
