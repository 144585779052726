import React, { memo, useCallback, useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import _ from 'lodash';

import { Modal } from 'ui/components/Modal/Modal';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { getLocations } from 'services/locations';
import { getSettingsPicking } from 'services/settings/picking/redux';
import { replaceValueInCollection } from 'helpers';
import {
  PickItem,
  pickItemIsInventoryType,
  PickItemStatus,
} from 'services/picking';
import { Errors } from 'services/forms/validation';
import { initialPickItem } from 'services/picking/consts';

import { PickItemCommitRow } from './components';
import { PICK_ITEMS_COLUMNS, PICK_ITEMS_TABLE_HEIGHT } from './consts';
import { ErrorModal, PickCommitWizardProps } from './types';
import { isTrackingGroupAmountValid } from './helpers';
import { splitColumns, finishColumns } from '../SummaryTrackingTable/consts';
import { SummaryTrackingTable } from '../SummaryTrackingTable';
import { OverPickingNotifyModal } from './OverPickingNotifyModal';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import { colorPalette, themeRestyle } from 'ui/theme';
import FBOPickItemCommitDetails from './components/PickItemCommitDetails/FBOPickItemCommitDetails';
import { Box } from '@mui/material';

const summaryFakePickItem = { ...initialPickItem, id: -1 };

const FBOPickCommitWizard: React.FC<PickCommitWizardProps> = (props) => {
  const { show, pickItems, onClose, onSave } = props;

  const locations = useSelector(getLocations).items;

  const pickingSettings = useSelector(getSettingsPicking);

  const [formPickItems, setFormPickItems] = React.useState<PickItem[]>([]);
  const [activePickItem, setActivePickItem] =
    React.useState<PickItem>(initialPickItem);
  const [isLoading, setIsLoading] = React.useState(false);
  const [trackingTableErrors, setTrackingTableErrors] = React.useState<
    Errors[]
  >([]);

  const [showNotifyWhenOverPickingModal, setShowNotifyWhenOverPickingModal] =
    useState(false);

  const [errorModal, setErrorModal] = React.useState<ErrorModal>({
    show: false,
    title: '',
    msg: '',
  });

  const isInventoryType = pickItemIsInventoryType(activePickItem);

  const isTrackingTableValid = useMemo(
    () => trackingTableErrors.filter((error) => !_.isEmpty(error)).length === 0,
    [trackingTableErrors]
  );

  const activeCommitSoNumber = useMemo(() => {
    const firstPickItem = _.get(
      formPickItems[0],
      'salesOrderItem.salesOrder.number',
      ''
    );
    return `Commit ${firstPickItem ? ' - SO ' + firstPickItem : ''}`;
  }, [formPickItems]);

  const isCommitReady = useMemo(() => {
    // commit is ready if all inventory pick items have some amount
    return formPickItems.some((i) => i.amount);
  }, [formPickItems]);
  const itemsToBeSplit = useMemo(() => {
    return formPickItems.filter((i) => (i.amount || 0) < (i.quantity || 0));
  }, [formPickItems]);
  const commitButtonDisabled =
    !isTrackingTableValid ||
    ((activePickItem.amount || 0) > (activePickItem.quantityAvailable || 0) &&
      isInventoryType);

  useEffect(() => {
    if (show) {
      // empty tracking groups to populate it from the inventory
      const availablePickItems = pickItems
        .filter(
          (i) =>
            i.status === PickItemStatus.Available ||
            i.status === PickItemStatus.Short ||
            i.status === PickItemStatus.Started
        )
        .map((i) => ({ ...i, trackingGroupList: [] }));

      setFormPickItems(availablePickItems);
      if (availablePickItems.length) {
        setActivePickItem(availablePickItems[0]);
      }
    } else {
      setActivePickItem(initialPickItem);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, pickItems]);

  const hideNotifyWhenOverPickingModal = useCallback(() => {
    setShowNotifyWhenOverPickingModal(false);
  }, []);

  const handleApplyClicked = useCallback(async () => {
    setIsLoading(true);
    await onSave(formPickItems);
    setIsLoading(false);
  }, [formPickItems, onSave]);

  const handleCommitClicked = useCallback(() => {
    if (
      ((activePickItem.amount || 0) > activePickItem.quantityAvailable! ||
        (activePickItem.amount || 0) > activePickItem.quantity!) &&
      isInventoryType
    ) {
      if (!pickingSettings.enableOverPicking) {
        setErrorModal({
          show: true,
          title: 'Overpicking is not enabled',
          msg: 'At the moment, overpicking is not supported. You can enable it under Settings > Picking',
        });
        return;
      } else if (
        pickingSettings.enableOverPicking &&
        pickingSettings.notifyWhenOverPicking
      ) {
        setShowNotifyWhenOverPickingModal(true);
      }
    }

    if (!isTrackingGroupAmountValid(activePickItem.trackingGroupList)) {
      setErrorModal({
        show: true,
        title: 'Wrong quantity',
        msg: 'You cannot commit more quantity in tracking group than is available.',
      });
      return;
    }

    const index = formPickItems.findIndex((i) => i.id === activePickItem.id);
    setFormPickItems(
      replaceValueInCollection(formPickItems, activePickItem, index)!
    );

    // set next pickItem as active
    if (formPickItems.length > index + 1) {
      setActivePickItem(formPickItems[index + 1]);
    } else {
      setActivePickItem(summaryFakePickItem);
    }
  }, [
    formPickItems,
    activePickItem,
    isInventoryType,
    pickingSettings.enableOverPicking,
    pickingSettings.notifyWhenOverPicking,
  ]);

  const handlePickItemClicked = useCallback(
    (itemId: number) => {
      setActivePickItem(
        formPickItems.find((i) => i.id === itemId) || summaryFakePickItem
      );
    },
    [formPickItems]
  );

  const handleAutoAssignClicked = useCallback(() => {
    const index = formPickItems.findIndex((i) => i.id === activePickItem.id);
    setFormPickItems(
      replaceValueInCollection(
        formPickItems,
        {
          ...activePickItem,
          quantity: activePickItem.quantity,
          autoAssign: true,
        },
        index
      )!
    );

    // set next pickItem as active
    if (formPickItems.length > index + 1) {
      setActivePickItem(formPickItems[index + 1]);
    } else {
      setActivePickItem(summaryFakePickItem);
    }
  }, [formPickItems, activePickItem]);

  const hideOverpickingModal = useCallback(() => {
    setErrorModal({
      show: false,
      title: '',
      msg: '',
    });
  }, []);

  const handleClose = useCallback(() => {
    setTrackingTableErrors([]);
    onClose();
  }, [onClose]);

  const ModalActions = () => {
    return (
      <Box display="flex" justifyContent={'flex-end'} width="100%">
        <FBOButton
          variant="secondary"
          color="neutral"
          size="medium"
          onClick={handleClose}
          data-qa="picking-wizard-cancel-button"
          sx={{ marginRight: '16px' }}
        >
          Cancel
        </FBOButton>
        {activePickItem.id &&
          activePickItem.id > 0 &&
          activePickItem.trackingGroupList.length > 0 && (
            <FBOButton
              variant="secondary"
              color="positive"
              size="medium"
              onClick={handleAutoAssignClicked}
              data-qa="picking-wizard-auto-select-and-commit"
              sx={{ marginRight: '16px' }}
            >
              Auto select and continue
            </FBOButton>
          )}

        {activePickItem.id && activePickItem.id > 0 && (
          <FBOButton
            variant="primary"
            color="positive"
            size="medium"
            disabled={commitButtonDisabled}
            onClick={handleCommitClicked}
            data-qa="picking-wizard-commit-items"
          >
            Next
          </FBOButton>
        )}
        {activePickItem.id === -1 && (
          <FBOButton
            variant="primary"
            color="positive"
            size="medium"
            disabled={!isCommitReady}
            loading={!!isLoading}
            onClick={handleApplyClicked}
            data-qa="picking-wizard-commit"
          >
            Commit
          </FBOButton>
        )}
      </Box>
    );
  };

  return (
    <>
      <Modal
        open={show}
        onApplyClicked={_.noop}
        onCancelClicked={handleClose}
        withoutDefaultPadding
        isLoadingContent={isLoading}
        title={activeCommitSoNumber}
        onResetClicked={_.noop}
        maxWidth="lg"
        nestedScrollAreas
        ModalActionsComponent={ModalActions}
      >
        <Grid
          container
          disableEqualOverflow
          sx={{
            paddingLeft: themeRestyle.spacing(3),
            paddingRight: themeRestyle.spacing(3),
          }}
        >
          <Grid xs={4}>
            <ItemsTable
              columns={PICK_ITEMS_COLUMNS}
              data={[...formPickItems, summaryFakePickItem]} // dirty hack to show summary page
              selectableItems={false}
              onItemClick={handlePickItemClicked}
              containerHeight={PICK_ITEMS_TABLE_HEIGHT}
              RenderCustomRow={PickItemCommitRow}
              activeId={activePickItem.id}
              tableLayoutFixed
              meta={{ isCommitReady }}
              dataQa="picking-item-commit-wizard-table"
              sx={{
                borderRadius: '5px',
                border: `1px solid ${colorPalette.redesign.background3}`,
                borderTop: 'none',
              }}
            />
          </Grid>
          {activePickItem.id !== null && activePickItem.id > 0 && (
            <Grid
              xs={8}
              sx={{ display: 'flex', flexDirection: 'column' }}
              paddingLeft={themeRestyle.spacing(3)}
              spacing={themeRestyle.spacing(2)}
            >
              <FBOPickItemCommitDetails
                pickItem={activePickItem}
                setPickItem={setActivePickItem}
                locations={locations}
                pickItems={formPickItems}
                trackingTableErrors={trackingTableErrors}
                setTrackingTableErrors={setTrackingTableErrors}
                setIsLoading={setIsLoading}
              />
            </Grid>
          )}
          {activePickItem.id === -1 && (
            <Grid
              xs={8}
              sx={{ display: 'flex', flexDirection: 'column' }}
              paddingLeft={themeRestyle.spacing(3)}
              spacing={themeRestyle.spacing(2)}
            >
              <FBOTitleBar title="Items to be committed" />
              <SummaryTrackingTable
                data={formPickItems.filter((i) => i.amount)}
                columns={finishColumns}
                emptyTableText={'No Items'}
                isCommitTable
                sx={{
                  borderRadius: '5px',
                  border: `1px solid ${colorPalette.redesign.background3}`,
                  borderTop: 'none',
                  maxHeight: '270px !important',
                }}
              />
              <FBOTitleBar title="Unassigned Items To Be Split To New Lines" />
              <SummaryTrackingTable
                data={itemsToBeSplit}
                columns={splitColumns}
                emptyTableText={'No Items'}
                sx={{
                  borderRadius: '5px',
                  border: `1px solid ${colorPalette.redesign.background3}`,
                  borderTop: 'none',
                  maxHeight: '270px',
                }}
              />
            </Grid>
          )}
        </Grid>
      </Modal>
      <ConfirmationModal
        open={errorModal.show}
        title={errorModal.title}
        body={errorModal.msg}
        onCancelClicked={hideOverpickingModal}
        onConfirmClicked={hideOverpickingModal}
      />
      <OverPickingNotifyModal
        open={showNotifyWhenOverPickingModal}
        onCancel={hideNotifyWhenOverPickingModal}
        title="Over-Picking"
        content="The quantity entered is larger than ordered"
        onContinue={hideNotifyWhenOverPickingModal}
      />
    </>
  );
};

export default memo(FBOPickCommitWizard);
