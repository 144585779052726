import React, { useEffect } from 'react';
import { Box, Typography, Chip } from '@mui/material';

import { Paper } from 'ui/components/Paper/Paper';
import { DriveLogoHorizontal, IconNames } from 'ui/theme';
import FeatureFlag from 'ui/components/FeatureFlag';
import { useFlags } from 'helpers/useFlags';
import { logErrorCtx } from 'app/logging';
import { NotACustomer } from 'ui/modules/public/components';

import { AuthContainerProps } from './types';
import { useAuthContainerStyle } from './styled';
import { getOutageBannerCopy } from 'services/outageBanner';
import { Icon } from 'ui/components/Icon';

const AuthContainer: React.FC<AuthContainerProps> = (
  props: AuthContainerProps
) => {
  const {
    children,
    title,
    showNotACustomer = false,
    sx = {},
    isMaintenancePage = false,
  } = props;

  const [outageBannerCopy, setOutageBannerCopy] = React.useState('');

  const classes = useAuthContainerStyle(props);
  const flags = useFlags();

  const outageBannerFeatureFlagEnabled = flags.outageBanner;

  useEffect(() => {
    if (outageBannerFeatureFlagEnabled) {
      (async () => {
        try {
          const newOutageBannerCopy = await getOutageBannerCopy();
          setOutageBannerCopy(newOutageBannerCopy);
        } catch (e) {
          logErrorCtx('Error Retriving Outage Banner Copy', {
            error: e as Error,
            stackTrace: (e as Error).stack,
            component: 'AppBar',
            title: 'Error in fetch outage banner copy',
            description: `Error retriving outage banner copy`,
          });
          return;
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.background}>
      <Paper
        p={isMaintenancePage ? '0' : '40px 40px 19px 40px'}
        className={classes.paper}
        sx={sx}
      >
        {isMaintenancePage ? (
          <Box display={'flex'} justifyContent={'center'}>
            <Icon name={IconNames.MaintenanceTools} />
          </Box>
        ) : (
          <Box textAlign="center">
            <img
              id="FishbowlDriveLogo"
              src={DriveLogoHorizontal}
              alt="FishBowl Drive"
              width={200}
            />
          </Box>
        )}
        <Box
          mt={'16px'}
          pb={isMaintenancePage ? '0' : '32px'}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          {isMaintenancePage ? (
            <Typography
              fontSize={'38px'}
              sx={{ maxWidth: '300px', lineHeight: 'normal' }}
              align="center"
            >
              {title}
            </Typography>
          ) : (
            <Typography
              variant="h4"
              align="center"
              className={classes.title}
              sx={{ letterSpacing: 1 }}
            >
              {title}
            </Typography>
          )}
        </Box>
        <Box>{children}</Box>
      </Paper>

      <FeatureFlag featureFlag={'outageBanner'}>
        {outageBannerCopy && (
          <Box py={5}>
            <Chip
              label={outageBannerCopy}
              style={{ backgroundColor: '#D22B2B', fontSize: 12 }}
              size="small"
              color="secondary"
            />
          </Box>
        )}
      </FeatureFlag>

      {showNotACustomer && <NotACustomer />}
    </Box>
  );
};

export default React.memo(AuthContainer);
