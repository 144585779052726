import React, {
  memo,
  useCallback,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { ObjectSchema } from 'yup';

import CardWizard from 'ui/components/Wizard/CardWizard';
import { Fields, validateYup } from 'services/forms/validation';
import {
  ChannelType,
  customerSchema,
  EcommerceLocationHash,
  fulfillmentSchema,
  initialEcommerceSettings,
  inventorySchema,
  itemSchema,
  orderSchema,
  posSchema,
  shopifySchema,
  EcommerceSettings,
} from 'services/integrations/ecommerce';

import { EcommerceWizardProps } from './types';
import { OrderSettingsForm } from '../OrderSettingsForm';
import { CustomerSettingsForm } from '../CustomerSettingsForm';
import { ItemSettingsForm } from '../ItemSettingsForm';
import { InventorySettingsForm } from '../InventorySettingsForm';
import { FulfillmentSettingsForm } from '../FulfillmentSettingsForm';
import { POSSettingsForm } from '../POSSettingsForm';
import { FBASettingsForm } from '../FBASettingsForm';
import { replaceValueInCollection, scrollToSection } from 'helpers';
import { useEcommerceWizardStyle } from '../../../../styled';
import {
  EcommerceContext,
  GetDivIdByChannel,
  imageLogoUrl,
} from '../../../../EcommerceProvider';
import useEcommerceSettings from '../../../../useEcommerceSettings';
import { APP_BAR_HEIGHT } from 'app/components/AppBar';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { showNotification } from 'services/api';

const steps = (
  classes: any = {},
  activeStep: number = 0,
  channelType: number | null = 0
) => {
  let fixedSteps = [
    {
      label: 'Order settings',
      icon:
        activeStep >= 1 ? (
          <RadioButtonCheckedIcon className={classes.activeIcon} />
        ) : (
          <RadioButtonUncheckedOutlinedIcon />
        ),
    },
    {
      label: 'Customer settings',
      icon:
        activeStep >= 2 ? (
          <RadioButtonCheckedIcon className={classes.activeIcon} />
        ) : (
          <RadioButtonUncheckedOutlinedIcon />
        ),
    },
    {
      label: 'Item settings',
      icon:
        activeStep >= 3 ? (
          <RadioButtonCheckedIcon className={classes.activeIcon} />
        ) : (
          <RadioButtonUncheckedOutlinedIcon />
        ),
    },
    {
      label: 'Inventory settings',
      icon:
        activeStep >= 4 ? (
          <RadioButtonCheckedIcon className={classes.activeIcon} />
        ) : (
          <RadioButtonUncheckedOutlinedIcon />
        ),
    },
  ];

  let varSteps = [];

  if (channelType === ChannelType.Shopify) {
    varSteps = [
      {
        label: 'Fulfillment settings',
        icon:
          activeStep >= 5 ? (
            <RadioButtonCheckedIcon className={classes.activeIcon} />
          ) : (
            <RadioButtonUncheckedOutlinedIcon />
          ),
      },
      {
        label: 'POS settings',
        icon: <RadioButtonUncheckedOutlinedIcon />,
      },
    ];
  } else if (channelType === ChannelType.Amazon) {
    varSteps = [
      {
        label: 'Fulfillment settings',
        icon:
          activeStep >= 5 ? (
            <RadioButtonCheckedIcon className={classes.activeIcon} />
          ) : (
            <RadioButtonUncheckedOutlinedIcon />
          ),
      },
      {
        label: 'FBA settings',
        icon: <RadioButtonUncheckedOutlinedIcon />,
      },
    ];
  } else {
    varSteps = [
      {
        label: 'Fufillment settings',
        icon: <RadioButtonUncheckedOutlinedIcon />,
      },
    ];
  }

  if (varSteps) {
    fixedSteps.push(...varSteps);
  }

  if (channelType === ChannelType.ShipStation) {
    fixedSteps = [
      {
        label: 'Order settings',
        icon:
          activeStep >= 1 ? (
            <RadioButtonCheckedIcon className={classes.activeIcon} />
          ) : (
            <RadioButtonUncheckedOutlinedIcon />
          ),
      },
      {
        label: 'Customer settings',
        icon:
          activeStep >= 2 ? (
            <RadioButtonCheckedIcon className={classes.activeIcon} />
          ) : (
            <RadioButtonUncheckedOutlinedIcon />
          ),
      },
      {
        label: 'Fufillment settings',
        icon: <RadioButtonUncheckedOutlinedIcon />,
      },
    ];
  }

  return fixedSteps;
};

/** Get right schema with matching object */
const getSettingsSchema = (
  settings: EcommerceSettings,
  activeStep: number
): [ObjectSchema<any>, Fields] => {
  switch (activeStep) {
    case 0:
      return [orderSchema, settings.order];
    case 1:
      return [customerSchema, settings.customer];
    case 2:
      return [itemSchema, settings.item];
    case 3:
      return [inventorySchema, settings.inventory];
    case 4:
      return [fulfillmentSchema, settings.inventory];
    case 5:
      return [posSchema, settings.pos];
    default:
      return [shopifySchema, settings];
  }
};

const EcommerceWizard: React.FC<EcommerceWizardProps> = (props) => {
  const classes = useEcommerceWizardStyle();

  const [activeStep, setActiveStep] = useState(0);

  const [
    settings,
    ,
    saveSettings,
    ,
    setErrors,
    ,
    isLoading,
    ,
    setSettingsExtended,
  ] = useContext(EcommerceContext);

  const location = useLocation();
  const { index } = props;
  const [setting, setSetting] = useState(initialEcommerceSettings);

  const [error, setError] = useState<any>({});

  const [, , , , , , channelName] = useEcommerceSettings(index);

  useEffect(() => {
    if (settings.length > 0) {
      setSetting(settings.find((e, i) => i === index)!);
    }
  }, [settings, index]);

  useEffect(() => {
    setErrors((old) => replaceValueInCollection(old, error, index)!);
  }, [setErrors, error, index]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        _.includes(
          [
            EcommerceLocationHash.Shopify,
            EcommerceLocationHash.Amazon,
            EcommerceLocationHash.WooCommerce,
            EcommerceLocationHash.Salesforce,
            EcommerceLocationHash.BigCommerce,
            EcommerceLocationHash.Magento,
            EcommerceLocationHash.ShipStation,
            EcommerceLocationHash.Ebay,
          ],
          location.hash
        )
      ) {
        scrollToSection(location.hash.replace('#', ''), APP_BAR_HEIGHT);
        clearInterval(interval);
      }
    }, 1000);
  }, [location.hash]);

  const handleNext = useCallback(async () => {
    const [validateSchema, validateSettings] = getSettingsSchema(
      setting,
      activeStep
    );

    // Validate current tab
    const isValid = validateYup(validateSettings, validateSchema, setError);
    if (!isValid) {
      return;
    }

    let valid = true;
    if (
      setting.channel.channel === ChannelType.Shopify &&
      activeStep === 3 && //inventory
      setting.inventory.update
    ) {
      const mappingsWithValues = Object.values(
        setting.inventory.mapping
      ).filter((m) => m.length);

      valid = !mappingsWithValues.length ? false : true;
    }
    if (
      setting.channel.channel === ChannelType.ShipStation &&
      activeStep === 0 && //order
      setting.orderExport.enabled
    ) {
      const mappingsWithValues = Object.values(
        setting.orderExport.locations
      ).filter((m) => m.length);

      valid = !mappingsWithValues.length ? false : true;

      if (!setting.orderExport.status) {
        return;
      }
    }

    if (!valid) {
      setError({
        mappings: 'Please select location',
      });
      showNotification('Please select at least one location', {
        variant: 'error',
      });

      return;
    }

    // If last step then we want to save
    if (
      steps(classes, activeStep, setting.channel.channel).length ===
      activeStep + 1
    ) {
      try {
        await saveSettings(setting.channel.id!);

        setSettingsExtended(
          (old) =>
            replaceValueInCollection(
              old,
              {
                settings: { ...setting },
                isSetupNeeded: !setting.order.location,
              },
              index
            )!
        );
      } catch {
        // Ignore error
      }
      return;
    }
    setActiveStep(activeStep + 1);
  }, [
    activeStep,
    saveSettings,
    setting,
    setError,
    index,
    setSettingsExtended,
    classes,
  ]);

  const handlePrevious = useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep]);

  // navigate from tabs
  const handleChange = (step: number) => {
    if (activeStep + 1 === step) {
      handleNext();
    }
    if (step < activeStep) {
      setActiveStep(step);
    }
  };

  const forms = useMemo(() => {
    let f = [
      <OrderSettingsForm key={0} index={index} />,
      <CustomerSettingsForm key={1} index={index} />,
      <ItemSettingsForm key={2} index={index} />,
      <InventorySettingsForm key={3} index={index} />,
      <FulfillmentSettingsForm key={4} index={index} />,
    ];

    if (setting.channel.channel === ChannelType.Shopify) {
      f.push(<POSSettingsForm key={5} index={index} />);
    } else if (setting.channel.channel === ChannelType.Amazon) {
      f.push(<FBASettingsForm key={5} index={index} />);
    }

    if (setting.channel.channel === ChannelType.ShipStation) {
      f = [
        <OrderSettingsForm key={0} index={index} />,
        <CustomerSettingsForm key={1} index={index} />,
        <FulfillmentSettingsForm key={2} index={index} />,
      ];
    }
    return f;
  }, [setting.channel.channel, index]);

  return (
    <>
      <div id={GetDivIdByChannel(setting.channel.channel!)}>
        <CardWizard
          steps={steps(classes, activeStep, setting.channel.channel)}
          title={'Configure ' + channelName + ' - ' + setting.channel.name}
          activeStep={activeStep}
          onNext={handleNext}
          onPrevious={handlePrevious}
          isLoading={isLoading}
          onChange={handleChange}
          imageUrl={imageLogoUrl(setting.channel.channel!)}
        >
          {forms}
        </CardWizard>
      </div>
    </>
  );
};

export default memo(EcommerceWizard);
