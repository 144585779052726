import React, { memo, useCallback } from 'react';
import { TableCell } from '@mui/material';

import { ItemReorderPointsAlert } from 'services/items';
import { IconNames } from 'ui/theme';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { TextField } from 'ui/components/TextField/TextField';

import { useReorderTabManageAlertsStyle } from './styled';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOSwitch from 'ui/theme/components/FBOSwitch/FBOSwitch';

const AlertsRow: React.FC<RenderCustomRowProps<ItemReorderPointsAlert>> = (
  props
) => {
  const { row: alert, index, setData, onAction } = props;

  const classes = useReorderTabManageAlertsStyle();

  const handleTextFieldChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setData({ ...alert, [event.target.name]: event.target.value }, index);
    },
    [setData, index, alert]
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setData({ ...alert, [event.target.name]: checked }, index);
    },
    [setData, index, alert]
  );

  const handleDeleteClicked = useCallback(() => {
    if (onAction) {
      onAction(index);
    }
  }, [index, onAction]);

  return (
    <>
      <TableCell padding={'none'}>
        <TextField
          onChange={handleTextFieldChange}
          value={alert.contactName}
          name="contactName"
          placeholder="Name"
          className={classes.searchInputOuter}
        />
      </TableCell>
      <TableCell padding={'none'}>
        <TextField
          onChange={handleTextFieldChange}
          value={alert.email}
          name="email"
          placeholder="Email"
          className={classes.searchInputOuter}
        />
      </TableCell>
      <TableCell padding={'none'}>
        <FBOSwitch
          name="receiveEmailNotifications"
          size="medium"
          checked={alert.receiveEmailNotifications}
          onChange={handleCheckboxChange}
          dataQa="receive-email-notifactions-switch"
        />
      </TableCell>
      <TableCell padding={'none'}>
        <FBOSwitch
          name="receiveInStockNotifications"
          size="medium"
          checked={alert.receiveInStockNotifications}
          onChange={handleCheckboxChange}
          dataQa="receive-in-stock-notifications-switch"
        />
      </TableCell>
      <TableCell padding={'none'} align="center">
        <FBOButton
          variant="tertiary"
          color="negative"
          size="small"
          icon={IconNames.TrashCan}
          onClick={handleDeleteClicked}
          data-qa="alerts-row-delete-button"
        />
      </TableCell>
    </>
  );
};

export default memo(AlertsRow);
