import React, { useMemo } from 'react';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { Pagination } from 'services/search';
import { PermissionType } from 'services/permissions';
import { arrayHasAllDeletedObjects } from 'helpers';
import { RepresentativeSearchResultsProps } from './types';
import { createActionBarOptions, REPRESENTATIVE_COLUMNS } from '../../consts';
import { default as RepresentativeRow } from './RepresentativeRow';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const RepresentativesSearchResults: React.FC<
  RepresentativeSearchResultsProps
> = (props) => {
  const {
    activeRepresentativeId,
    handleRepresentativeClick,
    onAddNewPress,
    representatives,
    activeDate,
    onPageAction,
    pagination,
    onPaginationChange,
    isLoadingRepresentatives,
  } = props;

  const allDeletedRepresentatives = useMemo(
    () => arrayHasAllDeletedObjects(representatives),
    [representatives]
  );

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  const resolvedColumns = activeRepresentativeId
    ? REPRESENTATIVE_COLUMNS(activeDate).filter(
        (c) => c.title === 'First Name' || c.title === 'Initials'
      )
    : REPRESENTATIVE_COLUMNS(activeDate);

  return (
    <>
      <FBOTitleBar title="Representatives">
        {activeRepresentativeId !== -1 && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant={!activeRepresentativeId ? 'primary' : 'secondary'}
            color="positive"
            size="medium"
            icon="FBOAddCircle"
            onClick={onAddNewPress}
            data-qa="representatives-new"
            permissions={[PermissionType.RepresentativesCreate]}
          >
            {!activeRepresentativeId ? 'Add New' : ''}
          </FBOButton>
        )}
        <FBOMenuButton
          variant="secondary"
          items={createActionBarOptions(onPageAction, representatives.length)}
          data-qa="representatives-three-dot-menu"
        />
      </FBOTitleBar>

      <ItemsTable
        data={representatives}
        columns={resolvedColumns}
        activeId={activeRepresentativeId}
        onItemClick={handleRepresentativeClick}
        pagination={pagination}
        RenderCustomRow={RepresentativeRow}
        disableSelection={allDeletedRepresentatives}
        onPaginationChange={handlePaginationChange}
        selectableItems={false}
        isLoadingData={isLoadingRepresentatives}
        dataQa="representatives-table"
        emptyTableText={
          !activeRepresentativeId
            ? "ADD NEW ENTRY BY PRESSING 'ADD NEW'"
            : 'NO REPRESENTATIVES AVAILABLE'
        }
        showEnhancedTablePaginationActions={!Boolean(activeRepresentativeId)}
      />
    </>
  );
};

export default RepresentativesSearchResults;
