import React, { memo, useCallback } from 'react';
import { ReportFiltersProps } from '../../types';
import { Box } from '@mui/system';
import { TextField } from 'ui/components/TextField/TextField';
import { ShipAsyncAutocomplete } from 'ui/components/Autocomplete/ShipAutocomplete';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Ship } from 'services/shipping';
import {
  useHandleCheckboxChange,
  useHandleTextFieldChange,
} from 'services/forms/hooks';

const BOLFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters, errors } = props;

  const handleTextFieldChange = useHandleTextFieldChange(
    setParameters,
    parameters
  );

  const handleShipChange = useCallback(
    (ship: Ship | null) =>
      setParameters((old) => ({ ...old, ShipId: ship?.id || null })),
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  return (
    <Box px={2} pb={0} pt={1}>
      <Box mb={1}>
        <ShipAsyncAutocomplete
          label="Ship Number"
          value={parameters.ShipId ?? null}
          placeholder="Select ship number"
          onChange={handleShipChange}
          error={!!errors.ShipId}
          helperText={errors.ShipId}
          dataQa="bol-filter-ship-number"
        />
      </Box>
      <Box mb={1}>
        <TextField
          className="redesign"
          variant="standard"
          label="3rd Party Name"
          placeholder="Enter 3rd party name"
          name="ThirdPartyName"
          type="text"
          value={parameters.ThirdPartyName ?? null}
          onChange={handleTextFieldChange}
          fullWidth
          dataQa="bol-filter-third-party-name"
        />
      </Box>
      <Box mb={1}>
        <TextField
          className="redesign"
          variant="standard"
          label="3rd Party Address"
          placeholder="Enter 3rd party address"
          name="ThirdPartyAddress"
          type="text"
          value={parameters.ThirdPartyAddress ?? null}
          onChange={handleTextFieldChange}
          fullWidth
          dataQa="bol-filter-third-party-address"
        />
      </Box>
      <Box mb={1}>
        <TextField
          className="redesign"
          variant="standard"
          label="3rd Party City"
          placeholder="Enter 3rd party city"
          name="ThirdPartyCity"
          type="text"
          value={parameters.ThirdPartyCity ?? null}
          onChange={handleTextFieldChange}
          fullWidth
          dataQa="bol-filter-third-party-city"
        />
      </Box>
      <Box mb={1}>
        <TextField
          className="redesign"
          variant="standard"
          label="3rd Party State"
          placeholder="Enter 3rd party state"
          name="ThirdPartyState"
          type="text"
          value={parameters.ThirdPartyState ?? null}
          onChange={handleTextFieldChange}
          fullWidth
          dataQa="bol-filter-third-party-state"
        />
      </Box>
      <Box mb={1}>
        <TextField
          className="redesign"
          variant="standard"
          label="3rd Party Zip"
          placeholder="Enter 3rd party zip"
          name="ThirdPartyZip"
          type="text"
          value={parameters.ThirdPartyZip ?? null}
          onChange={handleTextFieldChange}
          fullWidth
          dataQa="bol-filter-third-party-zip"
        />
      </Box>
      <Box mb={1}>
        <TextField
          className="redesign"
          variant="standard"
          label="3rd Party Country"
          placeholder="Enter 3rd party country"
          name="ThirdPartyCountry"
          type="text"
          value={parameters.ThirdPartyCountry ?? null}
          onChange={handleTextFieldChange}
          fullWidth
          dataQa="bol-filter-third-party-country"
        />
      </Box>
      <Box mb={1}>
        <TextField
          className="redesign"
          variant="standard"
          label="Special Instructions"
          placeholder="Enter special instructions"
          name="SpecialInstructions"
          type="text"
          value={parameters.SpecialInstructions ?? null}
          onChange={handleTextFieldChange}
          fullWidth
          dataQa="bol-filter-special-instructions"
        />
      </Box>
      <Box mb={1}>
        <TextField
          className="redesign"
          variant="standard"
          label="Trailer Number"
          placeholder="Enter trailer number"
          name="TrailerNumber"
          type="text"
          value={parameters.TrailerNumber ?? null}
          onChange={handleTextFieldChange}
          fullWidth
          dataQa="bol-filter-trailer-number"
        />
      </Box>
      <Box mb={1}>
        <TextField
          className="redesign"
          variant="standard"
          label="Pro Number"
          placeholder="Enter pro number"
          name="ProNumber"
          type="text"
          value={parameters.ProNumber ?? null}
          onChange={handleTextFieldChange}
          fullWidth
          dataQa="bol-filter-pro-number"
        />
      </Box>
      <Box mb={1}>
        <TextField
          className="redesign"
          variant="standard"
          label="Seal Number(s)"
          placeholder="Enter seal number"
          name="SealNumbers"
          type="text"
          value={parameters.SealNumbers ?? null}
          onChange={handleTextFieldChange}
          fullWidth
          dataQa="bol-filter-seal-number"
        />
      </Box>
      <Box mb={1}>
        <TextField
          className="redesign"
          variant="standard"
          label="Additional Order Info"
          placeholder="Enter additional order Info"
          name="AdditionalOrderInfo"
          type="text"
          value={parameters.AdditionalOrderInfo ?? null}
          onChange={handleTextFieldChange}
          fullWidth
          dataQa="bol-filter-additional-order-info"
        />
      </Box>
      <Box mb={1}>
        <TextField
          className="redesign"
          variant="standard"
          label="NMFC#"
          placeholder="Enter NMFC#"
          name="NmfcNumber"
          type="text"
          value={parameters.NmfcNumber ?? null}
          onChange={handleTextFieldChange}
          fullWidth
          dataQa="bol-filter-nmfc-number"
        />
      </Box>
      <Box mb={1}>
        <TextField
          className="redesign"
          variant="standard"
          label="Class"
          placeholder="Enter class"
          name="Class"
          type="text"
          value={parameters.Class ?? null}
          onChange={handleTextFieldChange}
          fullWidth
          dataQa="bol-filter-class"
        />
      </Box>
      <FormControlLabel
        className="redesign"
        sx={{ marginBottom: '8px' }}
        control={
          <Checkbox
            className="redesign"
            checked={Boolean(parameters.HazardousMaterials)}
            onChange={handleCheckboxChange}
            name="HazardousMaterials"
            color="primary"
            data-qa="bol-filter-hazardous-materials"
          />
        }
        label="Hazardous Materials"
      />
      <FormControlLabel
        className="redesign"
        sx={{ marginBottom: '8px' }}
        control={
          <Checkbox
            className="redesign"
            checked={Boolean(parameters.ShowBolBarcode)}
            onChange={handleCheckboxChange}
            name="ShowBolBarcode"
            color="primary"
            data-qa="bol-filter-show-bol-barcode"
          />
        }
        label="Show BOL Barcode"
      />
      <FormControlLabel
        className="redesign"
        sx={{ marginBottom: '8px' }}
        control={
          <Checkbox
            className="redesign"
            checked={Boolean(parameters.ShowProNumBarcode)}
            onChange={handleCheckboxChange}
            name="ShowProNumBarcode"
            color="primary"
            data-qa="bol-filter-show-pro-num-barcode"
          />
        }
        label="Show Pro Num Barcode"
      />
      <FormControlLabel
        className="redesign"
        sx={{ marginBottom: '8px' }}
        control={
          <Checkbox
            className="redesign"
            checked={Boolean(parameters.ShowTotalQty)}
            onChange={handleCheckboxChange}
            name="ShowTotalQty"
            color="primary"
            data-qa="bol-filter-show-total-qty"
          />
        }
        label="Show Total Qty"
      />
      <FormControlLabel
        className="redesign"
        sx={{ marginBottom: '8px' }}
        control={
          <Checkbox
            className="redesign"
            checked={Boolean(parameters.ShowTotalWeight)}
            onChange={handleCheckboxChange}
            name="ShowTotalWeight"
            color="primary"
            data-qa="bol-filter-show-total-weight"
          />
        }
        label="Show Total Weight"
      />
    </Box>
  );
};

export default memo(BOLFilters);
