import React, { useCallback } from 'react';
import { Box, InputAdornment, TableCell } from '@mui/material';
import _ from 'lodash';

import { stopInputPropagation } from 'helpers';
import { IconNames, colorPalette } from 'ui/theme';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { TextField } from 'ui/components/TextField/TextField';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';

import { RopItem } from '../../../types';
import { useCustomRowStyle } from './styled';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import Icon from 'ui/components/Icon/Icon';

export const CustomRow: React.FC<RenderCustomRowProps<RopItem>> = (props) => {
  const { row, setData, onAction, index } = props;

  const classes = useCustomRowStyle();

  const handleTextChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setData(
      {
        ...row,
        [ev.target.name]: ev.target.value ? parseInt(ev.target.value) : 0,
      },
      index
    );
  };

  const handleOnAction = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      if (onAction) {
        onAction(row.id);
      }
    },
    [row.id, onAction]
  );

  return (
    <>
      <TableCell className={classes.tableCell}>
        <Box width={224}>
          <ImageItemBox
            source={_.get(row, 'item.defaultImage', null)}
            name={_.get(row, 'item.name', null)}
            description={_.get(row, 'item.description', null)}
          />
        </Box>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Box width={134}>
          <TextField
            type="number"
            value={row.safetyStock}
            name="safetyStock"
            placeholder="0"
            onChange={handleTextChange}
            onClick={stopInputPropagation}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Days</InputAdornment>
              ),
            }}
            inputProps={{
              'data-qa': `saefty-stock-input-${_.get(row, 'item.name', '')}`,
            }}
          />
        </Box>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Box width={134}>
          <TextField
            type="number"
            value={row.leadTime}
            name="leadTime"
            placeholder="0"
            onChange={handleTextChange}
            onClick={stopInputPropagation}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Days</InputAdornment>
              ),
            }}
            inputProps={{
              'data-qa': `lead-time-input-${_.get(row, 'item.name', '')}`,
            }}
          />
        </Box>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Box width={134}>
          <TextField
            type="number"
            value={row.orderInterval}
            name="orderInterval"
            placeholder="0"
            onChange={handleTextChange}
            onClick={stopInputPropagation}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Days</InputAdornment>
              ),
            }}
            inputProps={{
              'data-qa': `order-interval-input-${_.get(row, 'item.name', '')}`,
            }}
          />
        </Box>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Box width={134}>
          <TextField
            type="number"
            value={row.notifyAt}
            name="notifyAt"
            placeholder="0"
            onChange={handleTextChange}
            onClick={stopInputPropagation}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Days</InputAdornment>
              ),
            }}
            inputProps={{
              'data-qa': `notify-at-input-${_.get(row, 'item.name', '')}`,
            }}
          />
        </Box>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <FBOButton
          variant="tertiary"
          color="neutral"
          size="small"
          icon={IconNames.Bell}
          onClick={handleOnAction}
          data-qa={`notifications-row-item-${_.get(row, 'item.name', '')}`}
        />
      </TableCell>
      <TableCell className={classes.tableCell}>
        <Box
          display="flex"
          alignItems="center"
          width={24}
          height="100%"
          color={colorPalette.fishbowlCorporateGray3}
        >
          <Icon
            name={IconNames.SubCircle}
            color={colorPalette.redesign.contentPrimary}
            style={{ width: '24px', padding: '2px' }}
          />
        </Box>
      </TableCell>
    </>
  );
};
