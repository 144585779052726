import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';

import TabPanel from 'ui/components/TabPanel/TabPanel';
import { CustomerCarriersBillingType } from 'services/shipping';

import { useCardRowStyle } from './styled';
import { PurchaseLabelRatesProps } from './types';
import RatesCustomRow from './RatesCustomRow';
import { usePurchaseLabelExtra } from '../../PurchaseLabelProvider';

enum TabRate {
  USPS,
  UPS,
  FedEx,
}

const PurchaseLabelRates: React.FC<PurchaseLabelRatesProps> = (props) => {
  const { rates } = props;

  const classes = useCardRowStyle();

  const { purchaseLabelExtra, setPurchaseLabelExtra } = usePurchaseLabelExtra();

  const [activeTab, setActiveTab] = useState<TabRate | boolean>(false);

  const isUspsRateDisabled = useMemo(() => {
    return (
      purchaseLabelExtra.billingType ===
        CustomerCarriersBillingType.Recipient ||
      purchaseLabelExtra.billingType ===
        CustomerCarriersBillingType.Third_Party ||
      (!!purchaseLabelExtra.packagingCarrier &&
        (purchaseLabelExtra.packagingCarrier === 'fedex' ||
          purchaseLabelExtra.packagingCarrier === 'ups'))
    );
  }, [purchaseLabelExtra.billingType, purchaseLabelExtra.packagingCarrier]);

  const isUpsRateDisabled = useMemo(() => {
    return (
      !!purchaseLabelExtra.packagingCarrier &&
      purchaseLabelExtra.packagingCarrier === 'fedex'
    );
  }, [purchaseLabelExtra.packagingCarrier]);

  const isFedExRateDisabled = useMemo(() => {
    return (
      !!purchaseLabelExtra.packagingCarrier &&
      purchaseLabelExtra.packagingCarrier === 'ups'
    );
  }, [purchaseLabelExtra.packagingCarrier]);

  // activate first available tab
  useEffect(() => {
    if (!isUspsRateDisabled) {
      setActiveTab(TabRate.USPS);
      return;
    }
    if (!isUpsRateDisabled) {
      setActiveTab(TabRate.UPS);
      return;
    }
    if (!isFedExRateDisabled) {
      setActiveTab(TabRate.FedEx);
      return;
    }
    setActiveTab(false);
  }, [isUspsRateDisabled, isUpsRateDisabled, isFedExRateDisabled]);

  const handleActiveTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  const handleSelectService = (carrierCode: string, serviceCode: string) => {
    setPurchaseLabelExtra((old) => ({
      ...old,
      carrierCode,
      serviceCode,
    }));
  };

  return (
    <>
      <Box className={classes.sidebar}>
        {rates.length === 0 ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="100%"
            flexDirection="column"
          >
            <Typography variant="h4">No rates</Typography>
            <Typography variant="body1">
              Cannot finish purchase label without rates
            </Typography>
          </Box>
        ) : (
          <>
            <Tabs
              value={activeTab}
              onChange={handleActiveTabChange}
              indicatorColor="primary"
            >
              <Tab
                label="USPS"
                disabled={isUspsRateDisabled}
                value={TabRate.USPS}
              />
              <Tab
                label="UPS"
                disabled={isUpsRateDisabled}
                value={TabRate.UPS}
              />
              <Tab
                label="FedEx"
                disabled={isFedExRateDisabled}
                value={TabRate.FedEx}
              />
            </Tabs>
            <Box display="flex" flexDirection="column" width="100%">
              {rates.map((rate, index) => (
                <TabPanel
                  value={activeTab}
                  index={index}
                  flexGrow
                  noSpacing
                  key={`tab_panel_${rate.carrierCode}`}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    flexGrow={1}
                  >
                    {rate.services.length > 0 ? (
                      rate.services.map((service) => (
                        <RatesCustomRow
                          key={`row_${service.serviceCode}`}
                          service={service}
                          selected={
                            purchaseLabelExtra.serviceCode ===
                            service.serviceCode
                          }
                          onSelect={() =>
                            handleSelectService(
                              rate.carrierCode,
                              service.serviceCode
                            )
                          }
                          billingType={purchaseLabelExtra.billingType}
                        />
                      ))
                    ) : (
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width="100%"
                        flexGrow={1}
                      >
                        <Typography variant="h4">No rates available</Typography>
                      </Box>
                    )}
                  </Box>
                </TabPanel>
              ))}
            </Box>
            {!rates.length && (
              <TabPanel value={activeTab} index={activeTab} flexGrow noSpacing>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  width="100%"
                  flexGrow={1}
                >
                  <Typography variant="h4">No rates available</Typography>
                </Box>
              </TabPanel>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default memo(PurchaseLabelRates);
