import React, {
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
  memo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab } from '@mui/material';
import _ from 'lodash';

import { TabPanel } from 'ui/components/TabPanel';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import {
  postPickVoid,
  getPick,
  transformPick,
  updatePick,
  Pick,
  PickItem,
  PickItemStatus,
} from 'services/picking';
import { getSettingsPicking } from 'services/settings/picking/redux';
import {
  postPickCommit,
  postPickFinish,
  postPickStart,
  unGroupPick,
} from 'services/picking/api';
import { showNotification } from 'services/api';
import { Errors, validateYup } from 'services/forms/validation';
import { customFieldsYupSchema } from 'ui/components/CustomFields/CustomFields';
import { PermissionType } from 'services/permissions';
import {
  clearModuleNavigation,
  ModuleNavigationType,
  updateModuleNavigation,
} from 'services/moduleNavigation';
import { ReportId } from 'services/reports';
import { ReportsModal } from 'ui/components/Modal/ReportsModal';
import { colorPalette } from 'ui/theme';

import { PickingDetailsCardProps } from './types';
import { createActionBarOptions, defaultActivePick } from './consts';
import {
  pickHasExpiredItems,
  shouldShowCommit,
  shouldShowFinish,
  shouldShowStart,
  shouldShowUnGroup,
  shouldShowVoid,
} from './helpers';
import { yupPickingGeneralSchema } from './validations';
import { getErrorMessage } from 'helpers';
import { logErrorCtx } from 'app/logging';

// FF CU-863hakwb6
import { useFlags } from 'helpers/useFlags';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';
import GeneralTab from './components/GeneralTab/GeneralTab';
import FBOPickCommitWizard from './components/PickCommitWizard/FBOPickCommitWizard';
import FBOPickFinishWizard from './components/PickFinishWizard/FBOPickFinishWizard';
// FF CU-863hakwb6

const PickingDetailsCard: React.FunctionComponent<PickingDetailsCardProps> = (
  props
) => {
  const { activePickId, onClose, fetchSearchResults } = props;

  const pickingSettings = useSelector(getSettingsPicking);

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [activePick, setActivePick] = useState<Pick>(defaultActivePick);
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [showCommitWizard, setShowCommitWizard] = useState(false);
  const [showFinishWizard, setShowFinishWizard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showExpiredItemsConfirmation, setShowExpiredItemsConfirmation] =
    useState(false);
  const [customFieldsErrors, setCustomFieldsErrors] = useState<Errors>({});
  const [showReportModal, setShowReportModal] = useState(false);
  const oldState = useRef<Pick | null>(defaultActivePick);

  const numberOfAvailablePickItems = useMemo(
    () =>
      activePick.pickItems.filter(
        (i) =>
          i.status === PickItemStatus.Available ||
          i.status === PickItemStatus.Short ||
          i.status === PickItemStatus.Started
      ).length,
    [activePick.pickItems]
  );

  // FF CU-863hakwb6
  const flags = useFlags();
  const expandsOptimizationFlag = flags.expandsOptimization;
  // FF CU-863hakwb6

  useEffect(() => {
    if (!activePickId) {
      setActivePick(defaultActivePick);
      return;
    }

    const asyncFce = async () => {
      setIsLoading(true);
      let newPick;
      try {
        newPick = await getPick(activePickId);
      } catch (e) {
        const message = getErrorMessage(e);
        showNotification(`${message} - picking couldn't be loaded.`, {
          variant: 'error',
        });
        setIsLoading(false);

        onClose();
        return;
      }
      oldState.current = newPick;
      setActivePick(newPick);
      setIsLoading(false);
    };
    asyncFce();

    setActiveTab(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePickId]);

  useEffect(() => {
    if (!activePick.id || activePick.id === -1) {
      dispatch(clearModuleNavigation(ModuleNavigationType.Sales));
      return;
    }

    if (_.isEmpty(activePick.salesOrders)) {
      dispatch(
        updateModuleNavigation(ModuleNavigationType.Purchase, {
          purchaseOrderId:
            activePick.purchaseOrders.length > 0
              ? activePick.purchaseOrders[0].id
              : undefined,
          shipIds: activePick.shipments.map((s) => s.id),
        })
      );
    } else {
      dispatch(
        updateModuleNavigation(ModuleNavigationType.Sales, {
          salesOrderIds: activePick.salesOrders.map((s) => s.id),
          pickIds: [activePick.id],
          shipIds: activePick.shipments.map((s) => s.id),
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePick.status, activePick.id, activePick.shipments]);

  const handleSaveClicked = useCallback(async () => {
    oldState.current = activePick;

    const isValid = validateYup(
      activePick,
      yupPickingGeneralSchema,
      setValidationErrors
    );

    const isCustomFieldsValid = validateYup(
      activePick.customFields,
      customFieldsYupSchema,
      setCustomFieldsErrors
    );

    if (!isCustomFieldsValid || !isValid) {
      return false;
    }
    setIsLoading(true);

    try {
      await updatePick(activePick);
      await fetchSearchResults();
    } catch {
      setIsLoading(false);
      return false;
    }

    setIsLoading(false);
    return true;
  }, [fetchSearchResults, activePick, onClose]);

  const handleVoidClicked = useCallback(async () => {
    setIsLoading(true);
    let newPick;
    try {
      newPick = await postPickVoid(activePick.id, expandsOptimizationFlag);
      newPick = newPick.data;
      if (expandsOptimizationFlag) {
        newPick = await getPick(activePickId!);
      }
    } catch {
      setIsLoading(false);
      return;
    }
    const updatedPick = transformPick(newPick);
    oldState.current = updatedPick;
    setActivePick(updatedPick);

    setIsLoading(false);
    await fetchSearchResults();
  }, [activePick.id, fetchSearchResults]);

  const finishPick = useCallback(async () => {
    setIsLoading(true);

    const pickItemsToFinish = activePick.pickItems
      .filter((i) => i.status !== PickItemStatus.Finished)
      .map((i) => ({
        ...i,
        amount: i.quantity,
      }));

    try {
      let updatedPick = await postPickFinish(
        activePickId!,
        pickItemsToFinish,
        expandsOptimizationFlag
      );
      if (expandsOptimizationFlag) {
        updatedPick = await getPick(activePickId!);
      }
      oldState.current = updatedPick;
      setActivePick(updatedPick);
    } catch (err) {
      setIsLoading(false);
      const error = err as Error;
      logErrorCtx('Pick was not finished', {
        error,
        stackTrace: error.stack,
        title: 'Posting of Picks was not Finished',
        description: 'Api Call to post Picks did not finish properly',
        component: 'PickingDetailsCard',
      });
    }

    setIsLoading(false);
    fetchSearchResults();
  }, [activePickId, activePick.pickItems, fetchSearchResults]);

  const handleFinishClicked = useCallback(() => {
    if (numberOfAvailablePickItems > 0) {
      setShowFinishWizard(true);
      return;
    }

    if (
      pickingSettings.pickingExpiredItemWarning &&
      pickHasExpiredItems(activePick.pickItems)
    ) {
      setShowExpiredItemsConfirmation(true);
      return;
    }

    finishPick();
  }, [
    activePick,
    numberOfAvailablePickItems,
    pickingSettings.pickingExpiredItemWarning,
    finishPick,
  ]);

  const handleActiveTabChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  const handleCommitClicked = useCallback(() => {
    if (numberOfAvailablePickItems) {
      setShowCommitWizard(true);
    }
  }, [numberOfAvailablePickItems]);

  const handleCommitModalCloseClicked = useCallback(() => {
    setShowCommitWizard(false);
  }, []);

  const handleCommitWizardApply = useCallback(
    async (pickItems: PickItem[]) => {
      const commitPickItems = pickItems.filter((i) => i.amount);

      try {
        let updatedPick = await postPickCommit(
          activePickId!,
          commitPickItems,
          expandsOptimizationFlag
        );
        if (expandsOptimizationFlag) {
          updatedPick = await getPick(activePickId!);
        }

        oldState.current = updatedPick;
        setActivePick(updatedPick);
      } catch (e) {
        return;
      }

      setShowCommitWizard(false);
      fetchSearchResults();
    },
    [activePickId, fetchSearchResults]
  );

  const handleFinishWizardCloseClicked = useCallback(
    () => setShowFinishWizard(false),
    []
  );

  const handleFinishWizardApply = useCallback(
    async (pickItems: PickItem[]) => {
      const finishPickItems = pickItems.filter((i) => i.amount);

      try {
        let updatedPick = await postPickFinish(
          activePickId!,
          finishPickItems,
          expandsOptimizationFlag
        );
        if (expandsOptimizationFlag) {
          updatedPick = await getPick(activePickId!);
        }
        oldState.current = updatedPick;
        setActivePick(updatedPick);
      } catch (e) {
        const error = e as Error;
        const message = getErrorMessage(error);
        showNotification(`Couldn't Finish Sales Picking - ${message}`, {
          variant: 'error',
        });
        logErrorCtx('Sales Item Picking Failed', {
          error,
          stackTrace: error.stack,
          description: 'Failed when finishing item commit process',
          component: 'PickingPage -> PickingDetailsCard',
        });
        return;
      }

      setShowFinishWizard(false);
      fetchSearchResults();
    },
    [activePickId, fetchSearchResults]
  );

  const handleExpiredItemsConfirmClicked = useCallback(() => {
    finishPick();
    setShowCommitWizard(false);
    setShowExpiredItemsConfirmation(false);
  }, [finishPick]);

  const handleStartClicked = useCallback(async () => {
    setIsLoading(true);
    let updatedPick;
    try {
      await postPickStart(activePick.id, expandsOptimizationFlag);
      //TODO: to be removed once the above code returns PickItem.Item.images expand
      updatedPick = await getPick(activePickId!);
    } catch {
      setIsLoading(false);
      return;
    }

    oldState.current = updatedPick;
    setActivePick(updatedPick);
    setIsLoading(false);
  }, [activePick.id]);

  const handleUngroupClicked = useCallback(async () => {
    setIsLoading(true);

    try {
      await unGroupPick(activePick.id);
    } catch (e) {
      const error = e as Error;
      const message = getErrorMessage(error);
      showNotification(`Failed to Un-Group Sale Picks - ${message}`, {
        variant: 'error',
      });
      logErrorCtx('Filed to UnGroup the Sale Picks', {
        error,
        stackTrace: error.stack,
        description: "Can't remove grouping of sale item picks",
        component: 'PickingPage -> PickingDetailsCard',
      });
      setIsLoading(false);
      return;
    }

    onClose();
    fetchSearchResults();
    setIsLoading(false);
  }, [activePick.id, onClose, fetchSearchResults]);

  return (
    <DetailsCard
      onSubmit={handleSaveClicked}
      isLoading={isLoading}
      state={activePick}
      oldState={oldState}
    >
      <FBOTitleBar title={activePick.number} status={activePick.status}>
        {shouldShowUnGroup(activePick) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="picking-ungroup"
            permissions={[PermissionType.PickingEdit]}
            onClick={handleUngroupClicked}
          >
            Ungroup
          </FBOButton>
        )}
        {shouldShowStart(activePick) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="picking-start"
            permissions={[PermissionType.PickingEdit]}
            onClick={handleStartClicked}
          >
            Start
          </FBOButton>
        )}
        {shouldShowCommit(numberOfAvailablePickItems, activePick.status) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="picking-commit"
            permissions={[PermissionType.PickingCommit]}
            onClick={handleCommitClicked}
          >
            Commit
          </FBOButton>
        )}
        {shouldShowFinish(activePick.status) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            data-qa="picking-finish"
            permissions={[PermissionType.PickingFinish]}
            onClick={handleFinishClicked}
          >
            Finish
          </FBOButton>
        )}
        {shouldShowVoid(activePick.status) && (
          <FBOButton
            sx={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            onClick={handleVoidClicked}
            data-qa="picking-void"
            permissions={[PermissionType.PickingEdit]}
          >
            Void
          </FBOButton>
        )}
        <FBOButton
          sx={{ marginRight: '8px' }}
          variant="secondary"
          color="positive"
          size="medium"
          data-qa="picking-save"
          onClick={() => handleSaveClicked()}
          permissions={[PermissionType.PickingEdit]}
        >
          Save
        </FBOButton>
        <FBOMenuButton
          style={{
            marginRight: '8px',
            color: colorPalette.redesign.contentSecondary,
          }}
          variant="tertiary"
          items={createActionBarOptions(activePickId, () =>
            setShowReportModal(true)
          )}
          data-qa="picking-detail-three-dot-menu"
        />
        <FBOButton
          variant="tertiary"
          color="neutral"
          size="medium"
          icon="FBOClose"
          onClick={onClose}
          data-qa="picking-close"
        />
      </FBOTitleBar>

      <Tabs
        value={activeTab}
        onChange={handleActiveTabChange}
        indicatorColor="primary"
        className="redesign"
      >
        <Tab label="General" />
      </Tabs>
      <TabPanel
        value={activeTab}
        index={0}
        flexGrow
        noSpacing
        style={{ flexDirection: 'column' }}
      >
        <GeneralTab
          activePick={activePick}
          setActivePick={setActivePick}
          validationErrors={validationErrors}
          pickId={activePickId}
          fetchSearchResults={fetchSearchResults}
          customFieldsErrors={customFieldsErrors}
          oldState={oldState}
        />
      </TabPanel>
      <FBOPickCommitWizard
        show={showCommitWizard}
        pickItems={activePick.pickItems}
        onClose={handleCommitModalCloseClicked}
        onSave={handleCommitWizardApply}
      />
      <FBOPickFinishWizard
        show={showFinishWizard}
        pickItems={activePick.pickItems}
        onClose={handleFinishWizardCloseClicked}
        onSave={handleFinishWizardApply}
      />

      {pickingSettings.pickingExpiredItemWarning && (
        <ConfirmationModal
          open={showExpiredItemsConfirmation}
          title="Finish Pick"
          body="Some items are expired. Are you sure you want to finish this pick?"
          onCancelClicked={() => setShowExpiredItemsConfirmation(false)}
          onConfirmClicked={handleExpiredItemsConfirmClicked}
          confirmLabel="Finish"
          confirmButtonRed
        />
      )}
      <ReportsModal
        isOpen={showReportModal}
        reportId={ReportId.Pick}
        params={{ pickId: activePickId }}
        onClose={() => setShowReportModal(false)}
        autoGenerate
      />
    </DetailsCard>
  );
};

export default memo(PickingDetailsCard);
