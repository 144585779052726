import { defaultMaximumPagination, RequestConfig } from 'helpers';
import { AccountType } from 'services/accounting';
import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';
import { transformQbdAccount } from './transformations';
import { QbdAccount } from './types';

export const disconnectQbd = async (clearMappings: boolean = false) => {
  await createApiCall({
    path: `/integrations/qbd_disconnect?clearMappings=${clearMappings}`,
    method: 'GET',
  })();
};

export const fetchQbdAccountsAPI = async (
  config: RequestConfig,
  accountType: AccountType | string | null,
  listId: string[] | null
): Promise<DataWithPagination<QbdAccount>> => {
  const {
    pagination = defaultMaximumPagination,
    customQuickSearchColumns = ['name'],
    quickSearchValue,
  } = config;
  let path = `/integrations/qbd_accounts`;

  if (accountType) {
    path += `?accountType=${accountType}`;
  }

  if (listId && listId.length > 0) {
    path += (accountType ? `&` : `?`) + `listId=${listId}`;
  }

  if (quickSearchValue) {
    path +=
      (accountType || listId ? `&` : `?`) +
      `quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${quickSearchValue}`;
  }

  const res = await paginatedApiCall(path, pagination, transformQbdAccount);
  return res;
};
