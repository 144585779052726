import React, { memo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BigCommerceLogo, IconNames } from 'ui/theme';
import {
  fetchEcommerceChannels,
  getAllECommerceSettingsByChannel,
} from 'services/integrations/ecommerce/redux';
import {
  ChannelType,
  disconnectChannel,
} from 'services/integrations/ecommerce';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';

import { BigCommerceCardProps } from './types';
import { Card } from '..';
import { CardButton } from '../IntegrationButtons/CardButton';
import { showNotification } from 'services/api';
import { IconButtonWithActions } from 'ui/components/Button/IconButtonWithActions';
import { MenuItem } from 'ui/components/Menu';

const BigCommerceCard: React.FC<BigCommerceCardProps> = (props) => {
  const { connectionValid } = props;

  const dispatch = useDispatch();

  const ecommerceSettings = useSelector(
    getAllECommerceSettingsByChannel(ChannelType.BigCommerce)
  );

  const [showDisconnectModal, setShowDisconnectModal] =
    useState<boolean>(false);
  const [showMaxIntegrationModal, setShowMaxIntegrationModal] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeChannel, setActiveChannel] = useState<{
    id: number;
    name: string;
  }>();

  const handleBigCoomerceConnectClick = useCallback(() => {
    if (!connectionValid) {
      setShowMaxIntegrationModal(true);
      return;
    }

    const url = 'https://login.bigcommerce.com/login';
    window.open(url, '_blank', 'noopener');
  }, [connectionValid]);

  const disconnectBigCoomerce = useCallback(async () => {
    if (activeChannel) {
      setIsLoading(true);
      try {
        await disconnectChannel(activeChannel.id!);
        // Lint skip to be removed
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(fetchEcommerceChannels());
      } catch (e) {
        const error = e as Error;

        showNotification(`Disconnect from channel failed - ${error.message}`, {
          variant: 'error',
        });
      }

      setIsLoading(false);
      setShowDisconnectModal(false);
    } else {
      showNotification(`Unable to disconnect! No channel id provided!`, {
        variant: 'error',
      });
    }
  }, [dispatch, activeChannel]);

  const createItemsSearchActionBarOptions = (): MenuItem[] => {
    return ecommerceSettings.map((channel) => {
      return {
        label: channel.channel.name!,
        onOptionClick: () => {
          setActiveChannel({
            id: channel.channel.id!,
            name: channel.channel.name!,
          });
          setShowDisconnectModal(true);
        },
      };
    });
  };

  return (
    <>
      <Card
        imageUrl={BigCommerceLogo}
        cardId="bigcommerce"
        isLoading={false}
        description="Manage orders while keeping inventory levels up to date with BigCommerce."
        title="BigCommerce"
      >
        <>
          {ecommerceSettings.length > 0 ? (
            <IconButtonWithActions
              icon={IconNames.MoreVertical}
              iconSize={16}
              text="Disconnect"
              boxStyle
              items={createItemsSearchActionBarOptions()}
            />
          ) : null}
          <CardButton
            onClick={handleBigCoomerceConnectClick}
            variant="primary"
            color="positive"
            dataQa="big-commerce-connect"
            label="Connect"
          />
        </>
      </Card>
      <ConfirmationModal
        open={showMaxIntegrationModal}
        title="Maximum number of integrations has been reached!"
        onConfirmClicked={() => setShowMaxIntegrationModal(false)}
        onCancelClicked={() => setShowMaxIntegrationModal(false)}
        body="Please contact sales to add additional integrations."
      />
      <ConfirmationModal
        open={showDisconnectModal}
        title="Disconnect BigCommerce"
        body={
          'Are you sure you want to disconnect BigCommerce channel ' +
          (activeChannel ? activeChannel!.name : null) +
          '?'
        }
        isLoading={isLoading}
        onCancelClicked={() => setShowDisconnectModal(false)}
        onConfirmClicked={disconnectBigCoomerce}
      />
    </>
  );
};

export default memo(BigCommerceCard);
