import React, { useCallback, useMemo } from 'react';

import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { Pagination } from 'services/search';
import { PermissionType } from 'services/permissions';
import { arrayHasAllDeletedObjects } from 'helpers';

import { VendorsSearchResultsProps } from './types';
import VendorsRow from './VendorsRow';
import { VENDOR_COLUMNS, VENDOR_COLUMNS_SMALL } from '../../consts';
import { createVendorsSearchActionBarOptions } from './consts';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const VendorsSearchResults: React.FC<VendorsSearchResultsProps> = (props) => {
  const {
    activeVendorId,
    handleVendorClick,
    onAddNewPress,
    vendors,
    isLoadingVendors,
    selectedVendors,
    pagination,
    activeDate,
    setSelectedVendors,
    onPaginationChange,
    onDeleteClicked,
    onAction,
    onVendorPageAction,
  } = props;

  const resolvedColumns = activeVendorId
    ? VENDOR_COLUMNS_SMALL
    : VENDOR_COLUMNS(activeDate);

  const allDeletedVendors = useMemo(
    () => arrayHasAllDeletedObjects(vendors),
    [vendors]
  );

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  const handleSelectClick = useSelectedItemsChanges(
    selectedVendors,
    setSelectedVendors
  );

  const handleAction = useCallback(
    (action: any) => {
      onAction(action.type, action.value);
    },
    [onAction]
  );

  return (
    <>
      <FBOTitleBar title="Vendors">
        {selectedVendors.length > 0 && !activeVendorId && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant="secondary"
            color="negative"
            size="medium"
            icon="TrashCan"
            data-qa="vendor-delete"
            permissions={[PermissionType.VendorsDelete]}
            onClick={onDeleteClicked}
          >
            Delete
          </FBOButton>
        )}
        {activeVendorId !== -1 && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant={!activeVendorId ? 'primary' : 'secondary'}
            color="positive"
            size="medium"
            icon="FBOAddCircle"
            onClick={onAddNewPress}
            data-qa="vendor-add-new"
            permissions={[PermissionType.VendorsCreate]}
          >
            {!activeVendorId ? 'Add New' : ''}
          </FBOButton>
        )}
        <FBOMenuButton
          variant="secondary"
          items={createVendorsSearchActionBarOptions(onVendorPageAction)}
          data-qa="vendors-three-dot-menu"
        />
      </FBOTitleBar>
      <ItemsTable
        data={vendors}
        columns={resolvedColumns}
        activeId={activeVendorId}
        onSelectedChange={handleSelectClick}
        selectedItems={selectedVendors}
        onItemClick={handleVendorClick}
        selectableItems={!activeVendorId}
        isLoadingData={isLoadingVendors}
        disableSelection={allDeletedVendors}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        RenderCustomRow={VendorsRow}
        onAction={handleAction}
        dataQa="vendor-table"
        emptyTableText={
          !activeVendorId
            ? "ADD NEW ENTRY BY PRESSING 'ADD NEW'"
            : 'NO VENDORS AVAILABLE'
        }
        showEnhancedTablePaginationActions={!Boolean(activeVendorId)}
      />
    </>
  );
};

export default VendorsSearchResults;
