import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TableCell, Grid, Link, Box, Popover, Typography } from '@mui/material';
import _ from 'lodash';

import { colorPalette, IconNames } from 'ui/theme';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { IconItemBox } from 'ui/components/Icon/IconItemBox';
import { MultiFormatInput } from 'ui/components/TextField/MultiFormatInput';
import {
  itemHasSerialTracking,
  bundleItemHasSerialTracking,
} from 'services/inventory';

import {
  formatTypes,
  roundToDecimals,
  stopInputPropagation,
  useCurrencyFormatter,
  useGetCurrencySymbol,
} from 'helpers';
import { getTaxRates, TaxRate, renderTaxRate } from 'services/taxRates';
import { getUoms, EACH_UOM_ID, Uom } from 'services/uoms';
import {
  SalesOrderItemTypes,
  DiscountTypes,
  convertToNegative,
  calculatePriceBasedOnUomChange,
  calculateTotals,
  SalesOrder,
  SalesOrderBundleItem,
} from 'services/salesOrders';
import { PermissionType } from 'services/permissions';
import { SaleItemPriceRule } from 'services/items/saleItems';
import { activeUserHasPermission } from 'services/user/redux';
import { ItemType, ItemUomConversion } from 'services/items';

import { showThumbnail } from 'services/thumbnail/redux';
import { TaxJarTooltip } from 'ui/components/TaxJarTooltip';
import { TaxTooltip } from 'ui/components/TaxTooltip';
import { getSettingsCompany } from 'services/settings/company';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { filterItemUoms } from 'services/items/helpers';
import { getSettingsSalesOrder } from 'services/settings/salesOrders';

import { MenuTableCell } from '../MenuTableCell';
import { PopoverItem } from '../PopoverItem';
import {
  totalAnchorOrigin,
  totalTransformOrigin,
  discountAnchorOrigin,
  discountTransformOrigin,
} from '../SaleRow/consts';
import { useSalesOrderTableCellStyle } from '../../styled';
import { editSalesOrderPermissions } from '../../../../helpers';
import {
  salesOrderItemStatusRegister,
  areFieldsDisabled,
  isSalesOrderEstimate,
} from 'ui/modules/sales/pages/SalesOrderPage/stateRegister';
import { getSaleItemPricingRule } from 'services/items/saleItems/api';
import { calculateMulticurrencyPrice } from '../../helpers';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const BundleRow: React.FC<RenderCustomRowProps<SalesOrderBundleItem>> = (
  props
) => {
  const { row, index, setData, errors, meta, columns } = props;
  const { salesOrder, disabled, setIsLoading } = meta as {
    salesOrder: SalesOrder;
    disabled: boolean;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  };

  const currencyFormatter = useCurrencyFormatter();
  const currencySymbol = useGetCurrencySymbol();

  const [taxEl, setTaxEl] = useState<null | HTMLElement>(null);
  const [discountEl, setDiscountEl] = useState<null | HTMLElement>(null);
  const [discountPopupIndex, setDiscountPopupIndex] = useState<0 | 1>(0);
  const [discountPopupValue, setDiscountPopupValue] = useState<number | null>(
    null
  );
  const [pricingRule, setPricingRule] = useState<SaleItemPriceRule | null>(
    null
  );
  const [uoms, setUoms] = useState<Uom[]>([]);
  const [amountDisabled, setAmountDisabled] = useState(false);

  const dispatch = useDispatch();

  const activeMulticurrencyCode = _.get(
    salesOrder,
    'customer.currency.code',
    null
  );

  const handleOpenPreview = useCallback(
    (e) => {
      const imageUrl = _.get(row, 'item.defaultImage', '') as string;
      dispatch(showThumbnail(imageUrl));
      e.stopPropagation();
    },
    [dispatch, row]
  );

  const { items: storeTaxRates } = useSelector(getTaxRates);
  const { items: defaultUoms } = useSelector(getUoms);
  const { taxJarToken } = useSelector(getSettingsCompany);
  const soSettings = useSelector(getSettingsSalesOrder);

  const isDisabled = useCallback(salesOrderItemStatusRegister(row), [
    row.status,
    salesOrder.status,
  ]);

  const fieldsDisabled = useCallback(areFieldsDisabled(row, disabled), [
    row.status,
    salesOrder.status,
  ]);

  const isEstimateSalesOrder = useCallback(isSalesOrderEstimate(salesOrder), [
    salesOrder.status,
  ]);

  const editPermission = editSalesOrderPermissions(salesOrder);

  const discount = row.discount.value
    ? roundToDecimals(row.discount.value, 2)
    : 0;

  const canClick = useSelector(activeUserHasPermission(editPermission));
  const classes = useSalesOrderTableCellStyle();

  const canClickLink = !fieldsDisabled() && canClick;

  const selectedTaxRate = useMemo(
    () => storeTaxRates.find((t) => t.id === row.taxId) || null,
    [storeTaxRates, row.taxId]
  );

  const isSerialTracked = useMemo(() => {
    if (row.item?.itemType === ItemType.Bundle) {
      return !bundleItemHasSerialTracking(row.item.bundleItems);
    } else if (row.item) {
      return itemHasSerialTracking(row.item);
    } else return false;
  }, [row.item]);

  const isDiscountPopVisible = useMemo(() => Boolean(discountEl), [discountEl]);
  const isTaxPopoverVisible = useMemo(() => Boolean(taxEl), [taxEl]);
  const taxRates = useMemo(
    () => storeTaxRates.filter((tax) => tax.taxType === 'Percentage'),
    [storeTaxRates]
  );

  const rowIcon = useMemo(() => {
    switch (row.salesOrderItemType) {
      case SalesOrderItemTypes.Sale:
      case SalesOrderItemTypes.Bundle:
      case SalesOrderItemTypes.BundleCreditReturn:
        return (
          <ImageItemBox
            name={_.get(row, 'name', null)}
            description={_.get(row, 'description', null)}
            source={_.get(row, 'item.defaultImage', null)}
            onClick={handleOpenPreview}
          />
        );
      case SalesOrderItemTypes.CreditReturn:
        return (
          <IconItemBox
            name={_.get(row, 'name', null)}
            description={_.get(row, 'description', null)}
            source={IconNames.CreditReturn}
          />
        );
    }
  }, [row, handleOpenPreview]);

  // watch for quantity change and fetch new price
  const amountElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (row.saleItem) {
      const itemUoms = filterItemUoms(row.saleItem.defaultUom, defaultUoms);
      if (itemUoms) {
        setUoms(itemUoms);
      }
    }
  }, [row, defaultUoms]);

  // watch for quantity change and fetch new price
  const isFirstRun = useRef(true);

  useEffect(() => {
    // on component mount we don't want to fetch pricing rule
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    (async () => {
      if (
        !row.itemId ||
        !salesOrder.customerId ||
        !row.quantity ||
        !row.uomId
      ) {
        return;
      }

      setIsLoading(true);
      setAmountDisabled(true);

      const saleItemPricingRule = await getSaleItemPricingRule(
        row.itemId,
        null,
        salesOrder.customerId,
        row.quantity || 0,
        row.uomId
      );

      setPricingRule(saleItemPricingRule);
      setAmountDisabled(false);
      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row.price]);

  const handlePriceInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const convertedValue = e.target.value
        ? convertToNegative(e.target.value, row.salesOrderItemType)
        : null;

      const totals = calculateTotals(
        convertedValue || 0,
        row.quantity || 0,
        row.discount,
        row.taxRate || 0,
        false
      );

      const nulledBundleItems = row.salesOrderItems.map((soi) => ({
        ...soi,
        price: 0,
      }));

      setData(
        {
          ...row,
          price: convertedValue,
          salesOrderItems: nulledBundleItems,
          ...totals,
        },
        index
      );
      isFirstRun.current = true;
    },
    [row, index, setData]
  );

  const resolveDisplayTax = useMemo(() => {
    const displayTaxJarTaxRate =
      row.cityTaxRate +
      row.countyTaxRate +
      row.specialDistrictTaxRate +
      row.stateTaxRate;

    if (!row.taxable) {
      return 'Not Taxable';
    }
    if (taxJarToken) {
      return `${(displayTaxJarTaxRate * 100).toFixed(3)}%`;
    }
    if (selectedTaxRate) {
      return renderTaxRate(selectedTaxRate, currencySymbol);
    } else {
      return '0%';
    }
  }, [taxJarToken, selectedTaxRate, row, currencySymbol]);

  const fulfilledBundleItemCount = useMemo(() => {
    const fulfilledSoItemsPerBundle = row.salesOrderItems.map((soi) => {
      return Math.floor(soi.quantityFulfilled! / soi.quantity!);
    });

    return Math.min(...fulfilledSoItemsPerBundle);
  }, [row.salesOrderItems]);

  const handleAmountInputChange = useCallback(
    (value: number | null) => {
      const totals = calculateTotals(
        row.price || 0,
        value || 0,
        row.discount,
        row.taxRate || 0,
        soSettings.priceIncludesTax || false
      );

      setData({ ...row, quantity: value, ...totals }, index);
    },
    [row, index, setData, soSettings.priceIncludesTax]
  );

  const handleAmountMenuChange = useCallback(
    (uomId: number) => {
      const newUom = uoms.find((u) => u.id === uomId)!;
      const oldUom = uoms.find((u) => u.id === row.uomId)!;

      const itemUomConversions: ItemUomConversion[] = _.get(
        row,
        'saleItem.item.itemUomConversionList',
        null
      );

      const newPrice = calculatePriceBasedOnUomChange(
        newUom,
        oldUom,
        row.price || 0,
        itemUomConversions
      );

      const totals = calculateTotals(
        newPrice,
        row.quantity || 0,
        row.discount,
        row.taxRate || 0,
        soSettings.priceIncludesTax || false
      );

      setData({ ...row, uomId, price: newPrice, ...totals }, index);
    },
    [uoms, row, setData, index, soSettings.priceIncludesTax]
  );

  const handleTaxClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      setTaxEl(event.currentTarget);
    },
    []
  );

  const handleTaxPopClicked = useCallback(
    (tax: TaxRate | null, taxable: boolean) => () => {
      const taxRate = tax ? tax.percentage : null;
      const price = row.price || 0;
      const newTaxRate = tax ? tax.percentage! : 0;

      const totals = calculateTotals(
        price,
        row.quantity || 0,
        row.discount,
        newTaxRate,
        soSettings.priceIncludesTax || false
      );

      setData(
        {
          ...row,
          price: price,
          taxId: tax ? tax.id : null,
          taxRate,
          taxable,
          ...totals,
        },
        index
      );
      setTaxEl(null);
    },
    [index, row, setData, soSettings.priceIncludesTax]
  );

  const handleTaxPopClose = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      setTaxEl(null);
    },
    []
  );

  const handleDiscountPopupApplyClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      const discount = {
        value: discountPopupValue,
        type:
          discountPopupIndex === 0
            ? DiscountTypes.Percent
            : DiscountTypes.FlatRate,
      };

      const totals = calculateTotals(
        row.price || 0,
        row.quantity || 0,
        discount,
        row.taxRate || 0,
        soSettings.priceIncludesTax || false
      );

      setData({ ...row, discount, ...totals }, index);
      setDiscountEl(null);
    },
    [
      discountPopupValue,
      index,
      row,
      setData,
      discountPopupIndex,
      soSettings.priceIncludesTax,
    ]
  );

  const handleDiscountPopupChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDiscountPopupValue(parseFloat(e.target.value));
    },
    []
  );

  const handleDiscountAmountTypeChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();

      const taxTypeValue = e.target.innerText === '%' ? 0 : 1;
      setDiscountPopupIndex(taxTypeValue);
    },
    []
  );

  const handleDiscountClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      setDiscountPopupIndex(
        row.discount.type === DiscountTypes.Percent ? 0 : 1
      );
      setDiscountPopupValue(row.discount.value);
      setDiscountEl(event.currentTarget);
    },
    [row.discount]
  );

  const handleDiscountPopClose = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      setDiscountEl(null);
    },
    []
  );

  const handleApplyPricingRule = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (!pricingRule) {
      return;
    }

    const { price } = pricingRule;

    const totals = calculateTotals(
      pricingRule.price,
      row.quantity ?? 0,
      row.discount ?? 0,
      row.taxRate ?? 0,
      soSettings.priceIncludesTax || false
    );

    const multiPrice = calculateMulticurrencyPrice(
      pricingRule.price ?? 0,
      salesOrder.exchangeRate ?? 1
    );

    setData(
      {
        ...row,
        multiCurrencyItemPrice:
          row.salesOrderItemType === SalesOrderItemTypes.CreditReturn
            ? -multiPrice
            : multiPrice,
        price:
          row.salesOrderItemType === SalesOrderItemTypes.CreditReturn
            ? -price
            : price,
        ...totals,
        hasPricingRule: true,
      },
      index
    );

    setPricingRule(null);
  };

  const RenderTaxTooltip = useCallback(
    (props: { children: ReactElement }) => {
      const { children } = props;

      return taxJarToken ? (
        <TaxJarTooltip values={row || null} disableHoverListener={!taxJarToken}>
          {children}
        </TaxJarTooltip>
      ) : (
        <TaxTooltip tax={selectedTaxRate} taxable={row.taxable}>
          {children}
        </TaxTooltip>
      );
    },
    [row, taxJarToken, selectedTaxRate]
  );

  return (
    <>
      <TableCell style={{ width: columns[0].width }} className={classes.cell}>
        {rowIcon}
      </TableCell>
      <TableCell className={classes.cell}>
        <Grid container>
          <Grid item xs>
            <CurrencyField
              placeholder="Price"
              name="price"
              value={row.price}
              disabled={isDisabled('price')}
              onChange={handlePriceInputChange}
              onClick={stopInputPropagation}
              fullWidth
              inputProps={{
                allowNegative: true,
                'data-qa': 'item-row-price' as any,
                style: {
                  color: row.hasPricingRule
                    ? colorPalette.fishbowlCorporateLightBlue
                    : 'initial',
                },
              }}
              permissions={[PermissionType.SalesOrderOverridePrice]}
              error={!!errors.price}
              allowNegative
              decimalPlaces={6}
              noMargin
            />
          </Grid>
          <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
            <Box margin="0px 5px 0px -15px" paddingLeft="5px">
              &times;
            </Box>
          </MultiCurrencyWrapper>
          {!!pricingRule && pricingRule.price !== row.price && (
            <Grid xs={12}>
              <Typography
                variant="body2"
                className={classes.pricingRulePrice}
                component="span"
              >
                {currencyFormatter(pricingRule.price)}
              </Typography>
              <Link
                variant="body2"
                color="textPrimary"
                onClick={handleApplyPricingRule}
                href="#"
                underline="always"
              >
                Apply
              </Link>
            </Grid>
          )}
        </Grid>
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell className={classes.cell}>
          <Grid container>
            <Grid item xs>
              <CurrencyField
                placeholder="Price"
                name="price"
                value={row.multiCurrencyItemPrice}
                disabled={isDisabled('price')}
                onChange={handlePriceInputChange}
                onClick={stopInputPropagation}
                fullWidth
                permissions={[PermissionType.SalesOrderOverridePrice]}
                error={!!errors.price}
                currencyCode={activeMulticurrencyCode}
                allowNegative
                decimalPlaces={6}
                noMargin
              />
            </Grid>
            <Grid item>
              <Box marginLeft="13px" marginRight="-10px" marginTop="9px">
                &times;
              </Box>
            </Grid>
          </Grid>
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell className={classes.cell}>
        <TextFieldQuantity
          placeholder="Quantity"
          name="quantity"
          value={row.quantity}
          selectedUomId={row.uomId || EACH_UOM_ID}
          onTextChange={handleAmountInputChange}
          onClick={stopInputPropagation}
          onMenuChange={
            isEstimateSalesOrder() ? undefined : handleAmountMenuChange
          }
          permissions={editPermission}
          fullWidth
          error={!!errors.quantity}
          isDecimal={!isSerialTracked}
          uoms={uoms}
          dataQa="item-row-quantity"
          disabled={amountDisabled || isDisabled('quantity')}
          additionalInputProps={{ inputRef: amountElement }}
          disableAutoFocus
          noMargin={!(!!pricingRule && pricingRule.price !== row.price)}
        />
      </TableCell>
      <TableCell align="left" className={classes.cell}>
        <Link
          variant="body2"
          color="textPrimary"
          onClick={canClickLink ? handleDiscountClicked : _.noop}
          underline="always"
          data-qa="item-row-discount"
          className="aligned-link"
        >
          {row.discount.type === DiscountTypes.FlatRate
            ? currencyFormatter(discount)
            : ''}
          {row.discount.type === DiscountTypes.Percent ? `${discount}%` : ''}
        </Link>
        <Popover
          anchorOrigin={discountAnchorOrigin}
          transformOrigin={discountTransformOrigin}
          anchorEl={discountEl}
          open={isDiscountPopVisible}
          onClose={handleDiscountPopClose}
        >
          <Box p={1} display="flex" alignItems="center">
            <MultiFormatInput
              className="redesign"
              variant="standard"
              type="text"
              label="Quantity"
              placeholder="Enter quantity"
              value={discountPopupValue}
              onClick={stopInputPropagation}
              onFormatChange={handleDiscountAmountTypeChanged}
              onChange={handleDiscountPopupChange}
              options={formatTypes(currencySymbol)}
              activeIndex={discountPopupIndex}
              dataQa="item-row-discount-quantity"
            />
            <Box ml={1}>
              <FBOButton
                variant="primary"
                color="positive"
                size="small"
                onClick={handleDiscountPopupApplyClick}
                data-qa="item-row-discount-add"
              >
                Add
              </FBOButton>
            </Box>
          </Box>
        </Popover>
      </TableCell>
      <TableCell className={classes.cell} align="right">
        <RenderTaxTooltip>
          <Link
            variant="body2"
            color="textPrimary"
            onClick={!taxJarToken && canClickLink ? handleTaxClicked : _.noop}
            underline="always"
            data-qa="item-row-tax"
            className="aligned-link"
          >
            {resolveDisplayTax}
          </Link>
        </RenderTaxTooltip>
        <Popover
          anchorOrigin={totalAnchorOrigin}
          transformOrigin={totalTransformOrigin}
          anchorEl={taxEl}
          open={isTaxPopoverVisible}
          onClose={handleTaxPopClose}
        >
          <Box width={150}>
            <Grid container>
              <PopoverItem
                label="None"
                value="0%"
                onClick={handleTaxPopClicked(null, true)}
              />
              {taxRates.map((tax, i) => {
                return (
                  <PopoverItem
                    key={i}
                    label={tax.name!}
                    value={renderTaxRate(tax, currencySymbol)}
                    onClick={handleTaxPopClicked(tax, true)}
                  />
                );
              })}
              <PopoverItem
                label="Not Taxable"
                value=""
                onClick={handleTaxPopClicked(null, false)}
              />
            </Grid>
          </Box>
        </Popover>
      </TableCell>
      <TableCell align="center" className={classes.cell}>
        <StatusLabel
          onClick={stopInputPropagation}
          status={row.status}
          label={`${fulfilledBundleItemCount}/${row.quantity || 0}`}
        />
      </TableCell>
      <TableCell
        align="right"
        className={!soSettings.priceIncludesTax ? classes.cell : ''}
        onClick={stopInputPropagation}
      >
        <Typography
          variant="body2"
          color="textPrimary"
          data-qa="sale-item-sub-total"
        >
          {currencyFormatter(row.subTotal)}
        </Typography>
        {!soSettings.priceIncludesTax && (
          <Typography
            variant="subtitle2"
            color="textSecondary"
            data-qa="sale-item-tax-total"
          >
            {currencyFormatter(row.taxable ? row.taxTotal : 0)}
          </Typography>
        )}
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell
          align="right"
          className={!soSettings.priceIncludesTax ? classes.cell : ''}
          onClick={stopInputPropagation}
        >
          <Typography
            variant="body2"
            color="textPrimary"
            data-qa="sale-item-sub-total"
          >
            {currencyFormatter(0)}
          </Typography>
          {!soSettings.priceIncludesTax && (
            <Typography
              variant="subtitle2"
              color="textSecondary"
              data-qa="sale-item-tax-total"
            >
              {currencyFormatter(0)}
            </Typography>
          )}
        </TableCell>
      </MultiCurrencyWrapper>
      <MenuTableCell {...props} />
    </>
  );
};

export default memo(BundleRow);
