import React, {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TableCell, Grid, Link, Box, Popover, Typography } from '@mui/material';
import _ from 'lodash';

import { colorPalette, IconNames } from 'ui/theme';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import { IconItemBox } from 'ui/components/Icon/IconItemBox';
import { MultiFormatInput } from 'ui/components/TextField/MultiFormatInput';
import { itemHasSerialTracking } from 'services/inventory';
import {
  useCurrencyFormatter,
  roundToDecimals,
  stopInputPropagation,
  formatTypes,
  useGetCurrencySymbol,
  toMulticurrencyCalculate,
  toHomeCurrencyCalculate,
} from 'helpers';
import { getTaxRates, TaxRate, renderTaxRate } from 'services/taxRates';
import { getUoms, EACH_UOM_ID, Uom } from 'services/uoms';
import {
  SalesOrderItem,
  SalesOrderItemTypes,
  DiscountTypes,
  convertToNegative,
  calculatePriceBasedOnUomChange,
  calculateTotals,
  SalesOrder,
} from 'services/salesOrders';
import { PermissionType } from 'services/permissions';
import { getSaleItemPricingRule } from 'services/items/saleItems/api';
import { SaleItemPriceRule } from 'services/items/saleItems';
import { activeUserHasPermission } from 'services/user/redux';
import { getSettingsCompany } from 'services/settings/company';
import { TaxJarTooltip } from 'ui/components/TaxJarTooltip';
import { TaxTooltip } from 'ui/components/TaxTooltip';
import { showThumbnail } from 'services/thumbnail/redux';
import { ItemType, ItemUomConversion } from 'services/items';

import { MenuTableCell } from '../MenuTableCell';
import { PopoverItem } from '../PopoverItem';
import {
  totalAnchorOrigin,
  totalTransformOrigin,
  discountAnchorOrigin,
  discountTransformOrigin,
  salesRowInputChangeDelay,
} from './consts';
import { useSalesOrderTableCellStyle } from '../../styled';
import { editSalesOrderPermissions } from '../../../../helpers';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { calculateMulticurrencyPrice } from '../../helpers';
import { filterItemUoms } from 'services/items/helpers';
import { MultiCurrencyWrapper } from 'ui/components/MultiCurrencyWrapper/MultiCurrencyWrapper';
import { getSettingsSalesOrder } from 'services/settings/salesOrders';
import { useDebouncedCallback } from 'use-debounce';
import { useFlags } from 'helpers/useFlags';
import {
  salesOrderItemStatusRegister,
  areFieldsDisabled,
  isSalesOrderEstimate,
} from 'ui/modules/sales/pages/SalesOrderPage/stateRegister';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const SaleRow: React.FC<RenderCustomRowProps<SalesOrderItem>> = (props) => {
  const { row, index, setData, errors, meta, columns } = props;
  const { salesOrder, setIsLoading } = meta as {
    salesOrder: SalesOrder;
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  };

  const currencyFormatter = useCurrencyFormatter();
  const currencySymbol = useGetCurrencySymbol();
  const companySettings = useSelector(getSettingsCompany);
  const [isInternationalConnected, setisInternationalConnected] =
    useState(false);
  const flags = useFlags();

  const [taxEl, setTaxEl] = useState<null | HTMLElement>(null);
  const [discountEl, setDiscountEl] = useState<null | HTMLElement>(null);
  const [discountPopupIndex, setDiscountPopupIndex] = useState<0 | 1>(0);
  const [discountPopupValue, setDiscountPopupValue] = useState<number | null>(
    null
  );
  const [amountDisabled, setAmountDisabled] = useState(false);
  const [pricingRule, setPricingRule] = useState<SaleItemPriceRule | null>(
    null
  );
  const [taxExemptWarningModalVisible, setTaxExemptWarningModalVisible] =
    useState(false);
  const [selectedTax, setSelectedTax] = useState({
    tax: row.tax,
    taxable: false,
  });
  const [uoms, setUoms] = useState<Uom[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (companySettings.country !== 'US') setisInternationalConnected(true);
  }, [companySettings]);

  const handleOpenPreview = useCallback(
    (e) => {
      const imageUrl = _.get(row, 'saleItem.defaultImage', '') as string;
      dispatch(showThumbnail(imageUrl));
      e.stopPropagation();
    },
    [dispatch, row]
  );

  const { items: storeTaxRates } = useSelector(getTaxRates);
  const { items: defaultUoms } = useSelector(getUoms);
  const { taxJarToken } = useSelector(getSettingsCompany);
  const soSettings = useSelector(getSettingsSalesOrder);

  const isDisabled = useCallback(salesOrderItemStatusRegister(row), [
    row.status,
    salesOrder.status,
  ]);

  const fieldsDisabled = useCallback(areFieldsDisabled(row), [
    row.status,
    salesOrder.status,
  ]);

  const isEstimateSalesOrder = useCallback(isSalesOrderEstimate(salesOrder), [
    salesOrder.status,
  ]);

  const editPermission = editSalesOrderPermissions(salesOrder);

  const discount = row.discount.value
    ? roundToDecimals(row.discount.value, 2)
    : 0;

  const canClick = useSelector(activeUserHasPermission(editPermission));
  const classes = useSalesOrderTableCellStyle();

  const canClickLink = !fieldsDisabled() && canClick;

  const rowItemType = _.get(row.saleItem, 'item.itemType', null);

  const activeMulticurrencyCode = _.get(
    salesOrder,
    'customer.currency.code',
    null
  );

  const selectedTaxRate = useMemo(
    () => storeTaxRates.find((t) => t.id === row.taxId) || null,
    [storeTaxRates, row.taxId]
  );

  const isSerialTracked = useMemo(() => {
    if (row.saleItem && row.saleItem.item) {
      return itemHasSerialTracking(row.saleItem.item);
    }
    return false;
  }, [row.saleItem]);

  const formatedMulticurrencySubTotal = useMemo(
    () =>
      activeMulticurrencyCode &&
      currencyFormatter(
        row.multiCurrencySubTotal || 0,
        activeMulticurrencyCode
      ),
    [activeMulticurrencyCode, row.multiCurrencySubTotal, currencyFormatter]
  );

  const formatedMulticurrencyTaxTotal = useMemo(
    () =>
      activeMulticurrencyCode &&
      currencyFormatter(
        row.multiCurrencyTaxTotal || 0,
        activeMulticurrencyCode
      ),
    [activeMulticurrencyCode, row.multiCurrencyTaxTotal, currencyFormatter]
  );

  const isDiscountPopVisible = useMemo(() => Boolean(discountEl), [discountEl]);
  const isTaxPopoverVisible = useMemo(() => Boolean(taxEl), [taxEl]);
  const taxRates = useMemo(
    () =>
      storeTaxRates.filter((tax) =>
        isInternationalConnected && flags.intlTaxRates
          ? tax.channelTaxType !== 'PURCHASE'
          : tax.taxType === 'Percentage'
      ),
    [storeTaxRates, isInternationalConnected]
  );

  const rowIcon = useMemo(() => {
    switch (row.salesOrderItemType) {
      case SalesOrderItemTypes.Sale:
      case SalesOrderItemTypes.DropShip: {
        const description = _.get(row, 'description', '');
        const source = _.get(row, 'saleItem.defaultImage', null);

        return (
          <ImageItemBox
            name={_.get(row, 'name', null)}
            description={_.truncate(description!, { length: 100 })}
            source={source}
            onClick={handleOpenPreview}
          />
        );
      }
      case SalesOrderItemTypes.CreditReturn:
        return (
          <IconItemBox
            name={_.get(row, 'name', null)}
            description={_.get(row, 'description', null)}
            source={IconNames.CreditReturn}
          />
        );
    }
  }, [row, handleOpenPreview]);

  // watch for quantity change and fetch new price
  const isFirstRun = useRef(true);
  const amountElement = useRef<HTMLInputElement>(null);

  // fetch pricing rule on amount change
  useEffect(() => {
    // on component mount we don't want to fetch pricing rule
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    (async () => {
      if (
        !row.saleItem ||
        !row.saleItem.itemId ||
        !row.saleItem.id ||
        !salesOrder.customerId ||
        !row.quantity ||
        !row.uomId
      ) {
        return;
      }

      setIsLoading(true);
      setAmountDisabled(true);

      const saleItemPricingRule = await getSaleItemPricingRule(
        row.saleItem.itemId,
        row.saleItemId,
        salesOrder.customerId,
        row.quantity || 0,
        row.uomId
      );

      setPricingRule(saleItemPricingRule);
      setAmountDisabled(false);
      setIsLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row.price]);

  useEffect(() => {
    if (row.saleItem) {
      const itemUoms = filterItemUoms(row.saleItem.defaultUom, defaultUoms);
      if (itemUoms) {
        setUoms(itemUoms);
      }
    }
  }, [row, defaultUoms]);

  const handlePriceInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const convertedValue = e.target.value
        ? convertToNegative(e.target.value, row.salesOrderItemType)
        : null;

      const multiPrice = toMulticurrencyCalculate(
        convertedValue || 0,
        salesOrder.exchangeRate || 1
      );

      const totals = calculateTotals(
        convertedValue || 0,
        row.quantity || 0,
        row.discount,
        row.taxRate || 0,
        soSettings.priceIncludesTax || false,
        salesOrder.exchangeRate || 1
      );

      setData(
        {
          ...row,
          price: convertedValue,
          multiCurrencyItemPrice: multiPrice,
          ...totals,
        },
        index
      );
      isFirstRun.current = true;
    },
    [row, index, setData, salesOrder.exchangeRate, soSettings.priceIncludesTax]
  );

  const handleMultiCurrencyPriceInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const convertedValue = e.target.value
        ? convertToNegative(e.target.value, row.salesOrderItemType)
        : null;

      const price = toHomeCurrencyCalculate(
        convertedValue || 0,
        salesOrder.exchangeRate || 1
      );

      const totals = calculateTotals(
        price,
        row.quantity || 0,
        row.discount,
        row.taxRate || 0,
        soSettings.priceIncludesTax || false,
        salesOrder.exchangeRate || 1
      );

      setData(
        {
          ...row,
          multiCurrencyItemPrice: convertedValue,
          price: price,
          ...totals,
        },
        index
      );
    },
    [row, index, setData, salesOrder.exchangeRate, soSettings.priceIncludesTax]
  );

  const handleAmountInputChange = useDebouncedCallback(
    useCallback(
      (value: number | null) => {
        const totals = calculateTotals(
          row.price || 0,
          value || 0,
          row.discount,
          row.taxRate || 0,
          soSettings.priceIncludesTax || false,
          salesOrder.exchangeRate || 1
        );

        setData(
          {
            ...row,
            quantity: value,
            ...totals,
          },
          index
        );
      },
      [
        row,
        index,
        setData,
        salesOrder.exchangeRate,
        soSettings.priceIncludesTax,
      ]
    ),
    salesRowInputChangeDelay
  );

  const handleAmountMenuChange = useCallback(
    (uomId: number) => {
      const newUom = uoms.find((u) => u.id === uomId)!;
      const oldUom = uoms.find((u) => u.id === row.uomId)!;

      const itemUomConversions: ItemUomConversion[] = _.get(
        row,
        'saleItem.item.itemUomConversionList',
        null
      );

      const newPrice = calculatePriceBasedOnUomChange(
        newUom,
        oldUom,
        row.price || 0,
        itemUomConversions
      );

      const multiCurrencyItemPrice = calculateMulticurrencyPrice(
        newPrice || 0,
        salesOrder.exchangeRate || 1
      );

      const totals = calculateTotals(
        newPrice,
        row.quantity || 0,
        row.discount,
        row.taxRate || 0,
        soSettings.priceIncludesTax || false,
        salesOrder.exchangeRate || 1
      );

      setData(
        {
          ...row,
          uomId,
          price: newPrice,
          multiCurrencyItemPrice,
          ...totals,
        },
        index
      );
    },
    [
      uoms,
      row,
      setData,
      index,
      salesOrder.exchangeRate,
      soSettings.priceIncludesTax,
    ]
  );

  const handleTaxClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      setTaxEl(event.currentTarget.parentElement);
    },
    []
  );

  const updateTax = useCallback(
    (tax: TaxRate | null, taxable: boolean) => {
      const taxRate = tax ? tax.percentage : null;
      const price = row.price || 0;
      const newTaxRate = tax ? tax.percentage! : 0;

      const totals = calculateTotals(
        price,
        row.quantity || 0,
        row.discount,
        newTaxRate,
        soSettings.priceIncludesTax || false,
        salesOrder.exchangeRate || 1
      );

      setData(
        {
          ...row,
          taxId: tax ? tax.id : null,
          taxRate,
          taxable,
          ...totals,
        },
        index
      );
      setTaxEl(null);
    },
    [index, row, setData, salesOrder.exchangeRate, soSettings.priceIncludesTax]
  );

  const handleTaxPopClose = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      setTaxEl(null);
    },
    []
  );
  const handleTaxPopCliked = useCallback(
    (tax: TaxRate | null, taxable: boolean) => () => {
      setSelectedTax({ tax, taxable });
      if (
        salesOrder.customer &&
        salesOrder.customer.taxExempt &&
        row.taxId !== (tax ? tax.id : -1)
      ) {
        setTaxExemptWarningModalVisible(true);
        return;
      }

      updateTax(tax, taxable);
    },
    [row, salesOrder.customer, updateTax]
  );

  const handleTaxExemptWarningModalContinue = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setTaxExemptWarningModalVisible(false);
      updateTax(selectedTax.tax, selectedTax.taxable);
    },
    [updateTax, selectedTax]
  );

  const handleTaxExemptOnClose = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setTaxExemptWarningModalVisible(false);
    },
    []
  );

  const handleDiscountPopupApplyClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      const discount = {
        value: discountPopupValue,
        type:
          discountPopupIndex === 0
            ? DiscountTypes.Percent
            : DiscountTypes.FlatRate,
      };

      const totals = calculateTotals(
        row.price || 0,
        row.quantity || 0,
        discount,
        row.taxRate || 0,
        soSettings.priceIncludesTax || false,
        salesOrder.exchangeRate || 1
      );

      setData(
        {
          ...row,
          discount,
          ...totals,
        },
        index
      );
      setDiscountEl(null);
    },
    [
      discountPopupValue,
      index,
      row,
      setData,
      discountPopupIndex,
      salesOrder.exchangeRate,
      soSettings.priceIncludesTax,
    ]
  );

  const handleDiscountPopupChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDiscountPopupValue(parseFloat(e.target.value));
    },
    []
  );

  const handleDiscountAmountTypeChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.stopPropagation();

      const taxTypeValue = e.target.innerText === '%' ? 0 : 1;
      setDiscountPopupIndex(taxTypeValue);
    },
    []
  );

  const handleDiscountClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      event.stopPropagation();

      setDiscountPopupIndex(
        row.discount.type === DiscountTypes.Percent ? 0 : 1
      );
      setDiscountPopupValue(row.discount.value);
      setDiscountEl(event.currentTarget);
    },
    [row.discount]
  );

  const handleDiscountPopClose = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();

      setDiscountEl(null);
    },
    []
  );

  const handleApplyPricingRule = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    event.stopPropagation();

    if (!pricingRule) {
      return;
    }

    const { price } = pricingRule;

    const totals = calculateTotals(
      pricingRule.price,
      row.quantity || 0,
      row.discount || 0,
      row.taxRate || 0,
      soSettings.priceIncludesTax || false,
      salesOrder.exchangeRate || 1
    );

    const multiPrice = calculateMulticurrencyPrice(
      pricingRule.price || 0,
      salesOrder.exchangeRate || 1
    );

    setData(
      {
        ...row,
        multiCurrencyItemPrice:
          row.salesOrderItemType === SalesOrderItemTypes.CreditReturn
            ? -multiPrice
            : multiPrice,
        price:
          row.salesOrderItemType === SalesOrderItemTypes.CreditReturn
            ? -price
            : price,
        ...totals,
        hasPricingRule: true,
      },
      index
    );

    setPricingRule(null);
  };

  const resolveDisplayTax = useMemo(() => {
    const displayTaxJarTaxRate =
      row.cityTaxRate +
      row.countyTaxRate +
      row.specialDistrictTaxRate +
      row.stateTaxRate;

    if (!row.taxable) {
      return 'Not Taxable';
    }
    if (taxJarToken) {
      return `${(displayTaxJarTaxRate * 100).toFixed(3)}%`;
    }
    if (selectedTaxRate) {
      return renderTaxRate(selectedTaxRate, currencySymbol);
    } else {
      return '0%';
    }
  }, [taxJarToken, row, selectedTaxRate, currencySymbol]);

  const RenderTaxTooltip = useCallback(
    (props: { children: ReactElement }) => {
      const { children } = props;

      return taxJarToken ? (
        <TaxJarTooltip values={row || null} disableHoverListener={!taxJarToken}>
          {children}
        </TaxJarTooltip>
      ) : (
        <TaxTooltip tax={selectedTaxRate} taxable={row.taxable}>
          {children}
        </TaxTooltip>
      );
    },
    [row, taxJarToken, selectedTaxRate]
  );

  const taxButtonClickHandler = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      const permissionToClick = !taxJarToken && canClickLink;

      // If it hasn't permission, don't click and prevent from opening rows item modal.
      if (!permissionToClick) {
        event.stopPropagation();

        return false;
      }

      handleTaxClicked(event);
    },
    [taxJarToken, canClickLink, handleTaxClicked, salesOrder.status]
  );

  return (
    <>
      <TableCell style={{ width: columns[0].width }} className={classes.cell}>
        {rowIcon}
      </TableCell>
      <TableCell className={classes.cell} data-qa={`Unit-Price -${row.name}`}>
        <Grid container>
          <Grid item xs>
            <CurrencyField
              placeholder="Price"
              name="price"
              value={roundToDecimals(row.price || 0, 6)}
              onChange={handlePriceInputChange}
              onClick={stopInputPropagation}
              fullWidth
              inputProps={{
                'data-qa': 'item-row-price' as any,
                style: {
                  color: row.hasPricingRule
                    ? colorPalette.fishbowlCorporateLightBlue
                    : 'initial',
                },
              }}
              permissions={[PermissionType.SalesOrderOverridePrice]}
              error={!!errors.price}
              allowNegative
              decimalPlaces={6}
              disabled={isDisabled('price')}
              noMargin
            />
          </Grid>
          <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
            <Grid item>
              <Box marginLeft="13px" marginRight="-10px" marginTop="9px"></Box>
            </Grid>
          </MultiCurrencyWrapper>
          {!!pricingRule && pricingRule.price !== row.price && (
            <Grid xs={12}>
              <Typography
                variant="body2"
                className={classes.pricingRulePrice}
                component="span"
              >
                {currencyFormatter(pricingRule.price)}
              </Typography>
              <Link
                variant="body2"
                color="textPrimary"
                onClick={handleApplyPricingRule}
                href="#"
                underline="always"
              >
                Apply
              </Link>
            </Grid>
          )}
        </Grid>
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell
          className={classes.cell}
          data-qa={`Unit-Price-Alt - ${row.name}`}
        >
          <Grid container>
            <Grid item xs>
              <CurrencyField
                placeholder="Price"
                value={roundToDecimals(row.multiCurrencyItemPrice || 0, 6)}
                onChange={handleMultiCurrencyPriceInputChange}
                onClick={stopInputPropagation}
                fullWidth
                allowNegative
                decimalPlaces={6}
                debounceDelay={500}
                currencyCode={activeMulticurrencyCode}
                disabled={isDisabled('price')}
                noMargin
              />
            </Grid>
            <Box margin="10px -10px 0 0px" paddingLeft="5px">
              &times;
            </Box>
          </Grid>
        </TableCell>
      </MultiCurrencyWrapper>
      <TableCell className={classes.cell} data-qa={`Quantity - ${row.name}`}>
        <TextFieldQuantity
          placeholder="Quantity"
          name="quantity"
          value={row.quantity}
          selectedUomId={row.uomId || EACH_UOM_ID}
          onTextChange={handleAmountInputChange}
          onClick={stopInputPropagation}
          onMenuChange={
            !isEstimateSalesOrder() ? undefined : handleAmountMenuChange
          }
          permissions={editPermission}
          fullWidth
          error={!!errors.quantity}
          isDecimal={!isSerialTracked}
          uoms={uoms}
          dataQa="item-row-quantity"
          disabled={amountDisabled || isDisabled('quantity')}
          isUomSelectDisabled={rowItemType === ItemType.Shipping}
          additionalInputProps={{ inputRef: amountElement }}
          disableAutoFocus
          noMargin
        />
      </TableCell>
      <TableCell
        align="left"
        className={classes.cell}
        data-qa={`Discount - ${row.name}`}
      >
        <Link
          variant="body2"
          color="textPrimary"
          onClick={canClickLink ? handleDiscountClicked : _.noop}
          underline="always"
          data-qa="item-row-discount"
          className="aligned-link"
        >
          {row.discount.type === DiscountTypes.FlatRate
            ? currencyFormatter(discount)
            : ''}
          {row.discount.type === DiscountTypes.Percent ? `${discount}%` : ''}
        </Link>
        <Popover
          anchorOrigin={discountAnchorOrigin}
          transformOrigin={discountTransformOrigin}
          anchorEl={discountEl}
          open={isDiscountPopVisible}
          onClose={handleDiscountPopClose}
        >
          <Box p={1} display="flex" alignItems="center">
            <MultiFormatInput
              className="redesign"
              variant="standard"
              type="text"
              label={
                discountPopupIndex === 1
                  ? 'Discount Amount'
                  : 'Discount Percent'
              }
              placeholder="Enter quantity"
              value={discountPopupValue}
              onClick={stopInputPropagation}
              onFormatChange={handleDiscountAmountTypeChanged}
              onChange={handleDiscountPopupChange}
              options={formatTypes(currencySymbol)}
              activeIndex={discountPopupIndex}
              dataQa="item-row-discount-quantity"
            />
            <Box ml={1}>
              <FBOButton
                variant="primary"
                color="positive"
                size="small"
                onClick={handleDiscountPopupApplyClick}
                data-qa="item-row-discount-add"
              >
                Add
              </FBOButton>
            </Box>
          </Box>
        </Popover>
      </TableCell>
      <TableCell
        className={classes.cell}
        data-qa={`Tax - ${row.name}`}
        align="right"
      >
        {/* parent div for popover anchor */}
        <div>
          <RenderTaxTooltip>
            <Link
              variant="body2"
              color="textPrimary"
              onClick={taxButtonClickHandler}
              underline="always"
              data-qa="item-row-tax"
              className="aligned-link"
            >
              {resolveDisplayTax}
            </Link>
          </RenderTaxTooltip>
        </div>
        <Popover
          anchorOrigin={totalAnchorOrigin}
          transformOrigin={totalTransformOrigin}
          anchorEl={taxEl}
          open={isTaxPopoverVisible}
          onClose={handleTaxPopClose}
        >
          <Box width={150}>
            <Grid container>
              <PopoverItem
                label="None"
                value="0%"
                onClick={handleTaxPopCliked(null, true)}
              />
              {taxRates.map((tax, i) => {
                return (
                  <PopoverItem
                    key={i}
                    label={tax.name!}
                    value={renderTaxRate(tax, currencySymbol)}
                    onClick={handleTaxPopCliked(tax, true)}
                  />
                );
              })}
              <PopoverItem
                label="Not Taxable"
                value=""
                onClick={handleTaxPopCliked(null, false)}
              />
            </Grid>
          </Box>
        </Popover>
      </TableCell>
      <TableCell
        align="center"
        className={classes.cell}
        data-qa={`Status - ${row.name}`}
      >
        <StatusLabel
          status={row.status}
          label={`${row.quantityFulfilled ? row.quantityFulfilled : 0}/${
            row.quantity || 0
          }`}
        />
      </TableCell>
      <TableCell
        align="right"
        className={!soSettings.priceIncludesTax ? classes.cell : ''}
        onClick={stopInputPropagation}
        data-qa={`Subtotal-${row.name}`}
      >
        <Typography
          variant="body2"
          color="textPrimary"
          data-qa="sale-item-sub-total"
        >
          {currencyFormatter(row.subTotal)}
        </Typography>

        <Typography
          variant="subtitle2"
          color="textSecondary"
          data-qa="sale-item-tax-total"
        >
          {currencyFormatter(row.taxable ? row.taxTotal : 0)}
        </Typography>
      </TableCell>
      <MultiCurrencyWrapper multiCurrency={activeMulticurrencyCode}>
        <TableCell
          align="right"
          className={!soSettings.priceIncludesTax ? classes.cell : ''}
          onClick={stopInputPropagation}
        >
          <Typography
            variant="body2"
            color="textPrimary"
            data-qa="sale-item-sub-total"
          >
            {formatedMulticurrencySubTotal}
          </Typography>

          <Typography
            variant="subtitle2"
            color="textSecondary"
            data-qa="sale-item-tax-total"
          >
            {formatedMulticurrencyTaxTotal}
          </Typography>
        </TableCell>
      </MultiCurrencyWrapper>
      <MenuTableCell {...props} />
      <ConfirmationModal
        open={taxExemptWarningModalVisible}
        title="Tax Rate Changed"
        body={`Tax Rate has been changed and customer has Tax Exempt flag checked. Are you sure you want to continue?`}
        onCancelClicked={handleTaxExemptOnClose}
        onConfirmClicked={handleTaxExemptWarningModalContinue}
        confirmLabel="Continue"
        confirmButtonRed
      />
    </>
  );
};

export default memo(SaleRow);
