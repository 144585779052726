import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';

import { DateRange } from 'materialui-daterange-picker';
import { Page } from 'ui/components/Page/Page';
import { TabPanel } from 'ui/components/TabPanel';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import {
  fetchGeneralWidgetData,
  fetchProfitData,
  fetchPurchasingExpenses,
  fetchSalesByRegions,
  fetchSalesTotalRevenue,
} from 'services/dashboard/api';
import {
  ChartData,
  GeneralWidgetValues,
  RegionalSaleOrder,
} from 'services/dashboard/types';
import {
  initialFromDateRange,
  initialGeneralWidgetValues,
  initialToDateRange,
} from 'services/dashboard/consts';

import {
  DashboardLineChart,
  DashboardWidgets,
  DashboardGeneral,
  DashboardSales,
  DashboardPurchasing,
} from './components';
import { Routes } from '../../navigation';
import { DashboardPageCmp, DashboardPageProps } from './types';
import { useDashboardPageStyle } from './styled';
import {
  DATE_FORMAT,
  lineChartDataGeneral,
  lineChartDataPurchasing,
  lineChartDataSales,
} from './consts';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSettingsCompanies,
  getSettingsCompany,
} from 'services/settings/company';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { useHistory } from 'react-router-dom';
import { populateTestEnvironment } from 'services/sandbox';
import { isTestEnv } from 'services/api/testEnv';
import { useFlags } from 'helpers/useFlags';

const DashboardPage: DashboardPageCmp = (props: DashboardPageProps) => {
  const classes = useDashboardPageStyle(props);

  const [activeTab, setActiveTab] = useState(0);
  const [fromDateRange, setFromDateRange] =
    useState<DateRange>(initialFromDateRange);
  const [toDateRange, setToDateRange] = useState<DateRange>(initialToDateRange);
  const [generalWidgetData, setGeneralWidgetData] =
    useState<GeneralWidgetValues>(initialGeneralWidgetValues);
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [chartTitle, setChartTitle] = useState('PROFIT');
  const [salesByRegion, setSalesByRegion] = useState<RegionalSaleOrder[]>([]);
  const [nonAddressedOrder, setNonAdressedOrder] =
    useState<RegionalSaleOrder | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(true); //using this to force a render

  const dispatch = useDispatch();

  const companySettings = useSelector(getSettingsCompany);
  const history = useHistory();

  const flags = useFlags();

  const fromStartMoment = useMemo(
    () => moment(fromDateRange.startDate),
    [fromDateRange.startDate]
  );
  const fromEndMoment = useMemo(
    () => moment(fromDateRange.endDate),
    [fromDateRange.endDate]
  );
  const fromDiff = useMemo(
    () => Math.abs(fromStartMoment.diff(fromEndMoment, 'days')) + 1,
    [fromStartMoment, fromEndMoment]
  );
  const toStartMoment = useMemo(
    () => moment(toDateRange.startDate),
    [toDateRange.startDate]
  );
  const toEndMoment = useMemo(
    () => moment(toDateRange.endDate),
    [toDateRange.endDate]
  );

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchSettingsCompanies());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const localVar = localStorage.getItem('showWelcomeModal');
    if (
      companySettings.goLiveDate === null &&
      localVar === null &&
      isTestEnv()
    ) {
      localStorage.setItem('showWelcomeModal', 'true');
      setShowWelcomeModal(true);
    }
  }, [companySettings]);

  const activeLineChartData = useMemo(() => {
    switch (activeTab) {
      case 0:
        return lineChartDataGeneral;
      case 1:
        return lineChartDataSales;
      case 2:
        return lineChartDataPurchasing;
    }

    return lineChartDataGeneral;
  }, [activeTab]);

  const getSalesByRegion = useCallback(async () => {
    if (!fromStartMoment.isValid() || !fromEndMoment.isValid()) {
      return;
    }

    try {
      const resSalesByRegion = await fetchSalesByRegions(
        fromStartMoment.format(DATE_FORMAT),
        fromEndMoment.format(DATE_FORMAT)
      );

      setNonAdressedOrder(resSalesByRegion.nonAddressedOrder);
      setSalesByRegion(resSalesByRegion.salesByRegion);
    } catch {
      setSalesByRegion([]);
    }
  }, [fromStartMoment, fromEndMoment]);

  const handleWidgetData = useCallback(async () => {
    if (
      !fromStartMoment.isValid() ||
      !fromEndMoment.isValid() ||
      !toStartMoment.isValid() ||
      !toEndMoment.isValid()
    ) {
      return;
    }

    try {
      const data = await fetchGeneralWidgetData(
        fromStartMoment.format(DATE_FORMAT),
        fromEndMoment.format(DATE_FORMAT),
        toStartMoment.format(DATE_FORMAT),
        toEndMoment.format(DATE_FORMAT)
      );
      setGeneralWidgetData(data);
    } catch {
      setGeneralWidgetData(initialGeneralWidgetValues);
    }
  }, [fromStartMoment, fromEndMoment, toStartMoment, toEndMoment]);

  const handleGeneralTabData = useCallback(async () => {
    setChartTitle('PROFIT');
    const profit = await fetchProfitData(
      fromStartMoment.format(DATE_FORMAT),
      fromEndMoment.format(DATE_FORMAT),
      toStartMoment.format(DATE_FORMAT),
      toEndMoment.format(DATE_FORMAT)
    );
    setChartData(profit);
  }, [fromStartMoment, fromEndMoment, toStartMoment, toEndMoment]);

  const handleSalesTabData = useCallback(async () => {
    setChartTitle('REVENUE');
    const revenue = await fetchSalesTotalRevenue(
      fromStartMoment.format(DATE_FORMAT),
      fromEndMoment.format(DATE_FORMAT),
      toStartMoment.format(DATE_FORMAT),
      toEndMoment.format(DATE_FORMAT)
    );
    setChartData(revenue);
  }, [fromStartMoment, fromEndMoment, toStartMoment, toEndMoment]);

  const handlePurchaseTabData = useCallback(async () => {
    setChartTitle('EXPENSES');
    const expenses = await fetchPurchasingExpenses(
      fromStartMoment.format(DATE_FORMAT),
      fromEndMoment.format(DATE_FORMAT),
      toStartMoment.format(DATE_FORMAT),
      toEndMoment.format(DATE_FORMAT)
    );
    setChartData(expenses);
  }, [fromStartMoment, fromEndMoment, toStartMoment, toEndMoment]);

  const handleTabChange = useCallback(
    async (event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  useEffect(() => {
    (async () => {
      getSalesByRegion();
      handleWidgetData();
      switch (activeTab) {
        case 0:
          handleGeneralTabData();
          break;
        case 1:
          handleSalesTabData();
          break;
        case 2:
          handlePurchaseTabData();
          break;
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDateRange, toDateRange, activeTab]);

  const handleFromDateChange = useCallback((value: DateRange) => {
    const fromMoment = moment(value.startDate);
    const toMoment = moment(value.endDate);
    const diff = Math.abs(fromMoment.diff(toMoment, 'days'));

    setFromDateRange(value);
    setToDateRange({
      startDate: fromMoment
        .clone()
        .add(-1 * diff - 1, 'days')
        .toDate(),
      endDate: fromMoment.clone().add(-1, 'day').toDate(),
    });
  }, []);
  const handleToDateChange = useCallback(
    (value: DateRange) => {
      setToDateRange(value);
    },
    [setToDateRange]
  );

  const handleItemModuleClick = () => {
    localStorage.setItem('showWelcomeModal', 'false');
    setShowWelcomeModal(false);
    history.push('/materials/items');
  };

  const handleClose = () => {
    if (!isLoading) {
      localStorage.setItem('showWelcomeModal', 'false');
      setShowWelcomeModal(false);
    }
  };

  const handleSampleDataClick = useCallback(async () => {
    setIsLoading(true);
    try {
      await populateTestEnvironment();
      history.go(0);
    } catch {
      setIsLoading(false);
      localStorage.setItem('showWelcomeModal', 'false');
      setShowWelcomeModal(false);
      return;
    }
    setIsLoading(false);
    localStorage.setItem('showWelcomeModal', 'false');
    setShowWelcomeModal(false);
  }, [history]);

  const NewTenantModalActions = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
      paddingX={2}
    >
      <Box>
        <FBOButton
          variant="secondary"
          color="positive"
          size="medium"
          onClick={handleItemModuleClick}
          data-qa="go-to-item-module-button"
          disabled={isLoading}
        >
          Go to Item Module
        </FBOButton>
      </Box>

      <FBOButton
        variant="primary"
        color="positive"
        size="medium"
        onClick={handleSampleDataClick}
        data-qa="populate-sandbox-sample-data-button"
        loading={isLoading}
      >
        Populate Sample Data
      </FBOButton>
    </Box>
  );

  return (
    <Page>
      <Box className={classes.container}>
        <Box className={classes.sidebar}>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            className={classes.tabs}
            orientation="vertical"
            indicatorColor="secondary"
          >
            <Tab
              label="General"
              icon={<AssessmentIcon />}
              className={classes.tab}
              data-qa="general-tab"
            />
            <Tab
              label="Sales"
              icon={<MonetizationOnOutlinedIcon />}
              className={classes.tab}
              data-qa="sales-tab"
            />
            <Tab
              label="Purchasing"
              icon={<WidgetsOutlinedIcon />}
              className={classes.tab}
              data-qa="purchasing-tab"
            />
          </Tabs>

          <Box borderTop="1px solid #EBEBEB" mt={2} pt={3}>
            <Box mb={2}>
              <Box mb={1} pl={1.85}>
                <Typography variant="body2" color="textSecondary">
                  From
                </Typography>
              </Box>
              <DateRangePicker
                value={fromDateRange}
                label="Month to Date"
                name="dateRange"
                placeholder="Select Date Range"
                onChange={handleFromDateChange}
                fullWidth
                dataQa="home-from-date"
              />
            </Box>
            <Box mb={1} pl={1.85}>
              <Typography variant="body2" color="textSecondary">
                Compared to
              </Typography>
            </Box>
            <DateRangePicker
              value={toDateRange}
              label="Previous Month"
              name="dateRange2"
              placeholder="Select Date Range"
              onChange={handleToDateChange}
              rangeDays={fromDiff}
              fullWidth
              dataQa="previous-month-box"
            />
          </Box>
        </Box>

        <Box className={classes.content}>
          <DashboardWidgets data={generalWidgetData} />

          <DashboardLineChart
            title={chartTitle}
            data={chartData}
            lineChartData={activeLineChartData}
          />

          <TabPanel value={activeTab} index={0} noSpacing overflow>
            <DashboardGeneral
              fromStartDate={fromStartMoment}
              fromEndDate={fromEndMoment}
              salesByRegion={salesByRegion}
              setSalesByRegion={setSalesByRegion}
              nonAddressedOrder={nonAddressedOrder}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={1} noSpacing overflow>
            <DashboardSales
              fromStartDate={fromStartMoment}
              fromEndDate={fromEndMoment}
            />
          </TabPanel>
          <TabPanel value={activeTab} index={2} noSpacing overflow>
            <DashboardPurchasing
              fromStartDate={fromStartMoment}
              fromEndDate={fromEndMoment}
              toStartMoment={toStartMoment}
              toEndMoment={toEndMoment}
            />
          </TabPanel>
        </Box>
        {flags.welcomeModal && (
          <ConfirmationModal
            open={
              localStorage.getItem('showWelcomeModal') === 'true' &&
              showWelcomeModal
            }
            onCancelClicked={handleClose}
            title={'Welcome to Fishbowl Drive!'}
            body={
              'To get started we recommend that you populate this sandbox with some sample data. Populating sample data will take a minute. Or if you’re ready to get started with your own data you can go to the items module to start adding or uploading items.'
            }
            dataQa={'welcome-modal'}
            onConfirmClicked={() => {}}
            DialogActionsComponent={NewTenantModalActions}
            showCloseIcon
          />
        )}
      </Box>
    </Page>
  );
};

DashboardPage.route = Routes.DashboardPage;

export default DashboardPage;
