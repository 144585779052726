import _ from 'lodash';

import { PermissionType } from './types';

/**
 * @param allowedPermissions
 * @param userPermissions
 *
 * Check for permission.
 * If no allowed permission is sent OR user has at least 1 permission, return TRUE
 */
export const hasPermission = (
  allowedPermissions: PermissionType[],
  userPermissions: PermissionType[],
  isAdmin: boolean
) => {
  return (
    !allowedPermissions?.includes(PermissionType.Undelete) &&
    (isAdmin ||
      allowedPermissions?.length === 0 ||
      _.difference(allowedPermissions, userPermissions).length <
        allowedPermissions?.length)
  );
};
