import React, { memo, useEffect, useMemo, useRef } from 'react';
import { Box, Typography, Tooltip } from '@mui/material';

import { DIMENSIONS_UNITS } from 'services/items';

import { PackageDimensionsInputProps } from './types';
import { MenuItem } from 'ui/components/Menu';

import {
  useDimensionsStyle,
  LengthInput,
  WidthInput,
  HeightInput,
} from './styled';

const dimensionOptions: MenuItem[] = DIMENSIONS_UNITS.map((u) => ({
  label: u.name,
  shortName: u.shortName,
}));

const PackageDimensionsInput = (props: PackageDimensionsInputProps) => {
  const {
    lengthValue,
    widthValue,
    heightValue,
    onLengthChange,
    onWidthChange,
    onHeightChange,
    onMenuChange,
    error,
    dimension,
  } = props;

  const classes = useDimensionsStyle(props);

  const firstInputElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (firstInputElement.current !== null) {
      firstInputElement.current.focus();
      firstInputElement.current.select();
    }
  }, []);

  const selectedDimensionIndex = useMemo(() => {
    if (!dimension) {
      return null;
    }
    return dimensionOptions.findIndex((o) => o.label === dimension);
  }, [dimension]);

  return (
    <Box display="flex" justifyContent="flex-start">
      <Tooltip title="Length" placement="bottom-start">
        <LengthInput
          type="number"
          value={lengthValue}
          inputRef={firstInputElement}
          onChange={onLengthChange}
          disableDebounce={true}
          error={error}
        />
      </Tooltip>
      <Typography className={classes.separator} color="textSecondary">
        x
      </Typography>
      <Tooltip title="Width" placement="bottom-start">
        <WidthInput
          type="number"
          value={widthValue}
          onChange={onWidthChange}
          disableDebounce={true}
          error={error}
        />
      </Tooltip>
      <Typography className={classes.separator} color="textSecondary">
        x
      </Typography>
      <Tooltip title="Height" placement="bottom-start">
        <HeightInput
          type="number"
          value={heightValue}
          onTextChange={onHeightChange}
          disableDebounce={true}
          onMenuChange={onMenuChange}
          options={dimensionOptions}
          selectedOptionIndex={selectedDimensionIndex}
          error={error}
        />
      </Tooltip>
    </Box>
  );
};

export default memo(PackageDimensionsInput);
