import { TaxJarChannelData } from './types';

export enum TaxJarReportingOptions {
  Calculation = 'Calculation',
  CalculationsAndReporting = 'Calculations and Reporting',
}

export const initialTaxJarChannelData: TaxJarChannelData = {
  taxId: null,
  token: null,
  includeTaxReporting: TaxJarReportingOptions.Calculation,
};
