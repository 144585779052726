import React, { ReactNode, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';

import {
  ChannelType,
  EcommerceLocationHash,
  EcommerceSettingsExtended,
  importSettings,
  MappingsWithIds,
  EcommerceSettings,
  initialEcommerceSettingsMultipleConnections,
  putSettings,
} from 'services/integrations/ecommerce';
import {
  fetchEcommerceChannels,
  getAllECommerceSettings,
} from 'services/integrations/ecommerce/redux';
import { fetchEcommerceMappings } from 'services/integrations/api/api';
import { showNotification } from 'services/api';
import _ from 'lodash';

import {
  AmazonLogoSmall,
  BigCommerceLogoSmall,
  EbayLogoSmall,
  MagentoLogoSmall,
  SalesforceLogoSmall,
  ShipStationLogoSmall,
  ShopifyLogoSmall,
  WooCommerceLogoSmall,
} from 'ui/theme';
import { logErrorCtx } from 'app/logging';

export const EcommerceContext = React.createContext<
  [
    EcommerceSettings[],
    React.Dispatch<React.SetStateAction<EcommerceSettings[]>>,
    (id: number) => Promise<void>,
    any[],
    React.Dispatch<React.SetStateAction<any[]>>,
    boolean,
    boolean,
    EcommerceSettingsExtended[],
    React.Dispatch<React.SetStateAction<EcommerceSettingsExtended[]>>,
    MappingsWithIds[]
  ]
>([
  initialEcommerceSettingsMultipleConnections,
  () => {},
  async () => {},
  [],
  () => {},
  false,
  false,
  [],
  () => {},
  [],
]);

export const EcommerceProvider: React.FC<EcommerceProviderProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const [settings, setSettings] = useState<EcommerceSettings[]>([]);
  const [settingsExtended, setSettingsExtended] = useState<
    EcommerceSettingsExtended[]
  >([]);
  const [mappings, setMappings] = useState<MappingsWithIds[]>([]);
  const [errors, setErrors] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const isConnected = settings.length > 0;

  const reduxSettings = useSelector(getAllECommerceSettings());

  useEffect(() => {
    if (reduxSettings.length > 0) {
      setSettings(reduxSettings);

      const setEx: EcommerceSettingsExtended[] = [];
      const setEr: any[] = [];
      const setMp: MappingsWithIds[] = [];

      reduxSettings.forEach((s) => {
        setEx.push({
          settings: { ...s },
          isSetupNeeded: !s.order.location,
        });
        setEr.push([]);
      });

      setSettingsExtended(setEx);
      setErrors(setEr);

      (async () => {
        setIsLoading(true);
        for (const s of reduxSettings) {
          if (s.id) {
            try {
              const resp = await fetchEcommerceMappings(s.id);
              setMp.push({ id: s.id!, mappings: resp });
            } catch (e) {
              logErrorCtx('Error in fetchEcommerceMappings', {
                error: e as Error,
                component: 'EcommerceProvider',
              });
            }
          }
        }
        setMappings(setMp);
        setIsLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxSettings.length]);

  const saveSettings = useCallback(
    async (channelId) => {
      setIsLoading(true);
      try {
        await putSettings(
          channelId,
          settings.find((s) => s.channel.id === channelId)!
        );
        await importSettings(channelId);
        // Lint skip to be removed
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(fetchEcommerceChannels());

        showNotification('Settings updated', { variant: 'success' });
      } catch (e) {
        const messages = _.get(e, 'response.data.messages', []);
        const message: any = messages.find((m: any) => m.severity === 'Error');
        showNotification(_.get(message, 'body', 'Please contact support'), {
          variant: 'error',
        });

        setIsLoading(false);
        throw e;
      }
      setIsLoading(false);
    },
    [settings, dispatch]
  );

  return (
    <EcommerceContext.Provider
      value={[
        settings,
        setSettings,
        saveSettings,
        errors,
        setErrors,
        isConnected,
        isLoading,
        settingsExtended,
        setSettingsExtended,
        mappings,
      ]}
    >
      <Box id="ecommerce">{children}</Box>
    </EcommerceContext.Provider>
  );
};

export const GetDivIdByChannel = (channelType: number) => {
  let divId = '';
  switch (channelType) {
    case ChannelType.Shopify:
      divId = EcommerceLocationHash.Shopify;
      break;
    case ChannelType.Amazon:
      divId = EcommerceLocationHash.Amazon;
      break;
    case ChannelType.WooCommerce:
      divId = EcommerceLocationHash.WooCommerce;
      break;
    case ChannelType.Salesforce:
      divId = EcommerceLocationHash.Salesforce;
      break;
    case ChannelType.BigCommerce:
      divId = EcommerceLocationHash.BigCommerce;
      break;
    case ChannelType.Magento:
      divId = EcommerceLocationHash.Magento;
      break;
    case ChannelType.ShipStation:
      divId = EcommerceLocationHash.ShipStation;
      break;
    case ChannelType.Ebay:
      divId = EcommerceLocationHash.Ebay;
      break;
  }

  return divId.replace('#', '');
};

export const imageLogoUrl = (channelType: number) => {
  switch (channelType) {
    case ChannelType.Amazon:
      return AmazonLogoSmall;
    case ChannelType.BigCommerce:
      return BigCommerceLogoSmall;
    case ChannelType.Magento:
      return MagentoLogoSmall;
    case ChannelType.Salesforce:
      return SalesforceLogoSmall;
    case ChannelType.ShipStation:
      return ShipStationLogoSmall;
    case ChannelType.Shopify:
      return ShopifyLogoSmall;
    case ChannelType.WooCommerce:
      return WooCommerceLogoSmall;
    case ChannelType.Ebay:
      return EbayLogoSmall;
    default:
      break;
  }
};

interface EcommerceProviderProps {
  children: ReactNode;
}
