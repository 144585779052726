import React, { memo, useEffect, useState } from 'react';
import { Box, Button, Typography, Divider, Link } from '@mui/material';

import { getWaitingToExport, initialToExportData } from 'services/accounting';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { markAsPosted } from 'services/ledgers';
import { Paper } from 'ui/components/Paper/Paper';

import { TitleBar } from 'ui/components/TitleBar';

import { useCardStyle } from '../../styled';
import { CardWaitingToExportProps } from './types';
import { logErrorCtx } from 'app/logging';

const CardWaitingToExport: React.FC<CardWaitingToExportProps> = (props) => {
  const { onMappingClicked } = props;

  const [toExportData, setToExportData] = useState(initialToExportData);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const classes = useCardStyle();

  useEffect(() => {
    (async () => {
      try {
        const data = await getWaitingToExport();
        setToExportData(data);
      } catch {
        // Ignore error
      }
    })();
  }, []);

  const handleMarkAsPosted = async () => {
    setIsLoading(true);
    try {
      await markAsPosted();
      const data = await getWaitingToExport();
      setToExportData(data);
    } catch (e) {
      logErrorCtx('Waiting to Export Error', {
        error: e as Error,
        description: 'Cannot Retrieve Export Data',
        component: 'CardWaitingToExport',
      });
    }
    setIsLoading(false);
    setShowModal(false);
  };

  return (
    <Box className={classes.container}>
      <Paper id="waitingToExportId" className={classes.paper}>
        <TitleBar dangerouslySetInnerHTML={true} title="Waiting To Export">
          <Button onClick={onMappingClicked}>
            <b>Account Mapping</b>
          </Button>
        </TitleBar>
        <Box className={classes.rowContainer}>
          <Box className={classes.titleContainer}>
            <Typography
              dangerouslySetInnerHTML={{ __html: 'Inventory Adjustments' }}
            />
          </Box>
          <Typography className={classes.text} noWrap>
            {toExportData.inventoryAdjustments}
          </Typography>
        </Box>
        <Divider />
        <Box className={classes.rowContainer}>
          <Box className={classes.titleContainer}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: 'Purchase Order Fulfillments',
              }}
            />
          </Box>
          <Typography className={classes.text} noWrap>
            {toExportData.bills}
          </Typography>
        </Box>
        <Divider />
        <Box className={classes.rowContainer}>
          <Box className={classes.titleContainer}>
            <Typography
              dangerouslySetInnerHTML={{ __html: 'Sales Order Fulfillments' }}
            />
          </Box>
          <Typography className={classes.text} noWrap>
            {toExportData.invoices}
          </Typography>
        </Box>
        <Divider />
        <Box className={classes.rowContainer}>
          <Box className={classes.titleContainer}>
            <Typography dangerouslySetInnerHTML={{ __html: 'Payment' }} />
          </Box>
          <Typography className={classes.text} noWrap>
            {toExportData.payments}
          </Typography>
        </Box>
        <Divider />
        <Box className={classes.rowContainer}>
          <Box className={classes.titleContainer}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: "Don't want to export to QuickBooks Desktop?",
              }}
            />
          </Box>
          <Link
            className={classes.link}
            underline="always"
            variant="body2"
            color="primary"
            onClick={() => setShowModal(true)}
          >
            Mark as posted
          </Link>
        </Box>
      </Paper>
      <ConfirmationModal
        body={`This will mark all available QuickBooks Online exports as already
          posted. Continue?`}
        maxWidth="sm"
        open={showModal}
        title="Confirm Mark As Posted"
        onCancelClicked={() => setShowModal(false)}
        onConfirmClicked={handleMarkAsPosted}
        isLoading={isLoading}
        confirmLabel="Continue"
      />
    </Box>
  );
};

export default memo(CardWaitingToExport);
