import {
  paginateURL,
  advancedSearchURL,
  quickSearchURL,
  expandURL,
} from 'services/url';
import { createApiCall } from 'services/api';
import { FetchItemsHandler } from '../types';
import _ from 'lodash';
import { searchTermURL } from 'services/url/search';
import { urlContainsNewSearchEndpoint } from 'ui/components/Page/WithSearchResults/helpers';

export const fetchItems: FetchItemsHandler = async (
  url,
  dataAdapter,
  currentState,
  quickSearchColumns
) => {
  let path = url;
  const advancedSearch = currentState.advancedSearch;

  //new endpoints have different rules
  if (urlContainsNewSearchEndpoint(url)) {
    path = searchTermURL(
      path,
      currentState.quickSearch,
      quickSearchColumns,
      advancedSearch
    );
  } else {
    // quick search URL using received quick search
    path = quickSearchURL(
      path,
      currentState.quickSearch,
      quickSearchColumns,
      advancedSearch
    );
  }

  // advanced search URL using received advanced search
  path = advancedSearchURL(path, currentState.advancedSearch);

  // expand URL if there is expand field
  if (currentState.expand) {
    path = expandURL(path, currentState.expand);
  }

  // paginate URL if pagination was sent
  if (currentState.pagination) {
    path = paginateURL(path, currentState.pagination);
  }

  const itemsResponse = await createApiCall(
    { path, method: 'GET' },
    {
      getMessage: {
        error: (error: any) =>
          error.response.data.message ||
          'Something went wrong when we where trying to load items. Please try again in few minutes.',
      },
    }
  )();

  return {
    results: itemsResponse.data.results.map(dataAdapter),
    totalResults: itemsResponse.data.maxResults,
  };
};
