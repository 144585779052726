import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const columns: Column[] = [
  {
    title: 'Item',
    width: '20%',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'On Hand',
    type: ColumnTypes.renderByCustomRow,
    width: '8%',
  },

  {
    title: 'Allocated',
    type: ColumnTypes.renderByCustomRow,
    width: '10%',
  },
  {
    title: 'Available',
    type: ColumnTypes.renderByCustomRow,
    width: '8%',
  },
  {
    title: 'Incoming',
    type: ColumnTypes.renderByCustomRow,
    width: '10%',
  },
  {
    title: 'Min',
    type: ColumnTypes.renderByCustomRow,
    width: '5%',
  },
  {
    title: 'Max',
    type: ColumnTypes.renderByCustomRow,
    width: '5%',
  },
  {
    title: 'UOM',
    type: ColumnTypes.renderByCustomRow,
    width: '6%',
  },
  {
    title: 'To Order',
    type: ColumnTypes.renderByCustomRow,
    width: '16%',
  },
  {
    title: 'Vendor On Order',
    type: ColumnTypes.renderByCustomRow,
    width: '15%',
  },
];
