import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { IconNames, MagentoLogo } from 'ui/theme';
import {
  fetchEcommerceChannels,
  getAllECommerceSettingsByChannel,
} from 'services/integrations/ecommerce/redux';
import { showNotification } from 'services/api';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { Routes as IntegrationsRoutes } from 'ui/modules/integrations/navigation';
import { useHandleTextFieldChange } from 'services/forms';
import {
  ChannelType,
  disconnectChannel,
} from 'services/integrations/ecommerce';
import {
  connectMagento,
  initiaMagentoChannelData,
  MagentoChannelData,
} from 'services/integrations/ecommerce/magento';
import { Errors, validateYup } from 'services/forms/validation';

import { Card } from '../';
import { CardButton } from '../IntegrationButtons/CardButton';
import { magentoSchema } from './validations';
import { MagentoCardProps } from './types';
import { MenuItem } from 'ui/components/Menu';
import { IconButtonWithActions } from 'ui/components/Button/IconButtonWithActions';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const MagentoCard: React.FC<MagentoCardProps> = (props) => {
  const { connectionValid } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const magentoSettings = useSelector(
    getAllECommerceSettingsByChannel(ChannelType.Magento)
  );

  const [magentoForm, setMagentoForm] = useState<MagentoChannelData>(
    initiaMagentoChannelData
  );
  const [modalVisible, setModalVisible] = useState(false);
  const [errors, setErrors] = useState<Errors>({});
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [showConnectionModal, setShowConnectionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeChannel, setActiveChannel] = useState<{
    id: number;
    name: string;
  }>();

  const handleConnectClicked = async () => {
    if (!validateYup(magentoForm, magentoSchema, setErrors)) {
      return;
    }

    magentoForm.domain = magentoForm.domain!.replace(/\/$/, '');

    const domainExists = magentoSettings.filter(
      (el) => el.channel.domain === magentoForm.domain
    );

    if (domainExists.length > 0) {
      showNotification(`Wrong data. Domain already connected.`, {
        variant: 'warning',
      });

      return;
    }

    setIsLoading(true);

    let connected = false;

    try {
      connected = await connectMagento(magentoForm);
    } catch {
      setIsLoading(false);

      return;
    }

    if (!connected) {
      showNotification(`Wrong data. Please try again.`, {
        variant: 'warning',
      });
      setIsLoading(false);
      return;
    }

    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchEcommerceChannels());
    history.push(IntegrationsRoutes.EcommercePage + '#magento');
    showNotification(`Magento connected`, {
      variant: 'success',
    });
    setIsLoading(false);
  };

  const handleConnect = () => {
    if (!connectionValid) {
      setShowConnectionModal(true);
      return;
    }

    setModalVisible(true);
  };

  const handleDisconnect = async () => {
    if (activeChannel) {
      setIsLoading(true);

      try {
        await disconnectChannel(activeChannel.id);
        // Lint skip to be removed
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(fetchEcommerceChannels());
        showNotification(`Magento disconnected`, {
          variant: 'success',
        });
      } catch (e) {
        const error = e as Error;

        showNotification(`Magento disconnect failed - ${error.message}`, {
          variant: 'error',
        });
      }

      setIsLoading(false);
      setShowDisconnectModal(false);
      setActiveChannel(undefined);
    } else {
      showNotification(`Unable to disconnect! No channel id provided!`, {
        variant: 'error',
      });
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setMagentoForm(initiaMagentoChannelData);
  };

  const handleTextFieldChange = useHandleTextFieldChange(
    setMagentoForm,
    magentoForm
  );

  const createItemsSearchActionBarOptions = (): MenuItem[] => {
    return magentoSettings.map((channel) => {
      return {
        label: channel.channel.name!,
        onOptionClick: () => {
          setActiveChannel({
            id: channel.channel.id!,
            name: channel.channel.name!,
          });
          setShowDisconnectModal(true);
        },
      };
    });
  };

  return (
    <>
      <Card
        imageUrl={MagentoLogo}
        cardId="magento"
        isLoading={false}
        description="Manage orders while keeping inventory levels up to date with Adobe Commerce."
        title="Adobe Commerce"
      >
        <>
          {magentoSettings.length > 0 ? (
            <IconButtonWithActions
              icon={IconNames.MoreVertical}
              iconSize={16}
              text="Disconnect"
              boxStyle
              items={createItemsSearchActionBarOptions()}
            />
          ) : null}
          <CardButton
            onClick={handleConnect}
            variant="primary"
            color="positive"
            dataQa="magento-connect"
            label="Connect"
          />
        </>
      </Card>
      <Modal
        open={modalVisible}
        title="Enter Adobe Commerce data"
        maxWidth="sm"
        isLoadingContent={isLoading}
        onCancelClicked={handleCloseModal}
        ModalActionsComponent={() => (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <FBOButton
              variant="secondary"
              color="positive"
              data-qa="cancel-button"
              onClick={handleCloseModal}
            >
              Cancel
            </FBOButton>
            <Box ml={2}>
              <FBOButton
                variant="primary"
                color="positive"
                data-qa="save-button"
                onClick={handleConnectClicked}
              >
                Save
              </FBOButton>
            </Box>
          </Box>
        )}
      >
        <form onSubmit={handleConnectClicked}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <TextField
                label="Store URL"
                name="domain"
                onChange={handleTextFieldChange}
                value={magentoForm.domain}
                required
                error={!!errors.domain}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                label="Auth Token"
                name="token"
                onChange={handleTextFieldChange}
                value={magentoForm.token}
                required
                error={!!errors.token}
              />
            </Grid>
          </Grid>
        </form>
      </Modal>
      <ConfirmationModal
        open={showDisconnectModal}
        title="Disconnect Adobe Commerce"
        body={
          'Are you sure you want to disconnect Adobe Commerce channel ' +
          (activeChannel ? activeChannel!.name : null) +
          '?'
        }
        isLoading={isLoading}
        onCancelClicked={() => setShowDisconnectModal(false)}
        onConfirmClicked={handleDisconnect}
      />
      <ConfirmationModal
        open={showConnectionModal}
        title="Maximum number of integrations has been reached!"
        onConfirmClicked={() => setShowConnectionModal(false)}
        onCancelClicked={() => setShowConnectionModal(false)}
        body="Please contact sales to add additional integrations."
      />
    </>
  );
};

export default memo(MagentoCard);
