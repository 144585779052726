import React from 'react';
import { Box, TableCell, Typography } from '@mui/material';
import _ from 'lodash';
import Add from '@mui/icons-material/Add';
import Remove from '@mui/icons-material/Remove';

import { colorPalette } from 'ui/theme';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable/types';
import { Location } from 'services/locations';

const LocationRow: React.FC<RenderCustomRowProps<Location>> = (props) => {
  const { row, meta } = props;

  const searchRow = _.get(meta, 'searchRow', false);

  return (
    <>
      <TableCell>
        <Box height={40}>
          <Typography variant="body1" color="textPrimary">
            {row.name}
          </Typography>
          {row.heritage && (
            <Typography variant="subtitle1" color="textSecondary">
              {row.heritage}
            </Typography>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Box
          display="flex"
          alignItems="center"
          width={24}
          height="100%"
          color={colorPalette.fishbowlCorporateGray3}
        >
          {searchRow ? <Add color="inherit" /> : <Remove color="inherit" />}
        </Box>
      </TableCell>
    </>
  );
};

export default LocationRow;
