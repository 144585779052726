import React, { useMemo } from 'react';

import { Pagination } from 'services/search';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { LOCATION_COLUMNS } from 'services/locations';
import { PermissionType } from 'services/permissions';
import { arrayHasAllDeletedObjects } from 'helpers';
import { LocationSearchResultsProps } from './types';
import { createLocationsSearchActionBarOptions } from './consts';
import { default as LocationRow } from './LocationRow';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const LocationSearchCard: React.FC<LocationSearchResultsProps> = ({
  locations,
  activeLocationId,
  handleLocationClick,
  onAddNewPress,
  onPaginationChange,
  activeDate,
  isLoadingLocations,
  pagination,
  onOrderClicked,
  onLocationsPageAction,
}) => {
  const allDeletedLocations = useMemo(
    () => arrayHasAllDeletedObjects(locations),
    [locations]
  );

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  const alwaysOnColumns = LOCATION_COLUMNS(activeDate).filter(
    (c) => c.title === 'Name'
  );

  return (
    <>
      <FBOTitleBar title="Location">
        {activeLocationId !== -1 && (
          <>
            {!activeLocationId && (
              <FBOButton
                style={{ marginRight: '8px' }}
                variant="secondary"
                color="positive"
                size="medium"
                onClick={onOrderClicked}
                permissions={[
                  PermissionType.LocationsCreate,
                  PermissionType.LocationEdit,
                ]}
                data-qa="location-order-pick-button"
              >
                Order Pick
              </FBOButton>
            )}
            <FBOButton
              style={{ marginRight: '8px' }}
              variant={!activeLocationId ? 'primary' : 'secondary'}
              color="positive"
              size="medium"
              icon="FBOAddCircle"
              onClick={onAddNewPress}
              data-qa="location-new"
              permissions={[PermissionType.LocationsCreate]}
            >
              {!activeLocationId ? 'Add New' : ''}
            </FBOButton>
          </>
        )}
        <FBOMenuButton
          variant="secondary"
          items={createLocationsSearchActionBarOptions(
            onLocationsPageAction,
            locations.length
          )}
          data-qa="location-three-dot-menu"
        />
      </FBOTitleBar>

      <ItemsTable
        data={locations}
        columns={
          activeLocationId ? alwaysOnColumns : LOCATION_COLUMNS(activeDate)
        }
        RenderCustomRow={LocationRow}
        activeId={activeLocationId}
        onItemClick={handleLocationClick}
        selectableItems={false}
        disableSelection={allDeletedLocations}
        isLoadingData={isLoadingLocations}
        pagination={pagination}
        onPaginationChange={handlePaginationChange}
        dataQa="location-table"
        showEnhancedTablePaginationActions={!Boolean(activeLocationId)}
      />
    </>
  );
};

export default LocationSearchCard;
