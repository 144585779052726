import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'helpers/useFlags';

import {
  transformPurchaseOrder,
  fetchPurchaseOrder,
  PurchaseOrder,
  fetchSearch,
  getSearches,
  getPurchaseOrderId,
  unissuePurchaseOrders,
  deletePurchaseOrders,
  PurchaseOrderStatus,
  issuePurchaseOrders,
} from 'services/purchaseOrders';
import { fetchVendors, getVendors } from 'services/vendors';
import { fetchCarriers, getCarriers } from 'services/carriers';
import { fetchLocations, getLocations } from 'services/locations';
import { fetchUoms } from 'services/uoms';
import { fetchCustomers } from 'services/customers';
import { fetchUsers, getUsers } from 'services/user';
import { fetchPaymentTerms, getPaymentTerms } from 'services/paymentTerms';
import { SHIPPING_TERMS } from 'services/items';
import { Pagination } from 'services/search';
import {
  clearModuleNavigation,
  ModuleNavigationType,
  removeModuleNavigation,
} from 'services/moduleNavigation';
import { fetchSettingsCompanies } from 'services/settings/company/redux';
import { fetchSettingsPurchaseOrders } from 'services/settings/purchaseOrders';
import { showNotification } from 'services/api';
import { Routes } from 'ui/modules/purchasing/navigation';
import {
  ActiveItemIdState,
  withSearchResults,
} from 'ui/components/Page/WithSearchResults';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { CustomFieldsModal } from 'ui/components/CustomFields/CustomFieldsModal';
import { ReportsModal } from 'ui/components/Modal/ReportsModal';
import { ObjectType } from 'services/customFields';
import { useUrlQueryObject } from 'services/url';
import { ReportId } from 'services/reports';
import {
  showDownloadNotification,
  showLoadingNotification,
} from 'services/api/notifications';
import { showProgressAlert } from 'services/alert/redux';
import {
  BackgroundAction,
  BackgroundType,
  startBackgroundExport,
  startBackgroundImport,
  useBackgroundTasks,
} from 'services/backgroundTasks';
import { Dates, getErrorMessage, useGetIntlDateFormatString } from 'helpers';
import { fetchClasses } from 'services/classes';
import { fetchTaxRates } from 'services/taxRates';

import { AutoPurchaseOrderWizard } from './components/AutoPurchaseOrderWizard';
import { PurchaseOrderPageCmp, PurchaseOrderPageProps } from './types';
import {
  initialPagination,
  initialPurchaseOrderFormValues,
  advancedSearchReduxActions,
  displayNameMap,
  createDisplayValueMap,
} from './consts';
import {
  PurchaseOrderAdvancedSearch,
  PurchaseOrderSearchResults,
  PurchaseOrderDetailsCard,
} from './components';
import { PURCHASE_ORDER_COLUMNS } from './components/PurchaseOrderSearchResults';
import {
  PurchaseOrderPageAction,
  PurchaseOrderTableRowActions,
} from './components/PurchaseOrderSearchResults/types';
import { logErrorCtx } from 'app/logging';
import { ErrorBoundary } from '@datadog/rum-react-integration';
import { FallbackUI } from 'ui/components/Modal/ErrorMessage/common';
import { ModuleNavigation } from 'app/components/AppBar/components';

const PurchaseOrderPage: PurchaseOrderPageCmp = (
  props: PurchaseOrderPageProps
) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activePurchaseOrderId,
  } = props;

  const dispatch = useDispatch();
  const intlFormatDate = useGetIntlDateFormatString();
  const csvEnabled = useFlags().csvImport;

  const { items: vendors } = useSelector(getVendors);
  const { items: carriers } = useSelector(getCarriers);
  const { items: paymentTerms } = useSelector(getPaymentTerms);
  const { items: locations } = useSelector(getLocations);
  const users = useSelector(getUsers);

  const hiddenInput = useRef<HTMLInputElement>(null);

  const [nextPoNumber, setNextPoNumber] = useState<number>(-1);
  const [clone, setClone] = useState<boolean>(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [customFieldsModalVisible, setCustomFieldsModalVisible] =
    useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showAutoPurchaseOrderWizard, setShowAutoPurchaseOrderWizard] =
    useState(false);
  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateCreated);
  const [unissueModalVisible, setUnissueModalVisible] = useState(false);
  const [issueModalVisible, setIssueModalVisible] = useState(false);
  const [unissueLoadingVisible, setUnissueLoadingVisible] = useState(false);
  const [issueLoadingVisible, setIssueLoadingVisible] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);

  const [, extendUrlQuery] = useUrlQueryObject();

  const { startFetching, startCsvFetching } = useBackgroundTasks();

  const selectedPurchaseOrders: PurchaseOrder[] = useMemo(() => {
    return searchResult.items.filter((item) =>
      selectedItems.includes(item.id!)
    );
  }, [selectedItems, searchResult.items]);

  useEffect(() => {
    dispatch(clearModuleNavigation(ModuleNavigationType.Purchase));

    return () => {
      dispatch(removeModuleNavigation());
    };
  }, [dispatch]);

  const exportPurchaseOrders = useCallback(async () => {
    showDownloadNotification();
    try {
      await startBackgroundExport(BackgroundType.PurchaseOrder);
      startFetching();
    } catch {
      // continue regardless of error
    }
  }, [startFetching]);

  const importPurchaseOrders = useCallback(() => {
    hiddenInput.current!.click();
  }, [hiddenInput]);

  const handleHiddenInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const uploadedFile = event.target.files[0];
      showLoadingNotification('Your Purchase Order import has been initiated.');

      if (csvEnabled) startCsvFetching();
      event.target.value = '';
      try {
        await startBackgroundImport(
          BackgroundType.PurchaseOrder,
          uploadedFile,
          csvEnabled
        );
        dispatch(
          showProgressAlert(
            'Your Import to Purchase Order has been initiated. This may take a few minutes to complete.',
            BackgroundType.PurchaseOrder,
            BackgroundAction.Import
          )
        );
      } catch (e) {
        const message = getErrorMessage(e);

        logErrorCtx('Error in startBackgroundImport', {
          error: e as Error,
          description: message,
          component: 'PurchaseOrderPage',
        });
        return;
      }
    },
    [dispatch]
  );

  const handlePageAction = useCallback(
    (action: PurchaseOrderPageAction, date?: Dates) => {
      switch (action) {
        case PurchaseOrderPageAction.Export:
          exportPurchaseOrders();
          break;
        case PurchaseOrderPageAction.Import:
          importPurchaseOrders();
          break;
        case PurchaseOrderPageAction.CustomFields:
          setCustomFieldsModalVisible(true);
          break;
        case PurchaseOrderPageAction.ChangeDate:
          setActiveDate(date!);
          break;
        case PurchaseOrderPageAction.ShowReport:
          setShowReportModal(true);
          break;
      }
    },
    [exportPurchaseOrders, importPurchaseOrders]
  );

  const handleActivePurchaseOrderClose = useCallback(
    () => extendUrlQuery({ activeId: null }),
    [extendUrlQuery]
  );

  const handleAddNewPress = useCallback(async () => {
    const poNumber = await getPurchaseOrderId();
    setNextPoNumber(poNumber || -1);
    extendUrlQuery({ activeId: ActiveItemIdState.New });
    setClone(false);
  }, [extendUrlQuery]);

  const purchaseOrderClicked = useCallback(
    (purchaseOrderId: number) => {
      extendUrlQuery({ activeId: purchaseOrderId });
      setClone(false);
    },
    [extendUrlQuery]
  );

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );

  const hideDeleteModal = useCallback(() => {
    setDeleteModalVisible(false);
    setSelectedItems([]);
  }, [setSelectedItems]);

  const hideUnissueModal = useCallback(() => {
    setUnissueModalVisible(false);
    setSelectedItems([]);
  }, [setSelectedItems]);

  const closeIssueModal = useCallback(() => {
    setIssueModalVisible(false);
    setSelectedItems([]);
  }, [setSelectedItems]);

  const onDeleteClicked = useCallback(() => {
    const hasNonBidRequest = selectedPurchaseOrders.some(
      (po) => po.status !== PurchaseOrderStatus.BidRequest
    );
    if (hasNonBidRequest) {
      showNotification(
        'Only purchase orders in the Bid Request status may be deleted.',
        { variant: 'warning' }
      );
      setSelectedItems([]);
      return;
    }
    setDeleteModalVisible(true);
  }, [selectedPurchaseOrders]);

  const onUnissueClicked = useCallback(() => {
    const hasNonIssued = selectedPurchaseOrders.some(
      (po) => po.status !== PurchaseOrderStatus.Issued
    );
    if (hasNonIssued) {
      showNotification(
        'Only purchase orders in the issued status can be unissued.',
        { variant: 'warning' }
      );
      setSelectedItems([]);
      return;
    }
    setUnissueModalVisible(true);
  }, [selectedPurchaseOrders]);

  const handleDeleteConfirm = useCallback(async () => {
    try {
      await deletePurchaseOrders(selectedItems);
      await fetchSearchResult();
      hideDeleteModal();
    } catch {
      // Ignore error
    }
  }, [selectedItems, hideDeleteModal, fetchSearchResult]);

  const handleUnissueConfirm = useCallback(async () => {
    try {
      setUnissueLoadingVisible(true);
      await unissuePurchaseOrders(selectedItems);
      await fetchSearchResult();
      setUnissueLoadingVisible(false);
      hideUnissueModal();
    } catch {
      // Ignore error
    }
  }, [selectedItems, hideUnissueModal, fetchSearchResult]);

  const onIssueClicked = useCallback(() => {
    setIssueModalVisible(true);
  }, [setIssueModalVisible]);

  const handleIssueConfirm = useCallback(async () => {
    setIssueLoadingVisible(true);

    try {
      await issuePurchaseOrders(selectedItems);
      await fetchSearchResult();
      closeIssueModal();
    } catch (error) {
      logErrorCtx('Error in issuePurchaseOrders', {
        error: error as Error,
        component: 'PurchaseOrderPage',
      });
    }

    setIssueLoadingVisible(false);
  }, [selectedItems, closeIssueModal, fetchSearchResult]);

  const handleAction = useCallback(
    (type: PurchaseOrderTableRowActions, value: any) => {
      if (type === PurchaseOrderTableRowActions.Duplicate) {
        setClone(true);
        extendUrlQuery({ activeId: value });
      }
      if (type === PurchaseOrderTableRowActions.Delete) {
        setSelectedItems([value as number]);
        setDeleteClicked(true);
      }
    },
    [extendUrlQuery]
  );

  useEffect(() => {
    if (deleteClicked) {
      onDeleteClicked();
      setDeleteClicked(false);
    }
  }, [deleteClicked, onDeleteClicked]);

  return (
    <ErrorBoundary fallback={FallbackUI}>
      <ModuleNavigation />
      <PageWithAdvancedSearch
        detailCardColumns={PURCHASE_ORDER_COLUMNS(activeDate)}
        initialFormValues={initialPurchaseOrderFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={PurchaseOrderAdvancedSearch}
        displayNameMap={displayNameMap}
        displayValueMap={createDisplayValueMap(
          vendors,
          carriers,
          paymentTerms,
          SHIPPING_TERMS,
          locations,
          users,
          intlFormatDate
        )}
        showAllLabel="Show All Orders"
        pageName="Purchase Orders"
      >
        <PaperSlidingLayout shown={Boolean(activePurchaseOrderId)}>
          <PurchaseOrderSearchResults
            purchaseOrders={searchResult.items}
            activePurchaseOrderId={activePurchaseOrderId}
            handlePurchaseOrderClick={purchaseOrderClicked}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
            onAddNewPress={handleAddNewPress}
            isLoadingPurchaseOrders={isLoadingSearchResult}
            onAction={handleAction}
            selectedPurchaseOrders={selectedPurchaseOrders}
            activeDate={activeDate}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            onUnissueClicked={onUnissueClicked}
            onDeleteClicked={onDeleteClicked}
            onIssueClicked={onIssueClicked}
            onPageAction={handlePageAction}
            onAutoOrderClicked={() => setShowAutoPurchaseOrderWizard(true)}
          />
          <PurchaseOrderDetailsCard
            activePurchaseOrderId={activePurchaseOrderId}
            nextPoNumber={nextPoNumber}
            setNextPoNumber={setNextPoNumber}
            onClose={handleActivePurchaseOrderClose}
            fetchSearchResult={fetchSearchResult}
            clone={clone}
            setClone={setClone}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      <ConfirmationModal
        open={unissueModalVisible}
        title="Unissue Purchase Order"
        body={`This will unissue all selected purchase orders, are you sure?`}
        onCancelClicked={hideUnissueModal}
        onConfirmClicked={handleUnissueConfirm}
        confirmLabel="Unissue"
        cancelLabel="Cancel"
        isLoading={unissueLoadingVisible}
      />
      <ConfirmationModal
        open={issueModalVisible}
        title="Issue Purchase Orders"
        body="This will issue all selected purchase orders, are you sure?"
        onCancelClicked={closeIssueModal}
        onConfirmClicked={handleIssueConfirm}
        confirmLabel="Issue"
        cancelLabel="Cancel"
        isLoading={issueLoadingVisible}
      />
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Purchase Order"
        body={`This will delete all selected purchase orders and all related purchase order items, are you sure?`}
        onCancelClicked={hideDeleteModal}
        onConfirmClicked={handleDeleteConfirm}
        confirmLabel="Delete"
        cancelLabel="Cancel"
        confirmButtonRed
      />
      <CustomFieldsModal
        open={customFieldsModalVisible}
        setVisible={setCustomFieldsModalVisible}
        module={ObjectType.PurchaseOrder}
      />
      <input
        type="file"
        ref={hiddenInput}
        style={{ display: 'none' }}
        onChange={handleHiddenInput}
        accept=".csv"
      />
      <ReportsModal
        isOpen={showReportModal}
        reportId={ReportId.PurchaseOrderSummary}
        onClose={() => setShowReportModal(false)}
        params={{}}
        autoGenerate
      />
      <AutoPurchaseOrderWizard
        visible={showAutoPurchaseOrderWizard}
        onClose={() => setShowAutoPurchaseOrderWizard(false)}
        fetchSearchResult={fetchSearchResult}
      />
    </ErrorBoundary>
  );
};

PurchaseOrderPage.route = Routes.PurchaseOrderPage;

export default withSearchResults<PurchaseOrder>(PurchaseOrderPage, {
  url: '/v1/purchase_orders',
  expand: 'buyer,purchaseOrderItems,vendor',
  dataAdapter: transformPurchaseOrder,
  columns: PURCHASE_ORDER_COLUMNS(Dates.DateCreated),
  quickSearchColumns: [
    'number',
    'purchaseOrderStatus',
    'vendor.name',
    'buyer.firstName',
    'purchaseOrderItems.name',
    'purchaseOrderItems.item.name',
    'purchaseOrderItems.item.sku',
  ],
  getSearches,
  fetchSearch,
  initialPagination,
  rehydrationThunks: [
    fetchPurchaseOrder,
    fetchPaymentTerms,
    fetchLocations,
    fetchCustomers,
    fetchVendors,
    fetchCarriers,
    fetchTaxRates,
    fetchUoms,
    fetchUsers,
    fetchSettingsCompanies,
    fetchSettingsPurchaseOrders,
    fetchClasses,
  ],
});
