import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { styled } from 'ui/theme';
import { TextField } from 'ui/components/TextField/TextField';
import { TextFieldWithOptions } from 'ui/components/TextField/TextFieldWithOptions';

import { PackageDimensionsInputProps } from './types';

export const LengthInput = styled(TextField)`
  max-width: 55px;
  & > .MuiInputBase-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-right: 0;
  }
`;

export const WidthInput = styled(TextField)`
  max-width: 55px;
  & > .MuiInputBase-root {
    border-radius: 0;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-right: 0;
    border-left: 0;
  }
`;

export const HeightInput = styled(TextFieldWithOptions)`
  max-width: 90px;
  & > .MuiInputBase-root {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-left: 0;
  }
`;

export const useDimensionsStyle = makeStyles((theme: Theme) => ({
  separator: {
    display: 'flex',
    alignItems: 'center',
    minWidth: theme.spacing(1),
    borderTop: (props: PackageDimensionsInputProps) =>
      props.error ? '1px solid red' : '1px solid #c4c4c4',
    borderBottom: (props: PackageDimensionsInputProps) =>
      props.error ? '1px solid red' : '1px solid #c4c4c4',
  },
}));
