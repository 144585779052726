import React, {
  memo,
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
} from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import _ from 'lodash';

import {
  fetchShippingIntegrationRates,
  ShippingConnectionType,
  submitPurchaseLabel,
} from 'services/integrations/shipping';
import { getShippingConnection } from 'services/integrations/shipping/redux';
import { Modal } from 'ui/components/Modal/Modal';
import Wizard from 'ui/components/Wizard/Wizard';
import { NetworkSpinnerWrapper } from 'ui/components/NetworkSpinnerWrapper';
import { initialAddress } from 'services/addresses';
import { Errors, validateYup } from 'services/forms/validation';
import {
  initialPurchaseLabelExtra,
  PurchaseLabelResponse,
} from 'services/integrations/shipping/purchaseLabel';
import { showNotification } from 'services/api';
import { getActiveUser } from 'services/user';

import { PurcahseLabelWizardProps } from './types';
import { getPurchaseLabelWizardSteps } from './consts';
import { usePurchaseLabelWizardStyle } from './styled';
import {
  CustomsDeclarationStep,
  ItemsStep,
  PurchaseLabelStep,
} from './components';
import { transformShipToAddress } from './components/PurchaseLabelStep/transformations';
import { yupPurchaseLabelStepValidation } from '../../validations';
import {
  PurchaseLabelContext,
  usePurchaseLabelExtra,
} from './PurchaseLabelProvider';
import {
  fetchRatesValidationShippo,
  fetchRatesValidationShipStation,
} from './components/PurchaseLabelStep/validations';
import { isInternationalAddress } from '../../helpers';
import { mergeLabelsAndReport } from './helpers';
import { BrowserType, checkBrowser } from 'helpers';
import { logErrorCtx } from 'app/logging';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const PurchaseLabelWizard: React.FC<PurcahseLabelWizardProps> = (props) => {
  const { visible, onClose, ship } = props;

  const {
    purchaseLabel,
    setIsLoading,
    isLoading,
    cartons,
    setRates,
    setRatesValidation,
    notUSAddress,
    settings,
  } = useContext(PurchaseLabelContext);

  const { purchaseLabelExtra, setPurchaseLabelExtra } = usePurchaseLabelExtra();

  const classes = usePurchaseLabelWizardStyle();

  const connection = useSelector(getShippingConnection);
  const { user } = useSelector(getActiveUser);

  const [activeStep, setActiveStep] = useState(0);
  const [validationErrors, setValidationErrors] = useState<Errors>({});

  const steps = useMemo(
    () => getPurchaseLabelWizardSteps(classes, activeStep, notUSAddress),
    [classes, activeStep, notUSAddress]
  );

  const finishDisabled =
    !purchaseLabel.purchaseLabelExtra.serviceCode &&
    ((notUSAddress && activeStep === 2) || (!notUSAddress && activeStep === 0));

  useEffect(() => {
    if (visible) {
      setActiveStep(0);
    }
  }, [visible]);

  const finishWizard = useCallback(async () => {
    setIsLoading(true);

    let labels: PurchaseLabelResponse[] = [];
    try {
      labels = await submitPurchaseLabel(
        connection!,
        ship,
        cartons,
        transformShipToAddress(ship),
        _.get(ship, 'salesOrder.location.address', initialAddress),
        purchaseLabel.purchaseLabelExtra
      );
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Error in shipping purchase order', {
        error,
        stackTrace: error.stack,
        title: 'Error when shipping Purchase order',
        description: 'Failed to purchase shipping request',
        component: 'ShippingPage -> PurchaseLabelWizard',
      });
      setIsLoading(false);
      return;
    }

    const isSafari = checkBrowser() === BrowserType.Safari;

    // if submit purchase label is successful
    if (labels.length && !isSafari) {
      try {
        await mergeLabelsAndReport(labels, ship.id!, user!.id!);
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error in generating purchase labels', {
          error,
          stackTrace: error.stack,
          title: error.message,
          description: 'Failed to generate purchase labels',
          component: 'ShippingPage -> PurchaseLabelWizard',
        });
        showNotification(
          'Something went wrong while generating labels. Please contact support.',
          { variant: 'error' }
        );
      }
    }

    setIsLoading(false);
    onClose(true);
    setRates([]);
    setPurchaseLabelExtra((old) => ({
      ...old,
      serviceCode: null,
      carrierCode: null,
    }));
  }, [
    ship,
    purchaseLabel,
    connection,
    setIsLoading,
    onClose,
    cartons,
    user,
    setRates,
    setPurchaseLabelExtra,
  ]);

  const validatePurchaseOrderStep = useCallback(() => {
    return validateYup(
      purchaseLabel.purchaseLabelExtra,
      yupPurchaseLabelStepValidation,
      setValidationErrors
    );
  }, [purchaseLabel.purchaseLabelExtra]);

  const handleNextClicked = useCallback(() => {
    const PurchaseLabelStepNumber = notUSAddress ? 2 : 0;
    if (activeStep === PurchaseLabelStepNumber) {
      const isValid = validatePurchaseOrderStep();
      if (!isValid) {
        return;
      }
    }
    if (activeStep === steps.length - 1) {
      finishWizard();
      setValidationErrors({});
      return;
    }
    setValidationErrors({});
    setActiveStep(activeStep + 1);
  }, [
    notUSAddress,
    activeStep,
    steps,
    validatePurchaseOrderStep,
    finishWizard,
  ]);

  const handleBackClicked = useCallback(() => {
    setActiveStep(activeStep - 1);
    setValidationErrors({});
  }, [activeStep]);

  const handleOnClose = useCallback(() => {
    setValidationErrors({});
    onClose(false);
    setRates([]);
    setPurchaseLabelExtra(initialPurchaseLabelExtra);
  }, [onClose, setRates, setPurchaseLabelExtra]);

  const handleGetRatesClick = useCallback(async () => {
    if (
      !validateYup(
        purchaseLabelExtra,
        connection!.typeId === ShippingConnectionType.Shippo
          ? fetchRatesValidationShippo
          : fetchRatesValidationShipStation,
        setRatesValidation
      )
    ) {
      setRates([]);
      setPurchaseLabelExtra((old) => ({
        ...old,
        serviceCode: null,
        carrierCode: null,
      }));

      return;
    }

    const isInternational = isInternationalAddress(ship, settings);
    if (isInternational) {
      showNotification(
        'International Shipping has been disabled for this account',
        { variant: 'error' }
      );
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    try {
      const res = await fetchShippingIntegrationRates(
        connection!,
        cartons,
        transformShipToAddress(ship),
        _.get(ship, 'salesOrder.location.address', initialAddress),
        purchaseLabelExtra
      );
      setRates(res);
    } catch (e) {
      const error = e as Error;
      logErrorCtx('Error in fetching Shipping integration rates', {
        error,
        stackTrace: error.stack,
        title: error.message,
        description: 'Failed to fetching Shipping integration rates',
        component: 'ShippingPage -> PurchaseLabelWizard',
      });
    }
    setIsLoading(false);
  }, [
    connection,
    settings,
    purchaseLabelExtra,
    setIsLoading,
    cartons,
    ship,
    setPurchaseLabelExtra,
    setRatesValidation,
    setRates,
  ]);

  const FBOCustomFooter = (
    <Grid container>
      <Grid container item xs={6}>
        <FBOButton
          variant="secondary"
          color="neutral"
          size="medium"
          disabled={activeStep === 0}
          onClick={handleBackClicked}
          data-qa="purchase-label-wizard-back-button"
        >
          Back
        </FBOButton>
      </Grid>

      <Grid container item justifyContent="right" xs={6} spacing={2}>
        {activeStep === steps.length - 1 && (
          <Grid item>
            <FBOButton
              variant="secondary"
              color="positive"
              size="medium"
              onClick={handleGetRatesClick}
              data-qa="purchase-label-wizard-get-rates-button"
            >
              Get Rates
            </FBOButton>
          </Grid>
        )}
        <Grid item>
          <FBOButton
            variant="primary"
            color="positive"
            size="medium"
            disabled={finishDisabled}
            onClick={handleNextClicked}
            data-qa="purchase-label-wizard-apply-button"
          >
            {activeStep === steps.length - 1 ? 'Purchase Shipping' : 'Next'}
          </FBOButton>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={visible}
      onCancelClicked={handleOnClose}
      title="Purchase label"
      withoutFooter
      withoutDefaultPadding
    >
      <NetworkSpinnerWrapper isLoading={isLoading} size={24}>
        <Wizard
          steps={steps}
          activeStep={activeStep}
          hideSteps
          customFooter={FBOCustomFooter}
        >
          {notUSAddress
            ? [
                <ItemsStep key={1} shipItems={ship.shipItemList} />,
                <CustomsDeclarationStep key={2} />,
                <PurchaseLabelStep
                  key={3}
                  ship={ship}
                  errors={validationErrors}
                />,
              ]
            : [
                <PurchaseLabelStep
                  key={1}
                  ship={ship}
                  errors={validationErrors}
                />,
              ]}
        </Wizard>
      </NetworkSpinnerWrapper>
    </Modal>
  );
};

export default memo(PurchaseLabelWizard);
