import _ from 'lodash';
import * as yup from 'yup';

export const orderSchema = yup.object({
  location: yup.mixed().when('enabled', {
    is: true,
    then: yup.number(),
  }),
  taxItem: yup.mixed().when('enabled', {
    is: true,
    then: yup.number(),
  }),
  shippingItem: yup.mixed().when('enabled', {
    is: true,
    then: yup.number(),
  }),
  shippingTerms: yup.mixed().when('enabled', {
    is: true,
    then: yup.number(),
  }),
  status: yup.mixed().when('enabled', {
    is: true,
    then: yup.string(),
  }),
});

export const posSchema = yup.object({
  location: yup.mixed().when('enabled', {
    is: true,
    then: yup.number(),
  }),
  taxItem: yup.mixed().when('enabled', {
    is: true,
    then: yup.number(),
  }),
  customer: yup.mixed().when('enabled', {
    is: true,
    then: yup.number(),
  }),
  shippingItem: yup.mixed().when('enabled', {
    is: true,
    then: yup.number(),
  }),
  shippingTerms: yup.mixed().when('enabled', {
    is: true,
    then: yup.number(),
  }),
});

export const customerSchema = yup.object({
  customer: yup.string().required(),
  paymentTerms: yup.mixed().when('useDefault', {
    is: true,
    then: yup.number(),
  }),
  priority: yup.mixed().when('useDefault', {
    is: true,
    then: yup.string(),
  }),
  representative: yup.mixed().when('useDefault', {
    is: true,
    then: yup.string(),
  }),
});

export const itemSchema = yup.object({
  mapping: yup.mixed().when('create', {
    is: true,
    then: yup.lazy((obj) =>
      yup.object(
        _.mapValues(obj as any, (key, value) => {
          // only the Name field needs to be validated and the its Key is 10
          if (value === '10') {
            return yup.string().test('notnull', '', function () {
              return true;
            });
          }
          return yup.mixed();
        })
      )
    ),
  }),
  uom: yup.mixed().when('create', {
    is: true,
    then: yup.number(),
  }),
  dimensionsUnit: yup.mixed().when('create', {
    is: true,
    then: yup.string(),
  }),
  weightUnit: yup.mixed().when('create', {
    is: true,
    then: yup.string(),
  }),
});

export const shopifySchema = yup.object({
  customer: customerSchema,
  item: itemSchema,
  order: orderSchema,
});

export const inventorySchema = yup.object({
  update: yup.boolean(),
  type: yup.mixed().when('update', {
    is: true,
    then: yup.string().required(),
  }),
});

export const fulfillmentSchema = yup.object({
  update: yup.boolean(),
});

export const wooCommerceInventorySchema = yup.object({
  update: yup.boolean(),
  locations: yup.mixed().when('update', {
    is: true,
    then: yup.array().of(yup.number()).required(),
  }),
});
