import { refundStripePayment } from 'services/integrations/payments/stripe';
import {
  refundSalesOrderPayment,
  SalesOrderPayment,
} from 'services/salesOrders';

export const refundPayment = async (
  payment: SalesOrderPayment,
  amount: number
) => {
  const { salesOrderId, id: paymentId, data } = payment;

  if (data.type === 'stripe') {
    await refundStripePayment(
      salesOrderId!,
      paymentId!,
      data.paymentIntentId!,
      amount
    );

    return;
  }

  await refundSalesOrderPayment(salesOrderId!, paymentId!, amount);
};
