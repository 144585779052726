import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { CustomFieldsModal } from 'ui/components/CustomFields/CustomFieldsModal';
import {
  fetchSearch,
  getSearches,
  postPicksVoid,
  groupPicks,
  transformDisplayPick,
  DisplayPick,
} from 'services/picking';
import {
  getOrderPriorities,
  fetchOrderPriorities,
} from 'services/settings/orderPriorities';
import {
  clearModuleNavigation,
  ModuleNavigationType,
  removeModuleNavigation,
} from 'services/moduleNavigation';
import { fetchCustomers, getCustomers } from 'services/customers';
import { fetchLocations, getLocations } from 'services/locations';
import { fetchItem, Item } from 'services/items';
import { fetchUoms } from 'services/uoms';
import { Pagination } from 'services/search';
import { fetchUsers, getActiveUser, getUsers } from 'services/user';
import { fetchTags, fetchTagsAPI, Tag } from 'services/tags';
import { fetchSettingsPicking } from 'services/settings/picking/redux';
import { ObjectType } from 'services/customFields';
import { useUrlQueryObject } from 'services/url';
import { Dates } from 'helpers';

import { Routes } from '../../navigation';
import { PickingPageCmp, PickingPageProps } from './types';
import {
  PickingDetailsCard,
  PickingSearchResults,
  PickingAdvancedSearch,
  PICKING_COLUMNS,
} from './components';
import {
  advancedSearchReduxActions,
  initialPickingFormValues,
  displayNameMap,
  createDisplayValueMap,
  initialPagination,
  pickAdvancedSearchFieldsCombined,
} from './consts';
import { printPickTicketsReport } from './helpers';
import { PickingPageAction } from './components/PickingSearchResults/types';
import { fetchReportPresets, ReportId, ReportPreset } from 'services/reports';
import { ModuleNavigation } from 'app/components/AppBar/components';

const PickingPage: PickingPageCmp = (props: PickingPageProps) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activePickId,
  } = props;

  const dispatch = useDispatch();

  const users = useSelector(getUsers);
  const { items: locations } = useSelector(getLocations);
  const { items: customers } = useSelector(getCustomers);
  const { items: orderPriorities } = useSelector(getOrderPriorities);
  const { user: activeUser } = useSelector(getActiveUser);

  const [isLoading, setIsLoading] = useState(false);
  const [voidModalVisible, setVoidModalVisible] = useState(false);
  const [groupModalVisible, setGroupModalVisible] = useState(false);

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [searchItem, setSearchItem] = useState<Item | null>(null);
  const [searchTags, setSearchTags] = useState<Tag[]>([]);
  const [reportPresets, setReportPresets] = useState<ReportPreset[]>([]);

  const [showCustomFieldsModal, setShowCustomFieldsModal] = useState(false);
  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateCreated);

  const [, extendUrlQuery] = useUrlQueryObject();

  const activeUserId = activeUser ? activeUser.id! : 0;

  // watch advanced search columns and fetch selected item
  useEffect(() => {
    const itemId = _.get(
      searchResult.advancedSearch.columns,
      [pickAdvancedSearchFieldsCombined.Item],
      null
    );

    if (itemId) {
      (async () => {
        try {
          const resItem = await fetchItem(itemId as number);
          setSearchItem(resItem);
        } catch {
          // nothing
        }
      })();
    }
  }, [searchResult.advancedSearch.columns]);

  // fetch all pick report presets
  useEffect(() => {
    (async () => {
      try {
        const res = await fetchReportPresets(activeUserId, ReportId.Pick);
        setReportPresets(res);
      } catch {
        // nothing
      }
    })();
  }, [activeUserId]);

  // watch advanced search columns and fetch selected tags
  useEffect(() => {
    const tagIds = _.get(
      searchResult.advancedSearch.columns,
      [pickAdvancedSearchFieldsCombined.Tags],
      null
    ) as number[];

    if (tagIds) {
      (async () => {
        try {
          const resTags = await fetchTagsAPI({}, tagIds);

          setSearchTags(resTags.data);
        } catch {
          //ignore Error
        }
      })();
    }
  }, [searchResult.advancedSearch.columns]);

  // spatial navigation
  useEffect(() => {
    dispatch(clearModuleNavigation(ModuleNavigationType.Sales));

    return () => {
      dispatch(removeModuleNavigation());
    };
  }, [dispatch]);

  const handlePickClicked = useCallback(
    (pickId: number) => {
      extendUrlQuery({ activeId: pickId });
    },
    [extendUrlQuery]
  );

  const handleDetailsCardClosed = useCallback(() => {
    extendUrlQuery({ activeId: null });
  }, [extendUrlQuery]);

  const handlePaginationChange = (newPagination: Pagination) => {
    fetchSearchResult({ pagination: newPagination });
  };

  const handlePageAction = useCallback(
    (action: PickingPageAction, date?: Dates) => {
      switch (action) {
        case PickingPageAction.CustomFields:
          setShowCustomFieldsModal(true);
          break;
        case PickingPageAction.ChangeDate:
          setActiveDate(date!);
          break;
      }
    },
    []
  );

  const hideVoidModal = useCallback(() => {
    setVoidModalVisible(false);
    setSelectedItems([]);
  }, [setSelectedItems]);

  const onVoidClicked = useCallback(() => setVoidModalVisible(true), []);

  const handleVoidConfirm = useCallback(async () => {
    setIsLoading(true);
    try {
      await postPicksVoid(selectedItems);
      await fetchSearchResult();
      hideVoidModal();
    } catch {
      // Ignore error
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [selectedItems, hideVoidModal, fetchSearchResult]);

  const hideGroupModal = useCallback(() => {
    setGroupModalVisible(false);
    setSelectedItems([]);
  }, [setSelectedItems]);

  const onGroupClicked = useCallback(() => setGroupModalVisible(true), []);

  const handleGroupPicks = useCallback(async () => {
    setIsLoading(true);
    try {
      await groupPicks(selectedItems);
      await fetchSearchResult();
      hideGroupModal();
    } catch {
      // Ignore error
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [selectedItems, hideGroupModal, fetchSearchResult]);

  const onPrintPickTicketsClicked = useCallback(async () => {
    const defaultReportPreset = reportPresets.find(
      (preset) => preset.defaultFlag
    );
    const params = _.get(defaultReportPreset, 'parameters', {});

    setIsLoading(true);
    try {
      await printPickTicketsReport(selectedItems, params);
    } catch {
      // Ignore error
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [selectedItems, reportPresets]);

  return (
    <>
      <ModuleNavigation />
      <PageWithAdvancedSearch
        detailCardColumns={PICKING_COLUMNS(orderPriorities, activeDate)}
        initialFormValues={initialPickingFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={PickingAdvancedSearch}
        displayNameMap={displayNameMap}
        displayValueMap={createDisplayValueMap(
          orderPriorities,
          searchItem,
          customers,
          locations,
          users,
          searchTags
        )}
        showAllLabel="Show All Picks"
        pageName="Picking"
      >
        <PaperSlidingLayout shown={Boolean(activePickId)}>
          <PickingSearchResults
            picks={searchResult.items}
            activePickId={activePickId}
            handlePickClick={handlePickClicked}
            isLoadingPicks={isLoadingSearchResult}
            pagination={searchResult.pagination || initialPagination}
            activeDate={activeDate}
            onPaginationChange={handlePaginationChange}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            onPageAction={handlePageAction}
            onVoidClicked={onVoidClicked}
            onGroupClicked={onGroupClicked}
            onPrintPickTicketsClicked={onPrintPickTicketsClicked}
          />
          <PickingDetailsCard
            activePickId={activePickId}
            onClose={handleDetailsCardClosed}
            fetchSearchResults={fetchSearchResult}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      <CustomFieldsModal
        open={showCustomFieldsModal}
        setVisible={setShowCustomFieldsModal}
        module={ObjectType.Pick}
      />
      <ConfirmationModal
        open={voidModalVisible}
        title="Void Picking"
        body={`This will void all selected picks, are you sure?`}
        onCancelClicked={hideVoidModal}
        onConfirmClicked={handleVoidConfirm}
        confirmLabel="Void"
        confirmButtonRed
        isLoading={isLoading}
      />
      <ConfirmationModal
        open={groupModalVisible}
        title="Group Picking"
        body={`This will group all selected picks, are you sure?`}
        onCancelClicked={hideGroupModal}
        onConfirmClicked={handleGroupPicks}
        confirmLabel="Group"
        confirmButtonRed
        isLoading={isLoading}
      />
    </>
  );
};

PickingPage.route = Routes.PickingPage;

export default withSearchResults<DisplayPick>(PickingPage, {
  url: '/warehouse-mgmt/v1/search-picks?showStockStatus=true',
  expand: '',
  dataAdapter: transformDisplayPick,
  columns: PICKING_COLUMNS([], Dates.DateScheduled),
  getSearches,
  fetchSearch,
  initialPagination,
  quickSearchColumns: [
    'number',
    'customer.name',
    'parentLocation.name',
    'orderPriority.name',
    'status',
  ],
  rehydrationThunks: [
    fetchUsers,
    fetchCustomers,
    fetchLocations,
    fetchUoms,
    fetchTags,
    fetchOrderPriorities,
    fetchSettingsPicking,
  ],
});
