import { State } from 'app/redux';

import { initialEcommerceSettings } from '../consts';
import { ChannelType } from '../types';

export const getEcommerceChannels = (state: State) =>
  state.integrations.ecommerce;

export const getEcommerceChannelById = (id: number) => (state: State) => {
  const { items: channels } = getEcommerceChannels(state);
  return channels.find((c) => c.id === id);
};

//returns only 1 record - for single connections
export const getEcommerceSettingsByChannel =
  (channel: ChannelType) => (state: State) => {
    const { items: settings } = state.integrations.ecommerce;
    return (
      settings.find((s) => s.channel.channel === channel) ||
      initialEcommerceSettings
    );
  };

//returns array - for multiple connections
export const getAllECommerceSettingsByChannel =
  (channel: ChannelType) => (state: State) => {
    const { items: settings } = state.integrations.ecommerce;
    return (
      settings.filter((s) => s.channel.channel === channel) ||
      initialEcommerceSettings
    );
  };

export const getAllECommerceSettings = () => (state: State) => {
  const { items: settings } = state.integrations.ecommerce;
  return settings || initialEcommerceSettings;
};
