import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  WaitingToExport,
  WaitingToExportLedgers,
  getWaitingToExport,
} from 'services/accounting';
import { Modal } from 'ui/components/Modal/Modal';
import {
  LEDGER_DETAILS_COLUMNS,
  LEDGER_DETAILS_COLUMNS_WITH_LINK,
  initalLedgerItems,
} from './consts';
import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import CustomRow from './CustomRow';
import { colorPalette, themeRestyle } from 'ui/theme';
import {
  LedgerForWaitingToExportModal,
  fetchWaitingToExportLedgerDetails,
  markAsPostedById,
} from 'services/ledgers';
import { logErrorCtx } from 'app/logging';
import { getLedgerTypeForAPICall } from './helpers';
import { Box, Typography } from '@mui/material';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { useCardStyle } from '../../../../styled';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { PermissionType } from 'services/permissions';

interface WaitingToExportDetailsModalProps {
  open: boolean;
  onClose(): void;
  ledgerType: WaitingToExportLedgers | null;
  integrationName: string;
  setToExportData: React.Dispatch<React.SetStateAction<WaitingToExport>>;
}

const WaitingToExportDetailsModal = (
  props: WaitingToExportDetailsModalProps
) => {
  const { open, onClose, ledgerType, integrationName, setToExportData } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const classes = useCardStyle();

  const [ledgerItems, setLedgerItems] =
    useState<LedgerForWaitingToExportModal[]>(initalLedgerItems);

  const handleSelectClick = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );
  const fetchLedgers = async (ledgerType: WaitingToExportLedgers) => {
    setIsLoading(true);

    const platformLedgerType = getLedgerTypeForAPICall(ledgerType);

    try {
      const ledgerItemsResponse = await fetchWaitingToExportLedgerDetails(
        platformLedgerType
      );

      setLedgerItems(ledgerItemsResponse);
    } catch (error) {
      logErrorCtx('Error in fetchLedgers', {
        error: error as Error,
        component: 'WaitingToExportDetailsMoal',
        title: 'Error in fetchLedgers',
        description: 'Error in fetchLedgers',
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (ledgerType) {
      fetchLedgers(ledgerType);
    }
  }, [ledgerType]);

  const tableColumns = useMemo(() => {
    if (
      ledgerType === WaitingToExportLedgers.InventoryAdjustments ||
      ledgerType === WaitingToExportLedgers.JournalEntries
    ) {
      return LEDGER_DETAILS_COLUMNS;
    }

    return LEDGER_DETAILS_COLUMNS_WITH_LINK;
  }, [ledgerType]);
  const handleMarkAsPosted = async () => {
    setIsLoading(true);
    try {
      await markAsPostedById(selectedItems);
      const data = await getWaitingToExport();
      setToExportData(data);
      setSelectedItems([]);
    } catch (err) {
      const error = err as Error;
      logErrorCtx('Not Marked as Posted or Exported', {
        error,
        stackTrace: error.stack,
        title: 'Unable to mark as posted or exported',
        description: 'Account settings not marked as posted',
        component: 'WaitingToExportModal',
      });
    }
    setIsLoading(false);
    setShowModal(false);
    onClose();
  };
  const handleClose = () => {
    setShowModal(false);
    setSelectedItems([]);
    onClose();
  };

  const RenderCustomFooter = useCallback(
    (selectedItems) => {
      return (
        <Box className={classes.rowContainer}>
          <Box className={classes.titleContainer}>
            <Typography>
              {`Don't want to export to ${integrationName}?`}
            </Typography>
          </Box>
          <Box>
            <FBOButton
              onClick={() => setShowModal(true)}
              data-qa="accounting-mark-as-posted"
              variant="tertiary"
              color="positive"
              size="small"
              disabled={selectedItems.selectedItemsData.selected === 0}
              permissions={[PermissionType.AccountingEdit]}
            >
              Mark as posted
            </FBOButton>
            <FBOButton
              onClick={handleClose}
              data-qa="accounting-mark-as-posted"
              variant="primary"
              color="positive"
              size="medium"
            >
              Done
            </FBOButton>
          </Box>
        </Box>
      );
    },
    [selectedItems, onClose, integrationName]
  );
  return (
    <Modal
      title={ledgerType ?? ''}
      open={open}
      onClose={onClose}
      onCancelClicked={handleClose}
      withoutFooter
      fullWidth
    >
      <ItemsTable
        columns={tableColumns}
        data={ledgerItems}
        RenderCustomRow={CustomRow}
        selectableItems={true}
        onSelectedChange={handleSelectClick}
        selectedItems={selectedItems}
        tableBordered={false}
        dataQa="ledger-details-table"
        emptyTableText="NO LEDGER ITEMS"
        RenderCustomFooter={RenderCustomFooter}
        sx={{
          borderRadius: '10px',
          borderLeft: `1px solid ${colorPalette.redesign.background3}`,
          borderRight: `1px solid ${colorPalette.redesign.background3}`,
          borderBottom: `1px solid ${colorPalette.redesign.background3}`,
          marginLeft: themeRestyle.spacing(2),
          marginRight: themeRestyle.spacing(2),
        }}
        isLoading={isLoading}
        isLoadingData={isLoading}
      />
      <ConfirmationModal
        body={
          <div
            dangerouslySetInnerHTML={{
              __html: `This will remove the selected transactions from the export queue. This cannot be undone. Any associated journal entries will also need to be marked as posted. If you are planning to manually create these transactions in ${integrationName}, please ensure that you manually create all of the related journal entries as well. <a href="https://help.fishbowlonline.com/s/article/Drive-QuickBooks-Online#Mark_As_Posted" target="_blank">Want to Learn More?</a>`,
            }}
          />
        }
        maxWidth="sm"
        open={showModal}
        title="Confirm Mark As Posted"
        onCancelClicked={() => setShowModal(false)}
        onConfirmClicked={handleMarkAsPosted}
        isLoading={isLoading}
        confirmLabel="Continue"
      />
    </Modal>
  );
};

export default WaitingToExportDetailsModal;
