import { paymentIntegrationsApi } from 'services/api/config';
import { GeneratePaymentInfo } from 'services/salesOrders';

export const connectStripe = async (): Promise<string> => {
  const { data } = await paymentIntegrationsApi.post(
    '/api/v1/stripe/integrate'
  );

  return data.url;
};

export const createStripePayment = async (
  amount: number,
  stripeAccountId: string,
  salesOrderId: number
): Promise<string> => {
  const { data } = await paymentIntegrationsApi.post(
    `/api/v1/stripe/create-payment`,
    {
      stripeAccountId,
      amount,
      salesOrderId,
    }
  );

  return data.clientSecret;
};

export const generatePaymentLink = async (
  paymentInfo: GeneratePaymentInfo
): Promise<void> => {
  await paymentIntegrationsApi.post(
    `/api/v1/stripe/generate-payment-link`,
    paymentInfo
  );
};

export const refundStripePayment = async (
  salesOrderId: number,
  paymentId: number,
  paymentIntentId: string,
  amount: number
): Promise<void> => {
  await paymentIntegrationsApi.post(`/api/v1/stripe/refund-payment`, {
    salesOrderId,
    paymentId,
    paymentIntentId,
    amount: amount * 100,
  });
};
