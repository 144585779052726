import React, {
  memo,
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react';
import {
  Box,
  Button,
  IconButton,
  Popover,
  Typography,
  Tooltip,
  Tabs,
  Tab,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { ObjectSchema } from 'yup';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useDispatch } from 'react-redux';
import { logErrorCtx } from 'app/logging';
import { TitleBar } from 'ui/components/TitleBar';
import {
  customerSchema,
  fulfillmentSchema,
  inventorySchema,
  itemSchema,
  orderSchema,
  shopifySchema,
  putEcommerceShopName,
  headerTitleAnchorOrigin,
  headerTitleTransformOrigin,
  posSchema,
  initialEcommerceSettings,
  ChannelType,
  EcommerceSettings,
} from 'services/integrations/ecommerce';
import { Fields, validateYup } from 'services/forms/validation';
import { fetchEcommerceChannels } from 'services/integrations/ecommerce/redux';
import TextField from 'ui/components/TextField/TextField/TextField';
import { showNotification } from 'services/api';
import { replaceValueInCollection } from 'helpers';

import {
  EcommerceContext,
  GetDivIdByChannel,
  imageLogoUrl,
} from '../../../../EcommerceProvider';
import { EcommerceCardProps, EcommerceSettingsModalType } from './types';
import { useEcommerceCardStyle } from '../../../../styled';
import { HistoryCard } from '../../..';
import { EcommerceCardModal } from './components';

/** Get right schema with matching object */
const getSettingsSchema = (
  settings: EcommerceSettings,
  identifier:
    | 'order'
    | 'customer'
    | 'item'
    | 'inventory'
    | 'fulfillment'
    | 'pos'
    | 'fba'
): [ObjectSchema<any>, Fields] => {
  switch (identifier) {
    case 'order':
      return [orderSchema, settings.order];
    case 'customer':
      return [customerSchema, settings.customer];
    case 'item':
      return [itemSchema, settings.item];
    case 'inventory':
      return [inventorySchema, settings.inventory];
    case 'fulfillment':
      return [fulfillmentSchema, settings.fulfillment];
    case 'pos':
      return [posSchema, settings.pos];
    case 'fba':
      return [posSchema, settings.pos];
    default:
      return [shopifySchema, settings];
  }
};

const EcommerceCard: React.FC<EcommerceCardProps> = (props) => {
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [showItemModal, setShowItemModal] = useState(false);
  const [showInventoryModal, setShowInventoryModal] = useState(false);
  const [showFulfillmentModal, setShowFulfillmentModal] = useState(false);
  const [showPOSModal, setShowPOSModal] = useState(false);
  const [showFBAModal, setShowFBAModal] = useState(false);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [titleErrors, setTitleErrors] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const [settings, setSettings, saveSettings, , setErrors, , isLoading] =
    useContext(EcommerceContext);

  const classes = useEcommerceCardStyle();
  const dispatch = useDispatch();

  const { index } = props;
  const [setting, setSetting] = useState(initialEcommerceSettings);
  const [error, setError] = useState<any>({});

  const isPopoverVisible = useMemo(() => Boolean(anchorEl), [anchorEl]);

  useEffect(() => {
    setActiveTab(0);
    if (settings.length > 0) {
      setSetting(settings.find((e, i) => i === index)!);
    }
  }, [settings, index]);

  useEffect(() => {
    setErrors((old) => replaceValueInCollection(old, error, index)!);
  }, [setErrors, error, index]);

  const openModal = useCallback(
    (type: EcommerceSettingsModalType) => () => {
      switch (type) {
        case EcommerceSettingsModalType.OrderSettings:
          setShowOrderModal(true);
          break;
        case EcommerceSettingsModalType.CustomerSettings:
          setShowCustomerModal(true);
          break;
        case EcommerceSettingsModalType.ItemSettings:
          setShowItemModal(true);
          break;
        case EcommerceSettingsModalType.InventorySettings:
          setShowInventoryModal(true);
          break;
        case EcommerceSettingsModalType.FulfillmentSettings:
          setShowFulfillmentModal(true);
          break;
        case EcommerceSettingsModalType.POSSettings:
          setShowPOSModal(true);
          break;
        case EcommerceSettingsModalType.FBASettings:
          setShowFBAModal(true);
          break;
      }
    },
    []
  );

  const handleCloseModal = useCallback(() => {
    setShowOrderModal(false);
    setShowCustomerModal(false);
    setShowItemModal(false);
    setShowInventoryModal(false);
    setShowFulfillmentModal(false);
    setShowPOSModal(false);
    setShowFBAModal(false);
    setError({});
  }, []);

  const handleApplyClicked = useCallback(
    (
        identifier:
          | 'order'
          | 'customer'
          | 'item'
          | 'inventory'
          | 'fulfillment'
          | 'pos'
          | 'fba'
      ) =>
      async () => {
        let valid = true;
        if (
          setting.channel.channel === ChannelType.Shopify &&
          identifier === 'inventory' &&
          setting.inventory.update
        ) {
          const mappingsWithValues = Object.values(
            setting.inventory.mapping
          ).filter((m) => m.length);

          valid = !mappingsWithValues.length ? false : true;
        }
        if (
          setting.channel.channel === ChannelType.ShipStation &&
          identifier === 'order' &&
          setting.orderExport.enabled
        ) {
          const mappingsWithValues = Object.values(
            setting.orderExport.locations
          ).filter((m) => m.length);

          valid = !mappingsWithValues.length ? false : true;

          if (!setting.orderExport.status) {
            return;
          }
        }

        if (!valid) {
          setError({
            mappings: 'Please select location',
          });
          showNotification('Please select at least one location', {
            variant: 'error',
          });

          return;
        }

        const [validateSchema, validateSettings] = getSettingsSchema(
          setting,
          identifier
        );

        const isValid = validateYup(validateSettings, validateSchema, setError);

        if (!isValid) {
          return;
        }

        setIsLoadingContent(true);

        try {
          await saveSettings(setting.channel.id!);
        } catch (e) {
          const error = e as Error;
          logErrorCtx('Savings Not Saved', {
            error,
            stackTrace: error.stack,
            title: 'E-Commerce Settings Not Saved',
            description: 'Settings not saved for desired channel',
            component: 'EcommerceCard',
          });
          setIsLoadingContent(false);
          return;
        }
        setIsLoadingContent(false);
        handleCloseModal();
      },
    [saveSettings, setting, setError, handleCloseModal]
  );

  const handleTextfieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setTitle(event.target.value);
  };

  const handleClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault();
      setTitle(setting.channel.name);
      setAnchorEl(event.currentTarget);
    },
    [setting.channel.name]
  );

  const handlePopClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onShopNameSave = useCallback(async () => {
    if (!title) {
      setTitleErrors(true);
      return;
    }
    setAnchorEl(null);

    try {
      await putEcommerceShopName(setting.channel.id!, title);
      // Lint skip to be removed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      dispatch(fetchEcommerceChannels());
      let newSetting = { ...setting };
      newSetting = {
        ...newSetting,
        channel: { ...newSetting.channel, name: title },
      };
      setSettings((old) => replaceValueInCollection(old, newSetting, index)!);
    } catch (e) {
      const error = e as Error;

      showNotification(`Setting new name failed - ${error.message}`, {
        variant: 'error',
      });
    }
  }, [setting, setSettings, title, dispatch, index]);

  const handleActiveTabChange = useCallback(
    async (event: React.ChangeEvent<{}>, newValue: number) => {
      setActiveTab(newValue);
    },
    []
  );

  return (
    <>
      <Box className={classes.container}>
        <div
          id={GetDivIdByChannel(setting.channel.channel!)}
          className={classes.paper}
        >
          <Tabs
            value={activeTab}
            onChange={handleActiveTabChange}
            indicatorColor="primary"
          >
            <Tab label="Settings" />
            <Tab label="History" />
          </Tabs>

          {activeTab === 0 ? (
            <>
              <TitleBar
                title={setting.channel.name}
                statusCustom={
                  setting.channel.channel !== ChannelType.Shopify
                    ? 'Beta'
                    : undefined
                }
                noBorderRadius
                imageUrl={imageLogoUrl(setting.channel.channel!)}
              >
                <Tooltip title="Edit shop name" placement="top-start">
                  <IconButton
                    onClick={handleClicked}
                    style={{ borderLeft: 'none' }}
                    size="large"
                  >
                    <CreateOutlinedIcon style={{ color: 'white' }} />
                  </IconButton>
                </Tooltip>
                <Box flexGrow={1} />
                <Popover
                  anchorOrigin={headerTitleAnchorOrigin}
                  transformOrigin={headerTitleTransformOrigin}
                  anchorEl={anchorEl}
                  open={isPopoverVisible}
                  onClose={handlePopClose}
                >
                  <Box p={1} display="flex" alignItems="center">
                    <TextField
                      name="name"
                      value={setting.channel.name}
                      onChange={handleTextfieldChange}
                      error={titleErrors}
                    />
                    <Box ml={1}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={onShopNameSave}
                      >
                        Change
                      </Button>
                    </Box>
                  </Box>
                </Popover>
              </TitleBar>

              <Box className={classes.cardRow}>
                <Typography variant="body1" color="textPrimary">
                  Status
                </Typography>

                <Typography variant="body1" color="primary">
                  {setting.id ? 'Connected' : 'Disconnected'}
                </Typography>
              </Box>

              {setting.id && (
                <>
                  <Box
                    className={`${classes.cardRow} ${classes.cardRowClickable}`}
                    onClick={openModal(
                      EcommerceSettingsModalType.OrderSettings
                    )}
                  >
                    <Typography variant="body1" color="textPrimary">
                      Order Settings
                    </Typography>

                    <ArrowRightIcon />
                  </Box>

                  <Box
                    className={`${classes.cardRow} ${classes.cardRowClickable}`}
                    onClick={openModal(
                      EcommerceSettingsModalType.CustomerSettings
                    )}
                  >
                    <Typography variant="body1" color="textPrimary">
                      Customer Settings
                    </Typography>

                    <ArrowRightIcon />
                  </Box>
                  {setting.channel.channel !== ChannelType.ShipStation ? (
                    <>
                      <Box
                        className={`${classes.cardRow} ${classes.cardRowClickable}`}
                        onClick={openModal(
                          EcommerceSettingsModalType.ItemSettings
                        )}
                      >
                        <Typography variant="body1" color="textPrimary">
                          Item Settings
                        </Typography>

                        <ArrowRightIcon />
                      </Box>

                      <Box
                        className={`${classes.cardRow} ${classes.cardRowClickable}`}
                        onClick={openModal(
                          EcommerceSettingsModalType.InventorySettings
                        )}
                      >
                        <Typography variant="body1" color="textPrimary">
                          Inventory Settings
                        </Typography>

                        <ArrowRightIcon />
                      </Box>
                    </>
                  ) : null}
                  <Box
                    className={`${classes.cardRow} ${classes.cardRowClickable}`}
                    onClick={openModal(
                      EcommerceSettingsModalType.FulfillmentSettings
                    )}
                  >
                    <Typography variant="body1" color="textPrimary">
                      Fulfillment Settings
                    </Typography>

                    <ArrowRightIcon />
                  </Box>
                  {setting.channel.channel === ChannelType.Shopify ? (
                    <Box
                      className={`${classes.cardRow} ${classes.cardRowClickable}`}
                      onClick={openModal(
                        EcommerceSettingsModalType.POSSettings
                      )}
                    >
                      <Typography variant="body1" color="textPrimary">
                        POS Settings
                      </Typography>

                      <ArrowRightIcon />
                    </Box>
                  ) : null}
                  {setting.channel.channel === ChannelType.Amazon ? (
                    <Box
                      className={`${classes.cardRow} ${classes.cardRowClickable}`}
                      onClick={openModal(
                        EcommerceSettingsModalType.FBASettings
                      )}
                    >
                      <Typography variant="body1" color="textPrimary">
                        FBA Settings
                      </Typography>

                      <ArrowRightIcon />
                    </Box>
                  ) : null}
                </>
              )}
            </>
          ) : (
            <HistoryCard
              settingsId={setting.id!}
              channelName={setting.channel.name}
              imageUrl={imageLogoUrl(setting.channel.channel!)}
            />
          )}
        </div>
      </Box>

      <EcommerceCardModal
        open={showOrderModal}
        onClose={handleCloseModal}
        onSaveClicked={handleApplyClicked('order')}
        isLoadingContent={isLoadingContent || isLoading}
        index={index}
        type={EcommerceSettingsModalType.OrderSettings}
        channelId={setting.channel.id!}
        channelType={setting.channel.channel!}
      />

      <EcommerceCardModal
        open={showCustomerModal}
        onClose={handleCloseModal}
        onSaveClicked={handleApplyClicked('customer')}
        isLoadingContent={isLoadingContent || isLoading}
        index={index}
        type={EcommerceSettingsModalType.CustomerSettings}
        channelId={setting.channel.id!}
        channelType={setting.channel.channel!}
      />

      <EcommerceCardModal
        open={showItemModal}
        onClose={handleCloseModal}
        onSaveClicked={handleApplyClicked('item')}
        isLoadingContent={isLoadingContent || isLoading}
        index={index}
        type={EcommerceSettingsModalType.ItemSettings}
        channelId={setting.channel.id!}
        channelType={setting.channel.channel!}
      />

      <EcommerceCardModal
        open={showInventoryModal}
        onClose={handleCloseModal}
        onSaveClicked={handleApplyClicked('inventory')}
        isLoadingContent={isLoadingContent || isLoading}
        index={index}
        type={EcommerceSettingsModalType.InventorySettings}
        channelId={setting.channel.id!}
        channelType={setting.channel.channel!}
      />

      <EcommerceCardModal
        open={showFulfillmentModal}
        onClose={handleCloseModal}
        onSaveClicked={handleApplyClicked('fulfillment')}
        isLoadingContent={isLoadingContent || isLoading}
        index={index}
        type={EcommerceSettingsModalType.FulfillmentSettings}
        channelId={setting.channel.id!}
        channelType={setting.channel.channel!}
      />

      <EcommerceCardModal
        open={showPOSModal}
        onClose={handleCloseModal}
        onSaveClicked={handleApplyClicked('pos')}
        isLoadingContent={isLoadingContent || isLoading}
        index={index}
        type={EcommerceSettingsModalType.POSSettings}
        channelId={setting.channel.id!}
        channelType={setting.channel.channel!}
      />

      <EcommerceCardModal
        open={showFBAModal}
        onClose={handleCloseModal}
        onSaveClicked={handleApplyClicked('fba')}
        isLoadingContent={isLoadingContent || isLoading}
        index={index}
        type={EcommerceSettingsModalType.FBASettings}
        channelId={setting.channel.id!}
        channelType={setting.channel.channel!}
      />
    </>
  );
};

export default memo(EcommerceCard);
