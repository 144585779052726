import React from 'react';
import { Typography } from '@mui/material';
import moment from 'moment';

import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';

export const cardHistoryColumns: (format: string) => Column[] = (format) => [
  {
    type: ColumnTypes.custom,
    field: 'dateCreated',
    title: 'Date',
    render: (row) => {
      return <Typography>{moment(row.dateCrated).format(format)}</Typography>;
    },
  },
  {
    type: ColumnTypes.string,
    field: 'type',
    title: 'Status',
  },
];
