import React from 'react';
import { TextField as MaterialTextField } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { useSelector } from 'react-redux';

import { activeUserHasPermission } from 'services/user/redux';

import { TextFieldProps } from './types';
import { useTextFieldNoBorder } from './styled';

const TextField = (
  {
    value,
    onChange,
    name,
    readOnly,
    disableDebounce = false,
    debounceDelay = 200,
    disabled,
    additionalInputProps,
    dataQa,
    allowLastPassIcon = false,
    disableAutoFocus = false,
    permissions = [],
    fullWidth = true,
    variant = 'outlined',
    ...otherProps
  }: TextFieldProps,
  ref: any
) => {
  const { notchedOutline } = useTextFieldNoBorder();
  const canEdit = useSelector(activeUserHasPermission(permissions));

  const isDisabled = disabled || !canEdit;

  React.useEffect(() => {
    setLastEvent({
      target: { value: value === null ? '' : value },
    } as any);
  }, [value]);

  const [lastEvent, setLastEvent] = React.useState<
    React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  >({
    target: { value: value === null ? '' : value },
  } as any);

  const denouncedOnChange = useDebouncedCallback(() => {
    if (onChange) {
      onChange(lastEvent);
    }
  }, debounceDelay);

  const handleOnChanged = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    e.persist();
    setLastEvent(e);
    denouncedOnChange();
  };

  const handleOnFocus = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.target.focus();
  };

  return (
    <MaterialTextField
      fullWidth={fullWidth}
      InputProps={{
        classes: readOnly ? { notchedOutline } : {},
        readOnly: readOnly,
        ...additionalInputProps,
      }}
      inputProps={{
        'data-qa': dataQa,
        'data-lpignore': `${!allowLastPassIcon}`,
      }}
      InputLabelProps={{
        shrink: true,
      }}
      value={
        disableDebounce ? (value === null ? '' : value) : lastEvent.target.value
      }
      name={name}
      disabled={isDisabled}
      onChange={disableDebounce ? onChange : handleOnChanged}
      onFocus={disableAutoFocus ? undefined : handleOnFocus}
      variant={variant}
      size="small"
      ref={ref}
      // NOTE: prevent scrolling
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      {...otherProps}
    />
  );
};

export default React.memo(React.forwardRef(TextField));
