import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Divider, Switch, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { UomAutocomplete } from 'ui/components/Autocomplete/UomAutocomplete';
import { fetchUoms, getUoms, Uom } from 'services/uoms';

import {
  DIMENSIONS_UNITS,
  DimensionUnit,
  NonPhysical_ItemType,
  WeightUnit,
  WEIGHT_UNITS,
} from 'services/items';
import {
  AutocompleteMappings,
  ChannelType,
} from 'services/integrations/ecommerce';

import _ from 'lodash';
import { SettingsFormProps } from '../../types';
import { useEcommerceCardStyle } from '../../../../styled';
import useEcommerceSettings from '../../../../useEcommerceSettings';
import { Class } from 'services/classes';
import { ClassAutocomplete } from 'ui/components/Autocomplete/ClassesAutocomplete';

const ItemSettingsForm: React.FC<SettingsFormProps> = (props) => {
  const { index } = props;

  const [
    setting,
    setEcommerceSettings,
    errors = [],
    channel,
    fishbowl,
    ,
    channelName,
  ] = useEcommerceSettings(index);

  const [itemSettings, setItemSettings] = useState(setting);
  const { items: uoms } = useSelector(getUoms);

  const dispatch = useDispatch();
  const classes = useEcommerceCardStyle();

  const shopifyOptions: AutocompleteMappings[] = useMemo(() => {
    return Object.entries(channel)
      .filter(([, value]) => typeof value === 'string')
      .map(([key, value]) => ({ id: key, value: value as string }));
  }, [channel]);

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchUoms());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (itemSettings.id) {
      setEcommerceSettings(itemSettings);
    }
  }, [itemSettings, setEcommerceSettings, index]);

  const selectedUom = useMemo(
    () => uoms.find((u) => u.id === itemSettings.item.uom) || null,
    [uoms, itemSettings]
  );

  const selectedDimensionUnit = useMemo(
    () =>
      DIMENSIONS_UNITS.find((u) => u.name === itemSettings.item.dimensionsUnit),
    [itemSettings]
  );

  const selectedWeightUnit = useMemo(
    () => WEIGHT_UNITS.find((u) => u.name === itemSettings.item.weightUnit),
    [itemSettings]
  );

  useEffect(() => {
    const newMappings: any = {};
    if (!_.isEmpty(fishbowl)) {
      Object.entries(fishbowl).forEach(([id]: any) => {
        if (!itemSettings.item.mapping[id]) {
          newMappings[id] = null;
          return;
        }
        newMappings[id] = itemSettings.item.mapping[id];
      });

      setItemSettings((old) => ({
        ...old,
        item: { ...old.item, mapping: newMappings },
      }));
    }
    // eslint-disable-next-line
  }, [fishbowl]);

  const handleAutoCompleteChange = useCallback(
    (name: 'uom' | 'dimensionsUnit' | 'weightUnit' | 'type' | 'fbItemType') =>
      (v: any) => {
        const arr = ['dimensionsUnit', 'weightUnit'];
        if (arr.includes(name)) {
          setItemSettings((old) => ({
            ...old,
            item: { ...old.item, [name]: v ? v.name : null },
          }));
          return;
        }

        if (name === 'uom') {
          setItemSettings((old) => ({
            ...old,
            item: { ...old.item, [name]: v ? v.id : null },
          }));
          return;
        }

        setItemSettings((old) => ({
          ...old,
          item: { ...old.item, [name]: v ? v : null },
        }));
      },
    [setItemSettings]
  );

  const handleAutoCompleteMappingsChange = useCallback(
    (value: AutocompleteMappings | null, mappingKey: string) => {
      setItemSettings((old) => ({
        ...old,
        item: {
          ...old.item,
          mapping: {
            ...old.item.mapping,
            [mappingKey]: value ? value.id : null,
          },
        },
      }));
    },
    [setItemSettings]
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setItemSettings((old) => ({
        ...old,
        item: {
          ...old.item,
          [event.target.name]: checked,
        },
      }));
    },
    [setItemSettings]
  );
  const handleShopifyCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setItemSettings((old) => ({
        ...old,
        item: {
          ...old.item,
          [event.target.name]: checked,
        },
      }));
    },
    [setItemSettings]
  );
  const handleClassChange = (accountingClass: Class | null) => {
    setItemSettings((old) => ({
      ...old,
      item: {
        ...old.item,
        class: accountingClass ? accountingClass.id : null,
      },
    }));
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      height="100%"
      overflow="auto"
    >
      <Box className={classes.modalRowExpandable}>
        <Box className={classes.modalRow}>
          <Typography variant="body1" color="textPrimary">
            Create New Items
          </Typography>

          <Switch
            checked={itemSettings.item.create || false}
            onChange={handleCheckboxChange}
            name="create"
            color="primary"
          />
        </Box>
        <Box className={classes.modalRow}>
          <Typography variant="body1" color="textPrimary">
            Create/Link items only when on orders imported from Shopify
          </Typography>

          <Switch
            checked={itemSettings.item.createLinkItems || false}
            onChange={handleShopifyCheckboxChange}
            name="createLinkItems"
            color="primary"
          />
        </Box>
      </Box>

      <Divider />

      {(itemSettings.item.create || itemSettings.item.createLinkItems) && (
        <>
          <Box className={classes.modalRowExpandable}>
            {Object.entries(itemSettings.item.mapping).map(
              ([id, value]: any, index) => {
                if (fishbowl[id] && fishbowl[id].toLowerCase() === 'sku')
                  fishbowl[id] = fishbowl[id].toUpperCase();

                return (
                  <Box className={classes.modalRowInner} key={index}>
                    <Box width="45%">
                      <Typography variant="body1" color="textPrimary">
                        {fishbowl[id]}
                      </Typography>
                    </Box>
                    <Box width="45%">
                      <Autocomplete
                        options={shopifyOptions}
                        value={
                          value ? { id: value, value: channel[value] } : null
                        }
                        getOptionLabel={(o) => o.value}
                        isOptionEqualToValue={(o, val) => o.id === val.id}
                        onChange={(
                          e: React.ChangeEvent<{}>,
                          v: AutocompleteMappings | null
                        ) => handleAutoCompleteMappingsChange(v, id)}
                        placeholder={channelName + ' Field'}
                        error={!!errors[`mapping.${id}`]}
                      />
                    </Box>
                  </Box>
                );
              }
            )}
          </Box>
          {_.includes(
            [ChannelType.Shopify, ChannelType.BigCommerce],
            setting.channel.channel
          ) ? (
            <>
              <Box py={2}>
                <Divider />
              </Box>
              <Box className={classes.modalRowInner}>
                <Box width="45%">
                  <Typography variant="body1" color="textPrimary">
                    Set item for all products
                  </Typography>
                </Box>
                <Box width="45%">
                  <Autocomplete
                    placeholder="Inventory"
                    onChange={(e: React.ChangeEvent<{}>, v: string | null) =>
                      handleAutoCompleteChange('fbItemType')(v)
                    }
                    options={Object.values(NonPhysical_ItemType)}
                    getOptionLabel={(u) => u}
                    value={itemSettings.item.fbItemType}
                  />
                </Box>
              </Box>
              <Box className={classes.modalRowInner}>
                <Box width="45%">
                  <Typography variant="body1" color="textPrimary">
                    {setting.channel.channel === ChannelType.Shopify
                      ? 'When "This is a physical product" is not selected in Shopify, set Item type to:'
                      : 'When "Downloadable product" is set in BigCommerce, set Item type to:'}
                  </Typography>
                </Box>
                <Box width="45%">
                  <Autocomplete
                    placeholder="Item Type"
                    onChange={(e: React.ChangeEvent<{}>, v: string | null) =>
                      handleAutoCompleteChange('type')(v)
                    }
                    options={Object.values(NonPhysical_ItemType)}
                    getOptionLabel={(u) => u}
                    value={itemSettings.item.type}
                  />
                </Box>
              </Box>
            </>
          ) : null}

          <Box py={2}>
            <Divider />
          </Box>

          <Box className={classes.modalRowExpandable}>
            <Box className={classes.modalRowInner}>
              <Box width="45%">
                <Typography variant="body1" color="textPrimary">
                  Accounting Class
                </Typography>
              </Box>
              <Box width="45%">
                <ClassAutocomplete
                  value={itemSettings.item.class}
                  onChange={handleClassChange}
                  placeholder="Select a class"
                />
              </Box>
            </Box>
            <Box className={classes.modalRowInner}>
              <Box width="45%">
                <Typography variant="body1" color="textPrimary">
                  Unit of Measure
                </Typography>
              </Box>
              <Box width="45%">
                <UomAutocomplete
                  placeholder="Select UOM"
                  onChange={(v: Uom | null) =>
                    handleAutoCompleteChange('uom')(v)
                  }
                  value={selectedUom}
                  error={!!errors['uom']}
                />
              </Box>
            </Box>
            <Box className={classes.modalRowInner}>
              <Box width="45%">
                <Typography variant="body1" color="textPrimary">
                  Dimensions UOM
                </Typography>
              </Box>
              <Box width="45%">
                <Autocomplete
                  placeholder="Dimension"
                  onChange={(
                    e: React.ChangeEvent<{}>,
                    v: DimensionUnit | null
                  ) => handleAutoCompleteChange('dimensionsUnit')(v)}
                  options={DIMENSIONS_UNITS}
                  getOptionLabel={(u) => u.name}
                  value={selectedDimensionUnit}
                  error={!!errors['dimensionsUnit']}
                />
              </Box>
            </Box>
            <Box className={classes.modalRowInner}>
              <Box width="45%">
                <Typography variant="body1" color="textPrimary">
                  Weight UOM
                </Typography>
              </Box>
              <Box width="45%">
                <Autocomplete
                  options={WEIGHT_UNITS}
                  value={selectedWeightUnit}
                  getOptionLabel={(u) => u.name}
                  onChange={(e: React.ChangeEvent<{}>, v: WeightUnit | null) =>
                    handleAutoCompleteChange('weightUnit')(v)
                  }
                  placeholder="Weight UOM"
                  error={!!errors['weightUnit']}
                />
              </Box>
            </Box>
            <Box p={1} />
            {_.includes(
              [
                ChannelType.Shopify,
                ChannelType.BigCommerce,
                ChannelType.WooCommerce,
                ChannelType.Salesforce,
              ],
              setting.channel.channel
            ) ? (
              <>
                <Divider />
                <Box className={classes.modalRow}>
                  <Typography variant="body1" color="textPrimary">
                    {`Update Items in Fishbowl when changed in ${itemSettings.channel.name}`}
                  </Typography>

                  <Switch
                    checked={itemSettings.item.update}
                    onChange={handleCheckboxChange}
                    name="update"
                    color="primary"
                  />
                </Box>
              </>
            ) : null}
          </Box>

          <Divider />
        </>
      )}
    </Box>
  );
};

export default memo(ItemSettingsForm);
