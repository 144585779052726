import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { colorPalette, SupportColors } from 'ui/theme';

import { APP_BAR_HEIGHT } from './consts';
import { AppBarComponentProps } from './types';

export const useBarStyle = makeStyles<Theme, AppBarComponentProps>(
  (theme: Theme) => {
    return {
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        maxHeight: APP_BAR_HEIGHT,
        background: theme.palette.appBar.primary,
      },
      middleBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flex: '1',
      },
      companyName: {
        display: 'flex',
        justifyContent: 'flex-end',
        flexGrow: 0,
      },
      demoHighlight: {
        position: 'absolute',
        width: 'calc(100% - 116px)',
        bottom: -4,
        height: 4,
        right: 116,
        background: `linear-gradient(90deg, transparent 20%, ${colorPalette.fishbowlCorporateBlue} 55%)`,
      },
      demoBanner: {
        position: 'absolute',
        right: 0,
        width: 120,
        height: 120,
        top: '100%',
        overflow: 'hidden',
        zIndex: 2000,
        backgroundColor: 'rgba(0,41,240,0.90)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        color: colorPalette.white,
        paddingBottom: theme.spacing(1),
        clipPath: 'polygon(0 0, 100% 100%, 100% 0)',
      },
      demoText: {
        transform: 'rotate(45deg) translate3d(2px,-80%,0)',
        transformOrigin: '50% 50%',
        display: 'block',
        textAlign: 'center',
        fontWeight: 700,
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      divider: {
        height: APP_BAR_HEIGHT,
        backgroundColor: 'rgba(255, 255, 255, 0.15)',
      },
      iconColor: {
        fill: SupportColors.iconOnDark,
      },
      logoWrapper: {
        overflow: 'hidden',
        width: ({ drawerExpanded }) => (drawerExpanded ? 200 : 49),
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1.5),
      },
      logOutRemoteButton: {
        backgroundColor: theme.palette.error.main,
      },
      sandboxSpinner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        background: 'rgba(1, 0, 5, 0.3)',
      },
      outageBannerWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    };
  }
);
