import React, { memo } from 'react';
import { Box } from '@mui/material';

import { initialPagination, ItemsTable } from 'ui/components/Table/ItemsTable';
import { useGetIntlDateFormatString } from 'helpers';
import { Paper } from 'ui/components/Paper/Paper';
import { TitleBar } from 'ui/components/TitleBar';

import { useCardStyle } from '../../styled';
import { cardHistoryColumns } from './consts';
import { CardHistoryProps } from './types';

const CardHistory: React.FC<CardHistoryProps> = (props) => {
  const { history } = props;
  const classes = useCardStyle();
  const intlDateFormat = useGetIntlDateFormatString();

  return (
    <Box className={classes.container}>
      <Paper id="historyId" className={classes.paper}>
        <TitleBar dangerouslySetInnerHTML={true} title="History" />
        <ItemsTable
          columns={cardHistoryColumns(intlDateFormat)}
          data={history}
          selectableItems={false}
          emptyTableText="No Data"
          pagination={initialPagination}
        />
      </Paper>
    </Box>
  );
};

export default memo(CardHistory);
