import React from 'react';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { BulkWizardData } from './types';

export const getSteps = (activeStep: number = 0, classes: any = {}) => [
  {
    label: 'Locations',
    icon:
      activeStep > 0 ? (
        <RadioButtonCheckedIcon className={classes.activeIcon} />
      ) : (
        <RadioButtonUncheckedOutlinedIcon />
      ),
  },
  {
    label: 'Items',
    icon:
      activeStep > 0 ? (
        <RadioButtonCheckedIcon className={classes.activeIcon} />
      ) : (
        <RadioButtonUncheckedOutlinedIcon />
      ),
  },
];

export const initialBulkWizardData: BulkWizardData = {
  locationIds: [],
  items: [],
};
