import { createApiCall } from 'services/api';

export const fetchShopifyUrl = async (shopName: string): Promise<string> => {
  const response = await createApiCall({
    path: `/integrations/shopify/auth?shop=${shopName}`,
    method: 'GET',
  })();

  return response.data;
};
