import React, { memo, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import smoothscroll from 'smoothscroll-polyfill';
import { Box, Typography, Divider, ButtonBase } from '@mui/material';
import { APP_BAR_HEIGHT } from 'app/components/AppBar';
import { getEcommerceChannels } from 'services/integrations/ecommerce/redux';
import { useCardNavigationStyle } from './styled';
import { cardNavigationDescriptor } from './consts';
import { CardNavigationProps } from './types';
import { scrollToSection } from 'helpers';

const CardNavigation: React.FC<CardNavigationProps> = (props) => {
  const classes = useCardNavigationStyle(props);
  const { search } = props;

  const { items: connectedChannels } = useSelector(getEcommerceChannels);

  smoothscroll.polyfill();

  const connectedChannelTypes = useMemo(() => {
    return connectedChannels.map((channel) => channel.channel.channel);
  }, [connectedChannels]);

  const regex = useMemo(() => {
    return RegExp(_.escapeRegExp(search), 'gi');
  }, [search]);

  const filteredData = useMemo(() => {
    return cardNavigationDescriptor.filter((i) => regex.test(i.title));
  }, [regex]);

  const handleCardNavigationClick = useCallback((moduleId: string) => {
    if (!moduleId) {
      return;
    }

    if (moduleId.includes('#')) {
      moduleId = moduleId.replace('#', '');
    }

    window.location.hash = moduleId;

    scrollToSection(moduleId, APP_BAR_HEIGHT);
  }, []);

  return (
    <Box className={classes.navigation}>
      {filteredData.map((descriptor, i) => {
        if (!connectedChannelTypes.includes(descriptor.channel)) {
          return null;
        }

        const { icon: Icon, title, separate, id } = descriptor;

        return (
          <React.Fragment key={`fragment-${i}`}>
            {separate && filteredData.length > 1 && <Divider />}

            <ButtonBase
              className={classes.rowContainer}
              key={`box-${i}`}
              onClick={() => handleCardNavigationClick(id)}
            >
              <Icon className={classes.icon} key={`icon-${i}`} />
              <Typography className={classes.text} key={`typo-${i}`}>
                {title}
              </Typography>
            </ButtonBase>
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default memo(CardNavigation);
