import { Button, IconButton as MuiIconButton } from '@mui/material';
import React, { memo, useCallback, useMemo } from 'react';
import { showNotification } from 'services/api';
import { importSettings, syncInventory } from 'services/integrations/ecommerce';

import { Modal } from 'ui/components/Modal/Modal';
import { TitleBar } from 'ui/components/TitleBar';

import { CustomerSettingsForm } from '../../../CustomerSettingsForm';
import { FBASettingsForm } from '../../../FBASettingsForm';
import { FulfillmentSettingsForm } from '../../../FulfillmentSettingsForm';
import { InventorySettingsForm } from '../../../InventorySettingsForm';
import { ItemSettingsForm } from '../../../ItemSettingsForm';
import { OrderSettingsForm } from '../../../OrderSettingsForm';
import { POSSettingsForm } from '../../../POSSettingsForm';
import { EcommerceSettingsModalType } from '../../types';

import { EcommerceCardModalProps } from './types';

import { IconNames } from 'ui/theme';
import { Icon } from 'ui/components/Icon';
import { getAdminAccessToken } from 'services/auth/services';
import _ from 'lodash';
import { imageLogoUrl } from 'ui/modules/integrations/pages/EcommercePage/EcommerceProvider';

const EcommerceCardModal: React.FC<EcommerceCardModalProps> = (props) => {
  const {
    open,
    channelId,
    onClose,
    onSaveClicked,
    isLoadingContent,
    index,
    type,
    channelType,
  } = props;

  const importClicked = useCallback(async () => {
    try {
      await importSettings(channelId);
      showNotification('Item import has been initiated', {
        variant: 'success',
      });
    } catch (e) {
      const error = e as Error;

      showNotification(`Item import failed - ${error.message}`, {
        variant: 'error',
      });
    }
  }, [channelId]);

  const syncInitiate = useCallback(async () => {
    try {
      await syncInventory(channelId);
      showNotification('Inventory sync has been initiated', {
        variant: 'success',
      });
    } catch (error) {
      // Ignore error
    }
  }, [channelId]);

  const form = useMemo(() => {
    switch (type) {
      case EcommerceSettingsModalType.OrderSettings:
        return <OrderSettingsForm index={index} />;
      case EcommerceSettingsModalType.CustomerSettings:
        return <CustomerSettingsForm index={index} />;
      case EcommerceSettingsModalType.ItemSettings:
        return <ItemSettingsForm index={index} />;
      case EcommerceSettingsModalType.InventorySettings:
        return <InventorySettingsForm index={index} />;
      case EcommerceSettingsModalType.FulfillmentSettings:
        return <FulfillmentSettingsForm index={index} />;
      case EcommerceSettingsModalType.POSSettings:
        return <POSSettingsForm index={index} />;
      case EcommerceSettingsModalType.FBASettings:
        return <FBASettingsForm index={index} />;
      default:
        break;
    }
  }, [type, index]);

  const titleBarComponent = () => {
    return (
      <TitleBar title={type + ' Settings'} imageUrl={imageLogoUrl(channelType)}>
        {type === EcommerceSettingsModalType.ItemSettings && (
          <Button onClick={importClicked}>IMPORT</Button>
        )}
        {type === EcommerceSettingsModalType.InventorySettings &&
          !!getAdminAccessToken() && (
            <Button onClick={syncInitiate}>SYNC</Button>
          )}
        <MuiIconButton onClick={onClose}>
          <Icon onDark name={IconNames.Close} />
        </MuiIconButton>
      </TitleBar>
    );
  };

  const customHeigth = useMemo(() => {
    if (
      _.includes(
        [
          EcommerceSettingsModalType.FBASettings,
          EcommerceSettingsModalType.InventorySettings,
          EcommerceSettingsModalType.FulfillmentSettings,
          EcommerceSettingsModalType.POSSettings,
        ],
        type
      )
    ) {
      return 500;
    }

    if (type === EcommerceSettingsModalType.CustomerSettings) {
      return 300;
    }
  }, [type]);

  return (
    <Modal
      open={open}
      onCancelClicked={onClose}
      onApplyClicked={onSaveClicked}
      withoutDefaultPadding
      nestedScrollAreas
      TitleBarComponent={titleBarComponent}
      maxWidth="sm"
      customHeight={customHeigth}
      footerDivider="shadow"
      isLoadingContent={isLoadingContent}
    >
      {form}
    </Modal>
  );
};

export default memo(EcommerceCardModal);
