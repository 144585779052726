import React, { useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { getCurrentPaymentIntegration } from 'services/integrations/payments/redux';
import { PaymentIntegrationType } from 'services/integrations/payments';

import { PaymentTabCardProps } from './types';
import { AuthorizeForm, PaymentForm, StripeForm } from './components';

const PaymentTabCard = (props: PaymentTabCardProps) => {
  const paymentIntegration = useSelector(getCurrentPaymentIntegration);

  const ResolvedForm = useCallback(
    (props: PaymentTabCardProps) => {
      const paymentIntegrationType = _.get(
        paymentIntegration,
        'accountData.type',
        null
      );
      switch (paymentIntegrationType) {
        case PaymentIntegrationType.AuthorizeNet: {
          return <AuthorizeForm {...props} />;
        }
        case PaymentIntegrationType.Stripe: {
          return <StripeForm />;
        }
        default: {
          return <PaymentForm {...props} />;
        }
      }
    },
    [paymentIntegration]
  );

  return <ResolvedForm {...props} />;
};

export default memo(PaymentTabCard);
