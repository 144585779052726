import _ from 'lodash';

import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/taxRates';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { Column, ColumnTypes } from 'ui/components/Table/ItemsTable';
import { PaginationDirection } from 'services/url';
import { Vendor } from 'services/vendors';
import { Dates } from 'helpers';
import { MenuItem } from 'ui/components/Menu';
import { IconNames } from 'ui/theme';

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const displayNameMap = {
  name: 'Name',
  code: 'Code',
  agencyId: 'Vendor',
  deleted: 'Deleted',
};

export const initialTaxRateASFormValues: AdvancedSearchFormValues = {
  name: null,
  code: null,
  agencyId: null,
  deleted: null,
};

export const createDisplayValueMap = (vendors: Vendor[]) => ({
  agencyId: (id: number) =>
    _.get(
      vendors.find((v) => v.id === id),
      'name',
      null
    ),
});

export const TAX_RATE_COLUMNS_ALWAYS_ON: Column[] = [
  {
    title: 'Name',
    field: 'name',
    type: ColumnTypes.renderByCustomRow,
  },
  {
    title: 'Rate/Amount',
    field: 'percentage',
    type: ColumnTypes.renderByCustomRow,
  },
];

export const TAX_RATE_COLUMNS = (date: Dates): Column[] => {
  const resolveDateColumn = (): Column => {
    switch (date) {
      case Dates.DateLastModified:
        return {
          title: 'Date Last Modified',
          field: Dates.DateLastModified,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
      default:
        return {
          title: 'Date Created',
          field: Dates.DateCreated,
          sortable: true,
          type: ColumnTypes.renderByCustomRow,
          searchable: false,
        };
    }
  };

  return [
    {
      title: 'Name',
      field: 'name',
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Code',
      field: 'code',
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
    },
    {
      title: 'Rate/Amount',
      field: 'percentage',
      sortable: true,
      type: ColumnTypes.renderByCustomRow,
    },
    resolveDateColumn(),
  ];
};

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'dateLastModified',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};

export enum TaxRatesPageAction {
  ChangeDate,
  Export,
  Import,
}

export const createActionBarOptions = (
  onActionClick: (actionType: TaxRatesPageAction, date: Dates) => void
): MenuItem[] => [
  {
    label: 'Import Tax Rates',
    onOptionClick: () =>
      onActionClick(TaxRatesPageAction.Import, Dates.DateCreated),
    dataQa: 'tax-rate-date-import',
    icon: IconNames.FBOImport,
    separateAfter: true,
  },
  {
    label: 'Export Tax Rates',
    onOptionClick: () =>
      onActionClick(TaxRatesPageAction.Export, Dates.DateCreated),
    icon: IconNames.FBOExport,
    dataQa: 'tax-rate-date-export',
    separateAfter: true,
  },

  {
    icon: IconNames.FBORecycleSmall,
    label: 'Change Dates',
    dataQa: 'tax-rate-change-dates',
    subItems: [
      {
        label: 'Date Created',
        onOptionClick: () =>
          onActionClick(TaxRatesPageAction.ChangeDate, Dates.DateCreated),
        dataQa: 'tax-rate-date-created',
        separateAfter: true,
      },
      {
        label: 'Date Last Modified',
        onOptionClick: () =>
          onActionClick(TaxRatesPageAction.ChangeDate, Dates.DateLastModified),
        dataQa: 'tax-rate-date-last-modified',
      },
    ],
  },
];
