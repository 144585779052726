import {
  createFilterOptions,
  FilterOptionsState,
} from '@mui/material/useAutocomplete';

import { initialUser, User } from 'services/user';

export const usersAutocompleteGetOptionLabel = (user: User) => {
  if (!user.id || user.id < 0) {
    return `+ Add "${user.firstName}"`;
  }

  return `${user.firstName} ${user.lastName}`;
};

const filter = createFilterOptions<User>();

export const usersAutocompleteFilterOptions =
  (disableAdd: boolean) =>
  (options: User[], params: FilterOptionsState<User>) => {
    const filtered = filter(options, params);

    if (disableAdd) {
      return filtered;
    }

    if (!!params.inputValue) {
      // add default item at the beginning of options
      filtered.unshift({
        ...initialUser,
        firstName: params.inputValue || null,
      });
    }

    return filtered;
  };
