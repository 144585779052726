import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { ReportFiltersProps } from '../../types';
import { Box } from '@mui/system';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { Item } from 'services/items';
import { costingMethodOptions, eventOptions, groupByOptions } from './consts';
import { CostingMethodOption, Event, GroupBy } from './types';
import { CustomersAutocomplete } from 'ui/components/Autocomplete/CustomerAutocomplete';
import { ClassAutocomplete } from 'ui/components/Autocomplete/ClassesAutocomplete';
import { Customer } from 'services/customers';
import { Class } from 'services/classes';
import { UserAsyncAutocomplete } from 'ui/components/Autocomplete/UserAsyncAutocomplete';
import { User } from 'services/user';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { DateRange } from 'materialui-daterange-picker';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Location } from 'services/locations';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useHandleCheckboxChange } from 'services/forms/hooks';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchSettingsCompanies,
  getSettingsCompany,
} from 'services/settings/company/redux';

const ConsumptionFilter: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters, presetLoaded, errors } = props;
  const dispatch = useDispatch();

  const { accountingMethod } = useSelector(getSettingsCompany);

  const selectedGroupBy = useMemo(() => {
    return groupByOptions.find((o) => o.type === parameters.groupby) || null;
  }, [parameters]);

  const selectedEvents = useMemo(() => {
    return eventOptions.filter((o) => parameters.event?.includes(o.type));
  }, [parameters]);

  const selectedDateRange = useMemo(() => {
    return { startDate: parameters?.startDate, endDate: parameters?.endDate };
  }, [parameters]);

  const handleGroupByChange = useCallback(
    (_e: any, groupBy: GroupBy | null) => {
      setParameters((old) => ({
        ...old,
        groupby: groupBy?.type ?? null,
      }));
    },
    [setParameters]
  );

  const handleItemChange = useCallback(
    (selectedItem: Item | null) => {
      setParameters((old) => ({
        ...old,
        item: selectedItem ? [selectedItem.id] : null,
      }));
    },
    [setParameters]
  );

  const handleCustomerChange = useCallback(
    (customer: Customer | null) => {
      setParameters((old) => ({
        ...old,
        customer: customer ? [customer.id] : null,
      }));
    },
    [setParameters]
  );

  const handleClassChange = useCallback(
    (accountingClass: Class | null) => {
      setParameters((old) => ({
        ...old,
        class: accountingClass ? [accountingClass.id] : null,
      }));
    },
    [setParameters]
  );

  const handleUserChange = useCallback(
    (user: User | null) => {
      setParameters((old) => ({
        ...old,
        user: user ? [user.id] : null,
      }));
    },
    [setParameters]
  );

  const handleEventsChange = useCallback(
    (_e: any, events: Event[] | null) => {
      setParameters((old) => ({
        ...old,
        event: events ? events.map((e) => e.type) : null,
      }));
    },
    [setParameters]
  );

  const handleDateChange = useCallback(
    (value: DateRange) => {
      setParameters((old) => ({
        ...old,
        startDate: value?.startDate,
        endDate: value?.endDate,
      }));
    },
    [setParameters]
  );

  const handleLocationChange = useCallback(
    (location: Location | null) => {
      setParameters((old) => ({
        ...old,
        location: location ? [location.id] : null,
      }));
    },
    [setParameters]
  );

  const handleCostingMethodChange = useCallback(
    (e: any, option: CostingMethodOption | null) => {
      setParameters((old) => ({
        ...old,
        costingMethod: option?.type ?? null,
      }));
    },
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  const getCostingMethod = (identifier: string | null) => {
    return (
      costingMethodOptions.find((c) =>
        c.identifier.includes((identifier || '').toLocaleLowerCase())
      ) || null
    );
  };

  useEffect(() => {
    setParameters((old) => ({
      ...old,
      costingMethod: getCostingMethod(accountingMethod)?.type ?? null,
    }));
  }, [accountingMethod, presetLoaded, setParameters]);

  useEffect(() => {
    if (!accountingMethod) {
      // @ts-ignore
      dispatch(fetchSettingsCompanies());
    }
  }, [dispatch, accountingMethod]);

  return (
    <Box px={2} pb={0} pt="16px">
      <Box mb={1}>
        <Autocomplete
          label="Group By"
          options={groupByOptions}
          placeholder="Enter group by"
          getOptionLabel={(i) => i.label}
          onChange={handleGroupByChange}
          value={selectedGroupBy}
          fullWidth
          dataQa="consumption-filter-group-by"
        />
      </Box>
      <Box mb={1}>
        <ItemsAutocomplete
          label="Item"
          placeholder="Select item"
          onChange={handleItemChange}
          value={parameters.item?.[0] ?? null}
          dataQa="consumption-filter-item"
        />
      </Box>
      <Box mb={1}>
        <CustomersAutocomplete
          label="Customer"
          onChange={handleCustomerChange}
          value={parameters.customer?.[0] ?? null}
          placeholder="Select customer"
          dataQa="consumption-filter-customer"
        />
      </Box>
      <Box mb={1}>
        <DateRangePicker
          className="date-picker-small-font"
          label="Date Range"
          value={selectedDateRange}
          aria-label="Date Range"
          name="dateRange"
          placeholder="Select date range"
          onChange={handleDateChange}
          fullWidth
          dataQa="consumption-filter-date"
        />
      </Box>
      <Box mb={1}>
        <UserAsyncAutocomplete
          label="User"
          value={parameters.user?.[0] ?? null}
          placeholder="Select User"
          onChange={handleUserChange}
          dataQa="consumption-filter-user"
        />
      </Box>
      <Box mb={1}>
        <ClassAutocomplete
          value={parameters.class?.[0] ?? null}
          onChange={handleClassChange}
          label="Class"
          placeholder="Select Class"
          dataQa="consumption-filter-class"
        />
      </Box>
      <Box mb={1}>
        <Autocomplete
          className="multi-line"
          label="Event"
          placeholder="Select inventory Event Types"
          options={eventOptions}
          getOptionLabel={(i) => i.label}
          value={selectedEvents}
          onChange={handleEventsChange}
          dataQa="consumption-filter-event"
          multiple
        />
      </Box>
      <Box mb={1}>
        <LocationsAsyncAutocomplete
          label="Location"
          value={parameters.location?.[0] ?? null}
          placeholder="Select location"
          companyWide={false}
          onChange={handleLocationChange}
          dataQa="consumption-filter-location"
        />
      </Box>
      <Box mb={1}>
        <Autocomplete
          label="Costing Method"
          options={costingMethodOptions}
          getOptionLabel={(i) => i.label}
          placeholder="Costing Method"
          value={getCostingMethod(parameters.costingMethod)}
          onChange={handleCostingMethodChange}
          error={!!errors.costingMethod}
          helperText={errors.costingMethod}
          required
          fullWidth
        />
      </Box>
      <FormControlLabel
        className="redesign"
        sx={{ marginBottom: '8px' }}
        control={
          <Checkbox
            className="redesign"
            checked={Boolean(parameters.showNote)}
            onChange={handleCheckboxChange}
            name="showNote"
            color="primary"
            data-qa="consumption-filter-show-footer"
          />
        }
        label="Show Note"
      />
      <FormControlLabel
        className="redesign"
        sx={{ marginBottom: '8px' }}
        control={
          <Checkbox
            className="redesign"
            checked={Boolean(parameters.showNoCustomerEvent)}
            onChange={handleCheckboxChange}
            name="showNoCustomerEvent"
            color="primary"
            data-qa="consumption-filter-show-customer-event"
          />
        }
        label="Show events with no customer"
      />
      <FormControlLabel
        className="redesign"
        sx={{ marginBottom: '8px' }}
        control={
          <Checkbox
            className="redesign"
            checked={Boolean(parameters.showNoClassEvent)}
            onChange={handleCheckboxChange}
            name="showNoClassEvent"
            color="primary"
            data-qa="consumption-filter-show-no-class-event"
          />
        }
        label="Show events with no class"
      />
    </Box>
  );
};
export default memo(ConsumptionFilter);
