import React, { memo, useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

import { Modal } from 'ui/components/Modal/Modal';
import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import {
  initialReceiptItemReceive,
  ReceiptItemReceive,
  ReceiptItemStatus,
} from 'services/receiving';
import { replaceValueInCollection } from 'helpers';
import { getSettingsPurchaseOrder } from 'services/settings/purchaseOrders';
import { Errors } from 'services/forms/validation';
import { resolveSerialTableErrors } from 'ui/components/Table/SerialTable/helpers';

import {
  ReceiveWizardRow,
  ReceiveWizardItem,
  ReceiveWizardRecievedItem,
  ReceiveWizardSummary,
} from './components';
import { ReceiveWizardProps } from './types';
import { receiveWizardColumns, RECEIVE_ITEM_TABLE_HEIGHT } from './consts';
import { transformReceiptItemToReceiptItemReceive } from '../../transformations';
import { trackingValidation } from './validations';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { colorPalette, themeRestyle } from 'ui/theme';

const fakeSummaryStep = { ...initialReceiptItemReceive, id: -1 };

const FBOReceiveWizard: React.FC<ReceiveWizardProps> = (props) => {
  const { show, receipt, receiptItems, onClose, onSubmit } = props;

  const [receives, setReceives] = useState<ReceiptItemReceive[]>([]);
  const [activeReceive, setActiveReceive] = useState(initialReceiptItemReceive);
  const [loading, setLoading] = useState(false);
  const [loadingContent, setLoadingContent] = useState(false);
  const [showOverreceivingModal, setShowOverreceivingModal] = useState(false);
  const [activeReceiveSerialErrors, setActiveReceiveSerialErrors] = useState<
    Errors[]
  >([]);

  const poSettings = useSelector(getSettingsPurchaseOrder);

  const showBackButton = useMemo(() => {
    if (_.isEmpty(receives)) {
      return false;
    }

    return receives[0].receiptItemId !== activeReceive.receiptItemId;
  }, [receives, activeReceive.receiptItemId]);

  const isSummaryStep = useMemo(
    () => activeReceive.receiptItemId === -1,
    [activeReceive.receiptItemId]
  );

  const hasSummaryStep = useMemo(() => receives.length > 1, [receives]);

  const selectedReceiptItem = useMemo(() => {
    return receiptItems.find((l) => l.id === activeReceive.id) || null;
  }, [receiptItems, activeReceive.id]);

  const vendorItemName = useMemo(() => {
    const DOT_CHAR = '\u2022';
    if (selectedReceiptItem) {
      if (!selectedReceiptItem.purchaseOrderItem) {
        return '';
      }
      return selectedReceiptItem.purchaseOrderItem.vendorItemName
        ? ` ${DOT_CHAR} ${selectedReceiptItem.purchaseOrderItem.vendorItemName}`
        : '';
    }
    return '';
  }, [selectedReceiptItem]);

  useEffect(() => {
    if (show) {
      const newReceives = receiptItems.map(
        transformReceiptItemToReceiptItemReceive
      );
      setReceives(newReceives);
      setActiveReceive(
        newReceives[0]
          ? { ...newReceives[0], quantity: receiptItems[0].quantity }
          : initialReceiptItemReceive
      );
      setActiveReceiveSerialErrors([]);
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const submitReceive = useCallback(async () => {
    setLoading(true);
    try {
      await onSubmit(hasSummaryStep ? receives : [activeReceive]);
    } catch {
      setLoading(false);
      return;
    }
    onClose();
  }, [onSubmit, onClose, receives, activeReceive, hasSummaryStep]);

  const handleSubmitClicked = useCallback(async () => {
    if (isSummaryStep) {
      await submitReceive();
      return;
    }
    if (activeReceive.trackingGroups[0]?.serialList) {
      setActiveReceiveSerialErrors(
        resolveSerialTableErrors(activeReceive.trackingGroups[0].serialList)
      );
    }
    const isTrackingValid = trackingValidation(activeReceive);

    if (!isTrackingValid) {
      return;
    }

    if (!hasSummaryStep) {
      await submitReceive();
      return;
    }
    // check if quantity is greater then receipt item quantity
    if (
      (activeReceive.quantity || 0) >
        (activeReceive.receiptItem.quantity || 0) &&
      !poSettings.enableOverReceiving
    ) {
      setShowOverreceivingModal(true);
      return;
    }

    const index = receives.findIndex(
      (r) => r.receiptItemId === activeReceive.receiptItemId
    );

    setReceives(
      replaceValueInCollection(
        receives,
        { ...activeReceive, quantity: activeReceive.quantity || 0 },
        index
      )!
    );

    // if active receipt is last in receipt items show summary page
    if (index === receives.length - 1) {
      setActiveReceive(fakeSummaryStep);
      return;
    }

    setActiveReceive({
      ...receives[index + 1],
      quantity: receives[index + 1].receiptItem.quantity,
    });
  }, [
    activeReceive,
    receives,
    isSummaryStep,
    hasSummaryStep,
    submitReceive,
    poSettings.enableOverReceiving,
  ]);
  const handleBackClicked = useCallback(() => {
    // if summary step then take last receipt item
    const receiptItemIndex = isSummaryStep
      ? receives.length - 1
      : receives.findIndex(
          (r) => r.receiptItemId === activeReceive.receiptItemId
        ) - 1;
    setActiveReceive({ ...receives[receiptItemIndex] });
    setActiveReceiveSerialErrors([]);
  }, [isSummaryStep, receives, activeReceive.receiptItemId]);

  const handleSelectedChange = useCallback(
    (id: number) => {
      setActiveReceiveSerialErrors([]);
      // if summary step
      if (id === -1) {
        setActiveReceive(fakeSummaryStep);
        return;
      }

      const receiptItem = receiptItems.find((i) => i.id === id)!;
      const receive = receives.find((r) => r.receiptItemId === id)!;
      setActiveReceive({
        ...receive,
        quantity:
          receive.quantity === null ? receiptItem.quantity : receive.quantity,
      });
    },
    [receives, receiptItems]
  );

  const ModalActions = () => {
    return (
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box>
          {showBackButton && (
            <FBOButton
              variant="secondary"
              color="neutral"
              size="large"
              onClick={handleBackClicked}
              data-qa="receive-wizard-back-button"
            >
              Back
            </FBOButton>
          )}
        </Box>
        <Box display="flex">
          <FBOButton
            variant="secondary"
            color="positive"
            size="large"
            onClick={onClose}
            data-qa="receive-wizard-cancel-button"
          >
            Cancel
          </FBOButton>
          <FBOButton
            variant="primary"
            color="positive"
            size="large"
            onClick={handleSubmitClicked}
            data-qa="receiving-receive-finish"
            loading={loading}
            sx={{ marginLeft: '16px' }}
          >
            {`${isSummaryStep || !hasSummaryStep ? 'Finish' : 'Next'}`}
          </FBOButton>
        </Box>
      </Box>
    );
  };

  const ConfirmationModalActions = () => {
    return (
      <FBOButton
        variant="primary"
        color="positive"
        size="large"
        onClick={() => setShowOverreceivingModal(false)}
        data-qa="alert-modal-ok"
      >
        OK
      </FBOButton>
    );
  };

  return (
    <>
      <Modal
        open={show}
        onCancelClicked={onClose}
        withoutDefaultPadding
        isLoading={loading}
        title={_.get(selectedReceiptItem, 'item.name', '') + vendorItemName}
        maxWidth="lg"
        nestedScrollAreas
        ModalActionsComponent={ModalActions}
        isLoadingContent={loadingContent}
      >
        <Grid
          container
          spacing={2}
          disableEqualOverflow
          sx={{
            paddingLeft: themeRestyle.spacing(3),
            paddingRight: themeRestyle.spacing(3),
          }}
        >
          {receives.length > 1 && (
            <Grid xs={3}>
              <ItemsTable
                data={receives.concat(fakeSummaryStep)}
                columns={receiveWizardColumns}
                onItemClick={handleSelectedChange}
                RenderCustomRow={ReceiveWizardRow}
                activeId={activeReceive.receiptItemId}
                containerHeight={RECEIVE_ITEM_TABLE_HEIGHT}
                tableLayoutFixed
                selectableItems={false}
                sx={{
                  borderRadius: '5px',
                  border: `1px solid ${colorPalette.redesign.background3}`,
                  borderTop: 'none',
                }}
              />
            </Grid>
          )}
          <Grid xs={receives.length > 1 ? 9 : 12}>
            {!isSummaryStep ? (
              activeReceive.receiptItem.status !==
              ReceiptItemStatus.Received ? (
                <ReceiveWizardItem
                  receipt={receipt}
                  receive={activeReceive}
                  serialErrors={activeReceiveSerialErrors}
                  setReceive={setActiveReceive}
                  setLoadingContent={setLoadingContent}
                />
              ) : (
                <ReceiveWizardRecievedItem
                  receipt={receipt}
                  receive={activeReceive}
                  serialErrors={activeReceiveSerialErrors}
                  setReceive={setActiveReceive}
                  setLoadingContent={setLoadingContent}
                />
              )
            ) : (
              <ReceiveWizardSummary receive={receives} />
            )}
          </Grid>
        </Grid>
      </Modal>
      <ConfirmationModal
        open={showOverreceivingModal}
        title="Over receiving is not enabled"
        body="At the moment, over receiving is not supported. You can enable it under Settings > Purchase Order > Enable over receiving"
        onCancelClicked={() => {}}
        onConfirmClicked={() => {}}
        DialogActionsComponent={ConfirmationModalActions}
      />
    </>
  );
};

export default memo(FBOReceiveWizard);
