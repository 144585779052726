import { integrationsApi } from 'services/api/config';
import {
  transformEcommerceHistory,
  transformToPutEcommerceSettings,
} from './transformations';
import { EcommerceHistory, EcommerceSettings } from './types';

export const importSettings = async (channelId: number): Promise<void> => {
  await integrationsApi.post(`/items/import/${channelId}`);
};

export const syncInventory = async (channelId: number): Promise<void> => {
  await integrationsApi.post(`/items/inventory/export/${channelId}`);
};

export const disconnectChannel = async (channelId: number) => {
  await integrationsApi.delete(`/configuration/channels/${channelId}`);
};

export const getEcommerceHistory = async (
  settingsId: number,
  type: number,
  count: number = 50
): Promise<EcommerceHistory[]> => {
  const res = await integrationsApi.get(
    `/events/${settingsId}/${type}?count=${count}`
  );
  return res.data.map(transformEcommerceHistory);
};

export const putEcommerceShopName = async (
  channelId: number,
  name: string | null
): Promise<void> => {
  await integrationsApi.put(`/configuration/channels/${channelId}`, { name });
};

export const putSettings = async (
  channelId: number,
  settings: EcommerceSettings
): Promise<void> => {
  await integrationsApi.put(
    `/configuration/settings/${channelId}`,
    transformToPutEcommerceSettings(settings, settings.channel)
  );
};
