import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { colorPalette } from 'ui/theme';

export const useCardNavigationStyle = makeStyles((theme: Theme) => ({
  navigation: {
    alignSelf: 'flex-start',
  },
  rowContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    height: theme.spacing(6),
    padding: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  text: {
    color: colorPalette.grey,
  },
  search: {
    marginBottom: theme.spacing(3),
  },
}));
