import { createApiReducer, chainReducers } from 'services/api';

import { initialPaymentIntegration } from '../consts';
import { PaymentIntegrationActionType } from './actions';

const fetchPaymentIntegrationsReducer = createApiReducer(
  [
    PaymentIntegrationActionType.FETCH_PAYMENT_INTEGRATIONS_START,
    PaymentIntegrationActionType.FETCH_PAYMENT_INTEGRATIONS_SUCCESS,
    PaymentIntegrationActionType.FETCH_PAYMENT_INTEGRATIONS_FAILURE,
  ],
  (data) => ({ connections: data }),
  (data) => data
);

const paymentIntegrationsChainedReducers = chainReducers(
  initialPaymentIntegration,
  fetchPaymentIntegrationsReducer
);

export default paymentIntegrationsChainedReducers;
