import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { colorPalette, SupportColors } from 'ui/theme';

export const useReorderTab = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    width: '100%',
    overflow: 'hidden',
  },
}));

export const useRenderTabRowStyle = makeStyles((theme: Theme) => ({
  headerStyle: {
    backgroundColor: colorPalette.applicationDarkGray10,
  },
  headerText: {
    whiteSpace: 'nowrap',
    color: SupportColors.tableHeaderTextColor,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    '&:only-of-type': {
      width: '100% !important',
    },
  },
  alertLabel: {
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  alertButton: {
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(2),
    borderRadius: theme.spacing(4),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
  },
  marginRight: {
    marginRight: '20px',
  },
  hiddenInput: {
    display: 'none',
  },
  buttonDisabled: {
    color: 'rgba(0, 0, 0, 0.24)',
  },
}));

export const useAddRowAlertModal = makeStyles(() => ({
  buttonLink: {
    'text-transform': 'none',
    'white-space': 'pre',
    cursor: 'pointer',
  },
}));

// Lint skip to be removed
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const useReorderTabManageAlertsStyle = makeStyles((theme: Theme) => ({
  chipSuccess: {
    backgroundColor: colorPalette.statusGreen,
  },
  chipWarning: {
    backgroundColor: colorPalette.statusOrange,
  },
  buttonStyle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  searchInputInner: {
    height: 32,
    background: 'white',
    paddingTop: `0!important`,
    paddingBottom: `0!important`,
  },
  searchInputOuter: {
    flex: 1,
    paddingRight: 5,
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  noBorder: {
    border: 0,
  },
}));
