import React, { useCallback } from 'react';
import { Grid, Box, Typography } from '@mui/material';

import { usePopoverItemStyle } from './styled';

const PopoverItem: React.FC<{
  label: string;
  onClick(): void;
}> = (props) => {
  const { label, onClick } = props;
  const classes = usePopoverItemStyle();

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      onClick();
    },
    [onClick]
  );

  return (
    <Grid container classes={{ root: classes.popover }} onClick={handleOnClick}>
      <Grid item xs={12}>
        <Box py={1} pl={1}>
          <Typography variant="body2" color="textSecondary">
            {label}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(PopoverItem);
