import { PermissionGroup } from './types';

export const initialPermissionGroup: PermissionGroup = {
  id: null,
  dateCreated: null,
  dateLastModified: null,
  name: null,
  permissionIds: [],
  permissions: [],
  readOnly: false,
  users: [],
  version: null,
};
