import React, { useCallback, memo, useMemo } from 'react';
import _ from 'lodash';
import { Box, Typography, Divider, ButtonBase } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import smoothscroll from 'smoothscroll-polyfill';

import { APP_BAR_HEIGHT } from 'app/components/AppBar';
import { SearchInput } from 'ui/components/SearchHeader/styled';

import { useCardNavigationStyle } from './styled';
import { cardNavigationDescriptor } from './consts';
import { CardNavigationProps } from './types';
import { scrollToSection } from 'helpers';

const CardNavigation: React.FC<CardNavigationProps> = (props) => {
  const classes = useCardNavigationStyle(props);
  const { search, setSearch, onMappingClicked } = props;

  smoothscroll.polyfill();

  const regex = useMemo(() => {
    return RegExp(_.escapeRegExp(search), 'gi');
  }, [search]);

  const filteredData = useMemo(() => {
    return cardNavigationDescriptor.filter((i) => regex.test(i.title));
  }, [regex]);

  const onNavClicked = useCallback(
    (moduleId: string) => {
      if (moduleId === 'accountMappingId') {
        onMappingClicked();
        return;
      }

      scrollToSection(moduleId, APP_BAR_HEIGHT);
    },
    [onMappingClicked]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <Box className={classes.navigation}>
      <SearchInput
        type="text"
        placeholder="Search Settings"
        className={classes.search}
        value={search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onChange={handleSearchChange}
      />
      {filteredData.map((descriptor, i) => {
        const { icon: Icon, title, separate, id } = descriptor;

        return (
          <React.Fragment key={`fragment-${i}`}>
            {separate && filteredData.length > 1 && <Divider />}

            <ButtonBase
              className={classes.rowContainer}
              key={`box-${i}`}
              onClick={() => onNavClicked(id)}
            >
              <Icon className={classes.icon} key={`icon-${i}`} />
              <Typography className={classes.text} key={`typo-${i}`}>
                {title}
              </Typography>
            </ButtonBase>
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default memo(CardNavigation);
