import React from 'react';
import { Switch } from 'react-router-dom';

import SecureRoute from 'ui/components/Route/SecureRoute';
import { PermissionType } from 'services/permissions';

import { Routes } from './routes';
import {
  UomPage,
  LocationPage,
  UsersPage,
  PaymentTermsPage,
  RepresentativesPage,
  TaxRatesPage,
  CarriersPage,
  SettingsPage,
  CurrencyPage,
} from '../pages';
import { SetupRouterCmp, SetupRouterProps } from './types';
import { PermissionsPage } from '../pages/PermissionsPage';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SetupRouter: SetupRouterCmp = (props: SetupRouterProps) => {
  return (
    <Switch>
      <SecureRoute
        path={UomPage.route}
        exact
        component={UomPage}
        permissions={[PermissionType.UomView]}
      />
      <SecureRoute
        path={LocationPage.route}
        exact
        component={LocationPage}
        permissions={[PermissionType.LocationView]}
      />
      <SecureRoute
        path={UsersPage.route}
        exact
        component={UsersPage}
        permissions={[PermissionType.UserView]}
      />
      <SecureRoute
        path={PermissionsPage.route}
        exact
        component={PermissionsPage}
        permissions={[PermissionType.PermissionView]}
      />
      <SecureRoute
        path={PaymentTermsPage.route}
        exact
        component={PaymentTermsPage}
        permissions={[PermissionType.PaymentTermsView]}
      />
      <SecureRoute
        path={CurrencyPage.route}
        exact
        component={CurrencyPage}
        permissions={[PermissionType.CurrencyView]}
      />
      <SecureRoute
        path={RepresentativesPage.route}
        exact
        component={RepresentativesPage}
        permissions={[PermissionType.RepresentativesView]}
      />
      <SecureRoute
        path={TaxRatesPage.route}
        exact
        component={TaxRatesPage}
        permissions={[PermissionType.TaxRatesView]}
      />
      <SecureRoute
        path={CarriersPage.route}
        exact
        component={CarriersPage}
        permissions={[PermissionType.CarriersView]}
      />
      <SecureRoute
        path={SettingsPage.route}
        exact
        component={SettingsPage}
        permissions={[
          PermissionType.SettingsAccountMappingView,
          PermissionType.SettingsCompanyView,
          PermissionType.SettingsEmailingView,
          PermissionType.SettingsPurchaseOrderView,
          PermissionType.SettingsSalesOrderView,
          PermissionType.SettingsTrackingView,
          PermissionType.SettingsShippingView,
          PermissionType.SettingsPickingView,
        ]}
      />
    </Switch>
  );
};

SetupRouter.route = Routes.SetupRouter;

export default SetupRouter;
