import React, { useCallback } from 'react';
import {
  Checkbox,
  Chip,
  FormControl,
  OutlinedInput,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormHelperText,
} from '@mui/material';
import _ from 'lodash';

import { MultiSelectProps } from './types';
import { useMultiSelectStyles } from './styled';

const MultiSelect: React.FC<MultiSelectProps> = (props) => {
  const {
    options,
    values = [],
    label = '',
    getOptionLabel = (o) => o,
    onChange,
    error,
    disabled,
    inputRef,
    helperText,
  } = props;

  const classes = useMultiSelectStyles();

  const handleChange = useCallback(
    //TODO remove any
    (event: SelectChangeEvent<any>, child: any) => {
      const clickedValue = child.props.value;
      let selectedValues = event.target.value;

      // If already in the list remove
      if (_.some(values, clickedValue)) {
        selectedValues = selectedValues.filter(
          (v: any) => getOptionLabel(v) !== getOptionLabel(clickedValue)
        );
      }

      if (onChange) {
        // Fire null if selectedValues is empty list
        onChange(!_.isEmpty(selectedValues) ? selectedValues : null);
      }
    },
    [getOptionLabel, onChange, values]
  );

  return (
    <FormControl
      className={'redesign-temp'}
      variant={'standard'}
      fullWidth
      size="small"
    >
      <InputLabel shrink variant={'standard'}>
        {label}
      </InputLabel>
      <Select
        className="multi-line"
        input={<OutlinedInput error={error} label={label} />}
        value={values}
        multiple
        onChange={handleChange}
        fullWidth
        disabled={disabled}
        variant={'standard'}
        classes={{ select: classes.selectBox }}
        renderValue={(selected) => (
          <div>
            {(selected as any[]).map((s) => {
              const name = getOptionLabel(s);
              return <Chip key={name} label={name} className={classes.chip} />;
            })}
          </div>
        )}
        inputRef={inputRef}
      >
        {options.map((o: any) => {
          const name = getOptionLabel(o);
          const allNames: string[] = values.map((v) => getOptionLabel(v));

          return (
            <MenuItem key={name} value={o}>
              <Checkbox
                className={'redesign'}
                checked={allNames.indexOf(name) > -1}
              />
              <ListItemText
                primary={name}
                data-qa={`multiple-choice-option-${name}`}
              />
            </MenuItem>
          );
        })}
      </Select>
      {helperText && <FormHelperText error>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default MultiSelect;
