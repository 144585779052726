import React, { useEffect, useCallback, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { useFlags } from 'helpers/useFlags';

import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import {
  transformCustomer,
  Customer,
  fetchCustomers,
  fetchSearch,
  getSearches,
  deleteCustomer,
  deleteCustomers,
  getCustomers,
} from 'services/customers';
import { fetchCarriers } from 'services/carriers';
import { fetchOrderPriorities } from 'services/settings/orderPriorities';
import {
  fetchRepresentatives,
  getRepresentatives,
} from 'services/representatives/redux';
import {
  fetchPaymentTerms,
  getPaymentTerms,
} from 'services/paymentTerms/redux';
import { Pagination } from 'services/search/types';
import { fetchTags, fetchTagsAPI, Tag } from 'services/tags';
import { fetchSettingsCompanies } from 'services/settings/company/redux';
import { ObjectType } from 'services/customFields';
import { useUrlQueryObject } from 'services/url';
import {
  showDownloadNotification,
  showLoadingNotification,
} from 'services/api/notifications';
import { CustomFieldsModal } from 'ui/components/CustomFields/CustomFieldsModal';
import {
  BackgroundAction,
  BackgroundType,
  startBackgroundExport,
  startBackgroundImport,
  useBackgroundTasks,
} from 'services/backgroundTasks';
import { showProgressAlert } from 'services/alert/redux';
import { Dates, useGetIntlDateFormatString } from 'helpers';
import { fetchClasses } from 'services/classes';
import { fetchTaxRates } from 'services/taxRates';

import {
  CustomersSearchResults,
  CustomerDetailCard,
  CustomersAdvancedSearch,
} from './components';
import { Routes } from '../../navigation';
import { CustomersPageProps, CustomerPageCmp } from './types';
import {
  initialCustomerFormValues,
  advancedSearchReduxActions,
  displayNameMap,
  initialPagination,
  CUSTOMER_COLUMNS,
  createDisplayValueMap,
} from './consts';
import {
  CustomerPageAction,
  CustomerPageRowAction,
} from './components/CustomersSearchResults/consts';
import { logErrorCtx } from 'app/logging';

const CustomersPage: CustomerPageCmp = (props: CustomersPageProps) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activeCustomerId,
  } = props;

  const flags = useFlags();

  const { items: representative } = useSelector(getRepresentatives);
  const { items: customers } = useSelector(getCustomers);
  const { items: paymentTerms } = useSelector(getPaymentTerms);

  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState<Customer | null>();
  const [customFieldsModalVisible, setCustomFieldsModalVisible] =
    useState(false);
  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateCreated);
  const [searchTags, setSearchTags] = useState<Tag[]>([]);

  const [, extendUrlQuery] = useUrlQueryObject();
  const dispatch = useDispatch();

  const hiddenInput = useRef<HTMLInputElement>(null);

  const { startFetching, startCsvFetching } = useBackgroundTasks();
  const intlFormatDate = useGetIntlDateFormatString();

  // watch advanced search columns and fetch selected tags
  useEffect(() => {
    const tagIds = _.get(
      searchResult.advancedSearch.columns,
      ['tags.id'],
      null
    ) as number[];

    if (tagIds) {
      (async () => {
        try {
          const resTags = await fetchTagsAPI({}, tagIds);

          setSearchTags(resTags.data);
        } catch {
          //ignore Error
        }
      })();
    }
  }, [searchResult.advancedSearch.columns]);

  const exportCustomers = useCallback(async () => {
    showDownloadNotification();
    try {
      await startBackgroundExport(BackgroundType.Customer);
      startFetching();
    } catch {
      // continue regardless of error
    }
  }, [startFetching]);

  const importCustomers = useCallback(() => {
    hiddenInput.current!.click();
  }, [hiddenInput]);

  const handleHiddenInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const uploadedFile = event.target.files[0];
      showLoadingNotification('Your Customers import has been initiated.');

      if (flags.csvImport) startCsvFetching();
      event.target.value = '';
      try {
        await startBackgroundImport(
          BackgroundType.Customer,
          uploadedFile,
          flags.csvImport
        );
        dispatch(
          showProgressAlert(
            'Your Import to Customers has been initiated. This may take a few minutes to complete.',
            BackgroundType.Customer,
            BackgroundAction.Import
          )
        );
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error in CustomerPage', {
          error,
          stackTrace: error.stack,
          title: 'Error while importing customer',
          description: 'startBackgroundImport  Not Fetched from Server',
          component: 'CustomerPage',
        });
      }
    },
    [dispatch]
  );

  const handleActiveCustomerClose = useCallback(
    () => extendUrlQuery({ activeId: null }),
    [extendUrlQuery]
  );

  const handleAddNewPress = useCallback(() => {
    extendUrlQuery({ activeId: -1 });
  }, [extendUrlQuery]);

  const customerClicked = useCallback(
    async (customerId: number) => {
      extendUrlQuery({ activeId: customerId });
    },
    [extendUrlQuery]
  );

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );

  const handleAction = useCallback(
    async (type: CustomerPageRowAction, value: any) => {
      switch (type) {
        case CustomerPageRowAction.Delete: {
          setCustomerToDelete(value);
          break;
        }
      }
    },
    []
  );

  const handleCustomerPageAction = useCallback(
    (action: CustomerPageAction, date?: Dates) => {
      switch (action) {
        case CustomerPageAction.Export:
          exportCustomers();
          break;
        case CustomerPageAction.Import:
          importCustomers();
          break;
        case CustomerPageAction.CustomFields:
          setCustomFieldsModalVisible(true);
          break;
        case CustomerPageAction.ChangeDate:
          setActiveDate(date!);
          break;
      }
    },
    [exportCustomers, importCustomers]
  );

  const handleDeleteSingle = useCallback(async () => {
    setDeleteModalLoading(true);
    try {
      await deleteCustomer(customerToDelete!.id!);
      await fetchSearchResult();
      setCustomerToDelete(null);
    } catch {
      // Ignore error
    }
    setDeleteModalLoading(false);
  }, [customerToDelete, fetchSearchResult]);

  const onDeleteClicked = useCallback(() => setDeleteModalVisible(true), []);

  const hideDeleteModal = useCallback(() => {
    setDeleteModalVisible(false);
    setSelectedItems([]);
  }, [setSelectedItems]);

  const handleDeleteConfirm = useCallback(async () => {
    setDeleteModalLoading(true);
    try {
      await deleteCustomers(selectedItems);
    } catch {
      setDeleteModalLoading(false);
      return;
    }

    fetchSearchResult();
    setDeleteModalLoading(false);
    hideDeleteModal();
  }, [hideDeleteModal, fetchSearchResult, selectedItems]);

  return (
    <>
      <PageWithAdvancedSearch
        detailCardColumns={CUSTOMER_COLUMNS(activeDate)}
        initialFormValues={initialCustomerFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={CustomersAdvancedSearch}
        displayNameMap={displayNameMap}
        displayValueMap={createDisplayValueMap(
          representative,
          customers,
          paymentTerms,
          searchTags,
          intlFormatDate
        )}
        pageName="Customer"
      >
        <PaperSlidingLayout shown={Boolean(activeCustomerId)}>
          <CustomersSearchResults
            customers={searchResult.items}
            activeDate={activeDate}
            activeCustomerId={activeCustomerId}
            handleCustomerClick={customerClicked}
            onAddNewPress={handleAddNewPress}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
            isLoadingCustomers={isLoadingSearchResult}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            onDeleteClicked={onDeleteClicked}
            onAction={handleAction}
            onCustomerPageAction={handleCustomerPageAction}
          />
          <CustomerDetailCard
            activeCustomerId={activeCustomerId}
            onClose={handleActiveCustomerClose}
            fetchSearchResult={fetchSearchResult}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Customer"
        body={`This will delete all selected Customers, are you sure?`}
        onCancelClicked={hideDeleteModal}
        onConfirmClicked={handleDeleteConfirm}
        isLoading={deleteModalLoading}
        confirmLabel="Delete"
        confirmButtonRed
      />
      {customerToDelete && (
        <ConfirmationModal
          open
          title="Delete Customer"
          body={`Are you sure you want to delete ${customerToDelete.name}`}
          onCancelClicked={() => setCustomerToDelete(null)}
          onConfirmClicked={handleDeleteSingle}
          isLoading={deleteModalLoading}
          confirmLabel="Delete"
          confirmButtonRed
        />
      )}
      <CustomFieldsModal
        open={customFieldsModalVisible}
        setVisible={setCustomFieldsModalVisible}
        module={ObjectType.Customer}
      />
      <input
        type="file"
        ref={hiddenInput}
        style={{ display: 'none' }}
        onChange={handleHiddenInput}
        accept=".csv"
      />
    </>
  );
};

CustomersPage.route = Routes.CustomersPage;

export default withSearchResults<Customer>(CustomersPage, {
  url: '/v1/customers',
  expand: 'customerAddresses',
  dataAdapter: transformCustomer,
  columns: CUSTOMER_COLUMNS(Dates.DateCreated),
  fetchSearch,
  getSearches,
  initialPagination,
  rehydrationThunks: [
    fetchCarriers,
    fetchClasses,
    fetchCustomers,
    fetchOrderPriorities,
    fetchPaymentTerms,
    fetchRepresentatives,
    fetchSettingsCompanies,
    fetchTags,
    fetchTaxRates,
  ],
});
