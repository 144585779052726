import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import AutoComplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';

import { CarrierAccountAutocompleteProps } from './types';
import {
  carrierAccountAutocompleteFilterOptions,
  carrierAccountAutocompleteGetOptionLabel,
} from './helpers';
import {
  createCustomerCarrierAccount,
  CustomerCarrierAccount,
  fetchCustomerCarrierAccounts,
  fetchCustomerCarrierAccountsById,
  initialCustomerCarrierAccount,
  updateCustomerCarrierAccount,
} from 'services/customers';
import CarrierAccountAddModal from 'ui/modules/sales/pages/CustomersPage/components/CustomerDetailCard/components/CarrierAccountsTab/CarrierAccountAddModal';
import { Errors, validateYup } from 'services/forms/validation';
import { yupCustomerCarrierAccountsSchema } from 'ui/modules/sales/pages/CustomersPage/components/CustomerDetailCard/validation';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';

const CarrierAccountAutocomplete: React.FC<CarrierAccountAutocompleteProps> = (
  props
) => {
  const {
    label,
    value,
    placeholder,
    required,
    onChange,
    onFetch,
    disableAdd,
    removedIds = [],
    dataQa,
    additionalInputProps,
    classes,
    customer,
    disabled,
    error,
  } = props;

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [carrierAccounts, setCarrierAccounts] = useState<
    CustomerCarrierAccount[]
  >([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [innerValue, setInnerValue] = useState<CustomerCarrierAccount | null>(
    null
  );
  const [activeCarrierAccount, setActiveCarrierAccount] =
    useState<CustomerCarrierAccount>(initialCustomerCarrierAccount);
  const [validationErrors, setValidationErrors] = useState<Errors>({});
  const [reassignModalVisible, setReassignModalVisible] = useState(false);

  useEffect(() => {
    if (typeof value === 'number') {
      if (!innerValue) {
        (async () => {
          const resCustomerCarrierAccount =
            await fetchCustomerCarrierAccountsById(customer.id, value);
          setInnerValue(resCustomerCarrierAccount);
        })();
      }
    } else {
      setInnerValue(value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const getCarrierAccounts = useDebouncedCallback(
    async (execOnFetch: boolean) => {
      setFetchLoading(true);

      const resCustomerCarrierAccounts = (
        await fetchCustomerCarrierAccounts(
          {
            quickSearchValue: searchValue,
          },
          customer.id!
        )
      ).data;
      // don't show removedIds
      const filteredCarrierAccounts = resCustomerCarrierAccounts.filter(
        (u) => !removedIds.includes(u.id!)
      );

      setCarrierAccounts(filteredCarrierAccounts);
      setFetchLoading(false);
      if (execOnFetch) {
        onFetch(filteredCarrierAccounts);
      }
    },
    300
  );

  useEffect(() => {
    // when autocomplete values is changed and it's not null
    // or when autocomplete is opened and doesn't have any data
    // we want to fetch items
    if (searchValue || (open && !carrierAccounts.length)) {
      getCarrierAccounts(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, open]);

  const handleAutocompleteInputChange = (
    e: any,
    val: string,
    reason: string
  ) => {
    // reason why this event is triggered
    // it can be 'input', 'clear' and 'reset'
    if (reason === 'input') {
      setSearchValue(val || null);
      return;
    }

    setSearchValue(null);
  };

  const handleChange = (
    e: any,
    customerCarrierAccount: CustomerCarrierAccount | null
  ) => {
    if (
      customerCarrierAccount &&
      (customerCarrierAccount.id === null || customerCarrierAccount.id < 0)
    ) {
      setActiveCarrierAccount((old) => ({
        ...old,
        name: customerCarrierAccount.name,
        customerId: customer.id,
      }));

      setShowModal(true);
      return;
    }

    setInnerValue(customerCarrierAccount);
    onChange(customerCarrierAccount);
  };

  const save = useCallback(
    async (reassignDefaultFlag: boolean) => {
      setUpdateLoading(true);

      if (reassignDefaultFlag) {
        const customerAccountDefault = customer.carrierAccountSettings.find(
          (a) => a.defaultFlag === true
        );

        if (customerAccountDefault) {
          customerAccountDefault.defaultFlag = false;

          try {
            await updateCustomerCarrierAccount(customerAccountDefault);
          } catch {
            setUpdateLoading(false);
            return;
          }
        }
      }

      try {
        await createCustomerCarrierAccount(activeCarrierAccount);
      } catch {
        setUpdateLoading(false);
        return;
      }

      setUpdateLoading(false);
    },
    [customer.carrierAccountSettings, activeCarrierAccount]
  );

  const handleCloseReassignModal = () => {
    setReassignModalVisible(false);
  };

  const handleConfirmReassignModal = () => {
    save(true);
    setReassignModalVisible(false);
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSaveModal = useCallback(async () => {
    const isValid = validateYup(
      activeCarrierAccount,
      yupCustomerCarrierAccountsSchema,
      setValidationErrors
    );

    if (!isValid) {
      return;
    }

    const indexNumDefault = customer.carrierAccountSettings.findIndex(
      (a) => a.defaultFlag === true
    );

    if (activeCarrierAccount.defaultFlag && indexNumDefault !== -1) {
      setReassignModalVisible(true);
      return;
    }

    save(false);

    setShowModal(false);
    getCarrierAccounts(true);
    setActiveCarrierAccount(initialCustomerCarrierAccount);
  }, [
    activeCarrierAccount,
    getCarrierAccounts,
    customer.carrierAccountSettings,
    save,
  ]);

  return (
    <>
      <AutoComplete
        options={carrierAccounts}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={handleChange}
        renderInput={(params) => {
          // params.InputProps.endAdornment is component with 2 children
          // first child is clear button, so we want to set it to null
          const endAdornment = params.InputProps.endAdornment as any;
          const endAdornmentWithoutClear = {
            ...endAdornment,
            props: {
              ...endAdornment.props,
              children: [null, endAdornment.props.children[1]],
            },
          };

          return (
            <TextField
              // we are omitting props that would overwrite our styling in TextField
              {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
              label={label}
              placeholder={placeholder}
              required={required}
              error={error}
              InputProps={{
                ...params.InputProps,
                ...additionalInputProps,
                endAdornment: (
                  <React.Fragment>
                    {fetchLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {endAdornmentWithoutClear}
                  </React.Fragment>
                ),
              }}
              inputProps={{
                'data-qa': dataQa,
                ...params.inputProps,
              }}
            />
          );
        }}
        filterOptions={carrierAccountAutocompleteFilterOptions(!!disableAdd)}
        onInputChange={handleAutocompleteInputChange}
        getOptionLabel={carrierAccountAutocompleteGetOptionLabel}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        value={innerValue}
        disableClearable={required}
        classes={classes}
        autoSelect
        disabled={disabled}
        clearIcon={
          dataQa && (
            <CloseIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-clear-icon`}
            />
          )
        }
        popupIcon={
          dataQa && (
            <ArrowDropDownIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-dropdown-icon`}
            />
          )
        }
      />
      <CarrierAccountAddModal
        modalVisible={showModal}
        activeCarrierAccount={activeCarrierAccount}
        setActiveCarrierAccount={setActiveCarrierAccount}
        onClose={handleCloseModal}
        onSaveChanges={handleSaveModal}
        validationErrors={validationErrors}
        isLoading={updateLoading}
      />
      <ConfirmationModal
        open={reassignModalVisible}
        title={`Warning`}
        body={`There is already a default carrier account specified. Do you want to reassign the default to the one you are adding?`}
        onCancelClicked={handleCloseReassignModal}
        onConfirmClicked={handleConfirmReassignModal}
        confirmLabel="Reassign"
      />
    </>
  );
};

export default memo(CarrierAccountAutocomplete);
