import React from 'react';
import { TableCell, Typography } from '@mui/material';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';

import { ConfirmRopValues } from '../../types';

export const LocationRow: React.FC<RenderCustomRowProps<ConfirmRopValues>> = (
  props
) => {
  const { row } = props;

  return (
    <>
      <TableCell colSpan={8}>
        <Typography>{row.locationName}</Typography>
      </TableCell>
    </>
  );
};
