import React, { memo, useCallback, useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { PaymentContext } from 'ui/components/Modal/PaymentModal/PaymentProvider';
import { PaymentIntegrationType } from 'services/integrations/payments';
import { getPaymentIntegration } from 'services/integrations/payments/redux';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';

import { PaymentTabCardProps } from '../../types';

const AuthorizeForm: React.FC<PaymentTabCardProps> = (props) => {
  const { authFormButtonRef, validationErrors } = props;

  const { paymentAmount, setPaymentAmount } = useContext(PaymentContext);

  const authNetIntegration = useSelector(
    getPaymentIntegration(PaymentIntegrationType.AuthorizeNet)
  );

  // We add the AcceptUI script for the hosted payment form, and the script is attached to the Button component below
  useEffect(() => {
    const script = document.createElement('script');
    script.defer = true;
    script.type = 'text/javascript';
    script.src = 'https://jstest.authorize.net/v3/AcceptUI.js';
    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  // As the modals have to be closed to use the hosted form, we save the amount in localhost to use it later
  const handlePaymentAmountChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value ? parseFloat(e.target.value) : null;
      setPaymentAmount(value);
      const newValue = value ? (value * 100).toString() : '';
      localStorage.setItem('authNetAmount', newValue);
    },
    [setPaymentAmount]
  );

  return (
    <Box
      p={5}
      display="flex"
      flexDirection="column"
      flexGrow={1}
      alignItems="center"
    >
      <CurrencyField
        label="Payment Amount"
        placeholder="Enter amount"
        name="paymentAmount"
        fullWidth
        value={paymentAmount}
        required
        error={!!validationErrors.paymentAmount}
        onChange={handlePaymentAmountChanged}
      />
      <form id="paymentForm" method="POST">
        <input type="hidden" name="dataValue" id="dataValue" />
        <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
        <button
          type="button"
          ref={authFormButtonRef}
          className="AcceptUI"
          data-billingAddressOptions='{"show":true, "required":false}'
          data-apiLoginID={_.get(
            authNetIntegration,
            'accountData.authNetApiLoginId',
            ''
          )}
          data-clientKey={_.get(
            authNetIntegration,
            'accountData.publicClientKey',
            ''
          )}
          data-acceptUIFormBtnTxt="Submit"
          data-acceptUIFormHeaderTxt="Card Information"
          data-paymentOptions='{"showCreditCard": true, "showBankAccount": true}'
          data-responseHandler="responseHandler"
          hidden
        />
      </form>
    </Box>
  );
};

export default memo(AuthorizeForm);
