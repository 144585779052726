import React, { useCallback, useRef, useState } from 'react';

import { fetchUsers } from 'services/user';
import { useUrlQueryObject } from 'services/url';
import { Pagination } from 'services/search';
import {
  getSearches,
  fetchSearch,
  PermissionGroup,
  transformPermissionGroup,
} from 'services/permissions';
import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { PageWithAdvancedSearch } from 'ui/components/Page/PageWithAdvancedSearch';
import { withSearchResults } from 'ui/components/Page/WithSearchResults';
import { Dates, getErrorMessage } from 'helpers';

import { Routes } from '../../navigation';
import {
  PermissionDetailsCard,
  PermissionsAdvancedSearch,
  PermissionsSearchResults,
} from './components';
import { PermissionsPageCmp } from './types';
import {
  advancedSearchReduxActions,
  displayNameMap,
  initialPagination,
  initialPermisionFormValues,
  permissionColumns,
  PermissionsPageAction,
} from './consts';
import {
  showDownloadNotification,
  showLoadingNotification,
} from 'services/api/notifications';
import {
  BackgroundAction,
  BackgroundType,
  startBackgroundExport,
  startBackgroundImport,
  useBackgroundTasks,
} from 'services/backgroundTasks';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { logErrorCtx } from 'app/logging';
import { showProgressAlert } from 'services/alert/redux';

const PermissionsPage: PermissionsPageCmp = (props) => {
  const {
    searchState: searchResult,
    refreshSearchState: fetchSearchResult,
    isLoadingSearchState: isLoadingSearchResult,
    activeItemId: activeId,
  } = props;

  const [activeDate, setActiveDate] = useState<Dates>(Dates.DateCreated);
  const [, extendUrlQuery] = useUrlQueryObject();
  const dispatch = useDispatch();

  const csvEnabled = useFlags().csvImport;
  const { startFetching, startCsvFetching } = useBackgroundTasks();
  const hiddenInput = useRef<HTMLInputElement>(null);
  const handleDetailsCardClose = () => {
    extendUrlQuery({ activeId: null });
  };

  const handleAddNewPress = () => {
    extendUrlQuery({ activeId: -1 });
  };

  const handlePaginationChange = useCallback(
    (newPagination: Pagination) => {
      fetchSearchResult({ pagination: newPagination });
    },
    [fetchSearchResult]
  );
  const handlePermissionGroupClick = (id: number | null) => {
    extendUrlQuery({ activeId: id });
  };
  const exportPermissions = useCallback(async () => {
    showDownloadNotification();
    try {
      await startBackgroundExport(BackgroundType.Permissions);
      startFetching();
    } catch {
      // continue regardless of error
    }
  }, [startFetching]);
  const importPermissions = useCallback(() => {
    hiddenInput.current!.click();
  }, [hiddenInput]);

  const handlePermissionsPageAction = useCallback(
    (action: PermissionsPageAction, date?: Dates) => {
      switch (action) {
        case PermissionsPageAction.Export:
          exportPermissions();
          break;
        case PermissionsPageAction.Import:
          importPermissions();
          break;
        case PermissionsPageAction.ChangeDate:
          setActiveDate(date!);
          break;
      }
    },
    [exportPermissions, importPermissions]
  );
  const handleHiddenInput = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) {
        return;
      }

      const uploadedFile = event.target.files[0];
      showLoadingNotification('Your Permissions import has been initiated.');

      if (csvEnabled) startCsvFetching();

      event.target.value = '';
      try {
        await startBackgroundImport(
          BackgroundType.Permissions,
          uploadedFile,
          csvEnabled
        );
        dispatch(
          showProgressAlert(
            'Your Import to Permissions has been initiated. This may take a few minutes to complete.',
            BackgroundType.Permissions,
            BackgroundAction.Import
          )
        );
      } catch (e) {
        const message = getErrorMessage(e);
        const error = e as Error;
        logErrorCtx('Error in startBackgroundImport', {
          stackTrace: error.stack,
          error,
          description: message,
          component: 'PermissionPage',
        });
        return;
      }
    },
    [dispatch]
  );

  return (
    <>
      <PageWithAdvancedSearch
        detailCardColumns={permissionColumns(activeDate)}
        initialFormValues={initialPermisionFormValues}
        advancedSearchReduxActions={advancedSearchReduxActions}
        searchResult={searchResult}
        fetchSearchResult={fetchSearchResult}
        AdvancedSearchFieldsCmp={PermissionsAdvancedSearch}
        displayNameMap={displayNameMap}
        pageName="Permissions"
      >
        <PaperSlidingLayout shown={!!activeId}>
          <PermissionsSearchResults
            permissionGroups={searchResult.items}
            activeId={activeId}
            activeDate={activeDate}
            onPageAction={handlePermissionsPageAction}
            onPermissionGroupClick={handlePermissionGroupClick}
            onAddNewPress={handleAddNewPress}
            isLoading={isLoadingSearchResult}
            pagination={searchResult.pagination || initialPagination}
            onPaginationChange={handlePaginationChange}
          />
          <PermissionDetailsCard
            activeId={activeId}
            onClose={handleDetailsCardClose}
            fetchSearchResult={fetchSearchResult}
          />
        </PaperSlidingLayout>
      </PageWithAdvancedSearch>
      <input
        type="file"
        ref={hiddenInput}
        style={{ display: 'none' }}
        onChange={handleHiddenInput}
        accept=".csv"
      />
    </>
  );
};

PermissionsPage.route = Routes.PermissionsPage;

export default withSearchResults<PermissionGroup>(PermissionsPage, {
  url: '/v1/access_groups',
  dataAdapter: transformPermissionGroup,
  initialPagination,
  columns: permissionColumns(Dates.DateCreated),
  getSearches,
  fetchSearch,
  rehydrationThunks: [fetchUsers],
});
