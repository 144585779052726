import React from 'react';
import {
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Box,
  PaperProps,
} from '@mui/material';

import { ConfirmationModalProps } from './types';
import { useModalStyle } from './styled';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    body,
    onConfirmClicked,
    confirmLabel,
    onCancelClicked,
    cancelLabel,
    title,
    isLoading,
    DialogActionsComponent,
    maxWidth = 'xs',
    dataQa,
    showCloseIcon,
    confirmButtonRed,
    ...otherProps
  } = props;

  const classes = useModalStyle(props);

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onCancelClicked(event);
        }
      }}
      fullWidth={true}
      maxWidth={maxWidth}
      PaperProps={
        { ...otherProps['PaperProps'], 'data-qa': dataQa } as
          | Partial<PaperProps>
          | undefined
      }
      {...otherProps}
    >
      <DialogTitle className={classes.title}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignContent="center"
        >
          <Typography variant="h5" className={classes.titleText}>
            {title}
          </Typography>
          {showCloseIcon && (
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="medium"
              icon="FBOClose"
              aria-label="close"
              onClick={onCancelClicked}
              data-qa="close-icon"
              style={{ padding: 0 }}
            />
          )}
        </Box>
      </DialogTitle>
      {body && (
        <DialogContent className={classes.content}>
          <Box mb={2}>{body}</Box>
        </DialogContent>
      )}
      <DialogActions classes={{ spacing: classes.action }}>
        {DialogActionsComponent ? (
          <DialogActionsComponent />
        ) : (
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            marginLeft="24px"
            marginRight="24px"
          >
            <FBOButton
              variant="secondary"
              color="neutral"
              onClick={onCancelClicked}
              data-qa="confirmation-modal-cancel"
              style={{ width: '48%' }}
            >
              {cancelLabel ?? 'Cancel'}
            </FBOButton>
            <FBOButton
              variant="primary"
              color={confirmButtonRed ? 'negative' : 'positive'}
              size="medium"
              onClick={onConfirmClicked}
              loading={isLoading}
              data-qa="confirmation-modal-confirm"
              style={{ width: '48%' }}
            >
              {confirmLabel ?? 'Confirm'}
            </FBOButton>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};
