import _ from 'lodash';
import { ActiveUser } from 'services/user';
import { SellwareQueryParams } from './types';
import { getAuthSignature } from 'services/auth';

export const getSellwareUrl = async (
  activeUser: ActiveUser,
  companyName: string | null,
  register: boolean = false,
  reconnect: boolean = false
): Promise<string> => {
  const firstName = _.get(activeUser, 'user.firstName', '');
  const lastName = _.get(activeUser, 'user.lastName', '');
  const email = _.get(activeUser, 'user.email', '');

  const returnUrlQueryParam = () => {
    if (register) {
      return '?register=true';
    } else if (reconnect) {
      return '?reconnect=true';
    } else {
      return '';
    }
  };

  const companyNameParam = companyName ?? '';

  const getSignature = async (queryString: string) => {
    try {
      const signature = await getAuthSignature(queryString);
      return signature;
    } catch (e) {
      //ignore error as would be meaningless to user
    }
  };

  const queryParams: SellwareQueryParams = {
    id: 'fb-drive',
    email: encodeURIComponent(email),
    first_name: firstName,
    last_name: lastName,
    return_url:
      encodeURIComponent(
        `${process.env.REACT_APP_SELLWARE_RETURN_URL}${returnUrlQueryParam()}`
      ) || '',
    with_api: 1,
    company: encodeURIComponent(companyNameParam),
  };

  const sellwareUrl = process.env.REACT_APP_SELLWARE_REGISTER_URL || '';

  let queryString = '';

  if (queryParams) {
    for (const prop in queryParams) {
      queryString += `${prop}=${queryParams[prop]}&`;
    }

    queryString = queryString.substring(0, queryString.length - 1);
  }

  const signature = await getSignature(queryString);

  return `${sellwareUrl}?${queryString}&signature=${encodeURIComponent(
    signature
  )}`;
};
