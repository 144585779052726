import { createApiCall } from 'services/api';

import { MagentoChannelData } from './types';

export const connectMagento = async (
  data: MagentoChannelData
): Promise<boolean> => {
  const res = await createApiCall(
    {
      path: `/integrations/magento/connect?token=${data.token}&domain=${data.domain}`,
      method: 'GET',
    },
    {
      getMessage: {
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return res.data.success;
};
