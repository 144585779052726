import React, { useCallback, useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import moment from 'moment';

import {
  GeneratePaymentInfo,
  initialGeneratePaymentInfo,
} from 'services/salesOrders';
import { showNotification } from 'services/api';
import { Errors, validateYup } from 'services/forms/validation';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';

import { RequestPaymentModalProps } from './types';
import { yupGeneratePaymentSchema } from './validations';
import { Modal } from '../Modal';

const RequestPaymentModal: React.FC<RequestPaymentModalProps> = (props) => {
  const { isOpen, activeCustomer, onSave, onClose } = props;

  const [paymentInfo, setPaymentInfo] = useState<GeneratePaymentInfo>(
    initialGeneratePaymentInfo
  );
  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    if (isOpen) {
      setPaymentInfo(initialGeneratePaymentInfo);
    }
  }, [isOpen]);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentInfo((old) => ({
      ...old,
      amount: e.target.value ? parseFloat(e.target.value) : null,
    }));
  };

  const handleOnSave = useCallback(() => {
    if (!activeCustomer || !activeCustomer.email) {
      showNotification('Customer Email is required.', {
        variant: 'error',
      });
      return;
    }

    const isValid = validateYup(
      paymentInfo,
      yupGeneratePaymentSchema,
      setErrors
    );

    if (!isValid) {
      return;
    }

    onSave(paymentInfo);
    onClose();
  }, [onSave, onClose, paymentInfo, activeCustomer]);

  const handleDateChange = useCallback((value: any) => {
    const date = moment(value);

    setPaymentInfo((old) => ({
      ...old,
      dueDate: !value ? null : date.toDate(),
    }));
  }, []);

  return (
    <Modal
      open={isOpen}
      title="Request Payment"
      onApplyClicked={handleOnSave}
      onCancelClicked={onClose}
      withoutDefaultPadding
    >
      <Box p={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CurrencyField
              label="Amount"
              placeholder="Enter amount"
              name="amount"
              required
              value={paymentInfo.amount}
              onChange={handleAmountChange}
              error={!!errors.amount}
            />
          </Grid>
          <Grid item xs={12}>
            <DatePickerWrapper
              label="Due Date"
              name="dueDate"
              required
              minDate={moment().add(1, 'day').toDate()}
              value={paymentInfo.dueDate}
              onChange={handleDateChange}
              fullWidth
              error={!!errors.dueDate}
            />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default RequestPaymentModal;
