import { resolveImageUrl, transformDateToMomentDate } from 'helpers';
import { transformAddress } from 'services/addresses';
import { transformCarrier } from 'services/carriers';
import { transformCurrency } from 'services/currencies';
import { transformFob } from 'services/items';
import { transformLocation } from 'services/locations';
import { transformPaymentTerm } from 'services/paymentTerms';
import { transformOrderPriority } from 'services/settings/orderPriorities/transformations';

import { CompanySettings } from './types';

export const transformCompanySettings = (response: any): CompanySettings => {
  return {
    id: response.id,
    version: response.version,
    dateLastModified: response.dateLastModified,
    dateCreated: response.dateCreated,
    name: response.name,
    email: response.email,
    mainPhone: response.mainPhone,
    country: response.country,
    url: response.url,
    defaultAddressId: response.defaultAddressId,
    defaultAddress: response.defaultAddress
      ? transformAddress(response.defaultAddress)
      : null,
    imageUrl: response.imageUrl ? resolveImageUrl(response.imageUrl) : null,
    businessNumber: response.businessNumber,
    defaultLocationId: response.defaultLocationId,
    defaultLocation: response.defaultLocation
      ? transformLocation(response.defaultLocation)
      : null,
    timezone: response.timezone,
    taxJarToken: response.taxJarToken,
    tenant: response.tenant,
    purchasingTaxId: response.purchasingTaxId,
    purchasingTax: response.purchasingTax,
    taxExempt: response.taxExempt,
    taxExemptNumber: response.taxExemptNumber,
    useMultiCurrency: response.useMultiCurrency,
    displayCurrencyCodeForReports: response.displayCurrencyCodeForReports,
    homeCurrencyId: response.homeCurrencyId,
    homeCurrency: response.homeCurrency
      ? transformCurrency(response.homeCurrency)
      : null,
    defaultCarrierId: response.defaultCarrierId,
    defaultCarrier: response.defaultCarrier
      ? transformCarrier(response.defaultCarrier)
      : null,
    defaultPaymentTermId: response.defaultPaymentTermId,
    defaultPaymentTerm: response.defaultPaymentTerm
      ? transformPaymentTerm(response.defaultPaymentTerm)
      : null,
    defaultShippingTermId: response.defaultShippingTermId,
    defaultShippingTerm: response.defaultShippingTerm,
    defaultFobPointId: response.defaultFobPointId,
    defaultFobPoint: response.defaultFobPoint
      ? transformFob(response.defaultFobPoint)
      : null,
    defaultPriorityId: response.defaultPriorityId,
    defaultPriority: response.defaultPriority
      ? transformOrderPriority(response.defaultPriority)
      : null,
    defaultWeightUom: response.defaultWeightUom,
    defaultDimensionUom: response.defaultDimensionUom,
    expirationDate: response.expirationDate,
    maxUsers: response.maxUsers,
    googleAccessToken: response.googleAccessToken,
    googleRefreshToken: response.googleRefreshToken,
    accountingMethod: response.accountingMethod,
    goLiveDate: transformDateToMomentDate(response.goLiveDate),
    crmAssociations: response.crmAssociations,
  };
};

export const transformCompanyPut = (companySettings: CompanySettings) => {
  return {
    id: companySettings.id,
    version: companySettings.version,
    dateLastModified: companySettings.dateLastModified,
    dateCreated: companySettings.dateCreated,
    name: companySettings.name,
    email: companySettings.email,
    mainPhone: companySettings.mainPhone,
    country: companySettings.country,
    url: companySettings.url,
    defaultAddressId: 1,
    imageUrl: companySettings.imageUrl,
    businessNumber: companySettings.businessNumber,
    defaultLocationId: companySettings.defaultLocationId,
    timezone: companySettings.timezone,
    purchasingTaxId: null,
    taxExempt: companySettings.taxExempt,
    taxExemptNumber: companySettings.taxExemptNumber,
    useMultiCurrency: companySettings.useMultiCurrency,
    displayCurrencyCodeForReports:
      companySettings.displayCurrencyCodeForReports,
    homeCurrencyId: companySettings.homeCurrencyId,
    homeCurrency: companySettings.homeCurrency,
    defaultCarrierId: companySettings.defaultCarrierId,
    defaultPaymentTermId: companySettings.defaultPaymentTermId,
    defaultShippingTermId: companySettings.defaultShippingTermId,
    defaultFobPointId: companySettings.defaultFobPointId,
    defaultPriorityId: companySettings.defaultPriorityId,
    defaultWeightUom: companySettings.defaultWeightUom,
    defaultDimensionUom: companySettings.defaultDimensionUom,
    googleAccessToken: companySettings.googleAccessToken,
    googleRefreshToken: companySettings.googleRefreshToken,
    accountingMethod: companySettings.accountingMethod,
    goLiveDate: companySettings.goLiveDate,
  };
};
