import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Modal } from 'ui/components/Modal/Modal';
import { updateAverageCost } from 'services/items';
import { roundToDecimals } from 'helpers';
import { Errors, validateYup } from 'services/forms/validation';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';
import { AverageCostModalData, AverageCostModalProps } from './types';
import { AverageCostSchema } from './validations';
import { initialAverageCostModalData } from './consts';
import { logErrorCtx } from 'app/logging';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const AverageCostModal: React.FC<AverageCostModalProps> = ({
  isOpen,
  setIsOpen,
  item,
  averageCost,
  setItem,
  triggerFetchInventory,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [averageCostData, setAverageCostData] = useState<AverageCostModalData>(
    initialAverageCostModalData
  );
  const [errors, setErrors] = useState<Errors>({});

  const totalCost = useMemo(
    () =>
      roundToDecimals(
        (averageCostData.newAverageCost || 0) * item.totalQty!,
        2
      ),
    [averageCostData, item]
  );
  useEffect(() => {
    setAverageCostData((old) => ({ ...old, newAverageCost: averageCost }));
    !isOpen && setErrors({});
  }, [averageCost, isOpen]);

  const handleCostChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setAverageCostData((old) => ({
      ...old,
      newAverageCost: parseFloat(e.target.value) || 0,
    }));
  };
  const handleSaveClicked = useCallback(() => {
    if (!validateYup(averageCostData, AverageCostSchema, setErrors)) {
      return;
    }

    setIsLoading(true);
    (async () => {
      try {
        await updateAverageCost(averageCostData.newAverageCost!, item.id!);
        setItem((old) => ({
          ...old,
          cost: averageCostData.newAverageCost,
        }));
        triggerFetchInventory?.();
      } catch (err) {
        const error = err as Error;
        logErrorCtx('Failed to Update Average Cost', {
          error,
          stackTrace: error.stack,
          title: 'Average Cost was not Updated',
          description:
            'Failed to Update the Average Cost from an ItemDetailsCard',
          component: 'AverageCostModal',
        });
      }
      setIsOpen(false);
      setIsLoading(false);
    })();
  }, [averageCostData, item, setIsOpen, setItem]);

  const handleCancelClicked = () => {
    setIsOpen(false);
    setAverageCostData((old) => ({ ...old, newAverageCost: averageCost }));
  };

  const fboActionComponents = () => (
    <Box display="flex" justifyContent="flex-end" width="100%">
      <FBOButton
        variant="secondary"
        color="positive"
        size="medium"
        onClick={handleCancelClicked}
        data-qa="average-cost-modal-cancel-btn"
      >
        Cancel
      </FBOButton>
      <Box ml={2}>
        <FBOButton
          variant="primary"
          color="positive"
          onClick={handleSaveClicked}
          data-qa="average-cost-modal-save-btn"
        >
          Save
        </FBOButton>
      </Box>
    </Box>
  );

  return (
    <Modal
      open={isOpen}
      title="Modify Average Cost"
      maxWidth="xs"
      onCancelClicked={handleCancelClicked}
      isLoadingContent={isLoading}
      dataQa="average-cost-modal"
      ModalActionsComponent={fboActionComponents}
    >
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Typography variant="body2" color="textPrimary">
            Quantity on hand: {item.totalQty}
          </Typography>
        </Grid>
        <Grid item md={12}>
          <CurrencyField
            decimalPlaces={5}
            value={averageCostData.newAverageCost}
            label="Average cost"
            placeholder="Enter cost"
            autoComplete="nope"
            name="averageCost"
            onChange={handleCostChange}
            error={!!errors.newAverageCost}
            data-qa="average-cost-modal-average-cost-field"
          />
        </Grid>
        <Grid item md={12}>
          <CurrencyField
            label="Total cost"
            name="totalCost"
            value={totalCost}
            disabled
            data-qa="average-cost-modal-total-cost-field"
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default AverageCostModal;
