import { INITIAL_API_STATUS } from 'services/api';

import { PaymentIntegration } from './types';

export const paymentIntegrationHostname = process.env.REACT_APP_PAYMENTS_URL;

export const initialPaymentIntegration: PaymentIntegration = {
  _status: INITIAL_API_STATUS,
  connections: [],
};
