import React, { memo, useCallback, useMemo } from 'react';
import { TableCell, Typography, Link } from '@mui/material';
import _ from 'lodash';

import { Column } from 'ui/components/Table/ItemsTable/types';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import { TextField } from 'ui/components/TextField/TextField';
import { TrackingType, TrackingDataTypes } from 'services/settings/tracking';
import { EACH_UOM_ID } from 'services/uoms';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';

import { TrackingColumnsProps } from '../../types';
import { calculateSerialLinkLabel } from './helpers';
import { getTrackingTypeColumns } from '../../helpers';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import moment from 'moment';
import { TrackingInfo } from 'services/inventory';
import { useTrackingRemoveCells } from './styled';
import { formatDatePreview, useGetIntlDateFormatString } from 'helpers';
import { getSettingsCompanyCountry } from 'services/settings/company';
import { useSelector } from 'react-redux';

const TrackingRemoveCells: React.FC<TrackingColumnsProps> = (props) => {
  const {
    trackingGroup,
    onAmountChange,
    onSerialLinkClick,
    createLotChangeHandler,
    createDateChangeHandler,
    columns,
    rowErrors,
    itemUom,
    inventoryUom,
    isDecimal,
  } = props;
  const classes = useTrackingRemoveCells();
  const intlFormatDate = useGetIntlDateFormatString();
  const companyCountry = useSelector(getSettingsCompanyCountry);
  const trackingTypeColumns = useMemo(
    () => getTrackingTypeColumns(columns),
    [columns]
  );

  const handleLinkClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      onSerialLinkClick(e);
    },
    [onSerialLinkClick]
  );

  const renderTrackingColumn = (column: Column): JSX.Element | null => {
    const trackingDataType: TrackingDataTypes = _.get(
      column,
      'meta.trackingDataType'
    );
    const trackingType: TrackingType = _.get(column, 'meta.trackingType', {});
    const trackingTypeIndex = trackingGroup.trackingInfoList.findIndex(
      (t) => t.trackingTypeId === trackingType.id
    );
    const trackingInfo = trackingGroup.trackingInfoList[trackingTypeIndex];
    const isTrackingInfoNew = trackingInfo && trackingInfo.id === -1;
    const quantity = trackingGroup.quantity || 0;
    const serialLinkLabel = calculateSerialLinkLabel(
      quantity - trackingGroup.serialIds.length
    );

    const isPickItemExpired = (trackingInfo: TrackingInfo) => {
      const today = moment().startOf('day');
      const value = trackingInfo?.value;
      return moment(value).isBefore(today);
    };
    const WarningIcon = () => {
      return <ErrorOutlineIcon className={classes.warningIcon} />;
    };
    switch (trackingDataType) {
      case TrackingDataTypes.Text:
        return isTrackingInfoNew ? (
          <TextField
            onChange={createLotChangeHandler(trackingTypeIndex)}
            value={trackingInfo.value}
          />
        ) : (
          <Typography>{trackingInfo ? trackingInfo.value : null}</Typography>
        );
      case TrackingDataTypes.Date: {
        const formattedDateValue = formatDatePreview(
          trackingInfo?.value,
          companyCountry,
          intlFormatDate
        );
        return (
          <span className={classes.centeredFlex}>
            {isTrackingInfoNew ? (
              <DatePickerWrapper
                onChange={createDateChangeHandler(trackingTypeIndex)}
                value={trackingInfo.value}
                fullWidth
              />
            ) : (
              <Typography className={classes.spacing}>
                {formattedDateValue}
              </Typography>
            )}
            {isPickItemExpired(trackingInfo) && <WarningIcon />}
          </span>
        );
      }
      case TrackingDataTypes.SerialNumber:
        return quantity ? (
          <Link
            href="#"
            underline="always"
            onClick={handleLinkClick}
            color={
              quantity - trackingGroup.serialIds.length !== 0
                ? 'error'
                : 'primary'
            }
          >
            {serialLinkLabel}
          </Link>
        ) : null;
      default:
        return null;
    }
  };

  return (
    <>
      <TableCell>
        <Typography>
          {trackingGroup.onHand - trackingGroup.committedQuantity}{' '}
          {` ${inventoryUom ? inventoryUom.abbreviation : ''}`}
        </Typography>
      </TableCell>
      <TableCell>
        <TextFieldQuantity
          selectedUomId={itemUom ? itemUom.id! : EACH_UOM_ID}
          uoms={itemUom ? [itemUom] : []}
          value={trackingGroup.quantity}
          onTextChange={onAmountChange}
          error={!!rowErrors.quantity}
          isDecimal={isDecimal}
          label={rowErrors.quantity}
          noMargin
        />
      </TableCell>
      {trackingTypeColumns.map((column: Column, index: number) => (
        <TableCell key={index}>{renderTrackingColumn(column)}</TableCell>
      ))}
    </>
  );
};

export default memo(TrackingRemoveCells);
