import React, { useCallback, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import smoothscroll from 'smoothscroll-polyfill';

import { APP_BAR_HEIGHT } from 'app/components/AppBar';
import { hasPermission } from 'services/permissions';
import { getActiveUser } from 'services/user';
import { FBOSearchInput } from 'ui/components/SearchHeader/styled';

import { useCardNavigationStyle } from './styled';
import { cardNavigationDescriptor } from './consts';
import { CardNavigationProps } from './types';
import { icons } from 'ui/theme';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const CardNavigation: React.FC<CardNavigationProps> = (props) => {
  const classes = useCardNavigationStyle(props);
  const { search, setSearch } = props;

  const { permissions: userPermissions, isAdmin } = useSelector(getActiveUser);

  smoothscroll.polyfill();

  const regex = useMemo(() => {
    return RegExp(_.escapeRegExp(search), 'gi');
  }, [search]);

  const filteredData = useMemo(() => {
    return cardNavigationDescriptor.filter(
      (i) =>
        regex.test(i.title) || i.rowDescriptor.some((t) => regex.test(t.title))
    );
  }, [regex]);

  const scrollToModuleId = useCallback((moduleId: string) => {
    const moduleCard = document.getElementById(moduleId);

    if (!moduleCard) {
      return;
    }

    const scrollOffset = APP_BAR_HEIGHT + 24;
    const topOfElement = moduleCard.offsetTop - scrollOffset;

    const root = document.getElementById('root');
    if (root) root.scroll({ top: topOfElement, behavior: 'smooth' });
  }, []);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  return (
    <Box className={classes.navigation}>
      <FBOSearchInput
        type="text"
        placeholder="Search Preferences"
        value={search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <icons.MagnifyingGlass />
            </InputAdornment>
          ),
        }}
        onChange={handleSearchChange}
        sx={{ marginBottom: '20px' }}
      />

      {filteredData.map((descriptor, i) => {
        const { title, id, permissions, iconName } = descriptor;

        if (!hasPermission(permissions || [], userPermissions, isAdmin)) {
          return null;
        }

        return (
          <React.Fragment key={`fragment-${i}`}>
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="medium"
              key={`box-${i}`}
              icon={iconName}
              onClick={() => scrollToModuleId(id)}
              data-qa={`${title}-preferences-navigation-button`}
              style={{ padding: '8px' }}
            >
              {title}
            </FBOButton>
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default memo(CardNavigation);
