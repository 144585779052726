import { QbdAccount } from './types';

export const transformQbdAccount = (res: any): QbdAccount => {
  return {
    id: res.id,
    accountType: res.accountType,
    description: res.description,
    fullName: res.fullName,
    listId: res.listId,
    name: res.name,
  };
};
