import React, {
  memo,
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Box } from '@mui/material';

import { replaceValueInCollection } from 'helpers';
import { Item, ItemType } from 'services/items';
import { EACH_UOM_ID, getUoms, Uom } from 'services/uoms';
import {
  fulfillPurchaseOrderItem,
  initialPurchaseOrderItem,
  PurchaseOrderItem,
  PurchaseOrderItemTypes,
  PurchaseOrderStatus,
} from 'services/purchaseOrders';
import { showAlert } from 'services/alert/redux';
import { itemHasSerialTracking } from 'services/inventory';
import { TextFieldQuantity } from 'ui/components/TextField/TextFieldQuantity';
import { FBOButtonWithActions } from 'ui/components/Button/ButtonWithActions';
import {
  ItemsTable,
  RenderCustomRowProps,
} from 'ui/components/Table/ItemsTable';
import { activeUserHasPermission } from 'services/user/redux';

import { calculatePriceBasedOnUomChange } from 'services/salesOrders';
import { filterItemUoms } from 'services/items/helpers';
import { getSettingsCompany } from 'services/settings/company';
import { getTaxRates } from 'services/taxRates';

import { PurchaseOrderItemsProps, ThreeDotMenuActions } from './types';
import { usePurchaseOrderItemsStyle } from './styled';
import { ITEM_COLUMNS, purchaseItemRenderMap } from './consts';
import {
  createNewPurchaseOrderItem,
  createOptionsForItemSelected,
  createOptionsForItemNotSelected,
  findVendorItemName,
  duplicatePoItem,
  moveDownPoItem,
  moveUpPoItem,
  removePoItem,
  duplicatePoItemToCreditReturn,
} from './helpers';
import { TableFooter } from './components';
import { editPurchaseOrderPermissions } from '../../helpers';
import { PurchaseOrderItemEditModal } from './components/PurchaseOrderItemEditModal';
import PurchaseOrderItemsAsyncAutocomplete from 'ui/components/Autocomplete/ItemsAutocomplete/PurchaseOrderItemsAsyncAutocomplete';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import { colorPalette } from 'ui/theme';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import {
  getSettingsPurchaseOrder,
  fetchSettingsPurchaseOrders,
} from 'services/settings/purchaseOrders';

const RenderCustomRow: React.FC<RenderCustomRowProps<PurchaseOrderItem>> = memo(
  (props) => {
    const { row } = props;
    const ResolvedFunction = purchaseItemRenderMap[row.purchaseOrderItemType];
    return <ResolvedFunction {...(props as any)} />;
  }
);

const PurchaseOrderItems: React.FC<PurchaseOrderItemsProps> = (props) => {
  const {
    purchaseOrder,
    setPurchaseOrder,
    validationErrors,
    rowValidationErrors,
    poSettings,
    oldState,
  } = props;

  const dispatch = useDispatch();

  const classes = usePurchaseOrderItemsStyle();

  const { items: defaultUoms } = useSelector(getUoms);
  const { homeCurrency, useMultiCurrency } = useSelector(getSettingsCompany);
  const homeCurrencyCode = (homeCurrency && homeCurrency.code) || 'USD';
  const { items: taxRates } = useSelector(getTaxRates);
  const [activePurchaseOrderItem, setActivePurchaseOrderItem] =
    useState<PurchaseOrderItem>(initialPurchaseOrderItem);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);
  const [amount, setAmount] = useState<number | null>(null);
  const [selectedUomId, setSelectedUomId] = useState<number>(EACH_UOM_ID);
  const [selectedSerials, setSelectedSerials] = useState<number[]>([]);
  const [showPurchaseOrderItemEditModal, setShowPurchaseOrderItemEditModal] =
    useState(false);
  const [uoms, setUoms] = useState<Uom[]>([]);
  const [checkModalVisible, setCheckModalVisible] = useState(false);

  const selectedItemType = _.get(selectedItem, 'itemType', null);

  const inputElement = useRef<HTMLInputElement>(null);

  const editPermission = editPurchaseOrderPermissions(purchaseOrder);
  const canEditPoItems = useSelector(activeUserHasPermission(editPermission));
  const purchaseSettingsCopy = useSelector(getSettingsPurchaseOrder);
  const selectedTaxRate = useMemo(() => {
    return taxRates.find((r) => r.id === purchaseOrder.purchaseTaxId) || null;
  }, [purchaseOrder.purchaseTaxId, taxRates]);

  const activeMulticurrencyCode = useMemo(
    () => _.get(purchaseOrder, 'vendor.currency.code', null),
    [purchaseOrder]
  );
  useEffect(() => {
    // @ts-ignore
    dispatch(fetchSettingsPurchaseOrders());
  }, []);

  const showMultiCurrency = useMemo(
    () =>
      useMultiCurrency &&
      activeMulticurrencyCode &&
      activeMulticurrencyCode !== homeCurrencyCode,
    [useMultiCurrency, activeMulticurrencyCode]
  );

  const lastNonDeletedPoItemIndex = useMemo(
    () =>
      purchaseOrder.purchaseOrderItemList.filter((i) => !i.deleted).length - 1,
    [purchaseOrder.purchaseOrderItemList]
  );

  const isSerialTracked = useMemo(() => {
    if (selectedItem) {
      return itemHasSerialTracking(selectedItem);
    }
    return false;
  }, [selectedItem]);

  const resolvedVendorItemName = useMemo(() => {
    if (!selectedItem || !purchaseOrder.vendorId) {
      return '';
    }

    return (
      findVendorItemName(purchaseOrder.vendorId, selectedItem.vendorItems) || ''
    );
  }, [purchaseOrder.vendorId, selectedItem]);

  // reset state when on purchase order id change
  useEffect(() => {
    setSelectedItem(null);
    setAmount(null);
    setSelectedUomId(EACH_UOM_ID);
    setSelectedSerials([]);
  }, [purchaseOrder.id]);

  useEffect(() => {
    if (selectedItem) {
      const itemUoms = filterItemUoms(selectedItem.defaultUom, defaultUoms);
      if (itemUoms) {
        setUoms(itemUoms);
      }
      return;
    }

    setUoms([]);
  }, [selectedItem, defaultUoms]);

  const setPurchaseOrderItems = useCallback(
    (purchaseOrderItems: React.SetStateAction<PurchaseOrderItem[]>) => {
      if (typeof purchaseOrderItems === 'function') {
        setPurchaseOrder((p) => ({
          ...p,
          purchaseOrderItemList: purchaseOrderItems(p.purchaseOrderItemList),
        }));
        return;
      }

      setPurchaseOrder((p) => ({
        ...p,
        purchaseOrderItemList: purchaseOrderItems,
      }));
    },
    [setPurchaseOrder]
  );

  const deleteClicked = useCallback(() => {
    const newPoItems: PurchaseOrderItem[] =
      purchaseOrder.purchaseOrderItemList.map((poi) => {
        if (selectedSerials.includes(poi.id!)) {
          return { ...poi, deleted: true };
        }
        return poi;
      });
    const newRemoved: PurchaseOrderItem[] = newPoItems.filter((poi) => {
      return !((poi.id || 0) < 0 && poi.deleted);
    });

    setPurchaseOrderItems(newRemoved);
    setSelectedSerials([]);
  }, [
    selectedSerials,
    setPurchaseOrderItems,
    purchaseOrder.purchaseOrderItemList,
  ]);

  const beforeElementsRestyle = () => {
    return (
      <FBOButton
        key={1}
        style={{ marginLeft: '8px' }}
        variant="secondary"
        color="negative"
        size="medium"
        icon="TrashCan"
        permissions={editPermission}
        onClick={deleteClicked}
        data-qa="purchase-order-table-header-delete"
      >
        Delete
      </FBOButton>
    );
  };

  const beforeElements = useMemo(
    () => (selectedSerials.length ? [beforeElementsRestyle()] : []),
    [selectedSerials, deleteClicked, editPermission]
  );

  const handleSelectClick = useCallback(
    (id: number | number[]) => {
      if (purchaseOrder.status === PurchaseOrderStatus.Fulfilled) {
        return;
      }
      if (Array.isArray(id)) {
        setSelectedSerials(id);
        return;
      }

      const isIdAlreadySelected = selectedSerials.includes(id);
      if (!isIdAlreadySelected) {
        setSelectedSerials([...selectedSerials, id]);
        return;
      }

      setSelectedSerials(_.without(selectedSerials, id));
    },
    [selectedSerials, setSelectedSerials, purchaseOrder.status]
  );

  const handleFocus = useCallback(() => {
    // we are using timeout because focus is not working when clicking on dropdown item
    setTimeout(() => {
      if (inputElement.current !== null) {
        inputElement.current.focus();
      }
    }, 100);
  }, []);

  const addItem = useCallback(
    (purchaseOrderItemType: PurchaseOrderItemTypes) => {
      const newUom = uoms.find((u) => u.id === selectedUomId)!;
      const oldUom = uoms.find(
        (u) =>
          u.id ===
          (selectedItem
            ? selectedItem.defaultUomId || EACH_UOM_ID
            : EACH_UOM_ID)
      )!;
      const newCost = calculatePriceBasedOnUomChange(
        newUom,
        oldUom,
        selectedItem ? selectedItem.cost || 0 : 0,
        selectedItem ? selectedItem.itemUomConversionList : []
      );
      const newPurchaseOrderItem = createNewPurchaseOrderItem(
        purchaseOrder.vendorId,
        purchaseOrderItemType,
        selectedItem,
        purchaseOrder.purchaseOrderItemList,
        selectedUomId,
        amount,
        resolvedVendorItemName,
        newCost,
        purchaseOrder.exchangeRate,
        selectedTaxRate,
        taxRates,
        poSettings.costIncludesTax ? poSettings.costIncludesTax : false
      );
      setPurchaseOrderItems((poi) => [...poi, newPurchaseOrderItem]);
      setSelectedItem(null);
      setAmount(null);
      setSelectedUomId(EACH_UOM_ID);
      handleFocus();
    },
    [
      setPurchaseOrderItems,
      amount,
      selectedItem,
      selectedUomId,
      purchaseOrder.purchaseOrderItemList,
      resolvedVendorItemName,
      handleFocus,
      uoms,
      purchaseOrder.exchangeRate,
      selectedTaxRate,
      taxRates,
      poSettings.costIncludesTax,
    ]
  );

  const handleAddItemClicked = useCallback(
    (purchaseOrderItemType: PurchaseOrderItemTypes) => {
      const vendorItem = _.find(selectedItem?.vendorItems, {
        vendorId: purchaseOrder.vendorId,
      });

      if (
        amount &&
        vendorItem?.minimumQuantity &&
        amount < vendorItem.minimumQuantity
      ) {
        setCheckModalVisible(true);
        return;
      }
      addItem(purchaseOrderItemType);
    },
    [selectedItem, amount, addItem]
  );

  const fulfillItem = useCallback(
    async (poItem: PurchaseOrderItem) => {
      if (!purchaseOrder.id || !poItem.id) {
        return;
      }

      const resPurchaseOrder = await fulfillPurchaseOrderItem(
        purchaseOrder.id,
        poItem.id
      );
      oldState.current = resPurchaseOrder;
      setPurchaseOrder(resPurchaseOrder);
    },
    [purchaseOrder.id, setPurchaseOrder, oldState]
  );

  const threeDotMenuClick = useCallback(
    (data: { type: ThreeDotMenuActions; payload: PurchaseOrderItem }) => {
      switch (data.type) {
        case ThreeDotMenuActions.Duplicate:
          setPurchaseOrderItems(
            duplicatePoItem(data.payload, purchaseOrder.purchaseOrderItemList)
          );
          break;
        case ThreeDotMenuActions.DuplicateToCreditReturn:
          setPurchaseOrderItems(
            duplicatePoItemToCreditReturn(
              data.payload,
              purchaseOrder.purchaseOrderItemList
            )
          );
          break;
        case ThreeDotMenuActions.MoveDown: {
          setPurchaseOrderItems(
            moveDownPoItem(data.payload, purchaseOrder.purchaseOrderItemList)
          );
          break;
        }
        case ThreeDotMenuActions.MoveUp: {
          setPurchaseOrderItems(
            moveUpPoItem(data.payload, purchaseOrder.purchaseOrderItemList)
          );
          break;
        }
        case ThreeDotMenuActions.Remove: {
          setPurchaseOrderItems(
            removePoItem(data.payload, purchaseOrder.purchaseOrderItemList)
          );
          break;
        }
        case ThreeDotMenuActions.FulFill:
          fulfillItem(data.payload);
          break;
      }
    },
    [setPurchaseOrderItems, purchaseOrder.purchaseOrderItemList, fulfillItem]
  );

  const handleAutocompleteChange = useCallback(
    (item: Item | null) => {
      setSelectedItem(item);
      if (!item) {
        setAmount(null);
        return;
      }
      setAmount(1);
      setSelectedUomId(item.defaultUomId!);

      if (item.alertNotes) {
        dispatch(showAlert(item.alertNotes));
      }
    },
    [dispatch]
  );

  const handleAmountTextChange = useCallback(
    (value: number | null) => {
      setAmount(value);
    },
    [setAmount]
  );

  const handleAmountMenuChange = useCallback(
    (nextUomId: number) => {
      setSelectedUomId(nextUomId);
    },
    [setSelectedUomId]
  );

  const handleAutocompleteEnterClick = useCallback(() => {
    handleAddItemClicked(PurchaseOrderItemTypes.Purchase);
  }, [handleAddItemClicked]);

  const handleQuantityKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLDivElement>
  ) => {
    // if enter clicked and all fields are filled, add sale item
    if (e.keyCode === 13 && amount && selectedUomId && selectedItem) {
      handleAddItemClicked(PurchaseOrderItemTypes.Purchase);
    }
  };

  const handleItemClick = useCallback(
    (id: number) => {
      const purchaseItem = purchaseOrder.purchaseOrderItemList.find(
        (i) => i.id === id
      );

      if (!purchaseItem) {
        return;
      }

      setActivePurchaseOrderItem(purchaseItem);
      setShowPurchaseOrderItemEditModal(true);
    },
    [purchaseOrder.purchaseOrderItemList]
  );

  const handleEditModalSaveClicked = useCallback(() => {
    const index = purchaseOrder.purchaseOrderItemList.findIndex(
      (s) => s.id === activePurchaseOrderItem.id
    );

    setPurchaseOrder((old) => ({
      ...old,
      purchaseOrderItemList: replaceValueInCollection(
        purchaseOrder.purchaseOrderItemList,
        activePurchaseOrderItem,
        index
      )!,
    }));

    setShowPurchaseOrderItemEditModal(false);
  }, [activePurchaseOrderItem, purchaseOrder, setPurchaseOrder]);

  const yesClicked = useCallback(() => {
    setTimeout(() => setCheckModalVisible(false), 500);
    addItem(PurchaseOrderItemTypes.Purchase);
  }, [addItem]);

  return (
    <Box
      display="flex"
      flexGrow={1}
      flexDirection="column"
      height="70%"
      overflow={'hidden'}
      sx={{
        borderTop: `1px solid ${colorPalette.redesign.background3}`,
      }}
    >
      <Box paddingLeft={2} paddingRight={2}>
        <FBOTitleBar title="Items" beforeElements={beforeElements} noPadding>
          {purchaseOrder.status !== PurchaseOrderStatus.Fulfilled &&
            canEditPoItems && (
              <>
                <PurchaseOrderItemsAsyncAutocomplete
                  sx={{ marginLeft: '8px' }}
                  purchaseOrder={purchaseOrder}
                  onChange={handleAutocompleteChange}
                  value={selectedItem}
                  placeholder="Enter item name, description, or SKU"
                  onEnterClick={handleAutocompleteEnterClick}
                  dataQa="purchase-order-pickItem"
                  onlyShowVendorPartsInThePartDropDownFilter={
                    purchaseSettingsCopy.onlyShowVendorPartsInThePartDropDownFilter
                  }
                />

                <TextFieldQuantity
                  sx={{ marginRight: '8px' }}
                  placeholder="Quantity"
                  name="amount"
                  className={classes.amountInputOuter}
                  value={amount}
                  selectedUomId={selectedUomId}
                  onTextChange={handleAmountTextChange}
                  onMenuChange={handleAmountMenuChange}
                  additionalInputProps={{
                    classes: {
                      root: classes.amountInputInner,
                      notchedOutline: classes.noBorder,
                    },
                  }}
                  fullWidth={false}
                  uoms={uoms}
                  isUomSelectDisabled={selectedItemType === ItemType.Shipping}
                  isDecimal={!isSerialTracked}
                  dataQa="purchase-order-quantity"
                  onKeyDown={handleQuantityKeyDown}
                />
                <FBOButtonWithActions
                  buttonDisabled={!selectedItem}
                  actionItems={
                    !!selectedItem
                      ? createOptionsForItemSelected(handleAddItemClicked)
                      : createOptionsForItemNotSelected(handleAddItemClicked)
                  }
                  text="Add Item"
                  onClick={() =>
                    handleAddItemClicked(PurchaseOrderItemTypes.Purchase)
                  }
                  dataQa="purchase-order-item"
                />
              </>
            )}
        </FBOTitleBar>
      </Box>
      <ItemsTable
        data={purchaseOrder.purchaseOrderItemList}
        setData={setPurchaseOrderItems}
        onAction={threeDotMenuClick}
        columns={ITEM_COLUMNS(
          showMultiCurrency,
          activeMulticurrencyCode,
          homeCurrencyCode
        )}
        onItemClick={handleItemClick}
        onSelectedChange={handleSelectClick}
        selectedItems={selectedSerials}
        RenderCustomRow={RenderCustomRow}
        filterRows={(poItem) => !poItem.deleted}
        rowErrors={rowValidationErrors}
        emptyTableText="ADD NEW ITEMS BY PRESSING 'ADD ITEM'"
        RenderCustomFooter={TableFooter}
        tableLayoutFixed={!showMultiCurrency}
        footerData={{
          purchaseOrder,
          validationErrors,
          activeMulticurrencyCode,
        }}
        onFooterAction={{ setPurchaseOrder }}
        meta={{
          purchaseOrder,
          lastNonDeletedPoItemIndex,
          activeMulticurrencyCode,
        }}
        dataQa="purchase-order-items-table"
        getRowclsx={(row: PurchaseOrderItem) => ({
          [classes.creditReturn]:
            row.purchaseOrderItemType === PurchaseOrderItemTypes.CreditReturn ||
            row.purchaseOrderItemType === PurchaseOrderItemTypes.MiscCredit,
        })}
      />
      <PurchaseOrderItemEditModal
        show={showPurchaseOrderItemEditModal}
        onSave={handleEditModalSaveClicked}
        onClose={() => setShowPurchaseOrderItemEditModal(false)}
        activePurchaseOrderItem={activePurchaseOrderItem}
        setActivePurchaseOrderItem={setActivePurchaseOrderItem}
        purchaseOrderStatus={purchaseOrder.status}
        exchangeRate={purchaseOrder.exchangeRate}
        activeMulticurrencyCode={activeMulticurrencyCode}
      />
      <ConfirmationModal
        open={checkModalVisible}
        title="Order does not meet vendor minimum requirement"
        body={'Do you still want to continue?'}
        onCancelClicked={() => setCheckModalVisible(false)}
        onConfirmClicked={yesClicked}
        confirmLabel="Yes"
        cancelLabel="Cancel"
      />
    </Box>
  );
};

export default memo(PurchaseOrderItems);
