import { createApiCall } from 'services/api';

import { transformToRequestRegister } from './transformations';
import { CreateAccountFormValues, LoginFormValues } from './types';
import { authenticationApi } from 'services/api/config';

export const logIn = async (
  formValues: LoginFormValues
): Promise<{ refreshToken: string; token: string }> => {
  const resp = await createApiCall(
    {
      hostname: `${process.env.REACT_APP_AUTHENTICATION_URL}`,
      path: '/v2/auth/authenticate/',
      method: 'POST',
      body: {
        username: formValues.email,
        password: formValues.password,
      },
    },
    {
      getMessage: {
        success: () => 'You logged in successfully, welcome.',
        error: (error: any) => `${error.response.data.error.errorMessage}`,
      },
    }
  )();

  return {
    refreshToken: resp.data.data.refreshToken,
    token: resp.data.data.token,
  };
};

export const registerUser = async (
  formValues: CreateAccountFormValues
): Promise<void> => {
  await createApiCall(
    {
      path: '/v1/auth/register',
      method: 'POST',
      body: transformToRequestRegister(formValues),
    },
    {
      getMessage: {
        success: () => 'You created user successfully, please login.',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};

export const requestPasswordChange = async (email: string): Promise<void> => {
  await createApiCall(
    {
      path: '/v1/auth/register/reset_password',
      method: 'POST',
      body: { email },
    },
    {
      getMessage: {
        success: () => 'An email has been sent to you.',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};

export const changePassword = async (formValues: {
  email: string;
  verificationCode: string;
  password: string;
}): Promise<void> => {
  await createApiCall(
    {
      path: '/v1/auth/register/reset_password_finalize',
      method: 'POST',
      body: formValues,
    },
    {
      getMessage: {
        success: () => 'You changed password successfully, please login.',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};

export const getAuthSignature = async (queryString: string) => {
  const response = await authenticationApi({
    url: `/v2/sign`,
    method: 'POST',
    data: {
      queryString: queryString,
    },
  });

  return response.data.signature;
};
