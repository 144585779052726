import React, { useCallback, useMemo } from 'react';

import { Pagination } from 'services/search';
import {
  ItemsTable,
  useSelectedItemsChanges,
} from 'ui/components/Table/ItemsTable';
import { arrayHasAllDeletedObjects } from 'helpers';

import { Tooltip } from '@mui/material';
import { PermissionType } from 'services/permissions';
import { PurchaseOrderStatus } from 'services/purchaseOrders';

import {
  PURCHASE_ORDER_COLUMNS,
  PURCHASE_ORDER_DETAILS_COLUMNS,
  createPOSearchActionBarOptions,
} from './consts';
import { PurchaseOrderSearchResultsProps } from './types';
import PurchaseOrderRow from './PurchaseOrderRow';
import PurchaseOrderRowSmall from './PurchaseOrderRowSmall';
import FBOTitleBar from 'ui/theme/components/FBOTitleBar/FBOTitleBar';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const PurchaseOrderSearchResults: React.FC<PurchaseOrderSearchResultsProps> = (
  props
) => {
  const {
    purchaseOrders,
    activePurchaseOrderId,
    handlePurchaseOrderClick,
    onAddNewPress,
    isLoadingPurchaseOrders,
    pagination,
    onPaginationChange,
    onAction,
    selectedItems,
    activeDate,
    setSelectedItems,
    onUnissueClicked,
    onDeleteClicked,
    onPageAction,
    onAutoOrderClicked,
    onIssueClicked,
    selectedPurchaseOrders,
  } = props;

  const allDeletedPurchaseOrders = useMemo(
    () => arrayHasAllDeletedObjects(purchaseOrders),
    [purchaseOrders]
  );

  const handlePaginationChange = (newPagination: Pagination) => {
    onPaginationChange(newPagination);
  };

  const handleSelectClick = useSelectedItemsChanges(
    selectedItems,
    setSelectedItems
  );

  const resolvedColumns = activePurchaseOrderId
    ? PURCHASE_ORDER_DETAILS_COLUMNS
    : PURCHASE_ORDER_COLUMNS(activeDate);

  const handleAction = useCallback(
    (action: any) => {
      onAction(action.type, action.value);
    },
    [onAction]
  );

  const isIssuedDisabled = selectedPurchaseOrders.some(
    (p) => p.status !== PurchaseOrderStatus.BidRequest
  );

  const isUnissuedDisabled = selectedPurchaseOrders.some(
    (p) => p.status !== PurchaseOrderStatus.Issued
  );

  return (
    <>
      <FBOTitleBar title="Purchase Orders">
        {selectedItems.length > 0 && !activePurchaseOrderId && (
          <>
            <Tooltip
              title={
                isIssuedDisabled
                  ? 'Only purchase orders in "Bid Request" status can be issued'
                  : ''
              }
              placement="top"
            >
              <span>
                <FBOButton
                  // nasty margin fix for tooltip TODO : figure out why span is adding its own width
                  style={{ marginRight: '-16px' }}
                  variant="secondary"
                  color="positive"
                  size="medium"
                  permissions={[PermissionType.PurchaseOrderIssue]}
                  onClick={onIssueClicked}
                  disabled={isIssuedDisabled}
                  data-qa="purchase-orders-issue-button"
                >
                  Issue
                </FBOButton>
              </span>
            </Tooltip>
            <Tooltip
              title={
                isUnissuedDisabled
                  ? 'Only purchase orders in "Issued" status can be unissued'
                  : ''
              }
              placement="top"
            >
              <span>
                <FBOButton
                  variant="secondary"
                  color="positive"
                  size="medium"
                  permissions={[PermissionType.PurchaseOrderIssue]}
                  onClick={onUnissueClicked}
                  disabled={isUnissuedDisabled}
                  data-qa="purchase-orders-unissue-button"
                >
                  Unissue
                </FBOButton>
              </span>
            </Tooltip>
            <FBOButton
              style={{ marginRight: '8px' }}
              variant="secondary"
              color="negative"
              size="medium"
              icon="TrashCan"
              permissions={[PermissionType.PurchaseOrderDelete]}
              onClick={onDeleteClicked}
              data-qa="purchase-orders-delete-button"
            >
              Delete
            </FBOButton>
          </>
        )}
        {!activePurchaseOrderId && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant="secondary"
            color="positive"
            size="medium"
            onClick={onAutoOrderClicked}
            data-qa="auto-order-button"
          >
            Auto Order
          </FBOButton>
        )}
        {activePurchaseOrderId !== -1 && (
          <FBOButton
            style={{ marginRight: '8px' }}
            variant={!activePurchaseOrderId ? 'primary' : 'secondary'}
            color="positive"
            size="medium"
            icon="FBOAddCircle"
            onClick={onAddNewPress}
            data-qa="purchase-order-add"
            permissions={[PermissionType.PurchaseOrderCreate]}
          >
            {!activePurchaseOrderId ? 'Add New' : ''}
          </FBOButton>
        )}
        <FBOMenuButton
          variant="secondary"
          items={createPOSearchActionBarOptions(
            onPageAction,
            purchaseOrders.length
          )}
          data-qa="purchase-orders-table-three-dot-menu"
        />
      </FBOTitleBar>

      <ItemsTable
        data={purchaseOrders}
        columns={resolvedColumns}
        activeId={activePurchaseOrderId}
        onItemClick={handlePurchaseOrderClick}
        selectableItems={!activePurchaseOrderId}
        selectedItems={selectedItems}
        onSelectedChange={handleSelectClick}
        isLoadingData={isLoadingPurchaseOrders}
        pagination={pagination}
        disableSelection={allDeletedPurchaseOrders}
        RenderCustomRow={
          activePurchaseOrderId ? PurchaseOrderRowSmall : PurchaseOrderRow
        }
        onPaginationChange={handlePaginationChange}
        onAction={handleAction}
        dataQa="purchase-order-table"
        emptyTableText={
          activePurchaseOrderId
            ? 'NO PURCHASE ORDERS AVAILABLE'
            : "ADD NEW ENTRY BY PRESSING 'ADD NEW'"
        }
        showEnhancedTablePaginationActions={!Boolean(activePurchaseOrderId)}
      />
    </>
  );
};

export default PurchaseOrderSearchResults;
