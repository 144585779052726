import moment from 'moment';
import _ from 'lodash';

import {
  getSearches,
  addSearch,
  updateSearch,
  deleteSearch,
} from 'services/salesOrders';
import {
  AdvancedSearchFormValues,
  AdvancedSearchReduxFunctions,
} from 'services/search';
import { Customer } from 'services/customers';
import { PaginationDirection } from 'services/url';
import { Representative } from 'services/representatives';
import { Carrier } from 'services/carriers';
import { OrderPriority } from 'services/settings/orderPriorities';
import { PaymentTerm } from 'services/paymentTerms';
import { ShippingTerm } from 'services/items';
import { Location } from 'services/locations';
import { Tag } from 'services/tags';

export const initialSalesOrderFormValues: AdvancedSearchFormValues = {
  number: null,
  customerId: null,
  emails: null,
  phone: null,
  representativeId: null,
  carrierId: null,
  billToStreet: null,
  billToCity: null,
  billToState: null,
  billToPostalCode: null,
  billToCountry: null,
  shipToStreet: null,
  shipToCity: null,
  shipToState: null,
  shipToPostalCode: null,
  shiptoCountry: null,
  customerPONumber: null,
  vendorPONumber: null,
  notes: null,
  priorityId: null,
  paymentTermId: null,
  shippingTermId: null,
  locationId: null,
  'salesOrderItems.name': null,
  'salesOrderItems.description': null,
  'salesOrderItems.saleItem.item.tags.id%7C%7CsalesOrderBundleItems.salesOrderItems.saleItem.item.tags.id':
    null,
  salesOrderStatus: null,
  dateCreatedFrom: null,
  dateCreatedTo: null,
  dateLastModifiedFrom: null,
  dateLastModifiedTo: null,
  dateIssuedFrom: null,
  dateIssuedTo: null,
  dateScheduledFrom: null,
  dateScheduledTo: null,
  dateFulfilledFrom: null,
  dateFulfilledTo: null,
  dateExpiresFrom: null,
  dateExpiresTo: null,
};

export const displayNameMap = {
  number: 'Order Number',
  customerId: 'Customer',
  emails: 'Email',
  phone: 'Phone',
  representativeId: 'Representative',
  carrierId: 'Carrier',
  carrierServiceId: 'Carrier service',
  billToStreet: 'Bill to street',
  billToCity: 'Bill to city',
  billToState: 'Bill to state',
  billToPostalCode: 'Bill to postal code',
  billToCountry: 'Bill to country',
  shipToStreet: 'Ship to street',
  shipToCity: 'Ship to city',
  shipToState: 'Ship to state',
  shipToPostalCode: 'Ship to postal code',
  shipToCountry: 'Ship to country',
  customerPONumber: 'Customer PO Number',
  vendorPONumber: 'Vendor PO Number',
  notes: 'Notes',
  paymentTermId: 'Payment term',
  shippingTermId: 'Shipping term',
  priorityId: 'Priority',
  locationId: 'Location',
  'salesOrderItems.name': 'Item name',
  'salesOrderItems.description': 'Item description',
  'salesOrderItems.saleItem.item.tags.id%7C%7CsalesOrderBundleItems.salesOrderItems.saleItem.item.tags.id':
    'Tags',
  salesOrderStatus: 'Statuses',
  dateScheduledFrom: 'Date Scheduled After',
  dateScheduledTo: 'Date Scheduled Before',
  dateCreatedFrom: 'Date Created After',
  dateCreatedTo: 'Date Created Before',
  dateLastModifiedFrom: 'Date Modified After',
  dateLastModifiedTo: 'Date Modified Before',
  dateIssuedFrom: 'Date Issued After',
  dateIssuedTo: 'Date Issued Before',
  dateFulfilledFrom: 'Date Fulfilled After',
  dateFulfilledTo: 'Date Fulfilled Before',
  dateExpiresFrom: 'Date Expiers After',
  dateExpiresTo: 'Date Expires Before',
  deleted: 'Deleted',
};

export const advancedSearchReduxActions: AdvancedSearchReduxFunctions = {
  add: addSearch,
  delete: deleteSearch,
  update: updateSearch,
  get: getSearches,
};

export const initialPagination = {
  page: 1,
  pageSize: 25,
  sort: {
    sortBy: 'number',
    direction: PaginationDirection.Descending,
  },
  totalRows: 25,
};

export const createDisplayValueMap = (
  customers: Customer[],
  representative: Representative[],
  carriers: Carrier[],
  priorityLevels: OrderPriority[],
  paymentTerms: PaymentTerm[],
  shippingTerms: ShippingTerm[],
  locations: Location[],
  tags: Tag[],
  format: string
) => {
  let carrier: Carrier | null = null;
  return {
    customerId: (id: number) =>
      _.get(
        customers.find((c) => c.id === id),
        'name',
        null
      ),
    representativeId: (id: number) =>
      _.get(
        representative.find((r) => r.id === id),
        'firstName',
        null
      ) +
      ' ' +
      (_.get(
        representative.find((r) => r.id === id),
        'lastName'
      ) || ''),
    carrierId: (id: number) => {
      carrier = carriers.find((c) => c.id === id) || null;
      return _.get(carrier, 'name', null);
    },
    priorityId: (id: number) =>
      _.get(
        priorityLevels.find((p) => p.id === id),
        'name',
        null
      ),
    paymentTermId: (id: number) =>
      _.get(
        paymentTerms.find((p) => p.id === id),
        'name',
        null
      ),
    shippingTermId: (id: number) =>
      _.get(
        shippingTerms.find((s) => s.id === id),
        'name',
        null
      ),
    locationId: (id: number) =>
      _.get(
        locations.find((l) => l.id === id),
        'name',
        null
      ),
    carrierServiceId: (id: number) => {
      const carrierServices = carrier ? carrier.carrierServiceList : [];
      return _.get(
        carrierServices.find((s) => s.id === id),
        'name',
        null
      );
    },
    dateScheduledFrom: (date: string) =>
      date ? moment(date).format(format) : '',
    dateScheduledTo: (date: string) =>
      date ? moment(date).format(format) : '',
    dateCreatedFrom: (date: string) =>
      date ? moment(date).format(format) : '',
    dateCreatedTo: (date: string) => (date ? moment(date).format(format) : ''),
    dateLastModifiedFrom: (date: string) =>
      date ? moment(date).format(format) : '',
    dateLastModifiedTo: (date: string) =>
      date ? moment(date).format(format) : '',
    dateIssuedFrom: (date: string) => (date ? moment(date).format(format) : ''),
    dateIssuedTo: (date: string) => (date ? moment(date).format(format) : ''),
    dateFulfilledFrom: (date: string) =>
      date ? moment(date).format(format) : '',
    dateFulfilledTo: (date: string) =>
      date ? moment(date).format(format) : '',
    dateExpiresFrom: (date: string) =>
      date ? moment(date).format(format) : '',
    dateExpiresTo: (date: string) => (date ? moment(date).format(format) : ''),
    salesOrderStatus: (status: string) => status.split(',').join(', '),
    'salesOrderItems.saleItem.item.tags.id%7C%7CsalesOrderBundleItems.salesOrderItems.saleItem.item.tags.id':
      () => tags.map((t) => t.name).join(', '),
  };
};
