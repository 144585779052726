import * as yup from 'yup';

export const bulkMoveItemSchema = yup.object({
  quantity: yup.number().required(),
  moveToLocationId: yup.number().required(),
});

export const locationSchema = yup.object({
  locationIds: yup.array().of(yup.number()).required(),
});
export const itemsSchema = yup.object({
  items: yup.array().of(bulkMoveItemSchema).required(),
});

export const getSchema = (activeStep: number): any => {
  switch (activeStep) {
    case 0:
      return locationSchema;
    case 1:
      return itemsSchema;
    default:
      return locationSchema;
  }
};
