import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import {
  COUNTRIES,
  CountryAutocomplete,
} from 'ui/components/Autocomplete/CountryAutocomplete';
import { PhoneInputField } from 'ui/components/TextField/PhoneInputField';
import { useHandleTextFieldChange } from 'services/forms/hooks';
import {
  Address,
  BillToShipToAddressBeta, // @beta: additionally added for testing
  initialBillToShipToAddressBeta, // @beta: additionally added for testing
} from 'services/addresses';
import { validateYup } from 'services/forms/validation';
import { VerifiedAddressLabel } from 'ui/components/Modal/AddressModal/components';
import {
  StateAutocomplete,
  STATES,
} from 'ui/components/Autocomplete/StateAutocomplete';
import { postCustomerAddress } from 'services/customers';
import { yupAddressSchema } from 'services/addresses/validations';
import { postVendorAddress } from 'services/vendors';
import { SalesOrderStatus } from 'services/salesOrders'; // @beta: additionally added for testing
import { GridColumn, GridDivider } from 'ui/components/Grid';

import { TextFieldAddressModalProps } from './types';
import { editSalesOrderPermissions } from '../../helpers';
import { logErrorCtx } from 'app/logging';

const TextFieldAddressModal = ({
  address,
  modalVisible,
  onCancel,
  onSave,
  editMode,
  customerId = null,
  vendorId = null,
  salesOrder,
}: TextFieldAddressModalProps) => {
  const [validationErrors, setValidationErrors] = useState<any>({});
  const [createAddress, setCreateAddress] = useState<boolean>(false);

  // @beta: additionally added for testing
  const [formValues, setFormValues] = useState<BillToShipToAddressBeta>(
    initialBillToShipToAddressBeta
  );
  const editPermission = editSalesOrderPermissions(salesOrder);

  const fieldsDisabled =
    salesOrder.status === SalesOrderStatus.Fulfilled ||
    salesOrder.status === SalesOrderStatus.Cancelled;

  // Update From values when modal becomes visible
  useEffect(() => {
    if (modalVisible) {
      setFormValues(address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalVisible]);

  const handleResetClick = useCallback(() => {
    setFormValues(address);
    setValidationErrors({});
  }, [address]);

  const activeCountry = useMemo(() => {
    return COUNTRIES.find((c) => c.code === formValues.country) || null;
  }, [formValues]);

  const activeState = useMemo(() => {
    return STATES.find((s) => s.abbreviation === formValues.state) || null;
  }, [formValues]);

  const handleTextFieldChange = useHandleTextFieldChange(setFormValues, {
    ...formValues,
    verified: false,
  });

  const handleNonVerifiedTextFieldChange = useHandleTextFieldChange(
    setFormValues,
    {
      ...formValues,
      verified: true,
    }
  );

  const handleCountryChange = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, v: any) => {
      setFormValues({
        ...formValues,
        country: _.get(v, 'code', ''),
        verified: false,
      });
    },
    [formValues]
  );

  const handleStateChange = useCallback(
    (_e: React.ChangeEvent<HTMLInputElement>, v: any) => {
      setFormValues({
        ...formValues,
        state: _.get(v, 'abbreviation', ''),
        verified: false,
      });
    },
    [formValues]
  );

  const handleCreateAddressChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setCreateAddress(checked);
    },
    [setCreateAddress]
  );

  const handleAddSaveClick = async () => {
    const isValid = validateYup(
      formValues,
      yupAddressSchema,
      setValidationErrors
    );

    if (!isValid) {
      return;
    }

    if (customerId && createAddress && !editMode) {
      try {
        await postCustomerAddress(customerId, formValues as Address);
      } catch (error) {
        logErrorCtx('Error in postCustomerAddress', {
          error: error as Error,
          stackTrace: (error as Error).stack,
          component: 'TextFieldAddressModal',
          title: 'Error in postCustomerAddress',
          description: `Customer id ${customerId}`,
        });
      }
    }

    if (vendorId && createAddress && !editMode) {
      try {
        await postVendorAddress(vendorId, formValues as Address);
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error in TextAddressModal', {
          error,
          stackTrace: error.stack,
          title: 'Error while posting vendor address',
          description: 'postVendorAddress Not Fetched from Server',
          component: 'SalesOrderDetailCard -> TextFieldAddressModal',
        });
      }
    }

    onSave(formValues);
  };

  const handleResidentialChanged = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFormValues((old) => ({
        ...old,
        residential: checked,
        verified: false,
      }));
    },
    [setFormValues]
  );

  const applyLabel = () => {
    const update = 'Update';
    const add = 'Add';

    return editMode ? update : add;
  };

  return (
    <Modal
      open={modalVisible}
      title={editMode ? 'Edit Address' : 'Custom Address'}
      onCancelClicked={onCancel}
      onApplyClicked={handleAddSaveClick}
      onResetClicked={handleResetClick}
      applyLabel={applyLabel()}
      maxWidth="md"
    >
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Address Nickname"
            placeholder="Enter nickname"
            name="name"
            autoComplete="off"
            fullWidth
            value={formValues.name}
            required
            onChange={
              formValues.verified
                ? handleNonVerifiedTextFieldChange
                : handleTextFieldChange
            }
            error={!!validationErrors.name}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                className="redesign"
                checked={Boolean(formValues.residential)}
                name="residential"
                color="primary"
                onChange={handleResidentialChanged}
              />
            }
            label="Residential"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Street"
            placeholder="Enter street name"
            name="street"
            autoComplete="nope"
            fullWidth
            value={formValues.street}
            required
            onChange={handleTextFieldChange}
            error={!!validationErrors.street}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Street 2"
            placeholder="Enter street name"
            name="street2"
            autoComplete="nope"
            fullWidth
            value={formValues.street2}
            onChange={handleTextFieldChange}
            error={!!validationErrors.street2}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="City"
            placeholder="Enter city name"
            name="city"
            autoComplete="nope"
            fullWidth
            value={formValues.city}
            required
            onChange={handleTextFieldChange}
            error={!!validationErrors.city}
          />
        </Grid>
        <Grid item xs={3}>
          {formValues.country === 'US' ? (
            <StateAutocomplete
              value={activeState}
              onChange={handleStateChange}
              error={!!validationErrors.state}
              required
            />
          ) : (
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="State"
              placeholder="Enter state name"
              name="state"
              autoComplete="nope"
              fullWidth
              value={formValues.state}
              required
              onChange={handleTextFieldChange}
              error={!!validationErrors.state}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="ZIP"
            placeholder="Enter ZIP"
            name="postalCode"
            fullWidth
            value={formValues.postalCode}
            required
            onChange={handleTextFieldChange}
            error={!!validationErrors.postalCode}
          />
        </Grid>
        <Grid item xs={3}>
          <CountryAutocomplete
            value={activeCountry}
            onChange={handleCountryChange}
            required
            error={!!validationErrors.country}
          />
        </Grid>
      </Grid>

      <GridDivider top={5} bottom={5} />

      <GridColumn title="Contact Information" columnWidth={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Attention"
              placeholder="Enter attention"
              name="attention"
              autoComplete="off"
              fullWidth
              value={formValues.attention}
              onChange={
                formValues.verified
                  ? handleNonVerifiedTextFieldChange
                  : handleTextFieldChange
              }
            />
          </Grid>
          <Grid item xs={4}>
            <PhoneInputField
              label="Phone"
              placeholder="Enter phone number"
              value={formValues.phone} // @beta: additionally added for testing
              onChange={handleTextFieldChange} // @beta: additionally added for testing
              permissions={editPermission}
              disabled={fieldsDisabled}
              name="phone"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className="redesign"
              variant="standard"
              label="E-mail"
              placeholder="Enter email address"
              value={formValues.email} // @beta: additionally added for testing
              permissions={editPermission}
              disabled={fieldsDisabled}
              onChange={handleTextFieldChange} // @beta: additionally added for testing
              name="email"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={4}>
            {customerId && !editMode && (
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={createAddress}
                    name="addToCustomer"
                    color="primary"
                    onChange={handleCreateAddressChange}
                  />
                }
                label="Add to Customer"
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {vendorId && !editMode && (
              <FormControlLabel
                control={
                  <Checkbox
                    className="redesign"
                    checked={createAddress}
                    name="addToVendors"
                    color="primary"
                    onChange={handleCreateAddressChange}
                  />
                }
                label="Add to Vendor"
              />
            )}
          </Grid>
          <Grid item xs={4}>
            {formValues.country === 'US' && (
              <VerifiedAddressLabel
                verifiedLabelVisible={formValues.verified}
              />
            )}
          </Grid>
        </Grid>
      </GridColumn>
    </Modal>
  );
};

export default memo(TextFieldAddressModal);
