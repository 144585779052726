import React from 'react';
import _ from 'lodash';
import { Grid, FormControlLabel, Checkbox } from '@mui/material';

import { IconNames } from 'ui/theme';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { TextField } from 'ui/components/TextField/TextField';

import { ContactType } from 'services/addresses';

import { AddressContactListItemProps } from './types';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

export const AddressContactListItem: React.FC<AddressContactListItemProps> = (
  props
) => {
  const { errors, contact, onEdit, onDelete, onCheckboxClick } = props;
  const handleTextfieldChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onEdit({ ...contact, [event.target.name]: event.target.value || null });
  };

  const handleTypeChange = (e: any, value: ContactType | null) => {
    onEdit({ ...contact, type: value, defaultFlag: false });
  };

  return (
    <Grid container spacing={1} display={'flex'} alignItems={'center'}>
      <Grid item xs={4}>
        <TextField
          className="redesign"
          variant="standard"
          label="Name"
          name="name"
          type="text"
          placeholder="Enter name"
          autoComplete="nope"
          required
          value={contact.name}
          onChange={handleTextfieldChange}
          error={!!errors.name}
          dataQa="address-contact-name"
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          className="redesign"
          variant="standard"
          label="Value"
          name="value"
          type="text"
          placeholder="Enter value"
          autoComplete="nope"
          required
          value={contact.value}
          onChange={handleTextfieldChange}
          error={!!errors.value}
          dataQa="address-contact-value"
        />
      </Grid>
      <Grid item xs={2}>
        <Autocomplete
          label="Type"
          placeholder="Select type"
          options={_.values(ContactType)}
          getOptionLabel={(id: any) => id}
          value={contact.type}
          onChange={handleTypeChange}
          required
          error={!!errors.type}
          dataQa="address-contact-type"
        />
      </Grid>
      <Grid item xs={1}>
        <FormControlLabel
          control={
            <Checkbox
              className="redesign"
              checked={contact.defaultFlag!}
              value="default"
              inputProps={
                {
                  'data-qa': 'address-contact-default',
                } as any
              }
            />
          }
          label="Default"
          onChange={onCheckboxClick}
        />
      </Grid>

      <Grid item xs={1}>
        <FBOButton
          variant="tertiary"
          color="negative"
          size="medium"
          icon={IconNames.TrashCan}
          onClick={onDelete}
          data-qa="address-delete-contact-button"
          sx={{ marginLeft: '8px' }}
        />
      </Grid>
    </Grid>
  );
};
