import { SaleItem } from 'services/items/saleItems';
import { SalesOrder } from 'services/salesOrders';

export interface SalesOrderItemsCmp
  extends React.FunctionComponent<SalesOrderItemsProps> {}

export interface SalesOrderItemsProps {
  salesOrder: SalesOrder;
  setSalesOrder: React.Dispatch<React.SetStateAction<SalesOrder>>;
  validationErrors: any;
  rowValidationErrors: any[];
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  oldState: React.MutableRefObject<SalesOrder | null>;
  handleCalculateTaxesClicked: () => Promise<void>;
}

export interface SaleItemFormValues extends SaleItem {
  quantity: { amount: number | null; uomId: number };
}

export enum ThreeDotMenuActions {
  Duplicate,
  DuplicateToCreditReturn,
  MoveDown,
  MoveUp,
  Remove,
  FulFill,
  Edit,
}
