import {
  createApiReducer,
  chainReducers,
  INITIAL_API_STATE,
} from 'services/api';

import { transformQbdAccount } from '../transformations';
import { QuickbooksDesktopAccountActionTypes } from './actions';

const fetchQuickbooksDekstopAccountReducer = createApiReducer(
  [
    QuickbooksDesktopAccountActionTypes.FETCH_QBD_ACCOUNT_START,
    QuickbooksDesktopAccountActionTypes.FETCH_QBD_ACCOUNT_SUCCESS,
    QuickbooksDesktopAccountActionTypes.FETCH_QBD_ACCOUNT_FAILURE,
  ],
  (data) => ({ items: data.results.map(transformQbdAccount) }),
  (data) => data
);

const quickbooksDesktopAccountChainedReducers = chainReducers(
  INITIAL_API_STATE,
  fetchQuickbooksDekstopAccountReducer
);

export default quickbooksDesktopAccountChainedReducers;
