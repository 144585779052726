import { Item } from 'services/items';

import { RopItem } from '../../types';

export const resolveReorderBarWidths = (item: RopItem) => {
  const maxWidth =
    (item.leadTime || 0) + (item.orderInterval || 0) + (item.safetyStock || 0);

  const safetyStockWidth = item.safetyStock
    ? (item.safetyStock / maxWidth) * 100
    : 0;

  const leadTimeWidth = item.leadTime ? (item.leadTime / maxWidth) * 100 : 0;

  const orderIntervalWidth = item.orderInterval
    ? (item.orderInterval / maxWidth) * 100
    : 0;

  const notifyAtWidth = item.notifyAt ? (item.notifyAt / maxWidth) * 100 : 0;

  return {
    safetyStockWidth,
    leadTimeWidth,
    orderIntervalWidth,
    notifyAtWidth:
      item.notifyAt && item.notifyAt > maxWidth ? 100 : notifyAtWidth,
  };
};

export const transformToRopItem = (
  defaultValues: RopItem,
  item: Item,
  useVendorLeadTime: boolean
): RopItem => {
  const vendorItem = item.vendorItems.find((vi) => vi.defaultFlag);
  const vendorLeadTime =
    vendorItem && vendorItem.leadTime
      ? vendorItem.leadTime
      : defaultValues.leadTime;

  return {
    id: item.id,
    item: item,
    alerts: defaultValues.alerts,
    leadTime: useVendorLeadTime ? vendorLeadTime : defaultValues.leadTime,
    orderInterval: defaultValues.orderInterval,
    notifyAt: defaultValues.notifyAt,
    safetyStock: defaultValues.safetyStock,
  };
};
