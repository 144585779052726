import { State } from 'app/redux';

import { PaymentIntegrationType } from '../types';

export const isPaymentConnected =
  (paymentType: PaymentIntegrationType) => (state: State) => {
    const payments = state.integrations.payments.connections;

    return !!payments.find((p) => p.accountData.type === paymentType);
  };

export const getPaymentIntegration =
  (paymentType: PaymentIntegrationType = PaymentIntegrationType.Stripe) =>
  (state: State) => {
    const connections = state.integrations.payments.connections;
    if (!connections.length) {
      return null;
    }

    return connections.find((p) => p.accountData.type === paymentType) || null;
  };

export const getCurrentPaymentIntegration = (state: State) => {
  const connections = state.integrations.payments.connections;
  if (!connections.length) {
    return null;
  }

  return connections[0];
};
