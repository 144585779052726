import React, { memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormControlLabel, Checkbox } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { QuillTextEditor } from 'ui/components/TextField/QuillTextEditor';
import {
  SalesOrderEmail,
  initialSalesOrderEmail,
  sendSalesOrderEmail,
} from 'services/salesOrders';
import { useHandleTextFieldChange } from 'services/forms';
import { Errors, validateYup } from 'services/forms/validation';
import {
  fetchReportPresets,
  ReportId,
  reportList,
  getReportBlob,
} from 'services/reports';
import { getActiveUser } from 'services/user';
import { showNotification } from 'services/api';

import { SalesOrderEmailModalProps } from './types';
import { yupSoEmailModalSchema } from './validations';

const SalesOrderEmailModal: React.FC<SalesOrderEmailModalProps> = (props) => {
  const { show, salesOrder, onClose } = props;

  const { user: activeUser } = useSelector(getActiveUser);
  const activeUserId = activeUser ? activeUser.id! : 0;

  const [email, setEmail] = useState<SalesOrderEmail>(initialSalesOrderEmail);
  const [sendReport, setSendReport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<Errors>({});

  useEffect(() => {
    if (show) {
      setEmail({ ...initialSalesOrderEmail, to: salesOrder.emails });
      setSendReport(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const handleTextFieldchange = useHandleTextFieldChange(setEmail, email);

  const handleQuillTextChange = useCallback((content: string) => {
    setEmail((old) => ({ ...old, body: content }));
  }, []);

  const handleSendReportChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setSendReport(checked);
  };

  const fetchReportBlob = async () => {
    const presets = await fetchReportPresets(
      activeUserId || -1,
      ReportId.SalesOrder
    );

    const report = reportList.find(
      (report) => report.reportId === ReportId.SalesOrder
    )!;

    const defaultPreset = presets.find((p) => p.defaultFlag);

    const requiredParameters = { salesOrderId: salesOrder.id };

    const parameters = defaultPreset
      ? { ...defaultPreset.parameters, ...requiredParameters }
      : { ...report.parameters, ...requiredParameters };

    const blob = await getReportBlob(ReportId.SalesOrder, parameters);

    return blob;
  };

  const handleApplyClicked = async () => {
    // validation
    const isValid = validateYup(email, yupSoEmailModalSchema, setErrors);
    if (!isValid) {
      return;
    }

    setLoading(true);

    let file = new Blob();

    if (sendReport) {
      try {
        file = await fetchReportBlob();
      } catch {
        showNotification(
          'Something went wrong while generating report. Please try again.',
          { variant: 'error' }
        );

        setLoading(false);
        return;
      }
    }

    try {
      await sendSalesOrderEmail(
        email,
        salesOrder.number!,
        sendReport ? file : undefined
      );
    } catch {
      setLoading(false);
      return;
    }

    setLoading(false);
    onClose();
  };

  return (
    <Modal
      open={show}
      onClose={onClose}
      title="Send Email"
      applyLabel={'Send'}
      onApplyClicked={handleApplyClicked}
      onCancelClicked={onClose}
      withoutDefaultPadding
      footerDivider="shadow"
      customHeight={460}
      maxWidth="sm"
      isLoadingContent={loading}
      disableEnter={true}
    >
      <Grid container margin={2}>
        <Grid xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="To"
            name="to"
            placeholder="Enter email address"
            onChange={handleTextFieldchange}
            value={email.to}
            required
            error={!!errors.to}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Cc"
            name="cc"
            placeholder="Enter email address"
            onChange={handleTextFieldchange}
            value={email.cc}
            error={!!errors.cc}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Bcc"
            name="bcc"
            placeholder="Enter email address"
            onChange={handleTextFieldchange}
            value={email.bcc}
            error={!!errors.cc}
            helperText={errors.cc}
          />
        </Grid>
        <Grid xs={12}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Subject"
            name="subject"
            placeholder="Subject"
            onChange={handleTextFieldchange}
            value={email.subject}
            required
            error={!!errors.subject}
          />
        </Grid>
        <Grid xs={12} marginBottom={2}>
          <FormControlLabel
            control={
              <Checkbox
                className="redesign"
                checked={sendReport}
                onChange={handleSendReportChange}
              />
            }
            label="Send report"
          />
        </Grid>
        <Grid xs={12}>
          <QuillTextEditor
            placeholder="Message"
            onChange={handleQuillTextChange}
            value={email.body || ''}
            error={!!errors.body}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default memo(SalesOrderEmailModal);
