import { State } from 'app/redux';
import _ from 'lodash';

const getQuickbooksDesktop = (state: State) =>
  state.integrations.quickbooksDesktop;

export const getQuickbooksDesktopAccounts = (state: State) =>
  getQuickbooksDesktop(state).items;

export const getQuickbooksDesktopAccountIsConnected = (state: State) =>
  !_.isEmpty(getQuickbooksDesktopAccounts(state));
