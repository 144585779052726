import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { InputAdornment, SelectChangeEvent } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { Location } from 'services/locations';
import { getUomById } from 'services/uoms';
import { CostingMethod } from 'ui/modules/setup/pages/SettingsPage/components/CompanyCard/types';
import { getSettingsCompany } from 'services/settings/company';
import { useHandleTextFieldChange } from 'services/forms';
import { CurrencyField } from 'ui/components/TextField/CurrencyField';

import { EventFormProps } from '../../types';
import UomSelect from '../UomDropdown';
import { themeRestyle } from 'ui/theme';
import { CustomersAutocomplete } from 'ui/components/Autocomplete/CustomerAutocomplete';
import { ClassAutocomplete } from 'ui/components/Autocomplete/ClassesAutocomplete';
import { Customer } from 'services/customers';
import { Class } from 'services/classes';
import { useFlags } from 'helpers/useFlags';
import { calculateCost } from '../../helpers';
const FBOEventAddForm: React.FC<EventFormProps> = (props) => {
  const {
    formValues,
    noTracking,
    onlySerial,
    hasSerialTracking,
    validationErrors,
    averageCost,
    totalQty,
    setFormValues,
    uomIds,
    defaultUomAbbreviation,
    meta: { itemUomConversionList },
  } = props;
  const selectedUom = useSelector(getUomById(formValues.uomId)) || null;
  const companySettings = useSelector(getSettingsCompany);
  const flags = useFlags();
  const handleUomSelectChange = (e: SelectChangeEvent) => {
    const uomId = e.target.value;

    const newCost = calculateCost(uomId, averageCost, itemUomConversionList);

    setFormValues((form) => ({
      ...form,
      uomId: Number(uomId),
      cost: newCost,
    }));
  };
  const handleLocationChange = useCallback(
    (value: Location | null) => {
      setFormValues((f) => ({ ...f, locationFromId: value ? value.id : null }));
    },
    [setFormValues]
  );

  const handleCostChange = useHandleTextFieldChange(setFormValues, formValues);

  const handleTextFieldChange = useHandleTextFieldChange(
    setFormValues,
    formValues,
    !hasSerialTracking
  );
  const handleCustomerChange = useCallback(
    (customer: Customer | null) => {
      setFormValues((form) => ({
        ...form,
        materialExpensing: _.merge({}, form.materialExpensing, {
          customerId: customer?.id ?? null,
        }),
      }));
    },
    [setFormValues]
  );

  const handleClassChange = useCallback(
    (accountingClass: Class | null) => {
      setFormValues((form) => ({
        ...form,
        materialExpensing: _.merge({}, form.materialExpensing, {
          accountingClassId: accountingClass?.id ?? null,
        }),
      }));
    },
    [setFormValues]
  );
  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      columns={16}
      columnSpacing={themeRestyle.spacing(2)}
      padding={`0px ${themeRestyle.spacing(4)} 0px ${themeRestyle.spacing(4)}`}
      disableEqualOverflow
    >
      <Grid xs={noTracking || onlySerial ? 3 : 6}>
        <LocationsAsyncAutocomplete
          value={formValues.locationFromId}
          getLocationLabel={(l) => l.path || ''}
          label="Location"
          required
          placeholder="Select location"
          onChange={handleLocationChange}
          companyWide={false}
          error={!!validationErrors.locationFromId}
          dataQa="inventory-add-location"
        />
      </Grid>
      {(noTracking || onlySerial) && (
        <Grid xs={3}>
          <TextField
            className="redesign"
            variant="standard"
            type="number"
            label="Amount To Add"
            placeholder="Enter amount"
            onChange={handleTextFieldChange}
            disabled={
              !formValues.locationFromId || formValues.locationFromId < 0
            }
            name="quantity"
            value={formValues.quantity}
            error={!!validationErrors.quantity}
            dataQa="inventory-amt-to-add"
            disableDebounce={true}
          />
        </Grid>
      )}
      <Grid xs={2}>
        <UomSelect
          onChange={handleUomSelectChange}
          selectedUomId={selectedUom?.id ?? null}
          uomsWithConversionIds={uomIds}
          disabled={hasSerialTracking}
        />
      </Grid>
      <Grid xs={3}>
        <CurrencyField
          label="Unit Cost"
          name="cost"
          placeholder="Enter unit cost"
          autoComplete="nope"
          decimalPlaces={6}
          disableDebounce={true}
          value={formValues.cost}
          onChange={handleCostChange}
          required
          dataQa="inventory-add-unit-cost"
          error={!!validationErrors.cost}
        />
      </Grid>
      <Grid xs={3}>
        <TextField
          className="redesign"
          variant="standard"
          readOnly
          label="On Hand Quantity"
          value={totalQty}
          dataQa="inventory-add-quantity"
          additionalInputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                {defaultUomAbbreviation}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      {companySettings.accountingMethod === CostingMethod.Average && (
        <Grid xs={2}>
          <CurrencyField
            readOnly
            label="Avg. Unit Cost"
            value={parseFloat(averageCost)}
            allowNegative
            decimalPlaces={6}
          />
        </Grid>
      )}
      {flags.showCustomerAndClassFieldsInEventModals && (
        <Grid container xs={12}>
          <Grid xs={6}>
            <CustomersAutocomplete
              label="Customer/Job"
              onChange={handleCustomerChange}
              value={formValues.materialExpensing?.customerId ?? null}
              placeholder="Select Customer/Job"
              dataQa="remove-event-customer"
            />
          </Grid>
          <Grid xs={6}>
            <ClassAutocomplete
              value={formValues.materialExpensing?.accountingClassId ?? null}
              onChange={handleClassChange}
              label="Class/Category"
              placeholder="Select Class/Category"
              dataQa="remove-event-accounting-class"
            />
          </Grid>
        </Grid>
      )}
      <Grid xs={16}>
        <TextField
          className="redesign"
          variant="standard"
          type="text"
          placeholder="Add notes"
          label="Notes"
          name="notes"
          value={formValues.notes}
          onChange={handleTextFieldChange}
          dataQa="inventory-note"
        />
      </Grid>
    </Grid>
  );
};

export default FBOEventAddForm;
