import React from 'react';
import { Switch } from 'react-router-dom';

import { PermissionType } from 'services/permissions';
import SecureRoute from 'ui/components/Route/SecureRoute';

import { Routes } from './routes';
import {
  CustomersPage,
  SalesOrderPage,
  SalesOrderPageV2,
  PickingPage,
  ShippingPage,
  PricingRulesPage,
} from '../pages';
import { SalesRouterProps, SalesRouterCmp } from './types';
import { useFlags } from 'helpers/useFlags';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SalesRouter: SalesRouterCmp = (props: SalesRouterProps) => {
  const flags = useFlags();
  return (
    <Switch>
      <SecureRoute
        permissions={[PermissionType.CustomersView]}
        path={CustomersPage.route}
        exact
        component={CustomersPage}
      />
      <SecureRoute
        permissions={[PermissionType.PricingRulesView]}
        path={PricingRulesPage.route}
        exact
        component={PricingRulesPage}
      />
      <SecureRoute
        permissions={[PermissionType.SalesOrderView]}
        path={Routes.SalesOrderPage}
        exact
        component={
          flags.salesOrderPageV2Endpoint ? SalesOrderPageV2 : SalesOrderPage
        }
      />
      <SecureRoute
        permissions={[PermissionType.PickingView]}
        path={PickingPage.route}
        exact
        component={PickingPage}
      />
      <SecureRoute
        permissions={[PermissionType.ShippingView]}
        path={ShippingPage.route}
        exact
        component={ShippingPage}
      />
    </Switch>
  );
};

SalesRouter.route = Routes.SalesRouter;

export default SalesRouter;
