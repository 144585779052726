import React, { memo, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { DateRange } from 'materialui-daterange-picker';

import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { TagsAsyncAutocomplete } from 'ui/components/Autocomplete/TagsAutocomplete';
import { AdvancedSearchFieldsCmpProps } from 'ui/components/Page/PageWithAdvancedSearch';
import {
  CountryType,
  CountryAutocomplete,
  COUNTRIES,
} from 'ui/components/Autocomplete/CountryAutocomplete';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { getRangeForRequest } from 'ui/components/TextField/DateRangePicker/helpers';
import LabeledDivider from 'ui/components/Divider/LabeledDivider/LabeledDivider';
import { Customer, getCustomers } from 'services/customers';
import { getPaymentTerms, PaymentTerm } from 'services/paymentTerms';
import { getRepresentatives, Representative } from 'services/representatives';
import { Carrier, CarrierService, getCarriers } from 'services/carriers';
import {
  getOrderPriorities,
  OrderPriority,
} from 'services/settings/orderPriorities';
import { Location, getLocations } from 'services/locations';
import { ShippingTerm, SHIPPING_TERMS } from 'services/items';
import { SalesOrderStatus } from 'services/salesOrders';
import { transformDateToMomentDate } from 'helpers';
import { Tag } from 'services/tags';

const SalesOrderAdvancedSearch: React.FC<AdvancedSearchFieldsCmpProps> = (
  props
) => {
  const { setFormValues, formValues } = props;

  const { items: customers } = useSelector(getCustomers);
  const { items: representatives } = useSelector(getRepresentatives);
  const { items: carriers } = useSelector(getCarriers);
  const { items: paymentTerms } = useSelector(getPaymentTerms);
  const { items: orderPriorities } = useSelector(getOrderPriorities);
  const { items: locations } = useSelector(getLocations);

  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);

  const tagsIds = useMemo(() => {
    return formValues[
      'salesOrderItems.saleItem.item.tags.id%7C%7CsalesOrderBundleItems.salesOrderItems.saleItem.item.tags.id'
    ] as number[];
  }, [formValues]);

  const selectedRepresentative = useMemo(() => {
    return (
      representatives.find((r) => r.id === formValues.representativeId) || null
    );
  }, [formValues.representativeId, representatives]);

  const selectedCarrier = useMemo(() => {
    return carriers.find((c) => c.id === formValues.carrierId) || null;
  }, [formValues.carrierId, carriers]);

  const selectedService = useMemo(() => {
    if (!selectedCarrier) {
      return null;
    }
    return (
      selectedCarrier.carrierServiceList.find(
        (s) => s.id === formValues.carrierServiceId
      ) || null
    );
  }, [formValues, selectedCarrier]);

  const selectedCustomer = useMemo(() => {
    return customers.find((c) => c.id === formValues.customerId) || null;
  }, [formValues.customerId, customers]);

  const selectedBillToCountry = useMemo(() => {
    return COUNTRIES.find((c) => c.code === formValues.billToCountry) || null;
  }, [formValues]);

  const selectedShipToCountry = useMemo(() => {
    return COUNTRIES.find((c) => c.code === formValues.shipToCountry) || null;
  }, [formValues]);

  const selectedOrderPriority = useMemo(() => {
    return orderPriorities.find((o) => o.id === formValues.priorityId) || null;
  }, [formValues.priorityId, orderPriorities]);

  const selectedPaymentTerm = useMemo(() => {
    return paymentTerms.find((p) => p.id === formValues.paymentTermId) || null;
  }, [formValues.paymentTermId, paymentTerms]);

  const selectedShippingTerm = useMemo(() => {
    return (
      SHIPPING_TERMS.find((t) => t.id === formValues.shippingTermId) || null
    );
  }, [formValues.shippingTermId]);

  const selectedLocation = useMemo(() => {
    return locations.find((l) => l.id === formValues.locationId) || null;
  }, [formValues.locationId, locations]);

  const dateCreatedRange: DateRange | null = useMemo(() => {
    if (!formValues.dateCreatedFrom || !formValues.dateCreatedTo) {
      return null;
    }
    return {
      startDate: transformDateToMomentDate(
        formValues.dateCreatedFrom as string
      )!,
      endDate: transformDateToMomentDate(formValues.dateCreatedTo as string)!,
    };
  }, [formValues.dateCreatedFrom, formValues.dateCreatedTo]);

  const dateLastModifiedRange: DateRange | null = useMemo(() => {
    if (!formValues.dateLastModifiedFrom || !formValues.dateLastModifiedTo) {
      return null;
    }
    return {
      startDate: transformDateToMomentDate(
        formValues.dateLastModifiedFrom as string
      )!,
      endDate: transformDateToMomentDate(
        formValues.dateLastModifiedTo as string
      )!,
    };
  }, [formValues.dateLastModifiedFrom, formValues.dateLastModifiedTo]);

  const dateIssuedRange: DateRange | null = useMemo(() => {
    if (!formValues.dateIssuedFrom || !formValues.dateIssuedTo) {
      return null;
    }
    return {
      startDate: transformDateToMomentDate(
        formValues.dateIssuedFrom as string
      )!,
      endDate: transformDateToMomentDate(formValues.dateIssuedTo as string)!,
    };
  }, [formValues.dateIssuedFrom, formValues.dateIssuedTo]);

  const dateScheduledRange: DateRange | null = useMemo(() => {
    if (!formValues.dateScheduledFrom || !formValues.dateScheduledTo) {
      return null;
    }
    return {
      startDate: transformDateToMomentDate(
        formValues.dateScheduledFrom as string
      )!,
      endDate: transformDateToMomentDate(formValues.dateScheduledTo as string)!,
    };
  }, [formValues.dateScheduledFrom, formValues.dateScheduledTo]);

  const dateFulfilledRange: DateRange | null = useMemo(() => {
    if (!formValues.dateFulfilledFrom || !formValues.dateFulfilledTo) {
      return null;
    }
    return {
      startDate: transformDateToMomentDate(
        formValues.dateFulfilledFrom as string
      )!,
      endDate: transformDateToMomentDate(formValues.dateFulfilledTo as string)!,
    };
  }, [formValues.dateFulfilledFrom, formValues.dateFulfilledTo]);

  const dateExpiresRange: DateRange | null = useMemo(() => {
    if (!formValues.dateExpiresFrom || !formValues.dateExpiresTo) {
      return null;
    }
    return {
      startDate: transformDateToMomentDate(
        formValues.dateExpiresFrom as string
      )!,
      endDate: transformDateToMomentDate(formValues.dateExpiresTo as string)!,
    };
  }, [formValues.dateExpiresFrom, formValues.dateExpiresTo]);

  const selectedSoStatuses = useMemo(() => {
    if (!formValues.salesOrderStatus) {
      return [];
    }

    return formValues.salesOrderStatus.toString().split(',');
  }, [formValues.salesOrderStatus]);

  const salesOrderStatusOptions = useMemo(
    () =>
      _.difference(Object.values(SalesOrderStatus), [SalesOrderStatus.Voided]),
    []
  );

  const handleCustomerChange = useCallback(
    (e: React.ChangeEvent<{}>, value: Customer | null) => {
      setFormValues({ ...formValues, customerId: value ? value.id : null });
    },
    [formValues, setFormValues]
  );

  const handleRepresentativeChange = useCallback(
    (e: React.ChangeEvent<{}>, value: Representative | null) => {
      setFormValues({
        ...formValues,
        representativeId: value ? value.id : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleCarrierChange = useCallback(
    (e: React.ChangeEvent<{}>, value: Carrier | null) => {
      setFormValues({
        ...formValues,
        carrierId: value ? value.id : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleCarrierServiceChange = (
    e: React.ChangeEvent<{}>,
    carrierService: CarrierService | null
  ) => {
    setFormValues({
      ...formValues,
      carrierServiceId: carrierService ? carrierService.id : null,
    });
  };

  const handleOrderPriorityChange = useCallback(
    (e: React.ChangeEvent<{}>, value: OrderPriority | null) => {
      setFormValues({ ...formValues, priorityId: value ? value.id : null });
    },
    [formValues, setFormValues]
  );

  const handlePaymentTermChange = useCallback(
    (e: React.ChangeEvent<{}>, value: PaymentTerm | null) => {
      setFormValues({ ...formValues, paymentTermId: value ? value.id : null });
    },
    [formValues, setFormValues]
  );

  const handleShippingTermChange = useCallback(
    (e: React.ChangeEvent<{}>, value: ShippingTerm | null) => {
      setFormValues({ ...formValues, shippingTermId: value ? value.id : null });
    },
    [formValues, setFormValues]
  );

  const handleLocationChange = useCallback(
    (e: React.ChangeEvent<{}>, value: Location | null) => {
      setFormValues({ ...formValues, locationId: value ? value.id : null });
    },
    [formValues, setFormValues]
  );

  const handleBillToCountryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: CountryType | null) => {
      setFormValues({
        ...formValues,
        billToCountry: _.get(value, 'code', ''),
      });
    },
    [formValues, setFormValues]
  );

  const asyncHandleTagChange = useCallback(
    (value: Tag[]) => {
      setSelectedTags(value);
      setFormValues({
        ...formValues,
        'salesOrderItems.saleItem.item.tags.id%7C%7CsalesOrderBundleItems.salesOrderItems.saleItem.item.tags.id':
          value.length ? value.map((i) => i.id!) : null,
      });
    },
    [formValues, setFormValues, setSelectedTags]
  );

  const handleShipToCountryChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, value: CountryType | null) => {
      setFormValues({
        ...formValues,
        shipToCountry: _.get(value, 'code', ''),
      });
    },
    [formValues, setFormValues]
  );

  const handleStatusChange = useCallback(
    (e: React.ChangeEvent<{}>, statuses: SalesOrderStatus[]) => {
      setFormValues({
        ...formValues,
        salesOrderStatus: statuses.length ? statuses.join(',') : null,
      });
    },
    [formValues, setFormValues]
  );

  const handleDateRangeChange = useCallback(
    (field: string) => (value: DateRange) => {
      const requestRange = getRangeForRequest(field, value);

      if (!requestRange) {
        return;
      }

      setFormValues({
        ...formValues,
        ...requestRange,
      });
    },
    [formValues, setFormValues]
  );

  const handleShowDeletedChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setFormValues({ ...formValues, deleted: checked ? 'true' : null });
    },
    [formValues, setFormValues]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LabeledDivider label="Sales Order Info" />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Customer"
          placeholder="Select customer"
          options={customers}
          getOptionLabel={(c) => c.name}
          value={selectedCustomer}
          onChange={handleCustomerChange}
          dataQa="select-customer"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Sales Representative"
          placeholder="Select sales representative"
          options={representatives}
          getOptionLabel={(option: Representative) =>
            option.firstName + ' ' + (option.lastName || '')
          }
          value={selectedRepresentative}
          onChange={handleRepresentativeChange}
          dataQa="select-sales-representative"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Carrier"
          placeholder="Select carrier"
          options={carriers}
          getOptionLabel={(c) => c.name}
          value={selectedCarrier}
          onChange={handleCarrierChange}
          dataQa="select-carrier"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Location"
          placeholder="Select location"
          options={locations}
          getOptionLabel={(t) => t.name}
          value={selectedLocation}
          onChange={handleLocationChange}
          dataQa="select-location"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Carrier Service"
          value={selectedService}
          placeholder="Select carrier service"
          getOptionLabel={(c: CarrierService) => c.name || ''}
          options={selectedCarrier ? selectedCarrier.carrierServiceList : []}
          onChange={handleCarrierServiceChange}
          dataQa="select-carrier-service"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          className="multi-line"
          label="Sales Order Status"
          placeholder="Select sales order status"
          options={salesOrderStatusOptions}
          value={selectedSoStatuses}
          onChange={handleStatusChange}
          dataQa="select-sales-order-status"
          multiple
        />
      </Grid>
      <Grid item xs={6}>
        <TagsAsyncAutocomplete
          label="Tags"
          placeholder="Select tag"
          value={selectedTags}
          onChange={asyncHandleTagChange}
          ids={tagsIds}
          dataQa="select-tag"
          disableTagsModal
        />
      </Grid>
      <Grid item xs={12}>
        <LabeledDivider label="Addresses" />
      </Grid>
      <Grid item xs={6}>
        <CountryAutocomplete
          value={selectedBillToCountry}
          onChange={handleBillToCountryChange}
          label="Bill to Country"
          placeholder="Select country"
          data-qa="select-bill-to-country"
        />
      </Grid>
      <Grid item xs={6}>
        <CountryAutocomplete
          value={selectedShipToCountry}
          onChange={handleShipToCountryChange}
          label="Ship to Country"
          placeholder="Select country"
          data-qa="select-ship-to-country"
        />
      </Grid>
      <Grid item xs={12}>
        <LabeledDivider label="Details" />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Order Priority"
          placeholder="Select order priority"
          options={orderPriorities}
          getOptionLabel={(o) => o.name}
          value={selectedOrderPriority}
          onChange={handleOrderPriorityChange}
          dataQa="select-order-priority"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Payment Term"
          placeholder="Select payment term"
          options={paymentTerms}
          getOptionLabel={(p) => p.name}
          value={selectedPaymentTerm}
          onChange={handlePaymentTermChange}
          dataQa="select-payment-term"
        />
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          label="Shipping Term"
          placeholder="Select shipping term"
          options={SHIPPING_TERMS}
          getOptionLabel={(t) => t.name}
          value={selectedShippingTerm}
          onChange={handleShippingTermChange}
          dataQa="select-shipping-term"
        />
      </Grid>
      <Grid item xs={12}>
        <LabeledDivider label="Dates" />
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            label="Date Created"
            placeholder="Select date created range"
            value={dateCreatedRange}
            onChange={handleDateRangeChange('dateCreated')}
            dataQa="select-date-created-range"
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            value={dateLastModifiedRange}
            label="Date Modified"
            placeholder="Select date modified range"
            onChange={handleDateRangeChange('dateLastModified')}
            dataQa="select-date-modified-range"
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            label="Date Issued"
            placeholder="Select date issued range"
            value={dateIssuedRange}
            onChange={handleDateRangeChange('dateIssued')}
            dataQa="select-date-issued-range"
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            label="Date Scheduled"
            placeholder="Select date scheduled range"
            value={dateScheduledRange}
            onChange={handleDateRangeChange('dateScheduled')}
            dataQa="select-date-scheduled-range"
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            label="Date Fulfilled"
            placeholder="Select date fulfilled range"
            value={dateFulfilledRange}
            onChange={handleDateRangeChange('dateFulfilled')}
            dataQa="select-date-fulfilled-range"
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box>
          <DateRangePicker
            label="Date Expires"
            placeholder="Select expiration date range"
            value={dateExpiresRange}
            onChange={handleDateRangeChange('dateExpires')}
            dataQa="select-expiration-date-range"
            fullWidth
          />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          control={
            <Checkbox
              checked={!!formValues.deleted}
              onChange={handleShowDeletedChange}
              color="primary"
              data-qa="show-deleted"
            />
          }
          label="Show Deleted"
        />
      </Grid>
    </Grid>
  );
};

export default memo(SalesOrderAdvancedSearch);
