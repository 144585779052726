import React, { useCallback, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { DateRange as DateRangeType } from 'materialui-daterange-picker';

import { ItemsTable } from 'ui/components/Table/ItemsTable';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { replaceValueInCollection } from 'helpers';
import { ItemReorderPointsAlert } from 'services/items';

import { columns, customDateRange } from './consts';
import { CustomRow } from './CustomRow';
import { useRopWizard } from '../../AutoRopWizardProvider';
import { LocationRow } from './LocationRow';
import {
  ConfirmRopValueItem,
  ConfirmRopValues as IConfirmDataValues,
} from '../../types';
import { AlertsModal } from '../../../AlertsModal';
import { initialConfirmRopValueItem } from '../../consts';

const ConfirmRopValues: React.FC = () => {
  const { wizardData, confirmData, setWizardData, setConfirmData } =
    useRopWizard();

  const [activeConfirmRopValueItem, setActiveConfirmRopValueItem] =
    useState<ConfirmRopValueItem>(initialConfirmRopValueItem);
  const [alertModalVisible, setAlertModalVisible] = useState<boolean>(false);

  const handleDateChange = useCallback(
    (value: DateRangeType) => {
      setWizardData((old) => ({ ...old, chosenDates: value }));
    },
    [setWizardData]
  );

  const handleModalApplyClicked = useCallback(() => {
    // confirm data is divided by location name
    const confirmDataIndex = confirmData.findIndex(
      (cd) => cd.locationName === activeConfirmRopValueItem.locationName
    );
    const confirmItemIndex = confirmData[
      confirmDataIndex
    ].confirmItems.findIndex(
      (i) =>
        (i.item && i.item.id) ===
        (activeConfirmRopValueItem.item && activeConfirmRopValueItem.item.id)
    );

    if (confirmDataIndex === -1 || confirmItemIndex === -1) {
      return;
    }

    const newConfirmData: IConfirmDataValues = {
      ...confirmData[confirmDataIndex],
      confirmItems: replaceValueInCollection(
        confirmData[confirmDataIndex].confirmItems,
        activeConfirmRopValueItem,
        confirmItemIndex
      )!,
    };

    setConfirmData(
      replaceValueInCollection(confirmData, newConfirmData, confirmDataIndex)!
    );
    setAlertModalVisible(false);
  }, [activeConfirmRopValueItem, confirmData, setConfirmData]);

  const onAlertButtonClick = useCallback(
    (action: any) => {
      const confirmValue = confirmData[action.index];
      const confirmItem = confirmValue.confirmItems[action.itemIndex];

      if (confirmValue && confirmItem) {
        setActiveConfirmRopValueItem(confirmItem);
        setAlertModalVisible(true);
      }
    },
    [confirmData]
  );

  const handleSetAlerts = useCallback((alerts: ItemReorderPointsAlert[]) => {
    setActiveConfirmRopValueItem((old) => ({
      ...old,
      alerts,
    }));
  }, []);

  return (
    <>
      <Box display="flex" flexGrow={1} flexDirection="column" p={1}>
        <Box p={2} display="flex" flexDirection="column">
          <Grid container item alignItems="center" spacing={1}>
            <Grid item xs={3}>
              <DateRangePicker
                value={wizardData.chosenDates}
                label="Date Range"
                name="dateRange"
                placeholder="Select Date Range"
                onChange={handleDateChange}
                definedRanges={customDateRange}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <ItemsTable
          data={confirmData}
          setData={setConfirmData}
          selectableItems={false}
          columns={columns}
          onAction={onAlertButtonClick}
          meta={{ confirmData: confirmData, setConfirmData: setConfirmData }}
          RenderCustomRow={LocationRow}
          RenderRowSubrows={CustomRow}
          tableLayoutFixed
        />
      </Box>
      <AlertsModal
        modalVisible={alertModalVisible}
        alerts={
          activeConfirmRopValueItem ? activeConfirmRopValueItem.alerts : []
        }
        setAlerts={handleSetAlerts}
        onApply={handleModalApplyClicked}
        onClose={() => setAlertModalVisible(false)}
      />
    </>
  );
};

export default ConfirmRopValues;
