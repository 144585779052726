import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import { List, Theme } from '@mui/material';

import { colorPalette, SupportColors } from 'ui/theme';

import { DRAWER_WIDTH, DRAWER_SMALL_WIDTH } from './consts';
import { DrawerItemContainerProps, DrawerItemProps } from './types';

export const useAppDrawerStyle = makeStyles((theme: Theme) => ({
  paper: {
    width: DRAWER_WIDTH,
  },
  navigation: {
    'min-width': DRAWER_SMALL_WIDTH,
    'max-width': DRAWER_SMALL_WIDTH,
  },
  drawer: {
    backgroundColor: theme.palette.appDrawer.primary,
  },
  drawerOpen: {
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'stretch',
    width: DRAWER_WIDTH,
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'stretch',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: DRAWER_SMALL_WIDTH,
  },
  expandIcon: {
    position: 'sticky',
    backgroundColor: 'transparent',
    bottom: 0,
    left: 180,
    color: SupportColors.iconOnLight,
  },
  buttonBlock: {
    marginTop: 'auto',
    position: 'sticky',
    backgroundColor: theme.palette.appDrawer.primary,
    color: 'white',
    bottom: 0,
    display: 'flex',
  },
  expander: {
    backgroundColor: colorPalette.fishbowlDarkGray,
    '&:hover': {
      backgroundColor: colorPalette.fishbowlDarkGray,
    },
  },
}));

export const useItemStyle = makeStyles<Theme, DrawerItemProps>(
  (theme: Theme) => ({
    '@keyframes placeHolderShimmer': {
      '0%': {
        backgroundPosition: '-468px 0',
      },
      '100%': {
        backgroundPosition: '468px 0',
      },
    },
    drawerItem: {
      width: 'auto',
      whiteSpace: 'nowrap',
      borderRadius: theme.spacing(0.5),
      marginTop: 1,
      padding: ({ secondary }) =>
        secondary ? theme.spacing(1) : theme.spacing(2),
      paddingLeft: ({ secondary }) =>
        secondary ? theme.spacing(5) : theme.spacing(2),
      borderTop: ({ secondary }) =>
        secondary ? 'none' : `1px solid ${SupportColors.dividerOnPrimaryDark}`,
    },
    drawerItemExpanded: {
      width: 'auto',
      whiteSpace: 'nowrap',
      borderRadius: theme.spacing(0.5),
      marginTop: 1,
      padding: ({ secondary }) =>
        secondary ? theme.spacing(1) : theme.spacing(2),
      paddingLeft: ({ secondary }) =>
        secondary ? theme.spacing(5) : theme.spacing(2),
      borderTop: ({ secondary }) =>
        secondary ? 'none' : `1px solid ${SupportColors.dividerOnPrimaryDark}`,
      backgroundColor: colorPalette.fishbowlCorporateBlue,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    activeDrawerItem: {
      backgroundColor: colorPalette.fishbowlDarkGray,
    },
    text: {
      margin: 0,
      marginLeft: theme.spacing(2),
      color: SupportColors.iconOnLight,
    },
    activeText: {
      color: 'white',
    },
    activeBackground: {
      backgroundColor: colorPalette.fishbowlDarkGray,
      '&:hover': {
        backgroundColor: colorPalette.fishbowlDarkGray,
      },
      '& .MuiListItemText-root': {
        color: 'white',
      },
    },
    icon: {
      display: 'flex',
    },
    iconLoading: {
      width: 24,
      height: 24,
      borderRadius: 12,
      backgroundColor: '#555',
    },
    loadingText: {
      color: 'red',
      display: 'block',
      textIndent: -9999,
      borderRadius: 4,
      animationDuration: '1s',
      animationFillMode: 'forwards',
      animationIterationCount: 'infinite',
      animationName: '$placeHolderShimmer',
      animationTimingFunction: 'linear',
      background: 'linear-gradient(to right, #444 8%, #666 18%, #444 33%)',
      backgroundSize: '800px 24px',
      position: 'relative',
      height: 24,
    },
  })
);

export const useButtonStyle = makeStyles(() => ({
  button: {
    margin: '0px 20px 10px 20px',
    width: '80%',
    fontSize: '11px',
  },
}));

export const StyledList = styled(List)`
  & > div:last-child {
    border-bottom: 1px solid ${SupportColors.dividerOnPrimaryDark};
  }
`;

export const DrawerItemContainer = styled.div`
  background-color: ${(props: DrawerItemContainerProps) =>
    props.active ? colorPalette.fishbowlDarkGray : 'inherit'};
`;
