import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, InputAdornment, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';

import { ItemsTable } from 'ui/components/Table/ItemsTable';
import { TitleBar } from 'ui/components/TitleBar';
import { TextField } from 'ui/components/TextField/TextField';
import { getParentLocations, Location } from 'services/locations';
import { showNotification } from 'services/api';

import {
  fboAvailableLocationsColumns,
  fboSelectedLocationsColumns,
} from './consts';
import { useRopWizard } from '../../AutoRopWizardProvider';
import { useItemSelecorStyle } from '../ItemsSelector/styled';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { IconNames } from 'ui/theme';

const LocationGroups: React.FC = () => {
  const {
    wizardData: { locationIds },
    setLocationIds,
  } = useRopWizard();
  const [search, setSearch] = useState<string | null>(null);
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);

  const classes = useItemSelecorStyle();

  const parentLocations = useSelector(getParentLocations);

  const searchedLocations: Location[] = useMemo(() => {
    const lowerSearch = (search || '').toLowerCase();
    return parentLocations.filter((pl) => {
      const lowerName = (pl.name || '').toLowerCase();
      return lowerName.includes(lowerSearch);
    });
  }, [search, parentLocations]);

  // Reset location ids
  useEffect(() => {
    setLocationIds([]);
  }, [setLocationIds]);

  const searchRowClicked = useCallback(
    (itemId: number) => {
      const location = parentLocations.find((i) => i.id === itemId);
      const selectedLocation = selectedLocations.find((i) => i.id === itemId);

      if (selectedLocation) {
        showNotification('Location already added', { variant: 'warning' });
        return;
      }

      if (location) {
        setSelectedLocations((old) => [...old, location]);
        setLocationIds((old) => [...old, location.id!]);
      }
    },
    [parentLocations, selectedLocations, setLocationIds]
  );

  const selectedRowClicked = useCallback(
    (itemId: number) => {
      const newSelectedLocations = selectedLocations.filter(
        (i) => i.id !== itemId
      );
      const newLocationIds = locationIds.filter((i) => i !== itemId);

      setSelectedLocations(newSelectedLocations);
      setLocationIds(newLocationIds);
    },
    [locationIds, selectedLocations, setLocationIds]
  );

  const addAllClicked = useCallback(() => {
    const locationIds = searchedLocations.map((i) => i.id!);

    setLocationIds(locationIds);
    setSelectedLocations(searchedLocations);
  }, [searchedLocations, setLocationIds]);

  const removeAllClicked = useCallback(() => {
    setLocationIds([]);
    setSelectedLocations([]);
  }, [setLocationIds]);

  return (
    <Box display="flex" flexGrow={1} flexDirection="column" overflow="hidden">
      <Box p={2}>
        <Typography variant="body2" color="textPrimary">
          Select locations to set reorder points.
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box width="25%">
          <TitleBar noBorderRadius>
            <TextField
              placeholder="Search"
              className={classes.searchInputOuter}
              value={search}
              onChange={(ev) => setSearch(ev.target.value || null)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                classes: {
                  root: classes.searchInputInner,
                  notchedOutline: classes.noBorder,
                },
              }}
            />
            <FBOButton
              variant="secondary"
              color="neutral"
              size="medium"
              icon={IconNames.AddCircle}
              data-qa="rop-wizard-add-all"
              onClick={addAllClicked}
            >
              All
            </FBOButton>
          </TitleBar>
        </Box>
        <Box width="74%">
          <TitleBar title="Locations" noBorderRadius>
            <FBOButton
              variant="secondary"
              color="neutral"
              size="medium"
              icon={IconNames.SubCircle}
              data-qa="rop-wizard-remove-all"
              onClick={removeAllClicked}
            >
              All
            </FBOButton>
          </TitleBar>
        </Box>
      </Box>

      <Box
        display="flex"
        width="100%"
        flex={1}
        overflow="hidden"
        justifyContent="space-between"
      >
        <Box className={classes.tableColumn}>
          <ItemsTable
            data={searchedLocations}
            columns={fboAvailableLocationsColumns(searchedLocations.length)}
            selectableItems={false}
            onItemClick={searchRowClicked}
            dataQa="inventory-rop-left"
          />
        </Box>
        <Box className={classes.tableColumnPicked}>
          <ItemsTable
            data={selectedLocations}
            columns={fboSelectedLocationsColumns}
            selectableItems={false}
            onItemClick={selectedRowClicked}
            emptyTableText="ADD LOCATION BY SELECTING FROM THE LEFT TABLE"
            dataQa="inventory-rop-right"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default LocationGroups;
