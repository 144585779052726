import { getNewMapping, paymentMethod } from './helpers';
import {
  EcommerceChannel,
  EcommerceCustomer,
  EcommerceFulfillment,
  EcommerceHistory,
  EcommerceInventory,
  EcommerceItem,
  EcommerceOrder,
  EcommercePOS,
  EcommerceSettings,
  EcommerceOrderExport,
} from './types';
import { initialEcommercePOS, initialEcommerceOrderExport } from './consts';
import moment from 'moment';

export const transformEcommerceSettings = (res: any): EcommerceSettings => {
  return {
    id: res.id,
    customer: res.customer,
    fulfillment: transformEcommerceFulfillment(res.fulfillment),
    inventory: transformEcommerceInventory(res.inventory),
    item: transformEcommerceItem(res.item),
    order: transformOrder(res.order),
    pos: res.pos ? transformPOS(res.pos) : initialEcommercePOS,
    channel: res.channel,
    orderExport: res.orderExport
      ? transformOrderExportSettings(res.orderExport)
      : initialEcommerceOrderExport,
  };
};

export const transformEcommerceItem = (res: any): EcommerceItem => {
  const { newName, newDescription, newSku } = getNewMapping(res.mapping);

  return {
    dimensionsUnit: res.dimensionsUnit,
    mapping: res.mapping || {},
    newName,
    newDescription,
    newSku,
    fbItemType: res.fbItemType,
    createLinkItems: res.createLinkItems,
    type: res.type,
    uom: res.uom,
    weightUnit: res.weightUnit,
    update: res.update,
    create: res.create,
    class: res.class,
  };
};

export const transformEcommerceHistory = (res: any): EcommerceHistory => {
  return {
    id: res.id,
    date: moment.utc(res.date).local().format(),
    message: res.message,
    link: res.link,
    error: res.error,
    type: res.type,
  };
};

export const transformEcommerceInventory = (res: any): EcommerceInventory => {
  return {
    mapping: res.mapping || {},
    locations: res.locations || [],
    update: res.update,
    tags: res.tags || [],
    type: res.type ? res.type : 'Available',
  };
};

export const transformEcommerceFulfillment = (
  res: any
): EcommerceFulfillment => {
  return {
    mapping: res.locations || {},
    locations: res.locations || [],
    update: res.enabled,
    updateCost: res.updateCost,
  };
};

export const transformOrder = (res: any): EcommerceOrder => {
  return {
    enabled: res.enabled,
    createPayments: res.createPayments,
    location: res.location,
    paymentMethod: res.paymentMethod,
    shippingItem: res.shippingItem,
    shippingTerms: res.shippingTerms,
    defaultCarrier: res.defaultCarrier,
    status: res.status,
    useNumber: res.useNumber,
    taxItem: res.taxItem,
    class: res.class,
    prefix: res.prefix,
  };
};

export const transformPOS = (res: any): EcommercePOS => {
  return {
    class: res.class,
    createPayments: res.createPayments,
    location: res.location,
    paymentMethod: res.paymentMethod,
    shippingItem: res.shippingItem,
    shippingTerms: res.shippingTerms,
    customer: res.customer,
    useNumber: res.useNumber,
    taxItem: res.taxItem,
    enabled: res.enabled,
  };
};

export const transformOrderExportSettings = (
  res: any
): EcommerceOrderExport => {
  return {
    enabled: res.enabled,
    status: res.status,
    locations: res.locations || {},
    customField1: res.customField1,
    customField2: res.customField2,
    customField3: res.customField3,
  };
};

export const transformToPutEcommerceSettings = (
  settings: EcommerceSettings,
  channel: EcommerceChannel
): any => {
  return {
    id: settings.id,
    customer: transformCustomerSettings(settings.customer),
    fulfillment: transformFulfillmentSettings(settings.fulfillment),
    inventory: transformToPutInventorySettings(settings.inventory),
    item: transformItemSettings(settings.item),
    order: transformOrderSettings(settings.order, channel),
    pos: transformToPutPOSSettings(settings.pos, channel),
    orderExport: transformToPutOrderExportSettings(settings.orderExport),
  };
};

const transformFulfillmentSettings = (settings: EcommerceFulfillment): any => {
  return {
    locations: settings.mapping,
    enabled: settings.update,
    updateCost: settings.updateCost,
  };
};

const transformItemSettings = (settings: EcommerceItem): any => {
  // const mapping = {
  //   [FishbowlMappings.Name]: settings.newName!.id,
  //   [FishbowlMappings.Description]: settings.newDescription!.id,
  //   [FishbowlMappings.Sku]: settings.newSku!.id,
  // };
  const newMapping = settings.mapping;
  const mappingsKeys = Object.keys(settings.mapping);
  mappingsKeys.forEach((key) => {
    if (newMapping[parseInt(key)] === null) {
      delete newMapping[parseInt(key)];
    }
  });

  return {
    dimensionsUnit: settings.dimensionsUnit,
    mapping: newMapping,
    type: settings.type,
    fbItemType: settings.fbItemType,
    createLinkItems: settings.createLinkItems,
    uom: settings.uom,
    weightUnit: settings.weightUnit,
    update: settings.update,
    create: settings.create,
    class: settings.class,
  };
};

const transformOrderSettings = (
  settings: EcommerceOrder,
  channel: EcommerceChannel
): any => {
  return {
    createPayments: settings.createPayments,
    location: settings.location,
    paymentMethod: paymentMethod(channel),
    shippingItem: settings.shippingItem,
    shippingTerms: settings.shippingTerms,
    status: settings.status,
    defaultCarrier: settings.defaultCarrier,
    useNumber: settings.useNumber,
    taxItem: settings.taxItem,
    enabled: settings.enabled,
    class: settings.class,
    prefix: settings.prefix,
  };
};

const transformToPutPOSSettings = (
  settings: EcommercePOS,
  channel: EcommerceChannel
): any => {
  return {
    class: settings.class,
    customer: settings.customer,
    createPayments: settings.createPayments,
    location: settings.location,
    paymentMethod: paymentMethod(channel),
    shippingItem: settings.shippingItem,
    shippingTerms: settings.shippingTerms,
    useNumber: settings.useNumber,
    enabled: settings.enabled,
    taxItem: settings.taxItem,
  };
};

const transformToPutInventorySettings = (settings: EcommerceInventory): any => {
  return {
    update: settings.update,
    mapping: settings.mapping,
    locations: settings.locations,
    tags: settings.tags,
    type: settings.type,
  };
};

const transformCustomerSettings = (settings: EcommerceCustomer): any => {
  return {
    currency: 1,
    customer: settings.customer,
    paymentTerms: settings.paymentTerms,
    priority: settings.priority,
    representative: settings.representative,
    useDefault: settings.useDefault,
  };
};

const transformToPutOrderExportSettings = (
  settings: EcommerceOrderExport
): any => {
  return {
    enabled: settings.enabled,
    locations: settings.locations,
    customField1: settings.customField1,
    customField2: settings.customField2,
    customField3: settings.customField3,
    status: settings.status,
  };
};
