import React, { memo, useCallback, useMemo } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateRange } from 'materialui-daterange-picker';

import { SaleItemAsyncAutocomplete } from 'ui/components/Autocomplete/SaleItemAsyncAutocomplete';
import DateRangePicker from 'ui/components/TextField/DateRangePicker/DateRangePicker';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';
import { RepresentativeAsyncAutocomplete } from 'ui/components/Autocomplete/RepresentativeAsyncAutocomplete';
import { useHandleCheckboxChange } from 'services/forms/hooks';
import { Location } from 'services/locations';
import { Customer } from 'services/customers';
import { SaleItem } from 'services/items/saleItems';

import { DateRangeOptions, GroupByOptions, States } from './types';
import { ReportFiltersProps } from '../../types';
import { CustomersAutocomplete } from 'ui/components/Autocomplete/CustomerAutocomplete';

const SalesOrderSummaryFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters } = props;

  const selectedDateRange = useMemo(() => {
    return parameters.dateRange || {};
  }, [parameters]);

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);

  const handleLocationChange = useCallback(
    (location: Location | null) => {
      setParameters((old) => ({
        ...old,
        locationId: location ? location.id : null,
      }));
    },
    [setParameters]
  );

  const handleSelectAll = useCallback(
    (name: string) =>
      (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (name === 'optional') {
          setParameters((old) => ({
            ...old,
            showEstimate: checked,
            showFulfilled: checked,
            showIssued: checked,
            showInProgress: checked,
            showVoided: checked,
            selectAllOptional: checked,
          }));
        } else {
          setParameters((old) => ({
            ...old,
            columnDateFulfilled: checked,
            columnDateIssued: checked,
            columnDateScheduled: checked,
            columnShipToStateProvince: checked,
            columnCustomerPo: checked,
            columnPaymentTerm: checked,
            selectAllColumns: checked,
          }));
        }
      },
    [setParameters]
  );

  const handleSaleItemChange = useCallback(
    (saleItem: SaleItem | null) => {
      setParameters((old) => ({
        ...old,
        saleItemId: saleItem ? saleItem.id : null,
      }));
    },
    [setParameters]
  );

  const handleDateRangeOptionChange = useCallback(
    (e: any, dateRangeOption: DateRangeOptions | null) => {
      setParameters((old) => ({ ...old, dateRangeOption }));
    },
    [setParameters]
  );

  const handleGroupByOptionChange = useCallback(
    (e: any, groupBy: GroupByOptions | null) => {
      setParameters((old) => ({ ...old, groupBy }));
    },
    [setParameters]
  );

  const handleShipToStateChange = useCallback(
    (e: any, shipToStateProvince: States | null) => {
      setParameters((old) => ({ ...old, shipToStateProvince }));
    },
    [setParameters]
  );

  const handleAutocompleteChange = useCallback(
    (parameter: string) => (value: any) => {
      setParameters((old) => ({
        ...old,
        [parameter]: value ? value.id : null,
      }));
    },
    [setParameters]
  );

  const handleCustomerChange = useCallback(
    (value: Customer | null) => {
      setParameters((old) => ({
        ...old,
        customerId: value !== undefined ? value?.id : null,
      }));
    },
    [setParameters]
  );

  const handleDateChange = useCallback(
    (value: DateRange) => {
      setParameters((old) => ({ ...old, dateRange: value }));
    },
    [setParameters]
  );

  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll('optional')}
              color="primary"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%" pt={1}>
            <Box>
              <Autocomplete
                label="Date Range Option"
                options={Object.values(DateRangeOptions)}
                placeholder="Select date range option"
                value={parameters.dateRangeOption || null}
                onChange={handleDateRangeOptionChange}
                fullWidth
              />
            </Box>
            <Box>
              <Autocomplete
                label="Group By"
                options={Object.values(GroupByOptions)}
                placeholder="Select Group By Option"
                value={parameters.groupBy || null}
                onChange={handleGroupByOptionChange}
                fullWidth
              />
            </Box>
            <Box>
              <Autocomplete
                label="Ship to State/Territory"
                options={Object.values(States)}
                placeholder="Select State/Province "
                value={parameters.shipToStateProvince || null}
                onChange={handleShipToStateChange}
                fullWidth
              />
            </Box>
            <Box>
              <RepresentativeAsyncAutocomplete
                label="Sales Representative"
                placeholder="Select sales representative"
                value={parameters.representativeId}
                onChange={handleAutocompleteChange('representativeId')}
                disableAdd
              />
            </Box>
            <Box>
              <LocationsAsyncAutocomplete
                label="Location"
                value={parameters.locationId}
                placeholder="Select location"
                companyWide={false}
                onChange={handleLocationChange}
              />
            </Box>
            <Box>
              <CustomersAutocomplete
                label="Customer"
                placeholder="Select Customer"
                value={parameters.customerId}
                onChange={handleCustomerChange}
              />
            </Box>
            <Box>
              <SaleItemAsyncAutocomplete
                label="Sale Item"
                placeholder="Select Sale Item"
                value={parameters.saleItemId}
                onChange={handleSaleItemChange}
              />
            </Box>
            <DateRangePicker
              className="date-picker-small-font"
              label="Date Range"
              value={selectedDateRange}
              aria-label="Date Range"
              name="dateRange"
              placeholder="Select date range"
              onChange={handleDateChange}
              fullWidth
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showEstimate)}
                  onChange={handleCheckboxChange}
                  name="showEstimate"
                  color="primary"
                />
              }
              label="Estimate"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showFulfilled)}
                  onChange={handleCheckboxChange}
                  name="showFulfilled"
                  color="primary"
                />
              }
              label="Fulfilled"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showIssued)}
                  onChange={handleCheckboxChange}
                  name="showIssued"
                  color="primary"
                />
              }
              label="Issued"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showInProgress)}
                  onChange={handleCheckboxChange}
                  name="showInProgress"
                  color="primary"
                />
              }
              label="Partially Fulfilled"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showVoided)}
                  onChange={handleCheckboxChange}
                  name="showVoided"
                  color="primary"
                />
              }
              label="Voided"
            />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          sx={{ marginBottom: '8px' }}
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllColumns)}
              onChange={handleSelectAll('columns')}
              color="primary"
            />
          }
          label="Select All Columns"
        />
      </Box>
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            <b>Columns</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnDateFulfilled)}
                    onChange={handleCheckboxChange}
                    name="columnDateFulfilled"
                    color="primary"
                  />
                }
                label="Date Fulfilled"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnShipToStateProvince)}
                    onChange={handleCheckboxChange}
                    name="columnShipToStateProvince"
                    color="primary"
                  />
                }
                label="Ship to State/Territory"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnCustomerPo)}
                    onChange={handleCheckboxChange}
                    name="columnCustomerPo"
                    color="primary"
                  />
                }
                label="Customer PO"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnPaymentTerm)}
                    onChange={handleCheckboxChange}
                    name="columnPaymentTerm"
                    color="primary"
                  />
                }
                label="Order Payment Term"
              />
            </Box>
            <Box>
              <FormControlLabel
                className="redesign"
                sx={{ marginBottom: '8px' }}
                control={
                  <Checkbox
                    className="redesign"
                    checked={Boolean(parameters.columnDateIssued)}
                    onChange={handleCheckboxChange}
                    name="columnDateIssued"
                    color="primary"
                  />
                }
                label="Date Issued"
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(SalesOrderSummaryFilters);
