import React from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { BulkWizardItem } from 'services/inventory';

export const ErrorSubRow: React.FC<RenderCustomRowProps<BulkWizardItem>> = (
  props
) => {
  const { row } = props;

  return (
    <>
      {row.errorMessage && (
        <TableRow>
          <TableCell colSpan={6}>
            <Typography color="error">{row.errorMessage}</Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default ErrorSubRow;
