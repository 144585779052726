import { BulkWizardError, BulkWizardItem } from 'services/inventory';

export const resolveBulkWizardErrors = (
  bulkItems: BulkWizardItem[],
  errors: BulkWizardError[]
): BulkWizardItem[] => {
  return bulkItems.flatMap((i: BulkWizardItem) => {
    const itemError = errors.find(
      (e) =>
        e.id === i.itemId &&
        e.locationId === i.locationId &&
        e.moveToLocationId === i.moveToLocationId
    );

    if (!itemError) {
      return [];
    }

    return [
      {
        ...i,
        errorMessage: itemError.error,
      },
    ];
  });
};

export const setBulkItemIndexes = (items: BulkWizardItem[]) => {
  // we do this because every item needs to have a unique ID, but they have the same itemId
  return items.map((i, index) => ({ ...i, id: index }));
};
