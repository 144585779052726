import React, { memo, useState, useEffect, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Divider,
  Grid,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import _ from 'lodash';

import { useHandleTextFieldChange } from 'services/forms';
import { useHandleCheckboxChange } from 'services/forms/hooks';
import { WEIGHT_UNITS, WeightUnit } from 'services/items';
import { TextField } from 'ui/components/TextField/TextField';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import {
  ShippingSignatureConfirmationType,
  ShippoCodMethodsType,
  ShippoRecipientType,
  shippoSignatureConfirmationType,
  shipStationSignatureConfirmationType,
} from 'services/integrations/shipping/shippo';
import {
  Customer,
  CustomerCarrierAccount,
  initialCustomer,
} from 'services/customers';
import {
  ShippoBillingTypeOptions,
  ShipStationBillingTypeOptions,
} from 'services/shipping';
import {
  fetchShippingIntegrationFileTypes,
  getShippingIntegrationPackages,
  ShippingConnectionType,
  ShippingIntegrationFileType,
  ShippingIntegrationPackage,
} from 'services/integrations/shipping';
import { getShippingConnection } from 'services/integrations/shipping/redux';
import { PhoneInputField } from 'ui/components/TextField/PhoneInputField';
import { CarrierAccountAutocomplete } from 'ui/components/Autocomplete/CarrierAccountAutocomplete';

import { useCardRowStyle } from './styled';
import { PurchaseLabelStepProps } from './types';
import PurchaseLabelRates from './PurchaseLabelRates';
import {
  PurchaseLabelContext,
  usePurchaseLabelExtra,
} from '../../PurchaseLabelProvider';
import { logErrorCtx } from 'app/logging';

const PurchaseLabelStep: React.FC<PurchaseLabelStepProps> = ({
  ship,
  errors,
}) => {
  const classes = useCardRowStyle();

  const { rates, ratesValidation } = useContext(PurchaseLabelContext);
  const { purchaseLabelExtra, setPurchaseLabelExtra } = usePurchaseLabelExtra();
  const [activeCustomer, setActiveCustomer] =
    useState<Customer>(initialCustomer);
  const [shippingIntegrationPackages, setShippingIntegrationPackages] =
    useState<ShippingIntegrationPackage[]>([]);
  const [fileTypes, setFileTypes] = useState<ShippingIntegrationFileType[]>([]);
  const [carrierAccounts, setCarrierAccounts] = useState<
    CustomerCarrierAccount[]
  >([]);

  const connection = useSelector(getShippingConnection);

  const isCarrierAccountDisabled = useMemo(() => {
    return (
      purchaseLabelExtra.billingType === 'my_account' ||
      purchaseLabelExtra.billingType === 'SENDER' ||
      !purchaseLabelExtra.billingType
    );
  }, [purchaseLabelExtra.billingType]);

  useEffect(() => {
    const customer: Customer = _.get(ship, 'salesOrder.customer', []);
    setActiveCustomer(customer);

    const customerCarrierAccounts: CustomerCarrierAccount[] = _.get(
      ship,
      'salesOrder.customer.carrierAccountSettings',
      []
    );

    setCarrierAccounts(customerCarrierAccounts);
  }, [ship]);

  const selectedCarrierAccount = useMemo(
    () =>
      carrierAccounts.find(
        (a) =>
          a.carrier === purchaseLabelExtra.billingCarrierCode &&
          a.accountNumber === purchaseLabelExtra.billingAccount
      ) || null,
    [
      carrierAccounts,
      purchaseLabelExtra.billingAccount,
      purchaseLabelExtra.billingCarrierCode,
    ]
  );

  const filteredPackaging = useMemo(() => {
    if (!selectedCarrierAccount) {
      return shippingIntegrationPackages;
    }

    const carrier = selectedCarrierAccount.carrier!.toLowerCase();

    return shippingIntegrationPackages.filter((p) => p.carrier === carrier);
  }, [shippingIntegrationPackages, selectedCarrierAccount]);

  const selectedPackagingCode = useMemo(() => {
    return (
      filteredPackaging.find(
        (p) => p.code === purchaseLabelExtra.packagingCode
      ) || null
    );
  }, [filteredPackaging, purchaseLabelExtra.packagingCode]);

  const selectedWeightUnit = useMemo(() => {
    return (
      WEIGHT_UNITS.find(
        (u) => u.name === purchaseLabelExtra.dryIceWeightUnit
      ) || null
    );
  }, [purchaseLabelExtra.dryIceWeightUnit]);

  const shippoConnected = useMemo(() => {
    if (!connection) {
      return false;
    }

    return connection.typeId === ShippingConnectionType.Shippo;
  }, [connection]);

  const filteredSignatureTypes = useMemo(() => {
    if (!shippoConnected) {
      return shipStationSignatureConfirmationType;
    }

    const signatureTypes = shippoSignatureConfirmationType;

    switch (purchaseLabelExtra.packagingCarrier) {
      case 'fedex':
        return signatureTypes.filter((t) => t.key !== 'CERTIFIED');
      case 'ups':
        return signatureTypes.filter(
          (t) => t.key !== 'CERTIFIED' && t.key !== 'INDIRECT'
        );
      case 'usps':
        return signatureTypes.filter((t) => t.key !== 'INDIRECT');
      default:
        return signatureTypes;
    }
  }, [purchaseLabelExtra.packagingCarrier, shippoConnected]);

  const selectedSignatureType = useMemo(() => {
    if (shippoConnected) {
      return (
        shippoSignatureConfirmationType.find(
          (s) => s.key === purchaseLabelExtra.signatureType
        ) || null
      );
    }

    return (
      shipStationSignatureConfirmationType.find(
        (s) => s.key === purchaseLabelExtra.signatureType
      ) || null
    );
  }, [purchaseLabelExtra.signatureType, shippoConnected]);

  const shippingBillingTypeOptions = useMemo(() => {
    if (!connection) {
      return [];
    }

    switch (connection.typeId) {
      case ShippingConnectionType.Shippo:
        return ShippoBillingTypeOptions;
      case ShippingConnectionType.ShipStation:
        return ShipStationBillingTypeOptions;
      default:
        return [];
    }
  }, [connection]);

  const shippingBillingTypeValue = useMemo(() => {
    if (!connection) {
      return null;
    }

    switch (connection.typeId) {
      case ShippingConnectionType.Shippo:
        return (
          ShippoBillingTypeOptions.find(
            (s) => s.code === purchaseLabelExtra.billingType
          ) || null
        );
      case ShippingConnectionType.ShipStation:
        return (
          ShipStationBillingTypeOptions.find(
            (s) => s.code === purchaseLabelExtra.billingType
          ) || null
        );
      default:
        return null;
    }
  }, [connection, purchaseLabelExtra.billingType]);

  useEffect(() => {
    (async () => {
      try {
        const types = await fetchShippingIntegrationFileTypes(connection!);
        setFileTypes(types);
        if (!shippoConnected) {
          setPurchaseLabelExtra((old) => ({ ...old, labelFileType: 'PDF' }));
        }
      } catch (e) {
        logErrorCtx('Error in fetch Shipping Integration File Types', {
          error: e as Error,
          stackTrace: (e as Error).stack,
          component: 'Purchase Label Step',
          title: 'Error in fetch Shipping Integration File Types',
          description: 'Error in fetch Shipping Integration File Types',
        });
      }
    })();
  }, [connection, shippoConnected, setPurchaseLabelExtra]);

  const selectedFileType = useMemo(() => {
    return (
      fileTypes.find(
        (u) =>
          u.code ===
          (!shippoConnected
            ? 'application/pdf'
            : purchaseLabelExtra.labelFileType)
      ) || null
    );
  }, [fileTypes, shippoConnected, purchaseLabelExtra.labelFileType]);

  useEffect(() => {
    (async () => {
      try {
        const shippingIntegrationPackages =
          await getShippingIntegrationPackages(connection!);

        setShippingIntegrationPackages(shippingIntegrationPackages);
      } catch (e) {
        const error = e as Error;
        logErrorCtx('Error in PurchaseLabelStep', {
          error,
          stackTrace: error.stack,
          title: 'Error while getting shipping integration packages',
          description: 'getShippingIntegrationPackages Not Fetched from Server',
          component: 'PurchaseLabelWizard -> PurchaseLabelStep',
        });
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTextFieldChange = useHandleTextFieldChange(
    setPurchaseLabelExtra,
    purchaseLabelExtra
  );

  const handleCheckboxChange = useHandleCheckboxChange(setPurchaseLabelExtra);

  const handleAutoCompleteChange =
    (name: string) => (e: any, value: string | null) => {
      setPurchaseLabelExtra((old) => ({ ...old, [name]: value }));
    };

  const handlePackagingChange = (
    e: any,
    value: ShippingIntegrationPackage | null
  ) => {
    if (value && value.carrier !== 'ups' && value.carrier !== 'fedex') {
      setPurchaseLabelExtra((old) => ({
        ...old,
        packagingCode: value.code,
        packagingCarrier: value.carrier,
        containsDryIce: false,
        dryIceWeight: null,
        dryIceWeightUnit: null,
        recipientType: null,
        containsAlcohol: false,
        cod: false,
        codMethod: null,
        codAmount: null,
      }));

      return;
    }

    setPurchaseLabelExtra((old) => ({
      ...old,
      packagingCode: value ? value.code : null,
      packagingCarrier: value ? value.carrier : null,
    }));
  };

  const handleFileTypeChange = (
    e: any,
    value: ShippingIntegrationFileType | null
  ) => {
    setPurchaseLabelExtra((old) => ({
      ...old,
      labelFileType: value ? value.code : null,
    }));
  };

  const handleBillingTypeChange = (e: any, value: any | null) => {
    setPurchaseLabelExtra((old) => ({
      ...old,
      billingType: value ? value.code : null,
      billingAccount: null,
      billingCountry: null,
      billingZip: null,
      billingCarrierCode: null,
      billingParticipationCode: null,
    }));
  };

  const handleCarrierAccountChange = (value: CustomerCarrierAccount | null) => {
    setPurchaseLabelExtra((old) => ({
      ...old,
      billingCarrierCode: value ? value.carrier : null,
      billingAccount: value ? value.accountNumber : null,
      billingCountry: value ? value.billingCountry : null,
      billingZip: value ? value.billingZip : null,
    }));
  };

  const handleCarrierAccountFetch = (value: CustomerCarrierAccount[]) => {
    setCarrierAccounts(value);
  };

  return (
    <Box flexDirection="row" display="flex">
      <Box className={classes.content} p={4}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Autocomplete
              options={shippingBillingTypeOptions}
              onChange={handleBillingTypeChange}
              getOptionLabel={(u) => u.name}
              value={shippingBillingTypeValue}
              label="Billing Type"
              required
            />
          </Grid>
          <Grid item xs={8}>
            <CarrierAccountAutocomplete
              customer={activeCustomer}
              label="Carrier Account"
              value={selectedCarrierAccount}
              placeholder="Select Carrier Account"
              onChange={handleCarrierAccountChange}
              onFetch={handleCarrierAccountFetch}
              dataQa="purchase-order-buyer"
              disabled={isCarrierAccountDisabled}
              error={
                !!errors.billingAccount || !!ratesValidation.billingAccount
              }
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              options={filteredPackaging}
              getOptionLabel={(option) => option.name}
              onChange={handlePackagingChange}
              value={selectedPackagingCode}
              label="Packaging"
            />
          </Grid>
          {shippoConnected && (
            <>
              <Grid item xs={4}>
                <TextField
                  type="text"
                  label="Reference Field 1"
                  name="referenceField1"
                  value={purchaseLabelExtra.referenceField1}
                  onChange={handleTextFieldChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type="text"
                  label="Reference Field 2"
                  name="referenceField2"
                  value={purchaseLabelExtra.referenceField2}
                  onChange={handleTextFieldChange}
                />
              </Grid>
            </>
          )}
          <Grid item xs={4}>
            <PhoneInputField
              label="Recipient Phone"
              name="recipientPhone"
              value={purchaseLabelExtra.recipientPhone}
              onChange={handleTextFieldChange}
              error={
                !!errors.recipientPhone || !!ratesValidation.recipientPhone
              }
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              type="text"
              label="Recipient Email"
              name="recipientEmail"
              value={purchaseLabelExtra.recipientEmail}
              onChange={handleTextFieldChange}
              error={
                !!errors.recipientEmail || !!ratesValidation.recipientEmail
              }
              required
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              onChange={handleFileTypeChange}
              getOptionLabel={(option) => option.name}
              value={selectedFileType}
              label="Label File Type"
              options={fileTypes}
              disabled={!shippoConnected}
            />
          </Grid>
          {shippoConnected && (
            <>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={purchaseLabelExtra.useRetailRates}
                      name="useRetailRates"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Use Retail Rates"
                />
              </Grid>
            </>
          )}
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={purchaseLabelExtra.saturdayDelivery}
                  name="saturdayDelivery"
                  onChange={handleCheckboxChange}
                />
              }
              label="Saturday Delivery"
            />
          </Grid>
          {shippoConnected && (
            <React.Fragment>
              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={purchaseLabelExtra.containsDryIce}
                      name="containsDryIce"
                      onChange={handleCheckboxChange}
                      disabled={
                        !!purchaseLabelExtra.packagingCarrier &&
                        purchaseLabelExtra.packagingCarrier !== 'ups' &&
                        purchaseLabelExtra.packagingCarrier !== 'fedex'
                      }
                    />
                  }
                  label={
                    <>
                      <Typography>Contains Dry Ice</Typography>
                      <Typography variant="caption">
                        UPS and FedEx only
                      </Typography>
                    </>
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  type="number"
                  label="Dry Ice Weight"
                  placeholder="Enter weight"
                  name="dryIceWeight"
                  value={purchaseLabelExtra.dryIceWeight}
                  disabled={!purchaseLabelExtra.containsDryIce}
                  onChange={handleTextFieldChange}
                  error={!!ratesValidation.dryIceWeight}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  options={WEIGHT_UNITS}
                  getOptionLabel={(u: WeightUnit) => u.name}
                  value={selectedWeightUnit}
                  onChange={(e: any, v: any) =>
                    handleAutoCompleteChange('dryIceWeightUnit')(
                      e,
                      v ? v.name : null
                    )
                  }
                  disabled={!purchaseLabelExtra.containsDryIce}
                  error={!!ratesValidation.dryIceWeightUnit}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={purchaseLabelExtra.containsAlcohol}
                  name="containsAlcohol"
                  onChange={handleCheckboxChange}
                  disabled={
                    !!purchaseLabelExtra.packagingCarrier &&
                    purchaseLabelExtra.packagingCarrier !== 'ups' &&
                    purchaseLabelExtra.packagingCarrier !== 'fedex'
                  }
                />
              }
              label={
                <>
                  <Typography>Contains Alcohol</Typography>
                  <Typography variant="caption">UPS and FedEx only</Typography>
                </>
              }
            />
          </Grid>
          {shippoConnected && (
            <React.Fragment>
              <Grid item xs={8}>
                <Autocomplete
                  options={Object.values(ShippoRecipientType)}
                  onChange={handleAutoCompleteChange('recipientType')}
                  value={purchaseLabelExtra.recipientType}
                  disabled={!purchaseLabelExtra.containsAlcohol}
                  label="Recipient Type"
                  placeholder="Select type"
                  error={!!ratesValidation.recipientType}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={purchaseLabelExtra.cod}
                      name="cod"
                      onChange={handleCheckboxChange}
                      disabled={
                        !!purchaseLabelExtra.packagingCarrier &&
                        purchaseLabelExtra.packagingCarrier !== 'ups' &&
                        purchaseLabelExtra.packagingCarrier !== 'fedex'
                      }
                    />
                  }
                  label={
                    <>
                      <Typography>COD</Typography>
                      <Typography variant="caption">
                        UPS and FedEx only
                      </Typography>
                    </>
                  }
                />
              </Grid>

              <Grid item xs={4}>
                <Autocomplete
                  onChange={handleAutoCompleteChange('codMethod')}
                  value={purchaseLabelExtra.codMethod}
                  label="Method"
                  placeholder="Select method"
                  options={Object.values(ShippoCodMethodsType)}
                  disabled={!purchaseLabelExtra.cod}
                  error={!!ratesValidation.codMethod}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  type="number"
                  label="Amount"
                  placeholder="Enter amount"
                  name="codAmount"
                  value={purchaseLabelExtra.codAmount}
                  onChange={handleTextFieldChange}
                  disabled={!purchaseLabelExtra.cod}
                  error={!!ratesValidation.codAmount}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  value={purchaseLabelExtra.codCurrency}
                  label="Currency"
                  disabled
                />
              </Grid>
            </React.Fragment>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={purchaseLabelExtra.signatureConfirmation}
                  name="signatureConfirmation"
                  onChange={handleCheckboxChange}
                />
              }
              label="Signature Confirmation"
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              value={selectedSignatureType}
              label="Signature"
              placeholder="Select type"
              options={filteredSignatureTypes}
              disabled={!purchaseLabelExtra.signatureConfirmation}
              error={!!ratesValidation.signatureType}
              onChange={(e: any, v: ShippingSignatureConfirmationType | null) =>
                handleAutoCompleteChange('signatureType')(e, v ? v.key : null)
              }
              getOptionLabel={(s: ShippingSignatureConfirmationType) => s.name}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={purchaseLabelExtra.purchaseReturnLabel}
                  name="purchaseReturnLabel"
                  onChange={handleCheckboxChange}
                />
              }
              label="Purchase return label"
            />
          </Grid>
        </Grid>
      </Box>

      <PurchaseLabelRates rates={rates} />
    </Box>
  );
};

export default memo(PurchaseLabelStep);
