import React, { memo } from 'react';
import { Link, TableCell, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseOutlined from '@mui/icons-material/CloseOutlined';

import moment from 'moment';

import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { EcommerceHistory } from 'services/integrations/ecommerce';

import HistoryTooltip from '../HistoryTooltip/HistoryTooltip';
import { useGetIntlDateFormatString } from 'helpers';

const HistoryCardRow: React.FC<RenderCustomRowProps<EcommerceHistory>> = (
  props
) => {
  const { row } = props;

  const intlFormatDate = useGetIntlDateFormatString();

  return (
    <>
      <TableCell>
        <Typography variant="body2" color="textPrimary">
          {row.date
            ? `${moment(row.date).format(intlFormatDate)} ${moment(
                row.date
              ).format('HH:mm:ss')}`
            : ''}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle2" color="textSecondary">
          {row.link && !row.error ? (
            <HistoryTooltip title={row.link} disabled={!row.link}>
              <Link
                variant="body2"
                color="primary"
                href={row.link as string}
                underline="hover"
              >
                {row.message}
              </Link>
            </HistoryTooltip>
          ) : (
            row.message
          )}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2" color="textPrimary">
          {row.error ? (
            <CloseOutlined
              style={{
                color: 'white',
                background: 'red',
                borderRadius: '15px',
                fontSize: 25,
              }}
              titleAccess="Failure"
            />
          ) : (
            <CheckCircleIcon
              style={{ color: 'green', fontSize: 30 }}
              titleAccess="Success"
            />
          )}
        </Typography>
      </TableCell>
    </>
  );
};

export default memo(HistoryCardRow);
