import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

import { colorPalette } from 'ui/theme';

import { DRAWER_WIDTH, DRAWER_SMALL_WIDTH } from './consts';

import { TopBkgSvg } from 'ui/theme/images/styleguide/TopBkgSvg';
import { MiddleBkgSvg } from 'ui/theme/images/styleguide/MiddleBkgSvg';
import { BottomBkgSvg } from 'ui/theme/images/styleguide/BottomBkgSvg';

export const useAppDrawerStyle = makeStyles((theme: Theme) => ({
  drawer: {
    backgroundColor: colorPalette.redesign.backgroundMenu,
    backgroundImage: `${TopBkgSvg}, ${MiddleBkgSvg}, ${BottomBkgSvg}`,
    backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
    backgroundPosition: 'top right, center right, bottom left',
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    width: DRAWER_SMALL_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  expandIcon: {
    position: 'sticky',
    backgroundColor: 'transparent',
    bottom: 0,
    left: 180,
    color: colorPalette.redesign.contentTertiary,
  },
  buttonBlock: {
    marginTop: 'auto',
    position: 'sticky',
    backgroundColor: theme.palette.appDrawer.primary,
    color: 'white',
    bottom: 0,
    display: 'flex',
  },
  expander: {
    backgroundColor: colorPalette.transparent,
    '&:hover': {
      backgroundColor: colorPalette.transparent,
    },
  },
}));

export const useItemStyle = makeStyles<Theme>(() => ({
  iconLoading: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: '#555',
  },
  loadingText: {
    color: 'red',
    display: 'block',
    textIndent: -9999,
    borderRadius: 4,
    animationDuration: '1s',
    animationFillMode: 'forwards',
    animationIterationCount: 'infinite',
    animationName: '$placeHolderShimmer',
    animationTimingFunction: 'linear',
    background: 'linear-gradient(to right, #444 8%, #666 18%, #444 33%)',
    backgroundSize: '800px 24px',
    position: 'relative',
    height: 24,
  },
}));
