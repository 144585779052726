import React, { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { IconNames, WooCommerceLogo } from 'ui/theme';
import {
  fetchEcommerceChannels,
  getAllECommerceSettingsByChannel,
} from 'services/integrations/ecommerce/redux';
import { showNotification } from 'services/api';
import { Modal } from 'ui/components/Modal/Modal';
import { TextField } from 'ui/components/TextField/TextField';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { Routes as IntegrationsRoutes } from 'ui/modules/integrations/navigation';
import { useHandleTextFieldChange } from 'services/forms';
import { Errors, validateYup } from 'services/forms/validation';
import {
  ChannelType,
  disconnectChannel,
} from 'services/integrations/ecommerce';
import {
  connectWooCommerce,
  initialWooCommerceChannelData,
  WooCommerceChannelData,
} from 'services/integrations/ecommerce/woocommerce';

import { wooCommerceSchema } from './validations';
import { WooCommerceCardProps } from './types';
import { Card } from '../';
import { CardButton } from '../IntegrationButtons/CardButton';
import { IconButtonWithActions } from 'ui/components/Button/IconButtonWithActions';
import { MenuItem } from 'ui/components/Menu';
import { logErrorCtx } from 'app/logging';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

const WooCommerceCard: React.FC<WooCommerceCardProps> = (props) => {
  const { connectionValid } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const wooCommerceSettings = useSelector(
    getAllECommerceSettingsByChannel(ChannelType.WooCommerce)
  );

  const [modalVisible, setModalVisible] = useState(false);
  const [form, setForm] = useState<WooCommerceChannelData>(
    initialWooCommerceChannelData
  );
  const [errors, setErrors] = useState<Errors>({});
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [showConnectionModal, setShowConnectionModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeChannel, setActiveChannel] = useState<{
    id: number;
    name: string;
  }>();

  const handleConnectClicked = async () => {
    if (!validateYup(form, wooCommerceSchema, setErrors)) {
      return;
    }

    let connected = false;

    form.domain = form.domain!.replace(/\/$/, '');

    const domainExists = wooCommerceSettings.filter(
      (el) => el.channel.domain === form.domain
    );

    if (domainExists.length > 0) {
      showNotification(`Wrong data. Domain already connected.`, {
        variant: 'warning',
      });

      return;
    }

    setIsLoading(true);

    try {
      connected = await connectWooCommerce(form);
    } catch (e) {
      setIsLoading(false);
      const error = e as Error;
      logErrorCtx('WooCommerce connection failed.', {
        error,
        stackTrace: error.stack,
        title: 'Connecting to WooCommerce failed.',
        component: 'WooCommerce -> WooCommerceCard',
      });
    }

    if (!connected) {
      showNotification(`Wrong data. Please try again.`, {
        variant: 'warning',
      });
      setIsLoading(false);
      return;
    }

    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchEcommerceChannels());
    history.push(IntegrationsRoutes.EcommercePage + '#woocommerce');
    setIsLoading(false);
    showNotification(`WooCommerce connected`, {
      variant: 'success',
    });
  };

  const handleConnect = () => {
    if (!connectionValid) {
      setShowConnectionModal(true);
      return;
    }

    setModalVisible(true);
  };

  const handleDisconnect = async () => {
    if (activeChannel) {
      setIsLoading(true);
      try {
        await disconnectChannel(activeChannel.id);
        // Lint skip to be removed
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(fetchEcommerceChannels());
        showNotification(`WooCommerce disconnected`, {
          variant: 'success',
        });
      } catch (e) {
        const error = e as Error;

        showNotification(`WooCommerce disconnect failed - ${error.message}`, {
          variant: 'error',
        });
      }

      setIsLoading(false);
      setShowDisconnectModal(false);
      setActiveChannel(undefined);
    } else {
      showNotification(`Unable to disconnect! No channel id provided!`, {
        variant: 'error',
      });
    }
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleTextFieldChange = useHandleTextFieldChange(setForm, form);

  const createItemsSearchActionBarOptions = (): MenuItem[] => {
    return wooCommerceSettings.map((channel) => {
      return {
        label: channel.channel.name!,
        onOptionClick: () => {
          setActiveChannel({
            id: channel.channel.id!,
            name: channel.channel.name!,
          });
          setShowDisconnectModal(true);
        },
      };
    });
  };

  return (
    <>
      <Card
        imageUrl={WooCommerceLogo}
        cardId="woocommerce"
        isLoading={false}
        description="Manage orders while keeping inventory levels up to date with WooCommerce."
        title="WooCommerce"
      >
        <>
          {wooCommerceSettings.length > 0 ? (
            <IconButtonWithActions
              icon={IconNames.MoreVertical}
              iconSize={16}
              text="Disconnect"
              boxStyle
              items={createItemsSearchActionBarOptions()}
            />
          ) : null}
          <CardButton
            onClick={handleConnect}
            variant="primary"
            color="positive"
            dataQa="woo-commerce-connect"
            label="Connect"
          />
        </>
      </Card>
      <Modal
        open={modalVisible}
        title="Enter WooCommerce data"
        maxWidth="sm"
        onCancelClicked={handleCloseModal}
        isLoadingContent={isLoading}
        ModalActionsComponent={() => (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <FBOButton
              variant="secondary"
              color="positive"
              data-qa="cancel-button"
              onClick={handleCloseModal}
            >
              Cancel
            </FBOButton>
            <Box ml={2}>
              <FBOButton
                variant="primary"
                color="positive"
                data-qa="save-button"
                onClick={handleConnectClicked}
              >
                Save
              </FBOButton>
            </Box>
          </Box>
        )}
      >
        <form onSubmit={handleConnectClicked}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <TextField
                label="Domain"
                name="domain"
                onChange={handleTextFieldChange}
                value={form.domain}
                required
                error={!!errors.domain}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                label="Client Key"
                name="key"
                onChange={handleTextFieldChange}
                value={form.key}
                required
                error={!!errors.key}
              />
            </Grid>
            <Grid item md={12}>
              <TextField
                label="Client Secret"
                name="token"
                onChange={handleTextFieldChange}
                value={form.token}
                required
                error={!!errors.token}
              />
            </Grid>
          </Grid>
        </form>
      </Modal>
      <ConfirmationModal
        open={showDisconnectModal}
        title="Disconnect WooCommerce"
        body={
          'Are you sure you want to disconnect WooCommerce channel ' +
          (activeChannel ? activeChannel!.name : null) +
          '?'
        }
        isLoading={isLoading}
        onCancelClicked={() => setShowDisconnectModal(false)}
        onConfirmClicked={handleDisconnect}
      />
      <ConfirmationModal
        open={showConnectionModal}
        title="Maximum number of integrations has been reached!"
        onConfirmClicked={() => setShowConnectionModal(false)}
        onCancelClicked={() => setShowConnectionModal(false)}
        body="Please contact sales to add additional integrations."
      />
    </>
  );
};

export default memo(WooCommerceCard);
