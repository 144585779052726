import * as yup from 'yup';

import { SalesOrderItem, SalesOrderItemTypes } from 'services/salesOrders';

export const salesOrderSchema = yup.object().shape({
  number: yup.string(),
  customerId: yup.number().typeError('Customer is required'),
  locationId: yup.number(),
  dateScheduled: yup.date().nullable(true).typeError('Invalid Date Format'),
});
export const customerSchema = yup.object().shape({
  customerId: yup.number().typeError('Customer is required'),
});

export const flatTaxRowSchema = yup.object().shape({
  taxId: yup.number().required(),
  price: yup.number().required(),
});

export const noteRowSchema = yup.object().shape({
  name: yup.string().required(),
});

export const saleRowSchema = yup.object().shape({
  price: yup.number().required(),
  quantity: yup.number().required(),
});

export const miscRowSchema = yup.object().shape({
  name: yup.string().required(),
  price: yup.number().required(),
  quantity: yup.number().required(),
});

export const salesOrderItemsRowFormValidation = (row: SalesOrderItem) => {
  switch (row.salesOrderItemType) {
    case SalesOrderItemTypes.FlatTaxRate:
      return flatTaxRowSchema;
    case SalesOrderItemTypes.Note:
      return noteRowSchema;
    case SalesOrderItemTypes.Bundle:
    case SalesOrderItemTypes.BundleCreditReturn:
    case SalesOrderItemTypes.Sale:
    case SalesOrderItemTypes.CreditReturn:
    case SalesOrderItemTypes.DropShip:
      return saleRowSchema;
    case SalesOrderItemTypes.MiscReturn:
    case SalesOrderItemTypes.MiscSale:
      return miscRowSchema;
  }
};

const regex = /^(0[1-9]|1[0-2])\/\d{2}$/;

export const paymentModalCardSchema = yup.object().shape({
  paymentAmount: yup.number().required(),
  nameOnCard: yup.string().required(),
  cardType: yup.string().required(),
  expDate: yup.string().matches(regex).required(),
  lastFourDigits: yup
    .string()
    .test('len', 'There needs to be 4 digits', (val) =>
      val ? val.length === 4 : false
    )
    .required(),
});

export const paymentModalCashSchema = yup.object().shape({
  paymentAmount: yup.number().required(),
});

export const paymentModalCheckSchema = yup.object().shape({
  paymentAmount: yup.number().required(),
  referenceNumber: yup.number().required(),
});

export const paymentModalStripeSchema = yup.object().shape({
  paymentAmount: yup.number().required(),
  nameOnCard: yup.string().required(),
});

export const salesOrderAddNewPaymentModalValidation = (type: string) => {
  switch (type) {
    case 'AuthNet':
      return paymentModalCashSchema;
    case 'Stripe':
      return paymentModalStripeSchema;
    case 'Card':
      return paymentModalCardSchema;
    case 'Cash':
      return paymentModalCashSchema;
    case 'Check':
      return paymentModalCheckSchema;
    default:
      return paymentModalCardSchema;
  }
};
