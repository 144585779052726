import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export default function LoadingComponent() {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress
        color="primary"
        data-qa="reports-viewer-loading-spinner"
      />
    </Box>
  );
}
