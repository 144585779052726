import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Switch, Typography } from '@mui/material';

import { Item, ItemType, ShippingTerm, SHIPPING_TERMS } from 'services/items';
import { Location } from 'services/locations';
import {
  fetchTaxRates,
  getTaxRates,
  TaxRate,
  TaxType,
} from 'services/taxRates';

import LocationsAsyncAutocomplete from 'ui/components/Autocomplete/LocationsAsyncAutocomplete/LocationsAsyncAutocomplete';
import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { CustomersAutocomplete } from 'ui/components/Autocomplete/CustomerAutocomplete';
import { Customer } from 'services/customers';

import { SettingsFormProps } from '../../types';
import { useEcommerceCardStyle } from '../../../../styled';
import useEcommerceSettings from '../../../../useEcommerceSettings';

const POSSettingsForm: React.FC<SettingsFormProps> = (props) => {
  const classes = useEcommerceCardStyle();
  const dispatch = useDispatch();

  const { index } = props;

  const [setting, setEcommerceSettings, errors, , , , channelName] =
    useEcommerceSettings(index);

  const [posSettings, setPOSSettings] = useState(setting);

  const { items: taxes } = useSelector(getTaxRates);

  const selectedTaxItem = useMemo(
    () => taxes.find((t) => t.id === posSettings.pos.taxItem) || null,
    [posSettings.pos.taxItem, taxes]
  );

  const selectedShippingTerm = useMemo(
    () =>
      SHIPPING_TERMS.find((s) => s.id === posSettings.pos.shippingTerms) ||
      null,
    [posSettings.pos.shippingTerms]
  );

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchTaxRates());
  }, [dispatch]);

  useEffect(() => {
    if (posSettings.id) {
      setEcommerceSettings(posSettings);
    }
  }, [posSettings, setEcommerceSettings, index]);

  const handleAutoCompleteChange = useCallback(
    (
        name:
          | 'shippingTerms'
          | 'location'
          | 'shippingItem'
          | 'taxItem'
          | 'customer'
      ) =>
      (v: Location | TaxRate | Customer | Item | ShippingTerm | null) => {
        setPOSSettings((old) => ({
          ...old,
          pos: { ...old.pos, [name]: v ? v.id : null },
        }));
      },
    [setPOSSettings]
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setPOSSettings((old) => ({
        ...old,
        pos: {
          ...old.pos,
          [event.target.name]: checked,
        },
      }));
    },
    [setPOSSettings]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex="1"
      height="100%"
      overflow="auto"
    >
      <Box className={classes.modalRowExpandable}>
        <Box className={classes.modalRow}>
          <Typography variant="body1" color="textPrimary">
            Enable POS import
          </Typography>

          <Switch
            checked={posSettings.pos.enabled}
            onChange={handleCheckboxChange}
            name="enabled"
            color="primary"
          />
        </Box>
      </Box>

      <Divider />
      {posSettings.pos.enabled && (
        <>
          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Default location
            </Typography>

            <Box width="40%">
              <LocationsAsyncAutocomplete
                placeholder="Select Location"
                value={posSettings.pos.location}
                onChange={(v: Location | null) =>
                  handleAutoCompleteChange('location')(v)
                }
                parentId={null}
                companyWide={false}
                disableAdd
                error={!!errors.location}
              />
            </Box>
          </Box>

          <Divider />

          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Sales tax item
            </Typography>

            <Box width="40%">
              <Autocomplete
                options={taxes.filter((t) => t.taxType === TaxType.flatRate)}
                value={selectedTaxItem}
                onChange={(e: React.ChangeEvent<{}>, v: TaxRate | null) =>
                  handleAutoCompleteChange('taxItem')(v)
                }
                getOptionLabel={(option) => option.name}
                error={!!errors.taxItem}
                placeholder="Sales Tax"
              />
            </Box>
          </Box>

          <Divider />

          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Default customer
            </Typography>

            <Box width="40%">
              <CustomersAutocomplete
                onChange={(v: Customer | null) =>
                  handleAutoCompleteChange('customer')(v)
                }
                placeholder="Select Customer"
                value={posSettings.pos.customer}
                disableAdd
                error={!!errors.customer}
              />
            </Box>
          </Box>

          <Divider />

          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Shipping Item
            </Typography>

            <Box width="40%">
              <ItemsAutocomplete
                value={posSettings.pos.shippingItem}
                onChange={(v: Item | null) =>
                  handleAutoCompleteChange('shippingItem')(v)
                }
                placeholder="Select Item"
                itemTypes={[ItemType.Shipping]}
                disableAdd
                error={!!errors.shippingItem}
              />
            </Box>
          </Box>

          <Divider />

          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Shipping Terms
            </Typography>

            <Box width="40%">
              <Autocomplete
                options={SHIPPING_TERMS}
                value={selectedShippingTerm}
                onChange={(e: React.ChangeEvent<{}>, v: ShippingTerm | null) =>
                  handleAutoCompleteChange('shippingTerms')(v)
                }
                placeholder="Shipping Terms"
                getOptionLabel={(option: ShippingTerm) => option.name}
                error={!!errors.shippingTerms}
              />
            </Box>
          </Box>

          <Divider />

          <Box className={classes.modalRow}>
            <Typography variant="body1" color="textPrimary">
              Use {channelName} order number as Fishbowl order number
            </Typography>

            <Switch
              checked={posSettings.pos.useNumber}
              onChange={handleCheckboxChange}
              color="primary"
              name="useNumber"
            />
          </Box>

          <Divider />

          <Box className={classes.modalRowExpandable}>
            <Box className={classes.modalRow}>
              <Typography variant="body1" color="textPrimary">
                Create payments in Fishbowl
              </Typography>

              <Switch
                checked={posSettings.pos.createPayments}
                onChange={handleCheckboxChange}
                color="primary"
                name="createPayments"
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default memo(POSSettingsForm);
