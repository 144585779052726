import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useFlags } from 'helpers/useFlags';

import { Page } from 'ui/components/Page/Page';
import { getActiveUser } from 'services/user';
import { getConnectedEcommerceChannels } from 'services/integrations/redux/selectors';
import { fetchTaxRates } from 'services/taxRates';
import { fetchSettingsCompanies } from 'services/settings/company';
import { isPaymentConnected } from 'services/integrations/payments/redux';
import { PaymentIntegrationType } from 'services/integrations/payments';

import { CardSection, WooCommerceCard, AmazonCard } from './components';
import { ShopifyCard } from './components/Shopify';
import { QuickBooksCard } from './components/QuickBooks';
import { ShippoCard } from './components/Shippo';
import { useIntegrationsPageStyle } from './styled';
import { IntegrationsPageCmp, IntegrationsPageProps } from './types';
import { Routes } from '../../navigation';
import { StripeCard } from './components/Stripe';
import { BigCommerceCard } from './components/BigCommerce';
import { MagentoCard } from './components/Magento';
import { XeroCard } from './components/Xero';

const IntegrationsPage: IntegrationsPageCmp = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: IntegrationsPageProps
) => {
  const classes = useIntegrationsPageStyle();

  const dispatch = useDispatch();

  const { maxIntegrations } = useSelector(getActiveUser);
  const isStripeConnected = useSelector(
    isPaymentConnected(PaymentIntegrationType.Stripe)
  );

  const flags = useFlags();

  const connectedChannelsNumber = useSelector(getConnectedEcommerceChannels);

  const connectionValid = maxIntegrations! > connectedChannelsNumber;

  useEffect(() => {
    // Lint skip to be removed
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dispatch(fetchTaxRates());
    // @ts-ignore
    dispatch(fetchSettingsCompanies());
  }, [dispatch]);
  return (
    <>
      <Page>
        <Box className={classes.cardContainer}>
          <CardSection title="Accounting" sectionId="accounting">
            <QuickBooksCard />
            {flags.xero ? <XeroCard /> : null}
            {/* <QuickBooksDesktop /> */}
          </CardSection>

          <CardSection title="Shipping" sectionId="shipping">
            <ShippoCard />
          </CardSection>

          {flags.ecommerceIntegrations ? (
            <CardSection title="E-commerce" sectionId="e-commerce">
              <ShopifyCard connectionValid={connectionValid} />
              <>
                <WooCommerceCard connectionValid={connectionValid} />
                <BigCommerceCard connectionValid={connectionValid} />
                <AmazonCard connectionValid={connectionValid} />
                <MagentoCard connectionValid={connectionValid} />
              </>
            </CardSection>
          ) : null}

          {isStripeConnected && (
            <CardSection title="Payment" sectionId="payment">
              <StripeCard />
            </CardSection>
          )}
        </Box>
      </Page>
    </>
  );
};

IntegrationsPage.route = Routes.IntegrationsPage;

export default IntegrationsPage;
