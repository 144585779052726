import React, { useCallback, useContext, useState } from 'react';

import { initialRopWizardData } from './consts';
import { fillConfirmRopValueItems } from './helpers';
import { ConfirmRopValues, RopWizardData, RopItem } from './types';

export const RopWizardContext = React.createContext<{
  wizardData: RopWizardData;
  setWizardData: React.Dispatch<React.SetStateAction<RopWizardData>>;
  setLocationIds: React.Dispatch<React.SetStateAction<number[]>>;
  setRopItems: React.Dispatch<React.SetStateAction<RopItem[]>>;
  setDefaultValues: React.Dispatch<React.SetStateAction<RopItem>>;
  confirmData: ConfirmRopValues[];
  setConfirmData: React.Dispatch<React.SetStateAction<ConfirmRopValues[]>>;
  setConfirmDataFromResponse: (data: ConfirmRopValues[]) => void;
  errors: any;
  setErrors: React.Dispatch<React.SetStateAction<{}>>;
}>({
  wizardData: initialRopWizardData,
  setWizardData: () => {},
  setLocationIds: () => {},
  setRopItems: () => {},
  setDefaultValues: () => {},
  confirmData: [],
  setConfirmData: () => {},
  setConfirmDataFromResponse: () => {},
  errors: {},
  setErrors: () => {},
});

export const RopWizardProvider: React.FC = ({ children }) => {
  const [wizardData, setWizardData] =
    useState<RopWizardData>(initialRopWizardData);
  const [confirmData, setConfirmData] = useState<ConfirmRopValues[]>([]);
  const [errors, setErrors] = useState({});

  const setLocationIds = useCallback(
    (parameters: React.SetStateAction<number[]>) => {
      if (typeof parameters === 'function') {
        setWizardData((old) => ({
          ...old,
          locationIds: parameters(old.locationIds),
        }));
        return;
      }

      setWizardData((old) => ({
        ...old,
        locationIds: parameters,
      }));
    },
    [setWizardData]
  );

  const setRopItems = useCallback(
    (parameters: React.SetStateAction<RopItem[]>) => {
      if (typeof parameters === 'function') {
        setWizardData((old) => ({
          ...old,
          ropItems: parameters(old.ropItems),
        }));
        return;
      }

      setWizardData((old) => ({
        ...old,
        ropItems: parameters,
      }));
    },
    [setWizardData]
  );

  const setDefaultValues = useCallback(
    (parameters: React.SetStateAction<RopItem>) => {
      if (typeof parameters === 'function') {
        setWizardData((old) => ({
          ...old,
          defaultValues: parameters(old.defaultValues),
        }));
        return;
      }

      setWizardData((old) => ({
        ...old,
        defaultValues: parameters,
      }));
    },
    [setWizardData]
  );

  const setConfirmDataFromResponse = useCallback(
    (data: ConfirmRopValues[]) => {
      setConfirmData(fillConfirmRopValueItems(data, wizardData.ropItems));
    },
    [wizardData.ropItems]
  );

  return (
    <RopWizardContext.Provider
      value={{
        wizardData,
        setWizardData,
        setLocationIds,
        setRopItems,
        setDefaultValues,
        confirmData,
        setConfirmData,
        setConfirmDataFromResponse,
        errors,
        setErrors,
      }}
    >
      {children}
    </RopWizardContext.Provider>
  );
};

export const useRopWizard = () => {
  return useContext(RopWizardContext);
};
